import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A base64 encoded string */
  Base64: { input: string; output: string };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
  /** Represents untyped JSON */
  JSON: { input: any; output: any };
  /** Arbitrary JSON Type */
  JsonObject: { input: any; output: any };
};

export enum ActiveStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE',
}

export type ActivityLog = {
  __typename?: 'ActivityLog';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  resolvedRecords: Array<ActivityLogRecord>;
  user: ApplicationUser;
};

export type ActivityLogRecord = {
  __typename?: 'ActivityLogRecord';
  recordId: Scalars['String']['output'];
  recordType: Scalars['String']['output'];
};

export type ActivityLogsPaginated = {
  __typename?: 'ActivityLogsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ActivityLog>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Autogenerated input type of AddRecentItem */
export type AddRecentItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemId: Scalars['ID']['input'];
  itemType: RecentItemType;
};

export enum AftercareMethod {
  /** In person: one-on-one */
  InPerson_1On_1 = 'IN_PERSON_1_ON_1',
  /** In person: group */
  InPersonGroup = 'IN_PERSON_GROUP',
  /** Via email/social media */
  ViaEmailSocial = 'VIA_EMAIL_SOCIAL',
  /** Via telephone */
  ViaTel = 'VIA_TEL',
}

/** HUD AftercareProvided (R20.2) */
export enum AftercareProvided {
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) No */
  No = 'NO',
  /** (1) Yes */
  Yes = 'YES',
}

/** HUD Age Ranges */
export enum AgeRange {
  /** 62+ */
  Age62Plus = 'Age62Plus',
  /** 65+ */
  Age65Plus = 'Age65Plus',
  /** 5-12 */
  Ages5to12 = 'Ages5to12',
  /** 13-17 */
  Ages13to17 = 'Ages13to17',
  /** 18-24 */
  Ages18to24 = 'Ages18to24',
  /** 25-34 */
  Ages25to34 = 'Ages25to34',
  /** 35-44 */
  Ages35to44 = 'Ages35to44',
  /** 45-54 */
  Ages45to54 = 'Ages45to54',
  /** 55-61 */
  Ages55to61 = 'Ages55to61',
  /** 55-64 */
  Ages55to64 = 'Ages55to64',
  /** Under 5 */
  Under5 = 'Under5',
}

/** HUD AnnualPercentAMI (V7.B) */
export enum AnnualPercentAmi {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (3) More than 30% of AMI for household size */
  MoreThan_30OfAmiForHouseholdSize = 'MORE_THAN_30_OF_AMI_FOR_HOUSEHOLD_SIZE',
  /** (0) $0 (i.e., not employed, not receiving cash benefits, no other current income) */
  Num_0 = 'NUM_0',
  /** (1) 1-14% of Area Median Income (AMI) for household size */
  Num_1_14OfAreaMedianIncomeAmiForHouseholdSize = 'NUM_1_14_OF_AREA_MEDIAN_INCOME_AMI_FOR_HOUSEHOLD_SIZE',
  /** (2) 15-30% of AMI for household size */
  Num_15_30OfAmiForHouseholdSize = 'NUM_15_30_OF_AMI_FOR_HOUSEHOLD_SIZE',
}

/** User account for a user of the system */
export type ApplicationUser = {
  __typename?: 'ApplicationUser';
  activityLogs: ActivityLogsPaginated;
  auditHistory: ApplicationUserAuditEventsPaginated;
  clientAccessSummaries: ClientAccessSummariesPaginated;
  dateCreated: Scalars['ISO8601DateTime']['output'];
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  enrollmentAccessSummaries: EnrollmentAccessSummariesPaginated;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  recentItems: Array<OmnisearchResult>;
  staffAssignments?: Maybe<StaffAssignmentsPaginated>;
};

/** User account for a user of the system */
export type ApplicationUserActivityLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** User account for a user of the system */
export type ApplicationUserAuditHistoryArgs = {
  filters?: InputMaybe<UserAuditEventFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** User account for a user of the system */
export type ApplicationUserClientAccessSummariesArgs = {
  filters?: InputMaybe<ClientAccessSummaryFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** User account for a user of the system */
export type ApplicationUserEnrollmentAccessSummariesArgs = {
  filters?: InputMaybe<EnrollmentAccessSummaryFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** User account for a user of the system */
export type ApplicationUserStaffAssignmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ApplicationUserAuditEvent = {
  __typename?: 'ApplicationUserAuditEvent';
  clientId?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  enrollmentId?: Maybe<Scalars['String']['output']>;
  event: AuditEventType;
  graphqlType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Format is { field: { fieldName: "GQL field name", displayName: "Human readable name", values: [old, new] } } */
  objectChanges?: Maybe<Scalars['JsonObject']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  recordId: Scalars['ID']['output'];
  recordName: Scalars['String']['output'];
  trueUser?: Maybe<ApplicationUser>;
  user?: Maybe<ApplicationUser>;
};

export type ApplicationUserAuditEventsPaginated = {
  __typename?: 'ApplicationUserAuditEventsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ApplicationUserAuditEvent>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type ApplicationUserFilterOptions = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationUsersPaginated = {
  __typename?: 'ApplicationUsersPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ApplicationUser>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Custom Assessment */
export type Assessment = {
  __typename?: 'Assessment';
  access: AssessmentAccess;
  assessmentDate: Scalars['ISO8601Date']['output'];
  ceAssessment?: Maybe<CeAssessment>;
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  customDataElements: Array<CustomDataElement>;
  dataCollectionStage?: Maybe<DataCollectionStage>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * Definition to use for viewing the assessment. If upgradedDefinitionForEditing
   * is nil, then it should also be used for editing.
   */
  definition: FormDefinition;
  disabilityGroup?: Maybe<DisabilityGroup>;
  employmentEducation?: Maybe<EmploymentEducation>;
  enrollment: Enrollment;
  event?: Maybe<Event>;
  exit?: Maybe<Exit>;
  /** Client Location where the assessment was performed */
  geolocation?: Maybe<Geolocation>;
  healthAndDv?: Maybe<HealthAndDv>;
  id: Scalars['ID']['output'];
  inProgress: Scalars['Boolean']['output'];
  incomeBenefit?: Maybe<IncomeBenefit>;
  lockVersion: Scalars['Int']['output'];
  role: AssessmentRole;
  /**
   * Most recent published Definition to use for editing the assessment. Only
   * present if the original form definition was retired.
   */
  upgradedDefinitionForEditing?: Maybe<FormDefinition>;
  user?: Maybe<ApplicationUser>;
  wipValues?: Maybe<Scalars['JsonObject']['output']>;
  youthEducationStatus?: Maybe<YouthEducationStatus>;
};

export type AssessmentAccess = {
  __typename?: 'AssessmentAccess';
  canDeleteAssessments: Scalars['Boolean']['output'];
  canDeleteEnrollments: Scalars['Boolean']['output'];
  canEditEnrollments: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type AssessmentEligibility = {
  __typename?: 'AssessmentEligibility';
  formDefinitionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  role: AssessmentRole;
  title: Scalars['String']['output'];
};

export type AssessmentFilterOptions = {
  assessmentName?: InputMaybe<Array<Scalars['String']['input']>>;
  project?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectType?: InputMaybe<Array<ProjectType>>;
};

export type AssessmentInput = {
  /** Required if updating an existing assessment */
  assessmentId?: InputMaybe<Scalars['ID']['input']>;
  /** Whether warnings have been confirmed */
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required if saving a new assessment */
  enrollmentId: Scalars['ID']['input'];
  /** Form Definition that was used to perform this assessment */
  formDefinitionId: Scalars['ID']['input'];
  /** Transformed HUD values as JSON */
  hudValues: Scalars['JsonObject']['input'];
  /** Validate assessment but don't submit it */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Raw form state as JSON */
  values: Scalars['JsonObject']['input'];
};

/** HUD AssessmentLevel (4.19.4) */
export enum AssessmentLevel {
  /** (1) Crisis Needs Assessment */
  CrisisNeedsAssessment = 'CRISIS_NEEDS_ASSESSMENT',
  /** (2) Housing Needs Assessment */
  HousingNeedsAssessment = 'HOUSING_NEEDS_ASSESSMENT',
  /** Invalid Value */
  Invalid = 'INVALID',
}

/** Form Roles that are used for assessments. These types of forms are submitted using SubmitAssessment. */
export enum AssessmentRole {
  /** Annual */
  Annual = 'ANNUAL',
  /** Custom assessment */
  CustomAssessment = 'CUSTOM_ASSESSMENT',
  /** Exit */
  Exit = 'EXIT',
  /** Intake */
  Intake = 'INTAKE',
  /** Post exit */
  PostExit = 'POST_EXIT',
  /** Update */
  Update = 'UPDATE',
}

/** HUD Assessment Sorting Options */
export enum AssessmentSortOption {
  /** Assessment Date: Most Recent First */
  AssessmentDate = 'ASSESSMENT_DATE',
  /** Last Updated: Most Recent First */
  DateUpdated = 'DATE_UPDATED',
}

/** HUD AssessmentType (4.19.3) */
export enum AssessmentType {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (3) In Person */
  InPerson = 'IN_PERSON',
  /** (1) Phone */
  Phone = 'PHONE',
  /** (2) Virtual */
  Virtual = 'VIRTUAL',
}

export type AssessmentsForEnrollmentFilterOptions = {
  assessmentName?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssessmentsForHouseholdFilterOptions = {
  assessmentName?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssessmentsForProjectFilterOptions = {
  assessmentName?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssessmentsPaginated = {
  __typename?: 'AssessmentsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Assessment>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Input for AssignStaff mutation */
export type AssignStaffInput = {
  /** Assignment relationship ID */
  assignmentRelationshipId: Scalars['ID']['input'];
  /** Household ID */
  householdId: Scalars['ID']['input'];
  /** User ID */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of AssignStaff. */
export type AssignStaffPayload = {
  __typename?: 'AssignStaffPayload';
  errors: Array<ValidationError>;
  staffAssignment?: Maybe<StaffAssignment>;
};

export enum AuditEventType {
  Create = 'create',
  Destroy = 'destroy',
  Update = 'update',
}

/** Value to autofill based on conditional logic */
export type AutofillValue = {
  __typename?: 'AutofillValue';
  autofillBehavior: EnableBehavior;
  /** Whether to perform autofill when displaying a read-only view (defaults to false) */
  autofillReadonly?: Maybe<Scalars['Boolean']['output']>;
  autofillWhen: Array<EnableWhen>;
  /** Expression with mathematical or logical function defining the value */
  formula?: Maybe<Scalars['String']['output']>;
  /** Link IDs of numeric questions to sum up and set as the value if condition is met */
  sumQuestions?: Maybe<Array<Scalars['String']['output']>>;
  /** Value to autofill if condition is met */
  valueBoolean?: Maybe<Scalars['Boolean']['output']>;
  /** Value to autofill if condition is met */
  valueCode?: Maybe<Scalars['String']['output']>;
  /** Value to autofill if condition is met */
  valueNumber?: Maybe<Scalars['Int']['output']>;
  /** Link ID whos value to autofill if condition is met */
  valueQuestion?: Maybe<Scalars['String']['output']>;
};

/** HUD Availability (2.07.6) */
export enum Availability {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (3) Overflow */
  Overflow = 'OVERFLOW',
  /** (2) Seasonal */
  Seasonal = 'SEASONAL',
  /** (1) Year-round */
  YearRound = 'YEAR_ROUND',
}

/** HUD BedType (2.07.5) */
export enum BedType {
  /** (1) Facility-based */
  FacilityBased = 'FACILITY_BASED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (3) Other */
  Other = 'OTHER',
  /** (2) Voucher */
  Voucher = 'VOUCHER',
}

export enum BoundType {
  Max = 'MAX',
  Min = 'MIN',
}

/** Input for BulkAssignService mutation */
export type BulkAssignServiceInput = {
  /** Clients that should receive service. Clients that are unenrolled in the project will be enrolled in the project. */
  clientIds: Array<Scalars['ID']['input']>;
  /** CoC code to store as EnrollmentCoC when enrolling a new client. Only needed if Project operaties in multiple CoCs. */
  cocCode?: InputMaybe<Scalars['String']['input']>;
  dateProvided: Scalars['ISO8601Date']['input'];
  projectId: Scalars['ID']['input'];
  serviceTypeId: Scalars['ID']['input'];
};

/** Autogenerated return type of BulkAssignService. */
export type BulkAssignServicePayload = {
  __typename?: 'BulkAssignServicePayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of BulkMergeClients */
export type BulkMergeClientsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: Array<ClientMergeInput>;
};

/** Autogenerated return type of BulkMergeClients. */
export type BulkMergeClientsPayload = {
  __typename?: 'BulkMergeClientsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of BulkRemoveService. */
export type BulkRemoveServicePayload = {
  __typename?: 'BulkRemoveServicePayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of BulkReviewExternalSubmissions. */
export type BulkReviewExternalSubmissionsPayload = {
  __typename?: 'BulkReviewExternalSubmissionsPayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** HUD Coordinated Entry Assessment */
export type CeAssessment = {
  __typename?: 'CeAssessment';
  assessmentDate: Scalars['ISO8601Date']['output'];
  assessmentLevel?: Maybe<AssessmentLevel>;
  assessmentLocation: Scalars['String']['output'];
  assessmentType?: Maybe<AssessmentType>;
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  enrollment: Enrollment;
  id: Scalars['ID']['output'];
  prioritizationStatus?: Maybe<PrioritizationStatus>;
  user?: Maybe<ApplicationUser>;
};

export type CeAssessmentsPaginated = {
  __typename?: 'CeAssessmentsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<CeAssessment>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type CeParticipation = {
  __typename?: 'CeParticipation';
  accessPoint?: Maybe<NoYes>;
  ceParticipationStatusEndDate?: Maybe<Scalars['ISO8601Date']['output']>;
  ceParticipationStatusStartDate?: Maybe<Scalars['ISO8601Date']['output']>;
  createdBy?: Maybe<ApplicationUser>;
  crisisAssessment?: Maybe<NoYes>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  directServices?: Maybe<NoYes>;
  housingAssessment?: Maybe<NoYes>;
  id: Scalars['ID']['output'];
  preventionAssessment?: Maybe<NoYes>;
  receivesReferrals?: Maybe<NoYes>;
  user?: Maybe<ApplicationUser>;
};

export type CeParticipationsPaginated = {
  __typename?: 'CeParticipationsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<CeParticipation>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Autogenerated input type of ClearMci */
export type ClearMciInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: MciClearanceInput;
};

/** Autogenerated return type of ClearMci. */
export type ClearMciPayload = {
  __typename?: 'ClearMciPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  matches?: Maybe<Array<MciClearanceMatch>>;
};

/** Autogenerated input type of ClearRecentItems */
export type ClearRecentItemsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** HUD Client */
export type Client = {
  __typename?: 'Client';
  access: ClientAccess;
  activeEnrollment?: Maybe<Enrollment>;
  additionalRaceEthnicity?: Maybe<Scalars['String']['output']>;
  addresses: Array<ClientAddress>;
  afghanistanOef?: Maybe<NoYesReasonsForMissingData>;
  age?: Maybe<Scalars['Int']['output']>;
  alerts: Array<ClientAlert>;
  assessments: AssessmentsPaginated;
  auditHistory: ClientAuditEventsPaginated;
  contactPoints: Array<ClientContactPoint>;
  createdBy?: Maybe<ApplicationUser>;
  currentLivingSituations: CurrentLivingSituationsPaginated;
  customCaseNotes: CustomCaseNotesPaginated;
  customDataElements: Array<CustomDataElement>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  desertStorm?: Maybe<NoYesReasonsForMissingData>;
  differentIdentityText?: Maybe<Scalars['String']['output']>;
  disabilities: DisabilitiesPaginated;
  dischargeStatus?: Maybe<DischargeStatus>;
  dob?: Maybe<Scalars['ISO8601Date']['output']>;
  dobDataQuality: DobDataQuality;
  emailAddresses: Array<ClientContactPoint>;
  employmentEducations: EmploymentEducationsPaginated;
  enabledFeatures: Array<ClientDashboardFeature>;
  enrollments: EnrollmentsPaginated;
  externalIds: Array<ExternalIdentifier>;
  files: FilesPaginated;
  firstName?: Maybe<Scalars['String']['output']>;
  gender: Array<Gender>;
  healthAndDvs: HealthAndDvsPaginated;
  /** Meets the definition for HUD chronically homeless as of today (time of API request) */
  hudChronic?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ClientImage>;
  incomeBenefits: IncomeBenefitsPaginated;
  iraqOif?: Maybe<NoYesReasonsForMissingData>;
  iraqOnd?: Maybe<NoYesReasonsForMissingData>;
  koreanWar?: Maybe<NoYesReasonsForMissingData>;
  lastName?: Maybe<Scalars['String']['output']>;
  lockVersion: Scalars['Int']['output'];
  mergeAuditHistory: MergeAuditEventsPaginated;
  middleName?: Maybe<Scalars['String']['output']>;
  militaryBranch?: Maybe<MilitaryBranch>;
  nameDataQuality: NameDataQuality;
  nameSuffix?: Maybe<Scalars['String']['output']>;
  names: Array<ClientName>;
  otherTheater?: Maybe<NoYesReasonsForMissingData>;
  personalId: Scalars['String']['output'];
  phoneNumbers: Array<ClientContactPoint>;
  pronouns: Array<Scalars['String']['output']>;
  race: Array<Race>;
  scanCardCodes: ScanCardCodesPaginated;
  services: ServicesPaginated;
  ssn?: Maybe<Scalars['String']['output']>;
  ssnDataQuality: SsnDataQuality;
  user?: Maybe<ApplicationUser>;
  veteranStatus: NoYesReasonsForMissingData;
  vietnamWar?: Maybe<NoYesReasonsForMissingData>;
  worldWarIi?: Maybe<NoYesReasonsForMissingData>;
  yearEnteredService?: Maybe<Scalars['Int']['output']>;
  yearSeparated?: Maybe<Scalars['Int']['output']>;
  youthEducationStatuses: YouthEducationStatusesPaginated;
};

/** HUD Client */
export type ClientActiveEnrollmentArgs = {
  openOnDate: Scalars['ISO8601Date']['input'];
  projectId: Scalars['ID']['input'];
};

/** HUD Client */
export type ClientAssessmentsArgs = {
  filters?: InputMaybe<AssessmentFilterOptions>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<AssessmentSortOption>;
};

/** HUD Client */
export type ClientAuditHistoryArgs = {
  filters?: InputMaybe<ClientAuditEventFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Client */
export type ClientCurrentLivingSituationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Client */
export type ClientCustomCaseNotesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<CustomCaseNoteSortOption>;
};

/** HUD Client */
export type ClientDisabilitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Client */
export type ClientEmploymentEducationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Client */
export type ClientEnrollmentsArgs = {
  filters?: InputMaybe<EnrollmentsForClientFilterOptions>;
  includeEnrollmentsWithLimitedAccess?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<EnrollmentSortOption>;
};

/** HUD Client */
export type ClientFilesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<FileSortOption>;
};

/** HUD Client */
export type ClientHealthAndDvsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Client */
export type ClientIncomeBenefitsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Client */
export type ClientMergeAuditHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Client */
export type ClientScanCardCodesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Client */
export type ClientServicesArgs = {
  filters?: InputMaybe<ServiceFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ServiceSortOption>;
};

/** HUD Client */
export type ClientYouthEducationStatusesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ClientAccess = {
  __typename?: 'ClientAccess';
  canAuditClients: Scalars['Boolean']['output'];
  canDeleteAssessments: Scalars['Boolean']['output'];
  canDeleteClient: Scalars['Boolean']['output'];
  canDeleteEnrollments: Scalars['Boolean']['output'];
  canEditClient: Scalars['Boolean']['output'];
  canEditEnrollments: Scalars['Boolean']['output'];
  canManageAnyClientFiles: Scalars['Boolean']['output'];
  canManageClientAlerts: Scalars['Boolean']['output'];
  canManageOwnClientFiles: Scalars['Boolean']['output'];
  canManageScanCards: Scalars['Boolean']['output'];
  canMergeClients: Scalars['Boolean']['output'];
  canUploadClientFiles: Scalars['Boolean']['output'];
  canViewAnyConfidentialClientFiles: Scalars['Boolean']['output'];
  canViewAnyFiles: Scalars['Boolean']['output'];
  canViewAnyNonconfidentialClientFiles: Scalars['Boolean']['output'];
  canViewClientAlerts: Scalars['Boolean']['output'];
  canViewClientName: Scalars['Boolean']['output'];
  canViewClientPhoto: Scalars['Boolean']['output'];
  canViewDob: Scalars['Boolean']['output'];
  canViewEnrollmentDetails: Scalars['Boolean']['output'];
  canViewFullSsn: Scalars['Boolean']['output'];
  canViewPartialSsn: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type ClientAccessSummariesPaginated = {
  __typename?: 'ClientAccessSummariesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ClientAccessSummary>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type ClientAccessSummary = {
  __typename?: 'ClientAccessSummary';
  clientId: Scalars['ID']['output'];
  clientName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastAccessedAt: Scalars['ISO8601DateTime']['output'];
};

export type ClientAccessSummaryFilterOptions = {
  onOrAfter?: InputMaybe<Scalars['ISO8601Date']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type ClientAddress = {
  __typename?: 'ClientAddress';
  addressType?: Maybe<ClientAddressType>;
  city?: Maybe<Scalars['String']['output']>;
  client: Client;
  country?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  use?: Maybe<ClientAddressUse>;
  user?: Maybe<ApplicationUser>;
};

/** Allowed values for ClientAddress.type */
export enum ClientAddressType {
  /** Both */
  Both = 'both',
  /** Physical */
  Physical = 'physical',
  /** Postal */
  Postal = 'postal',
}

/** Allowed values for ClientAddress.use */
export enum ClientAddressUse {
  /** Home */
  Home = 'home',
  /** Mail */
  Mail = 'mail',
  /** Old */
  Old = 'old',
  /** School */
  School = 'school',
  /** Temp */
  Temp = 'temp',
  /** Work */
  Work = 'work',
}

/** Alert */
export type ClientAlert = {
  __typename?: 'ClientAlert';
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy?: Maybe<ApplicationUser>;
  expirationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  note: Scalars['String']['output'];
  priority: ClientAlertPriorityLevel;
};

/** Client Alert Input */
export type ClientAlertInput = {
  clientId: Scalars['ID']['input'];
  expirationDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<ClientAlertPriorityLevel>;
};

export enum ClientAlertPriorityLevel {
  /** 1 - High */
  High = 'high',
  /** 3 - Low */
  Low = 'low',
  /** 2 - Medium */
  Medium = 'medium',
}

export type ClientAuditEvent = {
  __typename?: 'ClientAuditEvent';
  clientId?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  enrollmentId?: Maybe<Scalars['String']['output']>;
  event: AuditEventType;
  graphqlType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Format is { field: { fieldName: "GQL field name", displayName: "Human readable name", values: [old, new] } } */
  objectChanges?: Maybe<Scalars['JsonObject']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  recordId: Scalars['ID']['output'];
  recordName: Scalars['String']['output'];
  trueUser?: Maybe<ApplicationUser>;
  user?: Maybe<ApplicationUser>;
};

export type ClientAuditEventFilterOptions = {
  clientRecordType?: InputMaybe<Array<Scalars['ID']['input']>>;
  user?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ClientAuditEventsPaginated = {
  __typename?: 'ClientAuditEventsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ClientAuditEvent>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type ClientContactPoint = {
  __typename?: 'ClientContactPoint';
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  system?: Maybe<ClientContactPointSystem>;
  use?: Maybe<ClientContactPointUse>;
  user?: Maybe<ApplicationUser>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Allowed values for ClientContactPoint.system */
export enum ClientContactPointSystem {
  /** Email */
  Email = 'email',
  /** Phone */
  Phone = 'phone',
  /** Url */
  Url = 'url',
}

/** Allowed values for ClientContactPoint.use */
export enum ClientContactPointUse {
  /** Home */
  Home = 'home',
  /** Mobile */
  Mobile = 'mobile',
  /** Old */
  Old = 'old',
  /** School */
  School = 'school',
  /** Temp */
  Temp = 'temp',
  /** Work */
  Work = 'work',
}

export enum ClientDashboardFeature {
  CaseNote = 'CASE_NOTE',
  File = 'FILE',
}

export type ClientFilterOptions = {
  organization?: InputMaybe<Array<Scalars['ID']['input']>>;
  project?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceInRange?: InputMaybe<ServiceRangeFilter>;
};

/** Client Image */
export type ClientImage = {
  __typename?: 'ClientImage';
  base64: Scalars['Base64']['output'];
  contentType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ClientMergeCandidate = {
  __typename?: 'ClientMergeCandidate';
  clients: Array<Client>;
  /** Warehouse ID */
  id: Scalars['ID']['output'];
  warehouseUrl: Scalars['String']['output'];
};

export type ClientMergeCandidatesPaginated = {
  __typename?: 'ClientMergeCandidatesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ClientMergeCandidate>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type ClientMergeInput = {
  clientIds: Array<Scalars['ID']['input']>;
};

export type ClientName = {
  __typename?: 'ClientName';
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  first?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last?: Maybe<Scalars['String']['output']>;
  middle?: Maybe<Scalars['String']['output']>;
  nameDataQuality?: Maybe<NameDataQuality>;
  notes?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['Boolean']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  use?: Maybe<ClientNameUse>;
  user?: Maybe<ApplicationUser>;
};

/** Allowed values for ClientName.use */
export enum ClientNameUse {
  /** Anonymous */
  Anonymous = 'anonymous',
  /** Maiden */
  Maiden = 'maiden',
  /** Nickname */
  Nickname = 'nickname',
  /** Official */
  Official = 'official',
  /** Old */
  Old = 'old',
  /** Temp */
  Temp = 'temp',
  /** Usual */
  Usual = 'usual',
}

/** HMIS Client search input */
export type ClientSearchInput = {
  /** Date of birth as format yyyy-mm-dd */
  dob?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Client primary key */
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<Array<Scalars['ID']['input']>>;
  personalId?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Last 4 digits of SSN */
  ssnSerial?: InputMaybe<Scalars['String']['input']>;
  /** Omnisearch string */
  textSearch?: InputMaybe<Scalars['String']['input']>;
  warehouseId?: InputMaybe<Scalars['String']['input']>;
};

/** HUD Client Sorting Options */
export enum ClientSortOption {
  /** Age: Oldest to Youngest */
  AgeOldestToYoungest = 'AGE_OLDEST_TO_YOUNGEST',
  /** Age: Youngest to Oldest */
  AgeYoungestToOldest = 'AGE_YOUNGEST_TO_OLDEST',
  /** Most Relevant */
  BestMatch = 'BEST_MATCH',
  /** First Name: A-Z */
  FirstNameAToZ = 'FIRST_NAME_A_TO_Z',
  /** First Name: Z-A */
  FirstNameZToA = 'FIRST_NAME_Z_TO_A',
  /** Last Name: A-Z */
  LastNameAToZ = 'LAST_NAME_A_TO_Z',
  /** Last Name: Z-A */
  LastNameZToA = 'LAST_NAME_Z_TO_A',
  /** Recently Added */
  RecentlyAdded = 'RECENTLY_ADDED',
}

export type ClientsPaginated = {
  __typename?: 'ClientsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Client>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD CmExitReason (V9.1) */
export enum CmExitReason {
  /** (1) Accomplished goals and/or obtained services and no longer needs CM */
  AccomplishedGoalsAndOrObtainedServicesAndNoLongerNeedsCm = 'ACCOMPLISHED_GOALS_AND_OR_OBTAINED_SERVICES_AND_NO_LONGER_NEEDS_CM',
  /** (4) Did not comply with HUD/VASH CM */
  DidNotComplyWithHudVashCm = 'DID_NOT_COMPLY_WITH_HUD_VASH_CM',
  /** (5) Eviction and/or other housing related issues */
  EvictionAndOrOtherHousingRelatedIssues = 'EVICTION_AND_OR_OTHER_HOUSING_RELATED_ISSUES',
  /** (3) Found/chose other housing */
  FoundChoseOtherHousing = 'FOUND_CHOSE_OTHER_HOUSING',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (7) No longer financially eligible for HUD/VASH voucher */
  NoLongerFinanciallyEligibleForHudVashVoucher = 'NO_LONGER_FINANCIALLY_ELIGIBLE_FOR_HUD_VASH_VOUCHER',
  /** (8) No longer interested in participating in this program */
  NoLongerInterestedInParticipatingInThisProgram = 'NO_LONGER_INTERESTED_IN_PARTICIPATING_IN_THIS_PROGRAM',
  /** (13) Other */
  Other = 'OTHER',
  /** (2) Transferred to another HUD/VASH program site */
  TransferredToAnotherHudVashProgramSite = 'TRANSFERRED_TO_ANOTHER_HUD_VASH_PROGRAM_SITE',
  /** (6) Unhappy with HUD/VASH housing */
  UnhappyWithHudVashHousing = 'UNHAPPY_WITH_HUD_VASH_HOUSING',
  /** (9) Veteran cannot be located */
  VeteranCannotBeLocated = 'VETERAN_CANNOT_BE_LOCATED',
  /** (12) Veteran is deceased */
  VeteranIsDeceased = 'VETERAN_IS_DECEASED',
  /** (11) Veteran is incarcerated */
  VeteranIsIncarcerated = 'VETERAN_IS_INCARCERATED',
  /** (10) Veteran too ill to participate at this time */
  VeteranTooIllToParticipateAtThisTime = 'VETERAN_TOO_ILL_TO_PARTICIPATE_AT_THIS_TIME',
}

export enum CompleteDisabilityResponse {
  /** Alcohol use disorder */
  AlcoholUseDisorder = 'ALCOHOL_USE_DISORDER',
  /** Both alcohol and drug use disorders */
  BothAlcoholAndDrugUseDisorders = 'BOTH_ALCOHOL_AND_DRUG_USE_DISORDERS',
  /** Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Drug use disorder */
  DrugUseDisorder = 'DRUG_USE_DISORDER',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** No */
  No = 'NO',
  /** Yes */
  Yes = 'YES',
}

export enum Component {
  /** Client Address input */
  Address = 'ADDRESS',
  /** Display text as an error alert */
  AlertError = 'ALERT_ERROR',
  /** Display text as an info alert */
  AlertInfo = 'ALERT_INFO',
  /** Display text as a success alert */
  AlertSuccess = 'ALERT_SUCCESS',
  /** Display text as a warning alert */
  AlertWarning = 'ALERT_WARNING',
  /** Render a boolean input item as a checkbox */
  Checkbox = 'CHECKBOX',
  /** Specialized component for rendering disabilities in a table */
  DisabilityTable = 'DISABILITY_TABLE',
  /** Render a choice input item as a dropdown */
  Dropdown = 'DROPDOWN',
  /** Email address input for ContactPoint */
  Email = 'EMAIL',
  /** Render a group of inputs horizontally */
  HorizontalGroup = 'HORIZONTAL_GROUP',
  /** Render contents in an info box */
  InfoGroup = 'INFO_GROUP',
  /** Render a group that contains children of the same type (e.g. all booleans) */
  InputGroup = 'INPUT_GROUP',
  /** MCI linking component */
  Mci = 'MCI',
  /** Duration component with hours and minutes, value stored as minutes */
  MinutesDuration = 'MINUTES_DURATION',
  /** Client Name input */
  Name = 'NAME',
  /** Phone number input for ContactPoint */
  Phone = 'PHONE',
  /** Render a choice input item as radio buttons */
  RadioButtons = 'RADIO_BUTTONS',
  /**
   * Render a choice input item as vertical radio buttons
   * @deprecated All radio buttons are now vertical
   */
  RadioButtonsVertical = 'RADIO_BUTTONS_VERTICAL',
  /** Signature input component */
  Signature = 'SIGNATURE',
  /** Signature input component */
  SignatureGroup = 'SIGNATURE_GROUP',
  /** SSN input component */
  Ssn = 'SSN',
  /** Render group as a table. Each child item should be a group representing 1 table row. */
  Table = 'TABLE',
}

export enum CounselingMethod {
  /** Family */
  Family = 'FAMILY',
  /** Group - including peer counseling */
  Group = 'GROUP',
  /** Individual */
  Individual = 'INDIVIDUAL',
}

/** HUD CountExchangeForSex (R15.B) */
export enum CountExchangeForSex {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (1) 1-3 */
  Num_1_3 = 'NUM_1_3',
  /** (2) 4-7 */
  Num_4_7 = 'NUM_4_7',
  /** (3) 8-11 */
  Num_8_11 = 'NUM_8_11',
  /** (4) 12 or more */
  Num_12OrMore = 'NUM_12_OR_MORE',
}

/** Autogenerated return type of CreateClientAlert. */
export type CreateClientAlertPayload = {
  __typename?: 'CreateClientAlertPayload';
  clientAlert?: Maybe<ClientAlert>;
  errors: Array<ValidationError>;
};

/** Autogenerated input type of CreateDirectUpload */
export type CreateDirectUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: DirectUploadInput;
};

/** Autogenerated return type of CreateDuplicateFormDefinition. */
export type CreateDuplicateFormDefinitionPayload = {
  __typename?: 'CreateDuplicateFormDefinitionPayload';
  errors: Array<ValidationError>;
  formIdentifier?: Maybe<FormIdentifier>;
};

/** Autogenerated return type of CreateFormDefinition. */
export type CreateFormDefinitionPayload = {
  __typename?: 'CreateFormDefinitionPayload';
  errors: Array<ValidationError>;
  formDefinition?: Maybe<FormDefinition>;
};

/** Autogenerated input type of CreateFormRule */
export type CreateFormRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  definitionId: Scalars['ID']['input'];
  input: FormRuleInput;
};

/** Autogenerated return type of CreateFormRule. */
export type CreateFormRulePayload = {
  __typename?: 'CreateFormRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  formRule?: Maybe<FormRule>;
};

/** Autogenerated return type of CreateNextDraftFormDefinition. */
export type CreateNextDraftFormDefinitionPayload = {
  __typename?: 'CreateNextDraftFormDefinitionPayload';
  errors: Array<ValidationError>;
  formIdentifier?: Maybe<FormIdentifier>;
};

/** Autogenerated return type of CreateProjectConfig. */
export type CreateProjectConfigPayload = {
  __typename?: 'CreateProjectConfigPayload';
  errors: Array<ValidationError>;
  projectConfig?: Maybe<ProjectConfig>;
};

/** Autogenerated return type of CreateScanCardCode. */
export type CreateScanCardCodePayload = {
  __typename?: 'CreateScanCardCodePayload';
  errors: Array<ValidationError>;
  scanCardCode?: Maybe<ScanCardCode>;
};

/** Autogenerated return type of CreateServiceType. */
export type CreateServiceTypePayload = {
  __typename?: 'CreateServiceTypePayload';
  errors: Array<ValidationError>;
  serviceType?: Maybe<ServiceType>;
};

/** Autogenerated input type of CreateUnits */
export type CreateUnitsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: UnitInput;
};

/** Autogenerated return type of CreateUnits. */
export type CreateUnitsPayload = {
  __typename?: 'CreateUnitsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  units?: Maybe<Array<Unit>>;
};

/** HUD CurrentEdStatus (C3.B) */
export enum CurrentEdStatus {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (1) Pursuing Associate's Degree */
  PursuingAssociateSDegree = 'PURSUING_ASSOCIATE_S_DEGREE',
  /** (0) Pursuing a high school diploma or GED */
  PursuingAHighSchoolDiplomaOrGed = 'PURSUING_A_HIGH_SCHOOL_DIPLOMA_OR_GED',
  /** (2) Pursuing Bachelor's Degree */
  PursuingBachelorSDegree = 'PURSUING_BACHELOR_S_DEGREE',
  /** (3) Pursuing Graduate Degree */
  PursuingGraduateDegree = 'PURSUING_GRADUATE_DEGREE',
  /** (4) Pursuing other post-secondary credential */
  PursuingOtherPostSecondaryCredential = 'PURSUING_OTHER_POST_SECONDARY_CREDENTIAL',
}

/** HUD Current Living Situation */
export type CurrentLivingSituation = {
  __typename?: 'CurrentLivingSituation';
  client: Client;
  clsSubsidyType?: Maybe<RentalSubsidyType>;
  createdBy?: Maybe<ApplicationUser>;
  currentLivingSituation: CurrentLivingSituationOptions;
  customDataElements: Array<CustomDataElement>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  enrollment: Enrollment;
  /** Client Location where the Current Living Situation was recorded */
  geolocation?: Maybe<Geolocation>;
  id: Scalars['ID']['output'];
  informationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  leaseOwn60Day?: Maybe<NoYesReasonsForMissingData>;
  leaveSituation14Days?: Maybe<NoYesReasonsForMissingData>;
  locationDetails?: Maybe<Scalars['String']['output']>;
  movedTwoOrMore?: Maybe<NoYesReasonsForMissingData>;
  resourcesToObtain?: Maybe<NoYesReasonsForMissingData>;
  subsequentResidence?: Maybe<NoYesReasonsForMissingData>;
  user?: Maybe<ApplicationUser>;
  verifiedBy?: Maybe<Scalars['String']['output']>;
  verifiedByProjectId?: Maybe<Scalars['ID']['output']>;
};

/** HUD CurrentLivingSituation (4.12) */
export enum CurrentLivingSituationOptions {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (101) Emergency shelter, including hotel or motel paid for with emergency shelter voucher, or Host Home shelter */
  EmergencyShelterIncludingHotelOrMotelPaidForWithEmergencyShelterVoucherOrHostHomeShelter = 'EMERGENCY_SHELTER_INCLUDING_HOTEL_OR_MOTEL_PAID_FOR_WITH_EMERGENCY_SHELTER_VOUCHER_OR_HOST_HOME_SHELTER',
  /** (215) Foster care home or foster care group home */
  FosterCareHomeOrFosterCareGroupHome = 'FOSTER_CARE_HOME_OR_FOSTER_CARE_GROUP_HOME',
  /** (206) Hospital or other residential non-psychiatric medical facility */
  HospitalOrOtherResidentialNonPsychiatricMedicalFacility = 'HOSPITAL_OR_OTHER_RESIDENTIAL_NON_PSYCHIATRIC_MEDICAL_FACILITY',
  /** (332) Host Home (non-crisis) */
  HostHomeNonCrisis = 'HOST_HOME_NON_CRISIS',
  /** (314) Hotel or motel paid for without emergency shelter voucher */
  HotelOrMotelPaidForWithoutEmergencyShelterVoucher = 'HOTEL_OR_MOTEL_PAID_FOR_WITHOUT_EMERGENCY_SHELTER_VOUCHER',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (207) Jail, prison or juvenile detention facility */
  JailPrisonOrJuvenileDetentionFacility = 'JAIL_PRISON_OR_JUVENILE_DETENTION_FACILITY',
  /** (225) Long-term care facility or nursing home */
  LongTermCareFacilityOrNursingHome = 'LONG_TERM_CARE_FACILITY_OR_NURSING_HOME',
  /** (17) Other */
  Other = 'OTHER',
  /** (411) Owned by client, no ongoing housing subsidy */
  OwnedByClientNoOngoingHousingSubsidy = 'OWNED_BY_CLIENT_NO_ONGOING_HOUSING_SUBSIDY',
  /** (421) Owned by client, with ongoing housing subsidy */
  OwnedByClientWithOngoingHousingSubsidy = 'OWNED_BY_CLIENT_WITH_ONGOING_HOUSING_SUBSIDY',
  /**
   * (116) Place not meant for habitation (e.g., a vehicle, an abandoned building,
   * bus/train/subway station/airport or anywhere outside)
   */
  PlaceNotMeantForHabitation = 'PLACE_NOT_MEANT_FOR_HABITATION',
  /** (204) Psychiatric hospital or other psychiatric facility */
  PsychiatricHospitalOrOtherPsychiatricFacility = 'PSYCHIATRIC_HOSPITAL_OR_OTHER_PSYCHIATRIC_FACILITY',
  /** (410) Rental by client, no ongoing housing subsidy */
  RentalByClientNoOngoingHousingSubsidy = 'RENTAL_BY_CLIENT_NO_ONGOING_HOUSING_SUBSIDY',
  /** (435) Rental by client, with ongoing housing subsidy */
  RentalByClientWithOngoingHousingSubsidy = 'RENTAL_BY_CLIENT_WITH_ONGOING_HOUSING_SUBSIDY',
  /** (329) Residential project or halfway house with no homeless criteria */
  ResidentialProjectOrHalfwayHouseWithNoHomelessCriteria = 'RESIDENTIAL_PROJECT_OR_HALFWAY_HOUSE_WITH_NO_HOMELESS_CRITERIA',
  /** (118) Safe Haven */
  SafeHaven = 'SAFE_HAVEN',
  /** (335) Staying or living in a family member’s room, apartment, or house */
  StayingOrLivingInAFamilyMemberSRoomApartmentOrHouse = 'STAYING_OR_LIVING_IN_A_FAMILY_MEMBER_S_ROOM_APARTMENT_OR_HOUSE',
  /** (336) Staying or living in a friend's room, apartment or house */
  StayingOrLivingInAFriendSRoomApartmentOrHouse = 'STAYING_OR_LIVING_IN_A_FRIEND_S_ROOM_APARTMENT_OR_HOUSE',
  /** (205) Substance abuse treatment facility or detox center */
  SubstanceAbuseTreatmentFacilityOrDetoxCenter = 'SUBSTANCE_ABUSE_TREATMENT_FACILITY_OR_DETOX_CENTER',
  /** (302) Transitional housing for homeless persons (including homeless youth) */
  TransitionalHousingForHomelessPersons = 'TRANSITIONAL_HOUSING_FOR_HOMELESS_PERSONS',
  /** (37) Worker unable to determine */
  WorkerUnableToDetermine = 'WORKER_UNABLE_TO_DETERMINE',
}

export type CurrentLivingSituationsPaginated = {
  __typename?: 'CurrentLivingSituationsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<CurrentLivingSituation>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD CurrentSchoolAttended (C3.2) */
export enum CurrentSchoolAttended {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (2) Currently enrolled and attending regularly (when school or the course is in session) */
  CurrentlyEnrolledAndAttendingRegularly = 'CURRENTLY_ENROLLED_AND_ATTENDING_REGULARLY',
  /** (1) Currently enrolled but NOT attending regularly (when school or the course is in session) */
  CurrentlyEnrolledButNotAttendingRegularly = 'CURRENTLY_ENROLLED_BUT_NOT_ATTENDING_REGULARLY',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) Not currently enrolled in any school or educational course */
  NotCurrentlyEnrolledInAnySchoolOrEducationalCourse = 'NOT_CURRENTLY_ENROLLED_IN_ANY_SCHOOL_OR_EDUCATIONAL_COURSE',
}

/** Case Note */
export type CustomCaseNote = {
  __typename?: 'CustomCaseNote';
  client: Client;
  content: Scalars['String']['output'];
  createdBy?: Maybe<ApplicationUser>;
  customDataElements: Array<CustomDataElement>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  enrollment: Enrollment;
  formDefinitionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  informationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  user?: Maybe<ApplicationUser>;
};

/** HUD Custom Case Note Sorting Options */
export enum CustomCaseNoteSortOption {
  /** Date Created */
  DateCreated = 'DATE_CREATED',
  /** Date Updated */
  DateUpdated = 'DATE_UPDATED',
  /** Information Date */
  InformationDate = 'INFORMATION_DATE',
}

export type CustomCaseNotesPaginated = {
  __typename?: 'CustomCaseNotesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<CustomCaseNote>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type CustomDataElement = {
  __typename?: 'CustomDataElement';
  /** Where to display the custom field in the application */
  displayHooks: Array<DisplayHook>;
  fieldType: CustomDataElementType;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  repeats: Scalars['Boolean']['output'];
  value?: Maybe<CustomDataElementValue>;
  values?: Maybe<Array<CustomDataElementValue>>;
};

/** Allowed values for CustomDataElementDefinition.type */
export enum CustomDataElementType {
  Boolean = 'boolean',
  Date = 'date',
  File = 'file',
  Float = 'float',
  Integer = 'integer',
  Json = 'json',
  String = 'string',
  Text = 'text',
}

export type CustomDataElementValue = {
  __typename?: 'CustomDataElementValue';
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  user?: Maybe<ApplicationUser>;
  valueBoolean?: Maybe<Scalars['Boolean']['output']>;
  valueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  valueFile?: Maybe<File>;
  valueFloat?: Maybe<Scalars['Float']['output']>;
  valueInteger?: Maybe<Scalars['Int']['output']>;
  valueJson?: Maybe<Scalars['JsonObject']['output']>;
  valueString?: Maybe<Scalars['String']['output']>;
  valueText?: Maybe<Scalars['String']['output']>;
};

/** HUD DOBDataQuality (3.03.2) */
export enum DobDataQuality {
  /** (2) Approximate or partial DOB reported */
  ApproximateOrPartialDobReported = 'APPROXIMATE_OR_PARTIAL_DOB_REPORTED',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (1) Full DOB reported */
  FullDobReported = 'FULL_DOB_REPORTED',
  /** Invalid Value */
  Invalid = 'INVALID',
}

export enum DataCollectedAbout {
  /** All Clients */
  AllClients = 'ALL_CLIENTS',
  /** All Veterans */
  AllVeterans = 'ALL_VETERANS',
  /** HoH */
  Hoh = 'HOH',
  /** HoH and Adults */
  HohAndAdults = 'HOH_AND_ADULTS',
  /** Veteran HoH */
  VeteranHoh = 'VETERAN_HOH',
}

export type DataCollectionFeature = {
  __typename?: 'DataCollectionFeature';
  dataCollectedAbout: DataCollectedAbout;
  id: Scalars['ID']['output'];
  legacy: Scalars['Boolean']['output'];
  role: DataCollectionFeatureRole;
};

export enum DataCollectionFeatureRole {
  /** Case note */
  CaseNote = 'CASE_NOTE',
  /** CE assessment */
  CeAssessment = 'CE_ASSESSMENT',
  /** CE event */
  CeEvent = 'CE_EVENT',
  /** Current living situation */
  CurrentLivingSituation = 'CURRENT_LIVING_SITUATION',
  /** External form */
  ExternalForm = 'EXTERNAL_FORM',
  /** Referral */
  Referral = 'REFERRAL',
  /** Referral request */
  ReferralRequest = 'REFERRAL_REQUEST',
  /** Service */
  Service = 'SERVICE',
}

/** HUD DataCollectionStage (5.03.1) */
export enum DataCollectionStage {
  /** (5) Annual assessment */
  AnnualAssessment = 'ANNUAL_ASSESSMENT',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (6) Post-exit */
  PostExit = 'POST_EXIT',
  /** (1) Project entry */
  ProjectEntry = 'PROJECT_ENTRY',
  /** (3) Project exit */
  ProjectExit = 'PROJECT_EXIT',
  /** (2) Update */
  Update = 'UPDATE',
}

/** Autogenerated input type of DeleteAssessment */
export type DeleteAssessmentInput = {
  assessmentLockVersion?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAssessment. */
export type DeleteAssessmentPayload = {
  __typename?: 'DeleteAssessmentPayload';
  assessmentId?: Maybe<Scalars['ID']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** Autogenerated input type of DeleteCeAssessment */
export type DeleteCeAssessmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteCeAssessment. */
export type DeleteCeAssessmentPayload = {
  __typename?: 'DeleteCeAssessmentPayload';
  ceAssessment?: Maybe<CeAssessment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** Autogenerated input type of DeleteCeEvent */
export type DeleteCeEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteCeEvent. */
export type DeleteCeEventPayload = {
  __typename?: 'DeleteCeEventPayload';
  ceEvent?: Maybe<Event>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** Autogenerated input type of DeleteCeParticipation */
export type DeleteCeParticipationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteCeParticipation. */
export type DeleteCeParticipationPayload = {
  __typename?: 'DeleteCeParticipationPayload';
  ceParticipation?: Maybe<CeParticipation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of DeleteClientAlert. */
export type DeleteClientAlertPayload = {
  __typename?: 'DeleteClientAlertPayload';
  clientAlert?: Maybe<ClientAlert>;
  errors: Array<ValidationError>;
};

/** Autogenerated input type of DeleteClientFile */
export type DeleteClientFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteClientFile. */
export type DeleteClientFilePayload = {
  __typename?: 'DeleteClientFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  file?: Maybe<File>;
};

/** Autogenerated input type of DeleteClientImage */
export type DeleteClientImageInput = {
  clientId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteClientImage. */
export type DeleteClientImagePayload = {
  __typename?: 'DeleteClientImagePayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** Autogenerated input type of DeleteClient */
export type DeleteClientInput = {
  clientLockVersion?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether warnings have been confirmed */
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteClient. */
export type DeleteClientPayload = {
  __typename?: 'DeleteClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** Autogenerated input type of DeleteCurrentLivingSituation */
export type DeleteCurrentLivingSituationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteCurrentLivingSituation. */
export type DeleteCurrentLivingSituationPayload = {
  __typename?: 'DeleteCurrentLivingSituationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  currentLivingSituation?: Maybe<CurrentLivingSituation>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of DeleteCustomCaseNote. */
export type DeleteCustomCaseNotePayload = {
  __typename?: 'DeleteCustomCaseNotePayload';
  customCaseNote?: Maybe<CustomCaseNote>;
  errors: Array<ValidationError>;
};

/** Autogenerated input type of DeleteEnrollment */
export type DeleteEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteEnrollment. */
export type DeleteEnrollmentPayload = {
  __typename?: 'DeleteEnrollmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enrollment?: Maybe<Enrollment>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of DeleteExternalFormSubmission. */
export type DeleteExternalFormSubmissionPayload = {
  __typename?: 'DeleteExternalFormSubmissionPayload';
  errors: Array<ValidationError>;
  externalFormSubmission?: Maybe<ExternalFormSubmission>;
};

/** Autogenerated return type of DeleteFormDefinition. */
export type DeleteFormDefinitionPayload = {
  __typename?: 'DeleteFormDefinitionPayload';
  errors: Array<ValidationError>;
  formDefinition?: Maybe<FormDefinition>;
};

/** Autogenerated return type of DeleteFormRule. */
export type DeleteFormRulePayload = {
  __typename?: 'DeleteFormRulePayload';
  errors: Array<ValidationError>;
  formRule?: Maybe<FormRule>;
};

/** Autogenerated input type of DeleteFunder */
export type DeleteFunderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteFunder. */
export type DeleteFunderPayload = {
  __typename?: 'DeleteFunderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  funder?: Maybe<Funder>;
};

/** Autogenerated input type of DeleteHmisParticipation */
export type DeleteHmisParticipationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteHmisParticipation. */
export type DeleteHmisParticipationPayload = {
  __typename?: 'DeleteHmisParticipationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  hmisParticipation?: Maybe<HmisParticipation>;
};

/** Autogenerated input type of DeleteInventory */
export type DeleteInventoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteInventory. */
export type DeleteInventoryPayload = {
  __typename?: 'DeleteInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  inventory?: Maybe<Inventory>;
};

/** Autogenerated input type of DeleteOrganization */
export type DeleteOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteOrganization. */
export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of DeleteProjectCoc */
export type DeleteProjectCocInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteProjectCoc. */
export type DeleteProjectCocPayload = {
  __typename?: 'DeleteProjectCocPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  projectCoc?: Maybe<ProjectCoc>;
};

/** Autogenerated return type of DeleteProjectConfig. */
export type DeleteProjectConfigPayload = {
  __typename?: 'DeleteProjectConfigPayload';
  errors: Array<ValidationError>;
  projectConfig?: Maybe<ProjectConfig>;
};

/** Autogenerated input type of DeleteProject */
export type DeleteProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteProject. */
export type DeleteProjectPayload = {
  __typename?: 'DeleteProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  project?: Maybe<Project>;
};

/** Autogenerated return type of DeleteScanCardCode. */
export type DeleteScanCardCodePayload = {
  __typename?: 'DeleteScanCardCodePayload';
  errors: Array<ValidationError>;
  scanCardCode?: Maybe<ScanCardCode>;
};

/** Autogenerated input type of DeleteService */
export type DeleteServiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteService. */
export type DeleteServicePayload = {
  __typename?: 'DeleteServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  service?: Maybe<Service>;
};

/** Autogenerated return type of DeleteServiceType. */
export type DeleteServiceTypePayload = {
  __typename?: 'DeleteServiceTypePayload';
  errors: Array<ValidationError>;
  serviceType?: Maybe<ServiceType>;
};

/** Autogenerated input type of DeleteUnits */
export type DeleteUnitsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  unitIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of DeleteUnits. */
export type DeleteUnitsPayload = {
  __typename?: 'DeleteUnitsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  unitIds?: Maybe<Array<Scalars['ID']['output']>>;
};

/** HUD DependentUnder6 (V7.O) */
export enum DependentUnder6 {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) No */
  No = 'NO',
  /** (2) Youngest child is 1 to 6 years old and/or one or more children (any age) require significant care */
  YoungestChildIs_1To_6YearsOldAndOrOneOrMoreChildrenAnyAgeRequireSignificantCare = 'YOUNGEST_CHILD_IS_1_TO_6_YEARS_OLD_AND_OR_ONE_OR_MORE_CHILDREN_ANY_AGE_REQUIRE_SIGNIFICANT_CARE',
  /** (1) Youngest child is under 1 year old */
  YoungestChildIsUnder_1YearOld = 'YOUNGEST_CHILD_IS_UNDER_1_YEAR_OLD',
}

/** HUD Destination (3.12) */
export enum Destination {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (24) Deceased */
  Deceased = 'DECEASED',
  /** (101) Emergency shelter, including hotel or motel paid for with emergency shelter voucher, or Host Home shelter */
  EmergencyShelterIncludingHotelOrMotelPaidForWithEmergencyShelterVoucherOrHostHomeShelter = 'EMERGENCY_SHELTER_INCLUDING_HOTEL_OR_MOTEL_PAID_FOR_WITH_EMERGENCY_SHELTER_VOUCHER_OR_HOST_HOME_SHELTER',
  /** (215) Foster care home or foster care group home */
  FosterCareHomeOrFosterCareGroupHome = 'FOSTER_CARE_HOME_OR_FOSTER_CARE_GROUP_HOME',
  /** (206) Hospital or other residential non-psychiatric medical facility */
  HospitalOrOtherResidentialNonPsychiatricMedicalFacility = 'HOSPITAL_OR_OTHER_RESIDENTIAL_NON_PSYCHIATRIC_MEDICAL_FACILITY',
  /** (332) Host Home (non-crisis) */
  HostHomeNonCrisis = 'HOST_HOME_NON_CRISIS',
  /** (314) Hotel or motel paid for without emergency shelter voucher */
  HotelOrMotelPaidForWithoutEmergencyShelterVoucher = 'HOTEL_OR_MOTEL_PAID_FOR_WITHOUT_EMERGENCY_SHELTER_VOUCHER',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (207) Jail, prison or juvenile detention facility */
  JailPrisonOrJuvenileDetentionFacility = 'JAIL_PRISON_OR_JUVENILE_DETENTION_FACILITY',
  /** (225) Long-term care facility or nursing home */
  LongTermCareFacilityOrNursingHome = 'LONG_TERM_CARE_FACILITY_OR_NURSING_HOME',
  /** (426) Moved from one HOPWA funded project to HOPWA PH */
  MovedFromOneHopwaFundedProjectToHopwaPh = 'MOVED_FROM_ONE_HOPWA_FUNDED_PROJECT_TO_HOPWA_PH',
  /** (327) Moved from one HOPWA funded project to HOPWA TH */
  MovedFromOneHopwaFundedProjectToHopwaTh = 'MOVED_FROM_ONE_HOPWA_FUNDED_PROJECT_TO_HOPWA_TH',
  /** (30) No exit interview completed */
  NoExitInterviewCompleted = 'NO_EXIT_INTERVIEW_COMPLETED',
  /** (17) Other */
  Other = 'OTHER',
  /** (411) Owned by client, no ongoing housing subsidy */
  OwnedByClientNoOngoingHousingSubsidy = 'OWNED_BY_CLIENT_NO_ONGOING_HOUSING_SUBSIDY',
  /** (421) Owned by client, with ongoing housing subsidy */
  OwnedByClientWithOngoingHousingSubsidy = 'OWNED_BY_CLIENT_WITH_ONGOING_HOUSING_SUBSIDY',
  /**
   * (116) Place not meant for habitation (e.g., a vehicle, an abandoned building,
   * bus/train/subway station/airport or anywhere outside)
   */
  PlaceNotMeantForHabitation = 'PLACE_NOT_MEANT_FOR_HABITATION',
  /** (204) Psychiatric hospital or other psychiatric facility */
  PsychiatricHospitalOrOtherPsychiatricFacility = 'PSYCHIATRIC_HOSPITAL_OR_OTHER_PSYCHIATRIC_FACILITY',
  /** (410) Rental by client, no ongoing housing subsidy */
  RentalByClientNoOngoingHousingSubsidy = 'RENTAL_BY_CLIENT_NO_ONGOING_HOUSING_SUBSIDY',
  /** (435) Rental by client, with ongoing housing subsidy */
  RentalByClientWithOngoingHousingSubsidy = 'RENTAL_BY_CLIENT_WITH_ONGOING_HOUSING_SUBSIDY',
  /** (329) Residential project or halfway house with no homeless criteria */
  ResidentialProjectOrHalfwayHouseWithNoHomelessCriteria = 'RESIDENTIAL_PROJECT_OR_HALFWAY_HOUSE_WITH_NO_HOMELESS_CRITERIA',
  /** (118) Safe Haven */
  SafeHaven = 'SAFE_HAVEN',
  /** (422) Staying or living with family, permanent tenure */
  StayingOrLivingWithFamilyPermanentTenure = 'STAYING_OR_LIVING_WITH_FAMILY_PERMANENT_TENURE',
  /** (312) Staying or living with family, temporary tenure (e.g. room, apartment or house) */
  StayingOrLivingWithFamilyTemporaryTenure = 'STAYING_OR_LIVING_WITH_FAMILY_TEMPORARY_TENURE',
  /** (423) Staying or living with friends, permanent tenure */
  StayingOrLivingWithFriendsPermanentTenure = 'STAYING_OR_LIVING_WITH_FRIENDS_PERMANENT_TENURE',
  /** (313) Staying or living with friends, temporary tenure (e.g. room apartment or house) */
  StayingOrLivingWithFriendsTemporaryTenure = 'STAYING_OR_LIVING_WITH_FRIENDS_TEMPORARY_TENURE',
  /** (205) Substance abuse treatment facility or detox center */
  SubstanceAbuseTreatmentFacilityOrDetoxCenter = 'SUBSTANCE_ABUSE_TREATMENT_FACILITY_OR_DETOX_CENTER',
  /** (302) Transitional housing for homeless persons (including homeless youth) */
  TransitionalHousingForHomelessPersons = 'TRANSITIONAL_HOUSING_FOR_HOMELESS_PERSONS',
}

/** Represents direct upload credentials */
export type DirectUpload = {
  __typename?: 'DirectUpload';
  filename: Scalars['String']['output'];
  /** HTTP request headers (JSON-encoded) */
  headers: Scalars['String']['output'];
  /** Created blob record signed ID */
  signedBlobId: Scalars['ID']['output'];
  /** Upload URL */
  url: Scalars['String']['output'];
};

/** File information required to prepare a direct upload */
export type DirectUploadInput = {
  /** File size (bytes) */
  byteSize: Scalars['Int']['input'];
  /** MD5 file checksum as base64 */
  checksum: Scalars['String']['input'];
  /** File content type */
  contentType: Scalars['String']['input'];
  /** Original file name */
  filename: Scalars['String']['input'];
};

export type DisabilitiesPaginated = {
  __typename?: 'DisabilitiesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Disability>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type Disability = {
  __typename?: 'Disability';
  antiRetroviral?: Maybe<NoYesReasonsForMissingData>;
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  disabilityResponse: CompleteDisabilityResponse;
  disabilityType: DisabilityType;
  enrollment: Enrollment;
  id: Scalars['ID']['output'];
  indefiniteAndImpairs?: Maybe<NoYesReasonsForMissingData>;
  informationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  tCellCount?: Maybe<Scalars['Int']['output']>;
  tCellCountAvailable?: Maybe<NoYesReasonsForMissingData>;
  tCellSource?: Maybe<TCellSourceViralLoadSource>;
  user?: Maybe<ApplicationUser>;
  viralLoad?: Maybe<Scalars['Int']['output']>;
  viralLoadAvailable?: Maybe<ViralLoadAvailable>;
};

/** Group of disability records that were collected at the same time */
export type DisabilityGroup = {
  __typename?: 'DisabilityGroup';
  antiRetroviral?: Maybe<NoYesReasonsForMissingData>;
  chronicHealthCondition?: Maybe<NoYesReasonsForMissingData>;
  chronicHealthConditionIndefiniteAndImpairs?: Maybe<NoYesReasonsForMissingData>;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  developmentalDisability?: Maybe<NoYesReasonsForMissingData>;
  /** Current disabling condition on the linked Enrollment. It may not match up with the disabilities specified in this group. */
  disablingCondition: NoYesReasonsForMissingData;
  enrollment: Enrollment;
  hivAids?: Maybe<NoYesReasonsForMissingData>;
  /** Concatenated string of Disability record IDs */
  id: Scalars['ID']['output'];
  informationDate: Scalars['ISO8601Date']['output'];
  mentalHealthDisorder?: Maybe<NoYesReasonsForMissingData>;
  mentalHealthDisorderIndefiniteAndImpairs?: Maybe<NoYesReasonsForMissingData>;
  physicalDisability?: Maybe<NoYesReasonsForMissingData>;
  physicalDisabilityIndefiniteAndImpairs?: Maybe<NoYesReasonsForMissingData>;
  substanceUseDisorder?: Maybe<DisabilityResponse>;
  substanceUseDisorderIndefiniteAndImpairs?: Maybe<NoYesReasonsForMissingData>;
  tCellCount?: Maybe<Scalars['Int']['output']>;
  tCellCountAvailable?: Maybe<NoYesReasonsForMissingData>;
  tCellSource?: Maybe<TCellSourceViralLoadSource>;
  user?: Maybe<ApplicationUser>;
  viralLoad?: Maybe<Scalars['Int']['output']>;
  viralLoadAvailable?: Maybe<ViralLoadAvailable>;
  viralLoadSource?: Maybe<TCellSourceViralLoadSource>;
};

/** HUD DisabilityResponse (4.10.2) */
export enum DisabilityResponse {
  /** (1) Alcohol use disorder */
  AlcoholUseDisorder = 'ALCOHOL_USE_DISORDER',
  /** (3) Both alcohol and drug use disorders */
  BothAlcoholAndDrugUseDisorders = 'BOTH_ALCOHOL_AND_DRUG_USE_DISORDERS',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (2) Drug use disorder */
  DrugUseDisorder = 'DRUG_USE_DISORDER',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) No */
  No = 'NO',
}

/** HUD DisabilityType (1.3) */
export enum DisabilityType {
  /** (7) Chronic health condition */
  ChronicHealthCondition = 'CHRONIC_HEALTH_CONDITION',
  /** (6) Developmental disability */
  DevelopmentalDisability = 'DEVELOPMENTAL_DISABILITY',
  /** (8) HIV/AIDS */
  HivAids = 'HIV_AIDS',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (9) Mental health disorder */
  MentalHealthDisorder = 'MENTAL_HEALTH_DISORDER',
  /** (5) Physical disability */
  PhysicalDisability = 'PHYSICAL_DISABILITY',
  /** (10) Substance use disorder */
  SubstanceUseDisorder = 'SUBSTANCE_USE_DISORDER',
}

export enum DisabledDisplay {
  Hidden = 'HIDDEN',
  Protected = 'PROTECTED',
  ProtectedWithValue = 'PROTECTED_WITH_VALUE',
}

/** HUD DischargeStatus (V1.12) */
export enum DischargeStatus {
  /** (4) Bad conduct */
  BadConduct = 'BAD_CONDUCT',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (5) Dishonorable */
  Dishonorable = 'DISHONORABLE',
  /** (2) General under honorable conditions */
  GeneralUnderHonorableConditions = 'GENERAL_UNDER_HONORABLE_CONDITIONS',
  /** (1) Honorable */
  Honorable = 'HONORABLE',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (7) Uncharacterized */
  Uncharacterized = 'UNCHARACTERIZED',
  /** (6) Under other than honorable conditions (OTH) */
  UnderOtherThanHonorableConditionsOth = 'UNDER_OTHER_THAN_HONORABLE_CONDITIONS_OTH',
}

/** Application for displaying Custom Data Element values */
export enum DisplayHook {
  /** Display value as a column when viewing a table of records (e.g. Current Living Situations) */
  TableSummary = 'TABLE_SUMMARY',
}

/** HUD Employment Education */
export type EmploymentEducation = {
  __typename?: 'EmploymentEducation';
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  employed?: Maybe<NoYesReasonsForMissingData>;
  employmentType?: Maybe<EmploymentType>;
  enrollment: Enrollment;
  id: Scalars['ID']['output'];
  informationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  lastGradeCompleted?: Maybe<LastGradeCompleted>;
  notEmployedReason?: Maybe<NotEmployedReason>;
  schoolStatus?: Maybe<SchoolStatus>;
  user?: Maybe<ApplicationUser>;
};

export type EmploymentEducationsPaginated = {
  __typename?: 'EmploymentEducationsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<EmploymentEducation>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD EmploymentType (R6.A) */
export enum EmploymentType {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (1) Full-time */
  FullTime = 'FULL_TIME',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) Part-time */
  PartTime = 'PART_TIME',
  /** (3) Seasonal / sporadic (including day labor) */
  SeasonalSporadic = 'SEASONAL_SPORADIC',
}

export enum EnableBehavior {
  All = 'ALL',
  Any = 'ANY',
}

export enum EnableOperator {
  /** Use with answerBoolean to specify is the item should be enabled or not. */
  Enabled = 'ENABLED',
  Equal = 'EQUAL',
  /** Use with answerBoolean to specify if an answer should exist or not. */
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEqual = 'GREATER_THAN_EQUAL',
  /** Whether the value is in the answerCodes array. */
  In = 'IN',
  /** Whether the value includes the given item */
  Includes = 'INCLUDES',
  LessThan = 'LESS_THAN',
  LessThanEqual = 'LESS_THAN_EQUAL',
  NotEqual = 'NOT_EQUAL',
}

export type EnableWhen = {
  __typename?: 'EnableWhen';
  /** If question is boolean type, value for comparison */
  answerBoolean?: Maybe<Scalars['Boolean']['output']>;
  /** If question is choice type, value for comparison */
  answerCode?: Maybe<Scalars['String']['output']>;
  /** If question is choice type, and operator is IN, values for comparison */
  answerCodes?: Maybe<Array<Scalars['String']['output']>>;
  /** If question is date type, value for comparison */
  answerDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** If question is choice type and has grouped options, value for comparison */
  answerGroupCode?: Maybe<Scalars['String']['output']>;
  /** If question is numeric, value for comparison */
  answerNumber?: Maybe<Scalars['Int']['output']>;
  /** The linkId of a question to compare with the question using the operator */
  compareQuestion?: Maybe<Scalars['String']['output']>;
  /** The Local Constant that determines whether item is enabled/disabled */
  localConstant?: Maybe<Scalars['String']['output']>;
  /** How to evaluate the question's answer */
  operator: EnableOperator;
  /** The linkId of question that determines whether item is enabled/disabled */
  question?: Maybe<Scalars['String']['output']>;
};

/** HUD Enrollment */
export type Enrollment = {
  __typename?: 'Enrollment';
  access: EnrollmentAccess;
  alcoholDrugUseDisorderFam?: Maybe<NoYesMissing>;
  annualPercentAmi?: Maybe<AnnualPercentAmi>;
  assessmentEligibilities: Array<AssessmentEligibility>;
  assessments: AssessmentsPaginated;
  auditHistory: EnrollmentAuditEventsPaginated;
  autoExited: Scalars['Boolean']['output'];
  ceAssessments: CeAssessmentsPaginated;
  childWelfareMonths?: Maybe<Scalars['Int']['output']>;
  childWelfareYears?: Maybe<RhyNumberofYears>;
  client: Client;
  clientEnrolledInPath?: Maybe<NoYesMissing>;
  clientLeaseholder?: Maybe<NoYesMissing>;
  cocPrioritized?: Maybe<NoYesMissing>;
  countOutreachReferralApproaches?: Maybe<Scalars['Int']['output']>;
  createdBy?: Maybe<ApplicationUser>;
  criminalRecord?: Maybe<NoYesMissing>;
  currentLivingSituations: CurrentLivingSituationsPaginated;
  currentPregnant?: Maybe<NoYesMissing>;
  currentUnit?: Maybe<Unit>;
  customCaseNotes: CustomCaseNotesPaginated;
  customDataElements: Array<CustomDataElement>;
  /** Data collection features that are enabled for this Enrollment (e.g. Current Living Situations, Events) */
  dataCollectionFeatures: Array<DataCollectionFeature>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateOfBcpStatus?: Maybe<Scalars['ISO8601Date']['output']>;
  dateOfEngagement?: Maybe<Scalars['ISO8601Date']['output']>;
  dateOfPathStatus?: Maybe<Scalars['ISO8601Date']['output']>;
  dateToStreetEssh?: Maybe<Scalars['ISO8601Date']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dependentUnder6?: Maybe<DependentUnder6>;
  disabilities: DisabilitiesPaginated;
  disabledHoh?: Maybe<NoYesMissing>;
  disablingCondition?: Maybe<NoYesReasonsForMissingData>;
  eligibleForRhy?: Maybe<NoYesMissing>;
  employmentEducations: EmploymentEducationsPaginated;
  enrollmentCoc?: Maybe<Scalars['String']['output']>;
  entryDate: Scalars['ISO8601Date']['output'];
  events: EventsPaginated;
  evictionHistory?: Maybe<EvictionHistory>;
  exitAssessment?: Maybe<Assessment>;
  exitDate?: Maybe<Scalars['ISO8601Date']['output']>;
  exitDestination?: Maybe<Destination>;
  files: FilesPaginated;
  formerWardChildWelfare?: Maybe<NoYesReasonsForMissingData>;
  formerWardJuvenileJustice?: Maybe<NoYesReasonsForMissingData>;
  /** Client Locations that have been collected during this Enrollment */
  geolocations: Array<Geolocation>;
  healthAndDvs: HealthAndDvsPaginated;
  hh5Plus?: Maybe<NoYesMissing>;
  hohLeaseholder?: Maybe<NoYesMissing>;
  household: Household;
  householdId: Scalars['ID']['output'];
  householdShortId: Scalars['ID']['output'];
  householdSize: Scalars['Int']['output'];
  hpScreeningScore?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  inProgress: Scalars['Boolean']['output'];
  incarceratedAdult?: Maybe<IncarceratedAdult>;
  incarceratedParent?: Maybe<NoYesMissing>;
  incomeBenefits: IncomeBenefitsPaginated;
  insufficientIncome?: Maybe<NoYesMissing>;
  intakeAssessment?: Maybe<Assessment>;
  juvenileJusticeMonths?: Maybe<Scalars['Int']['output']>;
  juvenileJusticeYears?: Maybe<RhyNumberofYears>;
  lastBedNightDate?: Maybe<Scalars['ISO8601Date']['output']>;
  lastCurrentLivingSituation?: Maybe<CurrentLivingSituation>;
  lastServiceDate?: Maybe<Scalars['ISO8601Date']['output']>;
  lengthOfStay?: Maybe<ResidencePriorLengthOfStay>;
  literalHomelessHistory?: Maybe<LiteralHomelessHistory>;
  livingSituation?: Maybe<PriorLivingSituation>;
  lockVersion: Scalars['Int']['output'];
  losUnderThreshold?: Maybe<NoYesMissing>;
  mentalHealthDisorderFam?: Maybe<NoYesMissing>;
  monthsHomelessPastThreeYears?: Maybe<MonthsHomelessPastThreeYears>;
  moveInAddresses: Array<ClientAddress>;
  moveInDate?: Maybe<Scalars['ISO8601Date']['output']>;
  numUnitsAssignedToHousehold: Scalars['Int']['output'];
  /** Forms for individual data elements that are collected at occurrence for this Enrollment (e.g. Move-In Date) */
  occurrencePointForms: Array<OccurrencePointForm>;
  openEnrollmentSummary: Array<EnrollmentSummary>;
  organizationName: Scalars['String']['output'];
  percentAmi?: Maybe<PercentAmi>;
  physicalDisabilityFam?: Maybe<NoYesMissing>;
  preferredLanguage?: Maybe<PreferredLanguage>;
  preferredLanguageDifferent?: Maybe<Scalars['String']['output']>;
  previousStreetEssh?: Maybe<NoYesMissing>;
  prisonDischarge?: Maybe<NoYesMissing>;
  project: Project;
  projectName: Scalars['String']['output'];
  projectType?: Maybe<ProjectType>;
  reasonNoServices?: Maybe<ReasonNoServices>;
  reasonNotEnrolled?: Maybe<ReasonNotEnrolled>;
  referralSource?: Maybe<ReferralSource>;
  relationshipToHoH: RelationshipToHoH;
  reminders: Array<Reminder>;
  rentalSubsidyType?: Maybe<RentalSubsidyType>;
  runawayYouth?: Maybe<NoYesReasonsForMissingData>;
  services: ServicesPaginated;
  sexOffender?: Maybe<NoYesMissing>;
  sexualOrientation?: Maybe<SexualOrientation>;
  sexualOrientationOther?: Maybe<Scalars['String']['output']>;
  singleParent?: Maybe<NoYesMissing>;
  /** Present if this household was enrolled as the result of a referral from another project. */
  sourceReferralPosting?: Maybe<ReferralPosting>;
  status: EnrollmentStatus;
  subsidyAtRisk?: Maybe<NoYesMissing>;
  targetScreenReqd?: Maybe<NoYesMissing>;
  thresholdScore?: Maybe<Scalars['Int']['output']>;
  timeToHousingLoss?: Maybe<TimeToHousingLoss>;
  timesHomelessPastThreeYears?: Maybe<TimesHomelessPastThreeYears>;
  translationNeeded?: Maybe<NoYesReasonsForMissingData>;
  unemploymentFam?: Maybe<NoYesMissing>;
  user?: Maybe<ApplicationUser>;
  vamcStation?: Maybe<VamcStationNumber>;
  youthEducationStatuses: YouthEducationStatusesPaginated;
};

/** HUD Enrollment */
export type EnrollmentAssessmentsArgs = {
  filters?: InputMaybe<AssessmentsForEnrollmentFilterOptions>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<AssessmentSortOption>;
};

/** HUD Enrollment */
export type EnrollmentAuditHistoryArgs = {
  filters?: InputMaybe<EnrollmentAuditEventFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Enrollment */
export type EnrollmentCeAssessmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<AssessmentSortOption>;
};

/** HUD Enrollment */
export type EnrollmentCurrentLivingSituationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Enrollment */
export type EnrollmentCustomCaseNotesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<CustomCaseNoteSortOption>;
};

/** HUD Enrollment */
export type EnrollmentDisabilitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Enrollment */
export type EnrollmentEmploymentEducationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Enrollment */
export type EnrollmentEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<EventSortOption>;
};

/** HUD Enrollment */
export type EnrollmentFilesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<FileSortOption>;
};

/** HUD Enrollment */
export type EnrollmentHealthAndDvsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Enrollment */
export type EnrollmentIncomeBenefitsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Enrollment */
export type EnrollmentLastServiceDateArgs = {
  serviceTypeId: Scalars['ID']['input'];
};

/** HUD Enrollment */
export type EnrollmentServicesArgs = {
  filters?: InputMaybe<ServicesForEnrollmentFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ServiceSortOption>;
};

/** HUD Enrollment */
export type EnrollmentYouthEducationStatusesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type EnrollmentAccess = {
  __typename?: 'EnrollmentAccess';
  canAuditEnrollments: Scalars['Boolean']['output'];
  canDeleteEnrollments: Scalars['Boolean']['output'];
  canEditEnrollments: Scalars['Boolean']['output'];
  canSplitHouseholds: Scalars['Boolean']['output'];
  canViewEnrollmentDetails: Scalars['Boolean']['output'];
  canViewEnrollmentLocationMap: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type EnrollmentAccessSummariesPaginated = {
  __typename?: 'EnrollmentAccessSummariesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<EnrollmentAccessSummary>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type EnrollmentAccessSummary = {
  __typename?: 'EnrollmentAccessSummary';
  clientId?: Maybe<Scalars['ID']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  enrollmentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastAccessedAt: Scalars['ISO8601DateTime']['output'];
  projectId?: Maybe<Scalars['ID']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
};

export type EnrollmentAccessSummaryFilterOptions = {
  onOrAfter?: InputMaybe<Scalars['ISO8601Date']['input']>;
  project?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type EnrollmentAuditEvent = {
  __typename?: 'EnrollmentAuditEvent';
  clientId?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  enrollmentId?: Maybe<Scalars['String']['output']>;
  event: AuditEventType;
  graphqlType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Format is { field: { fieldName: "GQL field name", displayName: "Human readable name", values: [old, new] } } */
  objectChanges?: Maybe<Scalars['JsonObject']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  recordId: Scalars['ID']['output'];
  recordName: Scalars['String']['output'];
  trueUser?: Maybe<ApplicationUser>;
  user?: Maybe<ApplicationUser>;
};

export type EnrollmentAuditEventFilterOptions = {
  enrollmentRecordType?: InputMaybe<Array<Scalars['ID']['input']>>;
  user?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type EnrollmentAuditEventsPaginated = {
  __typename?: 'EnrollmentAuditEventsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<EnrollmentAuditEvent>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum EnrollmentFilterOptionHouseholdTask {
  /** Annual Due */
  AnnualDue = 'ANNUAL_DUE',
}

export enum EnrollmentFilterOptionStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Exited */
  Exited = 'EXITED',
  /** Incomplete */
  Incomplete = 'INCOMPLETE',
}

export type EnrollmentRelationshipInput = {
  enrollmentId: Scalars['ID']['input'];
  relationshipToHoh: RelationshipToHoH;
};

/** HUD Enrollment Sorting Options */
export enum EnrollmentSortOption {
  /** Age: Oldest to Youngest */
  AgeOldestToYoungest = 'AGE_OLDEST_TO_YOUNGEST',
  /** Age: Youngest to Oldest */
  AgeYoungestToOldest = 'AGE_YOUNGEST_TO_OLDEST',
  /** First Name: A-Z */
  FirstNameAToZ = 'FIRST_NAME_A_TO_Z',
  /** First Name: Z-A */
  FirstNameZToA = 'FIRST_NAME_Z_TO_A',
  /** Household ID */
  HouseholdId = 'HOUSEHOLD_ID',
  /** Last Name: A-Z */
  LastNameAToZ = 'LAST_NAME_A_TO_Z',
  /** Last Name: Z-A */
  LastNameZToA = 'LAST_NAME_Z_TO_A',
  /** Most Recent */
  MostRecent = 'MOST_RECENT',
}

/** Status of this enrollment */
export enum EnrollmentStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Household Entry Incomplete */
  AnyEntryIncomplete = 'ANY_ENTRY_INCOMPLETE',
  /** Household Exit Incomplete */
  AnyExitIncomplete = 'ANY_EXIT_INCOMPLETE',
  /** Exited */
  Exited = 'EXITED',
  /** Entry Incomplete */
  OwnEntryIncomplete = 'OWN_ENTRY_INCOMPLETE',
  /** Exit Incomplete */
  OwnExitIncomplete = 'OWN_EXIT_INCOMPLETE',
}

export type EnrollmentSummary = {
  __typename?: 'EnrollmentSummary';
  canViewEnrollment: Scalars['Boolean']['output'];
  entryDate: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  inProgress: Scalars['Boolean']['output'];
  moveInDate?: Maybe<Scalars['ISO8601Date']['output']>;
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  projectType: ProjectType;
};

export type EnrollmentsForClientFilterOptions = {
  householdTasks?: InputMaybe<Array<EnrollmentFilterOptionHouseholdTask>>;
  openOnDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  projectType?: InputMaybe<Array<ProjectType>>;
  status?: InputMaybe<Array<EnrollmentFilterOptionStatus>>;
};

export type EnrollmentsForProjectFilterOptions = {
  assignedStaff?: InputMaybe<Scalars['ID']['input']>;
  bedNightOnDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  householdTasks?: InputMaybe<Array<EnrollmentFilterOptionHouseholdTask>>;
  openOnDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<EnrollmentFilterOptionStatus>>;
};

export type EnrollmentsPaginated = {
  __typename?: 'EnrollmentsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Enrollment>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** AC ESG Funding Service */
export type EsgFundingService = {
  __typename?: 'EsgFundingService';
  clientDob?: Maybe<Scalars['ISO8601Date']['output']>;
  clientId: Scalars['ID']['output'];
  customDataElements: Array<CustomDataElement>;
  dateProvided: Scalars['ISO8601Date']['output'];
  faAmount?: Maybe<Scalars['Float']['output']>;
  faEndDate?: Maybe<Scalars['ISO8601Date']['output']>;
  faStartDate?: Maybe<Scalars['ISO8601Date']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mciIds: Array<ExternalIdentifier>;
  organizationId: Scalars['ID']['output'];
  organizationName: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  projectName: Scalars['String']['output'];
};

/** HUD Event */
export type Event = {
  __typename?: 'Event';
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  enrollment: Enrollment;
  event: EventType;
  eventDate: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  locationCrisisOrPhHousing?: Maybe<Scalars['String']['output']>;
  probSolDivRrResult?: Maybe<NoYesMissing>;
  referralCaseManageAfter?: Maybe<NoYesMissing>;
  referralResult?: Maybe<ReferralResult>;
  resultDate?: Maybe<Scalars['ISO8601Date']['output']>;
  user?: Maybe<ApplicationUser>;
};

/** HUD Event Sorting Options */
export enum EventSortOption {
  EventDate = 'EVENT_DATE',
}

/** HUD EventType (4.20.2) */
export enum EventType {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) Problem Solving/Diversion/Rapid Resolution intervention or service */
  ProblemSolvingDiversionRapidResolutionInterventionOrService = 'PROBLEM_SOLVING_DIVERSION_RAPID_RESOLUTION_INTERVENTION_OR_SERVICE',
  /** (18) Referral to a Housing Stability Voucher */
  ReferralToAHousingStabilityVoucher = 'REFERRAL_TO_A_HOUSING_STABILITY_VOUCHER',
  /** (16) Referral to emergency assistance/flex fund/furniture assistance */
  ReferralToEmergencyAssistanceFlexFundFurnitureAssistance = 'REFERRAL_TO_EMERGENCY_ASSISTANCE_FLEX_FUND_FURNITURE_ASSISTANCE',
  /** (17) Referral to Emergency Housing Voucher (EHV) */
  ReferralToEmergencyHousingVoucherEhv = 'REFERRAL_TO_EMERGENCY_HOUSING_VOUCHER_EHV',
  /** (10) Referral to Emergency Shelter bed opening */
  ReferralToEmergencyShelterBedOpening = 'REFERRAL_TO_EMERGENCY_SHELTER_BED_OPENING',
  /** (7) Referral to Housing Navigation project or services */
  ReferralToHousingNavigationProjectOrServices = 'REFERRAL_TO_HOUSING_NAVIGATION_PROJECT_OR_SERVICES',
  /** (12) Referral to Joint TH-RRH project/unit/resource opening */
  ReferralToJointThRrhProjectUnitResourceOpening = 'REFERRAL_TO_JOINT_TH_RRH_PROJECT_UNIT_RESOURCE_OPENING',
  /** (8) Referral to Non-continuum services: Ineligible for continuum services */
  ReferralToNonContinuumServicesIneligibleForContinuumServices = 'REFERRAL_TO_NON_CONTINUUM_SERVICES_INELIGIBLE_FOR_CONTINUUM_SERVICES',
  /** (9) Referral to Non-continuum services: No availability in continuum services */
  ReferralToNonContinuumServicesNoAvailabilityInContinuumServices = 'REFERRAL_TO_NON_CONTINUUM_SERVICES_NO_AVAILABILITY_IN_CONTINUUM_SERVICES',
  /** (15) Referral to Other PH project/unit/resource opening */
  ReferralToOtherPhProjectUnitResourceOpening = 'REFERRAL_TO_OTHER_PH_PROJECT_UNIT_RESOURCE_OPENING',
  /** (5) Referral to Post-placement/ follow-up case management */
  ReferralToPostPlacementFollowUpCaseManagement = 'REFERRAL_TO_POST_PLACEMENT_FOLLOW_UP_CASE_MANAGEMENT',
  /** (1) Referral to Prevention Assistance project */
  ReferralToPreventionAssistanceProject = 'REFERRAL_TO_PREVENTION_ASSISTANCE_PROJECT',
  /** (14) Referral to PSH project resource opening */
  ReferralToPshProjectResourceOpening = 'REFERRAL_TO_PSH_PROJECT_RESOURCE_OPENING',
  /** (13) Referral to RRH project resource opening */
  ReferralToRrhProjectResourceOpening = 'REFERRAL_TO_RRH_PROJECT_RESOURCE_OPENING',
  /** (3) Referral to scheduled Coordinated Entry Crisis Needs Assessment */
  ReferralToScheduledCoordinatedEntryCrisisNeedsAssessment = 'REFERRAL_TO_SCHEDULED_COORDINATED_ENTRY_CRISIS_NEEDS_ASSESSMENT',
  /** (4) Referral to scheduled Coordinated Entry Housing Needs Assessment */
  ReferralToScheduledCoordinatedEntryHousingNeedsAssessment = 'REFERRAL_TO_SCHEDULED_COORDINATED_ENTRY_HOUSING_NEEDS_ASSESSMENT',
  /** (6) Referral to Street Outreach project or services */
  ReferralToStreetOutreachProjectOrServices = 'REFERRAL_TO_STREET_OUTREACH_PROJECT_OR_SERVICES',
  /** (11) Referral to Transitional Housing bed/unit opening */
  ReferralToTransitionalHousingBedUnitOpening = 'REFERRAL_TO_TRANSITIONAL_HOUSING_BED_UNIT_OPENING',
}

export type EventsPaginated = {
  __typename?: 'EventsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Event>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD EvictionHistory (V7.G) */
export enum EvictionHistory {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) No prior rental evictions */
  NoPriorRentalEvictions = 'NO_PRIOR_RENTAL_EVICTIONS',
  /** (1) 1 prior rental eviction */
  Num_1PriorRentalEviction = 'NUM_1_PRIOR_RENTAL_EVICTION',
  /** (2) 2 or more prior rental evictions */
  Num_2OrMorePriorRentalEvictions = 'NUM_2_OR_MORE_PRIOR_RENTAL_EVICTIONS',
}

export type Exit = {
  __typename?: 'Exit';
  aftercareDate?: Maybe<Scalars['ISO8601Date']['output']>;
  aftercareMethods?: Maybe<Array<AftercareMethod>>;
  aftercareProvided?: Maybe<AftercareProvided>;
  askedOrForcedToExchangeForSex?: Maybe<NoYesReasonsForMissingData>;
  askedOrForcedToExchangeForSexPastThreeMonths?: Maybe<NoYesReasonsForMissingData>;
  client: Client;
  cmExitReason?: Maybe<CmExitReason>;
  coercedToContinueWork?: Maybe<NoYesReasonsForMissingData>;
  counselingMethods?: Maybe<Array<CounselingMethod>>;
  counselingReceived?: Maybe<NoYesMissing>;
  countOfExchangeForSex?: Maybe<CountExchangeForSex>;
  createdBy?: Maybe<ApplicationUser>;
  customDataElements: Array<CustomDataElement>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  destination: Destination;
  destinationSafeClient?: Maybe<NoYesReasonsForMissingData>;
  destinationSafeWorker?: Maybe<WorkerResponse>;
  destinationSubsidyType?: Maybe<RentalSubsidyType>;
  earlyExitReason?: Maybe<ExpelledReason>;
  enrollment: Enrollment;
  exchangeForSex?: Maybe<NoYesReasonsForMissingData>;
  exchangeForSexPastThreeMonths?: Maybe<NoYesReasonsForMissingData>;
  exitDate: Scalars['ISO8601Date']['output'];
  housingAssessment?: Maybe<HousingAssessmentAtExit>;
  id: Scalars['ID']['output'];
  laborExploitPastThreeMonths?: Maybe<NoYesReasonsForMissingData>;
  otherDestination?: Maybe<Scalars['String']['output']>;
  posAdultConnections?: Maybe<WorkerResponse>;
  posCommunityConnections?: Maybe<WorkerResponse>;
  posPeerConnections?: Maybe<WorkerResponse>;
  postExitCounselingPlan?: Maybe<NoYesMissing>;
  projectCompletionStatus?: Maybe<ProjectCompletionStatus>;
  sessionCountAtExit?: Maybe<Scalars['Int']['output']>;
  sessionsInPlan?: Maybe<Scalars['Int']['output']>;
  subsidyInformation?: Maybe<SubsidyInformation>;
  subsidyInformationA?: Maybe<SubsidyInformationA>;
  subsidyInformationB?: Maybe<SubsidyInformationB>;
  user?: Maybe<ApplicationUser>;
  workPlaceViolenceThreats?: Maybe<NoYesReasonsForMissingData>;
  workplacePromiseDifference?: Maybe<NoYesReasonsForMissingData>;
};

/** HUD ExpelledReason (R17.A) */
export enum ExpelledReason {
  /** (1) Criminal activity/destruction of property/violence */
  CriminalActivityDestructionOfPropertyViolence = 'CRIMINAL_ACTIVITY_DESTRUCTION_OF_PROPERTY_VIOLENCE',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) Non-compliance with project rules */
  NonComplianceWithProjectRules = 'NON_COMPLIANCE_WITH_PROJECT_RULES',
  /** (3) Non-payment of rent/occupancy charge */
  NonPaymentOfRentOccupancyCharge = 'NON_PAYMENT_OF_RENT_OCCUPANCY_CHARGE',
  /** (5) Project terminated */
  ProjectTerminated = 'PROJECT_TERMINATED',
  /** (4) Reached maximum time allowed by project */
  ReachedMaximumTimeAllowedByProject = 'REACHED_MAXIMUM_TIME_ALLOWED_BY_PROJECT',
  /** (6) Unknown/disappeared */
  UnknownDisappeared = 'UNKNOWN_DISAPPEARED',
}

export type ExternalFormSubmission = {
  __typename?: 'ExternalFormSubmission';
  /** Client that was generated from this submission, if any */
  clientId?: Maybe<Scalars['ID']['output']>;
  customDataElements: Array<CustomDataElement>;
  definition: FormDefinition;
  /** Enrollment that was generated from this submission, if any */
  enrollmentId?: Maybe<Scalars['ID']['output']>;
  /** Client Location where the submission was recorded */
  geolocation?: Maybe<Geolocation>;
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  spam?: Maybe<Scalars['Boolean']['output']>;
  status: ExternalFormSubmissionStatus;
  submittedAt: Scalars['ISO8601DateTime']['output'];
  /** Key/value responses for certain summary-level form questions */
  summaryFields: Array<KeyValue>;
  values?: Maybe<Scalars['JSON']['output']>;
};

export type ExternalFormSubmissionFilterOptions = {
  includeSpam?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<ExternalFormSubmissionStatus>;
  submittedDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** External Form Submission Input */
export type ExternalFormSubmissionInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  spam?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<ExternalFormSubmissionStatus>;
};

export enum ExternalFormSubmissionStatus {
  New = 'new',
  Reviewed = 'reviewed',
}

export type ExternalFormSubmissionsPaginated = {
  __typename?: 'ExternalFormSubmissionsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ExternalFormSubmission>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** External Identifier */
export type ExternalIdentifier = {
  __typename?: 'ExternalIdentifier';
  /** API ID, not the actual identifier value */
  id: Scalars['ID']['output'];
  /** The identifier value */
  identifier?: Maybe<Scalars['ID']['output']>;
  label: Scalars['String']['output'];
  type: ExternalIdentifierType;
  url?: Maybe<Scalars['String']['output']>;
};

/** External Identifier Type */
export enum ExternalIdentifierType {
  /** HMIS ID */
  ClientId = 'CLIENT_ID',
  /** MCI ID */
  MciId = 'MCI_ID',
  /** Personal ID */
  PersonalId = 'PERSONAL_ID',
  /** Warehouse ID */
  WarehouseId = 'WAREHOUSE_ID',
}

export type FieldMapping = {
  __typename?: 'FieldMapping';
  /** Key of CustomDataElement where field is stored */
  customFieldKey?: Maybe<Scalars['String']['output']>;
  /** Field name that this field is stored as */
  fieldName?: Maybe<Scalars['String']['output']>;
  /** Type of record that this field is tied to */
  recordType?: Maybe<RelatedRecordType>;
};

/** File */
export type File = {
  __typename?: 'File';
  confidential?: Maybe<Scalars['Boolean']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  effectiveDate?: Maybe<Scalars['ISO8601Date']['output']>;
  enrollment?: Maybe<Enrollment>;
  enrollmentId?: Maybe<Scalars['ID']['output']>;
  expirationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ownFile: Scalars['Boolean']['output'];
  redacted: Scalars['Boolean']['output'];
  tags: Array<Scalars['String']['output']>;
  updatedBy?: Maybe<ApplicationUser>;
  uploadedBy?: Maybe<ApplicationUser>;
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<ApplicationUser>;
};

/** File Sorting Options */
export enum FileSortOption {
  DateCreated = 'DATE_CREATED',
  DateUpdated = 'DATE_UPDATED',
}

export type FilesPaginated = {
  __typename?: 'FilesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<File>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** FormDefinition */
export type FormDefinition = {
  __typename?: 'FormDefinition';
  cacheKey: Scalars['ID']['output'];
  dateCreated: Scalars['ISO8601DateTime']['output'];
  dateUpdated: Scalars['ISO8601DateTime']['output'];
  definition: FormDefinitionJson;
  formRules: FormRulesPaginated;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  projectMatches: FormProjectMatchesPaginated;
  rawDefinition: Scalars['JsonObject']['output'];
  role: FormRole;
  status: FormStatus;
  supportsSaveInProgress: Scalars['Boolean']['output'];
  system: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedBy?: Maybe<ApplicationUser>;
  version: Scalars['ID']['output'];
};

/** FormDefinition */
export type FormDefinitionFormRulesArgs = {
  filters?: InputMaybe<FormRuleFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<FormRuleSortOption>;
};

/** FormDefinition */
export type FormDefinitionProjectMatchesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type FormDefinitionForJsonResult = {
  __typename?: 'FormDefinitionForJsonResult';
  definition?: Maybe<FormDefinitionJson>;
  errors: Array<Scalars['String']['output']>;
};

export type FormDefinitionInput = {
  definition?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<FormRole>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type FormDefinitionJson = {
  __typename?: 'FormDefinitionJson';
  /** Nested items */
  item: Array<FormItem>;
};

export type FormDefinitionsPaginated = {
  __typename?: 'FormDefinitionsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<FormDefinition>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Type representing one form Identifier, which collects all versioned FormDefinitions for the same identifier */
export type FormIdentifier = {
  __typename?: 'FormIdentifier';
  allVersions: FormDefinitionsPaginated;
  /** Form version to use for display in the configuration tool interface. The form itself may be draft, status, or retired. */
  displayVersion: FormDefinition;
  draftVersion?: Maybe<FormDefinition>;
  id: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  /** Whether this form is managed in version control. If true, it should not be edited in the configuration tool. */
  managedInVersionControl: Scalars['Boolean']['output'];
  publishedVersion?: Maybe<FormDefinition>;
};

/** Type representing one form Identifier, which collects all versioned FormDefinitions for the same identifier */
export type FormIdentifierAllVersionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type FormIdentifierFilterOptions = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type FormIdentifiersPaginated = {
  __typename?: 'FormIdentifiersPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<FormIdentifier>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type FormInput = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  enrollmentId?: InputMaybe<Scalars['ID']['input']>;
  formDefinitionId: Scalars['ID']['input'];
  hudValues?: InputMaybe<Scalars['JsonObject']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  recordId?: InputMaybe<Scalars['ID']['input']>;
  serviceTypeId?: InputMaybe<Scalars['ID']['input']>;
  values?: InputMaybe<Scalars['JsonObject']['input']>;
};

/** A question or group of questions */
export type FormItem = {
  __typename?: 'FormItem';
  /**
   * Whether this item corresponds to the assessment date. Must be used with DATE
   * type. Should be used no more than once per form
   */
  assessmentDate?: Maybe<Scalars['Boolean']['output']>;
  /** Value(s) to autofill based on conditional logic */
  autofillValues?: Maybe<Array<AutofillValue>>;
  /** Bounds applied to the input value */
  bounds?: Maybe<Array<ValueBound>>;
  /** Label to use for placeholder and population table */
  briefText?: Maybe<Scalars['String']['output']>;
  /** Component to use for display/input of this item */
  component?: Maybe<Component>;
  /** Custom rules that apply to this item */
  customRule?: Maybe<Scalars['JSON']['output']>;
  /** Include this item only if the Client meets this HUD DataCollectedAbout condition */
  dataCollectedAbout?: Maybe<DataCollectedAbout>;
  /** How to display item if it is disabled */
  disabledDisplay?: Maybe<DisabledDisplay>;
  /** Users who can edit this field. If null, all users can edit */
  editorUserIds?: Maybe<Array<Scalars['ID']['output']>>;
  enableBehavior?: Maybe<EnableBehavior>;
  enableWhen?: Maybe<Array<EnableWhen>>;
  /** Helper text for the item */
  helperText?: Maybe<Scalars['String']['output']>;
  /** Whether the item should always be hidden */
  hidden?: Maybe<Scalars['Boolean']['output']>;
  /** Initial value(s) when item is first rendered */
  initial?: Maybe<Array<InitialValue>>;
  /** Nested items */
  item?: Maybe<Array<FormItem>>;
  /** Unique identifier for item */
  linkId: Scalars['String']['output'];
  mapping?: Maybe<FieldMapping>;
  /** Permitted answers, for choice items */
  pickListOptions?: Maybe<Array<PickListOption>>;
  /** Reference to value set of possible answer options */
  pickListReference?: Maybe<Scalars['String']['output']>;
  /** Whether to allow pre-filling this section from a previous assessment */
  prefill?: Maybe<Scalars['Boolean']['output']>;
  /** Prefix for the item label */
  prefix?: Maybe<Scalars['String']['output']>;
  /** Whether human editing is allowed */
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  /** Text to use for the item when displayed in read-only view */
  readonlyText?: Maybe<Scalars['String']['output']>;
  /** Whether the item may repeat (for choice types, this means multiple choice) */
  repeats?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the item must be included in data results */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** Rules that apply to this item */
  rule?: Maybe<Scalars['JSON']['output']>;
  /**
   * Whether to apply this field to all clients or a single client when bulk creating
   * @deprecated from old bulk services implementation, no longer supported
   */
  serviceDetailType?: Maybe<ServiceDetailType>;
  /** Size of the input element */
  size?: Maybe<InputSize>;
  /** Primary text for the item */
  text?: Maybe<Scalars['String']['output']>;
  type: ItemType;
  /** Whether to show a warning if this question is unanswered */
  warnIfEmpty?: Maybe<Scalars['Boolean']['output']>;
};

/** Project match for a form, including information about which clients in this project the form is applicable to. */
export type FormProjectMatch = {
  __typename?: 'FormProjectMatch';
  dataCollectedAbout: DataCollectedAbout;
  id: Scalars['ID']['output'];
  organizationName: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

export type FormProjectMatchesPaginated = {
  __typename?: 'FormProjectMatchesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<FormProjectMatch>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum FormRole {
  /** Annual */
  Annual = 'ANNUAL',
  /** Auto exit config */
  AutoExitConfig = 'AUTO_EXIT_CONFIG',
  /** Case note */
  CaseNote = 'CASE_NOTE',
  /** CE assessment */
  CeAssessment = 'CE_ASSESSMENT',
  /** CE event */
  CeEvent = 'CE_EVENT',
  /** CE participation */
  CeParticipation = 'CE_PARTICIPATION',
  /** Client */
  Client = 'CLIENT',
  /** Client alert */
  ClientAlert = 'CLIENT_ALERT',
  /** Client detail */
  ClientDetail = 'CLIENT_DETAIL',
  /** Current living situation */
  CurrentLivingSituation = 'CURRENT_LIVING_SITUATION',
  /** Custom assessment */
  CustomAssessment = 'CUSTOM_ASSESSMENT',
  /** Enrollment */
  Enrollment = 'ENROLLMENT',
  /** Exit */
  Exit = 'EXIT',
  /** External form */
  ExternalForm = 'EXTERNAL_FORM',
  /** External form submission review */
  ExternalFormSubmissionReview = 'EXTERNAL_FORM_SUBMISSION_REVIEW',
  /** File */
  File = 'FILE',
  /** Form definition */
  FormDefinition = 'FORM_DEFINITION',
  /** Form rule */
  FormRule = 'FORM_RULE',
  /** Funder */
  Funder = 'FUNDER',
  /** HMIS participation */
  HmisParticipation = 'HMIS_PARTICIPATION',
  /** Intake */
  Intake = 'INTAKE',
  /** Inventory */
  Inventory = 'INVENTORY',
  /** New client enrollment */
  NewClientEnrollment = 'NEW_CLIENT_ENROLLMENT',
  /** Occurrence point */
  OccurrencePoint = 'OCCURRENCE_POINT',
  /** Organization */
  Organization = 'ORGANIZATION',
  /** Post exit */
  PostExit = 'POST_EXIT',
  /** Project */
  Project = 'PROJECT',
  /** Project CoC */
  ProjectCoc = 'PROJECT_COC',
  /** Project config */
  ProjectConfig = 'PROJECT_CONFIG',
  /** Referral */
  Referral = 'REFERRAL',
  /** Referral request */
  ReferralRequest = 'REFERRAL_REQUEST',
  /** Service */
  Service = 'SERVICE',
  /** Update */
  Update = 'UPDATE',
}

export type FormRule = {
  __typename?: 'FormRule';
  active: Scalars['Boolean']['output'];
  activeStatus: ActiveStatus;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dataCollectedAbout?: Maybe<DataCollectedAbout>;
  definitionId?: Maybe<Scalars['ID']['output']>;
  definitionIdentifier: Scalars['String']['output'];
  definitionRole?: Maybe<FormRole>;
  definitionTitle?: Maybe<Scalars['String']['output']>;
  funder?: Maybe<FundingSource>;
  id: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  otherFunder?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']['output']>;
  projectType?: Maybe<ProjectType>;
  serviceCategory?: Maybe<ServiceCategory>;
  serviceType?: Maybe<ServiceType>;
  system: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type FormRuleFilterOptions = {
  activeStatus?: InputMaybe<Array<ActiveStatus>>;
  appliedToProject?: InputMaybe<Scalars['ID']['input']>;
  definition?: InputMaybe<Scalars['ID']['input']>;
  formType?: InputMaybe<Array<FormRole>>;
  projectType?: InputMaybe<Array<ProjectType>>;
  systemForm?: InputMaybe<Array<SystemStatus>>;
};

export type FormRuleInput = {
  activeStatus?: InputMaybe<ActiveStatus>;
  dataCollectedAbout?: InputMaybe<DataCollectedAbout>;
  funder?: InputMaybe<FundingSource>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  otherFunder?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projectType?: InputMaybe<ProjectType>;
  serviceCategoryId?: InputMaybe<Scalars['ID']['input']>;
  serviceTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export enum FormRuleSortOption {
  /** Date Updated */
  DateUpdated = 'DATE_UPDATED',
  /** Form Title */
  FormTitle = 'FORM_TITLE',
  /** Form Type */
  FormType = 'FORM_TYPE',
}

export type FormRulesPaginated = {
  __typename?: 'FormRulesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<FormRule>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum FormStatus {
  /** Draft */
  Draft = 'draft',
  /** Published */
  Published = 'published',
  /** Retired */
  Retired = 'retired',
}

export type Funder = {
  __typename?: 'Funder';
  active: Scalars['Boolean']['output'];
  createdBy?: Maybe<ApplicationUser>;
  customDataElements: Array<CustomDataElement>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  funder: FundingSource;
  grantId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  otherFunder?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  user?: Maybe<ApplicationUser>;
};

/** HUD Funder Sorting Options */
export enum FunderSortOption {
  StartDate = 'START_DATE',
}

export type FundersPaginated = {
  __typename?: 'FundersPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Funder>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD FundingSource (2.06.1) */
export enum FundingSource {
  /** (21) HHS: PATH - Street Outreach & Supportive Services Only */
  HhsPathStreetOutreachSupportiveServicesOnly = 'HHS_PATH_STREET_OUTREACH_SUPPORTIVE_SERVICES_ONLY',
  /** (22) HHS: RHY - Basic Center Program (prevention and shelter) */
  HhsRhyBasicCenterProgram = 'HHS_RHY_BASIC_CENTER_PROGRAM',
  /** (26) HHS: RHY - Demonstration Project */
  HhsRhyDemonstrationProject = 'HHS_RHY_DEMONSTRATION_PROJECT',
  /** (23) HHS: RHY - Maternity Group Home for Pregnant and Parenting Youth */
  HhsRhyMaternityGroupHomeForPregnantAndParentingYouth = 'HHS_RHY_MATERNITY_GROUP_HOME_FOR_PREGNANT_AND_PARENTING_YOUTH',
  /** (25) HHS: RHY - Street Outreach Project */
  HhsRhyStreetOutreachProject = 'HHS_RHY_STREET_OUTREACH_PROJECT',
  /** (24) HHS: RHY - Transitional Living Program */
  HhsRhyTransitionalLivingProgram = 'HHS_RHY_TRANSITIONAL_LIVING_PROGRAM',
  /** (1) HUD: CoC - Homelessness Prevention (High Performing Communities Only) */
  HudCocHomelessnessPrevention = 'HUD_COC_HOMELESSNESS_PREVENTION',
  /** (49) HUD: CoC - Joint Component RRH/PSH [Deprecated] */
  HudCocJointComponentRrhPshDeprecated = 'HUD_COC_JOINT_COMPONENT_RRH_PSH_DEPRECATED',
  /** (44) HUD: CoC - Joint Component TH/RRH */
  HudCocJointComponentThRrh = 'HUD_COC_JOINT_COMPONENT_TH_RRH',
  /** (2) HUD: CoC - Permanent Supportive Housing */
  HudCocPermanentSupportiveHousing = 'HUD_COC_PERMANENT_SUPPORTIVE_HOUSING',
  /** (3) HUD: CoC - Rapid Re-Housing */
  HudCocRapidReHousing = 'HUD_COC_RAPID_RE_HOUSING',
  /** (6) HUD: CoC - Safe Haven */
  HudCocSafeHaven = 'HUD_COC_SAFE_HAVEN',
  /** (7) HUD: CoC - Single Room Occupancy (SRO) */
  HudCocSingleRoomOccupancySro = 'HUD_COC_SINGLE_ROOM_OCCUPANCY_SRO',
  /** (4) HUD: CoC - Supportive Services Only */
  HudCocSupportiveServicesOnly = 'HUD_COC_SUPPORTIVE_SERVICES_ONLY',
  /** (5) HUD: CoC - Transitional Housing */
  HudCocTransitionalHousing = 'HUD_COC_TRANSITIONAL_HOUSING',
  /** (43) HUD: CoC - Youth Homeless Demonstration Program (YHDP) */
  HudCocYouthHomelessDemonstrationProgramYhdp = 'HUD_COC_YOUTH_HOMELESS_DEMONSTRATION_PROGRAM_YHDP',
  /** (47) HUD: ESG - CV */
  HudEsgCv = 'HUD_ESG_CV',
  /** (8) HUD: ESG - Emergency Shelter (operating and/or essential services) */
  HudEsgEmergencyShelter = 'HUD_ESG_EMERGENCY_SHELTER',
  /** (9) HUD: ESG - Homelessness Prevention */
  HudEsgHomelessnessPrevention = 'HUD_ESG_HOMELESSNESS_PREVENTION',
  /** (10) HUD: ESG - Rapid Rehousing */
  HudEsgRapidRehousing = 'HUD_ESG_RAPID_REHOUSING',
  /** (53) HUD: ESG - RUSH */
  HudEsgRush = 'HUD_ESG_RUSH',
  /** (11) HUD: ESG - Street Outreach */
  HudEsgStreetOutreach = 'HUD_ESG_STREET_OUTREACH',
  /** (50) HUD: HOME */
  HudHome = 'HUD_HOME',
  /** (51) HUD: HOME (ARP) */
  HudHomeArp = 'HUD_HOME_ARP',
  /** (48) HUD: HOPWA - CV */
  HudHopwaCv = 'HUD_HOPWA_CV',
  /** (13) HUD: HOPWA - Hotel/Motel Vouchers */
  HudHopwaHotelMotelVouchers = 'HUD_HOPWA_HOTEL_MOTEL_VOUCHERS',
  /** (14) HUD: HOPWA - Housing Information */
  HudHopwaHousingInformation = 'HUD_HOPWA_HOUSING_INFORMATION',
  /** (15) HUD: HOPWA - Permanent Housing (facility based or TBRA) */
  HudHopwaPermanentHousing = 'HUD_HOPWA_PERMANENT_HOUSING',
  /** (16) HUD: HOPWA - Permanent Housing Placement */
  HudHopwaPermanentHousingPlacement = 'HUD_HOPWA_PERMANENT_HOUSING_PLACEMENT',
  /** (17) HUD: HOPWA - Short-Term Rent, Mortgage, Utility assistance */
  HudHopwaShortTermRentMortgageUtilityAssistance = 'HUD_HOPWA_SHORT_TERM_RENT_MORTGAGE_UTILITY_ASSISTANCE',
  /** (18) HUD: HOPWA - Short-Term Supportive Facility */
  HudHopwaShortTermSupportiveFacility = 'HUD_HOPWA_SHORT_TERM_SUPPORTIVE_FACILITY',
  /** (19) HUD: HOPWA - Transitional Housing (facility based or TBRA) */
  HudHopwaTransitionalHousing = 'HUD_HOPWA_TRANSITIONAL_HOUSING',
  /** (20) HUD: HUD/VASH */
  HudHudVash = 'HUD_HUD_VASH',
  /** (35) HUD: Pay for Success */
  HudPayForSuccess = 'HUD_PAY_FOR_SUCCESS',
  /** (52) HUD: PIH (Emergency Housing Voucher) */
  HudPih = 'HUD_PIH',
  /** (36) HUD: Public and Indian Housing (PIH) Programs */
  HudPublicAndIndianHousingPihPrograms = 'HUD_PUBLIC_AND_INDIAN_HOUSING_PIH_PROGRAMS',
  /** (12) HUD: Rural Housing Stability Assistance Program [Deprecated] */
  HudRuralHousingStabilityAssistanceProgramDeprecated = 'HUD_RURAL_HOUSING_STABILITY_ASSISTANCE_PROGRAM_DEPRECATED',
  /** (55) HUD: Rural Special NOFO */
  HudRuralSpecialNofo = 'HUD_RURAL_SPECIAL_NOFO',
  /** (54) HUD: Unsheltered Special NOFO */
  HudUnshelteredSpecialNofo = 'HUD_UNSHELTERED_SPECIAL_NOFO',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (46) Local or Other Funding Source */
  LocalOrOtherFundingSource = 'LOCAL_OR_OTHER_FUNDING_SOURCE',
  /** (34) N/A */
  NA = 'N_A',
  /** (30) VA: Community Contract Safe Haven Program */
  VaCommunityContractSafeHavenProgram = 'VA_COMMUNITY_CONTRACT_SAFE_HAVEN_PROGRAM',
  /** (32) VA: Compensated Work Therapy Transitional Residence */
  VaCompensatedWorkTherapyTransitionalResidence = 'VA_COMPENSATED_WORK_THERAPY_TRANSITIONAL_RESIDENCE',
  /** (27) VA: CRS Contract Residential Services */
  VaCrsContractResidentialServices = 'VA_CRS_CONTRACT_RESIDENTIAL_SERVICES',
  /** (37) VA: Grant Per Diem - Bridge Housing */
  VaGrantPerDiemBridgeHousing = 'VA_GRANT_PER_DIEM_BRIDGE_HOUSING',
  /** (45) VA: Grant Per Diem - Case Management/Housing Retention */
  VaGrantPerDiemCaseManagementHousingRetention = 'VA_GRANT_PER_DIEM_CASE_MANAGEMENT_HOUSING_RETENTION',
  /** (40) VA: Grant Per Diem - Clinical Treatment */
  VaGrantPerDiemClinicalTreatment = 'VA_GRANT_PER_DIEM_CLINICAL_TREATMENT',
  /** (39) VA: Grant Per Diem - Hospital to Housing */
  VaGrantPerDiemHospitalToHousing = 'VA_GRANT_PER_DIEM_HOSPITAL_TO_HOUSING',
  /** (38) VA: Grant Per Diem - Low Demand */
  VaGrantPerDiemLowDemand = 'VA_GRANT_PER_DIEM_LOW_DEMAND',
  /** (41) VA: Grant Per Diem - Service Intensive Transitional Housing */
  VaGrantPerDiemServiceIntensiveTransitionalHousing = 'VA_GRANT_PER_DIEM_SERVICE_INTENSIVE_TRANSITIONAL_HOUSING',
  /** (42) VA: Grant Per Diem - Transition in Place */
  VaGrantPerDiemTransitionInPlace = 'VA_GRANT_PER_DIEM_TRANSITION_IN_PLACE',
  /** (33) VA: Supportive Services for Veteran Families */
  VaSupportiveServicesForVeteranFamilies = 'VA_SUPPORTIVE_SERVICES_FOR_VETERAN_FAMILIES',
}

/** HUD Gender (1.7) */
export enum Gender {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (2) Culturally Specific Identity (e.g., Two-Spirit) */
  CulturallySpecific = 'CULTURALLY_SPECIFIC',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (3) Different Identity */
  DifferentIdentity = 'DIFFERENT_IDENTITY',
  /** (1) Man (Boy, if child) */
  Man = 'MAN',
  /** (4) Non-Binary */
  NonBinary = 'NON_BINARY',
  /** (6) Questioning */
  Questioning = 'QUESTIONING',
  /** (5) Transgender */
  Transgender = 'TRANSGENDER',
  /** (0) Woman (Girl, if child) */
  Woman = 'WOMAN',
}

/** HUD GeographyType (2.03.4) */
export enum GeographyType {
  /** (99) Unknown / data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (3) Rural */
  Rural = 'RURAL',
  /** (2) Suburban */
  Suburban = 'SUBURBAN',
  /** (1) Urban */
  Urban = 'URBAN',
}

export type Geolocation = {
  __typename?: 'Geolocation';
  /** User who collected the location */
  collectedBy?: Maybe<ApplicationUser>;
  coordinates: GeolocationCoordinates;
  id: Scalars['ID']['output'];
  /** Timestamp when the location was collected */
  locatedAt: Scalars['ISO8601DateTime']['output'];
  /** Name of the Project that collected the location */
  projectName?: Maybe<Scalars['String']['output']>;
  /** Name of the form that collected this location */
  sourceFormName?: Maybe<Scalars['String']['output']>;
};

export type GeolocationCoordinates = {
  __typename?: 'GeolocationCoordinates';
  id: Scalars['ID']['output'];
  latitude: Scalars['String']['output'];
  longitude: Scalars['String']['output'];
};

/** HUD HMISParticipationType (2.08.1) */
export enum HmisParticipationType {
  /** (2) Comparable Database Participating */
  ComparableDatabaseParticipating = 'COMPARABLE_DATABASE_PARTICIPATING',
  /** (1) HMIS Participating */
  HmisParticipating = 'HMIS_PARTICIPATING',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) Not Participating */
  NotParticipating = 'NOT_PARTICIPATING',
}

/** HUD HOPWAMedAssistedLivingFac (2.02.8) */
export enum HopwaMedAssistedLivingFac {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) No */
  No = 'NO',
  /** (2) Non-HOPWA Funded Project */
  NonHopwaFundedProject = 'NON_HOPWA_FUNDED_PROJECT',
  /** (1) Yes */
  Yes = 'YES',
}

export type HealthAndDv = {
  __typename?: 'HealthAndDv';
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  currentlyFleeing?: Maybe<NoYesReasonsForMissingData>;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dentalHealthStatus?: Maybe<HealthStatus>;
  domesticViolenceSurvivor?: Maybe<NoYesReasonsForMissingData>;
  dueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  enrollment: Enrollment;
  generalHealthStatus?: Maybe<HealthStatus>;
  id: Scalars['ID']['output'];
  informationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  mentalHealthStatus?: Maybe<HealthStatus>;
  pregnancyStatus?: Maybe<NoYesReasonsForMissingData>;
  user?: Maybe<ApplicationUser>;
  whenOccurred?: Maybe<WhenDvOccurred>;
};

export type HealthAndDvsPaginated = {
  __typename?: 'HealthAndDvsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<HealthAndDv>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD HealthStatus (R7.1) */
export enum HealthStatus {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (1) Excellent */
  Excellent = 'EXCELLENT',
  /** (4) Fair */
  Fair = 'FAIR',
  /** (3) Good */
  Good = 'GOOD',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (5) Poor */
  Poor = 'POOR',
  /** (2) Very good */
  VeryGood = 'VERY_GOOD',
}

export type HmisParticipation = {
  __typename?: 'HmisParticipation';
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  hmisParticipationStatusEndDate?: Maybe<Scalars['ISO8601Date']['output']>;
  hmisParticipationStatusStartDate?: Maybe<Scalars['ISO8601Date']['output']>;
  hmisParticipationType?: Maybe<HmisParticipationType>;
  id: Scalars['ID']['output'];
  user?: Maybe<ApplicationUser>;
};

export type HmisParticipationsPaginated = {
  __typename?: 'HmisParticipationsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<HmisParticipation>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type Household = {
  __typename?: 'Household';
  anyInProgress: Scalars['Boolean']['output'];
  assessments: AssessmentsPaginated;
  earliestEntryDate: Scalars['ISO8601Date']['output'];
  householdClients: Array<HouseholdClient>;
  householdSize: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  latestExitDate?: Maybe<Scalars['ISO8601Date']['output']>;
  shortId: Scalars['ID']['output'];
  staffAssignments?: Maybe<StaffAssignmentsPaginated>;
};

export type HouseholdAssessmentsArgs = {
  filters?: InputMaybe<AssessmentsForHouseholdFilterOptions>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<AssessmentSortOption>;
};

export type HouseholdStaffAssignmentsArgs = {
  isCurrentlyAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD Client within a Household */
export type HouseholdClient = {
  __typename?: 'HouseholdClient';
  client: Client;
  enrollment: Enrollment;
  id: Scalars['ID']['output'];
  relationshipToHoH: RelationshipToHoH;
};

export type HouseholdFilterOptions = {
  assignedStaff?: InputMaybe<Scalars['ID']['input']>;
  hohAgeRange?: InputMaybe<AgeRange>;
  openOnDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<EnrollmentFilterOptionStatus>>;
};

/** HUD Household Sorting Options */
export enum HouseholdSortOption {
  /** Head of Household Age: Oldest to Youngest */
  HohAgeOldestToYoungest = 'HOH_AGE_OLDEST_TO_YOUNGEST',
  /** Head of Household Age: Youngest to Oldest */
  HohAgeYoungestToOldest = 'HOH_AGE_YOUNGEST_TO_OLDEST',
  /** Head of Household First Name: A-Z */
  HohFirstNameAToZ = 'HOH_FIRST_NAME_A_TO_Z',
  /** Head of Household First Name: Z-A */
  HohFirstNameZToA = 'HOH_FIRST_NAME_Z_TO_A',
  /** Head of Household Last Name: A-Z */
  HohLastNameAToZ = 'HOH_LAST_NAME_A_TO_Z',
  /** Head of Household Last Name: Z-A */
  HohLastNameZToA = 'HOH_LAST_NAME_Z_TO_A',
  /** Most Recent */
  MostRecent = 'MOST_RECENT',
}

/** HUD HouseholdType (2.07.4) */
export enum HouseholdType {
  /** (1) Households without children */
  HouseholdsWithoutChildren = 'HOUSEHOLDS_WITHOUT_CHILDREN',
  /** (3) Households with at least one adult and one child */
  HouseholdsWithAtLeastOneAdultAndOneChild = 'HOUSEHOLDS_WITH_AT_LEAST_ONE_ADULT_AND_ONE_CHILD',
  /** (4) Households with only children */
  HouseholdsWithOnlyChildren = 'HOUSEHOLDS_WITH_ONLY_CHILDREN',
  /** Invalid Value */
  Invalid = 'INVALID',
}

export type HouseholdsPaginated = {
  __typename?: 'HouseholdsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Household>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD HousingAssessmentAtExit (W5.1) */
export enum HousingAssessmentAtExit {
  /** (1) Able to maintain the housing they had at project entry */
  AbleToMaintainTheHousingTheyHadAtProjectEntry = 'ABLE_TO_MAINTAIN_THE_HOUSING_THEY_HAD_AT_PROJECT_ENTRY',
  /** (6) Client became homeless - moving to a shelter or other place unfit for human habitation */
  ClientBecameHomelessMovingToAShelterOrOtherPlaceUnfitForHumanHabitation = 'CLIENT_BECAME_HOMELESS_MOVING_TO_A_SHELTER_OR_OTHER_PLACE_UNFIT_FOR_HUMAN_HABITATION',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (10) Deceased */
  Deceased = 'DECEASED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (7) Jail/prison */
  JailPrison = 'JAIL_PRISON',
  /** (4) Moved in with family/friends on a permanent basis */
  MovedInWithFamilyFriendsOnAPermanentBasis = 'MOVED_IN_WITH_FAMILY_FRIENDS_ON_A_PERMANENT_BASIS',
  /** (3) Moved in with family/friends on a temporary basis */
  MovedInWithFamilyFriendsOnATemporaryBasis = 'MOVED_IN_WITH_FAMILY_FRIENDS_ON_A_TEMPORARY_BASIS',
  /** (5) Moved to a transitional or temporary housing facility or program */
  MovedToATransitionalOrTemporaryHousingFacilityOrProgram = 'MOVED_TO_A_TRANSITIONAL_OR_TEMPORARY_HOUSING_FACILITY_OR_PROGRAM',
  /** (2) Moved to new housing unit */
  MovedToNewHousingUnit = 'MOVED_TO_NEW_HOUSING_UNIT',
}

/** HUD HousingType (2.02.D) */
export enum HousingType {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) Site-based - clustered / multiple sites */
  SiteBasedClusteredMultipleSites = 'SITE_BASED_CLUSTERED_MULTIPLE_SITES',
  /** (1) Site-based - single site */
  SiteBasedSingleSite = 'SITE_BASED_SINGLE_SITE',
  /** (3) Tenant-based - scattered site */
  TenantBasedScatteredSite = 'TENANT_BASED_SCATTERED_SITE',
}

/** HUD IncarceratedAdult (V7.I) */
export enum IncarceratedAdult {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (1) Incarcerated once */
  IncarceratedOnce = 'INCARCERATED_ONCE',
  /** (2) Incarcerated two or more times */
  IncarceratedTwoOrMoreTimes = 'INCARCERATED_TWO_OR_MORE_TIMES',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) Not incarcerated */
  NotIncarcerated = 'NOT_INCARCERATED',
}

export type IncomeBenefit = {
  __typename?: 'IncomeBenefit';
  adap?: Maybe<NoYesReasonsForMissingData>;
  alimony?: Maybe<NoYesMissing>;
  alimonyAmount?: Maybe<Scalars['Float']['output']>;
  benefitsFromAnySource?: Maybe<NoYesReasonsForMissingData>;
  childSupport?: Maybe<NoYesMissing>;
  childSupportAmount?: Maybe<Scalars['Float']['output']>;
  client: Client;
  cobra?: Maybe<NoYesMissing>;
  connectionWithSoar?: Maybe<NoYesReasonsForMissingData>;
  createdBy?: Maybe<ApplicationUser>;
  customDataElements: Array<CustomDataElement>;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  earned?: Maybe<NoYesMissing>;
  earnedAmount?: Maybe<Scalars['Float']['output']>;
  employerProvided?: Maybe<NoYesMissing>;
  enrollment: Enrollment;
  ga?: Maybe<NoYesMissing>;
  gaAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  incomeFromAnySource?: Maybe<NoYesReasonsForMissingData>;
  indianHealthServices?: Maybe<NoYesMissing>;
  informationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  insuranceFromAnySource?: Maybe<NoYesReasonsForMissingData>;
  medicaid?: Maybe<NoYesMissing>;
  medicare?: Maybe<NoYesMissing>;
  noAdapReason?: Maybe<NoAssistanceReason>;
  noCobraReason?: Maybe<ReasonNotInsured>;
  noEmployerProvidedReason?: Maybe<ReasonNotInsured>;
  noIndianHealthServicesReason?: Maybe<ReasonNotInsured>;
  noMedicaidReason?: Maybe<ReasonNotInsured>;
  noMedicareReason?: Maybe<ReasonNotInsured>;
  noPrivatePayReason?: Maybe<ReasonNotInsured>;
  noRyanWhiteReason?: Maybe<NoAssistanceReason>;
  noSchipReason?: Maybe<ReasonNotInsured>;
  noStateHealthInsReason?: Maybe<ReasonNotInsured>;
  noVhaReason?: Maybe<ReasonNotInsured>;
  otherBenefitsSource?: Maybe<NoYesMissing>;
  otherBenefitsSourceIdentify?: Maybe<Scalars['String']['output']>;
  otherIncomeAmount?: Maybe<Scalars['Float']['output']>;
  otherIncomeSource?: Maybe<NoYesMissing>;
  otherIncomeSourceIdentify?: Maybe<Scalars['String']['output']>;
  otherInsurance?: Maybe<NoYesMissing>;
  otherInsuranceIdentify?: Maybe<Scalars['String']['output']>;
  otherTanf?: Maybe<NoYesMissing>;
  pension?: Maybe<NoYesMissing>;
  pensionAmount?: Maybe<Scalars['Float']['output']>;
  privateDisability?: Maybe<NoYesMissing>;
  privateDisabilityAmount?: Maybe<Scalars['Float']['output']>;
  privatePay?: Maybe<NoYesMissing>;
  ryanWhiteMedDent?: Maybe<NoYesReasonsForMissingData>;
  schip?: Maybe<NoYesMissing>;
  snap?: Maybe<NoYesMissing>;
  socSecRetirement?: Maybe<NoYesMissing>;
  socSecRetirementAmount?: Maybe<Scalars['Float']['output']>;
  ssdi?: Maybe<NoYesMissing>;
  ssdiAmount?: Maybe<Scalars['Float']['output']>;
  ssi?: Maybe<NoYesMissing>;
  ssiAmount?: Maybe<Scalars['Float']['output']>;
  stateHealthIns?: Maybe<NoYesMissing>;
  tanf?: Maybe<NoYesMissing>;
  tanfAmount?: Maybe<Scalars['Float']['output']>;
  tanfChildCare?: Maybe<NoYesMissing>;
  tanfTransportation?: Maybe<NoYesMissing>;
  totalMonthlyIncome?: Maybe<Scalars['String']['output']>;
  unemployment?: Maybe<NoYesMissing>;
  unemploymentAmount?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<ApplicationUser>;
  vaDisabilityNonService?: Maybe<NoYesMissing>;
  vaDisabilityNonServiceAmount?: Maybe<Scalars['Float']['output']>;
  vaDisabilityService?: Maybe<NoYesMissing>;
  vaDisabilityServiceAmount?: Maybe<Scalars['Float']['output']>;
  vhaServices?: Maybe<NoYesMissing>;
  wic?: Maybe<NoYesMissing>;
  workersComp?: Maybe<NoYesMissing>;
  workersCompAmount?: Maybe<Scalars['Float']['output']>;
};

export type IncomeBenefitsPaginated = {
  __typename?: 'IncomeBenefitsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<IncomeBenefit>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum InitialBehavior {
  /** When loading the form, only set the specified initial value if there is no existing value. */
  IfEmpty = 'IF_EMPTY',
  /** When loading the form, always overwrite the existing value with specified initial value. */
  Overwrite = 'OVERWRITE',
}

/** Initial value when item is first rendered */
export type InitialValue = {
  __typename?: 'InitialValue';
  initialBehavior: InitialBehavior;
  /** Boolean to set as initial value */
  valueBoolean?: Maybe<Scalars['Boolean']['output']>;
  /** Code to set as initial value */
  valueCode?: Maybe<Scalars['String']['output']>;
  /** Name of local variable to use as initial value if present. Variable type should match item type. */
  valueLocalConstant?: Maybe<Scalars['String']['output']>;
  /** Number to set as initial value */
  valueNumber?: Maybe<Scalars['Int']['output']>;
};

export enum InputSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Xsmall = 'XSMALL',
}

export type InventoriesPaginated = {
  __typename?: 'InventoriesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Inventory>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type Inventory = {
  __typename?: 'Inventory';
  active: Scalars['Boolean']['output'];
  availability?: Maybe<Availability>;
  bedInventory: Scalars['Int']['output'];
  chBedInventory?: Maybe<Scalars['Int']['output']>;
  chVetBedInventory?: Maybe<Scalars['Int']['output']>;
  chYouthBedInventory?: Maybe<Scalars['Int']['output']>;
  cocCode?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<ApplicationUser>;
  customDataElements: Array<CustomDataElement>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  esBedType?: Maybe<BedType>;
  householdType?: Maybe<HouseholdType>;
  id: Scalars['ID']['output'];
  inventoryEndDate?: Maybe<Scalars['ISO8601Date']['output']>;
  inventoryStartDate?: Maybe<Scalars['ISO8601Date']['output']>;
  otherBedInventory?: Maybe<Scalars['Int']['output']>;
  unitInventory: Scalars['Int']['output'];
  user?: Maybe<ApplicationUser>;
  vetBedInventory?: Maybe<Scalars['Int']['output']>;
  youthBedInventory?: Maybe<Scalars['Int']['output']>;
  youthVetBedInventory?: Maybe<Scalars['Int']['output']>;
};

export enum InventoryBedType {
  /** Chronic */
  Chronic = 'CHRONIC',
  /** Chronic Veteran */
  ChronicVeteran = 'CHRONIC_VETERAN',
  /** Chronic Youth */
  ChronicYouth = 'CHRONIC_YOUTH',
  /** Other */
  Other = 'OTHER',
  /** Veteran */
  Veteran = 'VETERAN',
  /** Youth */
  Youth = 'YOUTH',
  /** Youth Veteran */
  YouthVeteran = 'YOUTH_VETERAN',
}

/** HUD Inventory Sorting Options */
export enum InventorySortOption {
  StartDate = 'START_DATE',
}

export enum ItemType {
  Boolean = 'BOOLEAN',
  Choice = 'CHOICE',
  Currency = 'CURRENCY',
  Date = 'DATE',
  Display = 'DISPLAY',
  File = 'FILE',
  Geolocation = 'GEOLOCATION',
  Group = 'GROUP',
  Image = 'IMAGE',
  Integer = 'INTEGER',
  Object = 'OBJECT',
  OpenChoice = 'OPEN_CHOICE',
  String = 'STRING',
  Text = 'TEXT',
  TimeOfDay = 'TIME_OF_DAY',
}

/** Autogenerated return type of JoinHousehold. */
export type JoinHouseholdPayload = {
  __typename?: 'JoinHouseholdPayload';
  donorHousehold?: Maybe<Household>;
  errors: Array<ValidationError>;
  receivingHousehold: Household;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** HUD LastGradeCompleted (R4.1) */
export enum LastGradeCompleted {
  /** (11) Associate's degree */
  AssociateSDegree = 'ASSOCIATE_S_DEGREE',
  /** (12) Bachelor's degree */
  BachelorSDegree = 'BACHELOR_S_DEGREE',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (7) GED */
  Ged = 'GED',
  /** (2) Grades 5-6 */
  Grades_5_6 = 'GRADES_5_6',
  /** (3) Grades 7-8 */
  Grades_7_8 = 'GRADES_7_8',
  /** (4) Grades 9-11 */
  Grades_9_11 = 'GRADES_9_11',
  /** (5) Grade 12 */
  Grade_12 = 'GRADE_12',
  /** (13) Graduate degree */
  GraduateDegree = 'GRADUATE_DEGREE',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (1) Less than grade 5 */
  LessThanGrade_5 = 'LESS_THAN_GRADE_5',
  /** (6) School program does not have grade levels */
  SchoolProgramDoesNotHaveGradeLevels = 'SCHOOL_PROGRAM_DOES_NOT_HAVE_GRADE_LEVELS',
  /** (10) Some college */
  SomeCollege = 'SOME_COLLEGE',
  /** (14) Vocational certification */
  VocationalCertification = 'VOCATIONAL_CERTIFICATION',
}

/** HUD LiteralHomelessHistory (V7.C) */
export enum LiteralHomelessHistory {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) Most recent episode occurred in the last year */
  MostRecentEpisodeOccurredInTheLastYear = 'MOST_RECENT_EPISODE_OCCURRED_IN_THE_LAST_YEAR',
  /** (1) Most recent episode occurred more than one year ago */
  MostRecentEpisodeOccurredMoreThanOneYearAgo = 'MOST_RECENT_EPISODE_OCCURRED_MORE_THAN_ONE_YEAR_AGO',
  /** (2) None */
  None = 'NONE',
}

export type MciClearanceInput = {
  dob: Scalars['ISO8601Date']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Array<Gender>>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type MciClearanceMatch = {
  __typename?: 'MciClearanceMatch';
  age: Scalars['Int']['output'];
  dob: Scalars['ISO8601Date']['output'];
  /** ID of existing Client that has the same MCI ID */
  existingClientId?: Maybe<Scalars['ID']['output']>;
  firstName: Scalars['String']['output'];
  gender: Array<Gender>;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  mciId: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  nameSuffix?: Maybe<Scalars['String']['output']>;
  score: Scalars['Int']['output'];
  ssn?: Maybe<Scalars['String']['output']>;
};

export type MergeAuditEvent = {
  __typename?: 'MergeAuditEvent';
  client?: Maybe<Client>;
  clientIdsMerged: Array<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  mergedAt: Scalars['ISO8601DateTime']['output'];
  preMergeState: Scalars['JsonObject']['output'];
  user?: Maybe<ApplicationUser>;
};

export type MergeAuditEventFilterOptions = {
  user?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MergeAuditEventsPaginated = {
  __typename?: 'MergeAuditEventsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<MergeAuditEvent>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Autogenerated input type of MergeClients */
export type MergeClientsInput = {
  clientIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MergeClients. */
export type MergeClientsPayload = {
  __typename?: 'MergeClientsPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** HUD MilitaryBranch (V1.11) */
export enum MilitaryBranch {
  /** (2) Air Force */
  AirForce = 'AIR_FORCE',
  /** (1) Army */
  Army = 'ARMY',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (6) Coast Guard */
  CoastGuard = 'COAST_GUARD',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (4) Marines */
  Marines = 'MARINES',
  /** (3) Navy */
  Navy = 'NAVY',
  /** (7) Space Force */
  SpaceForce = 'SPACE_FORCE',
}

/** HUD MonthsHomelessPastThreeYears (3.917.5) */
export enum MonthsHomelessPastThreeYears {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (113) More than 12 months */
  MoreThan_12Months = 'MORE_THAN_12_MONTHS',
  /** (101) 1 */
  Num_1 = 'NUM_1',
  /** (102) 2 */
  Num_2 = 'NUM_2',
  /** (103) 3 */
  Num_3 = 'NUM_3',
  /** (104) 4 */
  Num_4 = 'NUM_4',
  /** (105) 5 */
  Num_5 = 'NUM_5',
  /** (106) 6 */
  Num_6 = 'NUM_6',
  /** (107) 7 */
  Num_7 = 'NUM_7',
  /** (108) 8 */
  Num_8 = 'NUM_8',
  /** (109) 9 */
  Num_9 = 'NUM_9',
  /** (110) 10 */
  Num_10 = 'NUM_10',
  /** (111) 11 */
  Num_11 = 'NUM_11',
  /** (112) 12 */
  Num_12 = 'NUM_12',
}

/** HUD MostRecentEdStatus (C3.A) */
export enum MostRecentEdStatus {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (6) Higher education: Dropped out */
  HigherEducationDroppedOut = 'HIGHER_EDUCATION_DROPPED_OUT',
  /** (7) Higher education: Obtained a credential/degree */
  HigherEducationObtainedACredentialDegree = 'HIGHER_EDUCATION_OBTAINED_A_CREDENTIAL_DEGREE',
  /** (5) Higher education: Pursuing a credential but not currently attending */
  HigherEducationPursuingACredentialButNotCurrentlyAttending = 'HIGHER_EDUCATION_PURSUING_A_CREDENTIAL_BUT_NOT_CURRENTLY_ATTENDING',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) K12: Dropped out */
  K12DroppedOut = 'K12_DROPPED_OUT',
  /** (4) K12: Expelled */
  K12Expelled = 'K12_EXPELLED',
  /** (0) K12: Graduated from high school */
  K12GraduatedFromHighSchool = 'K12_GRADUATED_FROM_HIGH_SCHOOL',
  /** (1) K12: Obtained GED */
  K12ObtainedGed = 'K12_OBTAINED_GED',
  /** (3) K12: Suspended */
  K12Suspended = 'K12_SUSPENDED',
}

export type Mutation = {
  __typename?: 'Mutation';
  addRecentItem?: Maybe<ApplicationUser>;
  assignStaff?: Maybe<AssignStaffPayload>;
  /** Assign services for a set of Clients. If any client is not enrolled, the client will be enrolled in the project as well. */
  bulkAssignService?: Maybe<BulkAssignServicePayload>;
  bulkMergeClients?: Maybe<BulkMergeClientsPayload>;
  bulkRemoveService?: Maybe<BulkRemoveServicePayload>;
  bulkReviewExternalSubmissions?: Maybe<BulkReviewExternalSubmissionsPayload>;
  /** Perform MCI clearance and return matches */
  clearMci?: Maybe<ClearMciPayload>;
  clearRecentItems?: Maybe<ApplicationUser>;
  createClientAlert?: Maybe<CreateClientAlertPayload>;
  createDirectUpload?: Maybe<DirectUpload>;
  createDuplicateFormDefinition?: Maybe<CreateDuplicateFormDefinitionPayload>;
  createFormDefinition?: Maybe<CreateFormDefinitionPayload>;
  createFormRule?: Maybe<CreateFormRulePayload>;
  createNextDraftFormDefinition?: Maybe<CreateNextDraftFormDefinitionPayload>;
  createProjectConfig?: Maybe<CreateProjectConfigPayload>;
  createScanCardCode?: Maybe<CreateScanCardCodePayload>;
  createServiceType?: Maybe<CreateServiceTypePayload>;
  createUnits?: Maybe<CreateUnitsPayload>;
  deleteAssessment?: Maybe<DeleteAssessmentPayload>;
  deleteCeAssessment?: Maybe<DeleteCeAssessmentPayload>;
  deleteCeEvent?: Maybe<DeleteCeEventPayload>;
  deleteCeParticipation?: Maybe<DeleteCeParticipationPayload>;
  deleteClient?: Maybe<DeleteClientPayload>;
  deleteClientAlert?: Maybe<DeleteClientAlertPayload>;
  deleteClientFile?: Maybe<DeleteClientFilePayload>;
  deleteClientImage?: Maybe<DeleteClientImagePayload>;
  deleteCurrentLivingSituation?: Maybe<DeleteCurrentLivingSituationPayload>;
  deleteCustomCaseNote?: Maybe<DeleteCustomCaseNotePayload>;
  deleteEnrollment?: Maybe<DeleteEnrollmentPayload>;
  deleteExternalFormSubmission?: Maybe<DeleteExternalFormSubmissionPayload>;
  deleteFormDefinition?: Maybe<DeleteFormDefinitionPayload>;
  deleteFormRule?: Maybe<DeleteFormRulePayload>;
  deleteFunder?: Maybe<DeleteFunderPayload>;
  deleteHmisParticipation?: Maybe<DeleteHmisParticipationPayload>;
  deleteInventory?: Maybe<DeleteInventoryPayload>;
  deleteOrganization?: Maybe<DeleteOrganizationPayload>;
  deleteProject?: Maybe<DeleteProjectPayload>;
  deleteProjectCoc?: Maybe<DeleteProjectCocPayload>;
  deleteProjectConfig?: Maybe<DeleteProjectConfigPayload>;
  deleteScanCardCode?: Maybe<DeleteScanCardCodePayload>;
  deleteService?: Maybe<DeleteServicePayload>;
  deleteServiceType?: Maybe<DeleteServiceTypePayload>;
  deleteUnits?: Maybe<DeleteUnitsPayload>;
  joinHousehold?: Maybe<JoinHouseholdPayload>;
  mergeClients?: Maybe<MergeClientsPayload>;
  publishFormDefinition?: Maybe<PublishFormDefinitionPayload>;
  refreshExternalSubmissions?: Maybe<RefreshExternalSubmissionsPayload>;
  restoreScanCardCode?: Maybe<RestoreScanCardCodePayload>;
  /** Create/Save assessment as work-in-progress */
  saveAssessment?: Maybe<SaveAssessmentPayload>;
  splitHousehold?: Maybe<SplitHouseholdPayload>;
  /** Create/Submit assessment, and create/update related HUD records */
  submitAssessment?: Maybe<SubmitAssessmentPayload>;
  /** Submit a form to create/update HUD record(s) */
  submitForm?: Maybe<SubmitFormPayload>;
  /** Submit multiple assessments in a household */
  submitHouseholdAssessments?: Maybe<SubmitHouseholdAssessmentsPayload>;
  unassignStaff?: Maybe<UnassignStaffPayload>;
  updateClientImage?: Maybe<UpdateClientImagePayload>;
  updateExternalFormSubmission?: Maybe<UpdateExternalFormSubmissionPayload>;
  updateFormDefinition?: Maybe<UpdateFormDefinitionPayload>;
  /** @deprecated Replaced with DeleteFormRule */
  updateFormRule?: Maybe<UpdateFormRulePayload>;
  updateProjectConfig?: Maybe<UpdateProjectConfigPayload>;
  /** Update a referral posting */
  updateReferralPosting?: Maybe<UpdateReferralPostingPayload>;
  updateRelationshipToHoH?: Maybe<UpdateRelationshipToHoHPayload>;
  updateServiceType?: Maybe<UpdateServiceTypePayload>;
  updateUnits?: Maybe<UpdateUnitsPayload>;
  /** Void a referral request */
  voidReferralRequest?: Maybe<VoidReferralRequestPayload>;
};

export type MutationAddRecentItemArgs = {
  input: AddRecentItemInput;
};

export type MutationAssignStaffArgs = {
  input: AssignStaffInput;
};

export type MutationBulkAssignServiceArgs = {
  input: BulkAssignServiceInput;
};

export type MutationBulkMergeClientsArgs = {
  input: BulkMergeClientsInput;
};

export type MutationBulkRemoveServiceArgs = {
  projectId: Scalars['ID']['input'];
  serviceIds: Array<Scalars['ID']['input']>;
};

export type MutationBulkReviewExternalSubmissionsArgs = {
  externalSubmissionIds: Array<Scalars['ID']['input']>;
};

export type MutationClearMciArgs = {
  input: ClearMciInput;
};

export type MutationClearRecentItemsArgs = {
  input: ClearRecentItemsInput;
};

export type MutationCreateClientAlertArgs = {
  input: ClientAlertInput;
};

export type MutationCreateDirectUploadArgs = {
  input: CreateDirectUploadInput;
};

export type MutationCreateDuplicateFormDefinitionArgs = {
  identifier: Scalars['String']['input'];
};

export type MutationCreateFormDefinitionArgs = {
  input: FormDefinitionInput;
};

export type MutationCreateFormRuleArgs = {
  input: CreateFormRuleInput;
};

export type MutationCreateNextDraftFormDefinitionArgs = {
  identifier: Scalars['String']['input'];
};

export type MutationCreateProjectConfigArgs = {
  input: ProjectConfigInput;
};

export type MutationCreateScanCardCodeArgs = {
  clientId: Scalars['ID']['input'];
  expirationDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type MutationCreateServiceTypeArgs = {
  input: ServiceTypeInput;
};

export type MutationCreateUnitsArgs = {
  input: CreateUnitsInput;
};

export type MutationDeleteAssessmentArgs = {
  input: DeleteAssessmentInput;
};

export type MutationDeleteCeAssessmentArgs = {
  input: DeleteCeAssessmentInput;
};

export type MutationDeleteCeEventArgs = {
  input: DeleteCeEventInput;
};

export type MutationDeleteCeParticipationArgs = {
  input: DeleteCeParticipationInput;
};

export type MutationDeleteClientArgs = {
  input: DeleteClientInput;
};

export type MutationDeleteClientAlertArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteClientFileArgs = {
  input: DeleteClientFileInput;
};

export type MutationDeleteClientImageArgs = {
  input: DeleteClientImageInput;
};

export type MutationDeleteCurrentLivingSituationArgs = {
  input: DeleteCurrentLivingSituationInput;
};

export type MutationDeleteCustomCaseNoteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteEnrollmentArgs = {
  input: DeleteEnrollmentInput;
};

export type MutationDeleteExternalFormSubmissionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFormDefinitionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFormRuleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFunderArgs = {
  input: DeleteFunderInput;
};

export type MutationDeleteHmisParticipationArgs = {
  input: DeleteHmisParticipationInput;
};

export type MutationDeleteInventoryArgs = {
  input: DeleteInventoryInput;
};

export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};

export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};

export type MutationDeleteProjectCocArgs = {
  input: DeleteProjectCocInput;
};

export type MutationDeleteProjectConfigArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteScanCardCodeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteServiceArgs = {
  input: DeleteServiceInput;
};

export type MutationDeleteServiceTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUnitsArgs = {
  input: DeleteUnitsInput;
};

export type MutationJoinHouseholdArgs = {
  joiningEnrollmentInputs: Array<EnrollmentRelationshipInput>;
  receivingHouseholdId: Scalars['ID']['input'];
};

export type MutationMergeClientsArgs = {
  input: MergeClientsInput;
};

export type MutationPublishFormDefinitionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestoreScanCardCodeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSaveAssessmentArgs = {
  input: SaveAssessmentInput;
};

export type MutationSplitHouseholdArgs = {
  splittingEnrollmentInputs: Array<EnrollmentRelationshipInput>;
};

export type MutationSubmitAssessmentArgs = {
  input: SubmitAssessmentInput;
};

export type MutationSubmitFormArgs = {
  input: SubmitFormInput;
};

export type MutationSubmitHouseholdAssessmentsArgs = {
  input: SubmitHouseholdAssessmentsInput;
};

export type MutationUnassignStaffArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateClientImageArgs = {
  input: UpdateClientImageInput;
};

export type MutationUpdateExternalFormSubmissionArgs = {
  id: Scalars['ID']['input'];
  input: ExternalFormSubmissionInput;
};

export type MutationUpdateFormDefinitionArgs = {
  id: Scalars['ID']['input'];
  input: FormDefinitionInput;
};

export type MutationUpdateFormRuleArgs = {
  input: UpdateFormRuleInput;
};

export type MutationUpdateProjectConfigArgs = {
  id: Scalars['ID']['input'];
  input: ProjectConfigInput;
};

export type MutationUpdateReferralPostingArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<ReferralPostingInput>;
};

export type MutationUpdateRelationshipToHoHArgs = {
  input: UpdateRelationshipToHoHInput;
};

export type MutationUpdateServiceTypeArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<ServiceTypeInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  supportsBulkAssignment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateUnitsArgs = {
  input: UpdateUnitsInput;
};

export type MutationVoidReferralRequestArgs = {
  referralRequestId: Scalars['ID']['input'];
};

/** HUD NameDataQuality (3.01.5) */
export enum NameDataQuality {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (1) Full name reported */
  FullNameReported = 'FULL_NAME_REPORTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) Partial, street name, or code name reported */
  PartialStreetNameOrCodeNameReported = 'PARTIAL_STREET_NAME_OR_CODE_NAME_REPORTED',
}

/** HUD NoAssistanceReason (W3) */
export enum NoAssistanceReason {
  /** (2) Applied; client not eligible */
  AppliedClientNotEligible = 'APPLIED_CLIENT_NOT_ELIGIBLE',
  /** (1) Applied; decision pending */
  AppliedDecisionPending = 'APPLIED_DECISION_PENDING',
  /** (3) Client did not apply */
  ClientDidNotApply = 'CLIENT_DID_NOT_APPLY',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (4) Insurance type not applicable for this client */
  InsuranceTypeNotApplicableForThisClient = 'INSURANCE_TYPE_NOT_APPLICABLE_FOR_THIS_CLIENT',
  /** Invalid Value */
  Invalid = 'INVALID',
}

/** HUD NoYes (1.10) */
export enum NoYes {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) No */
  No = 'NO',
  /** (1) Yes */
  Yes = 'YES',
}

/** HUD NoYesMissing (1.7) */
export enum NoYesMissing {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) No */
  No = 'NO',
  /** (1) Yes */
  Yes = 'YES',
}

/** HUD NoYesReasonsForMissingData (1.8) */
export enum NoYesReasonsForMissingData {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) No */
  No = 'NO',
  /** (1) Yes */
  Yes = 'YES',
}

/** HUD NotEmployedReason (R6.B) */
export enum NotEmployedReason {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (1) Looking for work */
  LookingForWork = 'LOOKING_FOR_WORK',
  /** (3) Not looking for work */
  NotLookingForWork = 'NOT_LOOKING_FOR_WORK',
  /** (2) Unable to work */
  UnableToWork = 'UNABLE_TO_WORK',
}

export type OccurrencePointForm = {
  __typename?: 'OccurrencePointForm';
  dataCollectedAbout: DataCollectedAbout;
  definition: FormDefinition;
  id: Scalars['ID']['output'];
};

/** Results from client/project omnisearch */
export type OmnisearchResult = Client | Project;

export type Organization = {
  __typename?: 'Organization';
  access: OrganizationAccess;
  contactInformation?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<ApplicationUser>;
  customDataElements: Array<CustomDataElement>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hudId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  organizationName: Scalars['String']['output'];
  projects: ProjectsPaginated;
  user?: Maybe<ApplicationUser>;
  victimServiceProvider: NoYesMissing;
};

export type OrganizationProjectsArgs = {
  filters?: InputMaybe<ProjectsForEnrollmentFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ProjectSortOption>;
};

export type OrganizationAccess = {
  __typename?: 'OrganizationAccess';
  canDeleteOrganization: Scalars['Boolean']['output'];
  canEditOrganization: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type OrganizationFilterOptions = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

/** HUD Organization Sorting Options */
export enum OrganizationSortOption {
  Name = 'NAME',
}

export type OrganizationsPaginated = {
  __typename?: 'OrganizationsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Organization>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD PATHReferralOutcome (P2.A) */
export enum PathReferralOutcome {
  /** (1) Attained */
  Attained = 'ATTAINED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) Not attained */
  NotAttained = 'NOT_ATTAINED',
  /** (3) Unknown */
  Unknown = 'UNKNOWN',
}

/** HUD PercentAMI (V4.1) */
export enum PercentAmi {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (1) 30% or less */
  Num_30OrLess = 'NUM_30_OR_LESS',
  /** (2) 31% to 50% */
  Num_31To_50 = 'NUM_31_TO_50',
  /** (3) 51% to 80% */
  Num_51To_80 = 'NUM_51_TO_80',
  /** (4) 81% or greater */
  Num_81OrGreater = 'NUM_81_OR_GREATER',
}

export type PickListOption = {
  __typename?: 'PickListOption';
  /** Code for the option */
  code: Scalars['String']['output'];
  /** Code for group that option belongs to, if grouped */
  groupCode?: Maybe<Scalars['String']['output']>;
  /** Label for group that option belongs to, if grouped */
  groupLabel?: Maybe<Scalars['String']['output']>;
  /** Helper text/html */
  helperText?: Maybe<Scalars['String']['output']>;
  /** Whether option is selected by default */
  initialSelected?: Maybe<Scalars['Boolean']['output']>;
  /** Label for the option */
  label?: Maybe<Scalars['String']['output']>;
  /** Numeric value, such as a score */
  numericValue?: Maybe<Scalars['Int']['output']>;
  /** Secondary label, such as project type or CoC code */
  secondaryLabel?: Maybe<Scalars['String']['output']>;
};

export enum PickListType {
  /**
   * Units available for the given Enrollment at the given project. Includes all
   * available units at project even if they have a different type from what the
   * household is currently occupying.
   */
  AdminAvailableUnitsForEnrollment = 'ADMIN_AVAILABLE_UNITS_FOR_ENROLLMENT',
  AllServiceCategories = 'ALL_SERVICE_CATEGORIES',
  AllServiceTypes = 'ALL_SERVICE_TYPES',
  /** All unit types. */
  AllUnitTypes = 'ALL_UNIT_TYPES',
  /**
   * Assessment names, including custom assessments and assessments that are
   * inactive. If a project is specified, the list is limited to assessments that
   * exist in the project (both active and inactive).
   */
  AssessmentNames = 'ASSESSMENT_NAMES',
  /** Current and historical user accounts */
  AuditableUsers = 'AUDITABLE_USERS',
  AvailableBulkServiceTypes = 'AVAILABLE_BULK_SERVICE_TYPES',
  AvailableFileTypes = 'AVAILABLE_FILE_TYPES',
  AvailableServiceTypes = 'AVAILABLE_SERVICE_TYPES',
  /**
   * Units available for the given Enrollment at the given project. List is limited
   * to units with the same unit type currently occupied by the household, if any.
   */
  AvailableUnitsForEnrollment = 'AVAILABLE_UNITS_FOR_ENROLLMENT',
  /** Unit types that have unoccupied units in the specified project */
  AvailableUnitTypes = 'AVAILABLE_UNIT_TYPES',
  /** Grouped HUD CE Event types */
  CeEvents = 'CE_EVENTS',
  ClientAuditEventRecordTypes = 'CLIENT_AUDIT_EVENT_RECORD_TYPES',
  Coc = 'COC',
  /** Continuum Projects */
  ContinuumProjects = 'CONTINUUM_PROJECTS',
  CurrentLivingSituation = 'CURRENT_LIVING_SITUATION',
  CustomServiceCategories = 'CUSTOM_SERVICE_CATEGORIES',
  Destination = 'DESTINATION',
  /** Current users who are eligible for staff assignment */
  EligibleStaffAssignmentUsers = 'ELIGIBLE_STAFF_ASSIGNMENT_USERS',
  /** Projects that the User can enroll Clients in */
  EnrollableProjects = 'ENROLLABLE_PROJECTS',
  /** Enrollments for the client, including WIP and Exited. */
  EnrollmentsForClient = 'ENROLLMENTS_FOR_CLIENT',
  EnrollmentAuditEventRecordTypes = 'ENROLLMENT_AUDIT_EVENT_RECORD_TYPES',
  /** External form types for the project. */
  ExternalFormTypesForProject = 'EXTERNAL_FORM_TYPES_FOR_PROJECT',
  /** Form Types */
  FormTypes = 'FORM_TYPES',
  Geocode = 'GEOCODE',
  /** Open HoH enrollments at the project. */
  OpenHohEnrollmentsForProject = 'OPEN_HOH_ENROLLMENTS_FOR_PROJECT',
  /** Open Projects that the user can see */
  OpenProjects = 'OPEN_PROJECTS',
  /** All Organizations that the User can see */
  Organization = 'ORGANIZATION',
  /** OtherFunder values for all Funders across the installation */
  OtherFunders = 'OTHER_FUNDERS',
  /** Unit types that are eligible to be added to project */
  PossibleUnitTypesForProject = 'POSSIBLE_UNIT_TYPES_FOR_PROJECT',
  PriorLivingSituation = 'PRIOR_LIVING_SITUATION',
  /** All Projects that the User can see */
  Project = 'PROJECT',
  /** Open Projects that can receive referrals */
  ProjectsReceivingReferrals = 'PROJECTS_RECEIVING_REFERRALS',
  ReferralOutcome = 'REFERRAL_OUTCOME',
  /** Residential Projects */
  ResidentialProjects = 'RESIDENTIAL_PROJECTS',
  /** Staff Assignment Relationships */
  StaffAssignmentRelationships = 'STAFF_ASSIGNMENT_RELATIONSHIPS',
  State = 'STATE',
  SubTypeProvided_3 = 'SUB_TYPE_PROVIDED_3',
  SubTypeProvided_4 = 'SUB_TYPE_PROVIDED_4',
  SubTypeProvided_5 = 'SUB_TYPE_PROVIDED_5',
  /** User accounts. Deprecated in favor of AUDITABLE_USERS */
  Users = 'USERS',
}

/** HUD PreferredLanguage (C4.A) */
export enum PreferredLanguage {
  /** (100) Acholi */
  Acholi = 'ACHOLI',
  /** (101) Afar */
  Afar = 'AFAR',
  /** (102) Afrikaans */
  Afrikaans = 'AFRIKAANS',
  /** (103) Ahtna */
  Ahtna = 'AHTNA',
  /** (104) Akan */
  Akan = 'AKAN',
  /** (105) Akateko */
  Akateko = 'AKATEKO',
  /** (106) Akuzipigestun / St. Lawrence Island Yupik (aka Siberian Yupik) */
  AkuzipigestunStLawrenceIslandYupik = 'AKUZIPIGESTUN_ST_LAWRENCE_ISLAND_YUPIK',
  /** (107) Albanian */
  Albanian = 'ALBANIAN',
  /** (108) Algonquian */
  Algonquian = 'ALGONQUIAN',
  /** (109) Alutiiq */
  Alutiiq = 'ALUTIIQ',
  /** (110) American Sign Language */
  AmericanSignLanguage = 'AMERICAN_SIGN_LANGUAGE',
  /** (111) Amharic */
  Amharic = 'AMHARIC',
  /** (112) Anuak */
  Anuak = 'ANUAK',
  /** (113) Apache */
  Apache = 'APACHE',
  /** (114) Arabic */
  Arabic = 'ARABIC',
  /** (115) Armenian */
  Armenian = 'ARMENIAN',
  /** (116) Assyrian */
  Assyrian = 'ASSYRIAN',
  /** (117) Atnakenaege’ / Ahtna */
  AtnakenaegeAhtna = 'ATNAKENAEGE_AHTNA',
  /** (118) Aymara */
  Aymara = 'AYMARA',
  /** (119) Azerbaijani */
  Azerbaijani = 'AZERBAIJANI',
  /** (120) Bahasa */
  Bahasa = 'BAHASA',
  /** (121) Bahdini */
  Bahdini = 'BAHDINI',
  /** (122) Bajuni */
  Bajuni = 'BAJUNI',
  /** (123) Bambara */
  Bambara = 'BAMBARA',
  /** (124) Bantu */
  Bantu = 'BANTU',
  /** (125) Barese */
  Barese = 'BARESE',
  /** (126) Basque */
  Basque = 'BASQUE',
  /** (127) Bassa */
  Bassa = 'BASSA',
  /** (128) Belorussian */
  Belorussian = 'BELORUSSIAN',
  /** (129) Bemba */
  Bemba = 'BEMBA',
  /** (130) Benaadir */
  Benaadir = 'BENAADIR',
  /** (131) Bengali */
  Bengali = 'BENGALI',
  /** (132) Berber */
  Berber = 'BERBER',
  /** (133) Black American Sign Language */
  BlackAmericanSignLanguage = 'BLACK_AMERICAN_SIGN_LANGUAGE',
  /** (134) Bosnian */
  Bosnian = 'BOSNIAN',
  /** (135) Bravanese */
  Bravanese = 'BRAVANESE',
  /** (136) Bulgarian */
  Bulgarian = 'BULGARIAN',
  /** (137) Burmese */
  Burmese = 'BURMESE',
  /** (138) Cambodian */
  Cambodian = 'CAMBODIAN',
  /** (139) Cantonese */
  Cantonese = 'CANTONESE',
  /** (140) Cape Verdean Creole */
  CapeVerdeanCreole = 'CAPE_VERDEAN_CREOLE',
  /** (141) Catalan */
  Catalan = 'CATALAN',
  /** (142) Cebuano */
  Cebuano = 'CEBUANO',
  /** (143) Central Alaskan Yup’ik / Yugtun */
  CentralAlaskanYupIkYugtun = 'CENTRAL_ALASKAN_YUP_IK_YUGTUN',
  /** (144) Chaldean */
  Chaldean = 'CHALDEAN',
  /** (145) Chamorro */
  Chamorro = 'CHAMORRO',
  /** (146) Chaochow */
  Chaochow = 'CHAOCHOW',
  /** (147) Cherokee */
  Cherokee = 'CHEROKEE',
  /** (148) Chinese */
  Chinese = 'CHINESE',
  /** (149) Chipewyan */
  Chipewyan = 'CHIPEWYAN',
  /** (150) Choctaw */
  Choctaw = 'CHOCTAW',
  /** (151) Chuukese */
  Chuukese = 'CHUUKESE',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (152) Cree */
  Cree = 'CREE',
  /** (153) Croatian */
  Croatian = 'CROATIAN',
  /** (154) Czech */
  Czech = 'CZECH',
  /** (155) Dakota */
  Dakota = 'DAKOTA',
  /** (156) Danish */
  Danish = 'DANISH',
  /** (157) Dari */
  Dari = 'DARI',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (158) Deg Xinag */
  DegXinag = 'DEG_XINAG',
  /** (160) Denaakk'e / Koyukon */
  DenaakkEKoyukon = 'DENAAKK_E_KOYUKON',
  /** (159) Dena'inaq' / Dena'ina */
  DenaInaqDenaIna = 'DENA_INAQ_DENA_INA',
  /** (161) Dewoin */
  Dewoin = 'DEWOIN',
  /** (21) Different preferred language */
  DifferentPreferredLanguage = 'DIFFERENT_PREFERRED_LANGUAGE',
  /** (162) Dinak'i / Upper Kuskokwim */
  DinakIUpperKuskokwim = 'DINAK_I_UPPER_KUSKOKWIM',
  /** (163) Dinjii Zhuh K'yaa / Gwich'in */
  DinjiiZhuhKYaaGwichIn = 'DINJII_ZHUH_K_YAA_GWICH_IN',
  /** (164) Dinka */
  Dinka = 'DINKA',
  /** (165) Doogh Qinaq / Holikachuk */
  DooghQinaqHolikachuk = 'DOOGH_QINAQ_HOLIKACHUK',
  /** (166) Duala */
  Duala = 'DUALA',
  /** (167) Dutch */
  Dutch = 'DUTCH',
  /** (168) Dzongkha */
  Dzongkha = 'DZONGKHA',
  /** (169) Edo */
  Edo = 'EDO',
  /** (170) Ekegusli */
  Ekegusli = 'EKEGUSLI',
  /** (171) English */
  English = 'ENGLISH',
  /** (172) Estonian */
  Estonian = 'ESTONIAN',
  /** (173) Ewe */
  Ewe = 'EWE',
  /** (174) Eyak */
  Eyak = 'EYAK',
  /** (175) Farsi */
  Farsi = 'FARSI',
  /** (176) Fijian */
  Fijian = 'FIJIAN',
  /** (177) Filipino */
  Filipino = 'FILIPINO',
  /** (178) Finnish */
  Finnish = 'FINNISH',
  /** (179) Flemish */
  Flemish = 'FLEMISH',
  /** (180) French */
  French = 'FRENCH',
  /** (181) French Cajun */
  FrenchCajun = 'FRENCH_CAJUN',
  /** (182) French Canadian */
  FrenchCanadian = 'FRENCH_CANADIAN',
  /** (183) French Creole */
  FrenchCreole = 'FRENCH_CREOLE',
  /** (184) French Haitian */
  FrenchHaitian = 'FRENCH_HAITIAN',
  /** (185) Fukienese */
  Fukienese = 'FUKIENESE',
  /** (186) Fulani */
  Fulani = 'FULANI',
  /** (187) Fuzhou */
  Fuzhou = 'FUZHOU',
  /** (188) Ga */
  Ga = 'GA',
  /** (189) Gaddang */
  Gaddang = 'GADDANG',
  /** (190) Gaelic */
  Gaelic = 'GAELIC',
  /** (191) Garre */
  Garre = 'GARRE',
  /** (192) Gen */
  Gen = 'GEN',
  /** (193) Georgian */
  Georgian = 'GEORGIAN',
  /** (194) German */
  German = 'GERMAN',
  /** (195) Gheg */
  Gheg = 'GHEG',
  /** (196) Gokana */
  Gokana = 'GOKANA',
  /** (197) Greek */
  Greek = 'GREEK',
  /** (198) Gujarati */
  Gujarati = 'GUJARATI',
  /** (199) Gulay */
  Gulay = 'GULAY',
  /** (200) Gullah */
  Gullah = 'GULLAH',
  /** (201) Gurani */
  Gurani = 'GURANI',
  /** (202) Gwich'in */
  GwichIn = 'GWICH_IN',
  /** (203) Haida */
  Haida = 'HAIDA',
  /** (204) Haitian */
  Haitian = 'HAITIAN',
  /** (205) Haitian Creole */
  HaitianCreole = 'HAITIAN_CREOLE',
  /** (206) Hakka */
  Hakka = 'HAKKA',
  /** (208) Hassaniyya */
  Hassaniyya = 'HASSANIYYA',
  /** (209) Hausa */
  Hausa = 'HAUSA',
  /** (211) Hawaiian */
  Hawaiian = 'HAWAIIAN',
  /** (210) Hawai'i Sign Language */
  HawaiISignLanguage = 'HAWAI_I_SIGN_LANGUAGE',
  /** (212) Hebrew */
  Hebrew = 'HEBREW',
  /** (213) Hiligaynon */
  Hiligaynon = 'HILIGAYNON',
  /** (214) Hindi */
  Hindi = 'HINDI',
  /** (215) Hindko */
  Hindko = 'HINDKO',
  /** (216) Hmong */
  Hmong = 'HMONG',
  /** (217) Hokkien */
  Hokkien = 'HOKKIEN',
  /** (218) Holikachuk */
  Holikachuk = 'HOLIKACHUK',
  /** (219) Hopi */
  Hopi = 'HOPI',
  /** (220) Huanese */
  Huanese = 'HUANESE',
  /** (221) Hungarian */
  Hungarian = 'HUNGARIAN',
  /** (207) Häl golan / Hän */
  HLGolanHN = 'H_L_GOLAN_H_N',
  /** (222) Ibanag */
  Ibanag = 'IBANAG',
  /** (223) Icelandic */
  Icelandic = 'ICELANDIC',
  /** (224) Igbo */
  Igbo = 'IGBO',
  /** (225) Ilocano */
  Ilocano = 'ILOCANO',
  /** (226) Indonesian */
  Indonesian = 'INDONESIAN',
  /** (227) Inuktitut */
  Inuktitut = 'INUKTITUT',
  /** (228) Inupiatun / Inupiaq */
  InupiatunInupiaq = 'INUPIATUN_INUPIAQ',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (229) Italian */
  Italian = 'ITALIAN',
  /** (230) Jakartanese */
  Jakartanese = 'JAKARTANESE',
  /** (231) Jamaican Patois */
  JamaicanPatois = 'JAMAICAN_PATOIS',
  /** (232) Japanese */
  Japanese = 'JAPANESE',
  /** (233) Jarai */
  Jarai = 'JARAI',
  /** (234) Javanese */
  Javanese = 'JAVANESE',
  /** (235) Jingpho */
  Jingpho = 'JINGPHO',
  /** (236) Jinyu */
  Jinyu = 'JINYU',
  /** (237) Juba Arabic */
  JubaArabic = 'JUBA_ARABIC',
  /** (238) Jula */
  Jula = 'JULA',
  /** (239) Kaba */
  Kaba = 'KABA',
  /** (240) Kamba */
  Kamba = 'KAMBA',
  /** (241) Kam Muang */
  KamMuang = 'KAM_MUANG',
  /** (242) Kanjobal */
  Kanjobal = 'KANJOBAL',
  /** (243) Kannada */
  Kannada = 'KANNADA',
  /** (244) Karen */
  Karen = 'KAREN',
  /** (245) Kashmiri */
  Kashmiri = 'KASHMIRI',
  /** (246) Kayah */
  Kayah = 'KAYAH',
  /** (247) Kazakh */
  Kazakh = 'KAZAKH',
  /** (248) Keresan */
  Keresan = 'KERESAN',
  /** (249) Keresan Sign Language */
  KeresanSignLanguage = 'KERESAN_SIGN_LANGUAGE',
  /** (250) Kham */
  Kham = 'KHAM',
  /** (251) Khana */
  Khana = 'KHANA',
  /** (252) Khmer */
  Khmer = 'KHMER',
  /** (254) Kikuyu */
  Kikuyu = 'KIKUYU',
  /** (255) Kimiiru */
  Kimiiru = 'KIMIIRU',
  /** (256) Kinyarwanda */
  Kinyarwanda = 'KINYARWANDA',
  /** (257) Kiowa */
  Kiowa = 'KIOWA',
  /** (345) Kirundi */
  Kirundi = 'KIRUNDI',
  /** (258) Koho */
  Koho = 'KOHO',
  /** (259) Korean */
  Korean = 'KOREAN',
  /** (260) Koyukon */
  Koyukon = 'KOYUKON',
  /** (261) Krahn */
  Krahn = 'KRAHN',
  /** (262) Krio */
  Krio = 'KRIO',
  /** (263) Kunama */
  Kunama = 'KUNAMA',
  /** (264) Kurmanji */
  Kurmanji = 'KURMANJI',
  /** (265) Kyrgyz */
  Kyrgyz = 'KYRGYZ',
  /** (253) K'iche' */
  KIche = 'K_ICHE',
  /** (266) Lakota */
  Lakota = 'LAKOTA',
  /** (267) Laotian */
  Laotian = 'LAOTIAN',
  /** (268) Latvian */
  Latvian = 'LATVIAN',
  /** (269) Liberian Pidgin English */
  LiberianPidginEnglish = 'LIBERIAN_PIDGIN_ENGLISH',
  /** (270) Lingala */
  Lingala = 'LINGALA',
  /** (271) Lingít / Tlingit */
  LingTTlingit = 'LING_T_TLINGIT',
  /** (272) Lithuanian */
  Lithuanian = 'LITHUANIAN',
  /** (273) Lombard */
  Lombard = 'LOMBARD',
  /** (274) Luba-Kasai */
  LubaKasai = 'LUBA_KASAI',
  /** (275) Luganda */
  Luganda = 'LUGANDA',
  /** (276) Luo */
  Luo = 'LUO',
  /** (277) Maay */
  Maay = 'MAAY',
  /** (278) Macedonian */
  Macedonian = 'MACEDONIAN',
  /** (279) Malay */
  Malay = 'MALAY',
  /** (280) Malayalam */
  Malayalam = 'MALAYALAM',
  /** (281) Maltese */
  Maltese = 'MALTESE',
  /** (282) Mam */
  Mam = 'MAM',
  /** (283) Mandarin */
  Mandarin = 'MANDARIN',
  /** (284) Mandinka */
  Mandinka = 'MANDINKA',
  /** (285) Maninka */
  Maninka = 'MANINKA',
  /** (286) Manobo */
  Manobo = 'MANOBO',
  /** (287) Marathi */
  Marathi = 'MARATHI',
  /** (288) Marka */
  Marka = 'MARKA',
  /** (289) Marshallese */
  Marshallese = 'MARSHALLESE',
  /** (290) Masalit */
  Masalit = 'MASALIT',
  /** (291) Mbay */
  Mbay = 'MBAY',
  /** (292) Mien */
  Mien = 'MIEN',
  /** (293) Mirpuri */
  Mirpuri = 'MIRPURI',
  /** (294) Mixteco */
  Mixteco = 'MIXTECO',
  /** (295) Mizo */
  Mizo = 'MIZO',
  /** (296) Mnong */
  Mnong = 'MNONG',
  /** (297) Mongolian */
  Mongolian = 'MONGOLIAN',
  /** (298) Montenegrin */
  Montenegrin = 'MONTENEGRIN',
  /** (299) Moroccan Arabic */
  MoroccanArabic = 'MOROCCAN_ARABIC',
  /** (300) Mortlockese */
  Mortlockese = 'MORTLOCKESE',
  /** (301) Muscogee */
  Muscogee = 'MUSCOGEE',
  /** (302) Napoletano */
  Napoletano = 'NAPOLETANO',
  /** (303) Navajo */
  Navajo = 'NAVAJO',
  /** (304) Navajo Family Sign */
  NavajoFamilySign = 'NAVAJO_FAMILY_SIGN',
  /** (305) Ndebele */
  Ndebele = 'NDEBELE',
  /** (306) Neapolitan */
  Neapolitan = 'NEAPOLITAN',
  /** (307) Nee’aandeg’ / Tanacross */
  NeeAandegTanacross = 'NEE_AANDEG_TANACROSS',
  /** (308) Nepali */
  Nepali = 'NEPALI',
  /** (309) Ngambay */
  Ngambay = 'NGAMBAY',
  /** (310) Nigerian Pidgin */
  NigerianPidgin = 'NIGERIAN_PIDGIN',
  /** (311) Northern Sotho */
  NorthernSotho = 'NORTHERN_SOTHO',
  /** (312) Norwegian */
  Norwegian = 'NORWEGIAN',
  /** (313) Nuer */
  Nuer = 'NUER',
  /** (314) Nupe */
  Nupe = 'NUPE',
  /** (315) Nyanja */
  Nyanja = 'NYANJA',
  /** (316) Nyoro */
  Nyoro = 'NYORO',
  /** (318) Ojibwe */
  Ojibwe = 'OJIBWE',
  /** (319) Oromo */
  Oromo = 'OROMO',
  /** (317) O'odham */
  OOdham = 'O_ODHAM',
  /** (320) Pampangan */
  Pampangan = 'PAMPANGAN',
  /** (321) Papiamento */
  Papiamento = 'PAPIAMENTO',
  /** (322) Pashto */
  Pashto = 'PASHTO',
  /** (323) Pennsylvania Dutch */
  PennsylvaniaDutch = 'PENNSYLVANIA_DUTCH',
  /** (324) Persian */
  Persian = 'PERSIAN',
  /** (325) Plains Sign Language */
  PlainsSignLanguage = 'PLAINS_SIGN_LANGUAGE',
  /** (326) Plateau Sign Language */
  PlateauSignLanguage = 'PLATEAU_SIGN_LANGUAGE',
  /** (327) Plautdietsch */
  Plautdietsch = 'PLAUTDIETSCH',
  /** (328) Pohnpeian */
  Pohnpeian = 'POHNPEIAN',
  /** (329) Polish */
  Polish = 'POLISH',
  /** (330) Portuguese */
  Portuguese = 'PORTUGUESE',
  /** (331) Portuguese Brazilian */
  PortugueseBrazilian = 'PORTUGUESE_BRAZILIAN',
  /** (332) Portuguese Cape Verdean */
  PortugueseCapeVerdean = 'PORTUGUESE_CAPE_VERDEAN',
  /** (333) Portuguese Creole */
  PortugueseCreole = 'PORTUGUESE_CREOLE',
  /** (334) Puerto Rican Sign Language */
  PuertoRicanSignLanguage = 'PUERTO_RICAN_SIGN_LANGUAGE',
  /** (335) Pugliese */
  Pugliese = 'PUGLIESE',
  /** (336) Pulaar */
  Pulaar = 'PULAAR',
  /** (337) Punjabi */
  Punjabi = 'PUNJABI',
  /** (338) Putian */
  Putian = 'PUTIAN',
  /** (339) Quechua */
  Quechua = 'QUECHUA',
  /** (340) Quichua */
  Quichua = 'QUICHUA',
  /** (341) Rade */
  Rade = 'RADE',
  /** (342) Rakhine */
  Rakhine = 'RAKHINE',
  /** (343) Rohingya */
  Rohingya = 'ROHINGYA',
  /** (344) Romanian */
  Romanian = 'ROMANIAN',
  /** (346) Russian */
  Russian = 'RUSSIAN',
  /** (347) Samoan */
  Samoan = 'SAMOAN',
  /** (348) Samoan Sign Language */
  SamoanSignLanguage = 'SAMOAN_SIGN_LANGUAGE',
  /** (349) Sango */
  Sango = 'SANGO',
  /** (350) Seraiki */
  Seraiki = 'SERAIKI',
  /** (351) Serbian */
  Serbian = 'SERBIAN',
  /** (352) Shanghainese */
  Shanghainese = 'SHANGHAINESE',
  /** (353) Shona */
  Shona = 'SHONA',
  /** (354) Sichuan Yi */
  SichuanYi = 'SICHUAN_YI',
  /** (355) Sicilian */
  Sicilian = 'SICILIAN',
  /** (356) Sindhi */
  Sindhi = 'SINDHI',
  /** (357) Sinhalese */
  Sinhalese = 'SINHALESE',
  /** (358) Sioux */
  Sioux = 'SIOUX',
  /** (359) Slovak */
  Slovak = 'SLOVAK',
  /** (360) Slovenian */
  Slovenian = 'SLOVENIAN',
  /** (361) Sm’algyax / (Coast) Tsimshian */
  SmAlgyaxCoastTsimshian = 'SM_ALGYAX_COAST_TSIMSHIAN',
  /** (362) Soga */
  Soga = 'SOGA',
  /** (363) Somali */
  Somali = 'SOMALI',
  /** (364) Soninke */
  Soninke = 'SONINKE',
  /** (365) Sorani */
  Sorani = 'SORANI',
  /** (366) Sothern Sotho */
  SothernSotho = 'SOTHERN_SOTHO',
  /** (367) Spanish */
  Spanish = 'SPANISH',
  /** (368) Spanish Creole */
  SpanishCreole = 'SPANISH_CREOLE',
  /** (369) Sudanese Arabic */
  SudaneseArabic = 'SUDANESE_ARABIC',
  /** (370) Sugpiaq / Alutiiq */
  SugpiaqAlutiiq = 'SUGPIAQ_ALUTIIQ',
  /** (371) Sunda */
  Sunda = 'SUNDA',
  /** (372) Susu */
  Susu = 'SUSU',
  /** (373) Swahili */
  Swahili = 'SWAHILI',
  /** (374) Swati */
  Swati = 'SWATI',
  /** (375) Swedish */
  Swedish = 'SWEDISH',
  /** (376) Sylhetti */
  Sylhetti = 'SYLHETTI',
  /** (377) Tagalog */
  Tagalog = 'TAGALOG',
  /** (378) Taiwanese */
  Taiwanese = 'TAIWANESE',
  /** (379) Tajik */
  Tajik = 'TAJIK',
  /** (380) Tamil */
  Tamil = 'TAMIL',
  /** (381) Tanacross */
  Tanacross = 'TANACROSS',
  /** (382) Tanana */
  Tanana = 'TANANA',
  /** (383) Telugu */
  Telugu = 'TELUGU',
  /** (384) Thai */
  Thai = 'THAI',
  /** (385) Tibetan */
  Tibetan = 'TIBETAN',
  /** (386) Tigre */
  Tigre = 'TIGRE',
  /** (387) Tigrigna */
  Tigrigna = 'TIGRIGNA',
  /** (388) Tlingit */
  Tlingit = 'TLINGIT',
  /** (389) Toishanese */
  Toishanese = 'TOISHANESE',
  /** (390) Tongan */
  Tongan = 'TONGAN',
  /** (391) Tooro */
  Tooro = 'TOORO',
  /** (392) Trique */
  Trique = 'TRIQUE',
  /** (393) Tsimshian */
  Tsimshian = 'TSIMSHIAN',
  /** (394) Tsonga */
  Tsonga = 'TSONGA',
  /** (395) Tswana */
  Tswana = 'TSWANA',
  /** (396) Turkish */
  Turkish = 'TURKISH',
  /** (397) Turkmen */
  Turkmen = 'TURKMEN',
  /** (398) Twi */
  Twi = 'TWI',
  /** (399) Tzotzil */
  Tzotzil = 'TZOTZIL',
  /** (400) Ukrainian */
  Ukrainian = 'UKRAINIAN',
  /** (401) Unangam Tunuu / Aleutian Aleut */
  UnangamTunuuAleutianAleut = 'UNANGAM_TUNUU_ALEUTIAN_ALEUT',
  /** (402) Upper Kuskokwim */
  UpperKuskokwim = 'UPPER_KUSKOKWIM',
  /** (403) Urdu */
  Urdu = 'URDU',
  /** (404) Uyghur */
  Uyghur = 'UYGHUR',
  /** (405) Uzbek */
  Uzbek = 'UZBEK',
  /** (406) Venda */
  Venda = 'VENDA',
  /** (407) Vietnamese */
  Vietnamese = 'VIETNAMESE',
  /** (408) Visayan */
  Visayan = 'VISAYAN',
  /** (409) Welsh */
  Welsh = 'WELSH',
  /** (410) Wodaabe */
  Wodaabe = 'WODAABE',
  /** (411) Wolof */
  Wolof = 'WOLOF',
  /** (412) Wuzhou */
  Wuzhou = 'WUZHOU',
  /** (413) Xaat Kíl / Haida */
  XaatKLHaida = 'XAAT_K_L_HAIDA',
  /** (414) Xhosa */
  Xhosa = 'XHOSA',
  /** (415) Xiang */
  Xiang = 'XIANG',
  /** (416) Yemeni Arabic */
  YemeniArabic = 'YEMENI_ARABIC',
  /** (417) Yiddish */
  Yiddish = 'YIDDISH',
  /** (418) Yoruba */
  Yoruba = 'YORUBA',
  /** (419) Yunnanese */
  Yunnanese = 'YUNNANESE',
  /** (420) Yupik */
  Yupik = 'YUPIK',
  /** (421) Zapoteco */
  Zapoteco = 'ZAPOTECO',
  /** (422) Zarma */
  Zarma = 'ZARMA',
  /** (423) Zo */
  Zo = 'ZO',
  /** (424) Zulu */
  Zulu = 'ZULU',
  /** (425) Zuni */
  Zuni = 'ZUNI',
  /** (426) Zyphe */
  Zyphe = 'ZYPHE',
}

/** HUD PriorLivingSituation (3.917) */
export enum PriorLivingSituation {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (101) Emergency shelter, including hotel or motel paid for with emergency shelter voucher, or Host Home shelter */
  EmergencyShelterIncludingHotelOrMotelPaidForWithEmergencyShelterVoucherOrHostHomeShelter = 'EMERGENCY_SHELTER_INCLUDING_HOTEL_OR_MOTEL_PAID_FOR_WITH_EMERGENCY_SHELTER_VOUCHER_OR_HOST_HOME_SHELTER',
  /** (215) Foster care home or foster care group home */
  FosterCareHomeOrFosterCareGroupHome = 'FOSTER_CARE_HOME_OR_FOSTER_CARE_GROUP_HOME',
  /** (206) Hospital or other residential non-psychiatric medical facility */
  HospitalOrOtherResidentialNonPsychiatricMedicalFacility = 'HOSPITAL_OR_OTHER_RESIDENTIAL_NON_PSYCHIATRIC_MEDICAL_FACILITY',
  /** (332) Host Home (non-crisis) */
  HostHomeNonCrisis = 'HOST_HOME_NON_CRISIS',
  /** (314) Hotel or motel paid for without emergency shelter voucher */
  HotelOrMotelPaidForWithoutEmergencyShelterVoucher = 'HOTEL_OR_MOTEL_PAID_FOR_WITHOUT_EMERGENCY_SHELTER_VOUCHER',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (207) Jail, prison or juvenile detention facility */
  JailPrisonOrJuvenileDetentionFacility = 'JAIL_PRISON_OR_JUVENILE_DETENTION_FACILITY',
  /** (225) Long-term care facility or nursing home */
  LongTermCareFacilityOrNursingHome = 'LONG_TERM_CARE_FACILITY_OR_NURSING_HOME',
  /** (411) Owned by client, no ongoing housing subsidy */
  OwnedByClientNoOngoingHousingSubsidy = 'OWNED_BY_CLIENT_NO_ONGOING_HOUSING_SUBSIDY',
  /** (421) Owned by client, with ongoing housing subsidy */
  OwnedByClientWithOngoingHousingSubsidy = 'OWNED_BY_CLIENT_WITH_ONGOING_HOUSING_SUBSIDY',
  /**
   * (116) Place not meant for habitation (e.g., a vehicle, an abandoned building,
   * bus/train/subway station/airport or anywhere outside)
   */
  PlaceNotMeantForHabitation = 'PLACE_NOT_MEANT_FOR_HABITATION',
  /** (204) Psychiatric hospital or other psychiatric facility */
  PsychiatricHospitalOrOtherPsychiatricFacility = 'PSYCHIATRIC_HOSPITAL_OR_OTHER_PSYCHIATRIC_FACILITY',
  /** (410) Rental by client, no ongoing housing subsidy */
  RentalByClientNoOngoingHousingSubsidy = 'RENTAL_BY_CLIENT_NO_ONGOING_HOUSING_SUBSIDY',
  /** (435) Rental by client, with ongoing housing subsidy */
  RentalByClientWithOngoingHousingSubsidy = 'RENTAL_BY_CLIENT_WITH_ONGOING_HOUSING_SUBSIDY',
  /** (329) Residential project or halfway house with no homeless criteria */
  ResidentialProjectOrHalfwayHouseWithNoHomelessCriteria = 'RESIDENTIAL_PROJECT_OR_HALFWAY_HOUSE_WITH_NO_HOMELESS_CRITERIA',
  /** (118) Safe Haven */
  SafeHaven = 'SAFE_HAVEN',
  /** (335) Staying or living in a family member’s room, apartment, or house */
  StayingOrLivingInAFamilyMemberSRoomApartmentOrHouse = 'STAYING_OR_LIVING_IN_A_FAMILY_MEMBER_S_ROOM_APARTMENT_OR_HOUSE',
  /** (336) Staying or living in a friend's room, apartment or house */
  StayingOrLivingInAFriendSRoomApartmentOrHouse = 'STAYING_OR_LIVING_IN_A_FRIEND_S_ROOM_APARTMENT_OR_HOUSE',
  /** (205) Substance abuse treatment facility or detox center */
  SubstanceAbuseTreatmentFacilityOrDetoxCenter = 'SUBSTANCE_ABUSE_TREATMENT_FACILITY_OR_DETOX_CENTER',
  /** (302) Transitional housing for homeless persons (including homeless youth) */
  TransitionalHousingForHomelessPersons = 'TRANSITIONAL_HOUSING_FOR_HOMELESS_PERSONS',
}

/** HUD PrioritizationStatus (4.19.7) */
export enum PrioritizationStatus {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) Not placed on prioritization list */
  NotPlacedOnPrioritizationList = 'NOT_PLACED_ON_PRIORITIZATION_LIST',
  /** (1) Placed on prioritization list */
  PlacedOnPrioritizationList = 'PLACED_ON_PRIORITIZATION_LIST',
}

export type Project = {
  __typename?: 'Project';
  HOPWAMedAssistedLivingFac?: Maybe<HopwaMedAssistedLivingFac>;
  access: ProjectAccess;
  active: Scalars['Boolean']['output'];
  affiliatedProjects: Array<Project>;
  assessments: AssessmentsPaginated;
  ceParticipations: CeParticipationsPaginated;
  contactInformation?: Maybe<Scalars['String']['output']>;
  continuumProject?: Maybe<NoYes>;
  createdBy?: Maybe<ApplicationUser>;
  currentLivingSituations: CurrentLivingSituationsPaginated;
  customDataElements: Array<CustomDataElement>;
  /** Occurrence Point data collection features that are enabled for this Project (e.g. Current Living Situations, Events) */
  dataCollectionFeatures: Array<DataCollectionFeature>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enrollments: EnrollmentsPaginated;
  externalFormSubmissions: ExternalFormSubmissionsPaginated;
  funders: FundersPaginated;
  hasUnits: Scalars['Boolean']['output'];
  hmisParticipations: HmisParticipationsPaginated;
  households: HouseholdsPaginated;
  housingType?: Maybe<HousingType>;
  hudId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  incomingReferralPostings: ReferralPostingsPaginated;
  inventories: InventoriesPaginated;
  /** Forms for individual data elements that are collected at occurrence for this Project (e.g. Move-In Date) */
  occurrencePointForms: Array<OccurrencePointForm>;
  operatingEndDate?: Maybe<Scalars['ISO8601Date']['output']>;
  operatingStartDate?: Maybe<Scalars['ISO8601Date']['output']>;
  organization: Organization;
  outgoingReferralPostings: ReferralPostingsPaginated;
  projectCocs: ProjectCocsPaginated;
  projectName: Scalars['String']['output'];
  projectType?: Maybe<ProjectType>;
  referralRequests: ReferralRequestsPaginated;
  residentialAffiliation?: Maybe<NoYes>;
  residentialAffiliationProjectIds: Array<Scalars['ID']['output']>;
  residentialAffiliationProjects: Array<Project>;
  rrhSubType?: Maybe<RrhSubType>;
  /** Service types that are collected for this Project */
  serviceTypes: Array<ServiceType>;
  services: ServicesPaginated;
  staffAssignmentsEnabled: Scalars['Boolean']['output'];
  targetPopulation?: Maybe<TargetPopulation>;
  unitTypes: Array<UnitTypeCapacity>;
  units: UnitsPaginated;
  user?: Maybe<ApplicationUser>;
};

export type ProjectAssessmentsArgs = {
  filters?: InputMaybe<AssessmentsForProjectFilterOptions>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<AssessmentSortOption>;
};

export type ProjectCeParticipationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectCurrentLivingSituationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectEnrollmentsArgs = {
  filters?: InputMaybe<EnrollmentsForProjectFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<EnrollmentSortOption>;
};

export type ProjectExternalFormSubmissionsArgs = {
  filters?: InputMaybe<ExternalFormSubmissionFilterOptions>;
  formDefinitionIdentifier: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectFundersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<FunderSortOption>;
};

export type ProjectHmisParticipationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectHouseholdsArgs = {
  filters?: InputMaybe<HouseholdFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<HouseholdSortOption>;
};

export type ProjectIncomingReferralPostingsArgs = {
  filters?: InputMaybe<ReferralPostingFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectInventoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<InventorySortOption>;
};

export type ProjectOutgoingReferralPostingsArgs = {
  filters?: InputMaybe<ReferralPostingFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectProjectCocsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectReferralRequestsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectServicesArgs = {
  filters?: InputMaybe<ServicesForProjectFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ServiceSortOption>;
};

export type ProjectUnitsArgs = {
  filters?: InputMaybe<UnitFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectAccess = {
  __typename?: 'ProjectAccess';
  canDeleteAssessments: Scalars['Boolean']['output'];
  canDeleteEnrollments: Scalars['Boolean']['output'];
  canDeleteProject: Scalars['Boolean']['output'];
  canEditEnrollments: Scalars['Boolean']['output'];
  canEditProjectDetails: Scalars['Boolean']['output'];
  canEnrollClients: Scalars['Boolean']['output'];
  canManageDeniedReferrals: Scalars['Boolean']['output'];
  canManageExternalFormSubmissions: Scalars['Boolean']['output'];
  canManageIncomingReferrals: Scalars['Boolean']['output'];
  canManageOutgoingReferrals: Scalars['Boolean']['output'];
  canManageUnits: Scalars['Boolean']['output'];
  canSplitHouseholds: Scalars['Boolean']['output'];
  canViewDob: Scalars['Boolean']['output'];
  canViewEnrollmentDetails: Scalars['Boolean']['output'];
  canViewFullSsn: Scalars['Boolean']['output'];
  canViewPartialSsn: Scalars['Boolean']['output'];
  canViewUnits: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type ProjectCoc = {
  __typename?: 'ProjectCoc';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cocCode?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  geocode?: Maybe<Scalars['String']['output']>;
  geographyType?: Maybe<GeographyType>;
  id: Scalars['ID']['output'];
  state?: Maybe<Scalars['String']['output']>;
  user?: Maybe<ApplicationUser>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ProjectCocsPaginated = {
  __typename?: 'ProjectCocsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ProjectCoc>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD ProjectCompletionStatus (R17.1) */
export enum ProjectCompletionStatus {
  /** (2) Client voluntarily left early */
  ClientVoluntarilyLeftEarly = 'CLIENT_VOLUNTARILY_LEFT_EARLY',
  /** (3) Client was expelled or otherwise involuntarily discharged from project */
  ClientWasExpelledOrOtherwiseInvoluntarilyDischargedFromProject = 'CLIENT_WAS_EXPELLED_OR_OTHERWISE_INVOLUNTARILY_DISCHARGED_FROM_PROJECT',
  /** (1) Completed project */
  CompletedProject = 'COMPLETED_PROJECT',
  /** Invalid Value */
  Invalid = 'INVALID',
}

/** Project Config */
export type ProjectConfig = {
  __typename?: 'ProjectConfig';
  configOptions?: Maybe<Scalars['JSON']['output']>;
  configType: ProjectConfigType;
  id: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']['output']>;
  projectType?: Maybe<ProjectType>;
};

/** Project Config Input */
export type ProjectConfigInput = {
  configType?: InputMaybe<ProjectConfigType>;
  lengthOfAbsenceDays?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projectType?: InputMaybe<ProjectType>;
};

export enum ProjectConfigType {
  /** Auto Enter */
  AutoEnter = 'AUTO_ENTER',
  /** Auto Exit */
  AutoExit = 'AUTO_EXIT',
  /** Staff Assignment */
  StaffAssignment = 'STAFF_ASSIGNMENT',
}

export type ProjectConfigsPaginated = {
  __typename?: 'ProjectConfigsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ProjectConfig>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export enum ProjectFilterOptionStatus {
  /** Closed */
  Closed = 'CLOSED',
  /** Open */
  Open = 'OPEN',
}

export type ProjectFilterOptions = {
  funder?: InputMaybe<Array<FundingSource>>;
  organization?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectType?: InputMaybe<Array<ProjectType>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProjectFilterOptionStatus>>;
};

/** HUD Project Sorting Options */
export enum ProjectSortOption {
  /** Name */
  Name = 'NAME',
  /** Organization and Name */
  OrganizationAndName = 'ORGANIZATION_AND_NAME',
}

/** HUD Project Types */
export enum ProjectType {
  /** Coordinated Entry */
  Ce = 'CE',
  /** Day Shelter */
  DayShelter = 'DAY_SHELTER',
  /** Emergency Shelter - Entry Exit */
  EsEntryExit = 'ES_ENTRY_EXIT',
  /** Emergency Shelter - Night-by-Night */
  EsNbn = 'ES_NBN',
  /** Homelessness Prevention */
  Hp = 'HP',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** Other */
  Other = 'OTHER',
  /** PH - Housing with Services */
  PhOph = 'PH_OPH',
  /** PH - Housing Only */
  PhPh = 'PH_PH',
  /** PH - Permanent Supportive Housing */
  PhPsh = 'PH_PSH',
  /** PH - Rapid Re-Housing */
  PhRrh = 'PH_RRH',
  /** Safe Haven */
  Sh = 'SH',
  /** Street Outreach */
  So = 'SO',
  /** Services Only */
  Sso = 'SSO',
  /** Transitional Housing */
  Th = 'TH',
}

export type ProjectsForEnrollmentFilterOptions = {
  funder?: InputMaybe<Array<FundingSource>>;
  projectType?: InputMaybe<Array<ProjectType>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProjectFilterOptionStatus>>;
};

export type ProjectsPaginated = {
  __typename?: 'ProjectsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Project>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Autogenerated return type of PublishFormDefinition. */
export type PublishFormDefinitionPayload = {
  __typename?: 'PublishFormDefinitionPayload';
  errors: Array<ValidationError>;
  formIdentifier?: Maybe<FormIdentifier>;
};

export type Query = {
  __typename?: 'Query';
  access: QueryAccess;
  applicationUsers: ApplicationUsersPaginated;
  /** Assessment lookup */
  assessment?: Maybe<Assessment>;
  /** Get the correct Form Definition to use for an assessment, by Role or FormDefinition ID */
  assessmentFormDefinition?: Maybe<FormDefinition>;
  /** Client lookup */
  client?: Maybe<Client>;
  /** Custom forms for collecting and/or displaying custom details for a Client (outside of the Client demographics form) */
  clientDetailForms: Array<OccurrencePointForm>;
  /** Client omnisearch */
  clientOmniSearch: ClientsPaginated;
  /** Search for clients */
  clientSearch: ClientsPaginated;
  currentUser?: Maybe<ApplicationUser>;
  deniedPendingReferralPostings: ReferralPostingsPaginated;
  /** Enrollment lookup */
  enrollment?: Maybe<Enrollment>;
  esgFundingReport: Array<EsgFundingService>;
  externalFormSubmission?: Maybe<ExternalFormSubmission>;
  file?: Maybe<File>;
  formDefinition?: Maybe<FormDefinition>;
  formIdentifier?: Maybe<FormIdentifier>;
  formIdentifiers: FormIdentifiersPaginated;
  formRule?: Maybe<FormRule>;
  formRules: FormRulesPaginated;
  /** Funder lookup */
  funder?: Maybe<Funder>;
  /** Household lookup */
  household?: Maybe<Household>;
  /** Get group of assessments that are performed together */
  householdAssessments?: Maybe<Array<Assessment>>;
  /** Inventory lookup */
  inventory?: Maybe<Inventory>;
  mergeAuditHistory: MergeAuditEventsPaginated;
  mergeCandidates: ClientMergeCandidatesPaginated;
  /** Organization lookup */
  organization?: Maybe<Organization>;
  organizations: OrganizationsPaginated;
  parsedFormDefinition?: Maybe<FormDefinitionForJsonResult>;
  /** Get list of options for pick list */
  pickList: Array<PickListOption>;
  /** Project lookup */
  project?: Maybe<Project>;
  /** Whether the destination project is able to accept a referral for the client(s) belonging to the source enrollment */
  projectCanAcceptReferral: Scalars['Boolean']['output'];
  /** Project CoC lookup */
  projectCoc?: Maybe<ProjectCoc>;
  projectConfigs: ProjectConfigsPaginated;
  projects: ProjectsPaginated;
  /** Get the most relevant Form Definition to use for record viewing/editing */
  recordFormDefinition?: Maybe<FormDefinition>;
  referralPosting?: Maybe<ReferralPosting>;
  /** Service lookup */
  service?: Maybe<Service>;
  serviceCategories: ServiceCategoriesPaginated;
  serviceCategory?: Maybe<ServiceCategory>;
  /** Get most relevant form definition for the specified service type */
  serviceFormDefinition?: Maybe<FormDefinition>;
  /** Service type lookup */
  serviceType?: Maybe<ServiceType>;
  serviceTypes: ServiceTypesPaginated;
  staticFormDefinition: FormDefinition;
  /** User lookup */
  user?: Maybe<ApplicationUser>;
};

export type QueryApplicationUsersArgs = {
  filters?: InputMaybe<ApplicationUserFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAssessmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAssessmentFormDefinitionArgs = {
  assessmentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
  role?: InputMaybe<AssessmentRole>;
};

export type QueryClientArgs = {
  id: Scalars['ID']['input'];
};

export type QueryClientOmniSearchArgs = {
  filters?: InputMaybe<ClientFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ClientSortOption>;
  textSearch: Scalars['String']['input'];
};

export type QueryClientSearchArgs = {
  filters?: InputMaybe<ClientFilterOptions>;
  input: ClientSearchInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ClientSortOption>;
};

export type QueryDeniedPendingReferralPostingsArgs = {
  filters?: InputMaybe<ReferralPostingFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEnrollmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEsgFundingReportArgs = {
  clientIds: Array<Scalars['ID']['input']>;
};

export type QueryExternalFormSubmissionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFileArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFormDefinitionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFormIdentifierArgs = {
  identifier: Scalars['String']['input'];
};

export type QueryFormIdentifiersArgs = {
  filters?: InputMaybe<FormIdentifierFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFormRuleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFormRulesArgs = {
  filters?: InputMaybe<FormRuleFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<FormRuleSortOption>;
};

export type QueryFunderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryHouseholdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryHouseholdAssessmentsArgs = {
  assessmentId?: InputMaybe<Scalars['ID']['input']>;
  assessmentRole: AssessmentRole;
  householdId: Scalars['ID']['input'];
};

export type QueryInventoryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMergeAuditHistoryArgs = {
  filters?: InputMaybe<MergeAuditEventFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMergeCandidatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrganizationsArgs = {
  filters?: InputMaybe<OrganizationFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<OrganizationSortOption>;
};

export type QueryParsedFormDefinitionArgs = {
  input: Scalars['String']['input'];
};

export type QueryPickListArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  enrollmentId?: InputMaybe<Scalars['ID']['input']>;
  householdId?: InputMaybe<Scalars['ID']['input']>;
  pickListType: PickListType;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProjectCanAcceptReferralArgs = {
  destinationProjectId: Scalars['ID']['input'];
  sourceEnrollmentId: Scalars['ID']['input'];
};

export type QueryProjectCocArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProjectConfigsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProjectsArgs = {
  filters?: InputMaybe<ProjectFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ProjectSortOption>;
};

export type QueryRecordFormDefinitionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  role: RecordFormRole;
};

export type QueryReferralPostingArgs = {
  id: Scalars['ID']['input'];
};

export type QueryServiceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryServiceCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryServiceCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryServiceFormDefinitionArgs = {
  projectId: Scalars['ID']['input'];
  serviceTypeId: Scalars['ID']['input'];
};

export type QueryServiceTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryServiceTypesArgs = {
  filters?: InputMaybe<ServiceTypeFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryStaticFormDefinitionArgs = {
  role: StaticFormRole;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAccess = {
  __typename?: 'QueryAccess';
  canAdministerHmis: Scalars['Boolean']['output'];
  canAdministrateConfig: Scalars['Boolean']['output'];
  canAuditClients: Scalars['Boolean']['output'];
  canAuditEnrollments: Scalars['Boolean']['output'];
  canAuditUsers: Scalars['Boolean']['output'];
  canConfigureDataCollection: Scalars['Boolean']['output'];
  canDeleteAssessments: Scalars['Boolean']['output'];
  canDeleteClients: Scalars['Boolean']['output'];
  canDeleteEnrollments: Scalars['Boolean']['output'];
  canDeleteOrganization: Scalars['Boolean']['output'];
  canDeleteProject: Scalars['Boolean']['output'];
  canEditClients: Scalars['Boolean']['output'];
  canEditEnrollments: Scalars['Boolean']['output'];
  canEditOrganization: Scalars['Boolean']['output'];
  canEditProjectDetails: Scalars['Boolean']['output'];
  canEnrollClients: Scalars['Boolean']['output'];
  canImpersonateUsers: Scalars['Boolean']['output'];
  canManageAnyClientFiles: Scalars['Boolean']['output'];
  canManageClientAlerts: Scalars['Boolean']['output'];
  canManageDeniedReferrals: Scalars['Boolean']['output'];
  canManageExternalFormSubmissions: Scalars['Boolean']['output'];
  canManageForms: Scalars['Boolean']['output'];
  canManageIncomingReferrals: Scalars['Boolean']['output'];
  canManageOutgoingReferrals: Scalars['Boolean']['output'];
  canManageOwnClientFiles: Scalars['Boolean']['output'];
  canManageScanCards: Scalars['Boolean']['output'];
  canManageUnits: Scalars['Boolean']['output'];
  canMergeClients: Scalars['Boolean']['output'];
  canSplitHouseholds: Scalars['Boolean']['output'];
  canTransferEnrollments: Scalars['Boolean']['output'];
  canViewAnyConfidentialClientFiles: Scalars['Boolean']['output'];
  canViewAnyNonconfidentialClientFiles: Scalars['Boolean']['output'];
  canViewClientAlerts: Scalars['Boolean']['output'];
  canViewClientContactInfo: Scalars['Boolean']['output'];
  canViewClientName: Scalars['Boolean']['output'];
  canViewClientPhoto: Scalars['Boolean']['output'];
  canViewClients: Scalars['Boolean']['output'];
  canViewDob: Scalars['Boolean']['output'];
  canViewEnrollmentDetails: Scalars['Boolean']['output'];
  canViewEnrollmentLocationMap: Scalars['Boolean']['output'];
  canViewFullSsn: Scalars['Boolean']['output'];
  canViewHudChronicStatus: Scalars['Boolean']['output'];
  canViewLimitedEnrollmentDetails: Scalars['Boolean']['output'];
  canViewMyDashboard: Scalars['Boolean']['output'];
  canViewOpenEnrollmentSummary: Scalars['Boolean']['output'];
  canViewPartialSsn: Scalars['Boolean']['output'];
  canViewProject: Scalars['Boolean']['output'];
  canViewUnits: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

/** HUD RHYNumberofYears (R11.A) */
export enum RhyNumberofYears {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (1) Less than one year */
  LessThanOneYear = 'LESS_THAN_ONE_YEAR',
  /** (2) 1 to 2 years */
  Num_1To_2Years = 'NUM_1_TO_2_YEARS',
  /** (3) 3 to 5 or more years */
  Num_3To_5OrMoreYears = 'NUM_3_TO_5_OR_MORE_YEARS',
}

/** HUD RRHSubType (2.02.A) */
export enum RrhSubType {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) RRH: Housing with or without services */
  RrhHousingWithOrWithoutServices = 'RRH_HOUSING_WITH_OR_WITHOUT_SERVICES',
  /** (1) RRH: Services Only */
  RrhServicesOnly = 'RRH_SERVICES_ONLY',
}

/** HUD Race (1.7) */
export enum Race {
  /** (AmIndAKNative) American Indian, Alaska Native, or Indigenous */
  AmIndAkNative = 'AM_IND_AK_NATIVE',
  /** (Asian) Asian or Asian American */
  Asian = 'ASIAN',
  /** (BlackAfAmerican) Black, African American, or African */
  BlackAfAmerican = 'BLACK_AF_AMERICAN',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (HispanicLatinaeo) Hispanic/Latina/e/o */
  HispanicLatinaeo = 'HISPANIC_LATINAEO',
  /** (MidEastNAfrican) Middle Eastern or North African */
  MidEastNAfrican = 'MID_EAST_N_AFRICAN',
  /** (NativeHIPacific) Native Hawaiian or Pacific Islander */
  NativeHiPacific = 'NATIVE_HI_PACIFIC',
  /** (White) White */
  White = 'WHITE',
}

/** HUD ReasonNoServices (R2.A) */
export enum ReasonNoServices {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (4) Other */
  Other = 'OTHER',
  /** (1) Out of age range */
  OutOfAgeRange = 'OUT_OF_AGE_RANGE',
  /** (3) Ward of the criminal justice system */
  WardOfTheCriminalJusticeSystem = 'WARD_OF_THE_CRIMINAL_JUSTICE_SYSTEM',
  /** (2) Ward of the state */
  WardOfTheState = 'WARD_OF_THE_STATE',
}

/** HUD ReasonNotEnrolled (P3.A) */
export enum ReasonNotEnrolled {
  /** (1) Client was found ineligible for PATH */
  ClientWasFoundIneligibleForPath = 'CLIENT_WAS_FOUND_INELIGIBLE_FOR_PATH',
  /** (2) Client was not enrolled for other reason(s) */
  ClientWasNotEnrolledForOtherReasonS = 'CLIENT_WAS_NOT_ENROLLED_FOR_OTHER_REASON_S',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (3) Unable to locate client */
  UnableToLocateClient = 'UNABLE_TO_LOCATE_CLIENT',
}

/** HUD ReasonNotInsured (4.04.A) */
export enum ReasonNotInsured {
  /** (2) Applied; client not eligible */
  AppliedClientNotEligible = 'APPLIED_CLIENT_NOT_ELIGIBLE',
  /** (1) Applied; decision pending */
  AppliedDecisionPending = 'APPLIED_DECISION_PENDING',
  /** (3) Client did not apply */
  ClientDidNotApply = 'CLIENT_DID_NOT_APPLY',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (4) Insurance type N/A for this client */
  InsuranceTypeNAForThisClient = 'INSURANCE_TYPE_N_A_FOR_THIS_CLIENT',
  /** Invalid Value */
  Invalid = 'INVALID',
}

/** Types allowed for recent items */
export enum RecentItemType {
  Client = 'Client',
  Project = 'Project',
}

/** Form Roles that are used for record-editing. These types of forms are submitted using SubmitForm. */
export enum RecordFormRole {
  /** Case note */
  CaseNote = 'CASE_NOTE',
  /** CE assessment */
  CeAssessment = 'CE_ASSESSMENT',
  /** CE event */
  CeEvent = 'CE_EVENT',
  /** CE participation */
  CeParticipation = 'CE_PARTICIPATION',
  /** Client */
  Client = 'CLIENT',
  /** Client detail */
  ClientDetail = 'CLIENT_DETAIL',
  /** Current living situation */
  CurrentLivingSituation = 'CURRENT_LIVING_SITUATION',
  /** Enrollment */
  Enrollment = 'ENROLLMENT',
  /** External form */
  ExternalForm = 'EXTERNAL_FORM',
  /** File */
  File = 'FILE',
  /** Funder */
  Funder = 'FUNDER',
  /** HMIS participation */
  HmisParticipation = 'HMIS_PARTICIPATION',
  /** Inventory */
  Inventory = 'INVENTORY',
  /** New client enrollment */
  NewClientEnrollment = 'NEW_CLIENT_ENROLLMENT',
  /** Occurrence point */
  OccurrencePoint = 'OCCURRENCE_POINT',
  /** Organization */
  Organization = 'ORGANIZATION',
  /** Project */
  Project = 'PROJECT',
  /** Project CoC */
  ProjectCoc = 'PROJECT_COC',
  /** Referral */
  Referral = 'REFERRAL',
  /** Referral request */
  ReferralRequest = 'REFERRAL_REQUEST',
  /** Service */
  Service = 'SERVICE',
}

/** HUD RecordType (1.4) */
export enum RecordType {
  /** (200) Bed Night */
  BedNight = 'BED_NIGHT',
  /** (151) HOPWA Financial Assistance */
  HopwaFinancialAssistance = 'HOPWA_FINANCIAL_ASSISTANCE',
  /** (143) HOPWA Service */
  HopwaService = 'HOPWA_SERVICE',
  /** (210) HUD-VASH OTH Voucher Tracking */
  HudVashOthVoucherTracking = 'HUD_VASH_OTH_VOUCHER_TRACKING',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (300) Moving On Assistance */
  MovingOnAssistance = 'MOVING_ON_ASSISTANCE',
  /** (161) PATH Referral */
  PathReferral = 'PATH_REFERRAL',
  /** (141) PATH Service */
  PathService = 'PATH_SERVICE',
  /** (142) RHY Service Connections */
  RhyServiceConnections = 'RHY_SERVICE_CONNECTIONS',
  /** (152) SSVF Financial Assistance */
  SsvfFinancialAssistance = 'SSVF_FINANCIAL_ASSISTANCE',
  /** (144) SSVF Service */
  SsvfService = 'SSVF_SERVICE',
}

/** HUD Client within a Referral Household */
export type ReferralHouseholdMember = {
  __typename?: 'ReferralHouseholdMember';
  client: Client;
  id: Scalars['ID']['output'];
  openEnrollmentSummary: Array<EnrollmentSummary>;
  relationshipToHoH: RelationshipToHoH;
};

/** A referral for a household of one or more clients */
export type ReferralPosting = {
  __typename?: 'ReferralPosting';
  assignedDate: Scalars['ISO8601DateTime']['output'];
  chronic?: Maybe<Scalars['Boolean']['output']>;
  customDataElements: Array<CustomDataElement>;
  /** Admin Note associated with the denial (entered from the Denial Screen) */
  denialNote?: Maybe<Scalars['String']['output']>;
  denialReason?: Maybe<ReferralPostingDenialReasonType>;
  hohClient?: Maybe<Client>;
  /** Enrollment for the HoH at the receiving Project (if the referral was accepted) */
  hohEnrollment?: Maybe<Enrollment>;
  hohMciId?: Maybe<Scalars['ID']['output']>;
  hohName: Scalars['String']['output'];
  householdMembers: Array<ReferralHouseholdMember>;
  householdSize: Scalars['Int']['output'];
  hudChronic?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  needsWheelchairAccessibleUnit?: Maybe<Scalars['Boolean']['output']>;
  organization?: Maybe<Organization>;
  postingIdentifier?: Maybe<Scalars['ID']['output']>;
  /** Project that household is being referred to, if user can access it */
  project?: Maybe<Project>;
  referralDate: Scalars['ISO8601Date']['output'];
  referralIdentifier?: Maybe<Scalars['ID']['output']>;
  /** Note associated with the Referral that came from an External API */
  referralNotes?: Maybe<Scalars['String']['output']>;
  referralRequest?: Maybe<ReferralRequest>;
  referralResult?: Maybe<ReferralResult>;
  referredBy: Scalars['String']['output'];
  /** Name of project or external source that the referral originated from */
  referredFrom: Scalars['String']['output'];
  /** Name of the Project that household is being referred to */
  referredTo?: Maybe<Scalars['String']['output']>;
  /**
   * Note associated with the Referral Posting that either came from the External
   * API, or was entered when creating a referral within HMIS
   */
  resourceCoordinatorNotes?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  status: ReferralPostingStatus;
  /** Note associated with the status (E.g. why it was accepted pending / denied pending) */
  statusNote?: Maybe<Scalars['String']['output']>;
  statusNoteUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  statusNoteUpdatedBy?: Maybe<Scalars['String']['output']>;
  statusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  statusUpdatedBy?: Maybe<Scalars['String']['output']>;
  unitType?: Maybe<UnitTypeObject>;
};

/** Referral Posting Denial Reason */
export enum ReferralPostingDenialReasonType {
  /** Does not meet eligibility criteria */
  DoesNotMeetEligibilityCriteria = 'DoesNotMeetEligibilityCriteria',
  /** Enrolled, but declined HMIS data entry */
  EnrolledButDeclinedHmisDataEntry = 'EnrolledButDeclinedHMISDataEntry',
  /** Estimated vacancy no longer available */
  EstimatedVacancyNoLongerAvailable = 'EstimatedVacancyNoLongerAvailable',
  /** HMIS user error */
  HmisUserError = 'HMISUserError',
  /** Inability to complete intake */
  InabilityToCompleteIntake = 'InabilityToCompleteIntake',
  /** No longer experiencing homelessness */
  NoLongerExperiencingHomelessness = 'NoLongerExperiencingHomelessness',
  /** No longer interested in this program */
  NoLongerInterestedInThisProgram = 'NoLongerInterestedInThisProgram',
}

export type ReferralPostingFilterOptions = {
  status?: InputMaybe<Array<ReferralPostingStatus>>;
};

export type ReferralPostingInput = {
  denialNote?: InputMaybe<Scalars['String']['input']>;
  denialReason?: InputMaybe<ReferralPostingDenialReasonType>;
  referralResult?: InputMaybe<ReferralResult>;
  resendReferralRequest?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['ID']['input']>;
  statusNote?: InputMaybe<Scalars['String']['input']>;
};

/** Referral Posting Status */
export enum ReferralPostingStatus {
  /** Accepted Pending */
  AcceptedPendingStatus = 'accepted_pending_status',
  /** Accepted */
  AcceptedStatus = 'accepted_status',
  /** Assigned */
  AssignedStatus = 'assigned_status',
  /** Closed */
  ClosedStatus = 'closed_status',
  /** Denied Pending */
  DeniedPendingStatus = 'denied_pending_status',
  /** Denied */
  DeniedStatus = 'denied_status',
}

export type ReferralPostingsPaginated = {
  __typename?: 'ReferralPostingsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ReferralPosting>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type ReferralRequest = {
  __typename?: 'ReferralRequest';
  id: Scalars['ID']['output'];
  identifier: Scalars['ID']['output'];
  neededBy: Scalars['ISO8601Date']['output'];
  requestedOn: Scalars['ISO8601DateTime']['output'];
  requestorEmail: Scalars['String']['output'];
  requestorName: Scalars['String']['output'];
  requestorPhone: Scalars['String']['output'];
  unitType: UnitTypeObject;
};

export type ReferralRequestsPaginated = {
  __typename?: 'ReferralRequestsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ReferralRequest>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD ReferralResult (4.20.D) */
export enum ReferralResult {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (1) Successful referral: client accepted */
  SuccessfulReferralClientAccepted = 'SUCCESSFUL_REFERRAL_CLIENT_ACCEPTED',
  /** (2) Unsuccessful referral: client rejected */
  UnsuccessfulReferralClientRejected = 'UNSUCCESSFUL_REFERRAL_CLIENT_REJECTED',
  /** (3) Unsuccessful referral: provider rejected */
  UnsuccessfulReferralProviderRejected = 'UNSUCCESSFUL_REFERRAL_PROVIDER_REJECTED',
}

/** HUD ReferralSource (R1.1) */
export enum ReferralSource {
  /** (30) Child Welfare/CPS */
  ChildWelfareCps = 'CHILD_WELFARE_CPS',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (28) Hotline */
  Hotline = 'HOTLINE',
  /** (2) Individual: Parent/Guardian/Relative/Friend/Foster Parent/Other Individual */
  IndividualParentGuardianRelativeFriendFosterParentOtherIndividual = 'INDIVIDUAL_PARENT_GUARDIAN_RELATIVE_FRIEND_FOSTER_PARENT_OTHER_INDIVIDUAL',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (34) Juvenile Justice */
  JuvenileJustice = 'JUVENILE_JUSTICE',
  /** (35) Law Enforcement/Police */
  LawEnforcementPolice = 'LAW_ENFORCEMENT_POLICE',
  /** (37) Mental Hospital */
  MentalHospital = 'MENTAL_HOSPITAL',
  /** (39) Other organization */
  OtherOrganization = 'OTHER_ORGANIZATION',
  /** (7) Outreach Project */
  OutreachProject = 'OUTREACH_PROJECT',
  /** (18) Residential Project */
  ResidentialProject = 'RESIDENTIAL_PROJECT',
  /** (38) School */
  School = 'SCHOOL',
  /** (1) Self-referral */
  SelfReferral = 'SELF_REFERRAL',
  /** (11) Temporary Shelter */
  TemporaryShelter = 'TEMPORARY_SHELTER',
}

/** Autogenerated return type of RefreshExternalSubmissions. */
export type RefreshExternalSubmissionsPayload = {
  __typename?: 'RefreshExternalSubmissionsPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean']['output'];
};

/** Related record type for a group of questions in an assessment */
export enum RelatedRecordType {
  /** CeAssessment */
  Assessment = 'ASSESSMENT',
  /** Client */
  Client = 'CLIENT',
  /** CurrentLivingSituation */
  CurrentLivingSituation = 'CURRENT_LIVING_SITUATION',
  /** DisabilityGroup */
  DisabilityGroup = 'DISABILITY_GROUP',
  /** EmploymentEducation */
  EmploymentEducation = 'EMPLOYMENT_EDUCATION',
  /** Enrollment */
  Enrollment = 'ENROLLMENT',
  /** Event */
  Event = 'EVENT',
  /** Exit */
  Exit = 'EXIT',
  /** Geolocation */
  Geolocation = 'GEOLOCATION',
  /** HealthAndDv */
  HealthAndDv = 'HEALTH_AND_DV',
  /** IncomeBenefit */
  IncomeBenefit = 'INCOME_BENEFIT',
  /** YouthEducationStatus */
  YouthEducationStatus = 'YOUTH_EDUCATION_STATUS',
}

/** HUD RelationshipToHoH (3.15.1) */
export enum RelationshipToHoH {
  /** (2) Child */
  Child = 'CHILD',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (4) Other relative */
  OtherRelative = 'OTHER_RELATIVE',
  /** (1) Self (head of household) */
  SelfHeadOfHousehold = 'SELF_HEAD_OF_HOUSEHOLD',
  /** (3) Spouse or partner */
  SpouseOrPartner = 'SPOUSE_OR_PARTNER',
  /** (5) Unrelated household member */
  UnrelatedHouseholdMember = 'UNRELATED_HOUSEHOLD_MEMBER',
}

export type Reminder = {
  __typename?: 'Reminder';
  /** Relevant existing assessment, if any */
  assessmentId?: Maybe<Scalars['ID']['output']>;
  client: Client;
  dueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  enrollment: Enrollment;
  /** Form definition to use, if a new assessment is needed */
  formDefinitionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  overdue: Scalars['Boolean']['output'];
  topic: ReminderTopic;
};

export enum ReminderTopic {
  AgedIntoAdulthood = 'aged_into_adulthood',
  AnnualAssessment = 'annual_assessment',
  CurrentLivingSituation = 'current_living_situation',
  ExitIncomplete = 'exit_incomplete',
  IntakeIncomplete = 'intake_incomplete',
}

/** HUD RentalSubsidyType (3.12.A) */
export enum RentalSubsidyType {
  /** (437) Family Unification Program Voucher (FUP) */
  FamilyUnificationProgramVoucherFup = 'FAMILY_UNIFICATION_PROGRAM_VOUCHER_FUP',
  /** (438) Foster Youth to Independence Initiative (FYI) */
  FosterYouthToIndependenceInitiativeFyi = 'FOSTER_YOUTH_TO_INDEPENDENCE_INITIATIVE_FYI',
  /** (428) GPD TIP housing subsidy */
  GpdTipHousingSubsidy = 'GPD_TIP_HOUSING_SUBSIDY',
  /** (433) HCV voucher (tenant or project based) (not dedicated) */
  HcvVoucher = 'HCV_VOUCHER',
  /** (436) Housing Stability Voucher */
  HousingStabilityVoucher = 'HOUSING_STABILITY_VOUCHER',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (440) Other permanent housing dedicated for formerly homeless persons */
  OtherPermanentHousingDedicatedForFormerlyHomelessPersons = 'OTHER_PERMANENT_HOUSING_DEDICATED_FOR_FORMERLY_HOMELESS_PERSONS',
  /** (439) Permanent Supportive Housing */
  PermanentSupportiveHousing = 'PERMANENT_SUPPORTIVE_HOUSING',
  /** (434) Public housing unit */
  PublicHousingUnit = 'PUBLIC_HOUSING_UNIT',
  /** (420) Rental by client, with other ongoing housing subsidy */
  RentalByClientWithOtherOngoingHousingSubsidy = 'RENTAL_BY_CLIENT_WITH_OTHER_ONGOING_HOUSING_SUBSIDY',
  /** (431) RRH or equivalent subsidy */
  RrhOrEquivalentSubsidy = 'RRH_OR_EQUIVALENT_SUBSIDY',
  /** (419) VASH housing subsidy */
  VashHousingSubsidy = 'VASH_HOUSING_SUBSIDY',
}

/** HUD ResidencePriorLengthOfStay (3.917.2) */
export enum ResidencePriorLengthOfStay {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (4) 90 days or more but less than one year */
  Num_90DaysOrMoreButLessThanOneYear = 'NUM_90_DAYS_OR_MORE_BUT_LESS_THAN_ONE_YEAR',
  /** (3) One month or more, but less than 90 days */
  OneMonthOrMoreButLessThan_90Days = 'ONE_MONTH_OR_MORE_BUT_LESS_THAN_90_DAYS',
  /** (10) One night or less */
  OneNightOrLess = 'ONE_NIGHT_OR_LESS',
  /** (2) One week or more, but less than one month */
  OneWeekOrMoreButLessThanOneMonth = 'ONE_WEEK_OR_MORE_BUT_LESS_THAN_ONE_MONTH',
  /** (5) One year or longer */
  OneYearOrLonger = 'ONE_YEAR_OR_LONGER',
  /** (11) Two to six nights */
  TwoToSixNights = 'TWO_TO_SIX_NIGHTS',
}

/** Autogenerated return type of RestoreScanCardCode. */
export type RestoreScanCardCodePayload = {
  __typename?: 'RestoreScanCardCodePayload';
  errors: Array<ValidationError>;
  scanCardCode?: Maybe<ScanCardCode>;
};

/** HUD SSNDataQuality (3.02.2) */
export enum SsnDataQuality {
  /** (2) Approximate or partial SSN reported */
  ApproximateOrPartialSsnReported = 'APPROXIMATE_OR_PARTIAL_SSN_REPORTED',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (1) Full SSN reported */
  FullSsnReported = 'FULL_SSN_REPORTED',
  /** Invalid Value */
  Invalid = 'INVALID',
}

/** Autogenerated input type of SaveAssessment */
export type SaveAssessmentInput = {
  assessmentLockVersion?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: AssessmentInput;
};

/** Autogenerated return type of SaveAssessment. */
export type SaveAssessmentPayload = {
  __typename?: 'SaveAssessmentPayload';
  assessment?: Maybe<Assessment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

export type ScanCardCode = {
  __typename?: 'ScanCardCode';
  createdBy?: Maybe<ApplicationUser>;
  dateCreated: Scalars['ISO8601DateTime']['output'];
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deletedBy?: Maybe<ApplicationUser>;
  expirationDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  value: Scalars['ID']['output'];
};

export type ScanCardCodesPaginated = {
  __typename?: 'ScanCardCodesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ScanCardCode>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD SchoolStatus (R5.1) */
export enum SchoolStatus {
  /** (2) Attending school irregularly */
  AttendingSchoolIrregularly = 'ATTENDING_SCHOOL_IRREGULARLY',
  /** (1) Attending school regularly */
  AttendingSchoolRegularly = 'ATTENDING_SCHOOL_REGULARLY',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (5) Dropped out */
  DroppedOut = 'DROPPED_OUT',
  /** (7) Expelled */
  Expelled = 'EXPELLED',
  /** (3) Graduated from high school */
  GraduatedFromHighSchool = 'GRADUATED_FROM_HIGH_SCHOOL',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (4) Obtained GED */
  ObtainedGed = 'OBTAINED_GED',
  /** (6) Suspended */
  Suspended = 'SUSPENDED',
}

/** HUD or Custom Service rendered */
export type Service = {
  __typename?: 'Service';
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  customDataElements: Array<CustomDataElement>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateProvided?: Maybe<Scalars['ISO8601Date']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  enrollment: Enrollment;
  faAmount?: Maybe<Scalars['Float']['output']>;
  faEndDate?: Maybe<Scalars['ISO8601Date']['output']>;
  faStartDate?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  movingOnOtherType?: Maybe<Scalars['String']['output']>;
  otherTypeProvided?: Maybe<Scalars['String']['output']>;
  recordType?: Maybe<RecordType>;
  referralOutcome?: Maybe<PathReferralOutcome>;
  serviceType?: Maybe<ServiceType>;
  subTypeProvided?: Maybe<ServiceSubTypeProvided>;
  typeProvided?: Maybe<ServiceTypeProvided>;
  user?: Maybe<ApplicationUser>;
};

export type ServiceCategoriesPaginated = {
  __typename?: 'ServiceCategoriesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ServiceCategory>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  formRules: FormRulesPaginated;
  /** @deprecated No longer used */
  hud: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  serviceTypes: ServiceTypesPaginated;
  user?: Maybe<ApplicationUser>;
};

export type ServiceCategoryFormRulesArgs = {
  filters?: InputMaybe<FormRuleFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<FormRuleSortOption>;
};

export type ServiceCategoryServiceTypesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum ServiceDetailType {
  Bulk = 'BULK',
  Client = 'CLIENT',
}

export type ServiceFilterOptions = {
  dateProvided?: InputMaybe<Scalars['ISO8601Date']['input']>;
  project?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectType?: InputMaybe<Array<ProjectType>>;
  serviceCategory?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Object to capture input for filtering by date range served */
export type ServiceRangeFilter = {
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Project where the service was rendered */
  projectId?: InputMaybe<Scalars['ID']['input']>;
  /** Service type that was rendered during date range */
  serviceType?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['ISO8601Date']['input'];
};

/** HMIS Service Sorting Options */
export enum ServiceSortOption {
  /** Client Age: Oldest to Youngest */
  AgeOldestToYoungest = 'AGE_OLDEST_TO_YOUNGEST',
  /** Client Age: Youngest to Oldest */
  AgeYoungestToOldest = 'AGE_YOUNGEST_TO_OLDEST',
  /** Date service was provided */
  DateProvided = 'DATE_PROVIDED',
  /** Client First Name: A-Z */
  FirstNameAToZ = 'FIRST_NAME_A_TO_Z',
  /** Client First Name: Z-A */
  FirstNameZToA = 'FIRST_NAME_Z_TO_A',
  /** Client Last Name: A-Z */
  LastNameAToZ = 'LAST_NAME_A_TO_Z',
  /** Client Last Name: Z-A */
  LastNameZToA = 'LAST_NAME_Z_TO_A',
}

/** HUD Service TypeProvided:SubTypeProvided aggregate (V2.A, V2.B, V2.C) */
export enum ServiceSubTypeProvided {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (12) Child care */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsChildCare = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__CHILD_CARE',
  /** (2) Daily living services */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsDailyLivingServices = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__DAILY_LIVING_SERVICES',
  /** (6) Fiduciary and representative payee services */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsFiduciaryAndRepresentativePayeeServices = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__FIDUCIARY_AND_REPRESENTATIVE_PAYEE_SERVICES',
  /** (1) Health care services */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsHealthCareServices = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__HEALTH_CARE_SERVICES',
  /** (13) Housing counseling */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsHousingCounseling = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__HOUSING_COUNSELING',
  /** (5) Income support services */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsIncomeSupportServices = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__INCOME_SUPPORT_SERVICES',
  /** (7) Legal services - child support */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsLegalServicesChildSupport = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_CHILD_SUPPORT',
  /** (8) Legal services - eviction prevention */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsLegalServicesEvictionPrevention = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_EVICTION_PREVENTION',
  /** (11) Legal services - other */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsLegalServicesOther = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_OTHER',
  /** (9) Legal services - outstanding fines and penalties */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsLegalServicesOutstandingFinesAndPenalties = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_OUTSTANDING_FINES_AND_PENALTIES',
  /** (10) Legal services - restore / acquire driver's license */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsLegalServicesRestoreAcquireDriverSLicense = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_RESTORE_ACQUIRE_DRIVER_S_LICENSE',
  /** (3) Personal financial planning services */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsPersonalFinancialPlanningServices = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__PERSONAL_FINANCIAL_PLANNING_SERVICES',
  /** (4) Transportation services */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefitsTransportationServices = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS__TRANSPORTATION_SERVICES',
  /** (3) Educational assistance */
  SsvfServiceAssistanceObtainingVaBenefitsEducationalAssistance = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_VA_BENEFITS__EDUCATIONAL_ASSISTANCE',
  /** (2) Employment and training services */
  SsvfServiceAssistanceObtainingVaBenefitsEmploymentAndTrainingServices = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_VA_BENEFITS__EMPLOYMENT_AND_TRAINING_SERVICES',
  /** (4) Health care services */
  SsvfServiceAssistanceObtainingVaBenefitsHealthCareServices = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_VA_BENEFITS__HEALTH_CARE_SERVICES',
  /** (1) VA vocational and rehabilitation counseling */
  SsvfServiceAssistanceObtainingVaBenefitsVaVocationalAndRehabilitationCounseling = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_VA_BENEFITS__VA_VOCATIONAL_AND_REHABILITATION_COUNSELING',
  /** (10) Child care */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsChildCare = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__CHILD_CARE',
  /** (4) Fiduciary and representative payee services */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsFiduciaryAndRepresentativePayeeServices = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__FIDUCIARY_AND_REPRESENTATIVE_PAYEE_SERVICES',
  /** (11) Housing counseling */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsHousingCounseling = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__HOUSING_COUNSELING',
  /** (3) Income support services */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsIncomeSupportServices = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__INCOME_SUPPORT_SERVICES',
  /** (5) Legal services - child support */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsLegalServicesChildSupport = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_CHILD_SUPPORT',
  /** (6) Legal services - eviction prevention */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsLegalServicesEvictionPrevention = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_EVICTION_PREVENTION',
  /** (9) Legal services - other */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsLegalServicesOther = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_OTHER',
  /** (7) Legal services - outstanding fines and penalties */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsLegalServicesOutstandingFinesAndPenalties = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_OUTSTANDING_FINES_AND_PENALTIES',
  /** (8) Legal services - restore / acquire driver's license */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsLegalServicesRestoreAcquireDriverSLicense = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__LEGAL_SERVICES_RESTORE_ACQUIRE_DRIVER_S_LICENSE',
  /** (1) Personal financial planning services */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsPersonalFinancialPlanningServices = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__PERSONAL_FINANCIAL_PLANNING_SERVICES',
  /** (2) Transportation services */
  SsvfServiceDirectProvisionOfOtherPublicBenefitsTransportationServices = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS__TRANSPORTATION_SERVICES',
}

export type ServiceType = {
  __typename?: 'ServiceType';
  category: Scalars['String']['output'];
  createdBy?: Maybe<ApplicationUser>;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Definitions that are specified for this service type */
  formDefinitions: Array<FormDefinition>;
  hud: Scalars['Boolean']['output'];
  hudRecordType?: Maybe<RecordType>;
  hudTypeProvided?: Maybe<ServiceTypeProvided>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  serviceCategory: ServiceCategory;
  supportsBulkAssignment: Scalars['Boolean']['output'];
  user?: Maybe<ApplicationUser>;
};

export type ServiceTypeFilterOptions = {
  includeHudServices?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

/** Create service type input */
export type ServiceTypeInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  serviceCategoryId?: InputMaybe<Scalars['ID']['input']>;
  serviceCategoryName?: InputMaybe<Scalars['String']['input']>;
  supportsBulkAssignment?: InputMaybe<Scalars['Boolean']['input']>;
};

/** HUD Service RecordType:TypeProvided aggregate (P1.2, R14.2, W1.2, V2.2, W2.2, V3.3, P2.2, 4.14, V8.2, C2.2) */
export enum ServiceTypeProvided {
  /** (200) Bed Night */
  BedNightBedNight = 'BED_NIGHT__BED_NIGHT',
  /** (7) Mortgage assistance */
  HopwaFinancialAssistanceMortgageAssistance = 'HOPWA_FINANCIAL_ASSISTANCE__MORTGAGE_ASSISTANCE',
  /** (1) Rental assistance */
  HopwaFinancialAssistanceRentalAssistance = 'HOPWA_FINANCIAL_ASSISTANCE__RENTAL_ASSISTANCE',
  /** (2) Security deposits */
  HopwaFinancialAssistanceSecurityDeposits = 'HOPWA_FINANCIAL_ASSISTANCE__SECURITY_DEPOSITS',
  /** (3) Utility deposits */
  HopwaFinancialAssistanceUtilityDeposits = 'HOPWA_FINANCIAL_ASSISTANCE__UTILITY_DEPOSITS',
  /** (4) Utility payments */
  HopwaFinancialAssistanceUtilityPayments = 'HOPWA_FINANCIAL_ASSISTANCE__UTILITY_PAYMENTS',
  /** (1) Adult day care and personal assistance */
  HopwaServiceAdultDayCareAndPersonalAssistance = 'HOPWA_SERVICE__ADULT_DAY_CARE_AND_PERSONAL_ASSISTANCE',
  /** (2) Case management */
  HopwaServiceCaseManagement = 'HOPWA_SERVICE__CASE_MANAGEMENT',
  /** (3) Child care */
  HopwaServiceChildCare = 'HOPWA_SERVICE__CHILD_CARE',
  /** (4) Criminal justice/legal services */
  HopwaServiceCriminalJusticeLegalServices = 'HOPWA_SERVICE__CRIMINAL_JUSTICE_LEGAL_SERVICES',
  /** (5) Education */
  HopwaServiceEducation = 'HOPWA_SERVICE__EDUCATION',
  /** (6) Employment and training services */
  HopwaServiceEmploymentAndTrainingServices = 'HOPWA_SERVICE__EMPLOYMENT_AND_TRAINING_SERVICES',
  /** (7) Food/meals/nutritional services */
  HopwaServiceFoodMealsNutritionalServices = 'HOPWA_SERVICE__FOOD_MEALS_NUTRITIONAL_SERVICES',
  /** (8) Health/medical care */
  HopwaServiceHealthMedicalCare = 'HOPWA_SERVICE__HEALTH_MEDICAL_CARE',
  /** (9) Life skills training */
  HopwaServiceLifeSkillsTraining = 'HOPWA_SERVICE__LIFE_SKILLS_TRAINING',
  /** (10) Mental health care/counseling */
  HopwaServiceMentalHealthCareCounseling = 'HOPWA_SERVICE__MENTAL_HEALTH_CARE_COUNSELING',
  /** (14) Other HOPWA funded service */
  HopwaServiceOtherHopwaFundedService = 'HOPWA_SERVICE__OTHER_HOPWA_FUNDED_SERVICE',
  /** (11) Outreach and/or engagement */
  HopwaServiceOutreachAndOrEngagement = 'HOPWA_SERVICE__OUTREACH_AND_OR_ENGAGEMENT',
  /** (12) Substance use services/treatment */
  HopwaServiceSubstanceUseServicesTreatment = 'HOPWA_SERVICE__SUBSTANCE_USE_SERVICES_TREATMENT',
  /** (13) Transportation */
  HopwaServiceTransportation = 'HOPWA_SERVICE__TRANSPORTATION',
  /** (12) Other */
  HudVashOthVoucherTrackingOther = 'HUD_VASH_OTH_VOUCHER_TRACKING__OTHER',
  /** (1) Referral package forwarded to PHA */
  HudVashOthVoucherTrackingReferralPackageForwardedToPha = 'HUD_VASH_OTH_VOUCHER_TRACKING__REFERRAL_PACKAGE_FORWARDED_TO_PHA',
  /** (10) Veteran exited - family maintained the voucher */
  HudVashOthVoucherTrackingVeteranExitedFamilyMaintainedTheVoucher = 'HUD_VASH_OTH_VOUCHER_TRACKING__VETERAN_EXITED_FAMILY_MAINTAINED_THE_VOUCHER',
  /** (11) Veteran exited - prior to ever receiving a voucher */
  HudVashOthVoucherTrackingVeteranExitedPriorToEverReceivingAVoucher = 'HUD_VASH_OTH_VOUCHER_TRACKING__VETERAN_EXITED_PRIOR_TO_EVER_RECEIVING_A_VOUCHER',
  /** (9) Veteran exited - voucher was returned */
  HudVashOthVoucherTrackingVeteranExitedVoucherWasReturned = 'HUD_VASH_OTH_VOUCHER_TRACKING__VETERAN_EXITED_VOUCHER_WAS_RETURNED',
  /** (2) Voucher denied by PHA */
  HudVashOthVoucherTrackingVoucherDeniedByPha = 'HUD_VASH_OTH_VOUCHER_TRACKING__VOUCHER_DENIED_BY_PHA',
  /** (5) Voucher in use - veteran moved into housing */
  HudVashOthVoucherTrackingVoucherInUseVeteranMovedIntoHousing = 'HUD_VASH_OTH_VOUCHER_TRACKING__VOUCHER_IN_USE_VETERAN_MOVED_INTO_HOUSING',
  /** (3) Voucher issued by PHA */
  HudVashOthVoucherTrackingVoucherIssuedByPha = 'HUD_VASH_OTH_VOUCHER_TRACKING__VOUCHER_ISSUED_BY_PHA',
  /** (4) Voucher revoked or expired */
  HudVashOthVoucherTrackingVoucherRevokedOrExpired = 'HUD_VASH_OTH_VOUCHER_TRACKING__VOUCHER_REVOKED_OR_EXPIRED',
  /** (7) Voucher was administratively absorbed by new PHA */
  HudVashOthVoucherTrackingVoucherWasAdministrativelyAbsorbedByNewPha = 'HUD_VASH_OTH_VOUCHER_TRACKING__VOUCHER_WAS_ADMINISTRATIVELY_ABSORBED_BY_NEW_PHA',
  /** (8) Voucher was converted to Housing Choice Voucher */
  HudVashOthVoucherTrackingVoucherWasConvertedToHousingChoiceVoucher = 'HUD_VASH_OTH_VOUCHER_TRACKING__VOUCHER_WAS_CONVERTED_TO_HOUSING_CHOICE_VOUCHER',
  /** (6) Voucher was ported locally */
  HudVashOthVoucherTrackingVoucherWasPortedLocally = 'HUD_VASH_OTH_VOUCHER_TRACKING__VOUCHER_WAS_PORTED_LOCALLY',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (2) Financial assistance for Moving On (e.g., security deposit, moving expenses) */
  MovingOnAssistanceFinancialAssistanceForMovingOn = 'MOVING_ON_ASSISTANCE__FINANCIAL_ASSISTANCE_FOR_MOVING_ON',
  /** (4) Housing referral/placement */
  MovingOnAssistanceHousingReferralPlacement = 'MOVING_ON_ASSISTANCE__HOUSING_REFERRAL_PLACEMENT',
  /** (3) Non-financial assistance for Moving On (e.g., housing navigation, transition support) */
  MovingOnAssistanceNonFinancialAssistanceForMovingOn = 'MOVING_ON_ASSISTANCE__NON_FINANCIAL_ASSISTANCE_FOR_MOVING_ON',
  /** (5) Other */
  MovingOnAssistanceOther = 'MOVING_ON_ASSISTANCE__OTHER',
  /** (1) Subsidized housing application assistance */
  MovingOnAssistanceSubsidizedHousingApplicationAssistance = 'MOVING_ON_ASSISTANCE__SUBSIDIZED_HOUSING_APPLICATION_ASSISTANCE',
  /** (1) Community mental health */
  PathReferralCommunityMentalHealth = 'PATH_REFERRAL__COMMUNITY_MENTAL_HEALTH',
  /** (5) Educational services */
  PathReferralEducationalServices = 'PATH_REFERRAL__EDUCATIONAL_SERVICES',
  /** (9) Employment assistance */
  PathReferralEmploymentAssistance = 'PATH_REFERRAL__EMPLOYMENT_ASSISTANCE',
  /** (6) Housing services */
  PathReferralHousingServices = 'PATH_REFERRAL__HOUSING_SERVICES',
  /** (8) Income assistance */
  PathReferralIncomeAssistance = 'PATH_REFERRAL__INCOME_ASSISTANCE',
  /** (4) Job training */
  PathReferralJobTraining = 'PATH_REFERRAL__JOB_TRAINING',
  /** (10) Medical insurance */
  PathReferralMedicalInsurance = 'PATH_REFERRAL__MEDICAL_INSURANCE',
  /** (7) Permanent housing */
  PathReferralPermanentHousing = 'PATH_REFERRAL__PERMANENT_HOUSING',
  /** (3) Primary health/dental care */
  PathReferralPrimaryHealthDentalCare = 'PATH_REFERRAL__PRIMARY_HEALTH_DENTAL_CARE',
  /** (2) Substance use treatment */
  PathReferralSubstanceUseTreatment = 'PATH_REFERRAL__SUBSTANCE_USE_TREATMENT',
  /** (11) Temporary housing */
  PathReferralTemporaryHousing = 'PATH_REFERRAL__TEMPORARY_HOUSING',
  /** (6) Case management */
  PathServiceCaseManagement = 'PATH_SERVICE__CASE_MANAGEMENT',
  /** (14) Clinical assessment */
  PathServiceClinicalAssessment = 'PATH_SERVICE__CLINICAL_ASSESSMENT',
  /** (4) Community mental health */
  PathServiceCommunityMentalHealth = 'PATH_SERVICE__COMMUNITY_MENTAL_HEALTH',
  /** (3) Habilitation/rehabilitation */
  PathServiceHabilitationRehabilitation = 'PATH_SERVICE__HABILITATION_REHABILITATION',
  /** (10) Housing eligibility determination */
  PathServiceHousingEligibilityDetermination = 'PATH_SERVICE__HOUSING_ELIGIBILITY_DETERMINATION',
  /** (8) Housing minor renovation */
  PathServiceHousingMinorRenovation = 'PATH_SERVICE__HOUSING_MINOR_RENOVATION',
  /** (9) Housing moving assistance */
  PathServiceHousingMovingAssistance = 'PATH_SERVICE__HOUSING_MOVING_ASSISTANCE',
  /** (12) One-time rent for eviction prevention */
  PathServiceOneTimeRentForEvictionPrevention = 'PATH_SERVICE__ONE_TIME_RENT_FOR_EVICTION_PREVENTION',
  /** (7) Residential supportive services */
  PathServiceResidentialSupportiveServices = 'PATH_SERVICE__RESIDENTIAL_SUPPORTIVE_SERVICES',
  /** (1) Re-engagement */
  PathServiceReEngagement = 'PATH_SERVICE__RE_ENGAGEMENT',
  /** (2) Screening */
  PathServiceScreening = 'PATH_SERVICE__SCREENING',
  /** (11) Security deposits */
  PathServiceSecurityDeposits = 'PATH_SERVICE__SECURITY_DEPOSITS',
  /** (5) Substance use treatment */
  PathServiceSubstanceUseTreatment = 'PATH_SERVICE__SUBSTANCE_USE_TREATMENT',
  /** (2) Community service/service learning (CSL) */
  RhyServiceConnectionsCommunityServiceServiceLearningCsl = 'RHY_SERVICE_CONNECTIONS__COMMUNITY_SERVICE_SERVICE_LEARNING_CSL',
  /** (7) Criminal justice /legal services */
  RhyServiceConnectionsCriminalJusticeLegalServices = 'RHY_SERVICE_CONNECTIONS__CRIMINAL_JUSTICE_LEGAL_SERVICES',
  /** (5) Education */
  RhyServiceConnectionsEducation = 'RHY_SERVICE_CONNECTIONS__EDUCATION',
  /** (6) Employment and training services */
  RhyServiceConnectionsEmploymentAndTrainingServices = 'RHY_SERVICE_CONNECTIONS__EMPLOYMENT_AND_TRAINING_SERVICES',
  /** (14) Health/medical care */
  RhyServiceConnectionsHealthMedicalCare = 'RHY_SERVICE_CONNECTIONS__HEALTH_MEDICAL_CARE',
  /** (26) Home-based Services */
  RhyServiceConnectionsHomeBasedServices = 'RHY_SERVICE_CONNECTIONS__HOME_BASED_SERVICES',
  /** (8) Life skills training */
  RhyServiceConnectionsLifeSkillsTraining = 'RHY_SERVICE_CONNECTIONS__LIFE_SKILLS_TRAINING',
  /** (10) Parenting education for youth with children */
  RhyServiceConnectionsParentingEducationForYouthWithChildren = 'RHY_SERVICE_CONNECTIONS__PARENTING_EDUCATION_FOR_YOUTH_WITH_CHILDREN',
  /** (12) Post-natal care for client (person who gave birth) */
  RhyServiceConnectionsPostNatalCareForClient = 'RHY_SERVICE_CONNECTIONS__POST_NATAL_CARE_FOR_CLIENT',
  /** (27) Post-natal newborn care (wellness exams; immunizations) */
  RhyServiceConnectionsPostNatalNewbornCare = 'RHY_SERVICE_CONNECTIONS__POST_NATAL_NEWBORN_CARE',
  /** (13) Pre-natal care */
  RhyServiceConnectionsPreNatalCare = 'RHY_SERVICE_CONNECTIONS__PRE_NATAL_CARE',
  /** (28) STD Testing */
  RhyServiceConnectionsStdTesting = 'RHY_SERVICE_CONNECTIONS__STD_TESTING',
  /** (29) Street-based Services */
  RhyServiceConnectionsStreetBasedServices = 'RHY_SERVICE_CONNECTIONS__STREET_BASED_SERVICES',
  /** (18) Substance use disorder/Prevention Services */
  RhyServiceConnectionsSubstanceUseDisorderPreventionServices = 'RHY_SERVICE_CONNECTIONS__SUBSTANCE_USE_DISORDER_PREVENTION_SERVICES',
  /** (17) Substance use disorder treatment */
  RhyServiceConnectionsSubstanceUseDisorderTreatment = 'RHY_SERVICE_CONNECTIONS__SUBSTANCE_USE_DISORDER_TREATMENT',
  /** (10) Child care */
  SsvfFinancialAssistanceChildCare = 'SSVF_FINANCIAL_ASSISTANCE__CHILD_CARE',
  /** (14) Emergency housing assistance */
  SsvfFinancialAssistanceEmergencyHousingAssistance = 'SSVF_FINANCIAL_ASSISTANCE__EMERGENCY_HOUSING_ASSISTANCE',
  /** (16) Food Assistance */
  SsvfFinancialAssistanceFoodAssistance = 'SSVF_FINANCIAL_ASSISTANCE__FOOD_ASSISTANCE',
  /** (12) General housing stability assistance */
  SsvfFinancialAssistanceGeneralHousingStabilityAssistance = 'SSVF_FINANCIAL_ASSISTANCE__GENERAL_HOUSING_STABILITY_ASSISTANCE',
  /** (11) General housing stability assistance - emergency supplies [Deprecated] */
  SsvfFinancialAssistanceGeneralHousingStabilityAssistanceEmergencySuppliesDeprecated = 'SSVF_FINANCIAL_ASSISTANCE__GENERAL_HOUSING_STABILITY_ASSISTANCE_EMERGENCY_SUPPLIES_DEPRECATED',
  /** (17) Landlord Incentive */
  SsvfFinancialAssistanceLandlordIncentive = 'SSVF_FINANCIAL_ASSISTANCE__LANDLORD_INCENTIVE',
  /** (5) Moving costs */
  SsvfFinancialAssistanceMovingCosts = 'SSVF_FINANCIAL_ASSISTANCE__MOVING_COSTS',
  /** (1) Rental assistance */
  SsvfFinancialAssistanceRentalAssistance = 'SSVF_FINANCIAL_ASSISTANCE__RENTAL_ASSISTANCE',
  /** (2) Security deposit */
  SsvfFinancialAssistanceSecurityDeposit = 'SSVF_FINANCIAL_ASSISTANCE__SECURITY_DEPOSIT',
  /** (15) Shallow Subsidy - Financial Assistance */
  SsvfFinancialAssistanceShallowSubsidyFinancialAssistance = 'SSVF_FINANCIAL_ASSISTANCE__SHALLOW_SUBSIDY_FINANCIAL_ASSISTANCE',
  /** (18) Tenant Incentive */
  SsvfFinancialAssistanceTenantIncentive = 'SSVF_FINANCIAL_ASSISTANCE__TENANT_INCENTIVE',
  /** (8) Transportation services: tokens/vouchers */
  SsvfFinancialAssistanceTransportationServicesTokensVouchers = 'SSVF_FINANCIAL_ASSISTANCE__TRANSPORTATION_SERVICES_TOKENS_VOUCHERS',
  /** (9) Transportation services: vehicle repair/maintenance */
  SsvfFinancialAssistanceTransportationServicesVehicleRepairMaintenance = 'SSVF_FINANCIAL_ASSISTANCE__TRANSPORTATION_SERVICES_VEHICLE_REPAIR_MAINTENANCE',
  /** (3) Utility deposit */
  SsvfFinancialAssistanceUtilityDeposit = 'SSVF_FINANCIAL_ASSISTANCE__UTILITY_DEPOSIT',
  /** (4) Utility fee payment assistance */
  SsvfFinancialAssistanceUtilityFeePaymentAssistance = 'SSVF_FINANCIAL_ASSISTANCE__UTILITY_FEE_PAYMENT_ASSISTANCE',
  /** (4) Assistance obtaining/coordinating other public benefits */
  SsvfServiceAssistanceObtainingCoordinatingOtherPublicBenefits = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_COORDINATING_OTHER_PUBLIC_BENEFITS',
  /** (3) Assistance obtaining VA benefits */
  SsvfServiceAssistanceObtainingVaBenefits = 'SSVF_SERVICE__ASSISTANCE_OBTAINING_VA_BENEFITS',
  /** (2) Case management services */
  SsvfServiceCaseManagementServices = 'SSVF_SERVICE__CASE_MANAGEMENT_SERVICES',
  /** (5) Direct provision of other public benefits */
  SsvfServiceDirectProvisionOfOtherPublicBenefits = 'SSVF_SERVICE__DIRECT_PROVISION_OF_OTHER_PUBLIC_BENEFITS',
  /** (6) Other (non-TFA) supportive service approved by VA */
  SsvfServiceOtherNonTfaSupportiveServiceApprovedByVa = 'SSVF_SERVICE__OTHER_NON_TFA_SUPPORTIVE_SERVICE_APPROVED_BY_VA',
  /** (1) Outreach services */
  SsvfServiceOutreachServices = 'SSVF_SERVICE__OUTREACH_SERVICES',
  /** (9) Rapid Resolution */
  SsvfServiceRapidResolution = 'SSVF_SERVICE__RAPID_RESOLUTION',
  /** (8) Returning Home */
  SsvfServiceReturningHome = 'SSVF_SERVICE__RETURNING_HOME',
  /** (7) Shallow Subsidy */
  SsvfServiceShallowSubsidy = 'SSVF_SERVICE__SHALLOW_SUBSIDY',
}

export type ServiceTypesPaginated = {
  __typename?: 'ServiceTypesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<ServiceType>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type ServicesForEnrollmentFilterOptions = {
  dateProvided?: InputMaybe<Scalars['ISO8601Date']['input']>;
  serviceCategory?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ServicesForProjectFilterOptions = {
  dateProvided?: InputMaybe<Scalars['ISO8601Date']['input']>;
  serviceCategory?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceType?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ServicesPaginated = {
  __typename?: 'ServicesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Service>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** HUD SexualOrientation (R3.1) */
export enum SexualOrientation {
  /** (4) Bisexual */
  Bisexual = 'BISEXUAL',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (2) Gay */
  Gay = 'GAY',
  /** (1) Heterosexual */
  Heterosexual = 'HETEROSEXUAL',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (3) Lesbian */
  Lesbian = 'LESBIAN',
  /** (6) Other */
  Other = 'OTHER',
  /** (5) Questioning / unsure */
  QuestioningUnsure = 'QUESTIONING_UNSURE',
}

/** Autogenerated return type of SplitHousehold. */
export type SplitHouseholdPayload = {
  __typename?: 'SplitHouseholdPayload';
  errors: Array<ValidationError>;
  newHousehold: Household;
  remainingHousehold: Household;
};

/** Staff Assignment */
export type StaffAssignment = {
  __typename?: 'StaffAssignment';
  assignedAt: Scalars['ISO8601DateTime']['output'];
  household: Household;
  id: Scalars['ID']['output'];
  staffAssignmentRelationship: Scalars['String']['output'];
  unassignedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  user: ApplicationUser;
};

export type StaffAssignmentsPaginated = {
  __typename?: 'StaffAssignmentsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<StaffAssignment>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Form Roles that are used for non-configurable forms. These types of forms are submitted using custom mutations. */
export enum StaticFormRole {
  /** Auto exit config */
  AutoExitConfig = 'AUTO_EXIT_CONFIG',
  /** Client alert */
  ClientAlert = 'CLIENT_ALERT',
  /**
   * External form submission review
   * @deprecated External forms are moving to a directly configured react form, rather than a static form.
   */
  ExternalFormSubmissionReview = 'EXTERNAL_FORM_SUBMISSION_REVIEW',
  /** Form definition */
  FormDefinition = 'FORM_DEFINITION',
  /** Form rule */
  FormRule = 'FORM_RULE',
  /** Project config */
  ProjectConfig = 'PROJECT_CONFIG',
}

/** Autogenerated input type of SubmitAssessment */
export type SubmitAssessmentInput = {
  assessmentLockVersion?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: AssessmentInput;
};

/** Autogenerated return type of SubmitAssessment. */
export type SubmitAssessmentPayload = {
  __typename?: 'SubmitAssessmentPayload';
  assessment?: Maybe<Assessment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** Autogenerated input type of SubmitForm */
export type SubmitFormInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: FormInput;
  recordLockVersion?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of SubmitForm. */
export type SubmitFormPayload = {
  __typename?: 'SubmitFormPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  record?: Maybe<SubmitFormResult>;
};

/** Union type of allowed records for form submission response */
export type SubmitFormResult =
  | CeAssessment
  | CeParticipation
  | Client
  | CurrentLivingSituation
  | CustomCaseNote
  | Enrollment
  | Event
  | File
  | Funder
  | HmisParticipation
  | Inventory
  | Organization
  | Project
  | ProjectCoc
  | ReferralPosting
  | ReferralRequest
  | Service;

/** Autogenerated input type of SubmitHouseholdAssessments */
export type SubmitHouseholdAssessmentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether warnings have been confirmed */
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  submissions: Array<VersionedRecordInput>;
  /** Validate assessments but don't submit them */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of SubmitHouseholdAssessments. */
export type SubmitHouseholdAssessmentsPayload = {
  __typename?: 'SubmitHouseholdAssessmentsPayload';
  assessments?: Maybe<Array<Assessment>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** HUD SubsidyInformation (W5.AB) */
export enum SubsidyInformation {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (4) Only with financial assistance other than a subsidy */
  OnlyWithFinancialAssistanceOtherThanASubsidy = 'ONLY_WITH_FINANCIAL_ASSISTANCE_OTHER_THAN_A_SUBSIDY',
  /** (12) Without an on-going subsidy */
  WithoutAnOnGoingSubsidy = 'WITHOUT_AN_ON_GOING_SUBSIDY',
  /** (1) Without a subsidy */
  WithoutASubsidy = 'WITHOUT_A_SUBSIDY',
  /** (3) With an on-going subsidy acquired since project entry */
  WithAnOnGoingSubsidyAcquiredSinceProjectEntry = 'WITH_AN_ON_GOING_SUBSIDY_ACQUIRED_SINCE_PROJECT_ENTRY',
  /** (11) With on-going subsidy */
  WithOnGoingSubsidy = 'WITH_ON_GOING_SUBSIDY',
  /** (2) With the subsidy they had at project entry */
  WithTheSubsidyTheyHadAtProjectEntry = 'WITH_THE_SUBSIDY_THEY_HAD_AT_PROJECT_ENTRY',
}

/** HUD SubsidyInformationA (W5.A) */
export enum SubsidyInformationA {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (4) Only with financial assistance other than a subsidy */
  OnlyWithFinancialAssistanceOtherThanASubsidy = 'ONLY_WITH_FINANCIAL_ASSISTANCE_OTHER_THAN_A_SUBSIDY',
  /** (1) Without a subsidy */
  WithoutASubsidy = 'WITHOUT_A_SUBSIDY',
  /** (3) With an on-going subsidy acquired since project entry */
  WithAnOnGoingSubsidyAcquiredSinceProjectEntry = 'WITH_AN_ON_GOING_SUBSIDY_ACQUIRED_SINCE_PROJECT_ENTRY',
  /** (2) With the subsidy they had at project entry */
  WithTheSubsidyTheyHadAtProjectEntry = 'WITH_THE_SUBSIDY_THEY_HAD_AT_PROJECT_ENTRY',
}

/** HUD SubsidyInformationB (W5.B) */
export enum SubsidyInformationB {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (12) Without an on-going subsidy */
  WithoutAnOnGoingSubsidy = 'WITHOUT_AN_ON_GOING_SUBSIDY',
  /** (11) With on-going subsidy */
  WithOnGoingSubsidy = 'WITH_ON_GOING_SUBSIDY',
}

export enum SystemStatus {
  /** Non-System */
  NonSystem = 'NON_SYSTEM',
  /** System */
  System = 'SYSTEM',
}

/** HUD TCellSourceViralLoadSource (W4.B) */
export enum TCellSourceViralLoadSource {
  /** (2) Client Report */
  ClientReport = 'CLIENT_REPORT',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (1) Medical Report */
  MedicalReport = 'MEDICAL_REPORT',
  /** (3) Other */
  Other = 'OTHER',
}

/** HUD TargetPopulation (2.02.7) */
export enum TargetPopulation {
  /** (1) DV: Survivor of Domestic Violence */
  DvSurvivorOfDomesticViolence = 'DV_SURVIVOR_OF_DOMESTIC_VIOLENCE',
  /** (3) HIV: Persons with HIV/AIDS */
  HivPersonsWithHivAids = 'HIV_PERSONS_WITH_HIV_AIDS',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (4) NA: Not applicable */
  NaNotApplicable = 'NA_NOT_APPLICABLE',
}

/** HUD TimeToHousingLoss (V7.A) */
export enum TimeToHousingLoss {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (3) More than 21 days */
  MoreThan_21Days = 'MORE_THAN_21_DAYS',
  /** (0) 1-6 days */
  Num_1_6Days = 'NUM_1_6_DAYS',
  /** (1) 7-13 days */
  Num_7_13Days = 'NUM_7_13_DAYS',
  /** (2) 14-21 days */
  Num_14_21Days = 'NUM_14_21_DAYS',
}

/** HUD TimesHomelessPastThreeYears (3.917.4) */
export enum TimesHomelessPastThreeYears {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** (4) Four or more times */
  FourOrMoreTimes = 'FOUR_OR_MORE_TIMES',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (1) One time */
  OneTime = 'ONE_TIME',
  /** (3) Three times */
  ThreeTimes = 'THREE_TIMES',
  /** (2) Two times */
  TwoTimes = 'TWO_TIMES',
}

/** Autogenerated return type of UnassignStaff. */
export type UnassignStaffPayload = {
  __typename?: 'UnassignStaffPayload';
  errors: Array<ValidationError>;
  staffAssignment?: Maybe<StaffAssignment>;
};

export type Unit = {
  __typename?: 'Unit';
  dateCreated: Scalars['ISO8601DateTime']['output'];
  dateUpdated: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  occupants: Array<Enrollment>;
  project?: Maybe<Project>;
  unitSize?: Maybe<Scalars['Int']['output']>;
  unitType?: Maybe<UnitTypeObject>;
  user?: Maybe<ApplicationUser>;
};

export enum UnitFilterOptionStatus {
  /** Available */
  Available = 'AVAILABLE',
  /** Filled */
  Filled = 'FILLED',
}

export type UnitFilterOptions = {
  status?: InputMaybe<Array<UnitFilterOptionStatus>>;
  unitType?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UnitInput = {
  /** Number of units to create */
  count?: InputMaybe<Scalars['Int']['input']>;
  /** Prefix for unit names */
  prefix?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  unitTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type UnitTypeCapacity = {
  __typename?: 'UnitTypeCapacity';
  availability: Scalars['Int']['output'];
  capacity: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  unitType: Scalars['String']['output'];
};

export type UnitTypeObject = {
  __typename?: 'UnitTypeObject';
  bedType?: Maybe<InventoryBedType>;
  dateCreated: Scalars['ISO8601DateTime']['output'];
  dateUpdated: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  unitSize?: Maybe<Scalars['Int']['output']>;
};

export type UnitsPaginated = {
  __typename?: 'UnitsPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<Unit>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

/** Autogenerated input type of UpdateClientImage */
export type UpdateClientImageInput = {
  clientId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  imageBlobId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateClientImage. */
export type UpdateClientImagePayload = {
  __typename?: 'UpdateClientImagePayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateExternalFormSubmission. */
export type UpdateExternalFormSubmissionPayload = {
  __typename?: 'UpdateExternalFormSubmissionPayload';
  errors: Array<ValidationError>;
  externalFormSubmission?: Maybe<ExternalFormSubmission>;
};

/** Autogenerated return type of UpdateFormDefinition. */
export type UpdateFormDefinitionPayload = {
  __typename?: 'UpdateFormDefinitionPayload';
  errors: Array<ValidationError>;
  formDefinition?: Maybe<FormDefinition>;
};

/** Autogenerated input type of UpdateFormRule */
export type UpdateFormRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  input: FormRuleInput;
};

/** Autogenerated return type of UpdateFormRule. */
export type UpdateFormRulePayload = {
  __typename?: 'UpdateFormRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  formRule: FormRule;
};

/** Autogenerated return type of UpdateProjectConfig. */
export type UpdateProjectConfigPayload = {
  __typename?: 'UpdateProjectConfigPayload';
  errors: Array<ValidationError>;
  projectConfig?: Maybe<ProjectConfig>;
};

/** Autogenerated return type of UpdateReferralPosting. */
export type UpdateReferralPostingPayload = {
  __typename?: 'UpdateReferralPostingPayload';
  errors: Array<ValidationError>;
  record?: Maybe<ReferralPosting>;
};

/** Autogenerated input type of UpdateRelationshipToHoH */
export type UpdateRelationshipToHoHInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether user has confirmed the action */
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  enrollmentId: Scalars['ID']['input'];
  enrollmentLockVersion?: InputMaybe<Scalars['Int']['input']>;
  relationshipToHoH: RelationshipToHoH;
};

/** Autogenerated return type of UpdateRelationshipToHoH. */
export type UpdateRelationshipToHoHPayload = {
  __typename?: 'UpdateRelationshipToHoHPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enrollment?: Maybe<Enrollment>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateServiceType. */
export type UpdateServiceTypePayload = {
  __typename?: 'UpdateServiceTypePayload';
  errors: Array<ValidationError>;
  serviceType?: Maybe<ServiceType>;
};

/** Autogenerated input type of UpdateUnits */
export type UpdateUnitsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  unitIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateUnits. */
export type UpdateUnitsPayload = {
  __typename?: 'UpdateUnitsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<ValidationError>;
  units: Array<Unit>;
};

export type UserAuditEventFilterOptions = {
  clientRecordType?: InputMaybe<Array<Scalars['ID']['input']>>;
  enrollmentRecordType?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  attribute: Scalars['String']['output'];
  data?: Maybe<Scalars['JsonObject']['output']>;
  fullMessage: Scalars['String']['output'];
  /** Unique ID for this error */
  id?: Maybe<Scalars['String']['output']>;
  /** Link ID of form item if this error is linked to a specific item */
  linkId?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  readableAttribute?: Maybe<Scalars['String']['output']>;
  /** ID of the AR record this error pertains to */
  recordId?: Maybe<Scalars['ID']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  severity: ValidationSeverity;
  type: ValidationType;
};

export enum ValidationSeverity {
  Error = 'error',
  Warning = 'warning',
}

export enum ValidationType {
  DataNotCollected = 'data_not_collected',
  Information = 'information',
  Invalid = 'invalid',
  NotAllowed = 'not_allowed',
  NotFound = 'not_found',
  OutOfRange = 'out_of_range',
  Required = 'required',
  ServerError = 'server_error',
}

/** Bound applied to the response value. The bound may or may not be dependent on another questions answer. */
export type ValueBound = {
  __typename?: 'ValueBound';
  /** Unique identifier for this bound */
  id: Scalars['String']['output'];
  /** Value to offset the comparison value. Can be positive or negative. If date, offset is applied as number of days. */
  offset?: Maybe<Scalars['Int']['output']>;
  /** Link ID of dependent question, if this items value should be compared to another items value */
  question?: Maybe<Scalars['String']['output']>;
  /** Severity of bound. If error, user will be unable to submit a value that does not meet this condition. */
  severity: ValidationSeverity;
  type: BoundType;
  valueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Name of Local Constant to use for comparison */
  valueLocalConstant?: Maybe<Scalars['String']['output']>;
  valueNumber?: Maybe<Scalars['Int']['output']>;
};

/** HUD VamcStationNumber (V6.1) */
export enum VamcStationNumber {
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (402) (402) Togus, ME */
  Num_402TogusMe = 'NUM_402_TOGUS_ME',
  /** (405) (405) White River Junction, VT */
  Num_405WhiteRiverJunctionVt = 'NUM_405_WHITE_RIVER_JUNCTION_VT',
  /** (436) (436) Montana HCS */
  Num_436MontanaHcs = 'NUM_436_MONTANA_HCS',
  /** (437) (437) Fargo, ND */
  Num_437FargoNd = 'NUM_437_FARGO_ND',
  /** (438) (438) Sioux Falls, SD */
  Num_438SiouxFallsSd = 'NUM_438_SIOUX_FALLS_SD',
  /** (442) (442) Cheyenne, WY */
  Num_442CheyenneWy = 'NUM_442_CHEYENNE_WY',
  /** (459GE) (459GE) Guam */
  Num_459GeGuam = 'NUM_459_GE_GUAM',
  /** (459) (459) Honolulu, HI */
  Num_459HonoluluHi = 'NUM_459_HONOLULU_HI',
  /** (460) (460) Wilmington, DE */
  Num_460WilmingtonDe = 'NUM_460_WILMINGTON_DE',
  /** (463) (463) Anchorage, AK */
  Num_463AnchorageAk = 'NUM_463_ANCHORAGE_AK',
  /** (501) (501) New Mexico HCS */
  Num_501NewMexicoHcs = 'NUM_501_NEW_MEXICO_HCS',
  /** (502) (502) Alexandria, LA */
  Num_502AlexandriaLa = 'NUM_502_ALEXANDRIA_LA',
  /** (503) (503) Altoona, PA */
  Num_503AltoonaPa = 'NUM_503_ALTOONA_PA',
  /** (504) (504) Amarillo, TX */
  Num_504AmarilloTx = 'NUM_504_AMARILLO_TX',
  /** (506) (506) Ann Arbor, MI */
  Num_506AnnArborMi = 'NUM_506_ANN_ARBOR_MI',
  /** (508) (508) Atlanta, GA */
  Num_508AtlantaGa = 'NUM_508_ATLANTA_GA',
  /** (509) (509) Augusta, GA */
  Num_509AugustaGa = 'NUM_509_AUGUSTA_GA',
  /** (512) (512) Baltimore HCS, MD */
  Num_512BaltimoreHcsMd = 'NUM_512_BALTIMORE_HCS_MD',
  /** (515) (515) Battle Creek, MI */
  Num_515BattleCreekMi = 'NUM_515_BATTLE_CREEK_MI',
  /** (516) (516) Bay Pines, FL */
  Num_516BayPinesFl = 'NUM_516_BAY_PINES_FL',
  /** (517) (517) Beckley, WV */
  Num_517BeckleyWv = 'NUM_517_BECKLEY_WV',
  /** (518) (518) Bedford, MA */
  Num_518BedfordMa = 'NUM_518_BEDFORD_MA',
  /** (519) (519) Big Spring, TX */
  Num_519BigSpringTx = 'NUM_519_BIG_SPRING_TX',
  /** (520) (520) Gulf Coast HCS, MS */
  Num_520GulfCoastHcsMs = 'NUM_520_GULF_COAST_HCS_MS',
  /** (521) (521) Birmingham, AL */
  Num_521BirminghamAl = 'NUM_521_BIRMINGHAM_AL',
  /** (523) (523) VA Boston HCS, MA */
  Num_523VaBostonHcsMa = 'NUM_523_VA_BOSTON_HCS_MA',
  /** (526) (526) Bronx, NY */
  Num_526BronxNy = 'NUM_526_BRONX_NY',
  /** (528A5) (528A5) Canandaigua, NY */
  Num_528A5CanandaiguaNy = 'NUM_528_A5_CANANDAIGUA_NY',
  /** (528A6) (528A6) Bath, NY */
  Num_528A6BathNy = 'NUM_528_A6_BATH_NY',
  /** (528A7) (528A7) Syracuse, NY */
  Num_528A7SyracuseNy = 'NUM_528_A7_SYRACUSE_NY',
  /** (528A8) (528A8) Albany, NY */
  Num_528A8AlbanyNy = 'NUM_528_A8_ALBANY_NY',
  /** (528) (528) Western New York, NY */
  Num_528WesternNewYorkNy = 'NUM_528_WESTERN_NEW_YORK_NY',
  /** (529) (529) Butler, PA */
  Num_529ButlerPa = 'NUM_529_BUTLER_PA',
  /** (531) (531) Boise, ID */
  Num_531BoiseId = 'NUM_531_BOISE_ID',
  /** (534) (534) Charleston, SC */
  Num_534CharlestonSc = 'NUM_534_CHARLESTON_SC',
  /** (537) (537) Jesse Brown VAMC (Chicago), IL */
  Num_537JesseBrownVamcChicagoIl = 'NUM_537_JESSE_BROWN_VAMC_CHICAGO_IL',
  /** (538) (538) Chillicothe, OH */
  Num_538ChillicotheOh = 'NUM_538_CHILLICOTHE_OH',
  /** (539) (539) Cincinnati, OH */
  Num_539CincinnatiOh = 'NUM_539_CINCINNATI_OH',
  /** (540) (540) Clarksburg, WV */
  Num_540ClarksburgWv = 'NUM_540_CLARKSBURG_WV',
  /** (541) (541) Cleveland, OH */
  Num_541ClevelandOh = 'NUM_541_CLEVELAND_OH',
  /** (542) (542) Coatesville, PA */
  Num_542CoatesvillePa = 'NUM_542_COATESVILLE_PA',
  /** (544) (544) Columbia, SC */
  Num_544ColumbiaSc = 'NUM_544_COLUMBIA_SC',
  /** (546) (546) Miami, FL */
  Num_546MiamiFl = 'NUM_546_MIAMI_FL',
  /** (548) (548) West Palm Beach, FL */
  Num_548WestPalmBeachFl = 'NUM_548_WEST_PALM_BEACH_FL',
  /** (549) (549) Dallas, TX */
  Num_549DallasTx = 'NUM_549_DALLAS_TX',
  /** (550) (550) Danville, IL */
  Num_550DanvilleIl = 'NUM_550_DANVILLE_IL',
  /** (552) (552) Dayton, OH */
  Num_552DaytonOh = 'NUM_552_DAYTON_OH',
  /** (553) (553) Detroit, MI */
  Num_553DetroitMi = 'NUM_553_DETROIT_MI',
  /** (554) (554) Denver, CO */
  Num_554DenverCo = 'NUM_554_DENVER_CO',
  /** (556) (556) Captain James A Lovell FHCC */
  Num_556CaptainJamesALovellFhcc = 'NUM_556_CAPTAIN_JAMES_A_LOVELL_FHCC',
  /** (557) (557) Dublin, GA */
  Num_557DublinGa = 'NUM_557_DUBLIN_GA',
  /** (558) (558) Durham, NC */
  Num_558DurhamNc = 'NUM_558_DURHAM_NC',
  /** (561) (561) New Jersey HCS, NJ */
  Num_561NewJerseyHcsNj = 'NUM_561_NEW_JERSEY_HCS_NJ',
  /** (562) (562) Erie, PA */
  Num_562EriePa = 'NUM_562_ERIE_PA',
  /** (564) (564) Fayetteville, AR */
  Num_564FayettevilleAr = 'NUM_564_FAYETTEVILLE_AR',
  /** (565) (565) Fayetteville, NC */
  Num_565FayettevilleNc = 'NUM_565_FAYETTEVILLE_NC',
  /** (568) (568) Black Hills HCS, SD */
  Num_568BlackHillsHcsSd = 'NUM_568_BLACK_HILLS_HCS_SD',
  /** (570) (570) Fresno, CA */
  Num_570FresnoCa = 'NUM_570_FRESNO_CA',
  /** (573) (573) Gainesville, FL */
  Num_573GainesvilleFl = 'NUM_573_GAINESVILLE_FL',
  /** (575) (575) Grand Junction, CO */
  Num_575GrandJunctionCo = 'NUM_575_GRAND_JUNCTION_CO',
  /** (578) (578) Hines, IL */
  Num_578HinesIl = 'NUM_578_HINES_IL',
  /** (580) (580) Houston, TX */
  Num_580HoustonTx = 'NUM_580_HOUSTON_TX',
  /** (581) (581) Huntington, WV */
  Num_581HuntingtonWv = 'NUM_581_HUNTINGTON_WV',
  /** (583) (583) Indianapolis, IN */
  Num_583IndianapolisIn = 'NUM_583_INDIANAPOLIS_IN',
  /** (585) (585) Iron Mountain, MI */
  Num_585IronMountainMi = 'NUM_585_IRON_MOUNTAIN_MI',
  /** (586) (586) Jackson, MS */
  Num_586JacksonMs = 'NUM_586_JACKSON_MS',
  /** (589A4) (589A4) Columbia, MO */
  Num_589A4ColumbiaMo = 'NUM_589_A4_COLUMBIA_MO',
  /** (589A5) (589A5) Kansas City, MO */
  Num_589A5KansasCityMo = 'NUM_589_A5_KANSAS_CITY_MO',
  /** (589A6) (589A6) Eastern KS HCS, KS */
  Num_589A6EasternKsHcsKs = 'NUM_589_A6_EASTERN_KS_HCS_KS',
  /** (589A7) (589A7) Wichita, KS */
  Num_589A7WichitaKs = 'NUM_589_A7_WICHITA_KS',
  /** (589) (589) Kansas City, MO */
  Num_589KansasCityMo = 'NUM_589_KANSAS_CITY_MO',
  /** (590) (590) Hampton, VA */
  Num_590HamptonVa = 'NUM_590_HAMPTON_VA',
  /** (593) (593) Las Vegas, NV */
  Num_593LasVegasNv = 'NUM_593_LAS_VEGAS_NV',
  /** (595) (595) Lebanon, PA */
  Num_595LebanonPa = 'NUM_595_LEBANON_PA',
  /** (596) (596) Lexington, KY */
  Num_596LexingtonKy = 'NUM_596_LEXINGTON_KY',
  /** (598) (598) Little Rock, AR */
  Num_598LittleRockAr = 'NUM_598_LITTLE_ROCK_AR',
  /** (600) (600) Long Beach, CA */
  Num_600LongBeachCa = 'NUM_600_LONG_BEACH_CA',
  /** (603) (603) Louisville, KY */
  Num_603LouisvilleKy = 'NUM_603_LOUISVILLE_KY',
  /** (605) (605) Loma Linda, CA */
  Num_605LomaLindaCa = 'NUM_605_LOMA_LINDA_CA',
  /** (607) (607) Madison, WI */
  Num_607MadisonWi = 'NUM_607_MADISON_WI',
  /** (608) (608) Manchester, NH */
  Num_608ManchesterNh = 'NUM_608_MANCHESTER_NH',
  /** (610) (610) Northern Indiana HCS, IN */
  Num_610NorthernIndianaHcsIn = 'NUM_610_NORTHERN_INDIANA_HCS_IN',
  /** (612) (612) N. California, CA */
  Num_612NCaliforniaCa = 'NUM_612_N_CALIFORNIA_CA',
  /** (613) (613) Martinsburg, WV */
  Num_613MartinsburgWv = 'NUM_613_MARTINSBURG_WV',
  /** (614) (614) Memphis, TN */
  Num_614MemphisTn = 'NUM_614_MEMPHIS_TN',
  /** (618) (618) Minneapolis, MN */
  Num_618MinneapolisMn = 'NUM_618_MINNEAPOLIS_MN',
  /** (619) (619) Central Alabama Veterans HCS, AL */
  Num_619CentralAlabamaVeteransHcsAl = 'NUM_619_CENTRAL_ALABAMA_VETERANS_HCS_AL',
  /** (620) (620) VA Hudson Valley HCS, NY */
  Num_620VaHudsonValleyHcsNy = 'NUM_620_VA_HUDSON_VALLEY_HCS_NY',
  /** (621) (621) Mountain Home, TN */
  Num_621MountainHomeTn = 'NUM_621_MOUNTAIN_HOME_TN',
  /** (623) (623) Muskogee, OK */
  Num_623MuskogeeOk = 'NUM_623_MUSKOGEE_OK',
  /** (626) (626) Middle Tennessee HCS, TN */
  Num_626MiddleTennesseeHcsTn = 'NUM_626_MIDDLE_TENNESSEE_HCS_TN',
  /** (629) (629) New Orleans, LA */
  Num_629NewOrleansLa = 'NUM_629_NEW_ORLEANS_LA',
  /** (630) (630) New York Harbor HCS, NY */
  Num_630NewYorkHarborHcsNy = 'NUM_630_NEW_YORK_HARBOR_HCS_NY',
  /** (631) (631) VA Central Western Massachusetts HCS */
  Num_631VaCentralWesternMassachusettsHcs = 'NUM_631_VA_CENTRAL_WESTERN_MASSACHUSETTS_HCS',
  /** (632) (632) Northport, NY */
  Num_632NorthportNy = 'NUM_632_NORTHPORT_NY',
  /** (635) (635) Oklahoma City, OK */
  Num_635OklahomaCityOk = 'NUM_635_OKLAHOMA_CITY_OK',
  /** (636A6) (636A6) Central Iowa, IA */
  Num_636A6CentralIowaIa = 'NUM_636_A6_CENTRAL_IOWA_IA',
  /** (636A8) (636A8) Iowa City, IA */
  Num_636A8IowaCityIa = 'NUM_636_A8_IOWA_CITY_IA',
  /** (636) (636) Nebraska-W Iowa, NE */
  Num_636NebraskaWIowaNe = 'NUM_636_NEBRASKA_W_IOWA_NE',
  /** (637) (637) Asheville, NC */
  Num_637AshevilleNc = 'NUM_637_ASHEVILLE_NC',
  /** (640) (640) Palo Alto, CA */
  Num_640PaloAltoCa = 'NUM_640_PALO_ALTO_CA',
  /** (642) (642) Philadelphia, PA */
  Num_642PhiladelphiaPa = 'NUM_642_PHILADELPHIA_PA',
  /** (644) (644) Phoenix, AZ */
  Num_644PhoenixAz = 'NUM_644_PHOENIX_AZ',
  /** (646) (646) Pittsburgh, PA */
  Num_646PittsburghPa = 'NUM_646_PITTSBURGH_PA',
  /** (648) (648) Portland, OR */
  Num_648PortlandOr = 'NUM_648_PORTLAND_OR',
  /** (649) (649) Northern Arizona HCS */
  Num_649NorthernArizonaHcs = 'NUM_649_NORTHERN_ARIZONA_HCS',
  /** (650) (650) Providence, RI */
  Num_650ProvidenceRi = 'NUM_650_PROVIDENCE_RI',
  /** (652) (652) Richmond, VA */
  Num_652RichmondVa = 'NUM_652_RICHMOND_VA',
  /** (653) (653) Roseburg, OR */
  Num_653RoseburgOr = 'NUM_653_ROSEBURG_OR',
  /** (654) (654) Reno, NV */
  Num_654RenoNv = 'NUM_654_RENO_NV',
  /** (655) (655) Saginaw, MI */
  Num_655SaginawMi = 'NUM_655_SAGINAW_MI',
  /** (656) (656) St. Cloud, MN */
  Num_656StCloudMn = 'NUM_656_ST_CLOUD_MN',
  /** (657A4) (657A4) Poplar Bluff, MO */
  Num_657A4PoplarBluffMo = 'NUM_657_A4_POPLAR_BLUFF_MO',
  /** (657A5) (657A5) Marion, IL */
  Num_657A5MarionIl = 'NUM_657_A5_MARION_IL',
  /** (657) (657) St. Louis, MO */
  Num_657StLouisMo = 'NUM_657_ST_LOUIS_MO',
  /** (658) (658) Salem, VA */
  Num_658SalemVa = 'NUM_658_SALEM_VA',
  /** (659) (659) Salisbury, NC */
  Num_659SalisburyNc = 'NUM_659_SALISBURY_NC',
  /** (660) (660) Salt Lake City, UT */
  Num_660SaltLakeCityUt = 'NUM_660_SALT_LAKE_CITY_UT',
  /** (662) (662) San Francisco, CA */
  Num_662SanFranciscoCa = 'NUM_662_SAN_FRANCISCO_CA',
  /** (663) (663) VA Puget Sound, WA */
  Num_663VaPugetSoundWa = 'NUM_663_VA_PUGET_SOUND_WA',
  /** (664) (664) San Diego, CA */
  Num_664SanDiegoCa = 'NUM_664_SAN_DIEGO_CA',
  /** (666) (666) Sheridan, WY */
  Num_666SheridanWy = 'NUM_666_SHERIDAN_WY',
  /** (667) (667) Shreveport, LA */
  Num_667ShreveportLa = 'NUM_667_SHREVEPORT_LA',
  /** (668) (668) Spokane, WA */
  Num_668SpokaneWa = 'NUM_668_SPOKANE_WA',
  /** (671) (671) San Antonio, TX */
  Num_671SanAntonioTx = 'NUM_671_SAN_ANTONIO_TX',
  /** (672) (672) San Juan, PR */
  Num_672SanJuanPr = 'NUM_672_SAN_JUAN_PR',
  /** (673) (673) Tampa, FL */
  Num_673TampaFl = 'NUM_673_TAMPA_FL',
  /** (674) (674) Temple, TX */
  Num_674TempleTx = 'NUM_674_TEMPLE_TX',
  /** (675) (675) Orlando, FL */
  Num_675OrlandoFl = 'NUM_675_ORLANDO_FL',
  /** (676) (676) Tomah, WI */
  Num_676TomahWi = 'NUM_676_TOMAH_WI',
  /** (678) (678) Southern Arizona HCS */
  Num_678SouthernArizonaHcs = 'NUM_678_SOUTHERN_ARIZONA_HCS',
  /** (679) (679) Tuscaloosa, AL */
  Num_679TuscaloosaAl = 'NUM_679_TUSCALOOSA_AL',
  /** (687) (687) Walla Walla, WA */
  Num_687WallaWallaWa = 'NUM_687_WALLA_WALLA_WA',
  /** (688) (688) Washington, DC */
  Num_688WashingtonDc = 'NUM_688_WASHINGTON_DC',
  /** (689) (689) VA Connecticut HCS, CT */
  Num_689VaConnecticutHcsCt = 'NUM_689_VA_CONNECTICUT_HCS_CT',
  /** (691) (691) Greater Los Angeles HCS */
  Num_691GreaterLosAngelesHcs = 'NUM_691_GREATER_LOS_ANGELES_HCS',
  /** (692) (692) White City, OR */
  Num_692WhiteCityOr = 'NUM_692_WHITE_CITY_OR',
  /** (693) (693) Wilkes-Barre, PA */
  Num_693WilkesBarrePa = 'NUM_693_WILKES_BARRE_PA',
  /** (695) (695) Milwaukee, WI */
  Num_695MilwaukeeWi = 'NUM_695_MILWAUKEE_WI',
  /** (740) (740) VA Texas Valley Coastal Bend HCS */
  Num_740VaTexasValleyCoastalBendHcs = 'NUM_740_VA_TEXAS_VALLEY_COASTAL_BEND_HCS',
  /** (756) (756) El Paso, TX */
  Num_756ElPasoTx = 'NUM_756_EL_PASO_TX',
  /** (757) (757) Columbus, OH */
  Num_757ColumbusOh = 'NUM_757_COLUMBUS_OH',
}

export type VersionedRecordInput = {
  id: Scalars['ID']['input'];
  lockVersion?: InputMaybe<Scalars['Int']['input']>;
};

/** HUD ViralLoadAvailable (W4.3) */
export enum ViralLoadAvailable {
  /** (1) Available */
  Available = 'AVAILABLE',
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) Not available */
  NotAvailable = 'NOT_AVAILABLE',
  /** (2) Undetectable */
  Undetectable = 'UNDETECTABLE',
}

/** Autogenerated return type of VoidReferralRequest. */
export type VoidReferralRequestPayload = {
  __typename?: 'VoidReferralRequestPayload';
  errors: Array<ValidationError>;
  record?: Maybe<ReferralRequest>;
};

/** HUD WhenDVOccurred (4.11.A) */
export enum WhenDvOccurred {
  /** (8) Client doesn't know */
  ClientDoesnTKnow = 'CLIENT_DOESN_T_KNOW',
  /** (9) Client prefers not to answer */
  ClientPrefersNotToAnswer = 'CLIENT_PREFERS_NOT_TO_ANSWER',
  /** (99) Data not collected */
  DataNotCollected = 'DATA_NOT_COLLECTED',
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (4) One year or more */
  OneYearOrMore = 'ONE_YEAR_OR_MORE',
  /** (3) Six months to one year ago (excluding one year exactly) */
  SixMonthsToOneYearAgo = 'SIX_MONTHS_TO_ONE_YEAR_AGO',
  /** (2) Three to six months ago (excluding six months exactly) */
  ThreeToSixMonthsAgo = 'THREE_TO_SIX_MONTHS_AGO',
  /** (1) Within the past three months */
  WithinThePastThreeMonths = 'WITHIN_THE_PAST_THREE_MONTHS',
}

/** HUD WorkerResponse (R19.A) */
export enum WorkerResponse {
  /** Invalid Value */
  Invalid = 'INVALID',
  /** (0) No */
  No = 'NO',
  /** (2) Worker does not know */
  WorkerDoesNotKnow = 'WORKER_DOES_NOT_KNOW',
  /** (1) Yes */
  Yes = 'YES',
}

/** HUD Youth Education Status */
export type YouthEducationStatus = {
  __typename?: 'YouthEducationStatus';
  client: Client;
  createdBy?: Maybe<ApplicationUser>;
  currentEdStatus?: Maybe<CurrentEdStatus>;
  currentSchoolAttend?: Maybe<CurrentSchoolAttended>;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  enrollment: Enrollment;
  id: Scalars['ID']['output'];
  informationDate?: Maybe<Scalars['ISO8601Date']['output']>;
  mostRecentEdStatus?: Maybe<MostRecentEdStatus>;
  user?: Maybe<ApplicationUser>;
};

export type YouthEducationStatusesPaginated = {
  __typename?: 'YouthEducationStatusesPaginated';
  hasMoreAfter: Scalars['Boolean']['output'];
  hasMoreBefore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  nodes: Array<YouthEducationStatus>;
  nodesCount: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  pagesCount: Scalars['Int']['output'];
};

export type RootPermissionsFragment = {
  __typename?: 'QueryAccess';
  id: string;
  canAdministerHmis: boolean;
  canManageForms: boolean;
  canAdministrateConfig: boolean;
  canConfigureDataCollection: boolean;
  canImpersonateUsers: boolean;
  canAuditUsers: boolean;
  canManageDeniedReferrals: boolean;
  canMergeClients: boolean;
  canTransferEnrollments: boolean;
  canViewClients: boolean;
  canEditClients: boolean;
  canViewDob: boolean;
  canViewClientAlerts: boolean;
  canViewMyDashboard: boolean;
  canEditOrganization: boolean;
  canEditProjectDetails: boolean;
};

export type ClientAccessFieldsFragment = {
  __typename?: 'ClientAccess';
  id: string;
  canEditClient: boolean;
  canDeleteClient: boolean;
  canViewDob: boolean;
  canViewFullSsn: boolean;
  canViewPartialSsn: boolean;
  canViewClientName: boolean;
  canViewClientPhoto: boolean;
  canViewClientAlerts: boolean;
  canManageClientAlerts: boolean;
  canViewEnrollmentDetails: boolean;
  canAuditClients: boolean;
  canManageScanCards: boolean;
  canMergeClients: boolean;
  canViewAnyFiles: boolean;
  canManageAnyClientFiles: boolean;
  canManageOwnClientFiles: boolean;
  canUploadClientFiles: boolean;
};

export type EnrollmentAccessFieldsFragment = {
  __typename?: 'EnrollmentAccess';
  id: string;
  canEditEnrollments: boolean;
  canDeleteEnrollments: boolean;
  canAuditEnrollments: boolean;
  canViewEnrollmentLocationMap: boolean;
};

export type AssessmentAccessFieldsFragment = {
  __typename?: 'AssessmentAccess';
  id: string;
  canDeleteAssessments: boolean;
  canDeleteEnrollments: boolean;
  canEditEnrollments: boolean;
};

export type ProjectAccessFieldsFragment = {
  __typename?: 'ProjectAccess';
  id: string;
  canDeleteProject: boolean;
  canEnrollClients: boolean;
  canEditEnrollments: boolean;
  canViewEnrollmentDetails: boolean;
  canEditProjectDetails: boolean;
  canViewUnits: boolean;
  canManageUnits: boolean;
  canManageIncomingReferrals: boolean;
  canManageOutgoingReferrals: boolean;
  canManageExternalFormSubmissions: boolean;
  canSplitHouseholds: boolean;
};

export type OrganizationAccessFieldsFragment = {
  __typename?: 'OrganizationAccess';
  id: string;
  canEditOrganization: boolean;
  canDeleteOrganization: boolean;
};

export type GetRootPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRootPermissionsQuery = {
  __typename?: 'Query';
  access: {
    __typename?: 'QueryAccess';
    id: string;
    canAdministerHmis: boolean;
    canManageForms: boolean;
    canAdministrateConfig: boolean;
    canConfigureDataCollection: boolean;
    canImpersonateUsers: boolean;
    canAuditUsers: boolean;
    canManageDeniedReferrals: boolean;
    canMergeClients: boolean;
    canTransferEnrollments: boolean;
    canViewClients: boolean;
    canEditClients: boolean;
    canViewDob: boolean;
    canViewClientAlerts: boolean;
    canViewMyDashboard: boolean;
    canEditOrganization: boolean;
    canEditProjectDetails: boolean;
  };
};

export type ClientWithAlertFieldsFragment = {
  __typename?: 'Client';
  id: string;
  lockVersion: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  nameSuffix?: string | null;
  alerts: Array<{
    __typename?: 'ClientAlert';
    id: string;
    note: string;
    expirationDate?: string | null;
    createdAt: string;
    priority: ClientAlertPriorityLevel;
    createdBy?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  }>;
  access: {
    __typename?: 'ClientAccess';
    id: string;
    canViewClientAlerts: boolean;
    canManageClientAlerts: boolean;
  };
};

export type ClientAlertFieldsFragment = {
  __typename?: 'ClientAlert';
  id: string;
  note: string;
  expirationDate?: string | null;
  createdAt: string;
  priority: ClientAlertPriorityLevel;
  createdBy?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type CreateClientAlertMutationVariables = Exact<{
  input: ClientAlertInput;
}>;

export type CreateClientAlertMutation = {
  __typename?: 'Mutation';
  createClientAlert?: {
    __typename?: 'CreateClientAlertPayload';
    clientAlert?: {
      __typename?: 'ClientAlert';
      id: string;
      note: string;
      expirationDate?: string | null;
      createdAt: string;
      priority: ClientAlertPriorityLevel;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteClientAlertMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteClientAlertMutation = {
  __typename?: 'Mutation';
  deleteClientAlert?: {
    __typename?: 'DeleteClientAlertPayload';
    clientAlert?: {
      __typename?: 'ClientAlert';
      id: string;
      note: string;
      expirationDate?: string | null;
      createdAt: string;
      priority: ClientAlertPriorityLevel;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetHouseholdClientAlertsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetHouseholdClientAlertsQuery = {
  __typename?: 'Query';
  household?: {
    __typename?: 'Household';
    id: string;
    householdClients: Array<{
      __typename?: 'HouseholdClient';
      id: string;
      client: {
        __typename?: 'Client';
        id: string;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
        alerts: Array<{
          __typename?: 'ClientAlert';
          id: string;
          note: string;
          expirationDate?: string | null;
          createdAt: string;
          priority: ClientAlertPriorityLevel;
          createdBy?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }>;
        access: {
          __typename?: 'ClientAccess';
          id: string;
          canViewClientAlerts: boolean;
          canManageClientAlerts: boolean;
        };
      };
    }>;
  } | null;
};

export type AssessmentFieldsFragment = {
  __typename?: 'Assessment';
  id: string;
  lockVersion: number;
  inProgress: boolean;
  assessmentDate: string;
  dataCollectionStage?: DataCollectionStage | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string | null;
  role: AssessmentRole;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  createdBy?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  definition: {
    __typename?: 'FormDefinition';
    id: string;
    cacheKey: string;
    title: string;
  };
};

export type AssessmentWithRecordsFragment = {
  __typename?: 'Assessment';
  id: string;
  lockVersion: number;
  inProgress: boolean;
  assessmentDate: string;
  dataCollectionStage?: DataCollectionStage | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string | null;
  role: AssessmentRole;
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    lockVersion: number;
    entryDate: string;
    exitDate?: string | null;
    disablingCondition?: NoYesReasonsForMissingData | null;
    dateOfEngagement?: string | null;
    moveInDate?: string | null;
    livingSituation?: PriorLivingSituation | null;
    rentalSubsidyType?: RentalSubsidyType | null;
    lengthOfStay?: ResidencePriorLengthOfStay | null;
    losUnderThreshold?: NoYesMissing | null;
    previousStreetEssh?: NoYesMissing | null;
    dateToStreetEssh?: string | null;
    timesHomelessPastThreeYears?: TimesHomelessPastThreeYears | null;
    monthsHomelessPastThreeYears?: MonthsHomelessPastThreeYears | null;
    enrollmentCoc?: string | null;
    dateOfPathStatus?: string | null;
    clientEnrolledInPath?: NoYesMissing | null;
    reasonNotEnrolled?: ReasonNotEnrolled | null;
    percentAmi?: PercentAmi | null;
    referralSource?: ReferralSource | null;
    countOutreachReferralApproaches?: number | null;
    dateOfBcpStatus?: string | null;
    eligibleForRhy?: NoYesMissing | null;
    reasonNoServices?: ReasonNoServices | null;
    runawayYouth?: NoYesReasonsForMissingData | null;
    sexualOrientation?: SexualOrientation | null;
    sexualOrientationOther?: string | null;
    formerWardChildWelfare?: NoYesReasonsForMissingData | null;
    childWelfareYears?: RhyNumberofYears | null;
    childWelfareMonths?: number | null;
    formerWardJuvenileJustice?: NoYesReasonsForMissingData | null;
    juvenileJusticeYears?: RhyNumberofYears | null;
    juvenileJusticeMonths?: number | null;
    unemploymentFam?: NoYesMissing | null;
    mentalHealthDisorderFam?: NoYesMissing | null;
    physicalDisabilityFam?: NoYesMissing | null;
    alcoholDrugUseDisorderFam?: NoYesMissing | null;
    insufficientIncome?: NoYesMissing | null;
    incarceratedParent?: NoYesMissing | null;
    targetScreenReqd?: NoYesMissing | null;
    timeToHousingLoss?: TimeToHousingLoss | null;
    annualPercentAmi?: AnnualPercentAmi | null;
    literalHomelessHistory?: LiteralHomelessHistory | null;
    clientLeaseholder?: NoYesMissing | null;
    hohLeaseholder?: NoYesMissing | null;
    subsidyAtRisk?: NoYesMissing | null;
    evictionHistory?: EvictionHistory | null;
    criminalRecord?: NoYesMissing | null;
    incarceratedAdult?: IncarceratedAdult | null;
    prisonDischarge?: NoYesMissing | null;
    sexOffender?: NoYesMissing | null;
    disabledHoh?: NoYesMissing | null;
    currentPregnant?: NoYesMissing | null;
    singleParent?: NoYesMissing | null;
    dependentUnder6?: DependentUnder6 | null;
    hh5Plus?: NoYesMissing | null;
    cocPrioritized?: NoYesMissing | null;
    hpScreeningScore?: number | null;
    thresholdScore?: number | null;
    vamcStation?: VamcStationNumber | null;
    translationNeeded?: NoYesReasonsForMissingData | null;
    preferredLanguage?: PreferredLanguage | null;
    preferredLanguageDifferent?: string | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
    client: { __typename?: 'Client'; id: string };
  };
  ceAssessment?: {
    __typename?: 'CeAssessment';
    id: string;
    assessmentDate: string;
    assessmentLevel?: AssessmentLevel | null;
    assessmentLocation: string;
    assessmentType?: AssessmentType | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    dateDeleted?: string | null;
    prioritizationStatus?: PrioritizationStatus | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
  event?: {
    __typename?: 'Event';
    id: string;
    event: EventType;
    eventDate: string;
    locationCrisisOrPhHousing?: string | null;
    probSolDivRrResult?: NoYesMissing | null;
    referralCaseManageAfter?: NoYesMissing | null;
    referralResult?: ReferralResult | null;
    resultDate?: string | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    dateDeleted?: string | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
  incomeBenefit?: {
    __typename: 'IncomeBenefit';
    adap?: NoYesReasonsForMissingData | null;
    alimony?: NoYesMissing | null;
    alimonyAmount?: number | null;
    benefitsFromAnySource?: NoYesReasonsForMissingData | null;
    childSupport?: NoYesMissing | null;
    childSupportAmount?: number | null;
    cobra?: NoYesMissing | null;
    connectionWithSoar?: NoYesReasonsForMissingData | null;
    dataCollectionStage: DataCollectionStage;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    earned?: NoYesMissing | null;
    earnedAmount?: number | null;
    employerProvided?: NoYesMissing | null;
    ga?: NoYesMissing | null;
    gaAmount?: number | null;
    id: string;
    incomeFromAnySource?: NoYesReasonsForMissingData | null;
    indianHealthServices?: NoYesMissing | null;
    informationDate?: string | null;
    insuranceFromAnySource?: NoYesReasonsForMissingData | null;
    medicaid?: NoYesMissing | null;
    medicare?: NoYesMissing | null;
    noAdapReason?: NoAssistanceReason | null;
    noCobraReason?: ReasonNotInsured | null;
    noEmployerProvidedReason?: ReasonNotInsured | null;
    noIndianHealthServicesReason?: ReasonNotInsured | null;
    noMedicaidReason?: ReasonNotInsured | null;
    noMedicareReason?: ReasonNotInsured | null;
    noPrivatePayReason?: ReasonNotInsured | null;
    noRyanWhiteReason?: NoAssistanceReason | null;
    noSchipReason?: ReasonNotInsured | null;
    noStateHealthInsReason?: ReasonNotInsured | null;
    noVhaReason?: ReasonNotInsured | null;
    otherBenefitsSource?: NoYesMissing | null;
    otherBenefitsSourceIdentify?: string | null;
    otherIncomeAmount?: number | null;
    otherIncomeSource?: NoYesMissing | null;
    otherIncomeSourceIdentify?: string | null;
    otherInsurance?: NoYesMissing | null;
    otherInsuranceIdentify?: string | null;
    otherTanf?: NoYesMissing | null;
    pension?: NoYesMissing | null;
    pensionAmount?: number | null;
    privateDisability?: NoYesMissing | null;
    privateDisabilityAmount?: number | null;
    privatePay?: NoYesMissing | null;
    ryanWhiteMedDent?: NoYesReasonsForMissingData | null;
    schip?: NoYesMissing | null;
    snap?: NoYesMissing | null;
    socSecRetirement?: NoYesMissing | null;
    socSecRetirementAmount?: number | null;
    ssdi?: NoYesMissing | null;
    ssdiAmount?: number | null;
    ssi?: NoYesMissing | null;
    ssiAmount?: number | null;
    stateHealthIns?: NoYesMissing | null;
    tanf?: NoYesMissing | null;
    tanfAmount?: number | null;
    tanfChildCare?: NoYesMissing | null;
    tanfTransportation?: NoYesMissing | null;
    totalMonthlyIncome?: string | null;
    unemployment?: NoYesMissing | null;
    unemploymentAmount?: number | null;
    vaDisabilityNonService?: NoYesMissing | null;
    vaDisabilityNonServiceAmount?: number | null;
    vaDisabilityService?: NoYesMissing | null;
    vaDisabilityServiceAmount?: number | null;
    vhaServices?: NoYesMissing | null;
    wic?: NoYesMissing | null;
    workersComp?: NoYesMissing | null;
    workersCompAmount?: number | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
  } | null;
  disabilityGroup?: {
    __typename: 'DisabilityGroup';
    id: string;
    dataCollectionStage: DataCollectionStage;
    informationDate: string;
    disablingCondition: NoYesReasonsForMissingData;
    chronicHealthCondition?: NoYesReasonsForMissingData | null;
    chronicHealthConditionIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
    developmentalDisability?: NoYesReasonsForMissingData | null;
    hivAids?: NoYesReasonsForMissingData | null;
    mentalHealthDisorder?: NoYesReasonsForMissingData | null;
    mentalHealthDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
    physicalDisability?: NoYesReasonsForMissingData | null;
    physicalDisabilityIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
    substanceUseDisorder?: DisabilityResponse | null;
    substanceUseDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    tCellCountAvailable?: NoYesReasonsForMissingData | null;
    tCellCount?: number | null;
    tCellSource?: TCellSourceViralLoadSource | null;
    viralLoadAvailable?: ViralLoadAvailable | null;
    viralLoad?: number | null;
    viralLoadSource?: TCellSourceViralLoadSource | null;
    antiRetroviral?: NoYesReasonsForMissingData | null;
  } | null;
  healthAndDv?: {
    __typename: 'HealthAndDv';
    currentlyFleeing?: NoYesReasonsForMissingData | null;
    dataCollectionStage: DataCollectionStage;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    dentalHealthStatus?: HealthStatus | null;
    domesticViolenceSurvivor?: NoYesReasonsForMissingData | null;
    dueDate?: string | null;
    generalHealthStatus?: HealthStatus | null;
    id: string;
    informationDate?: string | null;
    mentalHealthStatus?: HealthStatus | null;
    pregnancyStatus?: NoYesReasonsForMissingData | null;
    whenOccurred?: WhenDvOccurred | null;
  } | null;
  exit?: {
    __typename?: 'Exit';
    id: string;
    aftercareDate?: string | null;
    aftercareProvided?: AftercareProvided | null;
    aftercareMethods?: Array<AftercareMethod> | null;
    askedOrForcedToExchangeForSex?: NoYesReasonsForMissingData | null;
    askedOrForcedToExchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
    cmExitReason?: CmExitReason | null;
    coercedToContinueWork?: NoYesReasonsForMissingData | null;
    counselingReceived?: NoYesMissing | null;
    counselingMethods?: Array<CounselingMethod> | null;
    countOfExchangeForSex?: CountExchangeForSex | null;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    destination: Destination;
    destinationSafeClient?: NoYesReasonsForMissingData | null;
    destinationSafeWorker?: WorkerResponse | null;
    destinationSubsidyType?: RentalSubsidyType | null;
    earlyExitReason?: ExpelledReason | null;
    exchangeForSex?: NoYesReasonsForMissingData | null;
    exchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
    exitDate: string;
    housingAssessment?: HousingAssessmentAtExit | null;
    laborExploitPastThreeMonths?: NoYesReasonsForMissingData | null;
    otherDestination?: string | null;
    posAdultConnections?: WorkerResponse | null;
    posCommunityConnections?: WorkerResponse | null;
    posPeerConnections?: WorkerResponse | null;
    postExitCounselingPlan?: NoYesMissing | null;
    projectCompletionStatus?: ProjectCompletionStatus | null;
    sessionCountAtExit?: number | null;
    sessionsInPlan?: number | null;
    subsidyInformation?: SubsidyInformation | null;
    workPlaceViolenceThreats?: NoYesReasonsForMissingData | null;
    workplacePromiseDifference?: NoYesReasonsForMissingData | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
  } | null;
  youthEducationStatus?: {
    __typename?: 'YouthEducationStatus';
    currentEdStatus?: CurrentEdStatus | null;
    currentSchoolAttend?: CurrentSchoolAttended | null;
    dataCollectionStage: DataCollectionStage;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    id: string;
    informationDate?: string | null;
    mostRecentEdStatus?: MostRecentEdStatus | null;
  } | null;
  employmentEducation?: {
    __typename?: 'EmploymentEducation';
    dataCollectionStage: DataCollectionStage;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    employed?: NoYesReasonsForMissingData | null;
    employmentType?: EmploymentType | null;
    id: string;
    informationDate?: string | null;
    lastGradeCompleted?: LastGradeCompleted | null;
    notEmployedReason?: NotEmployedReason | null;
    schoolStatus?: SchoolStatus | null;
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
  access: {
    __typename?: 'AssessmentAccess';
    id: string;
    canDeleteAssessments: boolean;
    canDeleteEnrollments: boolean;
    canEditEnrollments: boolean;
  };
  geolocation?: {
    __typename?: 'Geolocation';
    id: string;
    coordinates: {
      __typename?: 'GeolocationCoordinates';
      id: string;
      latitude: string;
      longitude: string;
    };
  } | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  createdBy?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  definition: {
    __typename?: 'FormDefinition';
    id: string;
    cacheKey: string;
    title: string;
  };
};

export type AssessmentWithValuesFragment = {
  __typename?: 'Assessment';
  wipValues?: any | null;
  id: string;
  lockVersion: number;
  inProgress: boolean;
  assessmentDate: string;
  dataCollectionStage?: DataCollectionStage | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string | null;
  role: AssessmentRole;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  createdBy?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  definition: {
    __typename?: 'FormDefinition';
    id: string;
    cacheKey: string;
    title: string;
  };
};

export type FullAssessmentFragment = {
  __typename?: 'Assessment';
  wipValues?: any | null;
  id: string;
  lockVersion: number;
  inProgress: boolean;
  assessmentDate: string;
  dataCollectionStage?: DataCollectionStage | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string | null;
  role: AssessmentRole;
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    lockVersion: number;
    entryDate: string;
    exitDate?: string | null;
    disablingCondition?: NoYesReasonsForMissingData | null;
    dateOfEngagement?: string | null;
    moveInDate?: string | null;
    livingSituation?: PriorLivingSituation | null;
    rentalSubsidyType?: RentalSubsidyType | null;
    lengthOfStay?: ResidencePriorLengthOfStay | null;
    losUnderThreshold?: NoYesMissing | null;
    previousStreetEssh?: NoYesMissing | null;
    dateToStreetEssh?: string | null;
    timesHomelessPastThreeYears?: TimesHomelessPastThreeYears | null;
    monthsHomelessPastThreeYears?: MonthsHomelessPastThreeYears | null;
    enrollmentCoc?: string | null;
    dateOfPathStatus?: string | null;
    clientEnrolledInPath?: NoYesMissing | null;
    reasonNotEnrolled?: ReasonNotEnrolled | null;
    percentAmi?: PercentAmi | null;
    referralSource?: ReferralSource | null;
    countOutreachReferralApproaches?: number | null;
    dateOfBcpStatus?: string | null;
    eligibleForRhy?: NoYesMissing | null;
    reasonNoServices?: ReasonNoServices | null;
    runawayYouth?: NoYesReasonsForMissingData | null;
    sexualOrientation?: SexualOrientation | null;
    sexualOrientationOther?: string | null;
    formerWardChildWelfare?: NoYesReasonsForMissingData | null;
    childWelfareYears?: RhyNumberofYears | null;
    childWelfareMonths?: number | null;
    formerWardJuvenileJustice?: NoYesReasonsForMissingData | null;
    juvenileJusticeYears?: RhyNumberofYears | null;
    juvenileJusticeMonths?: number | null;
    unemploymentFam?: NoYesMissing | null;
    mentalHealthDisorderFam?: NoYesMissing | null;
    physicalDisabilityFam?: NoYesMissing | null;
    alcoholDrugUseDisorderFam?: NoYesMissing | null;
    insufficientIncome?: NoYesMissing | null;
    incarceratedParent?: NoYesMissing | null;
    targetScreenReqd?: NoYesMissing | null;
    timeToHousingLoss?: TimeToHousingLoss | null;
    annualPercentAmi?: AnnualPercentAmi | null;
    literalHomelessHistory?: LiteralHomelessHistory | null;
    clientLeaseholder?: NoYesMissing | null;
    hohLeaseholder?: NoYesMissing | null;
    subsidyAtRisk?: NoYesMissing | null;
    evictionHistory?: EvictionHistory | null;
    criminalRecord?: NoYesMissing | null;
    incarceratedAdult?: IncarceratedAdult | null;
    prisonDischarge?: NoYesMissing | null;
    sexOffender?: NoYesMissing | null;
    disabledHoh?: NoYesMissing | null;
    currentPregnant?: NoYesMissing | null;
    singleParent?: NoYesMissing | null;
    dependentUnder6?: DependentUnder6 | null;
    hh5Plus?: NoYesMissing | null;
    cocPrioritized?: NoYesMissing | null;
    hpScreeningScore?: number | null;
    thresholdScore?: number | null;
    vamcStation?: VamcStationNumber | null;
    translationNeeded?: NoYesReasonsForMissingData | null;
    preferredLanguage?: PreferredLanguage | null;
    preferredLanguageDifferent?: string | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
    client: { __typename?: 'Client'; id: string };
  };
  ceAssessment?: {
    __typename?: 'CeAssessment';
    id: string;
    assessmentDate: string;
    assessmentLevel?: AssessmentLevel | null;
    assessmentLocation: string;
    assessmentType?: AssessmentType | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    dateDeleted?: string | null;
    prioritizationStatus?: PrioritizationStatus | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
  event?: {
    __typename?: 'Event';
    id: string;
    event: EventType;
    eventDate: string;
    locationCrisisOrPhHousing?: string | null;
    probSolDivRrResult?: NoYesMissing | null;
    referralCaseManageAfter?: NoYesMissing | null;
    referralResult?: ReferralResult | null;
    resultDate?: string | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    dateDeleted?: string | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
  incomeBenefit?: {
    __typename: 'IncomeBenefit';
    adap?: NoYesReasonsForMissingData | null;
    alimony?: NoYesMissing | null;
    alimonyAmount?: number | null;
    benefitsFromAnySource?: NoYesReasonsForMissingData | null;
    childSupport?: NoYesMissing | null;
    childSupportAmount?: number | null;
    cobra?: NoYesMissing | null;
    connectionWithSoar?: NoYesReasonsForMissingData | null;
    dataCollectionStage: DataCollectionStage;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    earned?: NoYesMissing | null;
    earnedAmount?: number | null;
    employerProvided?: NoYesMissing | null;
    ga?: NoYesMissing | null;
    gaAmount?: number | null;
    id: string;
    incomeFromAnySource?: NoYesReasonsForMissingData | null;
    indianHealthServices?: NoYesMissing | null;
    informationDate?: string | null;
    insuranceFromAnySource?: NoYesReasonsForMissingData | null;
    medicaid?: NoYesMissing | null;
    medicare?: NoYesMissing | null;
    noAdapReason?: NoAssistanceReason | null;
    noCobraReason?: ReasonNotInsured | null;
    noEmployerProvidedReason?: ReasonNotInsured | null;
    noIndianHealthServicesReason?: ReasonNotInsured | null;
    noMedicaidReason?: ReasonNotInsured | null;
    noMedicareReason?: ReasonNotInsured | null;
    noPrivatePayReason?: ReasonNotInsured | null;
    noRyanWhiteReason?: NoAssistanceReason | null;
    noSchipReason?: ReasonNotInsured | null;
    noStateHealthInsReason?: ReasonNotInsured | null;
    noVhaReason?: ReasonNotInsured | null;
    otherBenefitsSource?: NoYesMissing | null;
    otherBenefitsSourceIdentify?: string | null;
    otherIncomeAmount?: number | null;
    otherIncomeSource?: NoYesMissing | null;
    otherIncomeSourceIdentify?: string | null;
    otherInsurance?: NoYesMissing | null;
    otherInsuranceIdentify?: string | null;
    otherTanf?: NoYesMissing | null;
    pension?: NoYesMissing | null;
    pensionAmount?: number | null;
    privateDisability?: NoYesMissing | null;
    privateDisabilityAmount?: number | null;
    privatePay?: NoYesMissing | null;
    ryanWhiteMedDent?: NoYesReasonsForMissingData | null;
    schip?: NoYesMissing | null;
    snap?: NoYesMissing | null;
    socSecRetirement?: NoYesMissing | null;
    socSecRetirementAmount?: number | null;
    ssdi?: NoYesMissing | null;
    ssdiAmount?: number | null;
    ssi?: NoYesMissing | null;
    ssiAmount?: number | null;
    stateHealthIns?: NoYesMissing | null;
    tanf?: NoYesMissing | null;
    tanfAmount?: number | null;
    tanfChildCare?: NoYesMissing | null;
    tanfTransportation?: NoYesMissing | null;
    totalMonthlyIncome?: string | null;
    unemployment?: NoYesMissing | null;
    unemploymentAmount?: number | null;
    vaDisabilityNonService?: NoYesMissing | null;
    vaDisabilityNonServiceAmount?: number | null;
    vaDisabilityService?: NoYesMissing | null;
    vaDisabilityServiceAmount?: number | null;
    vhaServices?: NoYesMissing | null;
    wic?: NoYesMissing | null;
    workersComp?: NoYesMissing | null;
    workersCompAmount?: number | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
  } | null;
  disabilityGroup?: {
    __typename: 'DisabilityGroup';
    id: string;
    dataCollectionStage: DataCollectionStage;
    informationDate: string;
    disablingCondition: NoYesReasonsForMissingData;
    chronicHealthCondition?: NoYesReasonsForMissingData | null;
    chronicHealthConditionIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
    developmentalDisability?: NoYesReasonsForMissingData | null;
    hivAids?: NoYesReasonsForMissingData | null;
    mentalHealthDisorder?: NoYesReasonsForMissingData | null;
    mentalHealthDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
    physicalDisability?: NoYesReasonsForMissingData | null;
    physicalDisabilityIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
    substanceUseDisorder?: DisabilityResponse | null;
    substanceUseDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    tCellCountAvailable?: NoYesReasonsForMissingData | null;
    tCellCount?: number | null;
    tCellSource?: TCellSourceViralLoadSource | null;
    viralLoadAvailable?: ViralLoadAvailable | null;
    viralLoad?: number | null;
    viralLoadSource?: TCellSourceViralLoadSource | null;
    antiRetroviral?: NoYesReasonsForMissingData | null;
  } | null;
  healthAndDv?: {
    __typename: 'HealthAndDv';
    currentlyFleeing?: NoYesReasonsForMissingData | null;
    dataCollectionStage: DataCollectionStage;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    dentalHealthStatus?: HealthStatus | null;
    domesticViolenceSurvivor?: NoYesReasonsForMissingData | null;
    dueDate?: string | null;
    generalHealthStatus?: HealthStatus | null;
    id: string;
    informationDate?: string | null;
    mentalHealthStatus?: HealthStatus | null;
    pregnancyStatus?: NoYesReasonsForMissingData | null;
    whenOccurred?: WhenDvOccurred | null;
  } | null;
  exit?: {
    __typename?: 'Exit';
    id: string;
    aftercareDate?: string | null;
    aftercareProvided?: AftercareProvided | null;
    aftercareMethods?: Array<AftercareMethod> | null;
    askedOrForcedToExchangeForSex?: NoYesReasonsForMissingData | null;
    askedOrForcedToExchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
    cmExitReason?: CmExitReason | null;
    coercedToContinueWork?: NoYesReasonsForMissingData | null;
    counselingReceived?: NoYesMissing | null;
    counselingMethods?: Array<CounselingMethod> | null;
    countOfExchangeForSex?: CountExchangeForSex | null;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    destination: Destination;
    destinationSafeClient?: NoYesReasonsForMissingData | null;
    destinationSafeWorker?: WorkerResponse | null;
    destinationSubsidyType?: RentalSubsidyType | null;
    earlyExitReason?: ExpelledReason | null;
    exchangeForSex?: NoYesReasonsForMissingData | null;
    exchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
    exitDate: string;
    housingAssessment?: HousingAssessmentAtExit | null;
    laborExploitPastThreeMonths?: NoYesReasonsForMissingData | null;
    otherDestination?: string | null;
    posAdultConnections?: WorkerResponse | null;
    posCommunityConnections?: WorkerResponse | null;
    posPeerConnections?: WorkerResponse | null;
    postExitCounselingPlan?: NoYesMissing | null;
    projectCompletionStatus?: ProjectCompletionStatus | null;
    sessionCountAtExit?: number | null;
    sessionsInPlan?: number | null;
    subsidyInformation?: SubsidyInformation | null;
    workPlaceViolenceThreats?: NoYesReasonsForMissingData | null;
    workplacePromiseDifference?: NoYesReasonsForMissingData | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
  } | null;
  youthEducationStatus?: {
    __typename?: 'YouthEducationStatus';
    currentEdStatus?: CurrentEdStatus | null;
    currentSchoolAttend?: CurrentSchoolAttended | null;
    dataCollectionStage: DataCollectionStage;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    id: string;
    informationDate?: string | null;
    mostRecentEdStatus?: MostRecentEdStatus | null;
  } | null;
  employmentEducation?: {
    __typename?: 'EmploymentEducation';
    dataCollectionStage: DataCollectionStage;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    employed?: NoYesReasonsForMissingData | null;
    employmentType?: EmploymentType | null;
    id: string;
    informationDate?: string | null;
    lastGradeCompleted?: LastGradeCompleted | null;
    notEmployedReason?: NotEmployedReason | null;
    schoolStatus?: SchoolStatus | null;
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
  access: {
    __typename?: 'AssessmentAccess';
    id: string;
    canDeleteAssessments: boolean;
    canDeleteEnrollments: boolean;
    canEditEnrollments: boolean;
  };
  geolocation?: {
    __typename?: 'Geolocation';
    id: string;
    coordinates: {
      __typename?: 'GeolocationCoordinates';
      id: string;
      latitude: string;
      longitude: string;
    };
  } | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  createdBy?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  definition: {
    __typename?: 'FormDefinition';
    id: string;
    cacheKey: string;
    title: string;
  };
};

export type GetAssessmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetAssessmentQuery = {
  __typename?: 'Query';
  assessment?: {
    __typename?: 'Assessment';
    wipValues?: any | null;
    id: string;
    lockVersion: number;
    inProgress: boolean;
    assessmentDate: string;
    dataCollectionStage?: DataCollectionStage | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    dateDeleted?: string | null;
    role: AssessmentRole;
    definition: {
      __typename?: 'FormDefinition';
      id: string;
      cacheKey: string;
      title: string;
      role: FormRole;
      identifier: string;
      status: FormStatus;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      definition: {
        __typename?: 'FormDefinitionJson';
        item: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                item?: Array<{
                  __typename: 'FormItem';
                  linkId: string;
                  type: ItemType;
                  component?: Component | null;
                  prefix?: string | null;
                  text?: string | null;
                  briefText?: string | null;
                  readonlyText?: string | null;
                  helperText?: string | null;
                  required?: boolean | null;
                  warnIfEmpty?: boolean | null;
                  hidden?: boolean | null;
                  readOnly?: boolean | null;
                  repeats?: boolean | null;
                  pickListReference?: string | null;
                  size?: InputSize | null;
                  assessmentDate?: boolean | null;
                  prefill?: boolean | null;
                  dataCollectedAbout?: DataCollectedAbout | null;
                  disabledDisplay?: DisabledDisplay | null;
                  enableBehavior?: EnableBehavior | null;
                  rule?: any | null;
                  customRule?: any | null;
                  editorUserIds?: Array<string> | null;
                  mapping?: {
                    __typename?: 'FieldMapping';
                    recordType?: RelatedRecordType | null;
                    fieldName?: string | null;
                    customFieldKey?: string | null;
                  } | null;
                  bounds?: Array<{
                    __typename?: 'ValueBound';
                    id: string;
                    severity: ValidationSeverity;
                    type: BoundType;
                    question?: string | null;
                    valueNumber?: number | null;
                    valueDate?: string | null;
                    valueLocalConstant?: string | null;
                    offset?: number | null;
                  }> | null;
                  pickListOptions?: Array<{
                    __typename?: 'PickListOption';
                    code: string;
                    label?: string | null;
                    secondaryLabel?: string | null;
                    groupLabel?: string | null;
                    groupCode?: string | null;
                    initialSelected?: boolean | null;
                    helperText?: string | null;
                    numericValue?: number | null;
                  }> | null;
                  initial?: Array<{
                    __typename?: 'InitialValue';
                    valueCode?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    valueLocalConstant?: string | null;
                    initialBehavior: InitialBehavior;
                  }> | null;
                  enableWhen?: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }> | null;
                  autofillValues?: Array<{
                    __typename?: 'AutofillValue';
                    valueCode?: string | null;
                    valueQuestion?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    sumQuestions?: Array<string> | null;
                    formula?: string | null;
                    autofillBehavior: EnableBehavior;
                    autofillReadonly?: boolean | null;
                    autofillWhen: Array<{
                      __typename?: 'EnableWhen';
                      question?: string | null;
                      localConstant?: string | null;
                      operator: EnableOperator;
                      answerCode?: string | null;
                      answerCodes?: Array<string> | null;
                      answerNumber?: number | null;
                      answerBoolean?: boolean | null;
                      answerGroupCode?: string | null;
                      answerDate?: string | null;
                      compareQuestion?: string | null;
                    }>;
                  }> | null;
                }> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }>;
      };
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    };
    upgradedDefinitionForEditing?: {
      __typename?: 'FormDefinition';
      id: string;
      role: FormRole;
      title: string;
      cacheKey: string;
      identifier: string;
      status: FormStatus;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      definition: {
        __typename?: 'FormDefinitionJson';
        item: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                item?: Array<{
                  __typename: 'FormItem';
                  linkId: string;
                  type: ItemType;
                  component?: Component | null;
                  prefix?: string | null;
                  text?: string | null;
                  briefText?: string | null;
                  readonlyText?: string | null;
                  helperText?: string | null;
                  required?: boolean | null;
                  warnIfEmpty?: boolean | null;
                  hidden?: boolean | null;
                  readOnly?: boolean | null;
                  repeats?: boolean | null;
                  pickListReference?: string | null;
                  size?: InputSize | null;
                  assessmentDate?: boolean | null;
                  prefill?: boolean | null;
                  dataCollectedAbout?: DataCollectedAbout | null;
                  disabledDisplay?: DisabledDisplay | null;
                  enableBehavior?: EnableBehavior | null;
                  rule?: any | null;
                  customRule?: any | null;
                  editorUserIds?: Array<string> | null;
                  mapping?: {
                    __typename?: 'FieldMapping';
                    recordType?: RelatedRecordType | null;
                    fieldName?: string | null;
                    customFieldKey?: string | null;
                  } | null;
                  bounds?: Array<{
                    __typename?: 'ValueBound';
                    id: string;
                    severity: ValidationSeverity;
                    type: BoundType;
                    question?: string | null;
                    valueNumber?: number | null;
                    valueDate?: string | null;
                    valueLocalConstant?: string | null;
                    offset?: number | null;
                  }> | null;
                  pickListOptions?: Array<{
                    __typename?: 'PickListOption';
                    code: string;
                    label?: string | null;
                    secondaryLabel?: string | null;
                    groupLabel?: string | null;
                    groupCode?: string | null;
                    initialSelected?: boolean | null;
                    helperText?: string | null;
                    numericValue?: number | null;
                  }> | null;
                  initial?: Array<{
                    __typename?: 'InitialValue';
                    valueCode?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    valueLocalConstant?: string | null;
                    initialBehavior: InitialBehavior;
                  }> | null;
                  enableWhen?: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }> | null;
                  autofillValues?: Array<{
                    __typename?: 'AutofillValue';
                    valueCode?: string | null;
                    valueQuestion?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    sumQuestions?: Array<string> | null;
                    formula?: string | null;
                    autofillBehavior: EnableBehavior;
                    autofillReadonly?: boolean | null;
                    autofillWhen: Array<{
                      __typename?: 'EnableWhen';
                      question?: string | null;
                      localConstant?: string | null;
                      operator: EnableOperator;
                      answerCode?: string | null;
                      answerCodes?: Array<string> | null;
                      answerNumber?: number | null;
                      answerBoolean?: boolean | null;
                      answerGroupCode?: string | null;
                      answerDate?: string | null;
                      compareQuestion?: string | null;
                    }>;
                  }> | null;
                }> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }>;
      };
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    } | null;
    enrollment: {
      __typename?: 'Enrollment';
      id: string;
      lockVersion: number;
      entryDate: string;
      exitDate?: string | null;
      disablingCondition?: NoYesReasonsForMissingData | null;
      dateOfEngagement?: string | null;
      moveInDate?: string | null;
      livingSituation?: PriorLivingSituation | null;
      rentalSubsidyType?: RentalSubsidyType | null;
      lengthOfStay?: ResidencePriorLengthOfStay | null;
      losUnderThreshold?: NoYesMissing | null;
      previousStreetEssh?: NoYesMissing | null;
      dateToStreetEssh?: string | null;
      timesHomelessPastThreeYears?: TimesHomelessPastThreeYears | null;
      monthsHomelessPastThreeYears?: MonthsHomelessPastThreeYears | null;
      enrollmentCoc?: string | null;
      dateOfPathStatus?: string | null;
      clientEnrolledInPath?: NoYesMissing | null;
      reasonNotEnrolled?: ReasonNotEnrolled | null;
      percentAmi?: PercentAmi | null;
      referralSource?: ReferralSource | null;
      countOutreachReferralApproaches?: number | null;
      dateOfBcpStatus?: string | null;
      eligibleForRhy?: NoYesMissing | null;
      reasonNoServices?: ReasonNoServices | null;
      runawayYouth?: NoYesReasonsForMissingData | null;
      sexualOrientation?: SexualOrientation | null;
      sexualOrientationOther?: string | null;
      formerWardChildWelfare?: NoYesReasonsForMissingData | null;
      childWelfareYears?: RhyNumberofYears | null;
      childWelfareMonths?: number | null;
      formerWardJuvenileJustice?: NoYesReasonsForMissingData | null;
      juvenileJusticeYears?: RhyNumberofYears | null;
      juvenileJusticeMonths?: number | null;
      unemploymentFam?: NoYesMissing | null;
      mentalHealthDisorderFam?: NoYesMissing | null;
      physicalDisabilityFam?: NoYesMissing | null;
      alcoholDrugUseDisorderFam?: NoYesMissing | null;
      insufficientIncome?: NoYesMissing | null;
      incarceratedParent?: NoYesMissing | null;
      targetScreenReqd?: NoYesMissing | null;
      timeToHousingLoss?: TimeToHousingLoss | null;
      annualPercentAmi?: AnnualPercentAmi | null;
      literalHomelessHistory?: LiteralHomelessHistory | null;
      clientLeaseholder?: NoYesMissing | null;
      hohLeaseholder?: NoYesMissing | null;
      subsidyAtRisk?: NoYesMissing | null;
      evictionHistory?: EvictionHistory | null;
      criminalRecord?: NoYesMissing | null;
      incarceratedAdult?: IncarceratedAdult | null;
      prisonDischarge?: NoYesMissing | null;
      sexOffender?: NoYesMissing | null;
      disabledHoh?: NoYesMissing | null;
      currentPregnant?: NoYesMissing | null;
      singleParent?: NoYesMissing | null;
      dependentUnder6?: DependentUnder6 | null;
      hh5Plus?: NoYesMissing | null;
      cocPrioritized?: NoYesMissing | null;
      hpScreeningScore?: number | null;
      thresholdScore?: number | null;
      vamcStation?: VamcStationNumber | null;
      translationNeeded?: NoYesReasonsForMissingData | null;
      preferredLanguage?: PreferredLanguage | null;
      preferredLanguageDifferent?: string | null;
      customDataElements: Array<{
        __typename?: 'CustomDataElement';
        id: string;
        key: string;
        label: string;
        fieldType: CustomDataElementType;
        repeats: boolean;
        displayHooks: Array<DisplayHook>;
        value?: {
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        values?: Array<{
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }> | null;
      }>;
      client: { __typename?: 'Client'; id: string };
    };
    ceAssessment?: {
      __typename?: 'CeAssessment';
      id: string;
      assessmentDate: string;
      assessmentLevel?: AssessmentLevel | null;
      assessmentLocation: string;
      assessmentType?: AssessmentType | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      dateDeleted?: string | null;
      prioritizationStatus?: PrioritizationStatus | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    event?: {
      __typename?: 'Event';
      id: string;
      event: EventType;
      eventDate: string;
      locationCrisisOrPhHousing?: string | null;
      probSolDivRrResult?: NoYesMissing | null;
      referralCaseManageAfter?: NoYesMissing | null;
      referralResult?: ReferralResult | null;
      resultDate?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      dateDeleted?: string | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    incomeBenefit?: {
      __typename: 'IncomeBenefit';
      adap?: NoYesReasonsForMissingData | null;
      alimony?: NoYesMissing | null;
      alimonyAmount?: number | null;
      benefitsFromAnySource?: NoYesReasonsForMissingData | null;
      childSupport?: NoYesMissing | null;
      childSupportAmount?: number | null;
      cobra?: NoYesMissing | null;
      connectionWithSoar?: NoYesReasonsForMissingData | null;
      dataCollectionStage: DataCollectionStage;
      dateCreated?: string | null;
      dateDeleted?: string | null;
      dateUpdated?: string | null;
      earned?: NoYesMissing | null;
      earnedAmount?: number | null;
      employerProvided?: NoYesMissing | null;
      ga?: NoYesMissing | null;
      gaAmount?: number | null;
      id: string;
      incomeFromAnySource?: NoYesReasonsForMissingData | null;
      indianHealthServices?: NoYesMissing | null;
      informationDate?: string | null;
      insuranceFromAnySource?: NoYesReasonsForMissingData | null;
      medicaid?: NoYesMissing | null;
      medicare?: NoYesMissing | null;
      noAdapReason?: NoAssistanceReason | null;
      noCobraReason?: ReasonNotInsured | null;
      noEmployerProvidedReason?: ReasonNotInsured | null;
      noIndianHealthServicesReason?: ReasonNotInsured | null;
      noMedicaidReason?: ReasonNotInsured | null;
      noMedicareReason?: ReasonNotInsured | null;
      noPrivatePayReason?: ReasonNotInsured | null;
      noRyanWhiteReason?: NoAssistanceReason | null;
      noSchipReason?: ReasonNotInsured | null;
      noStateHealthInsReason?: ReasonNotInsured | null;
      noVhaReason?: ReasonNotInsured | null;
      otherBenefitsSource?: NoYesMissing | null;
      otherBenefitsSourceIdentify?: string | null;
      otherIncomeAmount?: number | null;
      otherIncomeSource?: NoYesMissing | null;
      otherIncomeSourceIdentify?: string | null;
      otherInsurance?: NoYesMissing | null;
      otherInsuranceIdentify?: string | null;
      otherTanf?: NoYesMissing | null;
      pension?: NoYesMissing | null;
      pensionAmount?: number | null;
      privateDisability?: NoYesMissing | null;
      privateDisabilityAmount?: number | null;
      privatePay?: NoYesMissing | null;
      ryanWhiteMedDent?: NoYesReasonsForMissingData | null;
      schip?: NoYesMissing | null;
      snap?: NoYesMissing | null;
      socSecRetirement?: NoYesMissing | null;
      socSecRetirementAmount?: number | null;
      ssdi?: NoYesMissing | null;
      ssdiAmount?: number | null;
      ssi?: NoYesMissing | null;
      ssiAmount?: number | null;
      stateHealthIns?: NoYesMissing | null;
      tanf?: NoYesMissing | null;
      tanfAmount?: number | null;
      tanfChildCare?: NoYesMissing | null;
      tanfTransportation?: NoYesMissing | null;
      totalMonthlyIncome?: string | null;
      unemployment?: NoYesMissing | null;
      unemploymentAmount?: number | null;
      vaDisabilityNonService?: NoYesMissing | null;
      vaDisabilityNonServiceAmount?: number | null;
      vaDisabilityService?: NoYesMissing | null;
      vaDisabilityServiceAmount?: number | null;
      vhaServices?: NoYesMissing | null;
      wic?: NoYesMissing | null;
      workersComp?: NoYesMissing | null;
      workersCompAmount?: number | null;
      customDataElements: Array<{
        __typename?: 'CustomDataElement';
        id: string;
        key: string;
        label: string;
        fieldType: CustomDataElementType;
        repeats: boolean;
        displayHooks: Array<DisplayHook>;
        value?: {
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        values?: Array<{
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }> | null;
      }>;
    } | null;
    disabilityGroup?: {
      __typename: 'DisabilityGroup';
      id: string;
      dataCollectionStage: DataCollectionStage;
      informationDate: string;
      disablingCondition: NoYesReasonsForMissingData;
      chronicHealthCondition?: NoYesReasonsForMissingData | null;
      chronicHealthConditionIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
      developmentalDisability?: NoYesReasonsForMissingData | null;
      hivAids?: NoYesReasonsForMissingData | null;
      mentalHealthDisorder?: NoYesReasonsForMissingData | null;
      mentalHealthDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
      physicalDisability?: NoYesReasonsForMissingData | null;
      physicalDisabilityIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
      substanceUseDisorder?: DisabilityResponse | null;
      substanceUseDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      tCellCountAvailable?: NoYesReasonsForMissingData | null;
      tCellCount?: number | null;
      tCellSource?: TCellSourceViralLoadSource | null;
      viralLoadAvailable?: ViralLoadAvailable | null;
      viralLoad?: number | null;
      viralLoadSource?: TCellSourceViralLoadSource | null;
      antiRetroviral?: NoYesReasonsForMissingData | null;
    } | null;
    healthAndDv?: {
      __typename: 'HealthAndDv';
      currentlyFleeing?: NoYesReasonsForMissingData | null;
      dataCollectionStage: DataCollectionStage;
      dateCreated?: string | null;
      dateDeleted?: string | null;
      dateUpdated?: string | null;
      dentalHealthStatus?: HealthStatus | null;
      domesticViolenceSurvivor?: NoYesReasonsForMissingData | null;
      dueDate?: string | null;
      generalHealthStatus?: HealthStatus | null;
      id: string;
      informationDate?: string | null;
      mentalHealthStatus?: HealthStatus | null;
      pregnancyStatus?: NoYesReasonsForMissingData | null;
      whenOccurred?: WhenDvOccurred | null;
    } | null;
    exit?: {
      __typename?: 'Exit';
      id: string;
      aftercareDate?: string | null;
      aftercareProvided?: AftercareProvided | null;
      aftercareMethods?: Array<AftercareMethod> | null;
      askedOrForcedToExchangeForSex?: NoYesReasonsForMissingData | null;
      askedOrForcedToExchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
      cmExitReason?: CmExitReason | null;
      coercedToContinueWork?: NoYesReasonsForMissingData | null;
      counselingReceived?: NoYesMissing | null;
      counselingMethods?: Array<CounselingMethod> | null;
      countOfExchangeForSex?: CountExchangeForSex | null;
      dateCreated?: string | null;
      dateDeleted?: string | null;
      dateUpdated?: string | null;
      destination: Destination;
      destinationSafeClient?: NoYesReasonsForMissingData | null;
      destinationSafeWorker?: WorkerResponse | null;
      destinationSubsidyType?: RentalSubsidyType | null;
      earlyExitReason?: ExpelledReason | null;
      exchangeForSex?: NoYesReasonsForMissingData | null;
      exchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
      exitDate: string;
      housingAssessment?: HousingAssessmentAtExit | null;
      laborExploitPastThreeMonths?: NoYesReasonsForMissingData | null;
      otherDestination?: string | null;
      posAdultConnections?: WorkerResponse | null;
      posCommunityConnections?: WorkerResponse | null;
      posPeerConnections?: WorkerResponse | null;
      postExitCounselingPlan?: NoYesMissing | null;
      projectCompletionStatus?: ProjectCompletionStatus | null;
      sessionCountAtExit?: number | null;
      sessionsInPlan?: number | null;
      subsidyInformation?: SubsidyInformation | null;
      workPlaceViolenceThreats?: NoYesReasonsForMissingData | null;
      workplacePromiseDifference?: NoYesReasonsForMissingData | null;
      customDataElements: Array<{
        __typename?: 'CustomDataElement';
        id: string;
        key: string;
        label: string;
        fieldType: CustomDataElementType;
        repeats: boolean;
        displayHooks: Array<DisplayHook>;
        value?: {
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        values?: Array<{
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }> | null;
      }>;
    } | null;
    youthEducationStatus?: {
      __typename?: 'YouthEducationStatus';
      currentEdStatus?: CurrentEdStatus | null;
      currentSchoolAttend?: CurrentSchoolAttended | null;
      dataCollectionStage: DataCollectionStage;
      dateCreated?: string | null;
      dateDeleted?: string | null;
      dateUpdated?: string | null;
      id: string;
      informationDate?: string | null;
      mostRecentEdStatus?: MostRecentEdStatus | null;
    } | null;
    employmentEducation?: {
      __typename?: 'EmploymentEducation';
      dataCollectionStage: DataCollectionStage;
      dateCreated?: string | null;
      dateDeleted?: string | null;
      employed?: NoYesReasonsForMissingData | null;
      employmentType?: EmploymentType | null;
      id: string;
      informationDate?: string | null;
      lastGradeCompleted?: LastGradeCompleted | null;
      notEmployedReason?: NotEmployedReason | null;
      schoolStatus?: SchoolStatus | null;
    } | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
    access: {
      __typename?: 'AssessmentAccess';
      id: string;
      canDeleteAssessments: boolean;
      canDeleteEnrollments: boolean;
      canEditEnrollments: boolean;
    };
    geolocation?: {
      __typename?: 'Geolocation';
      id: string;
      coordinates: {
        __typename?: 'GeolocationCoordinates';
        id: string;
        latitude: string;
        longitude: string;
      };
    } | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
    createdBy?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
};

export type GetClientAssessmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<AssessmentSortOption>;
  filters?: InputMaybe<AssessmentFilterOptions>;
}>;

export type GetClientAssessmentsQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    assessments: {
      __typename?: 'AssessmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Assessment';
        id: string;
        lockVersion: number;
        inProgress: boolean;
        assessmentDate: string;
        dataCollectionStage?: DataCollectionStage | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        role: AssessmentRole;
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          entryDate: string;
          exitDate?: string | null;
          autoExited: boolean;
          moveInDate?: string | null;
          lastBedNightDate?: string | null;
          projectName: string;
          organizationName: string;
          projectType?: ProjectType | null;
          inProgress: boolean;
          relationshipToHoH: RelationshipToHoH;
          access: {
            __typename?: 'EnrollmentAccess';
            id: string;
            canViewEnrollmentDetails: boolean;
          };
        };
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        definition: {
          __typename?: 'FormDefinition';
          id: string;
          cacheKey: string;
          title: string;
        };
      }>;
    };
  } | null;
};

export type GetEnrollmentAssessmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder?: InputMaybe<AssessmentSortOption>;
  filters?: InputMaybe<AssessmentsForEnrollmentFilterOptions>;
}>;

export type GetEnrollmentAssessmentsQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    assessments: {
      __typename?: 'AssessmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Assessment';
        id: string;
        lockVersion: number;
        inProgress: boolean;
        assessmentDate: string;
        dataCollectionStage?: DataCollectionStage | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        role: AssessmentRole;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        definition: {
          __typename?: 'FormDefinition';
          id: string;
          cacheKey: string;
          title: string;
        };
      }>;
    };
  } | null;
};

export type GetHouseholdAssessmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  inProgress?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder?: InputMaybe<AssessmentSortOption>;
  filters?: InputMaybe<AssessmentsForHouseholdFilterOptions>;
}>;

export type GetHouseholdAssessmentsQuery = {
  __typename?: 'Query';
  household?: {
    __typename?: 'Household';
    id: string;
    assessments: {
      __typename?: 'AssessmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Assessment';
        id: string;
        lockVersion: number;
        inProgress: boolean;
        assessmentDate: string;
        dataCollectionStage?: DataCollectionStage | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        role: AssessmentRole;
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          relationshipToHoH: RelationshipToHoH;
          client: {
            __typename?: 'Client';
            id: string;
            lockVersion: number;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            nameSuffix?: string | null;
          };
        };
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        definition: {
          __typename?: 'FormDefinition';
          id: string;
          cacheKey: string;
          title: string;
        };
      }>;
    };
  } | null;
};

export type GetRelatedAnnualsQueryVariables = Exact<{
  householdId: Scalars['ID']['input'];
  assessmentId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetRelatedAnnualsQuery = {
  __typename?: 'Query';
  householdAssessments?: Array<{
    __typename?: 'Assessment';
    id: string;
    assessmentDate: string;
    enrollment: {
      __typename?: 'Enrollment';
      id: string;
      relationshipToHoH: RelationshipToHoH;
    };
    client: {
      __typename?: 'Client';
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
    };
  }> | null;
};

export type SaveAssessmentMutationVariables = Exact<{
  input: SaveAssessmentInput;
}>;

export type SaveAssessmentMutation = {
  __typename?: 'Mutation';
  saveAssessment?: {
    __typename?: 'SaveAssessmentPayload';
    assessment?: {
      __typename?: 'Assessment';
      wipValues?: any | null;
      id: string;
      lockVersion: number;
      inProgress: boolean;
      assessmentDate: string;
      dataCollectionStage?: DataCollectionStage | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      dateDeleted?: string | null;
      role: AssessmentRole;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      definition: {
        __typename?: 'FormDefinition';
        id: string;
        cacheKey: string;
        title: string;
      };
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type SubmitAssessmentMutationVariables = Exact<{
  input: SubmitAssessmentInput;
}>;

export type SubmitAssessmentMutation = {
  __typename?: 'Mutation';
  submitAssessment?: {
    __typename?: 'SubmitAssessmentPayload';
    assessment?: {
      __typename?: 'Assessment';
      id: string;
      lockVersion: number;
      inProgress: boolean;
      assessmentDate: string;
      dataCollectionStage?: DataCollectionStage | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      dateDeleted?: string | null;
      role: AssessmentRole;
      enrollment: {
        __typename?: 'Enrollment';
        id: string;
        lockVersion: number;
        entryDate: string;
        exitDate?: string | null;
        disablingCondition?: NoYesReasonsForMissingData | null;
        dateOfEngagement?: string | null;
        moveInDate?: string | null;
        livingSituation?: PriorLivingSituation | null;
        rentalSubsidyType?: RentalSubsidyType | null;
        lengthOfStay?: ResidencePriorLengthOfStay | null;
        losUnderThreshold?: NoYesMissing | null;
        previousStreetEssh?: NoYesMissing | null;
        dateToStreetEssh?: string | null;
        timesHomelessPastThreeYears?: TimesHomelessPastThreeYears | null;
        monthsHomelessPastThreeYears?: MonthsHomelessPastThreeYears | null;
        enrollmentCoc?: string | null;
        dateOfPathStatus?: string | null;
        clientEnrolledInPath?: NoYesMissing | null;
        reasonNotEnrolled?: ReasonNotEnrolled | null;
        percentAmi?: PercentAmi | null;
        referralSource?: ReferralSource | null;
        countOutreachReferralApproaches?: number | null;
        dateOfBcpStatus?: string | null;
        eligibleForRhy?: NoYesMissing | null;
        reasonNoServices?: ReasonNoServices | null;
        runawayYouth?: NoYesReasonsForMissingData | null;
        sexualOrientation?: SexualOrientation | null;
        sexualOrientationOther?: string | null;
        formerWardChildWelfare?: NoYesReasonsForMissingData | null;
        childWelfareYears?: RhyNumberofYears | null;
        childWelfareMonths?: number | null;
        formerWardJuvenileJustice?: NoYesReasonsForMissingData | null;
        juvenileJusticeYears?: RhyNumberofYears | null;
        juvenileJusticeMonths?: number | null;
        unemploymentFam?: NoYesMissing | null;
        mentalHealthDisorderFam?: NoYesMissing | null;
        physicalDisabilityFam?: NoYesMissing | null;
        alcoholDrugUseDisorderFam?: NoYesMissing | null;
        insufficientIncome?: NoYesMissing | null;
        incarceratedParent?: NoYesMissing | null;
        targetScreenReqd?: NoYesMissing | null;
        timeToHousingLoss?: TimeToHousingLoss | null;
        annualPercentAmi?: AnnualPercentAmi | null;
        literalHomelessHistory?: LiteralHomelessHistory | null;
        clientLeaseholder?: NoYesMissing | null;
        hohLeaseholder?: NoYesMissing | null;
        subsidyAtRisk?: NoYesMissing | null;
        evictionHistory?: EvictionHistory | null;
        criminalRecord?: NoYesMissing | null;
        incarceratedAdult?: IncarceratedAdult | null;
        prisonDischarge?: NoYesMissing | null;
        sexOffender?: NoYesMissing | null;
        disabledHoh?: NoYesMissing | null;
        currentPregnant?: NoYesMissing | null;
        singleParent?: NoYesMissing | null;
        dependentUnder6?: DependentUnder6 | null;
        hh5Plus?: NoYesMissing | null;
        cocPrioritized?: NoYesMissing | null;
        hpScreeningScore?: number | null;
        thresholdScore?: number | null;
        vamcStation?: VamcStationNumber | null;
        translationNeeded?: NoYesReasonsForMissingData | null;
        preferredLanguage?: PreferredLanguage | null;
        preferredLanguageDifferent?: string | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
        client: { __typename?: 'Client'; id: string };
      };
      ceAssessment?: {
        __typename?: 'CeAssessment';
        id: string;
        assessmentDate: string;
        assessmentLevel?: AssessmentLevel | null;
        assessmentLocation: string;
        assessmentType?: AssessmentType | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        prioritizationStatus?: PrioritizationStatus | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      event?: {
        __typename?: 'Event';
        id: string;
        event: EventType;
        eventDate: string;
        locationCrisisOrPhHousing?: string | null;
        probSolDivRrResult?: NoYesMissing | null;
        referralCaseManageAfter?: NoYesMissing | null;
        referralResult?: ReferralResult | null;
        resultDate?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      incomeBenefit?: {
        __typename: 'IncomeBenefit';
        adap?: NoYesReasonsForMissingData | null;
        alimony?: NoYesMissing | null;
        alimonyAmount?: number | null;
        benefitsFromAnySource?: NoYesReasonsForMissingData | null;
        childSupport?: NoYesMissing | null;
        childSupportAmount?: number | null;
        cobra?: NoYesMissing | null;
        connectionWithSoar?: NoYesReasonsForMissingData | null;
        dataCollectionStage: DataCollectionStage;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        earned?: NoYesMissing | null;
        earnedAmount?: number | null;
        employerProvided?: NoYesMissing | null;
        ga?: NoYesMissing | null;
        gaAmount?: number | null;
        id: string;
        incomeFromAnySource?: NoYesReasonsForMissingData | null;
        indianHealthServices?: NoYesMissing | null;
        informationDate?: string | null;
        insuranceFromAnySource?: NoYesReasonsForMissingData | null;
        medicaid?: NoYesMissing | null;
        medicare?: NoYesMissing | null;
        noAdapReason?: NoAssistanceReason | null;
        noCobraReason?: ReasonNotInsured | null;
        noEmployerProvidedReason?: ReasonNotInsured | null;
        noIndianHealthServicesReason?: ReasonNotInsured | null;
        noMedicaidReason?: ReasonNotInsured | null;
        noMedicareReason?: ReasonNotInsured | null;
        noPrivatePayReason?: ReasonNotInsured | null;
        noRyanWhiteReason?: NoAssistanceReason | null;
        noSchipReason?: ReasonNotInsured | null;
        noStateHealthInsReason?: ReasonNotInsured | null;
        noVhaReason?: ReasonNotInsured | null;
        otherBenefitsSource?: NoYesMissing | null;
        otherBenefitsSourceIdentify?: string | null;
        otherIncomeAmount?: number | null;
        otherIncomeSource?: NoYesMissing | null;
        otherIncomeSourceIdentify?: string | null;
        otherInsurance?: NoYesMissing | null;
        otherInsuranceIdentify?: string | null;
        otherTanf?: NoYesMissing | null;
        pension?: NoYesMissing | null;
        pensionAmount?: number | null;
        privateDisability?: NoYesMissing | null;
        privateDisabilityAmount?: number | null;
        privatePay?: NoYesMissing | null;
        ryanWhiteMedDent?: NoYesReasonsForMissingData | null;
        schip?: NoYesMissing | null;
        snap?: NoYesMissing | null;
        socSecRetirement?: NoYesMissing | null;
        socSecRetirementAmount?: number | null;
        ssdi?: NoYesMissing | null;
        ssdiAmount?: number | null;
        ssi?: NoYesMissing | null;
        ssiAmount?: number | null;
        stateHealthIns?: NoYesMissing | null;
        tanf?: NoYesMissing | null;
        tanfAmount?: number | null;
        tanfChildCare?: NoYesMissing | null;
        tanfTransportation?: NoYesMissing | null;
        totalMonthlyIncome?: string | null;
        unemployment?: NoYesMissing | null;
        unemploymentAmount?: number | null;
        vaDisabilityNonService?: NoYesMissing | null;
        vaDisabilityNonServiceAmount?: number | null;
        vaDisabilityService?: NoYesMissing | null;
        vaDisabilityServiceAmount?: number | null;
        vhaServices?: NoYesMissing | null;
        wic?: NoYesMissing | null;
        workersComp?: NoYesMissing | null;
        workersCompAmount?: number | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
      } | null;
      disabilityGroup?: {
        __typename: 'DisabilityGroup';
        id: string;
        dataCollectionStage: DataCollectionStage;
        informationDate: string;
        disablingCondition: NoYesReasonsForMissingData;
        chronicHealthCondition?: NoYesReasonsForMissingData | null;
        chronicHealthConditionIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
        developmentalDisability?: NoYesReasonsForMissingData | null;
        hivAids?: NoYesReasonsForMissingData | null;
        mentalHealthDisorder?: NoYesReasonsForMissingData | null;
        mentalHealthDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
        physicalDisability?: NoYesReasonsForMissingData | null;
        physicalDisabilityIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
        substanceUseDisorder?: DisabilityResponse | null;
        substanceUseDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        tCellCountAvailable?: NoYesReasonsForMissingData | null;
        tCellCount?: number | null;
        tCellSource?: TCellSourceViralLoadSource | null;
        viralLoadAvailable?: ViralLoadAvailable | null;
        viralLoad?: number | null;
        viralLoadSource?: TCellSourceViralLoadSource | null;
        antiRetroviral?: NoYesReasonsForMissingData | null;
      } | null;
      healthAndDv?: {
        __typename: 'HealthAndDv';
        currentlyFleeing?: NoYesReasonsForMissingData | null;
        dataCollectionStage: DataCollectionStage;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        dentalHealthStatus?: HealthStatus | null;
        domesticViolenceSurvivor?: NoYesReasonsForMissingData | null;
        dueDate?: string | null;
        generalHealthStatus?: HealthStatus | null;
        id: string;
        informationDate?: string | null;
        mentalHealthStatus?: HealthStatus | null;
        pregnancyStatus?: NoYesReasonsForMissingData | null;
        whenOccurred?: WhenDvOccurred | null;
      } | null;
      exit?: {
        __typename?: 'Exit';
        id: string;
        aftercareDate?: string | null;
        aftercareProvided?: AftercareProvided | null;
        aftercareMethods?: Array<AftercareMethod> | null;
        askedOrForcedToExchangeForSex?: NoYesReasonsForMissingData | null;
        askedOrForcedToExchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
        cmExitReason?: CmExitReason | null;
        coercedToContinueWork?: NoYesReasonsForMissingData | null;
        counselingReceived?: NoYesMissing | null;
        counselingMethods?: Array<CounselingMethod> | null;
        countOfExchangeForSex?: CountExchangeForSex | null;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        destination: Destination;
        destinationSafeClient?: NoYesReasonsForMissingData | null;
        destinationSafeWorker?: WorkerResponse | null;
        destinationSubsidyType?: RentalSubsidyType | null;
        earlyExitReason?: ExpelledReason | null;
        exchangeForSex?: NoYesReasonsForMissingData | null;
        exchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
        exitDate: string;
        housingAssessment?: HousingAssessmentAtExit | null;
        laborExploitPastThreeMonths?: NoYesReasonsForMissingData | null;
        otherDestination?: string | null;
        posAdultConnections?: WorkerResponse | null;
        posCommunityConnections?: WorkerResponse | null;
        posPeerConnections?: WorkerResponse | null;
        postExitCounselingPlan?: NoYesMissing | null;
        projectCompletionStatus?: ProjectCompletionStatus | null;
        sessionCountAtExit?: number | null;
        sessionsInPlan?: number | null;
        subsidyInformation?: SubsidyInformation | null;
        workPlaceViolenceThreats?: NoYesReasonsForMissingData | null;
        workplacePromiseDifference?: NoYesReasonsForMissingData | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
      } | null;
      youthEducationStatus?: {
        __typename?: 'YouthEducationStatus';
        currentEdStatus?: CurrentEdStatus | null;
        currentSchoolAttend?: CurrentSchoolAttended | null;
        dataCollectionStage: DataCollectionStage;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        id: string;
        informationDate?: string | null;
        mostRecentEdStatus?: MostRecentEdStatus | null;
      } | null;
      employmentEducation?: {
        __typename?: 'EmploymentEducation';
        dataCollectionStage: DataCollectionStage;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        employed?: NoYesReasonsForMissingData | null;
        employmentType?: EmploymentType | null;
        id: string;
        informationDate?: string | null;
        lastGradeCompleted?: LastGradeCompleted | null;
        notEmployedReason?: NotEmployedReason | null;
        schoolStatus?: SchoolStatus | null;
      } | null;
      customDataElements: Array<{
        __typename?: 'CustomDataElement';
        id: string;
        key: string;
        label: string;
        fieldType: CustomDataElementType;
        repeats: boolean;
        displayHooks: Array<DisplayHook>;
        value?: {
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        values?: Array<{
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }> | null;
      }>;
      access: {
        __typename?: 'AssessmentAccess';
        id: string;
        canDeleteAssessments: boolean;
        canDeleteEnrollments: boolean;
        canEditEnrollments: boolean;
      };
      geolocation?: {
        __typename?: 'Geolocation';
        id: string;
        coordinates: {
          __typename?: 'GeolocationCoordinates';
          id: string;
          latitude: string;
          longitude: string;
        };
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      definition: {
        __typename?: 'FormDefinition';
        id: string;
        cacheKey: string;
        title: string;
      };
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type SubmitHouseholdAssessmentsMutationVariables = Exact<{
  input: SubmitHouseholdAssessmentsInput;
}>;

export type SubmitHouseholdAssessmentsMutation = {
  __typename?: 'Mutation';
  submitHouseholdAssessments?: {
    __typename?: 'SubmitHouseholdAssessmentsPayload';
    assessments?: Array<{
      __typename?: 'Assessment';
      id: string;
      lockVersion: number;
      inProgress: boolean;
      assessmentDate: string;
      dataCollectionStage?: DataCollectionStage | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      dateDeleted?: string | null;
      role: AssessmentRole;
      enrollment: {
        __typename?: 'Enrollment';
        id: string;
        lockVersion: number;
        entryDate: string;
        exitDate?: string | null;
        disablingCondition?: NoYesReasonsForMissingData | null;
        dateOfEngagement?: string | null;
        moveInDate?: string | null;
        livingSituation?: PriorLivingSituation | null;
        rentalSubsidyType?: RentalSubsidyType | null;
        lengthOfStay?: ResidencePriorLengthOfStay | null;
        losUnderThreshold?: NoYesMissing | null;
        previousStreetEssh?: NoYesMissing | null;
        dateToStreetEssh?: string | null;
        timesHomelessPastThreeYears?: TimesHomelessPastThreeYears | null;
        monthsHomelessPastThreeYears?: MonthsHomelessPastThreeYears | null;
        enrollmentCoc?: string | null;
        dateOfPathStatus?: string | null;
        clientEnrolledInPath?: NoYesMissing | null;
        reasonNotEnrolled?: ReasonNotEnrolled | null;
        percentAmi?: PercentAmi | null;
        referralSource?: ReferralSource | null;
        countOutreachReferralApproaches?: number | null;
        dateOfBcpStatus?: string | null;
        eligibleForRhy?: NoYesMissing | null;
        reasonNoServices?: ReasonNoServices | null;
        runawayYouth?: NoYesReasonsForMissingData | null;
        sexualOrientation?: SexualOrientation | null;
        sexualOrientationOther?: string | null;
        formerWardChildWelfare?: NoYesReasonsForMissingData | null;
        childWelfareYears?: RhyNumberofYears | null;
        childWelfareMonths?: number | null;
        formerWardJuvenileJustice?: NoYesReasonsForMissingData | null;
        juvenileJusticeYears?: RhyNumberofYears | null;
        juvenileJusticeMonths?: number | null;
        unemploymentFam?: NoYesMissing | null;
        mentalHealthDisorderFam?: NoYesMissing | null;
        physicalDisabilityFam?: NoYesMissing | null;
        alcoholDrugUseDisorderFam?: NoYesMissing | null;
        insufficientIncome?: NoYesMissing | null;
        incarceratedParent?: NoYesMissing | null;
        targetScreenReqd?: NoYesMissing | null;
        timeToHousingLoss?: TimeToHousingLoss | null;
        annualPercentAmi?: AnnualPercentAmi | null;
        literalHomelessHistory?: LiteralHomelessHistory | null;
        clientLeaseholder?: NoYesMissing | null;
        hohLeaseholder?: NoYesMissing | null;
        subsidyAtRisk?: NoYesMissing | null;
        evictionHistory?: EvictionHistory | null;
        criminalRecord?: NoYesMissing | null;
        incarceratedAdult?: IncarceratedAdult | null;
        prisonDischarge?: NoYesMissing | null;
        sexOffender?: NoYesMissing | null;
        disabledHoh?: NoYesMissing | null;
        currentPregnant?: NoYesMissing | null;
        singleParent?: NoYesMissing | null;
        dependentUnder6?: DependentUnder6 | null;
        hh5Plus?: NoYesMissing | null;
        cocPrioritized?: NoYesMissing | null;
        hpScreeningScore?: number | null;
        thresholdScore?: number | null;
        vamcStation?: VamcStationNumber | null;
        translationNeeded?: NoYesReasonsForMissingData | null;
        preferredLanguage?: PreferredLanguage | null;
        preferredLanguageDifferent?: string | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
        client: { __typename?: 'Client'; id: string };
      };
      ceAssessment?: {
        __typename?: 'CeAssessment';
        id: string;
        assessmentDate: string;
        assessmentLevel?: AssessmentLevel | null;
        assessmentLocation: string;
        assessmentType?: AssessmentType | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        prioritizationStatus?: PrioritizationStatus | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      event?: {
        __typename?: 'Event';
        id: string;
        event: EventType;
        eventDate: string;
        locationCrisisOrPhHousing?: string | null;
        probSolDivRrResult?: NoYesMissing | null;
        referralCaseManageAfter?: NoYesMissing | null;
        referralResult?: ReferralResult | null;
        resultDate?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      incomeBenefit?: {
        __typename: 'IncomeBenefit';
        adap?: NoYesReasonsForMissingData | null;
        alimony?: NoYesMissing | null;
        alimonyAmount?: number | null;
        benefitsFromAnySource?: NoYesReasonsForMissingData | null;
        childSupport?: NoYesMissing | null;
        childSupportAmount?: number | null;
        cobra?: NoYesMissing | null;
        connectionWithSoar?: NoYesReasonsForMissingData | null;
        dataCollectionStage: DataCollectionStage;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        earned?: NoYesMissing | null;
        earnedAmount?: number | null;
        employerProvided?: NoYesMissing | null;
        ga?: NoYesMissing | null;
        gaAmount?: number | null;
        id: string;
        incomeFromAnySource?: NoYesReasonsForMissingData | null;
        indianHealthServices?: NoYesMissing | null;
        informationDate?: string | null;
        insuranceFromAnySource?: NoYesReasonsForMissingData | null;
        medicaid?: NoYesMissing | null;
        medicare?: NoYesMissing | null;
        noAdapReason?: NoAssistanceReason | null;
        noCobraReason?: ReasonNotInsured | null;
        noEmployerProvidedReason?: ReasonNotInsured | null;
        noIndianHealthServicesReason?: ReasonNotInsured | null;
        noMedicaidReason?: ReasonNotInsured | null;
        noMedicareReason?: ReasonNotInsured | null;
        noPrivatePayReason?: ReasonNotInsured | null;
        noRyanWhiteReason?: NoAssistanceReason | null;
        noSchipReason?: ReasonNotInsured | null;
        noStateHealthInsReason?: ReasonNotInsured | null;
        noVhaReason?: ReasonNotInsured | null;
        otherBenefitsSource?: NoYesMissing | null;
        otherBenefitsSourceIdentify?: string | null;
        otherIncomeAmount?: number | null;
        otherIncomeSource?: NoYesMissing | null;
        otherIncomeSourceIdentify?: string | null;
        otherInsurance?: NoYesMissing | null;
        otherInsuranceIdentify?: string | null;
        otherTanf?: NoYesMissing | null;
        pension?: NoYesMissing | null;
        pensionAmount?: number | null;
        privateDisability?: NoYesMissing | null;
        privateDisabilityAmount?: number | null;
        privatePay?: NoYesMissing | null;
        ryanWhiteMedDent?: NoYesReasonsForMissingData | null;
        schip?: NoYesMissing | null;
        snap?: NoYesMissing | null;
        socSecRetirement?: NoYesMissing | null;
        socSecRetirementAmount?: number | null;
        ssdi?: NoYesMissing | null;
        ssdiAmount?: number | null;
        ssi?: NoYesMissing | null;
        ssiAmount?: number | null;
        stateHealthIns?: NoYesMissing | null;
        tanf?: NoYesMissing | null;
        tanfAmount?: number | null;
        tanfChildCare?: NoYesMissing | null;
        tanfTransportation?: NoYesMissing | null;
        totalMonthlyIncome?: string | null;
        unemployment?: NoYesMissing | null;
        unemploymentAmount?: number | null;
        vaDisabilityNonService?: NoYesMissing | null;
        vaDisabilityNonServiceAmount?: number | null;
        vaDisabilityService?: NoYesMissing | null;
        vaDisabilityServiceAmount?: number | null;
        vhaServices?: NoYesMissing | null;
        wic?: NoYesMissing | null;
        workersComp?: NoYesMissing | null;
        workersCompAmount?: number | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
      } | null;
      disabilityGroup?: {
        __typename: 'DisabilityGroup';
        id: string;
        dataCollectionStage: DataCollectionStage;
        informationDate: string;
        disablingCondition: NoYesReasonsForMissingData;
        chronicHealthCondition?: NoYesReasonsForMissingData | null;
        chronicHealthConditionIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
        developmentalDisability?: NoYesReasonsForMissingData | null;
        hivAids?: NoYesReasonsForMissingData | null;
        mentalHealthDisorder?: NoYesReasonsForMissingData | null;
        mentalHealthDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
        physicalDisability?: NoYesReasonsForMissingData | null;
        physicalDisabilityIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
        substanceUseDisorder?: DisabilityResponse | null;
        substanceUseDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        tCellCountAvailable?: NoYesReasonsForMissingData | null;
        tCellCount?: number | null;
        tCellSource?: TCellSourceViralLoadSource | null;
        viralLoadAvailable?: ViralLoadAvailable | null;
        viralLoad?: number | null;
        viralLoadSource?: TCellSourceViralLoadSource | null;
        antiRetroviral?: NoYesReasonsForMissingData | null;
      } | null;
      healthAndDv?: {
        __typename: 'HealthAndDv';
        currentlyFleeing?: NoYesReasonsForMissingData | null;
        dataCollectionStage: DataCollectionStage;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        dentalHealthStatus?: HealthStatus | null;
        domesticViolenceSurvivor?: NoYesReasonsForMissingData | null;
        dueDate?: string | null;
        generalHealthStatus?: HealthStatus | null;
        id: string;
        informationDate?: string | null;
        mentalHealthStatus?: HealthStatus | null;
        pregnancyStatus?: NoYesReasonsForMissingData | null;
        whenOccurred?: WhenDvOccurred | null;
      } | null;
      exit?: {
        __typename?: 'Exit';
        id: string;
        aftercareDate?: string | null;
        aftercareProvided?: AftercareProvided | null;
        aftercareMethods?: Array<AftercareMethod> | null;
        askedOrForcedToExchangeForSex?: NoYesReasonsForMissingData | null;
        askedOrForcedToExchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
        cmExitReason?: CmExitReason | null;
        coercedToContinueWork?: NoYesReasonsForMissingData | null;
        counselingReceived?: NoYesMissing | null;
        counselingMethods?: Array<CounselingMethod> | null;
        countOfExchangeForSex?: CountExchangeForSex | null;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        destination: Destination;
        destinationSafeClient?: NoYesReasonsForMissingData | null;
        destinationSafeWorker?: WorkerResponse | null;
        destinationSubsidyType?: RentalSubsidyType | null;
        earlyExitReason?: ExpelledReason | null;
        exchangeForSex?: NoYesReasonsForMissingData | null;
        exchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
        exitDate: string;
        housingAssessment?: HousingAssessmentAtExit | null;
        laborExploitPastThreeMonths?: NoYesReasonsForMissingData | null;
        otherDestination?: string | null;
        posAdultConnections?: WorkerResponse | null;
        posCommunityConnections?: WorkerResponse | null;
        posPeerConnections?: WorkerResponse | null;
        postExitCounselingPlan?: NoYesMissing | null;
        projectCompletionStatus?: ProjectCompletionStatus | null;
        sessionCountAtExit?: number | null;
        sessionsInPlan?: number | null;
        subsidyInformation?: SubsidyInformation | null;
        workPlaceViolenceThreats?: NoYesReasonsForMissingData | null;
        workplacePromiseDifference?: NoYesReasonsForMissingData | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
      } | null;
      youthEducationStatus?: {
        __typename?: 'YouthEducationStatus';
        currentEdStatus?: CurrentEdStatus | null;
        currentSchoolAttend?: CurrentSchoolAttended | null;
        dataCollectionStage: DataCollectionStage;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        id: string;
        informationDate?: string | null;
        mostRecentEdStatus?: MostRecentEdStatus | null;
      } | null;
      employmentEducation?: {
        __typename?: 'EmploymentEducation';
        dataCollectionStage: DataCollectionStage;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        employed?: NoYesReasonsForMissingData | null;
        employmentType?: EmploymentType | null;
        id: string;
        informationDate?: string | null;
        lastGradeCompleted?: LastGradeCompleted | null;
        notEmployedReason?: NotEmployedReason | null;
        schoolStatus?: SchoolStatus | null;
      } | null;
      customDataElements: Array<{
        __typename?: 'CustomDataElement';
        id: string;
        key: string;
        label: string;
        fieldType: CustomDataElementType;
        repeats: boolean;
        displayHooks: Array<DisplayHook>;
        value?: {
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        values?: Array<{
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }> | null;
      }>;
      access: {
        __typename?: 'AssessmentAccess';
        id: string;
        canDeleteAssessments: boolean;
        canDeleteEnrollments: boolean;
        canEditEnrollments: boolean;
      };
      geolocation?: {
        __typename?: 'Geolocation';
        id: string;
        coordinates: {
          __typename?: 'GeolocationCoordinates';
          id: string;
          latitude: string;
          longitude: string;
        };
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      definition: {
        __typename?: 'FormDefinition';
        id: string;
        cacheKey: string;
        title: string;
      };
    }> | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetAssessmentsForPopulationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  roles?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type GetAssessmentsForPopulationQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    assessments: {
      __typename?: 'AssessmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Assessment';
        id: string;
        lockVersion: number;
        inProgress: boolean;
        assessmentDate: string;
        dataCollectionStage?: DataCollectionStage | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        role: AssessmentRole;
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          entryDate: string;
          exitDate?: string | null;
          lockVersion: number;
          disablingCondition?: NoYesReasonsForMissingData | null;
          dateOfEngagement?: string | null;
          moveInDate?: string | null;
          livingSituation?: PriorLivingSituation | null;
          rentalSubsidyType?: RentalSubsidyType | null;
          lengthOfStay?: ResidencePriorLengthOfStay | null;
          losUnderThreshold?: NoYesMissing | null;
          previousStreetEssh?: NoYesMissing | null;
          dateToStreetEssh?: string | null;
          timesHomelessPastThreeYears?: TimesHomelessPastThreeYears | null;
          monthsHomelessPastThreeYears?: MonthsHomelessPastThreeYears | null;
          enrollmentCoc?: string | null;
          dateOfPathStatus?: string | null;
          clientEnrolledInPath?: NoYesMissing | null;
          reasonNotEnrolled?: ReasonNotEnrolled | null;
          percentAmi?: PercentAmi | null;
          referralSource?: ReferralSource | null;
          countOutreachReferralApproaches?: number | null;
          dateOfBcpStatus?: string | null;
          eligibleForRhy?: NoYesMissing | null;
          reasonNoServices?: ReasonNoServices | null;
          runawayYouth?: NoYesReasonsForMissingData | null;
          sexualOrientation?: SexualOrientation | null;
          sexualOrientationOther?: string | null;
          formerWardChildWelfare?: NoYesReasonsForMissingData | null;
          childWelfareYears?: RhyNumberofYears | null;
          childWelfareMonths?: number | null;
          formerWardJuvenileJustice?: NoYesReasonsForMissingData | null;
          juvenileJusticeYears?: RhyNumberofYears | null;
          juvenileJusticeMonths?: number | null;
          unemploymentFam?: NoYesMissing | null;
          mentalHealthDisorderFam?: NoYesMissing | null;
          physicalDisabilityFam?: NoYesMissing | null;
          alcoholDrugUseDisorderFam?: NoYesMissing | null;
          insufficientIncome?: NoYesMissing | null;
          incarceratedParent?: NoYesMissing | null;
          targetScreenReqd?: NoYesMissing | null;
          timeToHousingLoss?: TimeToHousingLoss | null;
          annualPercentAmi?: AnnualPercentAmi | null;
          literalHomelessHistory?: LiteralHomelessHistory | null;
          clientLeaseholder?: NoYesMissing | null;
          hohLeaseholder?: NoYesMissing | null;
          subsidyAtRisk?: NoYesMissing | null;
          evictionHistory?: EvictionHistory | null;
          criminalRecord?: NoYesMissing | null;
          incarceratedAdult?: IncarceratedAdult | null;
          prisonDischarge?: NoYesMissing | null;
          sexOffender?: NoYesMissing | null;
          disabledHoh?: NoYesMissing | null;
          currentPregnant?: NoYesMissing | null;
          singleParent?: NoYesMissing | null;
          dependentUnder6?: DependentUnder6 | null;
          hh5Plus?: NoYesMissing | null;
          cocPrioritized?: NoYesMissing | null;
          hpScreeningScore?: number | null;
          thresholdScore?: number | null;
          vamcStation?: VamcStationNumber | null;
          translationNeeded?: NoYesReasonsForMissingData | null;
          preferredLanguage?: PreferredLanguage | null;
          preferredLanguageDifferent?: string | null;
          project: {
            __typename?: 'Project';
            id: string;
            projectName: string;
            projectType?: ProjectType | null;
          };
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
          client: { __typename?: 'Client'; id: string };
        };
        ceAssessment?: {
          __typename?: 'CeAssessment';
          id: string;
          assessmentDate: string;
          assessmentLevel?: AssessmentLevel | null;
          assessmentLocation: string;
          assessmentType?: AssessmentType | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          dateDeleted?: string | null;
          prioritizationStatus?: PrioritizationStatus | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        event?: {
          __typename?: 'Event';
          id: string;
          event: EventType;
          eventDate: string;
          locationCrisisOrPhHousing?: string | null;
          probSolDivRrResult?: NoYesMissing | null;
          referralCaseManageAfter?: NoYesMissing | null;
          referralResult?: ReferralResult | null;
          resultDate?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          dateDeleted?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        incomeBenefit?: {
          __typename: 'IncomeBenefit';
          adap?: NoYesReasonsForMissingData | null;
          alimony?: NoYesMissing | null;
          alimonyAmount?: number | null;
          benefitsFromAnySource?: NoYesReasonsForMissingData | null;
          childSupport?: NoYesMissing | null;
          childSupportAmount?: number | null;
          cobra?: NoYesMissing | null;
          connectionWithSoar?: NoYesReasonsForMissingData | null;
          dataCollectionStage: DataCollectionStage;
          dateCreated?: string | null;
          dateDeleted?: string | null;
          dateUpdated?: string | null;
          earned?: NoYesMissing | null;
          earnedAmount?: number | null;
          employerProvided?: NoYesMissing | null;
          ga?: NoYesMissing | null;
          gaAmount?: number | null;
          id: string;
          incomeFromAnySource?: NoYesReasonsForMissingData | null;
          indianHealthServices?: NoYesMissing | null;
          informationDate?: string | null;
          insuranceFromAnySource?: NoYesReasonsForMissingData | null;
          medicaid?: NoYesMissing | null;
          medicare?: NoYesMissing | null;
          noAdapReason?: NoAssistanceReason | null;
          noCobraReason?: ReasonNotInsured | null;
          noEmployerProvidedReason?: ReasonNotInsured | null;
          noIndianHealthServicesReason?: ReasonNotInsured | null;
          noMedicaidReason?: ReasonNotInsured | null;
          noMedicareReason?: ReasonNotInsured | null;
          noPrivatePayReason?: ReasonNotInsured | null;
          noRyanWhiteReason?: NoAssistanceReason | null;
          noSchipReason?: ReasonNotInsured | null;
          noStateHealthInsReason?: ReasonNotInsured | null;
          noVhaReason?: ReasonNotInsured | null;
          otherBenefitsSource?: NoYesMissing | null;
          otherBenefitsSourceIdentify?: string | null;
          otherIncomeAmount?: number | null;
          otherIncomeSource?: NoYesMissing | null;
          otherIncomeSourceIdentify?: string | null;
          otherInsurance?: NoYesMissing | null;
          otherInsuranceIdentify?: string | null;
          otherTanf?: NoYesMissing | null;
          pension?: NoYesMissing | null;
          pensionAmount?: number | null;
          privateDisability?: NoYesMissing | null;
          privateDisabilityAmount?: number | null;
          privatePay?: NoYesMissing | null;
          ryanWhiteMedDent?: NoYesReasonsForMissingData | null;
          schip?: NoYesMissing | null;
          snap?: NoYesMissing | null;
          socSecRetirement?: NoYesMissing | null;
          socSecRetirementAmount?: number | null;
          ssdi?: NoYesMissing | null;
          ssdiAmount?: number | null;
          ssi?: NoYesMissing | null;
          ssiAmount?: number | null;
          stateHealthIns?: NoYesMissing | null;
          tanf?: NoYesMissing | null;
          tanfAmount?: number | null;
          tanfChildCare?: NoYesMissing | null;
          tanfTransportation?: NoYesMissing | null;
          totalMonthlyIncome?: string | null;
          unemployment?: NoYesMissing | null;
          unemploymentAmount?: number | null;
          vaDisabilityNonService?: NoYesMissing | null;
          vaDisabilityNonServiceAmount?: number | null;
          vaDisabilityService?: NoYesMissing | null;
          vaDisabilityServiceAmount?: number | null;
          vhaServices?: NoYesMissing | null;
          wic?: NoYesMissing | null;
          workersComp?: NoYesMissing | null;
          workersCompAmount?: number | null;
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
        } | null;
        disabilityGroup?: {
          __typename: 'DisabilityGroup';
          id: string;
          dataCollectionStage: DataCollectionStage;
          informationDate: string;
          disablingCondition: NoYesReasonsForMissingData;
          chronicHealthCondition?: NoYesReasonsForMissingData | null;
          chronicHealthConditionIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
          developmentalDisability?: NoYesReasonsForMissingData | null;
          hivAids?: NoYesReasonsForMissingData | null;
          mentalHealthDisorder?: NoYesReasonsForMissingData | null;
          mentalHealthDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
          physicalDisability?: NoYesReasonsForMissingData | null;
          physicalDisabilityIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
          substanceUseDisorder?: DisabilityResponse | null;
          substanceUseDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          tCellCountAvailable?: NoYesReasonsForMissingData | null;
          tCellCount?: number | null;
          tCellSource?: TCellSourceViralLoadSource | null;
          viralLoadAvailable?: ViralLoadAvailable | null;
          viralLoad?: number | null;
          viralLoadSource?: TCellSourceViralLoadSource | null;
          antiRetroviral?: NoYesReasonsForMissingData | null;
        } | null;
        healthAndDv?: {
          __typename: 'HealthAndDv';
          currentlyFleeing?: NoYesReasonsForMissingData | null;
          dataCollectionStage: DataCollectionStage;
          dateCreated?: string | null;
          dateDeleted?: string | null;
          dateUpdated?: string | null;
          dentalHealthStatus?: HealthStatus | null;
          domesticViolenceSurvivor?: NoYesReasonsForMissingData | null;
          dueDate?: string | null;
          generalHealthStatus?: HealthStatus | null;
          id: string;
          informationDate?: string | null;
          mentalHealthStatus?: HealthStatus | null;
          pregnancyStatus?: NoYesReasonsForMissingData | null;
          whenOccurred?: WhenDvOccurred | null;
        } | null;
        exit?: {
          __typename?: 'Exit';
          id: string;
          aftercareDate?: string | null;
          aftercareProvided?: AftercareProvided | null;
          aftercareMethods?: Array<AftercareMethod> | null;
          askedOrForcedToExchangeForSex?: NoYesReasonsForMissingData | null;
          askedOrForcedToExchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
          cmExitReason?: CmExitReason | null;
          coercedToContinueWork?: NoYesReasonsForMissingData | null;
          counselingReceived?: NoYesMissing | null;
          counselingMethods?: Array<CounselingMethod> | null;
          countOfExchangeForSex?: CountExchangeForSex | null;
          dateCreated?: string | null;
          dateDeleted?: string | null;
          dateUpdated?: string | null;
          destination: Destination;
          destinationSafeClient?: NoYesReasonsForMissingData | null;
          destinationSafeWorker?: WorkerResponse | null;
          destinationSubsidyType?: RentalSubsidyType | null;
          earlyExitReason?: ExpelledReason | null;
          exchangeForSex?: NoYesReasonsForMissingData | null;
          exchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
          exitDate: string;
          housingAssessment?: HousingAssessmentAtExit | null;
          laborExploitPastThreeMonths?: NoYesReasonsForMissingData | null;
          otherDestination?: string | null;
          posAdultConnections?: WorkerResponse | null;
          posCommunityConnections?: WorkerResponse | null;
          posPeerConnections?: WorkerResponse | null;
          postExitCounselingPlan?: NoYesMissing | null;
          projectCompletionStatus?: ProjectCompletionStatus | null;
          sessionCountAtExit?: number | null;
          sessionsInPlan?: number | null;
          subsidyInformation?: SubsidyInformation | null;
          workPlaceViolenceThreats?: NoYesReasonsForMissingData | null;
          workplacePromiseDifference?: NoYesReasonsForMissingData | null;
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
        } | null;
        youthEducationStatus?: {
          __typename?: 'YouthEducationStatus';
          currentEdStatus?: CurrentEdStatus | null;
          currentSchoolAttend?: CurrentSchoolAttended | null;
          dataCollectionStage: DataCollectionStage;
          dateCreated?: string | null;
          dateDeleted?: string | null;
          dateUpdated?: string | null;
          id: string;
          informationDate?: string | null;
          mostRecentEdStatus?: MostRecentEdStatus | null;
        } | null;
        employmentEducation?: {
          __typename?: 'EmploymentEducation';
          dataCollectionStage: DataCollectionStage;
          dateCreated?: string | null;
          dateDeleted?: string | null;
          employed?: NoYesReasonsForMissingData | null;
          employmentType?: EmploymentType | null;
          id: string;
          informationDate?: string | null;
          lastGradeCompleted?: LastGradeCompleted | null;
          notEmployedReason?: NotEmployedReason | null;
          schoolStatus?: SchoolStatus | null;
        } | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
        access: {
          __typename?: 'AssessmentAccess';
          id: string;
          canDeleteAssessments: boolean;
          canDeleteEnrollments: boolean;
          canEditEnrollments: boolean;
        };
        geolocation?: {
          __typename?: 'Geolocation';
          id: string;
          coordinates: {
            __typename?: 'GeolocationCoordinates';
            id: string;
            latitude: string;
            longitude: string;
          };
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        definition: {
          __typename?: 'FormDefinition';
          id: string;
          cacheKey: string;
          title: string;
        };
      }>;
    };
  } | null;
};

export type DeleteAssessmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  assessmentLockVersion?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DeleteAssessmentMutation = {
  __typename?: 'Mutation';
  deleteAssessment?: {
    __typename?: 'DeleteAssessmentPayload';
    clientMutationId?: string | null;
    assessmentId?: string | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type EnrollmentFieldsFromAssessmentFragment = {
  __typename?: 'Enrollment';
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  disablingCondition?: NoYesReasonsForMissingData | null;
  dateOfEngagement?: string | null;
  moveInDate?: string | null;
  livingSituation?: PriorLivingSituation | null;
  rentalSubsidyType?: RentalSubsidyType | null;
  lengthOfStay?: ResidencePriorLengthOfStay | null;
  losUnderThreshold?: NoYesMissing | null;
  previousStreetEssh?: NoYesMissing | null;
  dateToStreetEssh?: string | null;
  timesHomelessPastThreeYears?: TimesHomelessPastThreeYears | null;
  monthsHomelessPastThreeYears?: MonthsHomelessPastThreeYears | null;
  enrollmentCoc?: string | null;
  dateOfPathStatus?: string | null;
  clientEnrolledInPath?: NoYesMissing | null;
  reasonNotEnrolled?: ReasonNotEnrolled | null;
  percentAmi?: PercentAmi | null;
  referralSource?: ReferralSource | null;
  countOutreachReferralApproaches?: number | null;
  dateOfBcpStatus?: string | null;
  eligibleForRhy?: NoYesMissing | null;
  reasonNoServices?: ReasonNoServices | null;
  runawayYouth?: NoYesReasonsForMissingData | null;
  sexualOrientation?: SexualOrientation | null;
  sexualOrientationOther?: string | null;
  formerWardChildWelfare?: NoYesReasonsForMissingData | null;
  childWelfareYears?: RhyNumberofYears | null;
  childWelfareMonths?: number | null;
  formerWardJuvenileJustice?: NoYesReasonsForMissingData | null;
  juvenileJusticeYears?: RhyNumberofYears | null;
  juvenileJusticeMonths?: number | null;
  unemploymentFam?: NoYesMissing | null;
  mentalHealthDisorderFam?: NoYesMissing | null;
  physicalDisabilityFam?: NoYesMissing | null;
  alcoholDrugUseDisorderFam?: NoYesMissing | null;
  insufficientIncome?: NoYesMissing | null;
  incarceratedParent?: NoYesMissing | null;
  targetScreenReqd?: NoYesMissing | null;
  timeToHousingLoss?: TimeToHousingLoss | null;
  annualPercentAmi?: AnnualPercentAmi | null;
  literalHomelessHistory?: LiteralHomelessHistory | null;
  clientLeaseholder?: NoYesMissing | null;
  hohLeaseholder?: NoYesMissing | null;
  subsidyAtRisk?: NoYesMissing | null;
  evictionHistory?: EvictionHistory | null;
  criminalRecord?: NoYesMissing | null;
  incarceratedAdult?: IncarceratedAdult | null;
  prisonDischarge?: NoYesMissing | null;
  sexOffender?: NoYesMissing | null;
  disabledHoh?: NoYesMissing | null;
  currentPregnant?: NoYesMissing | null;
  singleParent?: NoYesMissing | null;
  dependentUnder6?: DependentUnder6 | null;
  hh5Plus?: NoYesMissing | null;
  cocPrioritized?: NoYesMissing | null;
  hpScreeningScore?: number | null;
  thresholdScore?: number | null;
  vamcStation?: VamcStationNumber | null;
  translationNeeded?: NoYesReasonsForMissingData | null;
  preferredLanguage?: PreferredLanguage | null;
  preferredLanguageDifferent?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  project: {
    __typename?: 'Project';
    id: string;
    projectName: string;
    projectType?: ProjectType | null;
  };
  intakeAssessment?: {
    __typename?: 'Assessment';
    id: string;
    user?: { __typename?: 'ApplicationUser'; name: string } | null;
  } | null;
};

export type IncomeBenefitValuesFragment = {
  __typename: 'IncomeBenefit';
  adap?: NoYesReasonsForMissingData | null;
  alimony?: NoYesMissing | null;
  alimonyAmount?: number | null;
  benefitsFromAnySource?: NoYesReasonsForMissingData | null;
  childSupport?: NoYesMissing | null;
  childSupportAmount?: number | null;
  cobra?: NoYesMissing | null;
  connectionWithSoar?: NoYesReasonsForMissingData | null;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  earned?: NoYesMissing | null;
  earnedAmount?: number | null;
  employerProvided?: NoYesMissing | null;
  ga?: NoYesMissing | null;
  gaAmount?: number | null;
  id: string;
  incomeFromAnySource?: NoYesReasonsForMissingData | null;
  indianHealthServices?: NoYesMissing | null;
  informationDate?: string | null;
  insuranceFromAnySource?: NoYesReasonsForMissingData | null;
  medicaid?: NoYesMissing | null;
  medicare?: NoYesMissing | null;
  noAdapReason?: NoAssistanceReason | null;
  noCobraReason?: ReasonNotInsured | null;
  noEmployerProvidedReason?: ReasonNotInsured | null;
  noIndianHealthServicesReason?: ReasonNotInsured | null;
  noMedicaidReason?: ReasonNotInsured | null;
  noMedicareReason?: ReasonNotInsured | null;
  noPrivatePayReason?: ReasonNotInsured | null;
  noRyanWhiteReason?: NoAssistanceReason | null;
  noSchipReason?: ReasonNotInsured | null;
  noStateHealthInsReason?: ReasonNotInsured | null;
  noVhaReason?: ReasonNotInsured | null;
  otherBenefitsSource?: NoYesMissing | null;
  otherBenefitsSourceIdentify?: string | null;
  otherIncomeAmount?: number | null;
  otherIncomeSource?: NoYesMissing | null;
  otherIncomeSourceIdentify?: string | null;
  otherInsurance?: NoYesMissing | null;
  otherInsuranceIdentify?: string | null;
  otherTanf?: NoYesMissing | null;
  pension?: NoYesMissing | null;
  pensionAmount?: number | null;
  privateDisability?: NoYesMissing | null;
  privateDisabilityAmount?: number | null;
  privatePay?: NoYesMissing | null;
  ryanWhiteMedDent?: NoYesReasonsForMissingData | null;
  schip?: NoYesMissing | null;
  snap?: NoYesMissing | null;
  socSecRetirement?: NoYesMissing | null;
  socSecRetirementAmount?: number | null;
  ssdi?: NoYesMissing | null;
  ssdiAmount?: number | null;
  ssi?: NoYesMissing | null;
  ssiAmount?: number | null;
  stateHealthIns?: NoYesMissing | null;
  tanf?: NoYesMissing | null;
  tanfAmount?: number | null;
  tanfChildCare?: NoYesMissing | null;
  tanfTransportation?: NoYesMissing | null;
  totalMonthlyIncome?: string | null;
  unemployment?: NoYesMissing | null;
  unemploymentAmount?: number | null;
  vaDisabilityNonService?: NoYesMissing | null;
  vaDisabilityNonServiceAmount?: number | null;
  vaDisabilityService?: NoYesMissing | null;
  vaDisabilityServiceAmount?: number | null;
  vhaServices?: NoYesMissing | null;
  wic?: NoYesMissing | null;
  workersComp?: NoYesMissing | null;
  workersCompAmount?: number | null;
};

export type IncomeBenefitFieldsFragment = {
  __typename: 'IncomeBenefit';
  adap?: NoYesReasonsForMissingData | null;
  alimony?: NoYesMissing | null;
  alimonyAmount?: number | null;
  benefitsFromAnySource?: NoYesReasonsForMissingData | null;
  childSupport?: NoYesMissing | null;
  childSupportAmount?: number | null;
  cobra?: NoYesMissing | null;
  connectionWithSoar?: NoYesReasonsForMissingData | null;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  earned?: NoYesMissing | null;
  earnedAmount?: number | null;
  employerProvided?: NoYesMissing | null;
  ga?: NoYesMissing | null;
  gaAmount?: number | null;
  id: string;
  incomeFromAnySource?: NoYesReasonsForMissingData | null;
  indianHealthServices?: NoYesMissing | null;
  informationDate?: string | null;
  insuranceFromAnySource?: NoYesReasonsForMissingData | null;
  medicaid?: NoYesMissing | null;
  medicare?: NoYesMissing | null;
  noAdapReason?: NoAssistanceReason | null;
  noCobraReason?: ReasonNotInsured | null;
  noEmployerProvidedReason?: ReasonNotInsured | null;
  noIndianHealthServicesReason?: ReasonNotInsured | null;
  noMedicaidReason?: ReasonNotInsured | null;
  noMedicareReason?: ReasonNotInsured | null;
  noPrivatePayReason?: ReasonNotInsured | null;
  noRyanWhiteReason?: NoAssistanceReason | null;
  noSchipReason?: ReasonNotInsured | null;
  noStateHealthInsReason?: ReasonNotInsured | null;
  noVhaReason?: ReasonNotInsured | null;
  otherBenefitsSource?: NoYesMissing | null;
  otherBenefitsSourceIdentify?: string | null;
  otherIncomeAmount?: number | null;
  otherIncomeSource?: NoYesMissing | null;
  otherIncomeSourceIdentify?: string | null;
  otherInsurance?: NoYesMissing | null;
  otherInsuranceIdentify?: string | null;
  otherTanf?: NoYesMissing | null;
  pension?: NoYesMissing | null;
  pensionAmount?: number | null;
  privateDisability?: NoYesMissing | null;
  privateDisabilityAmount?: number | null;
  privatePay?: NoYesMissing | null;
  ryanWhiteMedDent?: NoYesReasonsForMissingData | null;
  schip?: NoYesMissing | null;
  snap?: NoYesMissing | null;
  socSecRetirement?: NoYesMissing | null;
  socSecRetirementAmount?: number | null;
  ssdi?: NoYesMissing | null;
  ssdiAmount?: number | null;
  ssi?: NoYesMissing | null;
  ssiAmount?: number | null;
  stateHealthIns?: NoYesMissing | null;
  tanf?: NoYesMissing | null;
  tanfAmount?: number | null;
  tanfChildCare?: NoYesMissing | null;
  tanfTransportation?: NoYesMissing | null;
  totalMonthlyIncome?: string | null;
  unemployment?: NoYesMissing | null;
  unemploymentAmount?: number | null;
  vaDisabilityNonService?: NoYesMissing | null;
  vaDisabilityNonServiceAmount?: number | null;
  vaDisabilityService?: NoYesMissing | null;
  vaDisabilityServiceAmount?: number | null;
  vhaServices?: NoYesMissing | null;
  wic?: NoYesMissing | null;
  workersComp?: NoYesMissing | null;
  workersCompAmount?: number | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    project: {
      __typename?: 'Project';
      id: string;
      projectName: string;
      projectType?: ProjectType | null;
    };
  };
};

export type DisabilityGroupValuesFragment = {
  __typename: 'DisabilityGroup';
  id: string;
  dataCollectionStage: DataCollectionStage;
  informationDate: string;
  disablingCondition: NoYesReasonsForMissingData;
  chronicHealthCondition?: NoYesReasonsForMissingData | null;
  chronicHealthConditionIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
  developmentalDisability?: NoYesReasonsForMissingData | null;
  hivAids?: NoYesReasonsForMissingData | null;
  mentalHealthDisorder?: NoYesReasonsForMissingData | null;
  mentalHealthDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
  physicalDisability?: NoYesReasonsForMissingData | null;
  physicalDisabilityIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
  substanceUseDisorder?: DisabilityResponse | null;
  substanceUseDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  tCellCountAvailable?: NoYesReasonsForMissingData | null;
  tCellCount?: number | null;
  tCellSource?: TCellSourceViralLoadSource | null;
  viralLoadAvailable?: ViralLoadAvailable | null;
  viralLoad?: number | null;
  viralLoadSource?: TCellSourceViralLoadSource | null;
  antiRetroviral?: NoYesReasonsForMissingData | null;
};

export type DisabilityGroupFieldsFragment = {
  __typename: 'DisabilityGroup';
  id: string;
  dataCollectionStage: DataCollectionStage;
  informationDate: string;
  disablingCondition: NoYesReasonsForMissingData;
  chronicHealthCondition?: NoYesReasonsForMissingData | null;
  chronicHealthConditionIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
  developmentalDisability?: NoYesReasonsForMissingData | null;
  hivAids?: NoYesReasonsForMissingData | null;
  mentalHealthDisorder?: NoYesReasonsForMissingData | null;
  mentalHealthDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
  physicalDisability?: NoYesReasonsForMissingData | null;
  physicalDisabilityIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
  substanceUseDisorder?: DisabilityResponse | null;
  substanceUseDisorderIndefiniteAndImpairs?: NoYesReasonsForMissingData | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  tCellCountAvailable?: NoYesReasonsForMissingData | null;
  tCellCount?: number | null;
  tCellSource?: TCellSourceViralLoadSource | null;
  viralLoadAvailable?: ViralLoadAvailable | null;
  viralLoad?: number | null;
  viralLoadSource?: TCellSourceViralLoadSource | null;
  antiRetroviral?: NoYesReasonsForMissingData | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    project: {
      __typename?: 'Project';
      id: string;
      projectName: string;
      projectType?: ProjectType | null;
    };
  };
};

export type HealthAndDvValuesFragment = {
  __typename: 'HealthAndDv';
  currentlyFleeing?: NoYesReasonsForMissingData | null;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  dentalHealthStatus?: HealthStatus | null;
  domesticViolenceSurvivor?: NoYesReasonsForMissingData | null;
  dueDate?: string | null;
  generalHealthStatus?: HealthStatus | null;
  id: string;
  informationDate?: string | null;
  mentalHealthStatus?: HealthStatus | null;
  pregnancyStatus?: NoYesReasonsForMissingData | null;
  whenOccurred?: WhenDvOccurred | null;
};

export type HealthAndDvFieldsFragment = {
  __typename: 'HealthAndDv';
  currentlyFleeing?: NoYesReasonsForMissingData | null;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  dentalHealthStatus?: HealthStatus | null;
  domesticViolenceSurvivor?: NoYesReasonsForMissingData | null;
  dueDate?: string | null;
  generalHealthStatus?: HealthStatus | null;
  id: string;
  informationDate?: string | null;
  mentalHealthStatus?: HealthStatus | null;
  pregnancyStatus?: NoYesReasonsForMissingData | null;
  whenOccurred?: WhenDvOccurred | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    project: {
      __typename?: 'Project';
      id: string;
      projectName: string;
      projectType?: ProjectType | null;
    };
  };
};

export type ExitValuesFragment = {
  __typename?: 'Exit';
  id: string;
  aftercareDate?: string | null;
  aftercareProvided?: AftercareProvided | null;
  aftercareMethods?: Array<AftercareMethod> | null;
  askedOrForcedToExchangeForSex?: NoYesReasonsForMissingData | null;
  askedOrForcedToExchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
  cmExitReason?: CmExitReason | null;
  coercedToContinueWork?: NoYesReasonsForMissingData | null;
  counselingReceived?: NoYesMissing | null;
  counselingMethods?: Array<CounselingMethod> | null;
  countOfExchangeForSex?: CountExchangeForSex | null;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  destination: Destination;
  destinationSafeClient?: NoYesReasonsForMissingData | null;
  destinationSafeWorker?: WorkerResponse | null;
  destinationSubsidyType?: RentalSubsidyType | null;
  earlyExitReason?: ExpelledReason | null;
  exchangeForSex?: NoYesReasonsForMissingData | null;
  exchangeForSexPastThreeMonths?: NoYesReasonsForMissingData | null;
  exitDate: string;
  housingAssessment?: HousingAssessmentAtExit | null;
  laborExploitPastThreeMonths?: NoYesReasonsForMissingData | null;
  otherDestination?: string | null;
  posAdultConnections?: WorkerResponse | null;
  posCommunityConnections?: WorkerResponse | null;
  posPeerConnections?: WorkerResponse | null;
  postExitCounselingPlan?: NoYesMissing | null;
  projectCompletionStatus?: ProjectCompletionStatus | null;
  sessionCountAtExit?: number | null;
  sessionsInPlan?: number | null;
  subsidyInformation?: SubsidyInformation | null;
  workPlaceViolenceThreats?: NoYesReasonsForMissingData | null;
  workplacePromiseDifference?: NoYesReasonsForMissingData | null;
};

export type YouthEducationStatusValuesFragment = {
  __typename?: 'YouthEducationStatus';
  currentEdStatus?: CurrentEdStatus | null;
  currentSchoolAttend?: CurrentSchoolAttended | null;
  dataCollectionStage: DataCollectionStage;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  id: string;
  informationDate?: string | null;
  mostRecentEdStatus?: MostRecentEdStatus | null;
};

export type EmploymentEducationValuesFragment = {
  __typename?: 'EmploymentEducation';
  dataCollectionStage: DataCollectionStage;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  employed?: NoYesReasonsForMissingData | null;
  employmentType?: EmploymentType | null;
  id: string;
  informationDate?: string | null;
  lastGradeCompleted?: LastGradeCompleted | null;
  notEmployedReason?: NotEmployedReason | null;
  schoolStatus?: SchoolStatus | null;
};

export type ClientAuditEventFieldsFragment = {
  __typename?: 'ClientAuditEvent';
  id: string;
  createdAt: string;
  event: AuditEventType;
  objectChanges?: any | null;
  recordName: string;
  graphqlType: string;
  recordId: string;
  user?: { __typename?: 'ApplicationUser'; id: string; name: string } | null;
  trueUser?: {
    __typename?: 'ApplicationUser';
    id: string;
    name: string;
  } | null;
};

export type EnrollmentAuditEventFieldsFragment = {
  __typename?: 'EnrollmentAuditEvent';
  id: string;
  createdAt: string;
  event: AuditEventType;
  objectChanges?: any | null;
  recordName: string;
  graphqlType: string;
  recordId: string;
  user?: { __typename?: 'ApplicationUser'; id: string; name: string } | null;
  trueUser?: {
    __typename?: 'ApplicationUser';
    id: string;
    name: string;
  } | null;
};

export type UserAuditEventFieldsFragment = {
  __typename?: 'ApplicationUserAuditEvent';
  id: string;
  createdAt: string;
  event: AuditEventType;
  objectChanges?: any | null;
  recordName: string;
  graphqlType: string;
  recordId: string;
  clientId?: string | null;
  clientName?: string | null;
  enrollmentId?: string | null;
  projectId?: string | null;
  projectName?: string | null;
  user?: { __typename?: 'ApplicationUser'; id: string; name: string } | null;
};

export type GetClientAuditEventsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ClientAuditEventFilterOptions>;
}>;

export type GetClientAuditEventsQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    auditHistory: {
      __typename?: 'ClientAuditEventsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ClientAuditEvent';
        id: string;
        createdAt: string;
        event: AuditEventType;
        objectChanges?: any | null;
        recordName: string;
        graphqlType: string;
        recordId: string;
        user?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        trueUser?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetEnrollmentAuditEventsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<EnrollmentAuditEventFilterOptions>;
}>;

export type GetEnrollmentAuditEventsQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    auditHistory: {
      __typename?: 'EnrollmentAuditEventsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'EnrollmentAuditEvent';
        id: string;
        createdAt: string;
        event: AuditEventType;
        objectChanges?: any | null;
        recordName: string;
        graphqlType: string;
        recordId: string;
        user?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        trueUser?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetUserAuditEventsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<UserAuditEventFilterOptions>;
}>;

export type GetUserAuditEventsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ApplicationUser';
    id: string;
    auditHistory: {
      __typename?: 'ApplicationUserAuditEventsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ApplicationUserAuditEvent';
        id: string;
        createdAt: string;
        event: AuditEventType;
        objectChanges?: any | null;
        recordName: string;
        graphqlType: string;
        recordId: string;
        clientId?: string | null;
        clientName?: string | null;
        enrollmentId?: string | null;
        projectId?: string | null;
        projectName?: string | null;
        user?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
      }>;
    };
  } | null;
};

export type BulkServicesClientSearchQueryVariables = Exact<{
  textSearch: Scalars['String']['input'];
  filters?: InputMaybe<ClientFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ClientSortOption>;
  serviceTypeId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  serviceDate: Scalars['ISO8601Date']['input'];
}>;

export type BulkServicesClientSearchQuery = {
  __typename?: 'Query';
  clientSearch: {
    __typename?: 'ClientsPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'Client';
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
      dob?: string | null;
      age?: number | null;
      gender: Array<Gender>;
      pronouns: Array<string>;
      alerts: Array<{
        __typename?: 'ClientAlert';
        id: string;
        note: string;
        expirationDate?: string | null;
        createdAt: string;
        priority: ClientAlertPriorityLevel;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
      activeEnrollment?: {
        __typename?: 'Enrollment';
        id: string;
        entryDate: string;
        lastServiceDate?: string | null;
        services: {
          __typename?: 'ServicesPaginated';
          limit: number;
          offset: number;
          nodesCount: number;
          nodes: Array<{ __typename?: 'Service'; id: string }>;
        };
      } | null;
    }>;
  };
};

export type BulkAssignServiceMutationVariables = Exact<{
  input: BulkAssignServiceInput;
}>;

export type BulkAssignServiceMutation = {
  __typename?: 'Mutation';
  bulkAssignService?: {
    __typename?: 'BulkAssignServicePayload';
    success?: boolean | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type BulkRemoveServiceMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  serviceIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type BulkRemoveServiceMutation = {
  __typename?: 'Mutation';
  bulkRemoveService?: {
    __typename?: 'BulkRemoveServicePayload';
    success?: boolean | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type ClientSearchResultFieldsFragment = {
  __typename?: 'Client';
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  id: string;
  lockVersion: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  nameSuffix?: string | null;
  dob?: string | null;
  age?: number | null;
  gender: Array<Gender>;
  pronouns: Array<string>;
  ssn?: string | null;
  externalIds: Array<{
    __typename?: 'ExternalIdentifier';
    id: string;
    identifier?: string | null;
    url?: string | null;
    label: string;
    type: ExternalIdentifierType;
  }>;
  alerts: Array<{
    __typename?: 'ClientAlert';
    id: string;
    note: string;
    expirationDate?: string | null;
    createdAt: string;
    priority: ClientAlertPriorityLevel;
    createdBy?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  }>;
  access: {
    __typename?: 'ClientAccess';
    id: string;
    canViewFullSsn: boolean;
    canViewPartialSsn: boolean;
  };
};

export type ClientFieldsFragment = {
  __typename?: 'Client';
  dobDataQuality: DobDataQuality;
  gender: Array<Gender>;
  differentIdentityText?: string | null;
  pronouns: Array<string>;
  nameDataQuality: NameDataQuality;
  personalId: string;
  race: Array<Race>;
  additionalRaceEthnicity?: string | null;
  ssnDataQuality: SsnDataQuality;
  veteranStatus: NoYesReasonsForMissingData;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  enabledFeatures: Array<ClientDashboardFeature>;
  id: string;
  lockVersion: number;
  dob?: string | null;
  age?: number | null;
  ssn?: string | null;
  yearEnteredService?: number | null;
  yearSeparated?: number | null;
  worldWarIi?: NoYesReasonsForMissingData | null;
  koreanWar?: NoYesReasonsForMissingData | null;
  vietnamWar?: NoYesReasonsForMissingData | null;
  desertStorm?: NoYesReasonsForMissingData | null;
  afghanistanOef?: NoYesReasonsForMissingData | null;
  iraqOif?: NoYesReasonsForMissingData | null;
  iraqOnd?: NoYesReasonsForMissingData | null;
  otherTheater?: NoYesReasonsForMissingData | null;
  militaryBranch?: MilitaryBranch | null;
  dischargeStatus?: DischargeStatus | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  nameSuffix?: string | null;
  externalIds: Array<{
    __typename?: 'ExternalIdentifier';
    id: string;
    identifier?: string | null;
    url?: string | null;
    label: string;
    type: ExternalIdentifierType;
  }>;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  access: {
    __typename?: 'ClientAccess';
    id: string;
    canViewFullSsn: boolean;
    canViewPartialSsn: boolean;
    canEditClient: boolean;
    canDeleteClient: boolean;
    canViewDob: boolean;
    canViewClientName: boolean;
    canViewClientPhoto: boolean;
    canViewClientAlerts: boolean;
    canManageClientAlerts: boolean;
    canViewEnrollmentDetails: boolean;
    canAuditClients: boolean;
    canManageScanCards: boolean;
    canMergeClients: boolean;
    canViewAnyFiles: boolean;
    canManageAnyClientFiles: boolean;
    canManageOwnClientFiles: boolean;
    canUploadClientFiles: boolean;
  };
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
  names: Array<{
    __typename?: 'ClientName';
    id: string;
    first?: string | null;
    middle?: string | null;
    last?: string | null;
    suffix?: string | null;
    nameDataQuality?: NameDataQuality | null;
    use?: ClientNameUse | null;
    notes?: string | null;
    primary?: boolean | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
  }>;
  addresses: Array<{
    __typename?: 'ClientAddress';
    id: string;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    district?: string | null;
    country?: string | null;
    postalCode?: string | null;
    notes?: string | null;
    use?: ClientAddressUse | null;
    addressType?: ClientAddressType | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
  }>;
  phoneNumbers: Array<{
    __typename?: 'ClientContactPoint';
    id: string;
    value?: string | null;
    notes?: string | null;
    use?: ClientContactPointUse | null;
    system?: ClientContactPointSystem | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
  }>;
  emailAddresses: Array<{
    __typename?: 'ClientContactPoint';
    id: string;
    value?: string | null;
    notes?: string | null;
    use?: ClientContactPointUse | null;
    system?: ClientContactPointSystem | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
  }>;
  alerts: Array<{
    __typename?: 'ClientAlert';
    id: string;
    note: string;
    expirationDate?: string | null;
    createdAt: string;
    priority: ClientAlertPriorityLevel;
    createdBy?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  }>;
};

export type ClientNameFragment = {
  __typename?: 'Client';
  id: string;
  lockVersion: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  nameSuffix?: string | null;
};

export type ClientNameDobVetFragment = {
  __typename?: 'Client';
  dob?: string | null;
  veteranStatus: NoYesReasonsForMissingData;
  id: string;
  lockVersion: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  nameSuffix?: string | null;
};

export type ClientNameObjectFieldsFragment = {
  __typename?: 'ClientName';
  id: string;
  first?: string | null;
  middle?: string | null;
  last?: string | null;
  suffix?: string | null;
  nameDataQuality?: NameDataQuality | null;
  use?: ClientNameUse | null;
  notes?: string | null;
  primary?: boolean | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
};

export type ClientAddressFieldsFragment = {
  __typename?: 'ClientAddress';
  id: string;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  district?: string | null;
  country?: string | null;
  postalCode?: string | null;
  notes?: string | null;
  use?: ClientAddressUse | null;
  addressType?: ClientAddressType | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
};

export type ClientContactPointFieldsFragment = {
  __typename?: 'ClientContactPoint';
  id: string;
  value?: string | null;
  notes?: string | null;
  use?: ClientContactPointUse | null;
  system?: ClientContactPointSystem | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
};

export type ClientImageFragment = {
  __typename?: 'Client';
  id: string;
  image?: {
    __typename?: 'ClientImage';
    id: string;
    contentType: string;
    base64: string;
  } | null;
  access: {
    __typename?: 'ClientAccess';
    id: string;
    canEditClient: boolean;
    canViewClientPhoto: boolean;
  };
};

export type ClientIdentificationFieldsFragment = {
  __typename?: 'Client';
  id: string;
  lockVersion: number;
  dob?: string | null;
  age?: number | null;
  gender: Array<Gender>;
  pronouns: Array<string>;
};

export type ClientSsnFieldsFragment = {
  __typename?: 'Client';
  id: string;
  lockVersion: number;
  ssn?: string | null;
  access: {
    __typename?: 'ClientAccess';
    id: string;
    canViewFullSsn: boolean;
    canViewPartialSsn: boolean;
  };
};

export type ClientVeteranInfoFieldsFragment = {
  __typename?: 'Client';
  id: string;
  yearEnteredService?: number | null;
  yearSeparated?: number | null;
  worldWarIi?: NoYesReasonsForMissingData | null;
  koreanWar?: NoYesReasonsForMissingData | null;
  vietnamWar?: NoYesReasonsForMissingData | null;
  desertStorm?: NoYesReasonsForMissingData | null;
  afghanistanOef?: NoYesReasonsForMissingData | null;
  iraqOif?: NoYesReasonsForMissingData | null;
  iraqOnd?: NoYesReasonsForMissingData | null;
  otherTheater?: NoYesReasonsForMissingData | null;
  militaryBranch?: MilitaryBranch | null;
  dischargeStatus?: DischargeStatus | null;
};

export type ClientIdentifierFieldsFragment = {
  __typename?: 'ExternalIdentifier';
  id: string;
  identifier?: string | null;
  url?: string | null;
  label: string;
  type: ExternalIdentifierType;
};

export type ClientImageFieldsFragment = {
  __typename?: 'ClientImage';
  id: string;
  contentType: string;
  base64: string;
};

export type ClientOmniSearchFieldsFragment = {
  __typename?: 'Client';
  id: string;
  dob?: string | null;
  age?: number | null;
  gender: Array<Gender>;
  personalId: string;
  lockVersion: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  nameSuffix?: string | null;
};

export type SearchClientsQueryVariables = Exact<{
  filters?: InputMaybe<ClientFilterOptions>;
  input: ClientSearchInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ClientSortOption>;
  includeSsn?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchClientsQuery = {
  __typename?: 'Query';
  clientSearch: {
    __typename?: 'ClientsPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'Client';
      dateCreated?: string | null;
      dateDeleted?: string | null;
      dateUpdated?: string | null;
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
      dob?: string | null;
      age?: number | null;
      gender: Array<Gender>;
      pronouns: Array<string>;
      ssn?: string | null;
      externalIds: Array<{
        __typename?: 'ExternalIdentifier';
        id: string;
        identifier?: string | null;
        url?: string | null;
        label: string;
        type: ExternalIdentifierType;
      }>;
      alerts: Array<{
        __typename?: 'ClientAlert';
        id: string;
        note: string;
        expirationDate?: string | null;
        createdAt: string;
        priority: ClientAlertPriorityLevel;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
      access: {
        __typename?: 'ClientAccess';
        id: string;
        canViewFullSsn: boolean;
        canViewPartialSsn: boolean;
      };
    }>;
  };
};

export type GetClientQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetClientQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    dobDataQuality: DobDataQuality;
    gender: Array<Gender>;
    differentIdentityText?: string | null;
    pronouns: Array<string>;
    nameDataQuality: NameDataQuality;
    personalId: string;
    race: Array<Race>;
    additionalRaceEthnicity?: string | null;
    ssnDataQuality: SsnDataQuality;
    veteranStatus: NoYesReasonsForMissingData;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    enabledFeatures: Array<ClientDashboardFeature>;
    id: string;
    lockVersion: number;
    dob?: string | null;
    age?: number | null;
    ssn?: string | null;
    yearEnteredService?: number | null;
    yearSeparated?: number | null;
    worldWarIi?: NoYesReasonsForMissingData | null;
    koreanWar?: NoYesReasonsForMissingData | null;
    vietnamWar?: NoYesReasonsForMissingData | null;
    desertStorm?: NoYesReasonsForMissingData | null;
    afghanistanOef?: NoYesReasonsForMissingData | null;
    iraqOif?: NoYesReasonsForMissingData | null;
    iraqOnd?: NoYesReasonsForMissingData | null;
    otherTheater?: NoYesReasonsForMissingData | null;
    militaryBranch?: MilitaryBranch | null;
    dischargeStatus?: DischargeStatus | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
    externalIds: Array<{
      __typename?: 'ExternalIdentifier';
      id: string;
      identifier?: string | null;
      url?: string | null;
      label: string;
      type: ExternalIdentifierType;
    }>;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
    access: {
      __typename?: 'ClientAccess';
      id: string;
      canViewFullSsn: boolean;
      canViewPartialSsn: boolean;
      canEditClient: boolean;
      canDeleteClient: boolean;
      canViewDob: boolean;
      canViewClientName: boolean;
      canViewClientPhoto: boolean;
      canViewClientAlerts: boolean;
      canManageClientAlerts: boolean;
      canViewEnrollmentDetails: boolean;
      canAuditClients: boolean;
      canManageScanCards: boolean;
      canMergeClients: boolean;
      canViewAnyFiles: boolean;
      canManageAnyClientFiles: boolean;
      canManageOwnClientFiles: boolean;
      canUploadClientFiles: boolean;
    };
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
    names: Array<{
      __typename?: 'ClientName';
      id: string;
      first?: string | null;
      middle?: string | null;
      last?: string | null;
      suffix?: string | null;
      nameDataQuality?: NameDataQuality | null;
      use?: ClientNameUse | null;
      notes?: string | null;
      primary?: boolean | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
    }>;
    addresses: Array<{
      __typename?: 'ClientAddress';
      id: string;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      district?: string | null;
      country?: string | null;
      postalCode?: string | null;
      notes?: string | null;
      use?: ClientAddressUse | null;
      addressType?: ClientAddressType | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
    }>;
    phoneNumbers: Array<{
      __typename?: 'ClientContactPoint';
      id: string;
      value?: string | null;
      notes?: string | null;
      use?: ClientContactPointUse | null;
      system?: ClientContactPointSystem | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
    }>;
    emailAddresses: Array<{
      __typename?: 'ClientContactPoint';
      id: string;
      value?: string | null;
      notes?: string | null;
      use?: ClientContactPointUse | null;
      system?: ClientContactPointSystem | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
    }>;
    alerts: Array<{
      __typename?: 'ClientAlert';
      id: string;
      note: string;
      expirationDate?: string | null;
      createdAt: string;
      priority: ClientAlertPriorityLevel;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }>;
  } | null;
};

export type GetClientNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetClientNameQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    lockVersion: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
  } | null;
};

export type GetClientPermissionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetClientPermissionsQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    access: {
      __typename?: 'ClientAccess';
      id: string;
      canEditClient: boolean;
      canDeleteClient: boolean;
      canViewDob: boolean;
      canViewFullSsn: boolean;
      canViewPartialSsn: boolean;
      canViewClientName: boolean;
      canViewClientPhoto: boolean;
      canViewClientAlerts: boolean;
      canManageClientAlerts: boolean;
      canViewEnrollmentDetails: boolean;
      canAuditClients: boolean;
      canManageScanCards: boolean;
      canMergeClients: boolean;
      canViewAnyFiles: boolean;
      canManageAnyClientFiles: boolean;
      canManageOwnClientFiles: boolean;
      canUploadClientFiles: boolean;
    };
  } | null;
};

export type GetClientImageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetClientImageQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    image?: {
      __typename?: 'ClientImage';
      id: string;
      contentType: string;
      base64: string;
    } | null;
    access: {
      __typename?: 'ClientAccess';
      id: string;
      canEditClient: boolean;
      canViewClientPhoto: boolean;
    };
  } | null;
};

export type GetClientEnrollmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<EnrollmentsForClientFilterOptions>;
}>;

export type GetClientEnrollmentsQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    enrollments: {
      __typename?: 'EnrollmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Enrollment';
        id: string;
        lockVersion: number;
        entryDate: string;
        exitDate?: string | null;
        autoExited: boolean;
        moveInDate?: string | null;
        lastBedNightDate?: string | null;
        projectName: string;
        organizationName: string;
        projectType?: ProjectType | null;
        inProgress: boolean;
        relationshipToHoH: RelationshipToHoH;
        access: {
          __typename?: 'EnrollmentAccess';
          id: string;
          canViewEnrollmentDetails: boolean;
        };
      }>;
    };
  } | null;
};

export type GetClientServicesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ServiceSortOption>;
  filters?: InputMaybe<ServiceFilterOptions>;
}>;

export type GetClientServicesQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    services: {
      __typename?: 'ServicesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Service';
        id: string;
        dateProvided?: string | null;
        faAmount?: number | null;
        faStartDate?: string | null;
        faEndDate?: string | null;
        movingOnOtherType?: string | null;
        referralOutcome?: PathReferralOutcome | null;
        subTypeProvided?: ServiceSubTypeProvided | null;
        otherTypeProvided?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          entryDate: string;
          exitDate?: string | null;
          autoExited: boolean;
          moveInDate?: string | null;
          lastBedNightDate?: string | null;
          projectName: string;
          organizationName: string;
          projectType?: ProjectType | null;
          inProgress: boolean;
          relationshipToHoH: RelationshipToHoH;
          access: {
            __typename?: 'EnrollmentAccess';
            id: string;
            canViewEnrollmentDetails: boolean;
          };
        };
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        serviceType?: {
          __typename?: 'ServiceType';
          id: string;
          name: string;
          hud: boolean;
          hudRecordType?: RecordType | null;
          hudTypeProvided?: ServiceTypeProvided | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          supportsBulkAssignment: boolean;
          serviceCategory: {
            __typename?: 'ServiceCategory';
            id: string;
            name: string;
          };
        } | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
      }>;
    };
  } | null;
};

export type UpdateClientImageMutationVariables = Exact<{
  clientId: Scalars['ID']['input'];
  imageBlobId: Scalars['ID']['input'];
}>;

export type UpdateClientImageMutation = {
  __typename?: 'Mutation';
  updateClientImage?: {
    __typename?: 'UpdateClientImagePayload';
    clientMutationId?: string | null;
    client?: {
      __typename?: 'Client';
      id: string;
      image?: {
        __typename?: 'ClientImage';
        id: string;
        contentType: string;
        base64: string;
      } | null;
      access: {
        __typename?: 'ClientAccess';
        id: string;
        canEditClient: boolean;
        canViewClientPhoto: boolean;
      };
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteClientImageMutationVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;

export type DeleteClientImageMutation = {
  __typename?: 'Mutation';
  deleteClientImage?: {
    __typename?: 'DeleteClientImagePayload';
    clientMutationId?: string | null;
    client?: {
      __typename?: 'Client';
      id: string;
      image?: {
        __typename?: 'ClientImage';
        id: string;
        contentType: string;
        base64: string;
      } | null;
      access: {
        __typename?: 'ClientAccess';
        id: string;
        canEditClient: boolean;
        canViewClientPhoto: boolean;
      };
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteClientFileMutationVariables = Exact<{
  input: DeleteClientFileInput;
}>;

export type DeleteClientFileMutation = {
  __typename?: 'Mutation';
  deleteClientFile?: {
    __typename?: 'DeleteClientFilePayload';
    clientMutationId?: string | null;
    file?: {
      __typename?: 'File';
      confidential?: boolean | null;
      contentType?: string | null;
      effectiveDate?: string | null;
      expirationDate?: string | null;
      id: string;
      name: string;
      url?: string | null;
      tags: Array<string>;
      ownFile: boolean;
      redacted: boolean;
      enrollmentId?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      enrollment?: { __typename?: 'Enrollment'; id: string } | null;
      uploadedBy?: {
        __typename?: 'ApplicationUser';
        id: string;
        name: string;
      } | null;
      updatedBy?: {
        __typename?: 'ApplicationUser';
        id: string;
        name: string;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteClientMutationVariables = Exact<{
  input: DeleteClientInput;
}>;

export type DeleteClientMutation = {
  __typename?: 'Mutation';
  deleteClient?: {
    __typename?: 'DeleteClientPayload';
    clientMutationId?: string | null;
    client?: { __typename?: 'Client'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetClientHouseholdMemberCandidatesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetClientHouseholdMemberCandidatesQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    lockVersion: number;
    enrollments: {
      __typename?: 'EnrollmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Enrollment';
        id: string;
        project: {
          __typename?: 'Project';
          id: string;
          projectName: string;
          projectType?: ProjectType | null;
        };
        household: {
          __typename?: 'Household';
          id: string;
          householdClients: Array<{
            __typename?: 'HouseholdClient';
            id: string;
            relationshipToHoH: RelationshipToHoH;
            client: {
              __typename?: 'Client';
              id: string;
              lockVersion: number;
              firstName?: string | null;
              middleName?: string | null;
              lastName?: string | null;
              nameSuffix?: string | null;
              dob?: string | null;
              age?: number | null;
              gender: Array<Gender>;
              pronouns: Array<string>;
              ssn?: string | null;
              race: Array<Race>;
              veteranStatus: NoYesReasonsForMissingData;
              access: {
                __typename?: 'ClientAccess';
                id: string;
                canEditClient: boolean;
                canDeleteClient: boolean;
                canViewDob: boolean;
                canViewFullSsn: boolean;
                canViewPartialSsn: boolean;
                canViewClientName: boolean;
                canViewClientPhoto: boolean;
                canViewClientAlerts: boolean;
                canManageClientAlerts: boolean;
                canViewEnrollmentDetails: boolean;
                canAuditClients: boolean;
                canManageScanCards: boolean;
                canMergeClients: boolean;
                canViewAnyFiles: boolean;
                canManageAnyClientFiles: boolean;
                canManageOwnClientFiles: boolean;
                canUploadClientFiles: boolean;
              };
              externalIds: Array<{
                __typename?: 'ExternalIdentifier';
                id: string;
                identifier?: string | null;
                url?: string | null;
                label: string;
                type: ExternalIdentifierType;
              }>;
              alerts: Array<{
                __typename?: 'ClientAlert';
                id: string;
                note: string;
                expirationDate?: string | null;
                createdAt: string;
                priority: ClientAlertPriorityLevel;
                createdBy?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              }>;
            };
            enrollment: {
              __typename?: 'Enrollment';
              id: string;
              lockVersion: number;
              relationshipToHoH: RelationshipToHoH;
              autoExited: boolean;
              entryDate: string;
              exitDate?: string | null;
              inProgress: boolean;
              currentUnit?: {
                __typename?: 'Unit';
                id: string;
                name: string;
              } | null;
            };
          }>;
        };
      }>;
    };
  } | null;
};

export type GetFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFileQuery = {
  __typename?: 'Query';
  file?: {
    __typename?: 'File';
    confidential?: boolean | null;
    contentType?: string | null;
    effectiveDate?: string | null;
    expirationDate?: string | null;
    id: string;
    name: string;
    url?: string | null;
    tags: Array<string>;
    ownFile: boolean;
    redacted: boolean;
    enrollmentId?: string | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    enrollment?: { __typename?: 'Enrollment'; id: string } | null;
    uploadedBy?: {
      __typename?: 'ApplicationUser';
      id: string;
      name: string;
    } | null;
    updatedBy?: {
      __typename?: 'ApplicationUser';
      id: string;
      name: string;
    } | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
};

export type GetClientFilesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetClientFilesQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    files: {
      __typename?: 'FilesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          entryDate: string;
          exitDate?: string | null;
          autoExited: boolean;
          moveInDate?: string | null;
          lastBedNightDate?: string | null;
          projectName: string;
          organizationName: string;
          projectType?: ProjectType | null;
          inProgress: boolean;
          relationshipToHoH: RelationshipToHoH;
          access: {
            __typename?: 'EnrollmentAccess';
            id: string;
            canViewEnrollmentDetails: boolean;
          };
        } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type ClientDetailFormsQueryVariables = Exact<{ [key: string]: never }>;

export type ClientDetailFormsQuery = {
  __typename?: 'Query';
  clientDetailForms: Array<{
    __typename?: 'OccurrencePointForm';
    id: string;
    dataCollectedAbout: DataCollectedAbout;
    definition: {
      __typename?: 'FormDefinition';
      id: string;
      role: FormRole;
      title: string;
      cacheKey: string;
      identifier: string;
      status: FormStatus;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      definition: {
        __typename?: 'FormDefinitionJson';
        item: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                item?: Array<{
                  __typename: 'FormItem';
                  linkId: string;
                  type: ItemType;
                  component?: Component | null;
                  prefix?: string | null;
                  text?: string | null;
                  briefText?: string | null;
                  readonlyText?: string | null;
                  helperText?: string | null;
                  required?: boolean | null;
                  warnIfEmpty?: boolean | null;
                  hidden?: boolean | null;
                  readOnly?: boolean | null;
                  repeats?: boolean | null;
                  pickListReference?: string | null;
                  size?: InputSize | null;
                  assessmentDate?: boolean | null;
                  prefill?: boolean | null;
                  dataCollectedAbout?: DataCollectedAbout | null;
                  disabledDisplay?: DisabledDisplay | null;
                  enableBehavior?: EnableBehavior | null;
                  rule?: any | null;
                  customRule?: any | null;
                  editorUserIds?: Array<string> | null;
                  mapping?: {
                    __typename?: 'FieldMapping';
                    recordType?: RelatedRecordType | null;
                    fieldName?: string | null;
                    customFieldKey?: string | null;
                  } | null;
                  bounds?: Array<{
                    __typename?: 'ValueBound';
                    id: string;
                    severity: ValidationSeverity;
                    type: BoundType;
                    question?: string | null;
                    valueNumber?: number | null;
                    valueDate?: string | null;
                    valueLocalConstant?: string | null;
                    offset?: number | null;
                  }> | null;
                  pickListOptions?: Array<{
                    __typename?: 'PickListOption';
                    code: string;
                    label?: string | null;
                    secondaryLabel?: string | null;
                    groupLabel?: string | null;
                    groupCode?: string | null;
                    initialSelected?: boolean | null;
                    helperText?: string | null;
                    numericValue?: number | null;
                  }> | null;
                  initial?: Array<{
                    __typename?: 'InitialValue';
                    valueCode?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    valueLocalConstant?: string | null;
                    initialBehavior: InitialBehavior;
                  }> | null;
                  enableWhen?: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }> | null;
                  autofillValues?: Array<{
                    __typename?: 'AutofillValue';
                    valueCode?: string | null;
                    valueQuestion?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    sumQuestions?: Array<string> | null;
                    formula?: string | null;
                    autofillBehavior: EnableBehavior;
                    autofillReadonly?: boolean | null;
                    autofillWhen: Array<{
                      __typename?: 'EnableWhen';
                      question?: string | null;
                      localConstant?: string | null;
                      operator: EnableOperator;
                      answerCode?: string | null;
                      answerCodes?: Array<string> | null;
                      answerNumber?: number | null;
                      answerBoolean?: boolean | null;
                      answerGroupCode?: string | null;
                      answerDate?: string | null;
                      compareQuestion?: string | null;
                    }>;
                  }> | null;
                }> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }>;
      };
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    };
  }>;
};

export type MergeAuditEventFieldsFragment = {
  __typename?: 'MergeAuditEvent';
  id: string;
  mergedAt: string;
  clientIdsMerged: Array<string>;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type GetMergeCandidatesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetMergeCandidatesQuery = {
  __typename?: 'Query';
  mergeCandidates: {
    __typename?: 'ClientMergeCandidatesPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'ClientMergeCandidate';
      id: string;
      warehouseUrl: string;
      clients: Array<{
        __typename?: 'Client';
        id: string;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
        dob?: string | null;
        age?: number | null;
        gender: Array<Gender>;
        pronouns: Array<string>;
        ssn?: string | null;
        access: {
          __typename?: 'ClientAccess';
          id: string;
          canViewFullSsn: boolean;
          canViewPartialSsn: boolean;
        };
      }>;
    }>;
  };
};

export type ClientMergeHistoryQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ClientMergeHistoryQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    mergeAuditHistory: {
      __typename?: 'MergeAuditEventsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'MergeAuditEvent';
        id: string;
        mergedAt: string;
        clientIdsMerged: Array<string>;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type GlobalClientMergeHistoryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<MergeAuditEventFilterOptions>;
}>;

export type GlobalClientMergeHistoryQuery = {
  __typename?: 'Query';
  mergeAuditHistory: {
    __typename?: 'MergeAuditEventsPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'MergeAuditEvent';
      id: string;
      mergedAt: string;
      clientIdsMerged: Array<string>;
      client?: {
        __typename?: 'Client';
        id: string;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }>;
  };
};

export type MergeClientsMutationVariables = Exact<{
  input: MergeClientsInput;
}>;

export type MergeClientsMutation = {
  __typename?: 'Mutation';
  mergeClients?: {
    __typename?: 'MergeClientsPayload';
    client?: {
      __typename?: 'Client';
      dobDataQuality: DobDataQuality;
      gender: Array<Gender>;
      differentIdentityText?: string | null;
      pronouns: Array<string>;
      nameDataQuality: NameDataQuality;
      personalId: string;
      race: Array<Race>;
      additionalRaceEthnicity?: string | null;
      ssnDataQuality: SsnDataQuality;
      veteranStatus: NoYesReasonsForMissingData;
      dateCreated?: string | null;
      dateDeleted?: string | null;
      dateUpdated?: string | null;
      enabledFeatures: Array<ClientDashboardFeature>;
      id: string;
      lockVersion: number;
      dob?: string | null;
      age?: number | null;
      ssn?: string | null;
      yearEnteredService?: number | null;
      yearSeparated?: number | null;
      worldWarIi?: NoYesReasonsForMissingData | null;
      koreanWar?: NoYesReasonsForMissingData | null;
      vietnamWar?: NoYesReasonsForMissingData | null;
      desertStorm?: NoYesReasonsForMissingData | null;
      afghanistanOef?: NoYesReasonsForMissingData | null;
      iraqOif?: NoYesReasonsForMissingData | null;
      iraqOnd?: NoYesReasonsForMissingData | null;
      otherTheater?: NoYesReasonsForMissingData | null;
      militaryBranch?: MilitaryBranch | null;
      dischargeStatus?: DischargeStatus | null;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
      externalIds: Array<{
        __typename?: 'ExternalIdentifier';
        id: string;
        identifier?: string | null;
        url?: string | null;
        label: string;
        type: ExternalIdentifierType;
      }>;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      access: {
        __typename?: 'ClientAccess';
        id: string;
        canViewFullSsn: boolean;
        canViewPartialSsn: boolean;
        canEditClient: boolean;
        canDeleteClient: boolean;
        canViewDob: boolean;
        canViewClientName: boolean;
        canViewClientPhoto: boolean;
        canViewClientAlerts: boolean;
        canManageClientAlerts: boolean;
        canViewEnrollmentDetails: boolean;
        canAuditClients: boolean;
        canManageScanCards: boolean;
        canMergeClients: boolean;
        canViewAnyFiles: boolean;
        canManageAnyClientFiles: boolean;
        canManageOwnClientFiles: boolean;
        canUploadClientFiles: boolean;
      };
      customDataElements: Array<{
        __typename?: 'CustomDataElement';
        id: string;
        key: string;
        label: string;
        fieldType: CustomDataElementType;
        repeats: boolean;
        displayHooks: Array<DisplayHook>;
        value?: {
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        values?: Array<{
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }> | null;
      }>;
      names: Array<{
        __typename?: 'ClientName';
        id: string;
        first?: string | null;
        middle?: string | null;
        last?: string | null;
        suffix?: string | null;
        nameDataQuality?: NameDataQuality | null;
        use?: ClientNameUse | null;
        notes?: string | null;
        primary?: boolean | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
      }>;
      addresses: Array<{
        __typename?: 'ClientAddress';
        id: string;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        state?: string | null;
        district?: string | null;
        country?: string | null;
        postalCode?: string | null;
        notes?: string | null;
        use?: ClientAddressUse | null;
        addressType?: ClientAddressType | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
      }>;
      phoneNumbers: Array<{
        __typename?: 'ClientContactPoint';
        id: string;
        value?: string | null;
        notes?: string | null;
        use?: ClientContactPointUse | null;
        system?: ClientContactPointSystem | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
      }>;
      emailAddresses: Array<{
        __typename?: 'ClientContactPoint';
        id: string;
        value?: string | null;
        notes?: string | null;
        use?: ClientContactPointUse | null;
        system?: ClientContactPointSystem | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
      }>;
      alerts: Array<{
        __typename?: 'ClientAlert';
        id: string;
        note: string;
        expirationDate?: string | null;
        createdAt: string;
        priority: ClientAlertPriorityLevel;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type BulkMergeClientsMutationVariables = Exact<{
  input: BulkMergeClientsInput;
}>;

export type BulkMergeClientsMutation = {
  __typename?: 'Mutation';
  bulkMergeClients?: {
    __typename?: 'BulkMergeClientsPayload';
    success?: boolean | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type FormRuleFieldsFragment = {
  __typename?: 'FormRule';
  id: string;
  active: boolean;
  activeStatus: ActiveStatus;
  system: boolean;
  definitionIdentifier: string;
  definitionId?: string | null;
  definitionTitle?: string | null;
  definitionRole?: FormRole | null;
  dataCollectedAbout?: DataCollectedAbout | null;
  funder?: FundingSource | null;
  otherFunder?: string | null;
  projectType?: ProjectType | null;
  projectId?: string | null;
  organizationId?: string | null;
  createdAt: string;
  updatedAt: string;
  project?: {
    __typename?: 'Project';
    id: string;
    projectName: string;
    projectType?: ProjectType | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    hudId: string;
    organizationName: string;
  } | null;
  serviceCategory?: {
    __typename?: 'ServiceCategory';
    id: string;
    name: string;
  } | null;
  serviceType?: {
    __typename?: 'ServiceType';
    id: string;
    name: string;
    hud: boolean;
    hudRecordType?: RecordType | null;
    hudTypeProvided?: ServiceTypeProvided | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    supportsBulkAssignment: boolean;
    serviceCategory: {
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
    };
  } | null;
};

export type FormProjectMatchFieldsFragment = {
  __typename?: 'FormProjectMatch';
  id: string;
  projectName: string;
  organizationName: string;
  dataCollectedAbout: DataCollectedAbout;
};

export type ServiceTypeConfigFieldsFragment = {
  __typename?: 'ServiceType';
  id: string;
  name: string;
  hud: boolean;
  hudRecordType?: RecordType | null;
  hudTypeProvided?: ServiceTypeProvided | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  supportsBulkAssignment: boolean;
  formDefinitions: Array<{
    __typename?: 'FormDefinition';
    id: string;
    identifier: string;
    cacheKey: string;
    title: string;
  }>;
  serviceCategory: { __typename?: 'ServiceCategory'; id: string; name: string };
};

export type CreateServiceTypeMutationVariables = Exact<{
  input: ServiceTypeInput;
}>;

export type CreateServiceTypeMutation = {
  __typename?: 'Mutation';
  createServiceType?: {
    __typename?: 'CreateServiceTypePayload';
    serviceType?: {
      __typename?: 'ServiceType';
      id: string;
      name: string;
      hud: boolean;
      hudRecordType?: RecordType | null;
      hudTypeProvided?: ServiceTypeProvided | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      supportsBulkAssignment: boolean;
      serviceCategory: {
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
      };
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteServiceTypeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteServiceTypeMutation = {
  __typename?: 'Mutation';
  deleteServiceType?: {
    __typename?: 'DeleteServiceTypePayload';
    serviceType?: { __typename?: 'ServiceType'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type UpdateServiceTypeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<ServiceTypeInput>;
}>;

export type UpdateServiceTypeMutation = {
  __typename?: 'Mutation';
  updateServiceType?: {
    __typename?: 'UpdateServiceTypePayload';
    serviceType?: {
      __typename?: 'ServiceType';
      id: string;
      name: string;
      hud: boolean;
      hudRecordType?: RecordType | null;
      hudTypeProvided?: ServiceTypeProvided | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      supportsBulkAssignment: boolean;
      serviceCategory: {
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
      };
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetFormRulesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<FormRuleFilterOptions>;
  sortOrder?: InputMaybe<FormRuleSortOption>;
}>;

export type GetFormRulesQuery = {
  __typename?: 'Query';
  formRules: {
    __typename?: 'FormRulesPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'FormRule';
      id: string;
      active: boolean;
      activeStatus: ActiveStatus;
      system: boolean;
      definitionIdentifier: string;
      definitionId?: string | null;
      definitionTitle?: string | null;
      definitionRole?: FormRole | null;
      dataCollectedAbout?: DataCollectedAbout | null;
      funder?: FundingSource | null;
      otherFunder?: string | null;
      projectType?: ProjectType | null;
      projectId?: string | null;
      organizationId?: string | null;
      createdAt: string;
      updatedAt: string;
      project?: {
        __typename?: 'Project';
        id: string;
        projectName: string;
        projectType?: ProjectType | null;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        hudId: string;
        organizationName: string;
      } | null;
      serviceCategory?: {
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
      } | null;
      serviceType?: {
        __typename?: 'ServiceType';
        id: string;
        name: string;
        hud: boolean;
        hudRecordType?: RecordType | null;
        hudTypeProvided?: ServiceTypeProvided | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        supportsBulkAssignment: boolean;
        serviceCategory: {
          __typename?: 'ServiceCategory';
          id: string;
          name: string;
        };
      } | null;
    }>;
  };
};

export type GetFormProjectMatchesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetFormProjectMatchesQuery = {
  __typename?: 'Query';
  formDefinition?: {
    __typename?: 'FormDefinition';
    id: string;
    cacheKey: string;
    projectMatches: {
      __typename?: 'FormProjectMatchesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'FormProjectMatch';
        id: string;
        projectName: string;
        organizationName: string;
        dataCollectedAbout: DataCollectedAbout;
      }>;
    };
  } | null;
};

export type GetServiceCategoryRulesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<FormRuleFilterOptions>;
  sortOrder?: InputMaybe<FormRuleSortOption>;
}>;

export type GetServiceCategoryRulesQuery = {
  __typename?: 'Query';
  serviceCategory?: {
    __typename?: 'ServiceCategory';
    id: string;
    formRules: {
      __typename?: 'FormRulesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'FormRule';
        id: string;
        active: boolean;
        activeStatus: ActiveStatus;
        system: boolean;
        definitionIdentifier: string;
        definitionId?: string | null;
        definitionTitle?: string | null;
        definitionRole?: FormRole | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        funder?: FundingSource | null;
        otherFunder?: string | null;
        projectType?: ProjectType | null;
        projectId?: string | null;
        organizationId?: string | null;
        createdAt: string;
        updatedAt: string;
        project?: {
          __typename?: 'Project';
          id: string;
          projectName: string;
          projectType?: ProjectType | null;
        } | null;
        organization?: {
          __typename?: 'Organization';
          id: string;
          hudId: string;
          organizationName: string;
        } | null;
        serviceCategory?: {
          __typename?: 'ServiceCategory';
          id: string;
          name: string;
        } | null;
        serviceType?: {
          __typename?: 'ServiceType';
          id: string;
          name: string;
          hud: boolean;
          hudRecordType?: RecordType | null;
          hudTypeProvided?: ServiceTypeProvided | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          supportsBulkAssignment: boolean;
          serviceCategory: {
            __typename?: 'ServiceCategory';
            id: string;
            name: string;
          };
        } | null;
      }>;
    };
  } | null;
};

export type GetFormRuleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFormRuleQuery = {
  __typename?: 'Query';
  formRule?: {
    __typename?: 'FormRule';
    id: string;
    active: boolean;
    activeStatus: ActiveStatus;
    system: boolean;
    definitionIdentifier: string;
    definitionId?: string | null;
    definitionTitle?: string | null;
    definitionRole?: FormRole | null;
    dataCollectedAbout?: DataCollectedAbout | null;
    funder?: FundingSource | null;
    otherFunder?: string | null;
    projectType?: ProjectType | null;
    projectId?: string | null;
    organizationId?: string | null;
    createdAt: string;
    updatedAt: string;
    project?: {
      __typename?: 'Project';
      id: string;
      projectName: string;
      projectType?: ProjectType | null;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      hudId: string;
      organizationName: string;
    } | null;
    serviceCategory?: {
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
    } | null;
    serviceType?: {
      __typename?: 'ServiceType';
      id: string;
      name: string;
      hud: boolean;
      hudRecordType?: RecordType | null;
      hudTypeProvided?: ServiceTypeProvided | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      supportsBulkAssignment: boolean;
      serviceCategory: {
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
      };
    } | null;
  } | null;
};

export type CreateFormRuleMutationVariables = Exact<{
  input: CreateFormRuleInput;
}>;

export type CreateFormRuleMutation = {
  __typename?: 'Mutation';
  createFormRule?: {
    __typename?: 'CreateFormRulePayload';
    clientMutationId?: string | null;
    formRule?: {
      __typename?: 'FormRule';
      id: string;
      active: boolean;
      activeStatus: ActiveStatus;
      system: boolean;
      definitionIdentifier: string;
      definitionId?: string | null;
      definitionTitle?: string | null;
      definitionRole?: FormRole | null;
      dataCollectedAbout?: DataCollectedAbout | null;
      funder?: FundingSource | null;
      otherFunder?: string | null;
      projectType?: ProjectType | null;
      projectId?: string | null;
      organizationId?: string | null;
      createdAt: string;
      updatedAt: string;
      project?: {
        __typename?: 'Project';
        id: string;
        projectName: string;
        projectType?: ProjectType | null;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        hudId: string;
        organizationName: string;
      } | null;
      serviceCategory?: {
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
      } | null;
      serviceType?: {
        __typename?: 'ServiceType';
        id: string;
        name: string;
        hud: boolean;
        hudRecordType?: RecordType | null;
        hudTypeProvided?: ServiceTypeProvided | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        supportsBulkAssignment: boolean;
        serviceCategory: {
          __typename?: 'ServiceCategory';
          id: string;
          name: string;
        };
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeactivateFormRuleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeactivateFormRuleMutation = {
  __typename?: 'Mutation';
  deleteFormRule?: {
    __typename?: 'DeleteFormRulePayload';
    formRule?: { __typename?: 'FormRule'; id: string; active: boolean } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetServiceTypesQueryVariables = Exact<{
  filters?: InputMaybe<ServiceTypeFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetServiceTypesQuery = {
  __typename?: 'Query';
  serviceTypes: {
    __typename?: 'ServiceTypesPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'ServiceType';
      id: string;
      name: string;
      hud: boolean;
      hudRecordType?: RecordType | null;
      hudTypeProvided?: ServiceTypeProvided | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      supportsBulkAssignment: boolean;
      formDefinitions: Array<{
        __typename?: 'FormDefinition';
        id: string;
        identifier: string;
        cacheKey: string;
        title: string;
      }>;
      serviceCategory: {
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
      };
    }>;
  };
};

export type GetServiceCategoriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetServiceCategoriesQuery = {
  __typename?: 'Query';
  serviceCategories: {
    __typename?: 'ServiceCategoriesPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
      serviceTypes: {
        __typename?: 'ServiceTypesPaginated';
        offset: number;
        limit: number;
        nodesCount: number;
        nodes: Array<{ __typename?: 'ServiceType'; id: string; name: string }>;
      };
    }>;
  };
};

export type GetServiceTypeDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetServiceTypeDetailsQuery = {
  __typename?: 'Query';
  serviceType?: {
    __typename?: 'ServiceType';
    id: string;
    name: string;
    hud: boolean;
    hudRecordType?: RecordType | null;
    hudTypeProvided?: ServiceTypeProvided | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    supportsBulkAssignment: boolean;
    formDefinitions: Array<{
      __typename?: 'FormDefinition';
      id: string;
      identifier: string;
      cacheKey: string;
      title: string;
    }>;
    serviceCategory: {
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
    };
  } | null;
};

export type CurrentLivingSituationFieldsFragment = {
  __typename?: 'CurrentLivingSituation';
  id: string;
  clsSubsidyType?: RentalSubsidyType | null;
  currentLivingSituation: CurrentLivingSituationOptions;
  informationDate?: string | null;
  leaseOwn60Day?: NoYesReasonsForMissingData | null;
  leaveSituation14Days?: NoYesReasonsForMissingData | null;
  locationDetails?: string | null;
  movedTwoOrMore?: NoYesReasonsForMissingData | null;
  resourcesToObtain?: NoYesReasonsForMissingData | null;
  subsequentResidence?: NoYesReasonsForMissingData | null;
  dateUpdated?: string | null;
  dateCreated?: string | null;
  verifiedByProjectId?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
  geolocation?: {
    __typename?: 'Geolocation';
    id: string;
    coordinates: {
      __typename?: 'GeolocationCoordinates';
      id: string;
      latitude: string;
      longitude: string;
    };
  } | null;
};

export type ProjectCurrentLivingSituationFieldsFragment = {
  __typename?: 'CurrentLivingSituation';
  id: string;
  clsSubsidyType?: RentalSubsidyType | null;
  currentLivingSituation: CurrentLivingSituationOptions;
  informationDate?: string | null;
  leaseOwn60Day?: NoYesReasonsForMissingData | null;
  leaveSituation14Days?: NoYesReasonsForMissingData | null;
  locationDetails?: string | null;
  movedTwoOrMore?: NoYesReasonsForMissingData | null;
  resourcesToObtain?: NoYesReasonsForMissingData | null;
  subsequentResidence?: NoYesReasonsForMissingData | null;
  dateUpdated?: string | null;
  dateCreated?: string | null;
  verifiedByProjectId?: string | null;
  client: {
    __typename?: 'Client';
    id: string;
    lockVersion: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
  };
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    entryDate: string;
    exitDate?: string | null;
    inProgress: boolean;
  };
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
  geolocation?: {
    __typename?: 'Geolocation';
    id: string;
    coordinates: {
      __typename?: 'GeolocationCoordinates';
      id: string;
      latitude: string;
      longitude: string;
    };
  } | null;
};

export type GetEnrollmentCurrentLivingSituationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetEnrollmentCurrentLivingSituationsQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    currentLivingSituations: {
      __typename?: 'CurrentLivingSituationsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'CurrentLivingSituation';
        id: string;
        clsSubsidyType?: RentalSubsidyType | null;
        currentLivingSituation: CurrentLivingSituationOptions;
        informationDate?: string | null;
        leaseOwn60Day?: NoYesReasonsForMissingData | null;
        leaveSituation14Days?: NoYesReasonsForMissingData | null;
        locationDetails?: string | null;
        movedTwoOrMore?: NoYesReasonsForMissingData | null;
        resourcesToObtain?: NoYesReasonsForMissingData | null;
        subsequentResidence?: NoYesReasonsForMissingData | null;
        dateUpdated?: string | null;
        dateCreated?: string | null;
        verifiedByProjectId?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
        geolocation?: {
          __typename?: 'Geolocation';
          id: string;
          coordinates: {
            __typename?: 'GeolocationCoordinates';
            id: string;
            latitude: string;
            longitude: string;
          };
        } | null;
      }>;
    };
  } | null;
};

export type GetProjectCurrentLivingSituationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectCurrentLivingSituationsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    currentLivingSituations: {
      __typename?: 'CurrentLivingSituationsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'CurrentLivingSituation';
        id: string;
        clsSubsidyType?: RentalSubsidyType | null;
        currentLivingSituation: CurrentLivingSituationOptions;
        informationDate?: string | null;
        leaseOwn60Day?: NoYesReasonsForMissingData | null;
        leaveSituation14Days?: NoYesReasonsForMissingData | null;
        locationDetails?: string | null;
        movedTwoOrMore?: NoYesReasonsForMissingData | null;
        resourcesToObtain?: NoYesReasonsForMissingData | null;
        subsequentResidence?: NoYesReasonsForMissingData | null;
        dateUpdated?: string | null;
        dateCreated?: string | null;
        verifiedByProjectId?: string | null;
        client: {
          __typename?: 'Client';
          id: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
        };
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          entryDate: string;
          exitDate?: string | null;
          inProgress: boolean;
        };
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
        geolocation?: {
          __typename?: 'Geolocation';
          id: string;
          coordinates: {
            __typename?: 'GeolocationCoordinates';
            id: string;
            latitude: string;
            longitude: string;
          };
        } | null;
      }>;
    };
  } | null;
};

export type CustomCaseNoteFieldsFragment = {
  __typename?: 'CustomCaseNote';
  id: string;
  content: string;
  informationDate?: string | null;
  dateUpdated?: string | null;
  dateCreated?: string | null;
  formDefinitionId?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
};

export type GetEnrollmentCustomCaseNotesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<CustomCaseNoteSortOption>;
}>;

export type GetEnrollmentCustomCaseNotesQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    customCaseNotes: {
      __typename?: 'CustomCaseNotesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'CustomCaseNote';
        id: string;
        content: string;
        informationDate?: string | null;
        dateUpdated?: string | null;
        dateCreated?: string | null;
        formDefinitionId?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
      }>;
    };
  } | null;
};

export type DeleteCustomCaseNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCustomCaseNoteMutation = {
  __typename?: 'Mutation';
  deleteCustomCaseNote?: {
    __typename?: 'DeleteCustomCaseNotePayload';
    customCaseNote?: { __typename?: 'CustomCaseNote'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetClientCaseNotesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<CustomCaseNoteSortOption>;
}>;

export type GetClientCaseNotesQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    customCaseNotes: {
      __typename?: 'CustomCaseNotesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'CustomCaseNote';
        id: string;
        content: string;
        informationDate?: string | null;
        dateUpdated?: string | null;
        dateCreated?: string | null;
        formDefinitionId?: string | null;
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          entryDate: string;
          exitDate?: string | null;
          autoExited: boolean;
          moveInDate?: string | null;
          lastBedNightDate?: string | null;
          projectName: string;
          organizationName: string;
          projectType?: ProjectType | null;
          inProgress: boolean;
          relationshipToHoH: RelationshipToHoH;
          access: {
            __typename?: 'EnrollmentAccess';
            id: string;
            canViewEnrollmentDetails: boolean;
          };
        };
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
      }>;
    };
  } | null;
};

export type CustomDataElementValueFieldsFragment = {
  __typename?: 'CustomDataElementValue';
  id: string;
  valueBoolean?: boolean | null;
  valueDate?: string | null;
  valueFloat?: number | null;
  valueInteger?: number | null;
  valueJson?: any | null;
  valueString?: string | null;
  valueText?: string | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  valueFile?: {
    __typename?: 'File';
    confidential?: boolean | null;
    contentType?: string | null;
    effectiveDate?: string | null;
    expirationDate?: string | null;
    id: string;
    name: string;
    url?: string | null;
    tags: Array<string>;
    ownFile: boolean;
    redacted: boolean;
    enrollmentId?: string | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    enrollment?: { __typename?: 'Enrollment'; id: string } | null;
    uploadedBy?: {
      __typename?: 'ApplicationUser';
      id: string;
      name: string;
    } | null;
    updatedBy?: {
      __typename?: 'ApplicationUser';
      id: string;
      name: string;
    } | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type CustomDataElementFieldsFragment = {
  __typename?: 'CustomDataElement';
  id: string;
  key: string;
  label: string;
  fieldType: CustomDataElementType;
  repeats: boolean;
  displayHooks: Array<DisplayHook>;
  value?: {
    __typename?: 'CustomDataElementValue';
    id: string;
    valueBoolean?: boolean | null;
    valueDate?: string | null;
    valueFloat?: number | null;
    valueInteger?: number | null;
    valueJson?: any | null;
    valueString?: string | null;
    valueText?: string | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    valueFile?: {
      __typename?: 'File';
      confidential?: boolean | null;
      contentType?: string | null;
      effectiveDate?: string | null;
      expirationDate?: string | null;
      id: string;
      name: string;
      url?: string | null;
      tags: Array<string>;
      ownFile: boolean;
      redacted: boolean;
      enrollmentId?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      enrollment?: { __typename?: 'Enrollment'; id: string } | null;
      uploadedBy?: {
        __typename?: 'ApplicationUser';
        id: string;
        name: string;
      } | null;
      updatedBy?: {
        __typename?: 'ApplicationUser';
        id: string;
        name: string;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
  values?: Array<{
    __typename?: 'CustomDataElementValue';
    id: string;
    valueBoolean?: boolean | null;
    valueDate?: string | null;
    valueFloat?: number | null;
    valueInteger?: number | null;
    valueJson?: any | null;
    valueString?: string | null;
    valueText?: string | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    valueFile?: {
      __typename?: 'File';
      confidential?: boolean | null;
      contentType?: string | null;
      effectiveDate?: string | null;
      expirationDate?: string | null;
      id: string;
      name: string;
      url?: string | null;
      tags: Array<string>;
      ownFile: boolean;
      redacted: boolean;
      enrollmentId?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      enrollment?: { __typename?: 'Enrollment'; id: string } | null;
      uploadedBy?: {
        __typename?: 'ApplicationUser';
        id: string;
        name: string;
      } | null;
      updatedBy?: {
        __typename?: 'ApplicationUser';
        id: string;
        name: string;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  }> | null;
};

export type ProjectEnrollmentFieldsFragment = {
  __typename?: 'Enrollment';
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  autoExited: boolean;
  inProgress: boolean;
  relationshipToHoH: RelationshipToHoH;
  enrollmentCoc?: string | null;
  householdId: string;
  householdShortId: string;
  householdSize: number;
  client: {
    __typename?: 'Client';
    id: string;
    dob?: string | null;
    veteranStatus: NoYesReasonsForMissingData;
    lockVersion: number;
    age?: number | null;
    gender: Array<Gender>;
    pronouns: Array<string>;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
  };
};

export type ClientEnrollmentFieldsFragment = {
  __typename?: 'Enrollment';
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  autoExited: boolean;
  moveInDate?: string | null;
  lastBedNightDate?: string | null;
  projectName: string;
  organizationName: string;
  projectType?: ProjectType | null;
  inProgress: boolean;
  relationshipToHoH: RelationshipToHoH;
  access: {
    __typename?: 'EnrollmentAccess';
    id: string;
    canViewEnrollmentDetails: boolean;
  };
};

export type EnrollmentFieldsFragment = {
  __typename?: 'Enrollment';
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  exitDestination?: Destination | null;
  autoExited: boolean;
  inProgress: boolean;
  relationshipToHoH: RelationshipToHoH;
  enrollmentCoc?: string | null;
  householdId: string;
  householdShortId: string;
  householdSize: number;
  project: {
    __typename?: 'Project';
    id: string;
    projectName: string;
    projectType?: ProjectType | null;
  };
  client: {
    __typename?: 'Client';
    dob?: string | null;
    veteranStatus: NoYesReasonsForMissingData;
    id: string;
    lockVersion: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
  };
  access: {
    __typename?: 'EnrollmentAccess';
    id: string;
    canEditEnrollments: boolean;
    canDeleteEnrollments: boolean;
    canAuditEnrollments: boolean;
    canViewEnrollmentLocationMap: boolean;
  };
  currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
};

export type AssessedClientFieldsFragment = {
  __typename?: 'Client';
  ssn?: string | null;
  race: Array<Race>;
  dob?: string | null;
  veteranStatus: NoYesReasonsForMissingData;
  id: string;
  lockVersion: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  nameSuffix?: string | null;
};

export type EnrolledClientFieldsFragment = {
  __typename?: 'Client';
  hudChronic?: boolean | null;
  ssn?: string | null;
  race: Array<Race>;
  dob?: string | null;
  veteranStatus: NoYesReasonsForMissingData;
  id: string;
  lockVersion: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  nameSuffix?: string | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
  access: { __typename?: 'ClientAccess'; id: string; canEditClient: boolean };
};

export type AllEnrollmentDetailsFragment = {
  __typename?: 'Enrollment';
  numUnitsAssignedToHousehold: number;
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  exitDestination?: Destination | null;
  autoExited: boolean;
  inProgress: boolean;
  relationshipToHoH: RelationshipToHoH;
  enrollmentCoc?: string | null;
  householdId: string;
  householdShortId: string;
  householdSize: number;
  dateOfEngagement?: string | null;
  moveInDate?: string | null;
  livingSituation?: PriorLivingSituation | null;
  dateOfPathStatus?: string | null;
  clientEnrolledInPath?: NoYesMissing | null;
  reasonNotEnrolled?: ReasonNotEnrolled | null;
  disablingCondition?: NoYesReasonsForMissingData | null;
  translationNeeded?: NoYesReasonsForMissingData | null;
  preferredLanguage?: PreferredLanguage | null;
  preferredLanguageDifferent?: string | null;
  intakeAssessment?: { __typename?: 'Assessment'; id: string } | null;
  exitAssessment?: { __typename?: 'Assessment'; id: string } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
  client: {
    __typename?: 'Client';
    hudChronic?: boolean | null;
    dob?: string | null;
    veteranStatus: NoYesReasonsForMissingData;
    ssn?: string | null;
    race: Array<Race>;
    id: string;
    lockVersion: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
    access: { __typename?: 'ClientAccess'; id: string; canEditClient: boolean };
  };
  openEnrollmentSummary: Array<{
    __typename?: 'EnrollmentSummary';
    id: string;
    entryDate: string;
    inProgress: boolean;
    moveInDate?: string | null;
    projectId: string;
    projectName: string;
    projectType: ProjectType;
    canViewEnrollment: boolean;
  }>;
  dataCollectionFeatures: Array<{
    __typename?: 'DataCollectionFeature';
    id: string;
    role: DataCollectionFeatureRole;
    dataCollectedAbout: DataCollectedAbout;
    legacy: boolean;
  }>;
  occurrencePointForms: Array<{
    __typename?: 'OccurrencePointForm';
    id: string;
    dataCollectedAbout: DataCollectedAbout;
    definition: {
      __typename?: 'FormDefinition';
      id: string;
      role: FormRole;
      title: string;
      cacheKey: string;
      identifier: string;
      status: FormStatus;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      definition: {
        __typename?: 'FormDefinitionJson';
        item: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                item?: Array<{
                  __typename: 'FormItem';
                  linkId: string;
                  type: ItemType;
                  component?: Component | null;
                  prefix?: string | null;
                  text?: string | null;
                  briefText?: string | null;
                  readonlyText?: string | null;
                  helperText?: string | null;
                  required?: boolean | null;
                  warnIfEmpty?: boolean | null;
                  hidden?: boolean | null;
                  readOnly?: boolean | null;
                  repeats?: boolean | null;
                  pickListReference?: string | null;
                  size?: InputSize | null;
                  assessmentDate?: boolean | null;
                  prefill?: boolean | null;
                  dataCollectedAbout?: DataCollectedAbout | null;
                  disabledDisplay?: DisabledDisplay | null;
                  enableBehavior?: EnableBehavior | null;
                  rule?: any | null;
                  customRule?: any | null;
                  editorUserIds?: Array<string> | null;
                  mapping?: {
                    __typename?: 'FieldMapping';
                    recordType?: RelatedRecordType | null;
                    fieldName?: string | null;
                    customFieldKey?: string | null;
                  } | null;
                  bounds?: Array<{
                    __typename?: 'ValueBound';
                    id: string;
                    severity: ValidationSeverity;
                    type: BoundType;
                    question?: string | null;
                    valueNumber?: number | null;
                    valueDate?: string | null;
                    valueLocalConstant?: string | null;
                    offset?: number | null;
                  }> | null;
                  pickListOptions?: Array<{
                    __typename?: 'PickListOption';
                    code: string;
                    label?: string | null;
                    secondaryLabel?: string | null;
                    groupLabel?: string | null;
                    groupCode?: string | null;
                    initialSelected?: boolean | null;
                    helperText?: string | null;
                    numericValue?: number | null;
                  }> | null;
                  initial?: Array<{
                    __typename?: 'InitialValue';
                    valueCode?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    valueLocalConstant?: string | null;
                    initialBehavior: InitialBehavior;
                  }> | null;
                  enableWhen?: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }> | null;
                  autofillValues?: Array<{
                    __typename?: 'AutofillValue';
                    valueCode?: string | null;
                    valueQuestion?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    sumQuestions?: Array<string> | null;
                    formula?: string | null;
                    autofillBehavior: EnableBehavior;
                    autofillReadonly?: boolean | null;
                    autofillWhen: Array<{
                      __typename?: 'EnableWhen';
                      question?: string | null;
                      localConstant?: string | null;
                      operator: EnableOperator;
                      answerCode?: string | null;
                      answerCodes?: Array<string> | null;
                      answerNumber?: number | null;
                      answerBoolean?: boolean | null;
                      answerGroupCode?: string | null;
                      answerDate?: string | null;
                      compareQuestion?: string | null;
                    }>;
                  }> | null;
                }> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }>;
      };
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    };
  }>;
  project: {
    __typename?: 'Project';
    hasUnits: boolean;
    staffAssignmentsEnabled: boolean;
    id: string;
    projectName: string;
    projectType?: ProjectType | null;
    access: {
      __typename?: 'ProjectAccess';
      id: string;
      canSplitHouseholds: boolean;
      canManageIncomingReferrals: boolean;
    };
    projectCocs: { __typename?: 'ProjectCocsPaginated'; nodesCount: number };
  };
  sourceReferralPosting?: {
    __typename?: 'ReferralPosting';
    id: string;
    referredFrom: string;
    referralDate: string;
  } | null;
  access: {
    __typename?: 'EnrollmentAccess';
    id: string;
    canEditEnrollments: boolean;
    canDeleteEnrollments: boolean;
    canAuditEnrollments: boolean;
    canViewEnrollmentLocationMap: boolean;
  };
  currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
  moveInAddresses: Array<{
    __typename?: 'ClientAddress';
    id: string;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    district?: string | null;
    country?: string | null;
    postalCode?: string | null;
    notes?: string | null;
    use?: ClientAddressUse | null;
    addressType?: ClientAddressType | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
  }>;
};

export type EnrollmentOccurrencePointFieldsFragment = {
  __typename?: 'Enrollment';
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  dateOfEngagement?: string | null;
  moveInDate?: string | null;
  livingSituation?: PriorLivingSituation | null;
  enrollmentCoc?: string | null;
  dateOfPathStatus?: string | null;
  clientEnrolledInPath?: NoYesMissing | null;
  reasonNotEnrolled?: ReasonNotEnrolled | null;
  disablingCondition?: NoYesReasonsForMissingData | null;
  translationNeeded?: NoYesReasonsForMissingData | null;
  preferredLanguage?: PreferredLanguage | null;
  preferredLanguageDifferent?: string | null;
  moveInAddresses: Array<{
    __typename?: 'ClientAddress';
    id: string;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    district?: string | null;
    country?: string | null;
    postalCode?: string | null;
    notes?: string | null;
    use?: ClientAddressUse | null;
    addressType?: ClientAddressType | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
  }>;
};

export type EnrollmentValuesFragment = {
  __typename?: 'Enrollment';
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  disablingCondition?: NoYesReasonsForMissingData | null;
  dateOfEngagement?: string | null;
  moveInDate?: string | null;
  livingSituation?: PriorLivingSituation | null;
  rentalSubsidyType?: RentalSubsidyType | null;
  lengthOfStay?: ResidencePriorLengthOfStay | null;
  losUnderThreshold?: NoYesMissing | null;
  previousStreetEssh?: NoYesMissing | null;
  dateToStreetEssh?: string | null;
  timesHomelessPastThreeYears?: TimesHomelessPastThreeYears | null;
  monthsHomelessPastThreeYears?: MonthsHomelessPastThreeYears | null;
  enrollmentCoc?: string | null;
  dateOfPathStatus?: string | null;
  clientEnrolledInPath?: NoYesMissing | null;
  reasonNotEnrolled?: ReasonNotEnrolled | null;
  percentAmi?: PercentAmi | null;
  referralSource?: ReferralSource | null;
  countOutreachReferralApproaches?: number | null;
  dateOfBcpStatus?: string | null;
  eligibleForRhy?: NoYesMissing | null;
  reasonNoServices?: ReasonNoServices | null;
  runawayYouth?: NoYesReasonsForMissingData | null;
  sexualOrientation?: SexualOrientation | null;
  sexualOrientationOther?: string | null;
  formerWardChildWelfare?: NoYesReasonsForMissingData | null;
  childWelfareYears?: RhyNumberofYears | null;
  childWelfareMonths?: number | null;
  formerWardJuvenileJustice?: NoYesReasonsForMissingData | null;
  juvenileJusticeYears?: RhyNumberofYears | null;
  juvenileJusticeMonths?: number | null;
  unemploymentFam?: NoYesMissing | null;
  mentalHealthDisorderFam?: NoYesMissing | null;
  physicalDisabilityFam?: NoYesMissing | null;
  alcoholDrugUseDisorderFam?: NoYesMissing | null;
  insufficientIncome?: NoYesMissing | null;
  incarceratedParent?: NoYesMissing | null;
  targetScreenReqd?: NoYesMissing | null;
  timeToHousingLoss?: TimeToHousingLoss | null;
  annualPercentAmi?: AnnualPercentAmi | null;
  literalHomelessHistory?: LiteralHomelessHistory | null;
  clientLeaseholder?: NoYesMissing | null;
  hohLeaseholder?: NoYesMissing | null;
  subsidyAtRisk?: NoYesMissing | null;
  evictionHistory?: EvictionHistory | null;
  criminalRecord?: NoYesMissing | null;
  incarceratedAdult?: IncarceratedAdult | null;
  prisonDischarge?: NoYesMissing | null;
  sexOffender?: NoYesMissing | null;
  disabledHoh?: NoYesMissing | null;
  currentPregnant?: NoYesMissing | null;
  singleParent?: NoYesMissing | null;
  dependentUnder6?: DependentUnder6 | null;
  hh5Plus?: NoYesMissing | null;
  cocPrioritized?: NoYesMissing | null;
  hpScreeningScore?: number | null;
  thresholdScore?: number | null;
  vamcStation?: VamcStationNumber | null;
  translationNeeded?: NoYesReasonsForMissingData | null;
  preferredLanguage?: PreferredLanguage | null;
  preferredLanguageDifferent?: string | null;
};

export type SubmittedEnrollmentResultFieldsFragment = {
  __typename?: 'Enrollment';
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  exitDestination?: Destination | null;
  autoExited: boolean;
  inProgress: boolean;
  relationshipToHoH: RelationshipToHoH;
  enrollmentCoc?: string | null;
  householdId: string;
  householdShortId: string;
  householdSize: number;
  dateOfEngagement?: string | null;
  moveInDate?: string | null;
  livingSituation?: PriorLivingSituation | null;
  dateOfPathStatus?: string | null;
  clientEnrolledInPath?: NoYesMissing | null;
  reasonNotEnrolled?: ReasonNotEnrolled | null;
  disablingCondition?: NoYesReasonsForMissingData | null;
  translationNeeded?: NoYesReasonsForMissingData | null;
  preferredLanguage?: PreferredLanguage | null;
  preferredLanguageDifferent?: string | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
  project: {
    __typename?: 'Project';
    id: string;
    projectName: string;
    projectType?: ProjectType | null;
  };
  client: {
    __typename?: 'Client';
    dob?: string | null;
    veteranStatus: NoYesReasonsForMissingData;
    id: string;
    lockVersion: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
  };
  access: {
    __typename?: 'EnrollmentAccess';
    id: string;
    canEditEnrollments: boolean;
    canDeleteEnrollments: boolean;
    canAuditEnrollments: boolean;
    canViewEnrollmentLocationMap: boolean;
  };
  currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
  moveInAddresses: Array<{
    __typename?: 'ClientAddress';
    id: string;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    district?: string | null;
    country?: string | null;
    postalCode?: string | null;
    notes?: string | null;
    use?: ClientAddressUse | null;
    addressType?: ClientAddressType | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
  }>;
};

export type CeAssessmentFieldsFragment = {
  __typename?: 'CeAssessment';
  id: string;
  assessmentDate: string;
  assessmentLevel?: AssessmentLevel | null;
  assessmentLocation: string;
  assessmentType?: AssessmentType | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string | null;
  prioritizationStatus?: PrioritizationStatus | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type EnrollmentSummaryFieldsFragment = {
  __typename?: 'EnrollmentSummary';
  id: string;
  entryDate: string;
  inProgress: boolean;
  moveInDate?: string | null;
  projectId: string;
  projectName: string;
  projectType: ProjectType;
  canViewEnrollment: boolean;
};

export type EnrollmentRangeFieldsFragment = {
  __typename?: 'Enrollment';
  entryDate: string;
  exitDate?: string | null;
  inProgress: boolean;
};

export type EnrollmentWithHouseholdFieldsFragment = {
  __typename?: 'Enrollment';
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  exitDestination?: Destination | null;
  autoExited: boolean;
  inProgress: boolean;
  relationshipToHoH: RelationshipToHoH;
  enrollmentCoc?: string | null;
  householdId: string;
  householdShortId: string;
  householdSize: number;
  household: {
    __typename?: 'Household';
    id: string;
    householdSize: number;
    shortId: string;
    householdClients: Array<{
      __typename?: 'HouseholdClient';
      id: string;
      relationshipToHoH: RelationshipToHoH;
      client: {
        __typename?: 'Client';
        id: string;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
        dob?: string | null;
        age?: number | null;
        gender: Array<Gender>;
        pronouns: Array<string>;
        ssn?: string | null;
        race: Array<Race>;
        veteranStatus: NoYesReasonsForMissingData;
        access: {
          __typename?: 'ClientAccess';
          id: string;
          canEditClient: boolean;
          canDeleteClient: boolean;
          canViewDob: boolean;
          canViewFullSsn: boolean;
          canViewPartialSsn: boolean;
          canViewClientName: boolean;
          canViewClientPhoto: boolean;
          canViewClientAlerts: boolean;
          canManageClientAlerts: boolean;
          canViewEnrollmentDetails: boolean;
          canAuditClients: boolean;
          canManageScanCards: boolean;
          canMergeClients: boolean;
          canViewAnyFiles: boolean;
          canManageAnyClientFiles: boolean;
          canManageOwnClientFiles: boolean;
          canUploadClientFiles: boolean;
        };
        externalIds: Array<{
          __typename?: 'ExternalIdentifier';
          id: string;
          identifier?: string | null;
          url?: string | null;
          label: string;
          type: ExternalIdentifierType;
        }>;
        alerts: Array<{
          __typename?: 'ClientAlert';
          id: string;
          note: string;
          expirationDate?: string | null;
          createdAt: string;
          priority: ClientAlertPriorityLevel;
          createdBy?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }>;
      };
      enrollment: {
        __typename?: 'Enrollment';
        id: string;
        lockVersion: number;
        relationshipToHoH: RelationshipToHoH;
        autoExited: boolean;
        entryDate: string;
        exitDate?: string | null;
        inProgress: boolean;
        currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
      };
    }>;
  };
  project: {
    __typename?: 'Project';
    id: string;
    projectName: string;
    projectType?: ProjectType | null;
  };
  client: {
    __typename?: 'Client';
    dob?: string | null;
    veteranStatus: NoYesReasonsForMissingData;
    id: string;
    lockVersion: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
  };
  access: {
    __typename?: 'EnrollmentAccess';
    id: string;
    canEditEnrollments: boolean;
    canDeleteEnrollments: boolean;
    canAuditEnrollments: boolean;
    canViewEnrollmentLocationMap: boolean;
  };
  currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
};

export type GetEnrollmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetEnrollmentQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    lockVersion: number;
    entryDate: string;
    exitDate?: string | null;
    exitDestination?: Destination | null;
    autoExited: boolean;
    inProgress: boolean;
    relationshipToHoH: RelationshipToHoH;
    enrollmentCoc?: string | null;
    householdId: string;
    householdShortId: string;
    householdSize: number;
    disablingCondition?: NoYesReasonsForMissingData | null;
    dateOfEngagement?: string | null;
    moveInDate?: string | null;
    livingSituation?: PriorLivingSituation | null;
    rentalSubsidyType?: RentalSubsidyType | null;
    lengthOfStay?: ResidencePriorLengthOfStay | null;
    losUnderThreshold?: NoYesMissing | null;
    previousStreetEssh?: NoYesMissing | null;
    dateToStreetEssh?: string | null;
    timesHomelessPastThreeYears?: TimesHomelessPastThreeYears | null;
    monthsHomelessPastThreeYears?: MonthsHomelessPastThreeYears | null;
    dateOfPathStatus?: string | null;
    clientEnrolledInPath?: NoYesMissing | null;
    reasonNotEnrolled?: ReasonNotEnrolled | null;
    percentAmi?: PercentAmi | null;
    referralSource?: ReferralSource | null;
    countOutreachReferralApproaches?: number | null;
    dateOfBcpStatus?: string | null;
    eligibleForRhy?: NoYesMissing | null;
    reasonNoServices?: ReasonNoServices | null;
    runawayYouth?: NoYesReasonsForMissingData | null;
    sexualOrientation?: SexualOrientation | null;
    sexualOrientationOther?: string | null;
    formerWardChildWelfare?: NoYesReasonsForMissingData | null;
    childWelfareYears?: RhyNumberofYears | null;
    childWelfareMonths?: number | null;
    formerWardJuvenileJustice?: NoYesReasonsForMissingData | null;
    juvenileJusticeYears?: RhyNumberofYears | null;
    juvenileJusticeMonths?: number | null;
    unemploymentFam?: NoYesMissing | null;
    mentalHealthDisorderFam?: NoYesMissing | null;
    physicalDisabilityFam?: NoYesMissing | null;
    alcoholDrugUseDisorderFam?: NoYesMissing | null;
    insufficientIncome?: NoYesMissing | null;
    incarceratedParent?: NoYesMissing | null;
    targetScreenReqd?: NoYesMissing | null;
    timeToHousingLoss?: TimeToHousingLoss | null;
    annualPercentAmi?: AnnualPercentAmi | null;
    literalHomelessHistory?: LiteralHomelessHistory | null;
    clientLeaseholder?: NoYesMissing | null;
    hohLeaseholder?: NoYesMissing | null;
    subsidyAtRisk?: NoYesMissing | null;
    evictionHistory?: EvictionHistory | null;
    criminalRecord?: NoYesMissing | null;
    incarceratedAdult?: IncarceratedAdult | null;
    prisonDischarge?: NoYesMissing | null;
    sexOffender?: NoYesMissing | null;
    disabledHoh?: NoYesMissing | null;
    currentPregnant?: NoYesMissing | null;
    singleParent?: NoYesMissing | null;
    dependentUnder6?: DependentUnder6 | null;
    hh5Plus?: NoYesMissing | null;
    cocPrioritized?: NoYesMissing | null;
    hpScreeningScore?: number | null;
    thresholdScore?: number | null;
    vamcStation?: VamcStationNumber | null;
    translationNeeded?: NoYesReasonsForMissingData | null;
    preferredLanguage?: PreferredLanguage | null;
    preferredLanguageDifferent?: string | null;
    project: {
      __typename?: 'Project';
      id: string;
      projectName: string;
      projectType?: ProjectType | null;
    };
    client: {
      __typename?: 'Client';
      dob?: string | null;
      veteranStatus: NoYesReasonsForMissingData;
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
    };
    access: {
      __typename?: 'EnrollmentAccess';
      id: string;
      canEditEnrollments: boolean;
      canDeleteEnrollments: boolean;
      canAuditEnrollments: boolean;
      canViewEnrollmentLocationMap: boolean;
    };
    currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
  } | null;
};

export type GetEnrollmentDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetEnrollmentDetailsQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    numUnitsAssignedToHousehold: number;
    id: string;
    lockVersion: number;
    entryDate: string;
    exitDate?: string | null;
    exitDestination?: Destination | null;
    autoExited: boolean;
    inProgress: boolean;
    relationshipToHoH: RelationshipToHoH;
    enrollmentCoc?: string | null;
    householdId: string;
    householdShortId: string;
    householdSize: number;
    dateOfEngagement?: string | null;
    moveInDate?: string | null;
    livingSituation?: PriorLivingSituation | null;
    dateOfPathStatus?: string | null;
    clientEnrolledInPath?: NoYesMissing | null;
    reasonNotEnrolled?: ReasonNotEnrolled | null;
    disablingCondition?: NoYesReasonsForMissingData | null;
    translationNeeded?: NoYesReasonsForMissingData | null;
    preferredLanguage?: PreferredLanguage | null;
    preferredLanguageDifferent?: string | null;
    intakeAssessment?: { __typename?: 'Assessment'; id: string } | null;
    exitAssessment?: { __typename?: 'Assessment'; id: string } | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
    client: {
      __typename?: 'Client';
      hudChronic?: boolean | null;
      dob?: string | null;
      veteranStatus: NoYesReasonsForMissingData;
      ssn?: string | null;
      race: Array<Race>;
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
      customDataElements: Array<{
        __typename?: 'CustomDataElement';
        id: string;
        key: string;
        label: string;
        fieldType: CustomDataElementType;
        repeats: boolean;
        displayHooks: Array<DisplayHook>;
        value?: {
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        values?: Array<{
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }> | null;
      }>;
      access: {
        __typename?: 'ClientAccess';
        id: string;
        canEditClient: boolean;
      };
    };
    openEnrollmentSummary: Array<{
      __typename?: 'EnrollmentSummary';
      id: string;
      entryDate: string;
      inProgress: boolean;
      moveInDate?: string | null;
      projectId: string;
      projectName: string;
      projectType: ProjectType;
      canViewEnrollment: boolean;
    }>;
    dataCollectionFeatures: Array<{
      __typename?: 'DataCollectionFeature';
      id: string;
      role: DataCollectionFeatureRole;
      dataCollectedAbout: DataCollectedAbout;
      legacy: boolean;
    }>;
    occurrencePointForms: Array<{
      __typename?: 'OccurrencePointForm';
      id: string;
      dataCollectedAbout: DataCollectedAbout;
      definition: {
        __typename?: 'FormDefinition';
        id: string;
        role: FormRole;
        title: string;
        cacheKey: string;
        identifier: string;
        status: FormStatus;
        dateUpdated: string;
        supportsSaveInProgress: boolean;
        definition: {
          __typename?: 'FormDefinitionJson';
          item: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                item?: Array<{
                  __typename: 'FormItem';
                  linkId: string;
                  type: ItemType;
                  component?: Component | null;
                  prefix?: string | null;
                  text?: string | null;
                  briefText?: string | null;
                  readonlyText?: string | null;
                  helperText?: string | null;
                  required?: boolean | null;
                  warnIfEmpty?: boolean | null;
                  hidden?: boolean | null;
                  readOnly?: boolean | null;
                  repeats?: boolean | null;
                  pickListReference?: string | null;
                  size?: InputSize | null;
                  assessmentDate?: boolean | null;
                  prefill?: boolean | null;
                  dataCollectedAbout?: DataCollectedAbout | null;
                  disabledDisplay?: DisabledDisplay | null;
                  enableBehavior?: EnableBehavior | null;
                  rule?: any | null;
                  customRule?: any | null;
                  editorUserIds?: Array<string> | null;
                  item?: Array<{
                    __typename: 'FormItem';
                    linkId: string;
                    type: ItemType;
                    component?: Component | null;
                    prefix?: string | null;
                    text?: string | null;
                    briefText?: string | null;
                    readonlyText?: string | null;
                    helperText?: string | null;
                    required?: boolean | null;
                    warnIfEmpty?: boolean | null;
                    hidden?: boolean | null;
                    readOnly?: boolean | null;
                    repeats?: boolean | null;
                    pickListReference?: string | null;
                    size?: InputSize | null;
                    assessmentDate?: boolean | null;
                    prefill?: boolean | null;
                    dataCollectedAbout?: DataCollectedAbout | null;
                    disabledDisplay?: DisabledDisplay | null;
                    enableBehavior?: EnableBehavior | null;
                    rule?: any | null;
                    customRule?: any | null;
                    editorUserIds?: Array<string> | null;
                    mapping?: {
                      __typename?: 'FieldMapping';
                      recordType?: RelatedRecordType | null;
                      fieldName?: string | null;
                      customFieldKey?: string | null;
                    } | null;
                    bounds?: Array<{
                      __typename?: 'ValueBound';
                      id: string;
                      severity: ValidationSeverity;
                      type: BoundType;
                      question?: string | null;
                      valueNumber?: number | null;
                      valueDate?: string | null;
                      valueLocalConstant?: string | null;
                      offset?: number | null;
                    }> | null;
                    pickListOptions?: Array<{
                      __typename?: 'PickListOption';
                      code: string;
                      label?: string | null;
                      secondaryLabel?: string | null;
                      groupLabel?: string | null;
                      groupCode?: string | null;
                      initialSelected?: boolean | null;
                      helperText?: string | null;
                      numericValue?: number | null;
                    }> | null;
                    initial?: Array<{
                      __typename?: 'InitialValue';
                      valueCode?: string | null;
                      valueBoolean?: boolean | null;
                      valueNumber?: number | null;
                      valueLocalConstant?: string | null;
                      initialBehavior: InitialBehavior;
                    }> | null;
                    enableWhen?: Array<{
                      __typename?: 'EnableWhen';
                      question?: string | null;
                      localConstant?: string | null;
                      operator: EnableOperator;
                      answerCode?: string | null;
                      answerCodes?: Array<string> | null;
                      answerNumber?: number | null;
                      answerBoolean?: boolean | null;
                      answerGroupCode?: string | null;
                      answerDate?: string | null;
                      compareQuestion?: string | null;
                    }> | null;
                    autofillValues?: Array<{
                      __typename?: 'AutofillValue';
                      valueCode?: string | null;
                      valueQuestion?: string | null;
                      valueBoolean?: boolean | null;
                      valueNumber?: number | null;
                      sumQuestions?: Array<string> | null;
                      formula?: string | null;
                      autofillBehavior: EnableBehavior;
                      autofillReadonly?: boolean | null;
                      autofillWhen: Array<{
                        __typename?: 'EnableWhen';
                        question?: string | null;
                        localConstant?: string | null;
                        operator: EnableOperator;
                        answerCode?: string | null;
                        answerCodes?: Array<string> | null;
                        answerNumber?: number | null;
                        answerBoolean?: boolean | null;
                        answerGroupCode?: string | null;
                        answerDate?: string | null;
                        compareQuestion?: string | null;
                      }>;
                    }> | null;
                  }> | null;
                  mapping?: {
                    __typename?: 'FieldMapping';
                    recordType?: RelatedRecordType | null;
                    fieldName?: string | null;
                    customFieldKey?: string | null;
                  } | null;
                  bounds?: Array<{
                    __typename?: 'ValueBound';
                    id: string;
                    severity: ValidationSeverity;
                    type: BoundType;
                    question?: string | null;
                    valueNumber?: number | null;
                    valueDate?: string | null;
                    valueLocalConstant?: string | null;
                    offset?: number | null;
                  }> | null;
                  pickListOptions?: Array<{
                    __typename?: 'PickListOption';
                    code: string;
                    label?: string | null;
                    secondaryLabel?: string | null;
                    groupLabel?: string | null;
                    groupCode?: string | null;
                    initialSelected?: boolean | null;
                    helperText?: string | null;
                    numericValue?: number | null;
                  }> | null;
                  initial?: Array<{
                    __typename?: 'InitialValue';
                    valueCode?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    valueLocalConstant?: string | null;
                    initialBehavior: InitialBehavior;
                  }> | null;
                  enableWhen?: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }> | null;
                  autofillValues?: Array<{
                    __typename?: 'AutofillValue';
                    valueCode?: string | null;
                    valueQuestion?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    sumQuestions?: Array<string> | null;
                    formula?: string | null;
                    autofillBehavior: EnableBehavior;
                    autofillReadonly?: boolean | null;
                    autofillWhen: Array<{
                      __typename?: 'EnableWhen';
                      question?: string | null;
                      localConstant?: string | null;
                      operator: EnableOperator;
                      answerCode?: string | null;
                      answerCodes?: Array<string> | null;
                      answerNumber?: number | null;
                      answerBoolean?: boolean | null;
                      answerGroupCode?: string | null;
                      answerDate?: string | null;
                      compareQuestion?: string | null;
                    }>;
                  }> | null;
                }> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }>;
        };
        updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
      };
    }>;
    project: {
      __typename?: 'Project';
      hasUnits: boolean;
      staffAssignmentsEnabled: boolean;
      id: string;
      projectName: string;
      projectType?: ProjectType | null;
      access: {
        __typename?: 'ProjectAccess';
        id: string;
        canSplitHouseholds: boolean;
        canManageIncomingReferrals: boolean;
      };
      projectCocs: { __typename?: 'ProjectCocsPaginated'; nodesCount: number };
    };
    sourceReferralPosting?: {
      __typename?: 'ReferralPosting';
      id: string;
      referredFrom: string;
      referralDate: string;
    } | null;
    access: {
      __typename?: 'EnrollmentAccess';
      id: string;
      canEditEnrollments: boolean;
      canDeleteEnrollments: boolean;
      canAuditEnrollments: boolean;
      canViewEnrollmentLocationMap: boolean;
    };
    currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
    moveInAddresses: Array<{
      __typename?: 'ClientAddress';
      id: string;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      district?: string | null;
      country?: string | null;
      postalCode?: string | null;
      notes?: string | null;
      use?: ClientAddressUse | null;
      addressType?: ClientAddressType | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
    }>;
  } | null;
};

export type GetEnrollmentWithHouseholdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetEnrollmentWithHouseholdQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    lockVersion: number;
    entryDate: string;
    exitDate?: string | null;
    exitDestination?: Destination | null;
    autoExited: boolean;
    inProgress: boolean;
    relationshipToHoH: RelationshipToHoH;
    enrollmentCoc?: string | null;
    householdId: string;
    householdShortId: string;
    householdSize: number;
    household: {
      __typename?: 'Household';
      id: string;
      householdSize: number;
      shortId: string;
      householdClients: Array<{
        __typename?: 'HouseholdClient';
        id: string;
        relationshipToHoH: RelationshipToHoH;
        client: {
          __typename?: 'Client';
          id: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
          dob?: string | null;
          age?: number | null;
          gender: Array<Gender>;
          pronouns: Array<string>;
          ssn?: string | null;
          race: Array<Race>;
          veteranStatus: NoYesReasonsForMissingData;
          access: {
            __typename?: 'ClientAccess';
            id: string;
            canEditClient: boolean;
            canDeleteClient: boolean;
            canViewDob: boolean;
            canViewFullSsn: boolean;
            canViewPartialSsn: boolean;
            canViewClientName: boolean;
            canViewClientPhoto: boolean;
            canViewClientAlerts: boolean;
            canManageClientAlerts: boolean;
            canViewEnrollmentDetails: boolean;
            canAuditClients: boolean;
            canManageScanCards: boolean;
            canMergeClients: boolean;
            canViewAnyFiles: boolean;
            canManageAnyClientFiles: boolean;
            canManageOwnClientFiles: boolean;
            canUploadClientFiles: boolean;
          };
          externalIds: Array<{
            __typename?: 'ExternalIdentifier';
            id: string;
            identifier?: string | null;
            url?: string | null;
            label: string;
            type: ExternalIdentifierType;
          }>;
          alerts: Array<{
            __typename?: 'ClientAlert';
            id: string;
            note: string;
            expirationDate?: string | null;
            createdAt: string;
            priority: ClientAlertPriorityLevel;
            createdBy?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }>;
        };
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          relationshipToHoH: RelationshipToHoH;
          autoExited: boolean;
          entryDate: string;
          exitDate?: string | null;
          inProgress: boolean;
          currentUnit?: {
            __typename?: 'Unit';
            id: string;
            name: string;
          } | null;
        };
      }>;
    };
    project: {
      __typename?: 'Project';
      id: string;
      projectName: string;
      projectType?: ProjectType | null;
    };
    client: {
      __typename?: 'Client';
      dob?: string | null;
      veteranStatus: NoYesReasonsForMissingData;
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
    };
    access: {
      __typename?: 'EnrollmentAccess';
      id: string;
      canEditEnrollments: boolean;
      canDeleteEnrollments: boolean;
      canAuditEnrollments: boolean;
      canViewEnrollmentLocationMap: boolean;
    };
    currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
  } | null;
};

export type GetEnrollmentEventsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetEnrollmentEventsQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    lockVersion: number;
    events: {
      __typename?: 'EventsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Event';
        id: string;
        event: EventType;
        eventDate: string;
        locationCrisisOrPhHousing?: string | null;
        probSolDivRrResult?: NoYesMissing | null;
        referralCaseManageAfter?: NoYesMissing | null;
        referralResult?: ReferralResult | null;
        resultDate?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetEnrollmentCeAssessmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetEnrollmentCeAssessmentsQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    ceAssessments: {
      __typename?: 'CeAssessmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'CeAssessment';
        id: string;
        assessmentDate: string;
        assessmentLevel?: AssessmentLevel | null;
        assessmentLocation: string;
        assessmentType?: AssessmentType | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        prioritizationStatus?: PrioritizationStatus | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetEnrollmentRemindersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetEnrollmentRemindersQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    reminders: Array<{
      __typename?: 'Reminder';
      id: string;
      topic: ReminderTopic;
      dueDate?: string | null;
      overdue: boolean;
      formDefinitionId?: string | null;
      assessmentId?: string | null;
      enrollment: {
        __typename?: 'Enrollment';
        id: string;
        relationshipToHoH: RelationshipToHoH;
        intakeAssessment?: { __typename?: 'Assessment'; id: string } | null;
        exitAssessment?: { __typename?: 'Assessment'; id: string } | null;
      };
      client: {
        __typename?: 'Client';
        id: string;
        dob?: string | null;
        veteranStatus: NoYesReasonsForMissingData;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
      };
    }>;
  } | null;
};

export type DeleteEnrollmentMutationVariables = Exact<{
  input: DeleteEnrollmentInput;
}>;

export type DeleteEnrollmentMutation = {
  __typename?: 'Mutation';
  deleteEnrollment?: {
    __typename?: 'DeleteEnrollmentPayload';
    clientMutationId?: string | null;
    enrollment?: { __typename?: 'Enrollment'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteCeAssessmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCeAssessmentMutation = {
  __typename?: 'Mutation';
  deleteCeAssessment?: {
    __typename?: 'DeleteCeAssessmentPayload';
    clientMutationId?: string | null;
    ceAssessment?: { __typename?: 'CeAssessment'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteCeEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCeEventMutation = {
  __typename?: 'Mutation';
  deleteCeEvent?: {
    __typename?: 'DeleteCeEventPayload';
    clientMutationId?: string | null;
    ceEvent?: { __typename?: 'Event'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteCurrentLivingSituationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCurrentLivingSituationMutation = {
  __typename?: 'Mutation';
  deleteCurrentLivingSituation?: {
    __typename?: 'DeleteCurrentLivingSituationPayload';
    clientMutationId?: string | null;
    currentLivingSituation?: {
      __typename?: 'CurrentLivingSituation';
      id: string;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetEnrollmentAssessmentEligibilitiesQueryVariables = Exact<{
  enrollmentId: Scalars['ID']['input'];
}>;

export type GetEnrollmentAssessmentEligibilitiesQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    assessmentEligibilities: Array<{
      __typename?: 'AssessmentEligibility';
      id: string;
      title: string;
      formDefinitionId: string;
      role: AssessmentRole;
    }>;
  } | null;
};

export type GetEnrollmentPermissionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetEnrollmentPermissionsQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    access: {
      __typename?: 'EnrollmentAccess';
      id: string;
      canEditEnrollments: boolean;
      canDeleteEnrollments: boolean;
      canAuditEnrollments: boolean;
      canViewEnrollmentLocationMap: boolean;
    };
  } | null;
};

export type EventFieldsFragment = {
  __typename?: 'Event';
  id: string;
  event: EventType;
  eventDate: string;
  locationCrisisOrPhHousing?: string | null;
  probSolDivRrResult?: NoYesMissing | null;
  referralCaseManageAfter?: NoYesMissing | null;
  referralResult?: ReferralResult | null;
  resultDate?: string | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type ExternalFormSubmissionSummaryFragment = {
  __typename?: 'ExternalFormSubmission';
  id: string;
  submittedAt: string;
  spam?: boolean | null;
  status: ExternalFormSubmissionStatus;
  notes?: string | null;
  enrollmentId?: string | null;
  clientId?: string | null;
  summaryFields: Array<{
    __typename?: 'KeyValue';
    key: string;
    value?: string | null;
  }>;
};

export type ExternalFormSubmissionFieldsFragment = {
  __typename?: 'ExternalFormSubmission';
  values?: any | null;
  id: string;
  submittedAt: string;
  spam?: boolean | null;
  status: ExternalFormSubmissionStatus;
  notes?: string | null;
  enrollmentId?: string | null;
  clientId?: string | null;
  definition: {
    __typename?: 'FormDefinition';
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    status: FormStatus;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    definition: {
      __typename?: 'FormDefinitionJson';
      item: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }>;
    };
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  };
  summaryFields: Array<{
    __typename?: 'KeyValue';
    key: string;
    value?: string | null;
  }>;
};

export type UpdateExternalFormSubmissionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ExternalFormSubmissionInput;
}>;

export type UpdateExternalFormSubmissionMutation = {
  __typename?: 'Mutation';
  updateExternalFormSubmission?: {
    __typename?: 'UpdateExternalFormSubmissionPayload';
    externalFormSubmission?: {
      __typename?: 'ExternalFormSubmission';
      id: string;
      submittedAt: string;
      spam?: boolean | null;
      status: ExternalFormSubmissionStatus;
      notes?: string | null;
      enrollmentId?: string | null;
      clientId?: string | null;
      summaryFields: Array<{
        __typename?: 'KeyValue';
        key: string;
        value?: string | null;
      }>;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type BulkReviewExternalSubmissionsMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type BulkReviewExternalSubmissionsMutation = {
  __typename?: 'Mutation';
  bulkReviewExternalSubmissions?: {
    __typename?: 'BulkReviewExternalSubmissionsPayload';
    success?: boolean | null;
  } | null;
};

export type DeleteExternalFormSubmissionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteExternalFormSubmissionMutation = {
  __typename?: 'Mutation';
  deleteExternalFormSubmission?: {
    __typename?: 'DeleteExternalFormSubmissionPayload';
    externalFormSubmission?: {
      __typename?: 'ExternalFormSubmission';
      id: string;
      submittedAt: string;
      spam?: boolean | null;
      status: ExternalFormSubmissionStatus;
      notes?: string | null;
      enrollmentId?: string | null;
      clientId?: string | null;
      summaryFields: Array<{
        __typename?: 'KeyValue';
        key: string;
        value?: string | null;
      }>;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type RefreshExternalSubmissionsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RefreshExternalSubmissionsMutation = {
  __typename?: 'Mutation';
  refreshExternalSubmissions?: {
    __typename?: 'RefreshExternalSubmissionsPayload';
    success: boolean;
  } | null;
};

export type GetProjectExternalFormSubmissionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  formDefinitionIdentifier: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ExternalFormSubmissionFilterOptions>;
}>;

export type GetProjectExternalFormSubmissionsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    externalFormSubmissions: {
      __typename?: 'ExternalFormSubmissionsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ExternalFormSubmission';
        id: string;
        submittedAt: string;
        spam?: boolean | null;
        status: ExternalFormSubmissionStatus;
        notes?: string | null;
        enrollmentId?: string | null;
        clientId?: string | null;
        summaryFields: Array<{
          __typename?: 'KeyValue';
          key: string;
          value?: string | null;
        }>;
      }>;
    };
  } | null;
};

export type GetExternalFormSubmissionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetExternalFormSubmissionQuery = {
  __typename?: 'Query';
  externalFormSubmission?: {
    __typename?: 'ExternalFormSubmission';
    values?: any | null;
    id: string;
    submittedAt: string;
    spam?: boolean | null;
    status: ExternalFormSubmissionStatus;
    notes?: string | null;
    enrollmentId?: string | null;
    clientId?: string | null;
    definition: {
      __typename?: 'FormDefinition';
      id: string;
      role: FormRole;
      title: string;
      cacheKey: string;
      identifier: string;
      status: FormStatus;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      definition: {
        __typename?: 'FormDefinitionJson';
        item: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                item?: Array<{
                  __typename: 'FormItem';
                  linkId: string;
                  type: ItemType;
                  component?: Component | null;
                  prefix?: string | null;
                  text?: string | null;
                  briefText?: string | null;
                  readonlyText?: string | null;
                  helperText?: string | null;
                  required?: boolean | null;
                  warnIfEmpty?: boolean | null;
                  hidden?: boolean | null;
                  readOnly?: boolean | null;
                  repeats?: boolean | null;
                  pickListReference?: string | null;
                  size?: InputSize | null;
                  assessmentDate?: boolean | null;
                  prefill?: boolean | null;
                  dataCollectedAbout?: DataCollectedAbout | null;
                  disabledDisplay?: DisabledDisplay | null;
                  enableBehavior?: EnableBehavior | null;
                  rule?: any | null;
                  customRule?: any | null;
                  editorUserIds?: Array<string> | null;
                  mapping?: {
                    __typename?: 'FieldMapping';
                    recordType?: RelatedRecordType | null;
                    fieldName?: string | null;
                    customFieldKey?: string | null;
                  } | null;
                  bounds?: Array<{
                    __typename?: 'ValueBound';
                    id: string;
                    severity: ValidationSeverity;
                    type: BoundType;
                    question?: string | null;
                    valueNumber?: number | null;
                    valueDate?: string | null;
                    valueLocalConstant?: string | null;
                    offset?: number | null;
                  }> | null;
                  pickListOptions?: Array<{
                    __typename?: 'PickListOption';
                    code: string;
                    label?: string | null;
                    secondaryLabel?: string | null;
                    groupLabel?: string | null;
                    groupCode?: string | null;
                    initialSelected?: boolean | null;
                    helperText?: string | null;
                    numericValue?: number | null;
                  }> | null;
                  initial?: Array<{
                    __typename?: 'InitialValue';
                    valueCode?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    valueLocalConstant?: string | null;
                    initialBehavior: InitialBehavior;
                  }> | null;
                  enableWhen?: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }> | null;
                  autofillValues?: Array<{
                    __typename?: 'AutofillValue';
                    valueCode?: string | null;
                    valueQuestion?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    sumQuestions?: Array<string> | null;
                    formula?: string | null;
                    autofillBehavior: EnableBehavior;
                    autofillReadonly?: boolean | null;
                    autofillWhen: Array<{
                      __typename?: 'EnableWhen';
                      question?: string | null;
                      localConstant?: string | null;
                      operator: EnableOperator;
                      answerCode?: string | null;
                      answerCodes?: Array<string> | null;
                      answerNumber?: number | null;
                      answerBoolean?: boolean | null;
                      answerGroupCode?: string | null;
                      answerDate?: string | null;
                      compareQuestion?: string | null;
                    }>;
                  }> | null;
                }> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }>;
      };
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    };
    summaryFields: Array<{
      __typename?: 'KeyValue';
      key: string;
      value?: string | null;
    }>;
  } | null;
};

export type FileFieldsFragment = {
  __typename?: 'File';
  confidential?: boolean | null;
  contentType?: string | null;
  effectiveDate?: string | null;
  expirationDate?: string | null;
  id: string;
  name: string;
  url?: string | null;
  tags: Array<string>;
  ownFile: boolean;
  redacted: boolean;
  enrollmentId?: string | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  enrollment?: { __typename?: 'Enrollment'; id: string } | null;
  uploadedBy?: {
    __typename?: 'ApplicationUser';
    id: string;
    name: string;
  } | null;
  updatedBy?: {
    __typename?: 'ApplicationUser';
    id: string;
    name: string;
  } | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type ValidationErrorFieldsFragment = {
  __typename?: 'ValidationError';
  type: ValidationType;
  attribute: string;
  readableAttribute?: string | null;
  message: string;
  fullMessage: string;
  severity: ValidationSeverity;
  id?: string | null;
  recordId?: string | null;
  linkId?: string | null;
  section?: string | null;
  data?: any | null;
};

export type PickListOptionFieldsFragment = {
  __typename?: 'PickListOption';
  code: string;
  label?: string | null;
  secondaryLabel?: string | null;
  groupLabel?: string | null;
  groupCode?: string | null;
  initialSelected?: boolean | null;
  helperText?: string | null;
  numericValue?: number | null;
};

export type EnableWhenFieldsFragment = {
  __typename?: 'EnableWhen';
  question?: string | null;
  localConstant?: string | null;
  operator: EnableOperator;
  answerCode?: string | null;
  answerCodes?: Array<string> | null;
  answerNumber?: number | null;
  answerBoolean?: boolean | null;
  answerGroupCode?: string | null;
  answerDate?: string | null;
  compareQuestion?: string | null;
};

export type ItemFieldsFragment = {
  __typename: 'FormItem';
  linkId: string;
  type: ItemType;
  component?: Component | null;
  prefix?: string | null;
  text?: string | null;
  briefText?: string | null;
  readonlyText?: string | null;
  helperText?: string | null;
  required?: boolean | null;
  warnIfEmpty?: boolean | null;
  hidden?: boolean | null;
  readOnly?: boolean | null;
  repeats?: boolean | null;
  pickListReference?: string | null;
  size?: InputSize | null;
  assessmentDate?: boolean | null;
  prefill?: boolean | null;
  dataCollectedAbout?: DataCollectedAbout | null;
  disabledDisplay?: DisabledDisplay | null;
  enableBehavior?: EnableBehavior | null;
  rule?: any | null;
  customRule?: any | null;
  editorUserIds?: Array<string> | null;
  mapping?: {
    __typename?: 'FieldMapping';
    recordType?: RelatedRecordType | null;
    fieldName?: string | null;
    customFieldKey?: string | null;
  } | null;
  bounds?: Array<{
    __typename?: 'ValueBound';
    id: string;
    severity: ValidationSeverity;
    type: BoundType;
    question?: string | null;
    valueNumber?: number | null;
    valueDate?: string | null;
    valueLocalConstant?: string | null;
    offset?: number | null;
  }> | null;
  pickListOptions?: Array<{
    __typename?: 'PickListOption';
    code: string;
    label?: string | null;
    secondaryLabel?: string | null;
    groupLabel?: string | null;
    groupCode?: string | null;
    initialSelected?: boolean | null;
    helperText?: string | null;
    numericValue?: number | null;
  }> | null;
  initial?: Array<{
    __typename?: 'InitialValue';
    valueCode?: string | null;
    valueBoolean?: boolean | null;
    valueNumber?: number | null;
    valueLocalConstant?: string | null;
    initialBehavior: InitialBehavior;
  }> | null;
  enableWhen?: Array<{
    __typename?: 'EnableWhen';
    question?: string | null;
    localConstant?: string | null;
    operator: EnableOperator;
    answerCode?: string | null;
    answerCodes?: Array<string> | null;
    answerNumber?: number | null;
    answerBoolean?: boolean | null;
    answerGroupCode?: string | null;
    answerDate?: string | null;
    compareQuestion?: string | null;
  }> | null;
  autofillValues?: Array<{
    __typename?: 'AutofillValue';
    valueCode?: string | null;
    valueQuestion?: string | null;
    valueBoolean?: boolean | null;
    valueNumber?: number | null;
    sumQuestions?: Array<string> | null;
    formula?: string | null;
    autofillBehavior: EnableBehavior;
    autofillReadonly?: boolean | null;
    autofillWhen: Array<{
      __typename?: 'EnableWhen';
      question?: string | null;
      localConstant?: string | null;
      operator: EnableOperator;
      answerCode?: string | null;
      answerCodes?: Array<string> | null;
      answerNumber?: number | null;
      answerBoolean?: boolean | null;
      answerGroupCode?: string | null;
      answerDate?: string | null;
      compareQuestion?: string | null;
    }>;
  }> | null;
};

export type FormDefinitionJsonFieldsFragment = {
  __typename?: 'FormDefinitionJson';
  item: Array<{
    __typename: 'FormItem';
    linkId: string;
    type: ItemType;
    component?: Component | null;
    prefix?: string | null;
    text?: string | null;
    briefText?: string | null;
    readonlyText?: string | null;
    helperText?: string | null;
    required?: boolean | null;
    warnIfEmpty?: boolean | null;
    hidden?: boolean | null;
    readOnly?: boolean | null;
    repeats?: boolean | null;
    pickListReference?: string | null;
    size?: InputSize | null;
    assessmentDate?: boolean | null;
    prefill?: boolean | null;
    dataCollectedAbout?: DataCollectedAbout | null;
    disabledDisplay?: DisabledDisplay | null;
    enableBehavior?: EnableBehavior | null;
    rule?: any | null;
    customRule?: any | null;
    editorUserIds?: Array<string> | null;
    item?: Array<{
      __typename: 'FormItem';
      linkId: string;
      type: ItemType;
      component?: Component | null;
      prefix?: string | null;
      text?: string | null;
      briefText?: string | null;
      readonlyText?: string | null;
      helperText?: string | null;
      required?: boolean | null;
      warnIfEmpty?: boolean | null;
      hidden?: boolean | null;
      readOnly?: boolean | null;
      repeats?: boolean | null;
      pickListReference?: string | null;
      size?: InputSize | null;
      assessmentDate?: boolean | null;
      prefill?: boolean | null;
      dataCollectedAbout?: DataCollectedAbout | null;
      disabledDisplay?: DisabledDisplay | null;
      enableBehavior?: EnableBehavior | null;
      rule?: any | null;
      customRule?: any | null;
      editorUserIds?: Array<string> | null;
      item?: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }> | null;
      mapping?: {
        __typename?: 'FieldMapping';
        recordType?: RelatedRecordType | null;
        fieldName?: string | null;
        customFieldKey?: string | null;
      } | null;
      bounds?: Array<{
        __typename?: 'ValueBound';
        id: string;
        severity: ValidationSeverity;
        type: BoundType;
        question?: string | null;
        valueNumber?: number | null;
        valueDate?: string | null;
        valueLocalConstant?: string | null;
        offset?: number | null;
      }> | null;
      pickListOptions?: Array<{
        __typename?: 'PickListOption';
        code: string;
        label?: string | null;
        secondaryLabel?: string | null;
        groupLabel?: string | null;
        groupCode?: string | null;
        initialSelected?: boolean | null;
        helperText?: string | null;
        numericValue?: number | null;
      }> | null;
      initial?: Array<{
        __typename?: 'InitialValue';
        valueCode?: string | null;
        valueBoolean?: boolean | null;
        valueNumber?: number | null;
        valueLocalConstant?: string | null;
        initialBehavior: InitialBehavior;
      }> | null;
      enableWhen?: Array<{
        __typename?: 'EnableWhen';
        question?: string | null;
        localConstant?: string | null;
        operator: EnableOperator;
        answerCode?: string | null;
        answerCodes?: Array<string> | null;
        answerNumber?: number | null;
        answerBoolean?: boolean | null;
        answerGroupCode?: string | null;
        answerDate?: string | null;
        compareQuestion?: string | null;
      }> | null;
      autofillValues?: Array<{
        __typename?: 'AutofillValue';
        valueCode?: string | null;
        valueQuestion?: string | null;
        valueBoolean?: boolean | null;
        valueNumber?: number | null;
        sumQuestions?: Array<string> | null;
        formula?: string | null;
        autofillBehavior: EnableBehavior;
        autofillReadonly?: boolean | null;
        autofillWhen: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }>;
      }> | null;
    }> | null;
    mapping?: {
      __typename?: 'FieldMapping';
      recordType?: RelatedRecordType | null;
      fieldName?: string | null;
      customFieldKey?: string | null;
    } | null;
    bounds?: Array<{
      __typename?: 'ValueBound';
      id: string;
      severity: ValidationSeverity;
      type: BoundType;
      question?: string | null;
      valueNumber?: number | null;
      valueDate?: string | null;
      valueLocalConstant?: string | null;
      offset?: number | null;
    }> | null;
    pickListOptions?: Array<{
      __typename?: 'PickListOption';
      code: string;
      label?: string | null;
      secondaryLabel?: string | null;
      groupLabel?: string | null;
      groupCode?: string | null;
      initialSelected?: boolean | null;
      helperText?: string | null;
      numericValue?: number | null;
    }> | null;
    initial?: Array<{
      __typename?: 'InitialValue';
      valueCode?: string | null;
      valueBoolean?: boolean | null;
      valueNumber?: number | null;
      valueLocalConstant?: string | null;
      initialBehavior: InitialBehavior;
    }> | null;
    enableWhen?: Array<{
      __typename?: 'EnableWhen';
      question?: string | null;
      localConstant?: string | null;
      operator: EnableOperator;
      answerCode?: string | null;
      answerCodes?: Array<string> | null;
      answerNumber?: number | null;
      answerBoolean?: boolean | null;
      answerGroupCode?: string | null;
      answerDate?: string | null;
      compareQuestion?: string | null;
    }> | null;
    autofillValues?: Array<{
      __typename?: 'AutofillValue';
      valueCode?: string | null;
      valueQuestion?: string | null;
      valueBoolean?: boolean | null;
      valueNumber?: number | null;
      sumQuestions?: Array<string> | null;
      formula?: string | null;
      autofillBehavior: EnableBehavior;
      autofillReadonly?: boolean | null;
      autofillWhen: Array<{
        __typename?: 'EnableWhen';
        question?: string | null;
        localConstant?: string | null;
        operator: EnableOperator;
        answerCode?: string | null;
        answerCodes?: Array<string> | null;
        answerNumber?: number | null;
        answerBoolean?: boolean | null;
        answerGroupCode?: string | null;
        answerDate?: string | null;
        compareQuestion?: string | null;
      }>;
    }> | null;
  }>;
};

export type FormDefinitionMetadataFragment = {
  __typename?: 'FormDefinition';
  id: string;
  role: FormRole;
  title: string;
  cacheKey: string;
  identifier: string;
  status: FormStatus;
  dateUpdated: string;
  supportsSaveInProgress: boolean;
  updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
};

export type FormDefinitionFieldsFragment = {
  __typename?: 'FormDefinition';
  id: string;
  role: FormRole;
  title: string;
  cacheKey: string;
  identifier: string;
  status: FormStatus;
  dateUpdated: string;
  supportsSaveInProgress: boolean;
  definition: {
    __typename?: 'FormDefinitionJson';
    item: Array<{
      __typename: 'FormItem';
      linkId: string;
      type: ItemType;
      component?: Component | null;
      prefix?: string | null;
      text?: string | null;
      briefText?: string | null;
      readonlyText?: string | null;
      helperText?: string | null;
      required?: boolean | null;
      warnIfEmpty?: boolean | null;
      hidden?: boolean | null;
      readOnly?: boolean | null;
      repeats?: boolean | null;
      pickListReference?: string | null;
      size?: InputSize | null;
      assessmentDate?: boolean | null;
      prefill?: boolean | null;
      dataCollectedAbout?: DataCollectedAbout | null;
      disabledDisplay?: DisabledDisplay | null;
      enableBehavior?: EnableBehavior | null;
      rule?: any | null;
      customRule?: any | null;
      editorUserIds?: Array<string> | null;
      item?: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }> | null;
      mapping?: {
        __typename?: 'FieldMapping';
        recordType?: RelatedRecordType | null;
        fieldName?: string | null;
        customFieldKey?: string | null;
      } | null;
      bounds?: Array<{
        __typename?: 'ValueBound';
        id: string;
        severity: ValidationSeverity;
        type: BoundType;
        question?: string | null;
        valueNumber?: number | null;
        valueDate?: string | null;
        valueLocalConstant?: string | null;
        offset?: number | null;
      }> | null;
      pickListOptions?: Array<{
        __typename?: 'PickListOption';
        code: string;
        label?: string | null;
        secondaryLabel?: string | null;
        groupLabel?: string | null;
        groupCode?: string | null;
        initialSelected?: boolean | null;
        helperText?: string | null;
        numericValue?: number | null;
      }> | null;
      initial?: Array<{
        __typename?: 'InitialValue';
        valueCode?: string | null;
        valueBoolean?: boolean | null;
        valueNumber?: number | null;
        valueLocalConstant?: string | null;
        initialBehavior: InitialBehavior;
      }> | null;
      enableWhen?: Array<{
        __typename?: 'EnableWhen';
        question?: string | null;
        localConstant?: string | null;
        operator: EnableOperator;
        answerCode?: string | null;
        answerCodes?: Array<string> | null;
        answerNumber?: number | null;
        answerBoolean?: boolean | null;
        answerGroupCode?: string | null;
        answerDate?: string | null;
        compareQuestion?: string | null;
      }> | null;
      autofillValues?: Array<{
        __typename?: 'AutofillValue';
        valueCode?: string | null;
        valueQuestion?: string | null;
        valueBoolean?: boolean | null;
        valueNumber?: number | null;
        sumQuestions?: Array<string> | null;
        formula?: string | null;
        autofillBehavior: EnableBehavior;
        autofillReadonly?: boolean | null;
        autofillWhen: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }>;
      }> | null;
    }>;
  };
  updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
};

export type FormDefinitionFieldsForJsonEditorFragment = {
  __typename?: 'FormDefinition';
  status: FormStatus;
  rawDefinition: any;
  id: string;
  role: FormRole;
  title: string;
  cacheKey: string;
  identifier: string;
  dateUpdated: string;
  supportsSaveInProgress: boolean;
  definition: {
    __typename?: 'FormDefinitionJson';
    item: Array<{
      __typename: 'FormItem';
      linkId: string;
      type: ItemType;
      component?: Component | null;
      prefix?: string | null;
      text?: string | null;
      briefText?: string | null;
      readonlyText?: string | null;
      helperText?: string | null;
      required?: boolean | null;
      warnIfEmpty?: boolean | null;
      hidden?: boolean | null;
      readOnly?: boolean | null;
      repeats?: boolean | null;
      pickListReference?: string | null;
      size?: InputSize | null;
      assessmentDate?: boolean | null;
      prefill?: boolean | null;
      dataCollectedAbout?: DataCollectedAbout | null;
      disabledDisplay?: DisabledDisplay | null;
      enableBehavior?: EnableBehavior | null;
      rule?: any | null;
      customRule?: any | null;
      editorUserIds?: Array<string> | null;
      item?: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }> | null;
      mapping?: {
        __typename?: 'FieldMapping';
        recordType?: RelatedRecordType | null;
        fieldName?: string | null;
        customFieldKey?: string | null;
      } | null;
      bounds?: Array<{
        __typename?: 'ValueBound';
        id: string;
        severity: ValidationSeverity;
        type: BoundType;
        question?: string | null;
        valueNumber?: number | null;
        valueDate?: string | null;
        valueLocalConstant?: string | null;
        offset?: number | null;
      }> | null;
      pickListOptions?: Array<{
        __typename?: 'PickListOption';
        code: string;
        label?: string | null;
        secondaryLabel?: string | null;
        groupLabel?: string | null;
        groupCode?: string | null;
        initialSelected?: boolean | null;
        helperText?: string | null;
        numericValue?: number | null;
      }> | null;
      initial?: Array<{
        __typename?: 'InitialValue';
        valueCode?: string | null;
        valueBoolean?: boolean | null;
        valueNumber?: number | null;
        valueLocalConstant?: string | null;
        initialBehavior: InitialBehavior;
      }> | null;
      enableWhen?: Array<{
        __typename?: 'EnableWhen';
        question?: string | null;
        localConstant?: string | null;
        operator: EnableOperator;
        answerCode?: string | null;
        answerCodes?: Array<string> | null;
        answerNumber?: number | null;
        answerBoolean?: boolean | null;
        answerGroupCode?: string | null;
        answerDate?: string | null;
        compareQuestion?: string | null;
      }> | null;
      autofillValues?: Array<{
        __typename?: 'AutofillValue';
        valueCode?: string | null;
        valueQuestion?: string | null;
        valueBoolean?: boolean | null;
        valueNumber?: number | null;
        sumQuestions?: Array<string> | null;
        formula?: string | null;
        autofillBehavior: EnableBehavior;
        autofillReadonly?: boolean | null;
        autofillWhen: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }>;
      }> | null;
    }>;
  };
  updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
};

export type FormDefinitionFieldsForEditorFragment = {
  __typename?: 'FormDefinition';
  version: string;
  id: string;
  role: FormRole;
  title: string;
  cacheKey: string;
  identifier: string;
  status: FormStatus;
  dateUpdated: string;
  supportsSaveInProgress: boolean;
  definition: {
    __typename?: 'FormDefinitionJson';
    item: Array<{
      __typename: 'FormItem';
      linkId: string;
      type: ItemType;
      component?: Component | null;
      prefix?: string | null;
      text?: string | null;
      briefText?: string | null;
      readonlyText?: string | null;
      helperText?: string | null;
      required?: boolean | null;
      warnIfEmpty?: boolean | null;
      hidden?: boolean | null;
      readOnly?: boolean | null;
      repeats?: boolean | null;
      pickListReference?: string | null;
      size?: InputSize | null;
      assessmentDate?: boolean | null;
      prefill?: boolean | null;
      dataCollectedAbout?: DataCollectedAbout | null;
      disabledDisplay?: DisabledDisplay | null;
      enableBehavior?: EnableBehavior | null;
      rule?: any | null;
      customRule?: any | null;
      editorUserIds?: Array<string> | null;
      item?: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }> | null;
      mapping?: {
        __typename?: 'FieldMapping';
        recordType?: RelatedRecordType | null;
        fieldName?: string | null;
        customFieldKey?: string | null;
      } | null;
      bounds?: Array<{
        __typename?: 'ValueBound';
        id: string;
        severity: ValidationSeverity;
        type: BoundType;
        question?: string | null;
        valueNumber?: number | null;
        valueDate?: string | null;
        valueLocalConstant?: string | null;
        offset?: number | null;
      }> | null;
      pickListOptions?: Array<{
        __typename?: 'PickListOption';
        code: string;
        label?: string | null;
        secondaryLabel?: string | null;
        groupLabel?: string | null;
        groupCode?: string | null;
        initialSelected?: boolean | null;
        helperText?: string | null;
        numericValue?: number | null;
      }> | null;
      initial?: Array<{
        __typename?: 'InitialValue';
        valueCode?: string | null;
        valueBoolean?: boolean | null;
        valueNumber?: number | null;
        valueLocalConstant?: string | null;
        initialBehavior: InitialBehavior;
      }> | null;
      enableWhen?: Array<{
        __typename?: 'EnableWhen';
        question?: string | null;
        localConstant?: string | null;
        operator: EnableOperator;
        answerCode?: string | null;
        answerCodes?: Array<string> | null;
        answerNumber?: number | null;
        answerBoolean?: boolean | null;
        answerGroupCode?: string | null;
        answerDate?: string | null;
        compareQuestion?: string | null;
      }> | null;
      autofillValues?: Array<{
        __typename?: 'AutofillValue';
        valueCode?: string | null;
        valueQuestion?: string | null;
        valueBoolean?: boolean | null;
        valueNumber?: number | null;
        sumQuestions?: Array<string> | null;
        formula?: string | null;
        autofillBehavior: EnableBehavior;
        autofillReadonly?: boolean | null;
        autofillWhen: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }>;
      }> | null;
    }>;
  };
  updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
};

export type FormIdentifierDetailsFragment = {
  __typename?: 'FormIdentifier';
  id: string;
  identifier: string;
  managedInVersionControl: boolean;
  displayVersion: {
    __typename?: 'FormDefinition';
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    status: FormStatus;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  };
  draftVersion?: {
    __typename?: 'FormDefinition';
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    status: FormStatus;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  } | null;
};

export type UpdateFormDefinitionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: FormDefinitionInput;
}>;

export type UpdateFormDefinitionMutation = {
  __typename?: 'Mutation';
  updateFormDefinition?: {
    __typename?: 'UpdateFormDefinitionPayload';
    formDefinition?: {
      __typename?: 'FormDefinition';
      version: string;
      id: string;
      role: FormRole;
      title: string;
      cacheKey: string;
      identifier: string;
      status: FormStatus;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      definition: {
        __typename?: 'FormDefinitionJson';
        item: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                item?: Array<{
                  __typename: 'FormItem';
                  linkId: string;
                  type: ItemType;
                  component?: Component | null;
                  prefix?: string | null;
                  text?: string | null;
                  briefText?: string | null;
                  readonlyText?: string | null;
                  helperText?: string | null;
                  required?: boolean | null;
                  warnIfEmpty?: boolean | null;
                  hidden?: boolean | null;
                  readOnly?: boolean | null;
                  repeats?: boolean | null;
                  pickListReference?: string | null;
                  size?: InputSize | null;
                  assessmentDate?: boolean | null;
                  prefill?: boolean | null;
                  dataCollectedAbout?: DataCollectedAbout | null;
                  disabledDisplay?: DisabledDisplay | null;
                  enableBehavior?: EnableBehavior | null;
                  rule?: any | null;
                  customRule?: any | null;
                  editorUserIds?: Array<string> | null;
                  mapping?: {
                    __typename?: 'FieldMapping';
                    recordType?: RelatedRecordType | null;
                    fieldName?: string | null;
                    customFieldKey?: string | null;
                  } | null;
                  bounds?: Array<{
                    __typename?: 'ValueBound';
                    id: string;
                    severity: ValidationSeverity;
                    type: BoundType;
                    question?: string | null;
                    valueNumber?: number | null;
                    valueDate?: string | null;
                    valueLocalConstant?: string | null;
                    offset?: number | null;
                  }> | null;
                  pickListOptions?: Array<{
                    __typename?: 'PickListOption';
                    code: string;
                    label?: string | null;
                    secondaryLabel?: string | null;
                    groupLabel?: string | null;
                    groupCode?: string | null;
                    initialSelected?: boolean | null;
                    helperText?: string | null;
                    numericValue?: number | null;
                  }> | null;
                  initial?: Array<{
                    __typename?: 'InitialValue';
                    valueCode?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    valueLocalConstant?: string | null;
                    initialBehavior: InitialBehavior;
                  }> | null;
                  enableWhen?: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }> | null;
                  autofillValues?: Array<{
                    __typename?: 'AutofillValue';
                    valueCode?: string | null;
                    valueQuestion?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    sumQuestions?: Array<string> | null;
                    formula?: string | null;
                    autofillBehavior: EnableBehavior;
                    autofillReadonly?: boolean | null;
                    autofillWhen: Array<{
                      __typename?: 'EnableWhen';
                      question?: string | null;
                      localConstant?: string | null;
                      operator: EnableOperator;
                      answerCode?: string | null;
                      answerCodes?: Array<string> | null;
                      answerNumber?: number | null;
                      answerBoolean?: boolean | null;
                      answerGroupCode?: string | null;
                      answerDate?: string | null;
                      compareQuestion?: string | null;
                    }>;
                  }> | null;
                }> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }>;
      };
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type UpdateFormDefinitionFromJsonEditorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: FormDefinitionInput;
}>;

export type UpdateFormDefinitionFromJsonEditorMutation = {
  __typename?: 'Mutation';
  updateFormDefinition?: {
    __typename?: 'UpdateFormDefinitionPayload';
    formDefinition?: {
      __typename?: 'FormDefinition';
      status: FormStatus;
      rawDefinition: any;
      id: string;
      role: FormRole;
      title: string;
      cacheKey: string;
      identifier: string;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      definition: {
        __typename?: 'FormDefinitionJson';
        item: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                item?: Array<{
                  __typename: 'FormItem';
                  linkId: string;
                  type: ItemType;
                  component?: Component | null;
                  prefix?: string | null;
                  text?: string | null;
                  briefText?: string | null;
                  readonlyText?: string | null;
                  helperText?: string | null;
                  required?: boolean | null;
                  warnIfEmpty?: boolean | null;
                  hidden?: boolean | null;
                  readOnly?: boolean | null;
                  repeats?: boolean | null;
                  pickListReference?: string | null;
                  size?: InputSize | null;
                  assessmentDate?: boolean | null;
                  prefill?: boolean | null;
                  dataCollectedAbout?: DataCollectedAbout | null;
                  disabledDisplay?: DisabledDisplay | null;
                  enableBehavior?: EnableBehavior | null;
                  rule?: any | null;
                  customRule?: any | null;
                  editorUserIds?: Array<string> | null;
                  mapping?: {
                    __typename?: 'FieldMapping';
                    recordType?: RelatedRecordType | null;
                    fieldName?: string | null;
                    customFieldKey?: string | null;
                  } | null;
                  bounds?: Array<{
                    __typename?: 'ValueBound';
                    id: string;
                    severity: ValidationSeverity;
                    type: BoundType;
                    question?: string | null;
                    valueNumber?: number | null;
                    valueDate?: string | null;
                    valueLocalConstant?: string | null;
                    offset?: number | null;
                  }> | null;
                  pickListOptions?: Array<{
                    __typename?: 'PickListOption';
                    code: string;
                    label?: string | null;
                    secondaryLabel?: string | null;
                    groupLabel?: string | null;
                    groupCode?: string | null;
                    initialSelected?: boolean | null;
                    helperText?: string | null;
                    numericValue?: number | null;
                  }> | null;
                  initial?: Array<{
                    __typename?: 'InitialValue';
                    valueCode?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    valueLocalConstant?: string | null;
                    initialBehavior: InitialBehavior;
                  }> | null;
                  enableWhen?: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }> | null;
                  autofillValues?: Array<{
                    __typename?: 'AutofillValue';
                    valueCode?: string | null;
                    valueQuestion?: string | null;
                    valueBoolean?: boolean | null;
                    valueNumber?: number | null;
                    sumQuestions?: Array<string> | null;
                    formula?: string | null;
                    autofillBehavior: EnableBehavior;
                    autofillReadonly?: boolean | null;
                    autofillWhen: Array<{
                      __typename?: 'EnableWhen';
                      question?: string | null;
                      localConstant?: string | null;
                      operator: EnableOperator;
                      answerCode?: string | null;
                      answerCodes?: Array<string> | null;
                      answerNumber?: number | null;
                      answerBoolean?: boolean | null;
                      answerGroupCode?: string | null;
                      answerDate?: string | null;
                      compareQuestion?: string | null;
                    }>;
                  }> | null;
                }> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }>;
      };
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type CreateFormDefinitionMutationVariables = Exact<{
  input: FormDefinitionInput;
}>;

export type CreateFormDefinitionMutation = {
  __typename?: 'Mutation';
  createFormDefinition?: {
    __typename?: 'CreateFormDefinitionPayload';
    formDefinition?: {
      __typename?: 'FormDefinition';
      id: string;
      role: FormRole;
      title: string;
      cacheKey: string;
      identifier: string;
      status: FormStatus;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type CreateNextDraftFormDefinitionMutationVariables = Exact<{
  identifier: Scalars['String']['input'];
}>;

export type CreateNextDraftFormDefinitionMutation = {
  __typename?: 'Mutation';
  createNextDraftFormDefinition?: {
    __typename?: 'CreateNextDraftFormDefinitionPayload';
    formIdentifier?: {
      __typename?: 'FormIdentifier';
      id: string;
      identifier: string;
      draftVersion?: {
        __typename?: 'FormDefinition';
        id: string;
        role: FormRole;
        title: string;
        cacheKey: string;
        identifier: string;
        status: FormStatus;
        dateUpdated: string;
        supportsSaveInProgress: boolean;
        updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
      } | null;
    } | null;
  } | null;
};

export type CreateDuplicateFormDefinitionMutationVariables = Exact<{
  identifier: Scalars['String']['input'];
}>;

export type CreateDuplicateFormDefinitionMutation = {
  __typename?: 'Mutation';
  createDuplicateFormDefinition?: {
    __typename?: 'CreateDuplicateFormDefinitionPayload';
    formIdentifier?: {
      __typename?: 'FormIdentifier';
      id: string;
      identifier: string;
      draftVersion?: {
        __typename?: 'FormDefinition';
        id: string;
        role: FormRole;
        title: string;
        cacheKey: string;
        identifier: string;
        status: FormStatus;
        dateUpdated: string;
        supportsSaveInProgress: boolean;
        updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
      } | null;
    } | null;
  } | null;
};

export type PublishFormDefinitionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublishFormDefinitionMutation = {
  __typename?: 'Mutation';
  publishFormDefinition?: {
    __typename?: 'PublishFormDefinitionPayload';
    formIdentifier?: {
      __typename?: 'FormIdentifier';
      id: string;
      identifier: string;
      managedInVersionControl: boolean;
      displayVersion: {
        __typename?: 'FormDefinition';
        id: string;
        role: FormRole;
        title: string;
        cacheKey: string;
        identifier: string;
        status: FormStatus;
        dateUpdated: string;
        supportsSaveInProgress: boolean;
        updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
      };
      draftVersion?: {
        __typename?: 'FormDefinition';
        id: string;
        role: FormRole;
        title: string;
        cacheKey: string;
        identifier: string;
        status: FormStatus;
        dateUpdated: string;
        supportsSaveInProgress: boolean;
        updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteFormDefinitionDraftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteFormDefinitionDraftMutation = {
  __typename?: 'Mutation';
  deleteFormDefinition?: {
    __typename?: 'DeleteFormDefinitionPayload';
    formDefinition?: {
      __typename?: 'FormDefinition';
      id: string;
      cacheKey: string;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetPickListQueryVariables = Exact<{
  pickListType: PickListType;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  clientId?: InputMaybe<Scalars['ID']['input']>;
  householdId?: InputMaybe<Scalars['ID']['input']>;
  enrollmentId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetPickListQuery = {
  __typename?: 'Query';
  pickList: Array<{
    __typename?: 'PickListOption';
    code: string;
    label?: string | null;
    secondaryLabel?: string | null;
    groupLabel?: string | null;
    groupCode?: string | null;
    initialSelected?: boolean | null;
    helperText?: string | null;
    numericValue?: number | null;
  }>;
};

export type GetFormDefinitionQueryVariables = Exact<{
  role: RecordFormRole;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetFormDefinitionQuery = {
  __typename?: 'Query';
  recordFormDefinition?: {
    __typename?: 'FormDefinition';
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    status: FormStatus;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    definition: {
      __typename?: 'FormDefinitionJson';
      item: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }>;
    };
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  } | null;
};

export type GetStaticFormDefinitionQueryVariables = Exact<{
  role: StaticFormRole;
}>;

export type GetStaticFormDefinitionQuery = {
  __typename?: 'Query';
  staticFormDefinition: {
    __typename?: 'FormDefinition';
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    status: FormStatus;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    definition: {
      __typename?: 'FormDefinitionJson';
      item: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }>;
    };
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  };
};

export type GetServiceFormDefinitionQueryVariables = Exact<{
  serviceTypeId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
}>;

export type GetServiceFormDefinitionQuery = {
  __typename?: 'Query';
  serviceFormDefinition?: {
    __typename?: 'FormDefinition';
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    status: FormStatus;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    definition: {
      __typename?: 'FormDefinitionJson';
      item: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }>;
    };
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  } | null;
};

export type GetAssessmentFormDefinitionQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  role?: InputMaybe<AssessmentRole>;
  assessmentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
}>;

export type GetAssessmentFormDefinitionQuery = {
  __typename?: 'Query';
  assessmentFormDefinition?: {
    __typename?: 'FormDefinition';
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    status: FormStatus;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    definition: {
      __typename?: 'FormDefinitionJson';
      item: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }>;
    };
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  } | null;
};

export type GetFormIdentifierDetailsQueryVariables = Exact<{
  identifier: Scalars['String']['input'];
}>;

export type GetFormIdentifierDetailsQuery = {
  __typename?: 'Query';
  formIdentifier?: {
    __typename?: 'FormIdentifier';
    id: string;
    identifier: string;
    managedInVersionControl: boolean;
    displayVersion: {
      __typename?: 'FormDefinition';
      id: string;
      role: FormRole;
      title: string;
      cacheKey: string;
      identifier: string;
      status: FormStatus;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    };
    draftVersion?: {
      __typename?: 'FormDefinition';
      id: string;
      role: FormRole;
      title: string;
      cacheKey: string;
      identifier: string;
      status: FormStatus;
      dateUpdated: string;
      supportsSaveInProgress: boolean;
      updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
    } | null;
  } | null;
};

export type GetFormIdentifierVersionsQueryVariables = Exact<{
  identifier: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetFormIdentifierVersionsQuery = {
  __typename?: 'Query';
  formIdentifier?: {
    __typename?: 'FormIdentifier';
    id: string;
    identifier: string;
    allVersions: {
      __typename?: 'FormDefinitionsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'FormDefinition';
        version: string;
        dateUpdated: string;
        id: string;
        role: FormRole;
        title: string;
        cacheKey: string;
        identifier: string;
        status: FormStatus;
        supportsSaveInProgress: boolean;
        updatedBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetFormIdentifiersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<FormIdentifierFilterOptions>;
}>;

export type GetFormIdentifiersQuery = {
  __typename?: 'Query';
  formIdentifiers: {
    __typename?: 'FormIdentifiersPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'FormIdentifier';
      id: string;
      identifier: string;
      managedInVersionControl: boolean;
      displayVersion: {
        __typename?: 'FormDefinition';
        system: boolean;
        id: string;
        role: FormRole;
        title: string;
        cacheKey: string;
        identifier: string;
        status: FormStatus;
        dateUpdated: string;
        supportsSaveInProgress: boolean;
        formRules: { __typename?: 'FormRulesPaginated'; nodesCount: number };
        updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
      };
    }>;
  };
};

export type SubmitFormMutationVariables = Exact<{
  input: SubmitFormInput;
}>;

export type SubmitFormMutation = {
  __typename?: 'Mutation';
  submitForm?: {
    __typename?: 'SubmitFormPayload';
    clientMutationId?: string | null;
    record?:
      | {
          __typename?: 'CeAssessment';
          id: string;
          assessmentDate: string;
          assessmentLevel?: AssessmentLevel | null;
          assessmentLocation: string;
          assessmentType?: AssessmentType | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          dateDeleted?: string | null;
          prioritizationStatus?: PrioritizationStatus | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }
      | {
          __typename?: 'CeParticipation';
          id: string;
          accessPoint?: NoYes | null;
          ceParticipationStatusStartDate?: string | null;
          ceParticipationStatusEndDate?: string | null;
          crisisAssessment?: NoYes | null;
          directServices?: NoYes | null;
          housingAssessment?: NoYes | null;
          preventionAssessment?: NoYes | null;
          receivesReferrals?: NoYes | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }
      | {
          __typename?: 'Client';
          dobDataQuality: DobDataQuality;
          gender: Array<Gender>;
          differentIdentityText?: string | null;
          pronouns: Array<string>;
          nameDataQuality: NameDataQuality;
          personalId: string;
          race: Array<Race>;
          additionalRaceEthnicity?: string | null;
          ssnDataQuality: SsnDataQuality;
          veteranStatus: NoYesReasonsForMissingData;
          dateCreated?: string | null;
          dateDeleted?: string | null;
          dateUpdated?: string | null;
          enabledFeatures: Array<ClientDashboardFeature>;
          id: string;
          lockVersion: number;
          dob?: string | null;
          age?: number | null;
          ssn?: string | null;
          yearEnteredService?: number | null;
          yearSeparated?: number | null;
          worldWarIi?: NoYesReasonsForMissingData | null;
          koreanWar?: NoYesReasonsForMissingData | null;
          vietnamWar?: NoYesReasonsForMissingData | null;
          desertStorm?: NoYesReasonsForMissingData | null;
          afghanistanOef?: NoYesReasonsForMissingData | null;
          iraqOif?: NoYesReasonsForMissingData | null;
          iraqOnd?: NoYesReasonsForMissingData | null;
          otherTheater?: NoYesReasonsForMissingData | null;
          militaryBranch?: MilitaryBranch | null;
          dischargeStatus?: DischargeStatus | null;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
          externalIds: Array<{
            __typename?: 'ExternalIdentifier';
            id: string;
            identifier?: string | null;
            url?: string | null;
            label: string;
            type: ExternalIdentifierType;
          }>;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
          access: {
            __typename?: 'ClientAccess';
            id: string;
            canViewFullSsn: boolean;
            canViewPartialSsn: boolean;
            canEditClient: boolean;
            canDeleteClient: boolean;
            canViewDob: boolean;
            canViewClientName: boolean;
            canViewClientPhoto: boolean;
            canViewClientAlerts: boolean;
            canManageClientAlerts: boolean;
            canViewEnrollmentDetails: boolean;
            canAuditClients: boolean;
            canManageScanCards: boolean;
            canMergeClients: boolean;
            canViewAnyFiles: boolean;
            canManageAnyClientFiles: boolean;
            canManageOwnClientFiles: boolean;
            canUploadClientFiles: boolean;
          };
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
          names: Array<{
            __typename?: 'ClientName';
            id: string;
            first?: string | null;
            middle?: string | null;
            last?: string | null;
            suffix?: string | null;
            nameDataQuality?: NameDataQuality | null;
            use?: ClientNameUse | null;
            notes?: string | null;
            primary?: boolean | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
          }>;
          addresses: Array<{
            __typename?: 'ClientAddress';
            id: string;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            district?: string | null;
            country?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            use?: ClientAddressUse | null;
            addressType?: ClientAddressType | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
          }>;
          phoneNumbers: Array<{
            __typename?: 'ClientContactPoint';
            id: string;
            value?: string | null;
            notes?: string | null;
            use?: ClientContactPointUse | null;
            system?: ClientContactPointSystem | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
          }>;
          emailAddresses: Array<{
            __typename?: 'ClientContactPoint';
            id: string;
            value?: string | null;
            notes?: string | null;
            use?: ClientContactPointUse | null;
            system?: ClientContactPointSystem | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
          }>;
          alerts: Array<{
            __typename?: 'ClientAlert';
            id: string;
            note: string;
            expirationDate?: string | null;
            createdAt: string;
            priority: ClientAlertPriorityLevel;
            createdBy?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }>;
        }
      | {
          __typename?: 'CurrentLivingSituation';
          id: string;
          clsSubsidyType?: RentalSubsidyType | null;
          currentLivingSituation: CurrentLivingSituationOptions;
          informationDate?: string | null;
          leaseOwn60Day?: NoYesReasonsForMissingData | null;
          leaveSituation14Days?: NoYesReasonsForMissingData | null;
          locationDetails?: string | null;
          movedTwoOrMore?: NoYesReasonsForMissingData | null;
          resourcesToObtain?: NoYesReasonsForMissingData | null;
          subsequentResidence?: NoYesReasonsForMissingData | null;
          dateUpdated?: string | null;
          dateCreated?: string | null;
          verifiedByProjectId?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
          geolocation?: {
            __typename?: 'Geolocation';
            id: string;
            coordinates: {
              __typename?: 'GeolocationCoordinates';
              id: string;
              latitude: string;
              longitude: string;
            };
          } | null;
        }
      | {
          __typename?: 'CustomCaseNote';
          id: string;
          content: string;
          informationDate?: string | null;
          dateUpdated?: string | null;
          dateCreated?: string | null;
          formDefinitionId?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
        }
      | {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          entryDate: string;
          exitDate?: string | null;
          exitDestination?: Destination | null;
          autoExited: boolean;
          inProgress: boolean;
          relationshipToHoH: RelationshipToHoH;
          enrollmentCoc?: string | null;
          householdId: string;
          householdShortId: string;
          householdSize: number;
          dateOfEngagement?: string | null;
          moveInDate?: string | null;
          livingSituation?: PriorLivingSituation | null;
          dateOfPathStatus?: string | null;
          clientEnrolledInPath?: NoYesMissing | null;
          reasonNotEnrolled?: ReasonNotEnrolled | null;
          disablingCondition?: NoYesReasonsForMissingData | null;
          translationNeeded?: NoYesReasonsForMissingData | null;
          preferredLanguage?: PreferredLanguage | null;
          preferredLanguageDifferent?: string | null;
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
          project: {
            __typename?: 'Project';
            id: string;
            projectName: string;
            projectType?: ProjectType | null;
          };
          client: {
            __typename?: 'Client';
            dob?: string | null;
            veteranStatus: NoYesReasonsForMissingData;
            id: string;
            lockVersion: number;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            nameSuffix?: string | null;
          };
          access: {
            __typename?: 'EnrollmentAccess';
            id: string;
            canEditEnrollments: boolean;
            canDeleteEnrollments: boolean;
            canAuditEnrollments: boolean;
            canViewEnrollmentLocationMap: boolean;
          };
          currentUnit?: {
            __typename?: 'Unit';
            id: string;
            name: string;
          } | null;
          moveInAddresses: Array<{
            __typename?: 'ClientAddress';
            id: string;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            district?: string | null;
            country?: string | null;
            postalCode?: string | null;
            notes?: string | null;
            use?: ClientAddressUse | null;
            addressType?: ClientAddressType | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
          }>;
        }
      | {
          __typename?: 'Event';
          id: string;
          event: EventType;
          eventDate: string;
          locationCrisisOrPhHousing?: string | null;
          probSolDivRrResult?: NoYesMissing | null;
          referralCaseManageAfter?: NoYesMissing | null;
          referralResult?: ReferralResult | null;
          resultDate?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          dateDeleted?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }
      | {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }
      | {
          __typename?: 'Funder';
          id: string;
          dateCreated?: string | null;
          dateDeleted?: string | null;
          dateUpdated?: string | null;
          endDate?: string | null;
          funder: FundingSource;
          grantId?: string | null;
          otherFunder?: string | null;
          startDate?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }
      | {
          __typename?: 'HmisParticipation';
          id: string;
          hmisParticipationType?: HmisParticipationType | null;
          hmisParticipationStatusStartDate?: string | null;
          hmisParticipationStatusEndDate?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }
      | {
          __typename?: 'Inventory';
          availability?: Availability | null;
          bedInventory: number;
          chBedInventory?: number | null;
          chVetBedInventory?: number | null;
          chYouthBedInventory?: number | null;
          cocCode?: string | null;
          dateCreated?: string | null;
          dateDeleted?: string | null;
          dateUpdated?: string | null;
          esBedType?: BedType | null;
          householdType?: HouseholdType | null;
          id: string;
          inventoryEndDate?: string | null;
          inventoryStartDate?: string | null;
          otherBedInventory?: number | null;
          unitInventory: number;
          vetBedInventory?: number | null;
          youthBedInventory?: number | null;
          youthVetBedInventory?: number | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
        }
      | {
          __typename?: 'Organization';
          id: string;
          hudId: string;
          organizationName: string;
          description?: string | null;
          contactInformation?: string | null;
          victimServiceProvider: NoYesMissing;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          dateDeleted?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
        }
      | {
          __typename?: 'Project';
          id: string;
          hudId: string;
          description?: string | null;
          HOPWAMedAssistedLivingFac?: HopwaMedAssistedLivingFac | null;
          contactInformation?: string | null;
          continuumProject?: NoYes | null;
          housingType?: HousingType | null;
          residentialAffiliation?: NoYes | null;
          residentialAffiliationProjectIds: Array<string>;
          rrhSubType?: RrhSubType | null;
          staffAssignmentsEnabled: boolean;
          targetPopulation?: TargetPopulation | null;
          projectName: string;
          projectType?: ProjectType | null;
          operatingEndDate?: string | null;
          operatingStartDate?: string | null;
          organization: {
            __typename?: 'Organization';
            id: string;
            hudId: string;
            organizationName: string;
          };
          access: {
            __typename?: 'ProjectAccess';
            id: string;
            canDeleteProject: boolean;
            canEnrollClients: boolean;
            canEditEnrollments: boolean;
            canViewEnrollmentDetails: boolean;
            canEditProjectDetails: boolean;
            canViewUnits: boolean;
            canManageUnits: boolean;
            canManageIncomingReferrals: boolean;
            canManageOutgoingReferrals: boolean;
            canManageExternalFormSubmissions: boolean;
            canSplitHouseholds: boolean;
          };
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
          dataCollectionFeatures: Array<{
            __typename?: 'DataCollectionFeature';
            id: string;
            role: DataCollectionFeatureRole;
            dataCollectedAbout: DataCollectedAbout;
            legacy: boolean;
          }>;
          serviceTypes: Array<{
            __typename?: 'ServiceType';
            id: string;
            name: string;
            hud: boolean;
            hudRecordType?: RecordType | null;
            hudTypeProvided?: ServiceTypeProvided | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            supportsBulkAssignment: boolean;
            serviceCategory: {
              __typename?: 'ServiceCategory';
              id: string;
              name: string;
            };
          }>;
          projectCocs: {
            __typename?: 'ProjectCocsPaginated';
            nodesCount: number;
          };
        }
      | {
          __typename?: 'ProjectCoc';
          id: string;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          cocCode?: string | null;
          dateCreated?: string | null;
          dateDeleted?: string | null;
          dateUpdated?: string | null;
          geocode?: string | null;
          geographyType?: GeographyType | null;
          state?: string | null;
          zip?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }
      | { __typename?: 'ReferralPosting'; id: string }
      | {
          __typename?: 'ReferralRequest';
          id: string;
          requestedOn: string;
          identifier: string;
          neededBy: string;
          requestorName: string;
          requestorPhone: string;
          requestorEmail: string;
          unitType: {
            __typename?: 'UnitTypeObject';
            id: string;
            description?: string | null;
            bedType?: InventoryBedType | null;
            unitSize?: number | null;
            dateUpdated: string;
            dateCreated: string;
          };
        }
      | {
          __typename?: 'Service';
          id: string;
          dateProvided?: string | null;
          faAmount?: number | null;
          faStartDate?: string | null;
          faEndDate?: string | null;
          movingOnOtherType?: string | null;
          referralOutcome?: PathReferralOutcome | null;
          subTypeProvided?: ServiceSubTypeProvided | null;
          otherTypeProvided?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          dateDeleted?: string | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
          serviceType?: {
            __typename?: 'ServiceType';
            id: string;
            name: string;
            hud: boolean;
            hudRecordType?: RecordType | null;
            hudTypeProvided?: ServiceTypeProvided | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            supportsBulkAssignment: boolean;
            serviceCategory: {
              __typename?: 'ServiceCategory';
              id: string;
              name: string;
            };
          } | null;
          customDataElements: Array<{
            __typename?: 'CustomDataElement';
            id: string;
            key: string;
            label: string;
            fieldType: CustomDataElementType;
            repeats: boolean;
            displayHooks: Array<DisplayHook>;
            value?: {
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            values?: Array<{
              __typename?: 'CustomDataElementValue';
              id: string;
              valueBoolean?: boolean | null;
              valueDate?: string | null;
              valueFloat?: number | null;
              valueInteger?: number | null;
              valueJson?: any | null;
              valueString?: string | null;
              valueText?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              valueFile?: {
                __typename?: 'File';
                confidential?: boolean | null;
                contentType?: string | null;
                effectiveDate?: string | null;
                expirationDate?: string | null;
                id: string;
                name: string;
                url?: string | null;
                tags: Array<string>;
                ownFile: boolean;
                redacted: boolean;
                enrollmentId?: string | null;
                dateCreated?: string | null;
                dateUpdated?: string | null;
                enrollment?: { __typename?: 'Enrollment'; id: string } | null;
                uploadedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                updatedBy?: {
                  __typename?: 'ApplicationUser';
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename: 'ApplicationUser';
                  id: string;
                  name: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                } | null;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            }> | null;
          }>;
        }
      | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetFormDefinitionFieldsForEditorQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFormDefinitionFieldsForEditorQuery = {
  __typename?: 'Query';
  formDefinition?: {
    __typename?: 'FormDefinition';
    version: string;
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    status: FormStatus;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    definition: {
      __typename?: 'FormDefinitionJson';
      item: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }>;
    };
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  } | null;
};

export type GetFormDefinitionFieldsForJsonEditorQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFormDefinitionFieldsForJsonEditorQuery = {
  __typename?: 'Query';
  formDefinition?: {
    __typename?: 'FormDefinition';
    status: FormStatus;
    rawDefinition: any;
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    definition: {
      __typename?: 'FormDefinitionJson';
      item: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }>;
    };
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  } | null;
};

export type GetParsedFormDefinitionQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;

export type GetParsedFormDefinitionQuery = {
  __typename?: 'Query';
  parsedFormDefinition?: {
    __typename?: 'FormDefinitionForJsonResult';
    errors: Array<string>;
    definition?: {
      __typename?: 'FormDefinitionJson';
      item: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }>;
    } | null;
  } | null;
};

export type GeolocationFieldsFragment = {
  __typename?: 'Geolocation';
  id: string;
  coordinates: {
    __typename?: 'GeolocationCoordinates';
    id: string;
    latitude: string;
    longitude: string;
  };
};

export type GeolocationFieldsWithMetadataFragment = {
  __typename?: 'Geolocation';
  id: string;
  locatedAt: string;
  sourceFormName?: string | null;
  coordinates: {
    __typename?: 'GeolocationCoordinates';
    id: string;
    latitude: string;
    longitude: string;
  };
  collectedBy?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type GetEnrollmentGeolocationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetEnrollmentGeolocationsQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    geolocations: Array<{
      __typename?: 'Geolocation';
      id: string;
      locatedAt: string;
      sourceFormName?: string | null;
      coordinates: {
        __typename?: 'GeolocationCoordinates';
        id: string;
        latitude: string;
        longitude: string;
      };
      collectedBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }>;
  } | null;
};

export type HouseholdFieldsFragment = {
  __typename?: 'Household';
  id: string;
  householdSize: number;
  shortId: string;
  householdClients: Array<{
    __typename?: 'HouseholdClient';
    id: string;
    relationshipToHoH: RelationshipToHoH;
    client: {
      __typename?: 'Client';
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
      dob?: string | null;
      age?: number | null;
      gender: Array<Gender>;
      pronouns: Array<string>;
      ssn?: string | null;
      race: Array<Race>;
      veteranStatus: NoYesReasonsForMissingData;
      access: {
        __typename?: 'ClientAccess';
        id: string;
        canEditClient: boolean;
        canDeleteClient: boolean;
        canViewDob: boolean;
        canViewFullSsn: boolean;
        canViewPartialSsn: boolean;
        canViewClientName: boolean;
        canViewClientPhoto: boolean;
        canViewClientAlerts: boolean;
        canManageClientAlerts: boolean;
        canViewEnrollmentDetails: boolean;
        canAuditClients: boolean;
        canManageScanCards: boolean;
        canMergeClients: boolean;
        canViewAnyFiles: boolean;
        canManageAnyClientFiles: boolean;
        canManageOwnClientFiles: boolean;
        canUploadClientFiles: boolean;
      };
      externalIds: Array<{
        __typename?: 'ExternalIdentifier';
        id: string;
        identifier?: string | null;
        url?: string | null;
        label: string;
        type: ExternalIdentifierType;
      }>;
      alerts: Array<{
        __typename?: 'ClientAlert';
        id: string;
        note: string;
        expirationDate?: string | null;
        createdAt: string;
        priority: ClientAlertPriorityLevel;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
    enrollment: {
      __typename?: 'Enrollment';
      id: string;
      lockVersion: number;
      relationshipToHoH: RelationshipToHoH;
      autoExited: boolean;
      entryDate: string;
      exitDate?: string | null;
      inProgress: boolean;
      currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
    };
  }>;
};

export type HouseholdClientFieldsFragment = {
  __typename?: 'HouseholdClient';
  id: string;
  relationshipToHoH: RelationshipToHoH;
  client: {
    __typename?: 'Client';
    id: string;
    lockVersion: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
    dob?: string | null;
    age?: number | null;
    gender: Array<Gender>;
    pronouns: Array<string>;
    ssn?: string | null;
    race: Array<Race>;
    veteranStatus: NoYesReasonsForMissingData;
    access: {
      __typename?: 'ClientAccess';
      id: string;
      canEditClient: boolean;
      canDeleteClient: boolean;
      canViewDob: boolean;
      canViewFullSsn: boolean;
      canViewPartialSsn: boolean;
      canViewClientName: boolean;
      canViewClientPhoto: boolean;
      canViewClientAlerts: boolean;
      canManageClientAlerts: boolean;
      canViewEnrollmentDetails: boolean;
      canAuditClients: boolean;
      canManageScanCards: boolean;
      canMergeClients: boolean;
      canViewAnyFiles: boolean;
      canManageAnyClientFiles: boolean;
      canManageOwnClientFiles: boolean;
      canUploadClientFiles: boolean;
    };
    externalIds: Array<{
      __typename?: 'ExternalIdentifier';
      id: string;
      identifier?: string | null;
      url?: string | null;
      label: string;
      type: ExternalIdentifierType;
    }>;
    alerts: Array<{
      __typename?: 'ClientAlert';
      id: string;
      note: string;
      expirationDate?: string | null;
      createdAt: string;
      priority: ClientAlertPriorityLevel;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }>;
  };
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    lockVersion: number;
    relationshipToHoH: RelationshipToHoH;
    autoExited: boolean;
    entryDate: string;
    exitDate?: string | null;
    inProgress: boolean;
    currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
  };
};

export type ProjectEnrollmentsHouseholdFieldsFragment = {
  __typename?: 'Household';
  id: string;
  householdSize: number;
  shortId: string;
  householdClients: Array<{
    __typename?: 'HouseholdClient';
    id: string;
    relationshipToHoH: RelationshipToHoH;
    client: {
      __typename?: 'Client';
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
      dob?: string | null;
      age?: number | null;
      gender: Array<Gender>;
      pronouns: Array<string>;
    };
    enrollment: {
      __typename?: 'Enrollment';
      id: string;
      lockVersion: number;
      entryDate: string;
      exitDate?: string | null;
      inProgress: boolean;
      autoExited: boolean;
    };
  }>;
  staffAssignments?: {
    __typename?: 'StaffAssignmentsPaginated';
    nodesCount: number;
    nodes: Array<{
      __typename?: 'StaffAssignment';
      id: string;
      staffAssignmentRelationship: string;
      assignedAt: string;
      unassignedAt?: string | null;
      user: { __typename?: 'ApplicationUser'; id: string; name: string };
      household: { __typename?: 'Household'; id: string };
    }>;
  } | null;
};

export type ProjectEnrollmentsHouseholdClientFieldsFragment = {
  __typename?: 'HouseholdClient';
  id: string;
  relationshipToHoH: RelationshipToHoH;
  client: {
    __typename?: 'Client';
    id: string;
    lockVersion: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
    dob?: string | null;
    age?: number | null;
    gender: Array<Gender>;
    pronouns: Array<string>;
  };
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    lockVersion: number;
    entryDate: string;
    exitDate?: string | null;
    inProgress: boolean;
    autoExited: boolean;
  };
};

export type JoinHouseholdMutationVariables = Exact<{
  receivingHouseholdId: Scalars['ID']['input'];
  joiningEnrollmentInputs:
    | Array<EnrollmentRelationshipInput>
    | EnrollmentRelationshipInput;
}>;

export type JoinHouseholdMutation = {
  __typename?: 'Mutation';
  joinHousehold?: {
    __typename?: 'JoinHouseholdPayload';
    receivingHousehold: {
      __typename?: 'Household';
      id: string;
      householdSize: number;
      shortId: string;
      householdClients: Array<{
        __typename?: 'HouseholdClient';
        id: string;
        relationshipToHoH: RelationshipToHoH;
        client: {
          __typename?: 'Client';
          id: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
          dob?: string | null;
          age?: number | null;
          gender: Array<Gender>;
          pronouns: Array<string>;
          ssn?: string | null;
          race: Array<Race>;
          veteranStatus: NoYesReasonsForMissingData;
          access: {
            __typename?: 'ClientAccess';
            id: string;
            canEditClient: boolean;
            canDeleteClient: boolean;
            canViewDob: boolean;
            canViewFullSsn: boolean;
            canViewPartialSsn: boolean;
            canViewClientName: boolean;
            canViewClientPhoto: boolean;
            canViewClientAlerts: boolean;
            canManageClientAlerts: boolean;
            canViewEnrollmentDetails: boolean;
            canAuditClients: boolean;
            canManageScanCards: boolean;
            canMergeClients: boolean;
            canViewAnyFiles: boolean;
            canManageAnyClientFiles: boolean;
            canManageOwnClientFiles: boolean;
            canUploadClientFiles: boolean;
          };
          externalIds: Array<{
            __typename?: 'ExternalIdentifier';
            id: string;
            identifier?: string | null;
            url?: string | null;
            label: string;
            type: ExternalIdentifierType;
          }>;
          alerts: Array<{
            __typename?: 'ClientAlert';
            id: string;
            note: string;
            expirationDate?: string | null;
            createdAt: string;
            priority: ClientAlertPriorityLevel;
            createdBy?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }>;
        };
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          relationshipToHoH: RelationshipToHoH;
          autoExited: boolean;
          entryDate: string;
          exitDate?: string | null;
          inProgress: boolean;
          currentUnit?: {
            __typename?: 'Unit';
            id: string;
            name: string;
          } | null;
        };
      }>;
    };
    donorHousehold?: {
      __typename?: 'Household';
      id: string;
      householdSize: number;
      shortId: string;
      householdClients: Array<{
        __typename?: 'HouseholdClient';
        id: string;
        relationshipToHoH: RelationshipToHoH;
        client: {
          __typename?: 'Client';
          id: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
          dob?: string | null;
          age?: number | null;
          gender: Array<Gender>;
          pronouns: Array<string>;
          ssn?: string | null;
          race: Array<Race>;
          veteranStatus: NoYesReasonsForMissingData;
          access: {
            __typename?: 'ClientAccess';
            id: string;
            canEditClient: boolean;
            canDeleteClient: boolean;
            canViewDob: boolean;
            canViewFullSsn: boolean;
            canViewPartialSsn: boolean;
            canViewClientName: boolean;
            canViewClientPhoto: boolean;
            canViewClientAlerts: boolean;
            canManageClientAlerts: boolean;
            canViewEnrollmentDetails: boolean;
            canAuditClients: boolean;
            canManageScanCards: boolean;
            canMergeClients: boolean;
            canViewAnyFiles: boolean;
            canManageAnyClientFiles: boolean;
            canManageOwnClientFiles: boolean;
            canUploadClientFiles: boolean;
          };
          externalIds: Array<{
            __typename?: 'ExternalIdentifier';
            id: string;
            identifier?: string | null;
            url?: string | null;
            label: string;
            type: ExternalIdentifierType;
          }>;
          alerts: Array<{
            __typename?: 'ClientAlert';
            id: string;
            note: string;
            expirationDate?: string | null;
            createdAt: string;
            priority: ClientAlertPriorityLevel;
            createdBy?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }>;
        };
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          relationshipToHoH: RelationshipToHoH;
          autoExited: boolean;
          entryDate: string;
          exitDate?: string | null;
          inProgress: boolean;
          currentUnit?: {
            __typename?: 'Unit';
            id: string;
            name: string;
          } | null;
        };
      }>;
    } | null;
  } | null;
};

export type SplitHouseholdMutationVariables = Exact<{
  splittingEnrollmentInputs:
    | Array<EnrollmentRelationshipInput>
    | EnrollmentRelationshipInput;
}>;

export type SplitHouseholdMutation = {
  __typename?: 'Mutation';
  splitHousehold?: {
    __typename?: 'SplitHouseholdPayload';
    newHousehold: {
      __typename?: 'Household';
      id: string;
      householdSize: number;
      shortId: string;
      householdClients: Array<{
        __typename?: 'HouseholdClient';
        id: string;
        relationshipToHoH: RelationshipToHoH;
        client: {
          __typename?: 'Client';
          id: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
          dob?: string | null;
          age?: number | null;
          gender: Array<Gender>;
          pronouns: Array<string>;
          ssn?: string | null;
          race: Array<Race>;
          veteranStatus: NoYesReasonsForMissingData;
          access: {
            __typename?: 'ClientAccess';
            id: string;
            canEditClient: boolean;
            canDeleteClient: boolean;
            canViewDob: boolean;
            canViewFullSsn: boolean;
            canViewPartialSsn: boolean;
            canViewClientName: boolean;
            canViewClientPhoto: boolean;
            canViewClientAlerts: boolean;
            canManageClientAlerts: boolean;
            canViewEnrollmentDetails: boolean;
            canAuditClients: boolean;
            canManageScanCards: boolean;
            canMergeClients: boolean;
            canViewAnyFiles: boolean;
            canManageAnyClientFiles: boolean;
            canManageOwnClientFiles: boolean;
            canUploadClientFiles: boolean;
          };
          externalIds: Array<{
            __typename?: 'ExternalIdentifier';
            id: string;
            identifier?: string | null;
            url?: string | null;
            label: string;
            type: ExternalIdentifierType;
          }>;
          alerts: Array<{
            __typename?: 'ClientAlert';
            id: string;
            note: string;
            expirationDate?: string | null;
            createdAt: string;
            priority: ClientAlertPriorityLevel;
            createdBy?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }>;
        };
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          relationshipToHoH: RelationshipToHoH;
          autoExited: boolean;
          entryDate: string;
          exitDate?: string | null;
          inProgress: boolean;
          currentUnit?: {
            __typename?: 'Unit';
            id: string;
            name: string;
          } | null;
        };
      }>;
    };
    remainingHousehold: {
      __typename?: 'Household';
      id: string;
      householdSize: number;
      shortId: string;
      householdClients: Array<{
        __typename?: 'HouseholdClient';
        id: string;
        relationshipToHoH: RelationshipToHoH;
        client: {
          __typename?: 'Client';
          id: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
          dob?: string | null;
          age?: number | null;
          gender: Array<Gender>;
          pronouns: Array<string>;
          ssn?: string | null;
          race: Array<Race>;
          veteranStatus: NoYesReasonsForMissingData;
          access: {
            __typename?: 'ClientAccess';
            id: string;
            canEditClient: boolean;
            canDeleteClient: boolean;
            canViewDob: boolean;
            canViewFullSsn: boolean;
            canViewPartialSsn: boolean;
            canViewClientName: boolean;
            canViewClientPhoto: boolean;
            canViewClientAlerts: boolean;
            canManageClientAlerts: boolean;
            canViewEnrollmentDetails: boolean;
            canAuditClients: boolean;
            canManageScanCards: boolean;
            canMergeClients: boolean;
            canViewAnyFiles: boolean;
            canManageAnyClientFiles: boolean;
            canManageOwnClientFiles: boolean;
            canUploadClientFiles: boolean;
          };
          externalIds: Array<{
            __typename?: 'ExternalIdentifier';
            id: string;
            identifier?: string | null;
            url?: string | null;
            label: string;
            type: ExternalIdentifierType;
          }>;
          alerts: Array<{
            __typename?: 'ClientAlert';
            id: string;
            note: string;
            expirationDate?: string | null;
            createdAt: string;
            priority: ClientAlertPriorityLevel;
            createdBy?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }>;
        };
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          relationshipToHoH: RelationshipToHoH;
          autoExited: boolean;
          entryDate: string;
          exitDate?: string | null;
          inProgress: boolean;
          currentUnit?: {
            __typename?: 'Unit';
            id: string;
            name: string;
          } | null;
        };
      }>;
    };
  } | null;
};

export type GetHouseholdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetHouseholdQuery = {
  __typename?: 'Query';
  household?: {
    __typename?: 'Household';
    id: string;
    householdSize: number;
    shortId: string;
    householdClients: Array<{
      __typename?: 'HouseholdClient';
      id: string;
      relationshipToHoH: RelationshipToHoH;
      client: {
        __typename?: 'Client';
        id: string;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
        dob?: string | null;
        age?: number | null;
        gender: Array<Gender>;
        pronouns: Array<string>;
        ssn?: string | null;
        race: Array<Race>;
        veteranStatus: NoYesReasonsForMissingData;
        access: {
          __typename?: 'ClientAccess';
          id: string;
          canEditClient: boolean;
          canDeleteClient: boolean;
          canViewDob: boolean;
          canViewFullSsn: boolean;
          canViewPartialSsn: boolean;
          canViewClientName: boolean;
          canViewClientPhoto: boolean;
          canViewClientAlerts: boolean;
          canManageClientAlerts: boolean;
          canViewEnrollmentDetails: boolean;
          canAuditClients: boolean;
          canManageScanCards: boolean;
          canMergeClients: boolean;
          canViewAnyFiles: boolean;
          canManageAnyClientFiles: boolean;
          canManageOwnClientFiles: boolean;
          canUploadClientFiles: boolean;
        };
        externalIds: Array<{
          __typename?: 'ExternalIdentifier';
          id: string;
          identifier?: string | null;
          url?: string | null;
          label: string;
          type: ExternalIdentifierType;
        }>;
        alerts: Array<{
          __typename?: 'ClientAlert';
          id: string;
          note: string;
          expirationDate?: string | null;
          createdAt: string;
          priority: ClientAlertPriorityLevel;
          createdBy?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }>;
      };
      enrollment: {
        __typename?: 'Enrollment';
        id: string;
        lockVersion: number;
        relationshipToHoH: RelationshipToHoH;
        autoExited: boolean;
        entryDate: string;
        exitDate?: string | null;
        inProgress: boolean;
        currentUnit?: { __typename?: 'Unit'; id: string; name: string } | null;
      };
    }>;
  } | null;
};

export type UpdateRelationshipToHoHMutationVariables = Exact<{
  input: UpdateRelationshipToHoHInput;
}>;

export type UpdateRelationshipToHoHMutation = {
  __typename?: 'Mutation';
  updateRelationshipToHoH?: {
    __typename?: 'UpdateRelationshipToHoHPayload';
    clientMutationId?: string | null;
    enrollment?: {
      __typename?: 'Enrollment';
      id: string;
      relationshipToHoH: RelationshipToHoH;
      household: {
        __typename?: 'Household';
        id: string;
        householdClients: Array<{
          __typename?: 'HouseholdClient';
          id: string;
          relationshipToHoH: RelationshipToHoH;
          client: { __typename?: 'Client'; id: string };
          enrollment: {
            __typename?: 'Enrollment';
            id: string;
            lockVersion: number;
          };
        }>;
      };
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type InventoryFieldsFragment = {
  __typename?: 'Inventory';
  availability?: Availability | null;
  bedInventory: number;
  chBedInventory?: number | null;
  chVetBedInventory?: number | null;
  chYouthBedInventory?: number | null;
  cocCode?: string | null;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  esBedType?: BedType | null;
  householdType?: HouseholdType | null;
  id: string;
  inventoryEndDate?: string | null;
  inventoryStartDate?: string | null;
  otherBedInventory?: number | null;
  unitInventory: number;
  vetBedInventory?: number | null;
  youthBedInventory?: number | null;
  youthVetBedInventory?: number | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
};

export type MciMatchFieldsFragment = {
  __typename?: 'MciClearanceMatch';
  id: string;
  score: number;
  existingClientId?: string | null;
  age: number;
  dob: string;
  firstName: string;
  gender: Array<Gender>;
  lastName: string;
  mciId: string;
  middleName?: string | null;
  nameSuffix?: string | null;
  ssn?: string | null;
};

export type ClearMciMutationVariables = Exact<{
  input: ClearMciInput;
}>;

export type ClearMciMutation = {
  __typename?: 'Mutation';
  clearMci?: {
    __typename?: 'ClearMciPayload';
    clientMutationId?: string | null;
    matches?: Array<{
      __typename?: 'MciClearanceMatch';
      id: string;
      score: number;
      existingClientId?: string | null;
      age: number;
      dob: string;
      firstName: string;
      gender: Array<Gender>;
      lastName: string;
      mciId: string;
      middleName?: string | null;
      nameSuffix?: string | null;
      ssn?: string | null;
    }> | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type OmniSearchClientsQueryVariables = Exact<{
  textSearch: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type OmniSearchClientsQuery = {
  __typename?: 'Query';
  clientOmniSearch: {
    __typename?: 'ClientsPaginated';
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'Client';
      id: string;
      dob?: string | null;
      age?: number | null;
      gender: Array<Gender>;
      personalId: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
    }>;
  };
};

export type OmniSearchProjectsQueryVariables = Exact<{
  searchTerm: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type OmniSearchProjectsQuery = {
  __typename?: 'Query';
  projects: {
    __typename?: 'ProjectsPaginated';
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'Project';
      id: string;
      projectName: string;
      projectType?: ProjectType | null;
    }>;
  };
};

export type GetRecentItemsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecentItemsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'ApplicationUser';
    id: string;
    recentItems: Array<
      | {
          __typename?: 'Client';
          id: string;
          dob?: string | null;
          age?: number | null;
          gender: Array<Gender>;
          personalId: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
        }
      | {
          __typename?: 'Project';
          id: string;
          projectName: string;
          projectType?: ProjectType | null;
        }
    >;
  } | null;
};

export type AddRecentItemMutationVariables = Exact<{
  itemId: Scalars['ID']['input'];
  itemType: RecentItemType;
}>;

export type AddRecentItemMutation = {
  __typename?: 'Mutation';
  addRecentItem?: {
    __typename?: 'ApplicationUser';
    id: string;
    recentItems: Array<
      | {
          __typename?: 'Client';
          id: string;
          dob?: string | null;
          age?: number | null;
          gender: Array<Gender>;
          personalId: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
        }
      | {
          __typename?: 'Project';
          id: string;
          projectName: string;
          projectType?: ProjectType | null;
        }
    >;
  } | null;
};

export type ClearRecentItemsMutationVariables = Exact<{ [key: string]: never }>;

export type ClearRecentItemsMutation = {
  __typename?: 'Mutation';
  clearRecentItems?: {
    __typename?: 'ApplicationUser';
    id: string;
    recentItems: Array<
      | {
          __typename?: 'Client';
          id: string;
          dob?: string | null;
          age?: number | null;
          gender: Array<Gender>;
          personalId: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
        }
      | {
          __typename?: 'Project';
          id: string;
          projectName: string;
          projectType?: ProjectType | null;
        }
    >;
  } | null;
};

export type OrganizationNameFieldsFragment = {
  __typename?: 'Organization';
  id: string;
  hudId: string;
  organizationName: string;
};

export type OrganizationDetailFieldsFragment = {
  __typename?: 'Organization';
  id: string;
  hudId: string;
  description?: string | null;
  contactInformation?: string | null;
  victimServiceProvider: NoYesMissing;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
};

export type OrganizationFieldsFragment = {
  __typename?: 'Organization';
  id: string;
  hudId: string;
  organizationName: string;
  description?: string | null;
  contactInformation?: string | null;
  victimServiceProvider: NoYesMissing;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
};

export type GetOrganizationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<OrganizationFilterOptions>;
}>;

export type GetOrganizationsQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrganizationsPaginated';
    nodesCount: number;
    nodes: Array<{
      __typename?: 'Organization';
      id: string;
      hudId: string;
      organizationName: string;
      projects: { __typename?: 'ProjectsPaginated'; nodesCount: number };
    }>;
  };
};

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    hudId: string;
    organizationName: string;
    description?: string | null;
    contactInformation?: string | null;
    victimServiceProvider: NoYesMissing;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    dateDeleted?: string | null;
    access: {
      __typename?: 'OrganizationAccess';
      id: string;
      canEditOrganization: boolean;
      canDeleteOrganization: boolean;
    };
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
  } | null;
};

export type GetOrganizationProjectsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ProjectsForEnrollmentFilterOptions>;
  sortOrder?: InputMaybe<ProjectSortOption>;
}>;

export type GetOrganizationProjectsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    projects: {
      __typename?: 'ProjectsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Project';
        id: string;
        projectName: string;
        projectType?: ProjectType | null;
        operatingEndDate?: string | null;
        operatingStartDate?: string | null;
      }>;
    };
  } | null;
};

export type DeleteOrganizationMutationVariables = Exact<{
  input: DeleteOrganizationInput;
}>;

export type DeleteOrganizationMutation = {
  __typename?: 'Mutation';
  deleteOrganization?: {
    __typename?: 'DeleteOrganizationPayload';
    clientMutationId?: string | null;
    organization?: { __typename?: 'Organization'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type ProjectNameAndTypeFragment = {
  __typename?: 'Project';
  id: string;
  projectName: string;
  projectType?: ProjectType | null;
};

export type ProjectOperatingPeriodFragment = {
  __typename?: 'Project';
  id: string;
  operatingEndDate?: string | null;
  operatingStartDate?: string | null;
};

export type ProjectCocCountFragment = {
  __typename?: 'Project';
  projectCocs: { __typename?: 'ProjectCocsPaginated'; nodesCount: number };
};

export type ProjectAllFieldsFragment = {
  __typename?: 'Project';
  id: string;
  hudId: string;
  description?: string | null;
  HOPWAMedAssistedLivingFac?: HopwaMedAssistedLivingFac | null;
  contactInformation?: string | null;
  continuumProject?: NoYes | null;
  housingType?: HousingType | null;
  residentialAffiliation?: NoYes | null;
  residentialAffiliationProjectIds: Array<string>;
  rrhSubType?: RrhSubType | null;
  staffAssignmentsEnabled: boolean;
  targetPopulation?: TargetPopulation | null;
  projectName: string;
  projectType?: ProjectType | null;
  operatingEndDate?: string | null;
  operatingStartDate?: string | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    hudId: string;
    organizationName: string;
  };
  access: {
    __typename?: 'ProjectAccess';
    id: string;
    canDeleteProject: boolean;
    canEnrollClients: boolean;
    canEditEnrollments: boolean;
    canViewEnrollmentDetails: boolean;
    canEditProjectDetails: boolean;
    canViewUnits: boolean;
    canManageUnits: boolean;
    canManageIncomingReferrals: boolean;
    canManageOutgoingReferrals: boolean;
    canManageExternalFormSubmissions: boolean;
    canSplitHouseholds: boolean;
  };
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
  dataCollectionFeatures: Array<{
    __typename?: 'DataCollectionFeature';
    id: string;
    role: DataCollectionFeatureRole;
    dataCollectedAbout: DataCollectedAbout;
    legacy: boolean;
  }>;
  serviceTypes: Array<{
    __typename?: 'ServiceType';
    id: string;
    name: string;
    hud: boolean;
    hudRecordType?: RecordType | null;
    hudTypeProvided?: ServiceTypeProvided | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    supportsBulkAssignment: boolean;
    serviceCategory: {
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
    };
  }>;
  projectCocs: { __typename?: 'ProjectCocsPaginated'; nodesCount: number };
};

export type DataCollectionFeatureFieldsFragment = {
  __typename?: 'DataCollectionFeature';
  id: string;
  role: DataCollectionFeatureRole;
  dataCollectedAbout: DataCollectedAbout;
  legacy: boolean;
};

export type OccurrencePointFormFieldsFragment = {
  __typename?: 'OccurrencePointForm';
  id: string;
  dataCollectedAbout: DataCollectedAbout;
  definition: {
    __typename?: 'FormDefinition';
    id: string;
    role: FormRole;
    title: string;
    cacheKey: string;
    identifier: string;
    status: FormStatus;
    dateUpdated: string;
    supportsSaveInProgress: boolean;
    definition: {
      __typename?: 'FormDefinitionJson';
      item: Array<{
        __typename: 'FormItem';
        linkId: string;
        type: ItemType;
        component?: Component | null;
        prefix?: string | null;
        text?: string | null;
        briefText?: string | null;
        readonlyText?: string | null;
        helperText?: string | null;
        required?: boolean | null;
        warnIfEmpty?: boolean | null;
        hidden?: boolean | null;
        readOnly?: boolean | null;
        repeats?: boolean | null;
        pickListReference?: string | null;
        size?: InputSize | null;
        assessmentDate?: boolean | null;
        prefill?: boolean | null;
        dataCollectedAbout?: DataCollectedAbout | null;
        disabledDisplay?: DisabledDisplay | null;
        enableBehavior?: EnableBehavior | null;
        rule?: any | null;
        customRule?: any | null;
        editorUserIds?: Array<string> | null;
        item?: Array<{
          __typename: 'FormItem';
          linkId: string;
          type: ItemType;
          component?: Component | null;
          prefix?: string | null;
          text?: string | null;
          briefText?: string | null;
          readonlyText?: string | null;
          helperText?: string | null;
          required?: boolean | null;
          warnIfEmpty?: boolean | null;
          hidden?: boolean | null;
          readOnly?: boolean | null;
          repeats?: boolean | null;
          pickListReference?: string | null;
          size?: InputSize | null;
          assessmentDate?: boolean | null;
          prefill?: boolean | null;
          dataCollectedAbout?: DataCollectedAbout | null;
          disabledDisplay?: DisabledDisplay | null;
          enableBehavior?: EnableBehavior | null;
          rule?: any | null;
          customRule?: any | null;
          editorUserIds?: Array<string> | null;
          item?: Array<{
            __typename: 'FormItem';
            linkId: string;
            type: ItemType;
            component?: Component | null;
            prefix?: string | null;
            text?: string | null;
            briefText?: string | null;
            readonlyText?: string | null;
            helperText?: string | null;
            required?: boolean | null;
            warnIfEmpty?: boolean | null;
            hidden?: boolean | null;
            readOnly?: boolean | null;
            repeats?: boolean | null;
            pickListReference?: string | null;
            size?: InputSize | null;
            assessmentDate?: boolean | null;
            prefill?: boolean | null;
            dataCollectedAbout?: DataCollectedAbout | null;
            disabledDisplay?: DisabledDisplay | null;
            enableBehavior?: EnableBehavior | null;
            rule?: any | null;
            customRule?: any | null;
            editorUserIds?: Array<string> | null;
            item?: Array<{
              __typename: 'FormItem';
              linkId: string;
              type: ItemType;
              component?: Component | null;
              prefix?: string | null;
              text?: string | null;
              briefText?: string | null;
              readonlyText?: string | null;
              helperText?: string | null;
              required?: boolean | null;
              warnIfEmpty?: boolean | null;
              hidden?: boolean | null;
              readOnly?: boolean | null;
              repeats?: boolean | null;
              pickListReference?: string | null;
              size?: InputSize | null;
              assessmentDate?: boolean | null;
              prefill?: boolean | null;
              dataCollectedAbout?: DataCollectedAbout | null;
              disabledDisplay?: DisabledDisplay | null;
              enableBehavior?: EnableBehavior | null;
              rule?: any | null;
              customRule?: any | null;
              editorUserIds?: Array<string> | null;
              item?: Array<{
                __typename: 'FormItem';
                linkId: string;
                type: ItemType;
                component?: Component | null;
                prefix?: string | null;
                text?: string | null;
                briefText?: string | null;
                readonlyText?: string | null;
                helperText?: string | null;
                required?: boolean | null;
                warnIfEmpty?: boolean | null;
                hidden?: boolean | null;
                readOnly?: boolean | null;
                repeats?: boolean | null;
                pickListReference?: string | null;
                size?: InputSize | null;
                assessmentDate?: boolean | null;
                prefill?: boolean | null;
                dataCollectedAbout?: DataCollectedAbout | null;
                disabledDisplay?: DisabledDisplay | null;
                enableBehavior?: EnableBehavior | null;
                rule?: any | null;
                customRule?: any | null;
                editorUserIds?: Array<string> | null;
                mapping?: {
                  __typename?: 'FieldMapping';
                  recordType?: RelatedRecordType | null;
                  fieldName?: string | null;
                  customFieldKey?: string | null;
                } | null;
                bounds?: Array<{
                  __typename?: 'ValueBound';
                  id: string;
                  severity: ValidationSeverity;
                  type: BoundType;
                  question?: string | null;
                  valueNumber?: number | null;
                  valueDate?: string | null;
                  valueLocalConstant?: string | null;
                  offset?: number | null;
                }> | null;
                pickListOptions?: Array<{
                  __typename?: 'PickListOption';
                  code: string;
                  label?: string | null;
                  secondaryLabel?: string | null;
                  groupLabel?: string | null;
                  groupCode?: string | null;
                  initialSelected?: boolean | null;
                  helperText?: string | null;
                  numericValue?: number | null;
                }> | null;
                initial?: Array<{
                  __typename?: 'InitialValue';
                  valueCode?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  valueLocalConstant?: string | null;
                  initialBehavior: InitialBehavior;
                }> | null;
                enableWhen?: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }> | null;
                autofillValues?: Array<{
                  __typename?: 'AutofillValue';
                  valueCode?: string | null;
                  valueQuestion?: string | null;
                  valueBoolean?: boolean | null;
                  valueNumber?: number | null;
                  sumQuestions?: Array<string> | null;
                  formula?: string | null;
                  autofillBehavior: EnableBehavior;
                  autofillReadonly?: boolean | null;
                  autofillWhen: Array<{
                    __typename?: 'EnableWhen';
                    question?: string | null;
                    localConstant?: string | null;
                    operator: EnableOperator;
                    answerCode?: string | null;
                    answerCodes?: Array<string> | null;
                    answerNumber?: number | null;
                    answerBoolean?: boolean | null;
                    answerGroupCode?: string | null;
                    answerDate?: string | null;
                    compareQuestion?: string | null;
                  }>;
                }> | null;
              }> | null;
              mapping?: {
                __typename?: 'FieldMapping';
                recordType?: RelatedRecordType | null;
                fieldName?: string | null;
                customFieldKey?: string | null;
              } | null;
              bounds?: Array<{
                __typename?: 'ValueBound';
                id: string;
                severity: ValidationSeverity;
                type: BoundType;
                question?: string | null;
                valueNumber?: number | null;
                valueDate?: string | null;
                valueLocalConstant?: string | null;
                offset?: number | null;
              }> | null;
              pickListOptions?: Array<{
                __typename?: 'PickListOption';
                code: string;
                label?: string | null;
                secondaryLabel?: string | null;
                groupLabel?: string | null;
                groupCode?: string | null;
                initialSelected?: boolean | null;
                helperText?: string | null;
                numericValue?: number | null;
              }> | null;
              initial?: Array<{
                __typename?: 'InitialValue';
                valueCode?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                valueLocalConstant?: string | null;
                initialBehavior: InitialBehavior;
              }> | null;
              enableWhen?: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }> | null;
              autofillValues?: Array<{
                __typename?: 'AutofillValue';
                valueCode?: string | null;
                valueQuestion?: string | null;
                valueBoolean?: boolean | null;
                valueNumber?: number | null;
                sumQuestions?: Array<string> | null;
                formula?: string | null;
                autofillBehavior: EnableBehavior;
                autofillReadonly?: boolean | null;
                autofillWhen: Array<{
                  __typename?: 'EnableWhen';
                  question?: string | null;
                  localConstant?: string | null;
                  operator: EnableOperator;
                  answerCode?: string | null;
                  answerCodes?: Array<string> | null;
                  answerNumber?: number | null;
                  answerBoolean?: boolean | null;
                  answerGroupCode?: string | null;
                  answerDate?: string | null;
                  compareQuestion?: string | null;
                }>;
              }> | null;
            }> | null;
            mapping?: {
              __typename?: 'FieldMapping';
              recordType?: RelatedRecordType | null;
              fieldName?: string | null;
              customFieldKey?: string | null;
            } | null;
            bounds?: Array<{
              __typename?: 'ValueBound';
              id: string;
              severity: ValidationSeverity;
              type: BoundType;
              question?: string | null;
              valueNumber?: number | null;
              valueDate?: string | null;
              valueLocalConstant?: string | null;
              offset?: number | null;
            }> | null;
            pickListOptions?: Array<{
              __typename?: 'PickListOption';
              code: string;
              label?: string | null;
              secondaryLabel?: string | null;
              groupLabel?: string | null;
              groupCode?: string | null;
              initialSelected?: boolean | null;
              helperText?: string | null;
              numericValue?: number | null;
            }> | null;
            initial?: Array<{
              __typename?: 'InitialValue';
              valueCode?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              valueLocalConstant?: string | null;
              initialBehavior: InitialBehavior;
            }> | null;
            enableWhen?: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }> | null;
            autofillValues?: Array<{
              __typename?: 'AutofillValue';
              valueCode?: string | null;
              valueQuestion?: string | null;
              valueBoolean?: boolean | null;
              valueNumber?: number | null;
              sumQuestions?: Array<string> | null;
              formula?: string | null;
              autofillBehavior: EnableBehavior;
              autofillReadonly?: boolean | null;
              autofillWhen: Array<{
                __typename?: 'EnableWhen';
                question?: string | null;
                localConstant?: string | null;
                operator: EnableOperator;
                answerCode?: string | null;
                answerCodes?: Array<string> | null;
                answerNumber?: number | null;
                answerBoolean?: boolean | null;
                answerGroupCode?: string | null;
                answerDate?: string | null;
                compareQuestion?: string | null;
              }>;
            }> | null;
          }> | null;
          mapping?: {
            __typename?: 'FieldMapping';
            recordType?: RelatedRecordType | null;
            fieldName?: string | null;
            customFieldKey?: string | null;
          } | null;
          bounds?: Array<{
            __typename?: 'ValueBound';
            id: string;
            severity: ValidationSeverity;
            type: BoundType;
            question?: string | null;
            valueNumber?: number | null;
            valueDate?: string | null;
            valueLocalConstant?: string | null;
            offset?: number | null;
          }> | null;
          pickListOptions?: Array<{
            __typename?: 'PickListOption';
            code: string;
            label?: string | null;
            secondaryLabel?: string | null;
            groupLabel?: string | null;
            groupCode?: string | null;
            initialSelected?: boolean | null;
            helperText?: string | null;
            numericValue?: number | null;
          }> | null;
          initial?: Array<{
            __typename?: 'InitialValue';
            valueCode?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            valueLocalConstant?: string | null;
            initialBehavior: InitialBehavior;
          }> | null;
          enableWhen?: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }> | null;
          autofillValues?: Array<{
            __typename?: 'AutofillValue';
            valueCode?: string | null;
            valueQuestion?: string | null;
            valueBoolean?: boolean | null;
            valueNumber?: number | null;
            sumQuestions?: Array<string> | null;
            formula?: string | null;
            autofillBehavior: EnableBehavior;
            autofillReadonly?: boolean | null;
            autofillWhen: Array<{
              __typename?: 'EnableWhen';
              question?: string | null;
              localConstant?: string | null;
              operator: EnableOperator;
              answerCode?: string | null;
              answerCodes?: Array<string> | null;
              answerNumber?: number | null;
              answerBoolean?: boolean | null;
              answerGroupCode?: string | null;
              answerDate?: string | null;
              compareQuestion?: string | null;
            }>;
          }> | null;
        }> | null;
        mapping?: {
          __typename?: 'FieldMapping';
          recordType?: RelatedRecordType | null;
          fieldName?: string | null;
          customFieldKey?: string | null;
        } | null;
        bounds?: Array<{
          __typename?: 'ValueBound';
          id: string;
          severity: ValidationSeverity;
          type: BoundType;
          question?: string | null;
          valueNumber?: number | null;
          valueDate?: string | null;
          valueLocalConstant?: string | null;
          offset?: number | null;
        }> | null;
        pickListOptions?: Array<{
          __typename?: 'PickListOption';
          code: string;
          label?: string | null;
          secondaryLabel?: string | null;
          groupLabel?: string | null;
          groupCode?: string | null;
          initialSelected?: boolean | null;
          helperText?: string | null;
          numericValue?: number | null;
        }> | null;
        initial?: Array<{
          __typename?: 'InitialValue';
          valueCode?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          valueLocalConstant?: string | null;
          initialBehavior: InitialBehavior;
        }> | null;
        enableWhen?: Array<{
          __typename?: 'EnableWhen';
          question?: string | null;
          localConstant?: string | null;
          operator: EnableOperator;
          answerCode?: string | null;
          answerCodes?: Array<string> | null;
          answerNumber?: number | null;
          answerBoolean?: boolean | null;
          answerGroupCode?: string | null;
          answerDate?: string | null;
          compareQuestion?: string | null;
        }> | null;
        autofillValues?: Array<{
          __typename?: 'AutofillValue';
          valueCode?: string | null;
          valueQuestion?: string | null;
          valueBoolean?: boolean | null;
          valueNumber?: number | null;
          sumQuestions?: Array<string> | null;
          formula?: string | null;
          autofillBehavior: EnableBehavior;
          autofillReadonly?: boolean | null;
          autofillWhen: Array<{
            __typename?: 'EnableWhen';
            question?: string | null;
            localConstant?: string | null;
            operator: EnableOperator;
            answerCode?: string | null;
            answerCodes?: Array<string> | null;
            answerNumber?: number | null;
            answerBoolean?: boolean | null;
            answerGroupCode?: string | null;
            answerDate?: string | null;
            compareQuestion?: string | null;
          }>;
        }> | null;
      }>;
    };
    updatedBy?: { __typename?: 'ApplicationUser'; name: string } | null;
  };
};

export type ProjectCocFieldsFragment = {
  __typename?: 'ProjectCoc';
  id: string;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  cocCode?: string | null;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  geocode?: string | null;
  geographyType?: GeographyType | null;
  state?: string | null;
  zip?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type FunderFieldsFragment = {
  __typename?: 'Funder';
  id: string;
  dateCreated?: string | null;
  dateDeleted?: string | null;
  dateUpdated?: string | null;
  endDate?: string | null;
  funder: FundingSource;
  grantId?: string | null;
  otherFunder?: string | null;
  startDate?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type CeParticipationFieldsFragment = {
  __typename?: 'CeParticipation';
  id: string;
  accessPoint?: NoYes | null;
  ceParticipationStatusStartDate?: string | null;
  ceParticipationStatusEndDate?: string | null;
  crisisAssessment?: NoYes | null;
  directServices?: NoYes | null;
  housingAssessment?: NoYes | null;
  preventionAssessment?: NoYes | null;
  receivesReferrals?: NoYes | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type HmisParticipationFieldsFragment = {
  __typename?: 'HmisParticipation';
  id: string;
  hmisParticipationType?: HmisParticipationType | null;
  hmisParticipationStatusStartDate?: string | null;
  hmisParticipationStatusEndDate?: string | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type ProjectEnrollmentQueryEnrollmentFieldsFragment = {
  __typename?: 'Enrollment';
  id: string;
  lockVersion: number;
  entryDate: string;
  exitDate?: string | null;
  autoExited: boolean;
  inProgress: boolean;
  relationshipToHoH: RelationshipToHoH;
  enrollmentCoc?: string | null;
  householdId: string;
  householdShortId: string;
  householdSize: number;
  lastCurrentLivingSituation?: {
    __typename?: 'CurrentLivingSituation';
    id: string;
    informationDate?: string | null;
  } | null;
  household?: {
    __typename?: 'Household';
    id: string;
    householdClients: Array<{
      __typename?: 'HouseholdClient';
      id: string;
      relationshipToHoH: RelationshipToHoH;
      client: {
        __typename?: 'Client';
        id: string;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
      };
    }>;
    staffAssignments?: {
      __typename?: 'StaffAssignmentsPaginated';
      nodesCount: number;
      nodes: Array<{
        __typename?: 'StaffAssignment';
        id: string;
        staffAssignmentRelationship: string;
        assignedAt: string;
        unassignedAt?: string | null;
        user: { __typename?: 'ApplicationUser'; id: string; name: string };
        household: { __typename?: 'Household'; id: string };
      }>;
    } | null;
  };
  client: {
    __typename?: 'Client';
    id: string;
    dob?: string | null;
    veteranStatus: NoYesReasonsForMissingData;
    lockVersion: number;
    age?: number | null;
    gender: Array<Gender>;
    pronouns: Array<string>;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
  };
};

export type GetProjectsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ProjectFilterOptions>;
  sortOrder?: InputMaybe<ProjectSortOption>;
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  projects: {
    __typename?: 'ProjectsPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'Project';
      id: string;
      projectName: string;
      projectType?: ProjectType | null;
      operatingEndDate?: string | null;
      operatingStartDate?: string | null;
      organization: {
        __typename?: 'Organization';
        id: string;
        hudId: string;
        organizationName: string;
      };
    }>;
  };
};

export type GetProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    hudId: string;
    description?: string | null;
    HOPWAMedAssistedLivingFac?: HopwaMedAssistedLivingFac | null;
    contactInformation?: string | null;
    continuumProject?: NoYes | null;
    housingType?: HousingType | null;
    residentialAffiliation?: NoYes | null;
    residentialAffiliationProjectIds: Array<string>;
    rrhSubType?: RrhSubType | null;
    staffAssignmentsEnabled: boolean;
    targetPopulation?: TargetPopulation | null;
    projectName: string;
    projectType?: ProjectType | null;
    operatingEndDate?: string | null;
    operatingStartDate?: string | null;
    organization: {
      __typename?: 'Organization';
      id: string;
      hudId: string;
      organizationName: string;
    };
    access: {
      __typename?: 'ProjectAccess';
      id: string;
      canDeleteProject: boolean;
      canEnrollClients: boolean;
      canEditEnrollments: boolean;
      canViewEnrollmentDetails: boolean;
      canEditProjectDetails: boolean;
      canViewUnits: boolean;
      canManageUnits: boolean;
      canManageIncomingReferrals: boolean;
      canManageOutgoingReferrals: boolean;
      canManageExternalFormSubmissions: boolean;
      canSplitHouseholds: boolean;
    };
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
    dataCollectionFeatures: Array<{
      __typename?: 'DataCollectionFeature';
      id: string;
      role: DataCollectionFeatureRole;
      dataCollectedAbout: DataCollectedAbout;
      legacy: boolean;
    }>;
    serviceTypes: Array<{
      __typename?: 'ServiceType';
      id: string;
      name: string;
      hud: boolean;
      hudRecordType?: RecordType | null;
      hudTypeProvided?: ServiceTypeProvided | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      supportsBulkAssignment: boolean;
      serviceCategory: {
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
      };
    }>;
    projectCocs: { __typename?: 'ProjectCocsPaginated'; nodesCount: number };
  } | null;
};

export type GetProjectPermissionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectPermissionsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    access: {
      __typename?: 'ProjectAccess';
      id: string;
      canDeleteProject: boolean;
      canEnrollClients: boolean;
      canEditEnrollments: boolean;
      canViewEnrollmentDetails: boolean;
      canEditProjectDetails: boolean;
      canViewUnits: boolean;
      canManageUnits: boolean;
      canManageIncomingReferrals: boolean;
      canManageOutgoingReferrals: boolean;
      canManageExternalFormSubmissions: boolean;
      canSplitHouseholds: boolean;
    };
  } | null;
};

export type GetProjectEnrollmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  filters?: InputMaybe<EnrollmentsForProjectFilterOptions>;
  sortOrder?: InputMaybe<EnrollmentSortOption>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  includeCls?: InputMaybe<Scalars['Boolean']['input']>;
  includeStaffAssignment?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetProjectEnrollmentsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    enrollments: {
      __typename?: 'EnrollmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Enrollment';
        id: string;
        lockVersion: number;
        entryDate: string;
        exitDate?: string | null;
        autoExited: boolean;
        inProgress: boolean;
        relationshipToHoH: RelationshipToHoH;
        enrollmentCoc?: string | null;
        householdId: string;
        householdShortId: string;
        householdSize: number;
        lastCurrentLivingSituation?: {
          __typename?: 'CurrentLivingSituation';
          id: string;
          informationDate?: string | null;
        } | null;
        household?: {
          __typename?: 'Household';
          id: string;
          householdClients: Array<{
            __typename?: 'HouseholdClient';
            id: string;
            relationshipToHoH: RelationshipToHoH;
            client: {
              __typename?: 'Client';
              id: string;
              lockVersion: number;
              firstName?: string | null;
              middleName?: string | null;
              lastName?: string | null;
              nameSuffix?: string | null;
            };
          }>;
          staffAssignments?: {
            __typename?: 'StaffAssignmentsPaginated';
            nodesCount: number;
            nodes: Array<{
              __typename?: 'StaffAssignment';
              id: string;
              staffAssignmentRelationship: string;
              assignedAt: string;
              unassignedAt?: string | null;
              user: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              };
              household: { __typename?: 'Household'; id: string };
            }>;
          } | null;
        };
        client: {
          __typename?: 'Client';
          id: string;
          dob?: string | null;
          veteranStatus: NoYesReasonsForMissingData;
          lockVersion: number;
          age?: number | null;
          gender: Array<Gender>;
          pronouns: Array<string>;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
        };
      }>;
    };
  } | null;
};

export type GetProjectHouseholdsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  filters?: InputMaybe<HouseholdFilterOptions>;
  sortOrder?: InputMaybe<HouseholdSortOption>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  includeStaffAssignment?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetProjectHouseholdsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    households: {
      __typename?: 'HouseholdsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Household';
        id: string;
        householdSize: number;
        shortId: string;
        householdClients: Array<{
          __typename?: 'HouseholdClient';
          id: string;
          relationshipToHoH: RelationshipToHoH;
          client: {
            __typename?: 'Client';
            id: string;
            lockVersion: number;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            nameSuffix?: string | null;
            dob?: string | null;
            age?: number | null;
            gender: Array<Gender>;
            pronouns: Array<string>;
          };
          enrollment: {
            __typename?: 'Enrollment';
            id: string;
            lockVersion: number;
            entryDate: string;
            exitDate?: string | null;
            inProgress: boolean;
            autoExited: boolean;
          };
        }>;
        staffAssignments?: {
          __typename?: 'StaffAssignmentsPaginated';
          nodesCount: number;
          nodes: Array<{
            __typename?: 'StaffAssignment';
            id: string;
            staffAssignmentRelationship: string;
            assignedAt: string;
            unassignedAt?: string | null;
            user: { __typename?: 'ApplicationUser'; id: string; name: string };
            household: { __typename?: 'Household'; id: string };
          }>;
        } | null;
      }>;
    };
  } | null;
};

export type GetProjectServicesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  filters?: InputMaybe<ServicesForProjectFilterOptions>;
  sortOrder?: InputMaybe<ServiceSortOption>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectServicesQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    services: {
      __typename?: 'ServicesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Service';
        id: string;
        dateProvided?: string | null;
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          entryDate: string;
          exitDate?: string | null;
          inProgress: boolean;
          client: {
            __typename?: 'Client';
            dob?: string | null;
            veteranStatus: NoYesReasonsForMissingData;
            id: string;
            lockVersion: number;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            nameSuffix?: string | null;
          };
        };
        serviceType?: {
          __typename?: 'ServiceType';
          id: string;
          name: string;
          hud: boolean;
          hudRecordType?: RecordType | null;
          hudTypeProvided?: ServiceTypeProvided | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          supportsBulkAssignment: boolean;
          serviceCategory: {
            __typename?: 'ServiceCategory';
            id: string;
            name: string;
          };
        } | null;
      }>;
    };
  } | null;
};

export type GetProjectAssessmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<AssessmentSortOption>;
  filters?: InputMaybe<AssessmentsForProjectFilterOptions>;
}>;

export type GetProjectAssessmentsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    assessments: {
      __typename?: 'AssessmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Assessment';
        id: string;
        lockVersion: number;
        inProgress: boolean;
        assessmentDate: string;
        dataCollectionStage?: DataCollectionStage | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        role: AssessmentRole;
        enrollment: {
          __typename?: 'Enrollment';
          id: string;
          lockVersion: number;
          entryDate: string;
          exitDate?: string | null;
          exitDestination?: Destination | null;
          autoExited: boolean;
          inProgress: boolean;
          relationshipToHoH: RelationshipToHoH;
          enrollmentCoc?: string | null;
          householdId: string;
          householdShortId: string;
          householdSize: number;
          project: {
            __typename?: 'Project';
            id: string;
            projectName: string;
            projectType?: ProjectType | null;
          };
          client: {
            __typename?: 'Client';
            dob?: string | null;
            veteranStatus: NoYesReasonsForMissingData;
            id: string;
            lockVersion: number;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            nameSuffix?: string | null;
          };
          access: {
            __typename?: 'EnrollmentAccess';
            id: string;
            canEditEnrollments: boolean;
            canDeleteEnrollments: boolean;
            canAuditEnrollments: boolean;
            canViewEnrollmentLocationMap: boolean;
          };
          currentUnit?: {
            __typename?: 'Unit';
            id: string;
            name: string;
          } | null;
        };
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        definition: {
          __typename?: 'FormDefinition';
          id: string;
          cacheKey: string;
          title: string;
        };
      }>;
    };
  } | null;
};

export type DeleteProjectMutationVariables = Exact<{
  input: DeleteProjectInput;
}>;

export type DeleteProjectMutation = {
  __typename?: 'Mutation';
  deleteProject?: {
    __typename?: 'DeleteProjectPayload';
    clientMutationId?: string | null;
    project?: { __typename?: 'Project'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetFunderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFunderQuery = {
  __typename?: 'Query';
  funder?: {
    __typename?: 'Funder';
    id: string;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    endDate?: string | null;
    funder: FundingSource;
    grantId?: string | null;
    otherFunder?: string | null;
    startDate?: string | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
};

export type GetInventoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetInventoryQuery = {
  __typename?: 'Query';
  inventory?: {
    __typename?: 'Inventory';
    availability?: Availability | null;
    bedInventory: number;
    chBedInventory?: number | null;
    chVetBedInventory?: number | null;
    chYouthBedInventory?: number | null;
    cocCode?: string | null;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    esBedType?: BedType | null;
    householdType?: HouseholdType | null;
    id: string;
    inventoryEndDate?: string | null;
    inventoryStartDate?: string | null;
    otherBedInventory?: number | null;
    unitInventory: number;
    vetBedInventory?: number | null;
    youthBedInventory?: number | null;
    youthVetBedInventory?: number | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
  } | null;
};

export type GetProjectCocQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectCocQuery = {
  __typename?: 'Query';
  projectCoc?: {
    __typename?: 'ProjectCoc';
    id: string;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    cocCode?: string | null;
    dateCreated?: string | null;
    dateDeleted?: string | null;
    dateUpdated?: string | null;
    geocode?: string | null;
    geographyType?: GeographyType | null;
    state?: string | null;
    zip?: string | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
};

export type GetProjectInventoriesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectInventoriesQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    inventories: {
      __typename?: 'InventoriesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Inventory';
        availability?: Availability | null;
        bedInventory: number;
        chBedInventory?: number | null;
        chVetBedInventory?: number | null;
        chYouthBedInventory?: number | null;
        cocCode?: string | null;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        esBedType?: BedType | null;
        householdType?: HouseholdType | null;
        id: string;
        inventoryEndDate?: string | null;
        inventoryStartDate?: string | null;
        otherBedInventory?: number | null;
        unitInventory: number;
        vetBedInventory?: number | null;
        youthBedInventory?: number | null;
        youthVetBedInventory?: number | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
      }>;
    };
  } | null;
};

export type GetProjectReferralRequestsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectReferralRequestsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    referralRequests: {
      __typename?: 'ReferralRequestsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ReferralRequest';
        id: string;
        requestedOn: string;
        identifier: string;
        neededBy: string;
        requestorName: string;
        requestorPhone: string;
        requestorEmail: string;
        unitType: {
          __typename?: 'UnitTypeObject';
          id: string;
          description?: string | null;
          bedType?: InventoryBedType | null;
          unitSize?: number | null;
          dateUpdated: string;
          dateCreated: string;
        };
      }>;
    };
  } | null;
};

export type GetProjectReferralPostingsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReferralPostingFilterOptions>;
}>;

export type GetProjectReferralPostingsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    incomingReferralPostings: {
      __typename?: 'ReferralPostingsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ReferralPosting';
        id: string;
        referralIdentifier?: string | null;
        referralDate: string;
        hohName: string;
        hohMciId?: string | null;
        householdSize: number;
        referredFrom: string;
        referredBy: string;
        referredTo?: string | null;
        status: ReferralPostingStatus;
        assignedDate: string;
        statusUpdatedAt?: string | null;
        statusUpdatedBy?: string | null;
        project?: {
          __typename?: 'Project';
          id: string;
          projectName: string;
          projectType?: ProjectType | null;
        } | null;
        organization?: {
          __typename?: 'Organization';
          id: string;
          organizationName: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetProjectOutgoingReferralPostingsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReferralPostingFilterOptions>;
}>;

export type GetProjectOutgoingReferralPostingsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    outgoingReferralPostings: {
      __typename?: 'ReferralPostingsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ReferralPosting';
        id: string;
        referralIdentifier?: string | null;
        referralDate: string;
        hohName: string;
        hohMciId?: string | null;
        householdSize: number;
        referredFrom: string;
        referredBy: string;
        referredTo?: string | null;
        status: ReferralPostingStatus;
        assignedDate: string;
        statusUpdatedAt?: string | null;
        statusUpdatedBy?: string | null;
        hohEnrollment?: {
          __typename?: 'Enrollment';
          id: string;
          client: { __typename?: 'Client'; id: string };
        } | null;
        project?: {
          __typename?: 'Project';
          id: string;
          projectName: string;
          projectType?: ProjectType | null;
        } | null;
        organization?: {
          __typename?: 'Organization';
          id: string;
          organizationName: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetProjectProjectCocsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectProjectCocsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    projectCocs: {
      __typename?: 'ProjectCocsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ProjectCoc';
        id: string;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        cocCode?: string | null;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        geocode?: string | null;
        geographyType?: GeographyType | null;
        state?: string | null;
        zip?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetProjectFundersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectFundersQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    funders: {
      __typename?: 'FundersPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Funder';
        id: string;
        dateCreated?: string | null;
        dateDeleted?: string | null;
        dateUpdated?: string | null;
        endDate?: string | null;
        funder: FundingSource;
        grantId?: string | null;
        otherFunder?: string | null;
        startDate?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetProjectCeParticipationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectCeParticipationsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    ceParticipations: {
      __typename?: 'CeParticipationsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'CeParticipation';
        id: string;
        accessPoint?: NoYes | null;
        ceParticipationStatusStartDate?: string | null;
        ceParticipationStatusEndDate?: string | null;
        crisisAssessment?: NoYes | null;
        directServices?: NoYes | null;
        housingAssessment?: NoYes | null;
        preventionAssessment?: NoYes | null;
        receivesReferrals?: NoYes | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type GetProjectHmisParticipationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectHmisParticipationsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    hmisParticipations: {
      __typename?: 'HmisParticipationsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'HmisParticipation';
        id: string;
        hmisParticipationType?: HmisParticipationType | null;
        hmisParticipationStatusStartDate?: string | null;
        hmisParticipationStatusEndDate?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type DeleteInventoryMutationVariables = Exact<{
  input: DeleteInventoryInput;
}>;

export type DeleteInventoryMutation = {
  __typename?: 'Mutation';
  deleteInventory?: {
    __typename?: 'DeleteInventoryPayload';
    clientMutationId?: string | null;
    inventory?: { __typename?: 'Inventory'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteFunderMutationVariables = Exact<{
  input: DeleteFunderInput;
}>;

export type DeleteFunderMutation = {
  __typename?: 'Mutation';
  deleteFunder?: {
    __typename?: 'DeleteFunderPayload';
    clientMutationId?: string | null;
    funder?: { __typename?: 'Funder'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteProjectCocMutationVariables = Exact<{
  input: DeleteProjectCocInput;
}>;

export type DeleteProjectCocMutation = {
  __typename?: 'Mutation';
  deleteProjectCoc?: {
    __typename?: 'DeleteProjectCocPayload';
    clientMutationId?: string | null;
    projectCoc?: { __typename?: 'ProjectCoc'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteHmisParticipationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteHmisParticipationMutation = {
  __typename?: 'Mutation';
  deleteHmisParticipation?: {
    __typename?: 'DeleteHmisParticipationPayload';
    hmisParticipation?: { __typename?: 'HmisParticipation'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteCeParticipationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCeParticipationMutation = {
  __typename?: 'Mutation';
  deleteCeParticipation?: {
    __typename?: 'DeleteCeParticipationPayload';
    ceParticipation?: { __typename?: 'CeParticipation'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type ProjectConfigFieldsFragment = {
  __typename?: 'ProjectConfig';
  id: string;
  configType: ProjectConfigType;
  organizationId?: string | null;
  projectId?: string | null;
  projectType?: ProjectType | null;
  configOptions?: any | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    organizationName: string;
  } | null;
  project?: { __typename?: 'Project'; id: string; projectName: string } | null;
};

export type CreateProjectConfigMutationVariables = Exact<{
  input: ProjectConfigInput;
}>;

export type CreateProjectConfigMutation = {
  __typename?: 'Mutation';
  createProjectConfig?: {
    __typename?: 'CreateProjectConfigPayload';
    projectConfig?: {
      __typename?: 'ProjectConfig';
      id: string;
      configType: ProjectConfigType;
      organizationId?: string | null;
      projectId?: string | null;
      projectType?: ProjectType | null;
      configOptions?: any | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        organizationName: string;
      } | null;
      project?: {
        __typename?: 'Project';
        id: string;
        projectName: string;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type UpdateProjectConfigMutationVariables = Exact<{
  input: ProjectConfigInput;
  id: Scalars['ID']['input'];
}>;

export type UpdateProjectConfigMutation = {
  __typename?: 'Mutation';
  updateProjectConfig?: {
    __typename?: 'UpdateProjectConfigPayload';
    projectConfig?: {
      __typename?: 'ProjectConfig';
      id: string;
      configType: ProjectConfigType;
      organizationId?: string | null;
      projectId?: string | null;
      projectType?: ProjectType | null;
      configOptions?: any | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        organizationName: string;
      } | null;
      project?: {
        __typename?: 'Project';
        id: string;
        projectName: string;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteProjectConfigMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteProjectConfigMutation = {
  __typename?: 'Mutation';
  deleteProjectConfig?: {
    __typename?: 'DeleteProjectConfigPayload';
    projectConfig?: {
      __typename?: 'ProjectConfig';
      id: string;
      configType: ProjectConfigType;
      organizationId?: string | null;
      projectId?: string | null;
      projectType?: ProjectType | null;
      configOptions?: any | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        organizationName: string;
      } | null;
      project?: {
        __typename?: 'Project';
        id: string;
        projectName: string;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetProjectConfigsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectConfigsQuery = {
  __typename?: 'Query';
  projectConfigs: {
    __typename?: 'ProjectConfigsPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'ProjectConfig';
      id: string;
      configType: ProjectConfigType;
      organizationId?: string | null;
      projectId?: string | null;
      projectType?: ProjectType | null;
      configOptions?: any | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        organizationName: string;
      } | null;
      project?: {
        __typename?: 'Project';
        id: string;
        projectName: string;
      } | null;
    }>;
  };
};

export type VoidReferralRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type VoidReferralRequestMutation = {
  __typename?: 'Mutation';
  voidReferralRequest?: {
    __typename?: 'VoidReferralRequestPayload';
    record?: { __typename?: 'ReferralRequest'; id: string } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type UpdateReferralPostingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ReferralPostingInput;
}>;

export type UpdateReferralPostingMutation = {
  __typename?: 'Mutation';
  updateReferralPosting?: {
    __typename?: 'UpdateReferralPostingPayload';
    record?: {
      __typename?: 'ReferralPosting';
      id: string;
      assignedDate: string;
      chronic?: boolean | null;
      hudChronic?: boolean | null;
      denialNote?: string | null;
      denialReason?: ReferralPostingDenialReasonType | null;
      needsWheelchairAccessibleUnit?: boolean | null;
      postingIdentifier?: string | null;
      referralDate: string;
      referralIdentifier?: string | null;
      referralNotes?: string | null;
      referralResult?: ReferralResult | null;
      referredBy: string;
      referredFrom: string;
      referredTo?: string | null;
      resourceCoordinatorNotes?: string | null;
      score?: number | null;
      status: ReferralPostingStatus;
      statusNote?: string | null;
      statusNoteUpdatedAt?: string | null;
      statusNoteUpdatedBy?: string | null;
      statusUpdatedAt?: string | null;
      statusUpdatedBy?: string | null;
      referralRequest?: { __typename?: 'ReferralRequest'; id: string } | null;
      project?: {
        __typename?: 'Project';
        id: string;
        projectType?: ProjectType | null;
        projectName: string;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        organizationName: string;
      } | null;
      unitType?: {
        __typename?: 'UnitTypeObject';
        id: string;
        description?: string | null;
      } | null;
      hohEnrollment?: {
        __typename?: 'Enrollment';
        id: string;
        client: { __typename?: 'Client'; id: string };
      } | null;
      householdMembers: Array<{
        __typename?: 'ReferralHouseholdMember';
        id: string;
        relationshipToHoH: RelationshipToHoH;
        openEnrollmentSummary: Array<{
          __typename?: 'EnrollmentSummary';
          id: string;
          entryDate: string;
          inProgress: boolean;
          moveInDate?: string | null;
          projectId: string;
          projectName: string;
          projectType: ProjectType;
          canViewEnrollment: boolean;
        }>;
        client: {
          __typename?: 'Client';
          id: string;
          veteranStatus: NoYesReasonsForMissingData;
          gender: Array<Gender>;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
          dob?: string | null;
          age?: number | null;
          pronouns: Array<string>;
          externalIds: Array<{
            __typename?: 'ExternalIdentifier';
            id: string;
            identifier?: string | null;
            url?: string | null;
            label: string;
            type: ExternalIdentifierType;
          }>;
        };
      }>;
      customDataElements: Array<{
        __typename?: 'CustomDataElement';
        id: string;
        key: string;
        label: string;
        fieldType: CustomDataElementType;
        repeats: boolean;
        displayHooks: Array<DisplayHook>;
        value?: {
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        values?: Array<{
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }> | null;
      }>;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetProjectCanAcceptReferralQueryVariables = Exact<{
  sourceEnrollmentId: Scalars['ID']['input'];
  destinationProjectId: Scalars['ID']['input'];
}>;

export type GetProjectCanAcceptReferralQuery = {
  __typename?: 'Query';
  projectCanAcceptReferral: boolean;
};

export type GetReferralPostingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetReferralPostingQuery = {
  __typename?: 'Query';
  referralPosting?: {
    __typename?: 'ReferralPosting';
    id: string;
    assignedDate: string;
    chronic?: boolean | null;
    hudChronic?: boolean | null;
    denialNote?: string | null;
    denialReason?: ReferralPostingDenialReasonType | null;
    needsWheelchairAccessibleUnit?: boolean | null;
    postingIdentifier?: string | null;
    referralDate: string;
    referralIdentifier?: string | null;
    referralNotes?: string | null;
    referralResult?: ReferralResult | null;
    referredBy: string;
    referredFrom: string;
    referredTo?: string | null;
    resourceCoordinatorNotes?: string | null;
    score?: number | null;
    status: ReferralPostingStatus;
    statusNote?: string | null;
    statusNoteUpdatedAt?: string | null;
    statusNoteUpdatedBy?: string | null;
    statusUpdatedAt?: string | null;
    statusUpdatedBy?: string | null;
    referralRequest?: { __typename?: 'ReferralRequest'; id: string } | null;
    project?: {
      __typename?: 'Project';
      id: string;
      projectType?: ProjectType | null;
      projectName: string;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      organizationName: string;
    } | null;
    unitType?: {
      __typename?: 'UnitTypeObject';
      id: string;
      description?: string | null;
    } | null;
    hohEnrollment?: {
      __typename?: 'Enrollment';
      id: string;
      client: { __typename?: 'Client'; id: string };
    } | null;
    householdMembers: Array<{
      __typename?: 'ReferralHouseholdMember';
      id: string;
      relationshipToHoH: RelationshipToHoH;
      openEnrollmentSummary: Array<{
        __typename?: 'EnrollmentSummary';
        id: string;
        entryDate: string;
        inProgress: boolean;
        moveInDate?: string | null;
        projectId: string;
        projectName: string;
        projectType: ProjectType;
        canViewEnrollment: boolean;
      }>;
      client: {
        __typename?: 'Client';
        id: string;
        veteranStatus: NoYesReasonsForMissingData;
        gender: Array<Gender>;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
        dob?: string | null;
        age?: number | null;
        pronouns: Array<string>;
        externalIds: Array<{
          __typename?: 'ExternalIdentifier';
          id: string;
          identifier?: string | null;
          url?: string | null;
          label: string;
          type: ExternalIdentifierType;
        }>;
      };
    }>;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
  } | null;
};

export type GetDeniedPendingReferralPostingsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReferralPostingFilterOptions>;
}>;

export type GetDeniedPendingReferralPostingsQuery = {
  __typename?: 'Query';
  deniedPendingReferralPostings: {
    __typename?: 'ReferralPostingsPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename?: 'ReferralPosting';
      id: string;
      referralIdentifier?: string | null;
      referralDate: string;
      hohName: string;
      hohMciId?: string | null;
      householdSize: number;
      referredFrom: string;
      referredBy: string;
      referredTo?: string | null;
      status: ReferralPostingStatus;
      assignedDate: string;
      statusUpdatedAt?: string | null;
      statusUpdatedBy?: string | null;
      project?: {
        __typename?: 'Project';
        id: string;
        projectName: string;
        projectType?: ProjectType | null;
      } | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        organizationName: string;
      } | null;
    }>;
  };
};

export type ReferralPostingFieldsFragment = {
  __typename?: 'ReferralPosting';
  id: string;
  referralIdentifier?: string | null;
  referralDate: string;
  hohName: string;
  hohMciId?: string | null;
  householdSize: number;
  referredFrom: string;
  referredBy: string;
  referredTo?: string | null;
  status: ReferralPostingStatus;
  assignedDate: string;
  statusUpdatedAt?: string | null;
  statusUpdatedBy?: string | null;
  project?: {
    __typename?: 'Project';
    id: string;
    projectName: string;
    projectType?: ProjectType | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    organizationName: string;
  } | null;
};

export type ReferralPostingDetailFieldsFragment = {
  __typename?: 'ReferralPosting';
  id: string;
  assignedDate: string;
  chronic?: boolean | null;
  hudChronic?: boolean | null;
  denialNote?: string | null;
  denialReason?: ReferralPostingDenialReasonType | null;
  needsWheelchairAccessibleUnit?: boolean | null;
  postingIdentifier?: string | null;
  referralDate: string;
  referralIdentifier?: string | null;
  referralNotes?: string | null;
  referralResult?: ReferralResult | null;
  referredBy: string;
  referredFrom: string;
  referredTo?: string | null;
  resourceCoordinatorNotes?: string | null;
  score?: number | null;
  status: ReferralPostingStatus;
  statusNote?: string | null;
  statusNoteUpdatedAt?: string | null;
  statusNoteUpdatedBy?: string | null;
  statusUpdatedAt?: string | null;
  statusUpdatedBy?: string | null;
  referralRequest?: { __typename?: 'ReferralRequest'; id: string } | null;
  project?: {
    __typename?: 'Project';
    id: string;
    projectType?: ProjectType | null;
    projectName: string;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    organizationName: string;
  } | null;
  unitType?: {
    __typename?: 'UnitTypeObject';
    id: string;
    description?: string | null;
  } | null;
  hohEnrollment?: {
    __typename?: 'Enrollment';
    id: string;
    client: { __typename?: 'Client'; id: string };
  } | null;
  householdMembers: Array<{
    __typename?: 'ReferralHouseholdMember';
    id: string;
    relationshipToHoH: RelationshipToHoH;
    openEnrollmentSummary: Array<{
      __typename?: 'EnrollmentSummary';
      id: string;
      entryDate: string;
      inProgress: boolean;
      moveInDate?: string | null;
      projectId: string;
      projectName: string;
      projectType: ProjectType;
      canViewEnrollment: boolean;
    }>;
    client: {
      __typename?: 'Client';
      id: string;
      veteranStatus: NoYesReasonsForMissingData;
      gender: Array<Gender>;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
      dob?: string | null;
      age?: number | null;
      pronouns: Array<string>;
      externalIds: Array<{
        __typename?: 'ExternalIdentifier';
        id: string;
        identifier?: string | null;
        url?: string | null;
        label: string;
        type: ExternalIdentifierType;
      }>;
    };
  }>;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
};

export type ReferralRequestFieldsFragment = {
  __typename?: 'ReferralRequest';
  id: string;
  requestedOn: string;
  identifier: string;
  neededBy: string;
  requestorName: string;
  requestorPhone: string;
  requestorEmail: string;
  unitType: {
    __typename?: 'UnitTypeObject';
    id: string;
    description?: string | null;
    bedType?: InventoryBedType | null;
    unitSize?: number | null;
    dateUpdated: string;
    dateCreated: string;
  };
};

export type ReminderFieldsFragment = {
  __typename?: 'Reminder';
  id: string;
  topic: ReminderTopic;
  dueDate?: string | null;
  overdue: boolean;
  formDefinitionId?: string | null;
  assessmentId?: string | null;
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    relationshipToHoH: RelationshipToHoH;
    intakeAssessment?: { __typename?: 'Assessment'; id: string } | null;
    exitAssessment?: { __typename?: 'Assessment'; id: string } | null;
  };
  client: {
    __typename?: 'Client';
    id: string;
    dob?: string | null;
    veteranStatus: NoYesReasonsForMissingData;
    lockVersion: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    nameSuffix?: string | null;
  };
};

export type EsgFundingServiceFieldsFragment = {
  __typename?: 'EsgFundingService';
  id: string;
  clientId: string;
  clientDob?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  projectId: string;
  projectName: string;
  organizationId: string;
  organizationName: string;
  faAmount?: number | null;
  faStartDate?: string | null;
  faEndDate?: string | null;
  mciIds: Array<{
    __typename?: 'ExternalIdentifier';
    id: string;
    identifier?: string | null;
    url?: string | null;
    label: string;
    type: ExternalIdentifierType;
  }>;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
};

export type GetEsgFundingReportQueryVariables = Exact<{
  clientIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetEsgFundingReportQuery = {
  __typename?: 'Query';
  esgFundingReport: Array<{
    __typename?: 'EsgFundingService';
    id: string;
    clientId: string;
    clientDob?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    projectId: string;
    projectName: string;
    organizationId: string;
    organizationName: string;
    faAmount?: number | null;
    faStartDate?: string | null;
    faEndDate?: string | null;
    mciIds: Array<{
      __typename?: 'ExternalIdentifier';
      id: string;
      identifier?: string | null;
      url?: string | null;
      label: string;
      type: ExternalIdentifierType;
    }>;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
  }>;
};

export type ScanCardFieldsFragment = {
  __typename?: 'ScanCardCode';
  id: string;
  value: string;
  dateCreated: string;
  dateDeleted?: string | null;
  createdBy?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  deletedBy?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type GetClientScanCardCodesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetClientScanCardCodesQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    scanCardCodes: {
      __typename?: 'ScanCardCodesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ScanCardCode';
        id: string;
        value: string;
        dateCreated: string;
        dateDeleted?: string | null;
        createdBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        deletedBy?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }>;
    };
  } | null;
};

export type CreateScanCardMutationVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;

export type CreateScanCardMutation = {
  __typename?: 'Mutation';
  createScanCardCode?: {
    __typename?: 'CreateScanCardCodePayload';
    scanCardCode?: {
      __typename?: 'ScanCardCode';
      id: string;
      value: string;
      dateCreated: string;
      dateDeleted?: string | null;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      deletedBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteScanCardMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteScanCardMutation = {
  __typename?: 'Mutation';
  deleteScanCardCode?: {
    __typename?: 'DeleteScanCardCodePayload';
    scanCardCode?: {
      __typename?: 'ScanCardCode';
      id: string;
      value: string;
      dateCreated: string;
      dateDeleted?: string | null;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      deletedBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type RestoreScanCardMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RestoreScanCardMutation = {
  __typename?: 'Mutation';
  restoreScanCardCode?: {
    __typename?: 'RestoreScanCardCodePayload';
    scanCardCode?: {
      __typename?: 'ScanCardCode';
      id: string;
      value: string;
      dateCreated: string;
      dateDeleted?: string | null;
      createdBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      deletedBy?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type ServiceTypeFieldsFragment = {
  __typename?: 'ServiceType';
  id: string;
  name: string;
  hud: boolean;
  hudRecordType?: RecordType | null;
  hudTypeProvided?: ServiceTypeProvided | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  supportsBulkAssignment: boolean;
  serviceCategory: { __typename?: 'ServiceCategory'; id: string; name: string };
};

export type ServiceBasicFieldsFragment = {
  __typename?: 'Service';
  id: string;
  dateProvided?: string | null;
  serviceType?: {
    __typename?: 'ServiceType';
    id: string;
    name: string;
    hud: boolean;
    hudRecordType?: RecordType | null;
    hudTypeProvided?: ServiceTypeProvided | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    supportsBulkAssignment: boolean;
    serviceCategory: {
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
    };
  } | null;
};

export type ServiceFieldsFragment = {
  __typename?: 'Service';
  id: string;
  dateProvided?: string | null;
  faAmount?: number | null;
  faStartDate?: string | null;
  faEndDate?: string | null;
  movingOnOtherType?: string | null;
  referralOutcome?: PathReferralOutcome | null;
  subTypeProvided?: ServiceSubTypeProvided | null;
  otherTypeProvided?: string | null;
  dateCreated?: string | null;
  dateUpdated?: string | null;
  dateDeleted?: string | null;
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
  serviceType?: {
    __typename?: 'ServiceType';
    id: string;
    name: string;
    hud: boolean;
    hudRecordType?: RecordType | null;
    hudTypeProvided?: ServiceTypeProvided | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    supportsBulkAssignment: boolean;
    serviceCategory: {
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
    };
  } | null;
  customDataElements: Array<{
    __typename?: 'CustomDataElement';
    id: string;
    key: string;
    label: string;
    fieldType: CustomDataElementType;
    repeats: boolean;
    displayHooks: Array<DisplayHook>;
    value?: {
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    } | null;
    values?: Array<{
      __typename?: 'CustomDataElementValue';
      id: string;
      valueBoolean?: boolean | null;
      valueDate?: string | null;
      valueFloat?: number | null;
      valueInteger?: number | null;
      valueJson?: any | null;
      valueString?: string | null;
      valueText?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      valueFile?: {
        __typename?: 'File';
        confidential?: boolean | null;
        contentType?: string | null;
        effectiveDate?: string | null;
        expirationDate?: string | null;
        id: string;
        name: string;
        url?: string | null;
        tags: Array<string>;
        ownFile: boolean;
        redacted: boolean;
        enrollmentId?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        enrollment?: { __typename?: 'Enrollment'; id: string } | null;
        uploadedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        updatedBy?: {
          __typename?: 'ApplicationUser';
          id: string;
          name: string;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
    }> | null;
  }>;
};

export type ServiceCategoryFieldsFragment = {
  __typename?: 'ServiceCategory';
  id: string;
  name: string;
  serviceTypes: {
    __typename?: 'ServiceTypesPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{ __typename?: 'ServiceType'; id: string; name: string }>;
  };
};

export type GetServiceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetServiceQuery = {
  __typename?: 'Query';
  service?: {
    __typename?: 'Service';
    id: string;
    dateProvided?: string | null;
    faAmount?: number | null;
    faStartDate?: string | null;
    faEndDate?: string | null;
    movingOnOtherType?: string | null;
    referralOutcome?: PathReferralOutcome | null;
    subTypeProvided?: ServiceSubTypeProvided | null;
    otherTypeProvided?: string | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    dateDeleted?: string | null;
    user?: {
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
    serviceType?: {
      __typename?: 'ServiceType';
      id: string;
      name: string;
      hud: boolean;
      hudRecordType?: RecordType | null;
      hudTypeProvided?: ServiceTypeProvided | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      supportsBulkAssignment: boolean;
      serviceCategory: {
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
      };
    } | null;
    customDataElements: Array<{
      __typename?: 'CustomDataElement';
      id: string;
      key: string;
      label: string;
      fieldType: CustomDataElementType;
      repeats: boolean;
      displayHooks: Array<DisplayHook>;
      value?: {
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      values?: Array<{
        __typename?: 'CustomDataElementValue';
        id: string;
        valueBoolean?: boolean | null;
        valueDate?: string | null;
        valueFloat?: number | null;
        valueInteger?: number | null;
        valueJson?: any | null;
        valueString?: string | null;
        valueText?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        valueFile?: {
          __typename?: 'File';
          confidential?: boolean | null;
          contentType?: string | null;
          effectiveDate?: string | null;
          expirationDate?: string | null;
          id: string;
          name: string;
          url?: string | null;
          tags: Array<string>;
          ownFile: boolean;
          redacted: boolean;
          enrollmentId?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          enrollment?: { __typename?: 'Enrollment'; id: string } | null;
          uploadedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          updatedBy?: {
            __typename?: 'ApplicationUser';
            id: string;
            name: string;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      }> | null;
    }>;
  } | null;
};

export type GetServiceTypeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetServiceTypeQuery = {
  __typename?: 'Query';
  serviceType?: {
    __typename?: 'ServiceType';
    id: string;
    name: string;
    hud: boolean;
    hudRecordType?: RecordType | null;
    hudTypeProvided?: ServiceTypeProvided | null;
    dateCreated?: string | null;
    dateUpdated?: string | null;
    supportsBulkAssignment: boolean;
    serviceCategory: {
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
    };
  } | null;
};

export type DeleteServiceMutationVariables = Exact<{
  input: DeleteServiceInput;
}>;

export type DeleteServiceMutation = {
  __typename?: 'Mutation';
  deleteService?: {
    __typename?: 'DeleteServicePayload';
    clientMutationId?: string | null;
    service?: {
      __typename?: 'Service';
      id: string;
      dateProvided?: string | null;
      faAmount?: number | null;
      faStartDate?: string | null;
      faEndDate?: string | null;
      movingOnOtherType?: string | null;
      referralOutcome?: PathReferralOutcome | null;
      subTypeProvided?: ServiceSubTypeProvided | null;
      otherTypeProvided?: string | null;
      dateCreated?: string | null;
      dateUpdated?: string | null;
      dateDeleted?: string | null;
      user?: {
        __typename: 'ApplicationUser';
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      } | null;
      serviceType?: {
        __typename?: 'ServiceType';
        id: string;
        name: string;
        hud: boolean;
        hudRecordType?: RecordType | null;
        hudTypeProvided?: ServiceTypeProvided | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        supportsBulkAssignment: boolean;
        serviceCategory: {
          __typename?: 'ServiceCategory';
          id: string;
          name: string;
        };
      } | null;
      customDataElements: Array<{
        __typename?: 'CustomDataElement';
        id: string;
        key: string;
        label: string;
        fieldType: CustomDataElementType;
        repeats: boolean;
        displayHooks: Array<DisplayHook>;
        value?: {
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        } | null;
        values?: Array<{
          __typename?: 'CustomDataElementValue';
          id: string;
          valueBoolean?: boolean | null;
          valueDate?: string | null;
          valueFloat?: number | null;
          valueInteger?: number | null;
          valueJson?: any | null;
          valueString?: string | null;
          valueText?: string | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          valueFile?: {
            __typename?: 'File';
            confidential?: boolean | null;
            contentType?: string | null;
            effectiveDate?: string | null;
            expirationDate?: string | null;
            id: string;
            name: string;
            url?: string | null;
            tags: Array<string>;
            ownFile: boolean;
            redacted: boolean;
            enrollmentId?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            enrollment?: { __typename?: 'Enrollment'; id: string } | null;
            uploadedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            updatedBy?: {
              __typename?: 'ApplicationUser';
              id: string;
              name: string;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          user?: {
            __typename: 'ApplicationUser';
            id: string;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
          } | null;
        }> | null;
      }>;
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type GetEnrollmentServicesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ServicesForEnrollmentFilterOptions>;
}>;

export type GetEnrollmentServicesQuery = {
  __typename?: 'Query';
  enrollment?: {
    __typename?: 'Enrollment';
    id: string;
    services: {
      __typename?: 'ServicesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Service';
        id: string;
        dateProvided?: string | null;
        faAmount?: number | null;
        faStartDate?: string | null;
        faEndDate?: string | null;
        movingOnOtherType?: string | null;
        referralOutcome?: PathReferralOutcome | null;
        subTypeProvided?: ServiceSubTypeProvided | null;
        otherTypeProvided?: string | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        dateDeleted?: string | null;
        user?: {
          __typename: 'ApplicationUser';
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
        serviceType?: {
          __typename?: 'ServiceType';
          id: string;
          name: string;
          hud: boolean;
          hudRecordType?: RecordType | null;
          hudTypeProvided?: ServiceTypeProvided | null;
          dateCreated?: string | null;
          dateUpdated?: string | null;
          supportsBulkAssignment: boolean;
          serviceCategory: {
            __typename?: 'ServiceCategory';
            id: string;
            name: string;
          };
        } | null;
        customDataElements: Array<{
          __typename?: 'CustomDataElement';
          id: string;
          key: string;
          label: string;
          fieldType: CustomDataElementType;
          repeats: boolean;
          displayHooks: Array<DisplayHook>;
          value?: {
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          } | null;
          values?: Array<{
            __typename?: 'CustomDataElementValue';
            id: string;
            valueBoolean?: boolean | null;
            valueDate?: string | null;
            valueFloat?: number | null;
            valueInteger?: number | null;
            valueJson?: any | null;
            valueString?: string | null;
            valueText?: string | null;
            dateCreated?: string | null;
            dateUpdated?: string | null;
            valueFile?: {
              __typename?: 'File';
              confidential?: boolean | null;
              contentType?: string | null;
              effectiveDate?: string | null;
              expirationDate?: string | null;
              id: string;
              name: string;
              url?: string | null;
              tags: Array<string>;
              ownFile: boolean;
              redacted: boolean;
              enrollmentId?: string | null;
              dateCreated?: string | null;
              dateUpdated?: string | null;
              enrollment?: { __typename?: 'Enrollment'; id: string } | null;
              uploadedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              updatedBy?: {
                __typename?: 'ApplicationUser';
                id: string;
                name: string;
              } | null;
              user?: {
                __typename: 'ApplicationUser';
                id: string;
                name: string;
                firstName?: string | null;
                lastName?: string | null;
                email: string;
              } | null;
            } | null;
            user?: {
              __typename: 'ApplicationUser';
              id: string;
              name: string;
              firstName?: string | null;
              lastName?: string | null;
              email: string;
            } | null;
          }> | null;
        }>;
      }>;
    };
  } | null;
};

export type GetServiceCategoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetServiceCategoryQuery = {
  __typename?: 'Query';
  serviceCategory?: {
    __typename?: 'ServiceCategory';
    id: string;
    name: string;
    serviceTypes: {
      __typename?: 'ServiceTypesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{ __typename?: 'ServiceType'; id: string; name: string }>;
    };
  } | null;
};

export type GetServiceCategoryTypesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetServiceCategoryTypesQuery = {
  __typename?: 'Query';
  serviceCategory?: {
    __typename?: 'ServiceCategory';
    id: string;
    serviceTypes: {
      __typename?: 'ServiceTypesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ServiceType';
        id: string;
        name: string;
        hud: boolean;
        hudRecordType?: RecordType | null;
        hudTypeProvided?: ServiceTypeProvided | null;
        dateCreated?: string | null;
        dateUpdated?: string | null;
        supportsBulkAssignment: boolean;
        serviceCategory: {
          __typename?: 'ServiceCategory';
          id: string;
          name: string;
        };
      }>;
    };
  } | null;
};

export type HouseholdWithStaffAssignmentsFragment = {
  __typename?: 'Household';
  id: string;
  householdClients: Array<{
    __typename?: 'HouseholdClient';
    id: string;
    relationshipToHoH: RelationshipToHoH;
    client: {
      __typename?: 'Client';
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
    };
  }>;
  staffAssignments?: {
    __typename?: 'StaffAssignmentsPaginated';
    nodesCount: number;
    nodes: Array<{
      __typename?: 'StaffAssignment';
      id: string;
      staffAssignmentRelationship: string;
      assignedAt: string;
      unassignedAt?: string | null;
      user: { __typename?: 'ApplicationUser'; id: string; name: string };
      household: { __typename?: 'Household'; id: string };
    }>;
  } | null;
};

export type StaffAssignmentDetailsFragment = {
  __typename?: 'StaffAssignment';
  id: string;
  staffAssignmentRelationship: string;
  assignedAt: string;
  unassignedAt?: string | null;
  user: { __typename?: 'ApplicationUser'; id: string; name: string };
  household: { __typename?: 'Household'; id: string };
};

export type StaffAssignmentWithClientsFragment = {
  __typename?: 'StaffAssignment';
  id: string;
  staffAssignmentRelationship: string;
  assignedAt: string;
  unassignedAt?: string | null;
  user: { __typename?: 'ApplicationUser'; id: string; name: string };
  household: {
    __typename?: 'Household';
    id: string;
    anyInProgress: boolean;
    latestExitDate?: string | null;
    householdClients: Array<{
      __typename?: 'HouseholdClient';
      relationshipToHoH: RelationshipToHoH;
      client: {
        __typename?: 'Client';
        id: string;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
      };
      enrollment: {
        __typename?: 'Enrollment';
        id: string;
        entryDate: string;
        exitDate?: string | null;
        inProgress: boolean;
        project: {
          __typename?: 'Project';
          id: string;
          projectName: string;
          projectType?: ProjectType | null;
        };
      };
    }>;
  };
};

export type AssignStaffMutationVariables = Exact<{
  input: AssignStaffInput;
}>;

export type AssignStaffMutation = {
  __typename?: 'Mutation';
  assignStaff?: {
    __typename?: 'AssignStaffPayload';
    staffAssignment?: {
      __typename?: 'StaffAssignment';
      id: string;
      staffAssignmentRelationship: string;
      assignedAt: string;
      unassignedAt?: string | null;
      user: { __typename?: 'ApplicationUser'; id: string; name: string };
      household: { __typename?: 'Household'; id: string };
    } | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type UnassignStaffMutationVariables = Exact<{
  staffAssignmentId: Scalars['ID']['input'];
}>;

export type UnassignStaffMutation = {
  __typename?: 'Mutation';
  unassignStaff?: {
    __typename?: 'UnassignStaffPayload';
    staffAssignment?: {
      __typename?: 'StaffAssignment';
      id: string;
      staffAssignmentRelationship: string;
      assignedAt: string;
      unassignedAt?: string | null;
      user: { __typename?: 'ApplicationUser'; id: string; name: string };
      household: { __typename?: 'Household'; id: string };
    } | null;
  } | null;
};

export type GetHouseholdStaffAssignmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetHouseholdStaffAssignmentsQuery = {
  __typename?: 'Query';
  household?: {
    __typename?: 'Household';
    id: string;
    householdClients: Array<{
      __typename?: 'HouseholdClient';
      id: string;
      relationshipToHoH: RelationshipToHoH;
      client: {
        __typename?: 'Client';
        id: string;
        lockVersion: number;
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        nameSuffix?: string | null;
      };
    }>;
    staffAssignments?: {
      __typename?: 'StaffAssignmentsPaginated';
      nodesCount: number;
      nodes: Array<{
        __typename?: 'StaffAssignment';
        id: string;
        staffAssignmentRelationship: string;
        assignedAt: string;
        unassignedAt?: string | null;
        user: { __typename?: 'ApplicationUser'; id: string; name: string };
        household: { __typename?: 'Household'; id: string };
      }>;
    } | null;
  } | null;
};

export type GetHouseholdStaffAssignmentHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetHouseholdStaffAssignmentHistoryQuery = {
  __typename?: 'Query';
  household?: {
    __typename?: 'Household';
    id: string;
    staffAssignments?: {
      __typename?: 'StaffAssignmentsPaginated';
      nodesCount: number;
      nodes: Array<{
        __typename?: 'StaffAssignment';
        id: string;
        staffAssignmentRelationship: string;
        assignedAt: string;
        unassignedAt?: string | null;
        user: { __typename?: 'ApplicationUser'; id: string; name: string };
        household: { __typename?: 'Household'; id: string };
      }>;
    } | null;
  } | null;
};

export type UnitTypeCapacityFieldsFragment = {
  __typename?: 'UnitTypeCapacity';
  id: string;
  unitType: string;
  capacity: number;
  availability: number;
};

export type UnitTypeFieldsFragment = {
  __typename?: 'UnitTypeObject';
  id: string;
  description?: string | null;
  bedType?: InventoryBedType | null;
  unitSize?: number | null;
  dateUpdated: string;
  dateCreated: string;
};

export type UnitFieldsFragment = {
  __typename?: 'Unit';
  id: string;
  unitSize?: number | null;
  unitType?: {
    __typename?: 'UnitTypeObject';
    id: string;
    description?: string | null;
    bedType?: InventoryBedType | null;
    unitSize?: number | null;
    dateUpdated: string;
    dateCreated: string;
  } | null;
  occupants: Array<{
    __typename?: 'Enrollment';
    id: string;
    relationshipToHoH: RelationshipToHoH;
    client: {
      __typename?: 'Client';
      id: string;
      lockVersion: number;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      nameSuffix?: string | null;
    };
  }>;
};

export type GetUnitsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<UnitFilterOptions>;
}>;

export type GetUnitsQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    units: {
      __typename?: 'UnitsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'Unit';
        id: string;
        unitSize?: number | null;
        unitType?: {
          __typename?: 'UnitTypeObject';
          id: string;
          description?: string | null;
          bedType?: InventoryBedType | null;
          unitSize?: number | null;
          dateUpdated: string;
          dateCreated: string;
        } | null;
        occupants: Array<{
          __typename?: 'Enrollment';
          id: string;
          relationshipToHoH: RelationshipToHoH;
          client: {
            __typename?: 'Client';
            id: string;
            lockVersion: number;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            nameSuffix?: string | null;
          };
        }>;
      }>;
    };
  } | null;
};

export type GetProjectUnitTypesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectUnitTypesQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    unitTypes: Array<{
      __typename?: 'UnitTypeCapacity';
      id: string;
      unitType: string;
      capacity: number;
      availability: number;
    }>;
  } | null;
};

export type CreateUnitsMutationVariables = Exact<{
  input: CreateUnitsInput;
}>;

export type CreateUnitsMutation = {
  __typename?: 'Mutation';
  createUnits?: {
    __typename?: 'CreateUnitsPayload';
    clientMutationId?: string | null;
    units?: Array<{
      __typename?: 'Unit';
      id: string;
      unitSize?: number | null;
      unitType?: {
        __typename?: 'UnitTypeObject';
        id: string;
        description?: string | null;
        bedType?: InventoryBedType | null;
        unitSize?: number | null;
        dateUpdated: string;
        dateCreated: string;
      } | null;
      occupants: Array<{
        __typename?: 'Enrollment';
        id: string;
        relationshipToHoH: RelationshipToHoH;
        client: {
          __typename?: 'Client';
          id: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
        };
      }>;
    }> | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type DeleteUnitsMutationVariables = Exact<{
  input: DeleteUnitsInput;
}>;

export type DeleteUnitsMutation = {
  __typename?: 'Mutation';
  deleteUnits?: {
    __typename?: 'DeleteUnitsPayload';
    clientMutationId?: string | null;
    unitIds?: Array<string> | null;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type UpdateUnitsMutationVariables = Exact<{
  input: UpdateUnitsInput;
}>;

export type UpdateUnitsMutation = {
  __typename?: 'Mutation';
  updateUnits?: {
    __typename?: 'UpdateUnitsPayload';
    clientMutationId?: string | null;
    units: Array<{
      __typename?: 'Unit';
      id: string;
      unitSize?: number | null;
      unitType?: {
        __typename?: 'UnitTypeObject';
        id: string;
        description?: string | null;
        bedType?: InventoryBedType | null;
        unitSize?: number | null;
        dateUpdated: string;
        dateCreated: string;
      } | null;
      occupants: Array<{
        __typename?: 'Enrollment';
        id: string;
        relationshipToHoH: RelationshipToHoH;
        client: {
          __typename?: 'Client';
          id: string;
          lockVersion: number;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          nameSuffix?: string | null;
        };
      }>;
    }>;
    errors: Array<{
      __typename?: 'ValidationError';
      type: ValidationType;
      attribute: string;
      readableAttribute?: string | null;
      message: string;
      fullMessage: string;
      severity: ValidationSeverity;
      id?: string | null;
      recordId?: string | null;
      linkId?: string | null;
      section?: string | null;
      data?: any | null;
    }>;
  } | null;
};

export type CreateDirectUploadMutationMutationVariables = Exact<{
  input: DirectUploadInput;
}>;

export type CreateDirectUploadMutationMutation = {
  __typename?: 'Mutation';
  createDirectUpload?: {
    __typename?: 'DirectUpload';
    filename: string;
    headers: string;
    url: string;
    signedBlobId: string;
  } | null;
};

export type UserFieldsFragment = {
  __typename: 'ApplicationUser';
  id: string;
  name: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
};

export type UserActivityLogFieldsFragment = {
  __typename?: 'ActivityLog';
  id: string;
  ipAddress?: string | null;
  createdAt: string;
  resolvedRecords: Array<{
    __typename?: 'ActivityLogRecord';
    recordId: string;
    recordType: string;
  }>;
};

export type ClientAccessSummaryFieldsFragment = {
  __typename?: 'ClientAccessSummary';
  id: string;
  lastAccessedAt: string;
  clientId: string;
  clientName?: string | null;
};

export type EnrollmentAccessSummaryFieldsFragment = {
  __typename?: 'EnrollmentAccessSummary';
  id: string;
  lastAccessedAt: string;
  enrollmentId: string;
  clientId?: string | null;
  clientName?: string | null;
  projectId?: string | null;
  projectName?: string | null;
};

export type GetApplicationUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ApplicationUserFilterOptions>;
}>;

export type GetApplicationUsersQuery = {
  __typename?: 'Query';
  applicationUsers: {
    __typename?: 'ApplicationUsersPaginated';
    offset: number;
    limit: number;
    nodesCount: number;
    nodes: Array<{
      __typename: 'ApplicationUser';
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    }>;
  };
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename: 'ApplicationUser';
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  } | null;
};

export type GetUserAccessHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetUserAccessHistoryQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ApplicationUser';
    id: string;
    activityLogs: {
      __typename?: 'ActivityLogsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ActivityLog';
        id: string;
        ipAddress?: string | null;
        createdAt: string;
        resolvedRecords: Array<{
          __typename?: 'ActivityLogRecord';
          recordId: string;
          recordType: string;
        }>;
      }>;
    };
  } | null;
};

export type GetUserClientSummariesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ClientAccessSummaryFilterOptions>;
}>;

export type GetUserClientSummariesQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ApplicationUser';
    id: string;
    clientAccessSummaries: {
      __typename?: 'ClientAccessSummariesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'ClientAccessSummary';
        id: string;
        lastAccessedAt: string;
        clientId: string;
        clientName?: string | null;
      }>;
    };
  } | null;
};

export type GetUserEnrollmentSummariesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<EnrollmentAccessSummaryFilterOptions>;
}>;

export type GetUserEnrollmentSummariesQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ApplicationUser';
    id: string;
    enrollmentAccessSummaries: {
      __typename?: 'EnrollmentAccessSummariesPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'EnrollmentAccessSummary';
        id: string;
        lastAccessedAt: string;
        enrollmentId: string;
        clientId?: string | null;
        clientName?: string | null;
        projectId?: string | null;
        projectName?: string | null;
      }>;
    };
  } | null;
};

export type GetUserStaffAssignmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetUserStaffAssignmentsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'ApplicationUser';
    id: string;
    staffAssignments?: {
      __typename?: 'StaffAssignmentsPaginated';
      offset: number;
      limit: number;
      nodesCount: number;
      nodes: Array<{
        __typename?: 'StaffAssignment';
        id: string;
        staffAssignmentRelationship: string;
        assignedAt: string;
        unassignedAt?: string | null;
        user: { __typename?: 'ApplicationUser'; id: string; name: string };
        household: {
          __typename?: 'Household';
          id: string;
          anyInProgress: boolean;
          latestExitDate?: string | null;
          householdClients: Array<{
            __typename?: 'HouseholdClient';
            relationshipToHoH: RelationshipToHoH;
            client: {
              __typename?: 'Client';
              id: string;
              lockVersion: number;
              firstName?: string | null;
              middleName?: string | null;
              lastName?: string | null;
              nameSuffix?: string | null;
            };
            enrollment: {
              __typename?: 'Enrollment';
              id: string;
              entryDate: string;
              exitDate?: string | null;
              inProgress: boolean;
              project: {
                __typename?: 'Project';
                id: string;
                projectName: string;
                projectType?: ProjectType | null;
              };
            };
          }>;
        };
      }>;
    } | null;
  } | null;
};

export const RootPermissionsFragmentDoc = gql`
  fragment RootPermissions on QueryAccess {
    id
    canAdministerHmis
    canManageForms
    canAdministrateConfig
    canConfigureDataCollection
    canImpersonateUsers
    canAuditUsers
    canManageDeniedReferrals
    canMergeClients
    canTransferEnrollments
    canViewClients
    canEditClients
    canViewDob
    canViewClientAlerts
    canViewMyDashboard
    canEditOrganization
    canEditProjectDetails
  }
`;
export const OrganizationAccessFieldsFragmentDoc = gql`
  fragment OrganizationAccessFields on OrganizationAccess {
    id
    canEditOrganization
    canDeleteOrganization
  }
`;
export const ClientNameFragmentDoc = gql`
  fragment ClientName on Client {
    id
    lockVersion
    firstName
    middleName
    lastName
    nameSuffix
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on ApplicationUser {
    __typename
    id
    name
    firstName
    lastName
    email
  }
`;
export const ClientAlertFieldsFragmentDoc = gql`
  fragment ClientAlertFields on ClientAlert {
    id
    note
    expirationDate
    createdBy {
      ...UserFields
    }
    createdAt
    priority
  }
  ${UserFieldsFragmentDoc}
`;
export const ClientWithAlertFieldsFragmentDoc = gql`
  fragment ClientWithAlertFields on Client {
    ...ClientName
    alerts {
      ...ClientAlertFields
    }
    access {
      id
      canViewClientAlerts
      canManageClientAlerts
    }
  }
  ${ClientNameFragmentDoc}
  ${ClientAlertFieldsFragmentDoc}
`;
export const AssessmentFieldsFragmentDoc = gql`
  fragment AssessmentFields on Assessment {
    id
    lockVersion
    inProgress
    assessmentDate
    dataCollectionStage
    dateCreated
    dateUpdated
    dateDeleted
    role
    user {
      ...UserFields
    }
    createdBy {
      ...UserFields
    }
    definition {
      id
      cacheKey
      title
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const EnrollmentValuesFragmentDoc = gql`
  fragment EnrollmentValues on Enrollment {
    id
    lockVersion
    entryDate
    exitDate
    disablingCondition
    dateOfEngagement
    moveInDate
    livingSituation
    rentalSubsidyType
    lengthOfStay
    losUnderThreshold
    previousStreetEssh
    dateToStreetEssh
    timesHomelessPastThreeYears
    monthsHomelessPastThreeYears
    enrollmentCoc
    dateOfPathStatus
    clientEnrolledInPath
    reasonNotEnrolled
    percentAmi
    referralSource
    countOutreachReferralApproaches
    dateOfBcpStatus
    eligibleForRhy
    reasonNoServices
    runawayYouth
    sexualOrientation
    sexualOrientationOther
    formerWardChildWelfare
    childWelfareYears
    childWelfareMonths
    formerWardJuvenileJustice
    juvenileJusticeYears
    juvenileJusticeMonths
    unemploymentFam
    mentalHealthDisorderFam
    physicalDisabilityFam
    alcoholDrugUseDisorderFam
    unemploymentFam
    mentalHealthDisorderFam
    physicalDisabilityFam
    alcoholDrugUseDisorderFam
    insufficientIncome
    incarceratedParent
    targetScreenReqd
    timeToHousingLoss
    annualPercentAmi
    literalHomelessHistory
    clientLeaseholder
    hohLeaseholder
    subsidyAtRisk
    evictionHistory
    criminalRecord
    incarceratedAdult
    prisonDischarge
    sexOffender
    disabledHoh
    currentPregnant
    singleParent
    dependentUnder6
    hh5Plus
    cocPrioritized
    hpScreeningScore
    thresholdScore
    vamcStation
    translationNeeded
    preferredLanguage
    preferredLanguageDifferent
  }
`;
export const FileFieldsFragmentDoc = gql`
  fragment FileFields on File {
    confidential
    contentType
    effectiveDate
    expirationDate
    id
    name
    url
    tags
    ownFile
    redacted
    enrollmentId
    enrollment {
      id
    }
    uploadedBy {
      id
      name
    }
    updatedBy {
      id
      name
    }
    dateCreated
    dateUpdated
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const CustomDataElementValueFieldsFragmentDoc = gql`
  fragment CustomDataElementValueFields on CustomDataElementValue {
    id
    valueBoolean
    valueDate
    valueFloat
    valueInteger
    valueJson
    valueString
    valueText
    valueFile {
      ...FileFields
    }
    user {
      ...UserFields
    }
    dateCreated
    dateUpdated
  }
  ${FileFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const CustomDataElementFieldsFragmentDoc = gql`
  fragment CustomDataElementFields on CustomDataElement {
    id
    key
    label
    fieldType
    repeats
    displayHooks
    value {
      ...CustomDataElementValueFields
    }
    values {
      ...CustomDataElementValueFields
    }
  }
  ${CustomDataElementValueFieldsFragmentDoc}
`;
export const CeAssessmentFieldsFragmentDoc = gql`
  fragment CeAssessmentFields on CeAssessment {
    id
    assessmentDate
    assessmentLevel
    assessmentLocation
    assessmentType
    dateCreated
    dateUpdated
    dateDeleted
    prioritizationStatus
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const EventFieldsFragmentDoc = gql`
  fragment EventFields on Event {
    id
    event
    eventDate
    locationCrisisOrPhHousing
    probSolDivRrResult
    referralCaseManageAfter
    referralResult
    resultDate
    dateCreated
    dateUpdated
    dateDeleted
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const IncomeBenefitValuesFragmentDoc = gql`
  fragment IncomeBenefitValues on IncomeBenefit {
    __typename
    adap
    alimony
    alimonyAmount
    benefitsFromAnySource
    childSupport
    childSupportAmount
    cobra
    connectionWithSoar
    dataCollectionStage
    dateCreated
    dateDeleted
    dateUpdated
    earned
    earnedAmount
    employerProvided
    ga
    gaAmount
    id
    incomeFromAnySource
    indianHealthServices
    informationDate
    insuranceFromAnySource
    medicaid
    medicare
    noAdapReason
    noCobraReason
    noEmployerProvidedReason
    noIndianHealthServicesReason
    noMedicaidReason
    noMedicareReason
    noPrivatePayReason
    noRyanWhiteReason
    noSchipReason
    noStateHealthInsReason
    noVhaReason
    otherBenefitsSource
    otherBenefitsSourceIdentify
    otherIncomeAmount
    otherIncomeSource
    otherIncomeSourceIdentify
    otherInsurance
    otherInsuranceIdentify
    otherTanf
    pension
    pensionAmount
    privateDisability
    privateDisabilityAmount
    privatePay
    ryanWhiteMedDent
    schip
    snap
    socSecRetirement
    socSecRetirementAmount
    ssdi
    ssdiAmount
    ssi
    ssiAmount
    stateHealthIns
    tanf
    tanfAmount
    tanfChildCare
    tanfTransportation
    totalMonthlyIncome
    unemployment
    unemploymentAmount
    vaDisabilityNonService
    vaDisabilityNonServiceAmount
    vaDisabilityService
    vaDisabilityServiceAmount
    vhaServices
    wic
    workersComp
    workersCompAmount
  }
`;
export const DisabilityGroupValuesFragmentDoc = gql`
  fragment DisabilityGroupValues on DisabilityGroup {
    __typename
    id
    dataCollectionStage
    informationDate
    disablingCondition
    chronicHealthCondition
    chronicHealthConditionIndefiniteAndImpairs
    developmentalDisability
    hivAids
    mentalHealthDisorder
    mentalHealthDisorderIndefiniteAndImpairs
    physicalDisability
    physicalDisabilityIndefiniteAndImpairs
    substanceUseDisorder
    substanceUseDisorderIndefiniteAndImpairs
    dateCreated
    dateUpdated
    hivAids
    tCellCountAvailable
    tCellCount
    tCellSource
    viralLoadAvailable
    viralLoad
    viralLoadSource
    antiRetroviral
  }
`;
export const HealthAndDvValuesFragmentDoc = gql`
  fragment HealthAndDvValues on HealthAndDv {
    __typename
    currentlyFleeing
    dataCollectionStage
    dateCreated
    dateDeleted
    dateUpdated
    dentalHealthStatus
    domesticViolenceSurvivor
    dueDate
    generalHealthStatus
    id
    informationDate
    mentalHealthStatus
    pregnancyStatus
    whenOccurred
  }
`;
export const ExitValuesFragmentDoc = gql`
  fragment ExitValues on Exit {
    id
    aftercareDate
    aftercareProvided
    aftercareMethods
    askedOrForcedToExchangeForSex
    askedOrForcedToExchangeForSexPastThreeMonths
    cmExitReason
    coercedToContinueWork
    counselingReceived
    counselingMethods
    countOfExchangeForSex
    dateCreated
    dateDeleted
    dateUpdated
    destination
    destinationSafeClient
    destinationSafeWorker
    destinationSubsidyType
    earlyExitReason
    exchangeForSex
    exchangeForSexPastThreeMonths
    exitDate
    housingAssessment
    laborExploitPastThreeMonths
    otherDestination
    posAdultConnections
    posCommunityConnections
    posPeerConnections
    postExitCounselingPlan
    projectCompletionStatus
    sessionCountAtExit
    sessionsInPlan
    subsidyInformation
    workPlaceViolenceThreats
    workplacePromiseDifference
  }
`;
export const YouthEducationStatusValuesFragmentDoc = gql`
  fragment YouthEducationStatusValues on YouthEducationStatus {
    currentEdStatus
    currentSchoolAttend
    dataCollectionStage
    dateCreated
    dateDeleted
    dateUpdated
    id
    informationDate
    mostRecentEdStatus
  }
`;
export const EmploymentEducationValuesFragmentDoc = gql`
  fragment EmploymentEducationValues on EmploymentEducation {
    dataCollectionStage
    dateCreated
    dateDeleted
    employed
    employmentType
    id
    informationDate
    lastGradeCompleted
    notEmployedReason
    schoolStatus
  }
`;
export const AssessmentAccessFieldsFragmentDoc = gql`
  fragment AssessmentAccessFields on AssessmentAccess {
    id
    canDeleteAssessments
    canDeleteEnrollments
    canEditEnrollments
  }
`;
export const GeolocationFieldsFragmentDoc = gql`
  fragment GeolocationFields on Geolocation {
    id
    coordinates {
      id
      latitude
      longitude
    }
  }
`;
export const AssessmentWithRecordsFragmentDoc = gql`
  fragment AssessmentWithRecords on Assessment {
    ...AssessmentFields
    enrollment {
      ...EnrollmentValues
      customDataElements {
        ...CustomDataElementFields
      }
      client {
        id
      }
    }
    ceAssessment {
      ...CeAssessmentFields
    }
    event {
      ...EventFields
    }
    incomeBenefit {
      ...IncomeBenefitValues
      customDataElements {
        ...CustomDataElementFields
      }
    }
    disabilityGroup {
      ...DisabilityGroupValues
    }
    healthAndDv {
      ...HealthAndDvValues
    }
    exit {
      ...ExitValues
      customDataElements {
        ...CustomDataElementFields
      }
    }
    youthEducationStatus {
      ...YouthEducationStatusValues
    }
    employmentEducation {
      ...EmploymentEducationValues
    }
    customDataElements {
      ...CustomDataElementFields
    }
    access {
      ...AssessmentAccessFields
    }
    geolocation {
      ...GeolocationFields
    }
  }
  ${AssessmentFieldsFragmentDoc}
  ${EnrollmentValuesFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
  ${CeAssessmentFieldsFragmentDoc}
  ${EventFieldsFragmentDoc}
  ${IncomeBenefitValuesFragmentDoc}
  ${DisabilityGroupValuesFragmentDoc}
  ${HealthAndDvValuesFragmentDoc}
  ${ExitValuesFragmentDoc}
  ${YouthEducationStatusValuesFragmentDoc}
  ${EmploymentEducationValuesFragmentDoc}
  ${AssessmentAccessFieldsFragmentDoc}
  ${GeolocationFieldsFragmentDoc}
`;
export const AssessmentWithValuesFragmentDoc = gql`
  fragment AssessmentWithValues on Assessment {
    ...AssessmentFields
    wipValues
  }
  ${AssessmentFieldsFragmentDoc}
`;
export const FullAssessmentFragmentDoc = gql`
  fragment FullAssessment on Assessment {
    ...AssessmentWithRecords
    ...AssessmentWithValues
  }
  ${AssessmentWithRecordsFragmentDoc}
  ${AssessmentWithValuesFragmentDoc}
`;
export const ProjectNameAndTypeFragmentDoc = gql`
  fragment ProjectNameAndType on Project {
    id
    projectName
    projectType
  }
`;
export const EnrollmentFieldsFromAssessmentFragmentDoc = gql`
  fragment EnrollmentFieldsFromAssessment on Enrollment {
    ...EnrollmentValues
    user {
      ...UserFields
    }
    project {
      ...ProjectNameAndType
    }
    intakeAssessment {
      id
      user {
        name
      }
    }
  }
  ${EnrollmentValuesFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
`;
export const IncomeBenefitFieldsFragmentDoc = gql`
  fragment IncomeBenefitFields on IncomeBenefit {
    ...IncomeBenefitValues
    user {
      ...UserFields
    }
    enrollment {
      id
      project {
        ...ProjectNameAndType
      }
    }
  }
  ${IncomeBenefitValuesFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
`;
export const DisabilityGroupFieldsFragmentDoc = gql`
  fragment DisabilityGroupFields on DisabilityGroup {
    ...DisabilityGroupValues
    user {
      ...UserFields
    }
    enrollment {
      id
      project {
        ...ProjectNameAndType
      }
    }
  }
  ${DisabilityGroupValuesFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
`;
export const HealthAndDvFieldsFragmentDoc = gql`
  fragment HealthAndDvFields on HealthAndDv {
    ...HealthAndDvValues
    user {
      ...UserFields
    }
    enrollment {
      id
      project {
        ...ProjectNameAndType
      }
    }
  }
  ${HealthAndDvValuesFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
`;
export const ClientAuditEventFieldsFragmentDoc = gql`
  fragment ClientAuditEventFields on ClientAuditEvent {
    id
    createdAt
    event
    objectChanges
    recordName
    graphqlType
    recordId
    user {
      id
      name
    }
    trueUser {
      id
      name
    }
  }
`;
export const EnrollmentAuditEventFieldsFragmentDoc = gql`
  fragment EnrollmentAuditEventFields on EnrollmentAuditEvent {
    id
    createdAt
    event
    objectChanges
    recordName
    graphqlType
    recordId
    user {
      id
      name
    }
    trueUser {
      id
      name
    }
  }
`;
export const UserAuditEventFieldsFragmentDoc = gql`
  fragment UserAuditEventFields on ApplicationUserAuditEvent {
    id
    createdAt
    event
    objectChanges
    recordName
    graphqlType
    recordId
    clientId
    clientName
    enrollmentId
    projectId
    projectName
    user {
      id
      name
    }
  }
`;
export const ClientIdentificationFieldsFragmentDoc = gql`
  fragment ClientIdentificationFields on Client {
    id
    lockVersion
    dob
    age
    gender
    pronouns
  }
`;
export const ClientSsnFieldsFragmentDoc = gql`
  fragment ClientSsnFields on Client {
    id
    lockVersion
    ssn
    access {
      id
      canViewFullSsn
      canViewPartialSsn
    }
  }
`;
export const ClientIdentifierFieldsFragmentDoc = gql`
  fragment ClientIdentifierFields on ExternalIdentifier {
    id
    identifier
    url
    label
    type
  }
`;
export const ClientSearchResultFieldsFragmentDoc = gql`
  fragment ClientSearchResultFields on Client {
    ...ClientName
    ...ClientIdentificationFields
    ...ClientSsnFields @include(if: $includeSsn)
    dateCreated
    dateDeleted
    dateUpdated
    externalIds {
      ...ClientIdentifierFields
    }
    alerts {
      ...ClientAlertFields
    }
  }
  ${ClientNameFragmentDoc}
  ${ClientIdentificationFieldsFragmentDoc}
  ${ClientSsnFieldsFragmentDoc}
  ${ClientIdentifierFieldsFragmentDoc}
  ${ClientAlertFieldsFragmentDoc}
`;
export const ClientVeteranInfoFieldsFragmentDoc = gql`
  fragment ClientVeteranInfoFields on Client {
    id
    yearEnteredService
    yearSeparated
    worldWarIi
    koreanWar
    vietnamWar
    desertStorm
    afghanistanOef
    iraqOif
    iraqOnd
    otherTheater
    militaryBranch
    dischargeStatus
  }
`;
export const ClientAccessFieldsFragmentDoc = gql`
  fragment ClientAccessFields on ClientAccess {
    id
    canEditClient
    canDeleteClient
    canViewDob
    canViewFullSsn
    canViewPartialSsn
    canViewClientName
    canViewClientPhoto
    canViewClientAlerts
    canManageClientAlerts
    canViewEnrollmentDetails
    canAuditClients
    canManageScanCards
    canMergeClients
    canViewAnyFiles
    canManageAnyClientFiles
    canManageOwnClientFiles
    canUploadClientFiles
  }
`;
export const ClientNameObjectFieldsFragmentDoc = gql`
  fragment ClientNameObjectFields on ClientName {
    id
    first
    middle
    last
    suffix
    nameDataQuality
    use
    notes
    primary
    dateCreated
    dateUpdated
  }
`;
export const ClientAddressFieldsFragmentDoc = gql`
  fragment ClientAddressFields on ClientAddress {
    id
    line1
    line2
    city
    state
    district
    country
    postalCode
    notes
    use
    addressType
    dateCreated
    dateUpdated
  }
`;
export const ClientContactPointFieldsFragmentDoc = gql`
  fragment ClientContactPointFields on ClientContactPoint {
    id
    value
    notes
    use
    system
    dateCreated
    dateUpdated
  }
`;
export const ClientFieldsFragmentDoc = gql`
  fragment ClientFields on Client {
    ...ClientIdentificationFields
    ...ClientSsnFields
    ...ClientVeteranInfoFields
    dobDataQuality
    gender
    differentIdentityText
    pronouns
    nameDataQuality
    personalId
    race
    additionalRaceEthnicity
    ssnDataQuality
    veteranStatus
    dateCreated
    dateDeleted
    dateUpdated
    ...ClientName
    externalIds {
      ...ClientIdentifierFields
    }
    user {
      ...UserFields
    }
    access {
      ...ClientAccessFields
    }
    customDataElements {
      ...CustomDataElementFields
    }
    names {
      ...ClientNameObjectFields
    }
    addresses {
      ...ClientAddressFields
    }
    phoneNumbers {
      ...ClientContactPointFields
    }
    emailAddresses {
      ...ClientContactPointFields
    }
    enabledFeatures
    alerts {
      ...ClientAlertFields
    }
  }
  ${ClientIdentificationFieldsFragmentDoc}
  ${ClientSsnFieldsFragmentDoc}
  ${ClientVeteranInfoFieldsFragmentDoc}
  ${ClientNameFragmentDoc}
  ${ClientIdentifierFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${ClientAccessFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
  ${ClientNameObjectFieldsFragmentDoc}
  ${ClientAddressFieldsFragmentDoc}
  ${ClientContactPointFieldsFragmentDoc}
  ${ClientAlertFieldsFragmentDoc}
`;
export const ClientImageFieldsFragmentDoc = gql`
  fragment ClientImageFields on ClientImage {
    id
    contentType
    base64
  }
`;
export const ClientImageFragmentDoc = gql`
  fragment ClientImage on Client {
    id
    image {
      ...ClientImageFields
    }
    access {
      id
      canEditClient
      canViewClientPhoto
    }
  }
  ${ClientImageFieldsFragmentDoc}
`;
export const ClientOmniSearchFieldsFragmentDoc = gql`
  fragment ClientOmniSearchFields on Client {
    id
    dob
    age
    gender
    personalId
    ...ClientName
  }
  ${ClientNameFragmentDoc}
`;
export const MergeAuditEventFieldsFragmentDoc = gql`
  fragment MergeAuditEventFields on MergeAuditEvent {
    id
    mergedAt
    clientIdsMerged
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const OrganizationNameFieldsFragmentDoc = gql`
  fragment OrganizationNameFields on Organization {
    id
    hudId
    organizationName
  }
`;
export const ServiceTypeFieldsFragmentDoc = gql`
  fragment ServiceTypeFields on ServiceType {
    id
    name
    hud
    hudRecordType
    hudTypeProvided
    serviceCategory {
      id
      name
    }
    dateCreated
    dateUpdated
    supportsBulkAssignment
  }
`;
export const FormRuleFieldsFragmentDoc = gql`
  fragment FormRuleFields on FormRule {
    id
    active
    activeStatus
    system
    definitionIdentifier
    definitionId
    definitionTitle
    definitionRole
    dataCollectedAbout
    funder
    otherFunder
    projectType
    projectId
    project {
      ...ProjectNameAndType
    }
    organizationId
    organization {
      ...OrganizationNameFields
    }
    createdAt
    updatedAt
    serviceCategory {
      id
      name
    }
    serviceType {
      ...ServiceTypeFields
    }
  }
  ${ProjectNameAndTypeFragmentDoc}
  ${OrganizationNameFieldsFragmentDoc}
  ${ServiceTypeFieldsFragmentDoc}
`;
export const FormProjectMatchFieldsFragmentDoc = gql`
  fragment FormProjectMatchFields on FormProjectMatch {
    id
    projectName
    organizationName
    dataCollectedAbout
  }
`;
export const ServiceTypeConfigFieldsFragmentDoc = gql`
  fragment ServiceTypeConfigFields on ServiceType {
    ...ServiceTypeFields
    formDefinitions {
      id
      identifier
      cacheKey
      title
    }
  }
  ${ServiceTypeFieldsFragmentDoc}
`;
export const EnrollmentRangeFieldsFragmentDoc = gql`
  fragment EnrollmentRangeFields on Enrollment {
    entryDate
    exitDate
    inProgress
  }
`;
export const CurrentLivingSituationFieldsFragmentDoc = gql`
  fragment CurrentLivingSituationFields on CurrentLivingSituation {
    id
    clsSubsidyType
    currentLivingSituation
    informationDate
    leaseOwn60Day
    leaveSituation14Days
    locationDetails
    movedTwoOrMore
    resourcesToObtain
    subsequentResidence
    dateUpdated
    dateCreated
    verifiedByProjectId
    user {
      ...UserFields
    }
    customDataElements {
      ...CustomDataElementFields
    }
    geolocation {
      ...GeolocationFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
  ${GeolocationFieldsFragmentDoc}
`;
export const ProjectCurrentLivingSituationFieldsFragmentDoc = gql`
  fragment ProjectCurrentLivingSituationFields on CurrentLivingSituation {
    client {
      id
      ...ClientName
    }
    enrollment {
      id
      ...EnrollmentRangeFields
    }
    ...CurrentLivingSituationFields
  }
  ${ClientNameFragmentDoc}
  ${EnrollmentRangeFieldsFragmentDoc}
  ${CurrentLivingSituationFieldsFragmentDoc}
`;
export const CustomCaseNoteFieldsFragmentDoc = gql`
  fragment CustomCaseNoteFields on CustomCaseNote {
    id
    content
    informationDate
    dateUpdated
    dateCreated
    formDefinitionId
    user {
      ...UserFields
    }
    customDataElements {
      ...CustomDataElementFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
`;
export const ClientEnrollmentFieldsFragmentDoc = gql`
  fragment ClientEnrollmentFields on Enrollment {
    id
    lockVersion
    entryDate
    exitDate
    autoExited
    moveInDate
    lastBedNightDate
    projectName
    organizationName
    projectType
    inProgress
    relationshipToHoH
    access {
      id
      canViewEnrollmentDetails
    }
  }
`;
export const ClientNameDobVetFragmentDoc = gql`
  fragment ClientNameDobVet on Client {
    ...ClientName
    dob
    veteranStatus
  }
  ${ClientNameFragmentDoc}
`;
export const EnrollmentAccessFieldsFragmentDoc = gql`
  fragment EnrollmentAccessFields on EnrollmentAccess {
    id
    canEditEnrollments
    canDeleteEnrollments
    canAuditEnrollments
    canViewEnrollmentLocationMap
  }
`;
export const EnrollmentFieldsFragmentDoc = gql`
  fragment EnrollmentFields on Enrollment {
    id
    lockVersion
    entryDate
    exitDate
    exitDestination
    autoExited
    project {
      ...ProjectNameAndType
    }
    inProgress
    relationshipToHoH
    enrollmentCoc
    householdId
    householdShortId
    householdSize
    client {
      ...ClientNameDobVet
    }
    access {
      ...EnrollmentAccessFields
    }
    currentUnit {
      id
      name
    }
  }
  ${ProjectNameAndTypeFragmentDoc}
  ${ClientNameDobVetFragmentDoc}
  ${EnrollmentAccessFieldsFragmentDoc}
`;
export const EnrollmentOccurrencePointFieldsFragmentDoc = gql`
  fragment EnrollmentOccurrencePointFields on Enrollment {
    id
    lockVersion
    entryDate
    exitDate
    dateOfEngagement
    moveInDate
    livingSituation
    enrollmentCoc
    dateOfPathStatus
    clientEnrolledInPath
    reasonNotEnrolled
    disablingCondition
    translationNeeded
    preferredLanguage
    preferredLanguageDifferent
    moveInAddresses {
      ...ClientAddressFields
    }
  }
  ${ClientAddressFieldsFragmentDoc}
`;
export const AssessedClientFieldsFragmentDoc = gql`
  fragment AssessedClientFields on Client {
    ...ClientNameDobVet
    ssn
    race
  }
  ${ClientNameDobVetFragmentDoc}
`;
export const EnrolledClientFieldsFragmentDoc = gql`
  fragment EnrolledClientFields on Client {
    ...AssessedClientFields
    hudChronic
    customDataElements {
      ...CustomDataElementFields
    }
    access {
      id
      canEditClient
    }
  }
  ${AssessedClientFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
`;
export const EnrollmentSummaryFieldsFragmentDoc = gql`
  fragment EnrollmentSummaryFields on EnrollmentSummary {
    id
    entryDate
    inProgress
    moveInDate
    projectId
    projectName
    projectType
    canViewEnrollment
  }
`;
export const DataCollectionFeatureFieldsFragmentDoc = gql`
  fragment DataCollectionFeatureFields on DataCollectionFeature {
    id
    role
    dataCollectedAbout
    legacy
  }
`;
export const FormDefinitionMetadataFragmentDoc = gql`
  fragment FormDefinitionMetadata on FormDefinition {
    id
    role
    title
    cacheKey
    identifier
    status
    updatedBy {
      name
    }
    dateUpdated
    supportsSaveInProgress
  }
`;
export const PickListOptionFieldsFragmentDoc = gql`
  fragment PickListOptionFields on PickListOption {
    code
    label
    secondaryLabel
    groupLabel
    groupCode
    initialSelected
    helperText
    numericValue
  }
`;
export const EnableWhenFieldsFragmentDoc = gql`
  fragment EnableWhenFields on EnableWhen {
    question
    localConstant
    operator
    answerCode
    answerCodes
    answerNumber
    answerBoolean
    answerGroupCode
    answerDate
    compareQuestion
  }
`;
export const ItemFieldsFragmentDoc = gql`
  fragment ItemFields on FormItem {
    __typename
    linkId
    type
    component
    prefix
    text
    briefText
    readonlyText
    helperText
    required
    warnIfEmpty
    hidden
    readOnly
    repeats
    mapping {
      recordType
      fieldName
      customFieldKey
    }
    pickListReference
    size
    assessmentDate
    prefill
    bounds {
      id
      severity
      type
      question
      valueNumber
      valueDate
      valueLocalConstant
      offset
    }
    pickListOptions {
      ...PickListOptionFields
    }
    initial {
      valueCode
      valueBoolean
      valueNumber
      valueLocalConstant
      initialBehavior
    }
    dataCollectedAbout
    disabledDisplay
    enableBehavior
    enableWhen {
      ...EnableWhenFields
    }
    autofillValues {
      valueCode
      valueQuestion
      valueBoolean
      valueNumber
      sumQuestions
      formula
      autofillBehavior
      autofillReadonly
      autofillWhen {
        ...EnableWhenFields
      }
    }
    rule
    customRule
    editorUserIds
  }
  ${PickListOptionFieldsFragmentDoc}
  ${EnableWhenFieldsFragmentDoc}
`;
export const FormDefinitionJsonFieldsFragmentDoc = gql`
  fragment FormDefinitionJsonFields on FormDefinitionJson {
    item {
      ...ItemFields
      item {
        ...ItemFields
        item {
          ...ItemFields
          item {
            ...ItemFields
            item {
              ...ItemFields
            }
          }
        }
      }
    }
  }
  ${ItemFieldsFragmentDoc}
`;
export const FormDefinitionFieldsFragmentDoc = gql`
  fragment FormDefinitionFields on FormDefinition {
    ...FormDefinitionMetadata
    definition {
      ...FormDefinitionJsonFields
    }
  }
  ${FormDefinitionMetadataFragmentDoc}
  ${FormDefinitionJsonFieldsFragmentDoc}
`;
export const OccurrencePointFormFieldsFragmentDoc = gql`
  fragment OccurrencePointFormFields on OccurrencePointForm {
    id
    dataCollectedAbout
    definition {
      ...FormDefinitionFields
    }
  }
  ${FormDefinitionFieldsFragmentDoc}
`;
export const ProjectCocCountFragmentDoc = gql`
  fragment ProjectCocCount on Project {
    projectCocs {
      nodesCount
    }
  }
`;
export const AllEnrollmentDetailsFragmentDoc = gql`
  fragment AllEnrollmentDetails on Enrollment {
    ...EnrollmentFields
    ...EnrollmentOccurrencePointFields
    numUnitsAssignedToHousehold
    intakeAssessment {
      id
    }
    exitAssessment {
      id
    }
    customDataElements {
      ...CustomDataElementFields
    }
    client {
      ...EnrolledClientFields
    }
    openEnrollmentSummary {
      ...EnrollmentSummaryFields
    }
    dataCollectionFeatures {
      ...DataCollectionFeatureFields
    }
    occurrencePointForms {
      ...OccurrencePointFormFields
    }
    project {
      ...ProjectNameAndType
      ...ProjectCocCount
      hasUnits
      access {
        id
        canSplitHouseholds
        canManageIncomingReferrals
      }
      staffAssignmentsEnabled
    }
    sourceReferralPosting {
      id
      referredFrom
      referralDate
    }
  }
  ${EnrollmentFieldsFragmentDoc}
  ${EnrollmentOccurrencePointFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
  ${EnrolledClientFieldsFragmentDoc}
  ${EnrollmentSummaryFieldsFragmentDoc}
  ${DataCollectionFeatureFieldsFragmentDoc}
  ${OccurrencePointFormFieldsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
  ${ProjectCocCountFragmentDoc}
`;
export const SubmittedEnrollmentResultFieldsFragmentDoc = gql`
  fragment SubmittedEnrollmentResultFields on Enrollment {
    ...EnrollmentFields
    ...EnrollmentOccurrencePointFields
    customDataElements {
      ...CustomDataElementFields
    }
  }
  ${EnrollmentFieldsFragmentDoc}
  ${EnrollmentOccurrencePointFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
`;
export const HouseholdClientFieldsFragmentDoc = gql`
  fragment HouseholdClientFields on HouseholdClient {
    id
    relationshipToHoH
    client {
      id
      ...ClientName
      ...ClientIdentificationFields
      ...AssessedClientFields
      access {
        ...ClientAccessFields
      }
      externalIds {
        ...ClientIdentifierFields
      }
      alerts {
        ...ClientAlertFields
      }
    }
    enrollment {
      id
      lockVersion
      relationshipToHoH
      ...EnrollmentRangeFields
      autoExited
      currentUnit {
        id
        name
      }
    }
  }
  ${ClientNameFragmentDoc}
  ${ClientIdentificationFieldsFragmentDoc}
  ${AssessedClientFieldsFragmentDoc}
  ${ClientAccessFieldsFragmentDoc}
  ${ClientIdentifierFieldsFragmentDoc}
  ${ClientAlertFieldsFragmentDoc}
  ${EnrollmentRangeFieldsFragmentDoc}
`;
export const HouseholdFieldsFragmentDoc = gql`
  fragment HouseholdFields on Household {
    id
    householdSize
    shortId
    householdClients {
      ...HouseholdClientFields
    }
  }
  ${HouseholdClientFieldsFragmentDoc}
`;
export const EnrollmentWithHouseholdFieldsFragmentDoc = gql`
  fragment EnrollmentWithHouseholdFields on Enrollment {
    ...EnrollmentFields
    household {
      ...HouseholdFields
    }
  }
  ${EnrollmentFieldsFragmentDoc}
  ${HouseholdFieldsFragmentDoc}
`;
export const ExternalFormSubmissionSummaryFragmentDoc = gql`
  fragment ExternalFormSubmissionSummary on ExternalFormSubmission {
    id
    submittedAt
    spam
    status
    notes
    enrollmentId
    clientId
    summaryFields {
      key
      value
    }
  }
`;
export const ExternalFormSubmissionFieldsFragmentDoc = gql`
  fragment ExternalFormSubmissionFields on ExternalFormSubmission {
    ...ExternalFormSubmissionSummary
    definition {
      ...FormDefinitionFields
    }
    values
  }
  ${ExternalFormSubmissionSummaryFragmentDoc}
  ${FormDefinitionFieldsFragmentDoc}
`;
export const ValidationErrorFieldsFragmentDoc = gql`
  fragment ValidationErrorFields on ValidationError {
    type
    attribute
    readableAttribute
    message
    fullMessage
    severity
    id
    recordId
    linkId
    section
    data
  }
`;
export const FormDefinitionFieldsForJsonEditorFragmentDoc = gql`
  fragment FormDefinitionFieldsForJsonEditor on FormDefinition {
    ...FormDefinitionFields
    status
    rawDefinition
  }
  ${FormDefinitionFieldsFragmentDoc}
`;
export const FormDefinitionFieldsForEditorFragmentDoc = gql`
  fragment FormDefinitionFieldsForEditor on FormDefinition {
    ...FormDefinitionFields
    version
  }
  ${FormDefinitionFieldsFragmentDoc}
`;
export const FormIdentifierDetailsFragmentDoc = gql`
  fragment FormIdentifierDetails on FormIdentifier {
    id
    identifier
    managedInVersionControl
    displayVersion {
      ...FormDefinitionMetadata
    }
    draftVersion {
      ...FormDefinitionMetadata
    }
  }
  ${FormDefinitionMetadataFragmentDoc}
`;
export const GeolocationFieldsWithMetadataFragmentDoc = gql`
  fragment GeolocationFieldsWithMetadata on Geolocation {
    id
    coordinates {
      id
      latitude
      longitude
    }
    locatedAt
    sourceFormName
    collectedBy {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const ProjectEnrollmentsHouseholdClientFieldsFragmentDoc = gql`
  fragment ProjectEnrollmentsHouseholdClientFields on HouseholdClient {
    id
    relationshipToHoH
    client {
      id
      ...ClientName
      ...ClientIdentificationFields
    }
    enrollment {
      id
      lockVersion
      entryDate
      exitDate
      inProgress
      autoExited
    }
  }
  ${ClientNameFragmentDoc}
  ${ClientIdentificationFieldsFragmentDoc}
`;
export const StaffAssignmentDetailsFragmentDoc = gql`
  fragment StaffAssignmentDetails on StaffAssignment {
    id
    user {
      id
      name
    }
    staffAssignmentRelationship
    assignedAt
    unassignedAt
    household {
      id
    }
  }
`;
export const HouseholdWithStaffAssignmentsFragmentDoc = gql`
  fragment HouseholdWithStaffAssignments on Household {
    id
    householdClients {
      id
      relationshipToHoH
      client {
        id
        ...ClientName
      }
    }
    staffAssignments(limit: 100) {
      nodesCount
      nodes {
        ...StaffAssignmentDetails
      }
    }
  }
  ${ClientNameFragmentDoc}
  ${StaffAssignmentDetailsFragmentDoc}
`;
export const ProjectEnrollmentsHouseholdFieldsFragmentDoc = gql`
  fragment ProjectEnrollmentsHouseholdFields on Household {
    id
    householdSize
    shortId
    householdClients {
      ...ProjectEnrollmentsHouseholdClientFields
    }
    ...HouseholdWithStaffAssignments @include(if: $includeStaffAssignment)
  }
  ${ProjectEnrollmentsHouseholdClientFieldsFragmentDoc}
  ${HouseholdWithStaffAssignmentsFragmentDoc}
`;
export const InventoryFieldsFragmentDoc = gql`
  fragment InventoryFields on Inventory {
    availability
    bedInventory
    chBedInventory
    chVetBedInventory
    chYouthBedInventory
    cocCode
    dateCreated
    dateDeleted
    dateUpdated
    esBedType
    householdType
    id
    inventoryEndDate
    inventoryStartDate
    otherBedInventory
    unitInventory
    vetBedInventory
    youthBedInventory
    youthVetBedInventory
    user {
      ...UserFields
    }
    customDataElements {
      ...CustomDataElementFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
`;
export const MciMatchFieldsFragmentDoc = gql`
  fragment MciMatchFields on MciClearanceMatch {
    id
    score
    existingClientId
    age
    dob
    firstName
    gender
    lastName
    mciId
    middleName
    nameSuffix
    ssn
  }
`;
export const OrganizationDetailFieldsFragmentDoc = gql`
  fragment OrganizationDetailFields on Organization {
    id
    hudId
    description
    contactInformation
    victimServiceProvider
    dateCreated
    dateUpdated
    dateDeleted
    user {
      ...UserFields
    }
    customDataElements {
      ...CustomDataElementFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
`;
export const OrganizationFieldsFragmentDoc = gql`
  fragment OrganizationFields on Organization {
    ...OrganizationNameFields
    ...OrganizationDetailFields
  }
  ${OrganizationNameFieldsFragmentDoc}
  ${OrganizationDetailFieldsFragmentDoc}
`;
export const ProjectOperatingPeriodFragmentDoc = gql`
  fragment ProjectOperatingPeriod on Project {
    id
    operatingEndDate
    operatingStartDate
  }
`;
export const ProjectAccessFieldsFragmentDoc = gql`
  fragment ProjectAccessFields on ProjectAccess {
    id
    canDeleteProject
    canEnrollClients
    canEditEnrollments
    canViewEnrollmentDetails
    canEditProjectDetails
    canViewUnits
    canManageUnits
    canManageIncomingReferrals
    canManageOutgoingReferrals
    canManageExternalFormSubmissions
    canSplitHouseholds
  }
`;
export const ProjectAllFieldsFragmentDoc = gql`
  fragment ProjectAllFields on Project {
    id
    hudId
    ...ProjectNameAndType
    ...ProjectOperatingPeriod
    description
    HOPWAMedAssistedLivingFac
    contactInformation
    continuumProject
    housingType
    residentialAffiliation
    residentialAffiliationProjectIds
    rrhSubType
    staffAssignmentsEnabled
    targetPopulation
    organization {
      ...OrganizationNameFields
    }
    access {
      ...ProjectAccessFields
    }
    user {
      ...UserFields
    }
    customDataElements {
      ...CustomDataElementFields
    }
    dataCollectionFeatures {
      ...DataCollectionFeatureFields
    }
    serviceTypes {
      ...ServiceTypeFields
    }
    ...ProjectCocCount
  }
  ${ProjectNameAndTypeFragmentDoc}
  ${ProjectOperatingPeriodFragmentDoc}
  ${OrganizationNameFieldsFragmentDoc}
  ${ProjectAccessFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
  ${DataCollectionFeatureFieldsFragmentDoc}
  ${ServiceTypeFieldsFragmentDoc}
  ${ProjectCocCountFragmentDoc}
`;
export const ProjectCocFieldsFragmentDoc = gql`
  fragment ProjectCocFields on ProjectCoc {
    id
    address1
    address2
    city
    cocCode
    dateCreated
    dateDeleted
    dateUpdated
    geocode
    geographyType
    state
    zip
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const FunderFieldsFragmentDoc = gql`
  fragment FunderFields on Funder {
    id
    dateCreated
    dateDeleted
    dateUpdated
    endDate
    funder
    grantId
    otherFunder
    startDate
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const CeParticipationFieldsFragmentDoc = gql`
  fragment CeParticipationFields on CeParticipation {
    id
    accessPoint
    ceParticipationStatusStartDate
    ceParticipationStatusEndDate
    crisisAssessment
    directServices
    housingAssessment
    preventionAssessment
    receivesReferrals
    dateCreated
    dateUpdated
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const HmisParticipationFieldsFragmentDoc = gql`
  fragment HmisParticipationFields on HmisParticipation {
    id
    hmisParticipationType
    hmisParticipationStatusStartDate
    hmisParticipationStatusEndDate
    dateCreated
    dateUpdated
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const ProjectEnrollmentFieldsFragmentDoc = gql`
  fragment ProjectEnrollmentFields on Enrollment {
    id
    lockVersion
    entryDate
    exitDate
    autoExited
    inProgress
    relationshipToHoH
    enrollmentCoc
    householdId
    householdShortId
    householdSize
    client {
      id
      ...ClientNameDobVet
      ...ClientIdentificationFields
    }
  }
  ${ClientNameDobVetFragmentDoc}
  ${ClientIdentificationFieldsFragmentDoc}
`;
export const ProjectEnrollmentQueryEnrollmentFieldsFragmentDoc = gql`
  fragment ProjectEnrollmentQueryEnrollmentFields on Enrollment {
    ...ProjectEnrollmentFields
    lastCurrentLivingSituation @include(if: $includeCls) {
      id
      informationDate
    }
    household @include(if: $includeStaffAssignment) {
      id
      ...HouseholdWithStaffAssignments
    }
  }
  ${ProjectEnrollmentFieldsFragmentDoc}
  ${HouseholdWithStaffAssignmentsFragmentDoc}
`;
export const ProjectConfigFieldsFragmentDoc = gql`
  fragment ProjectConfigFields on ProjectConfig {
    id
    configType
    organizationId
    organization {
      id
      organizationName
    }
    projectId
    project {
      id
      projectName
    }
    projectType
    configOptions
  }
`;
export const ReferralPostingFieldsFragmentDoc = gql`
  fragment ReferralPostingFields on ReferralPosting {
    id
    referralIdentifier
    referralDate
    hohName
    hohMciId
    householdSize
    referredFrom
    referredBy
    referredTo
    status
    assignedDate
    statusUpdatedAt
    statusUpdatedBy
    project {
      id
      projectName
      projectType
    }
    organization {
      id
      organizationName
    }
  }
`;
export const ReferralPostingDetailFieldsFragmentDoc = gql`
  fragment ReferralPostingDetailFields on ReferralPosting {
    id
    assignedDate
    chronic
    hudChronic
    denialNote
    denialReason
    needsWheelchairAccessibleUnit
    postingIdentifier
    referralDate
    referralIdentifier
    referralNotes
    referralResult
    referredBy
    referredFrom
    referredTo
    resourceCoordinatorNotes
    score
    status
    statusNote
    statusNoteUpdatedAt
    statusNoteUpdatedBy
    statusUpdatedAt
    statusUpdatedBy
    referralRequest {
      id
    }
    project {
      id
      projectType
      projectName
    }
    organization {
      id
      organizationName
    }
    unitType {
      id
      description
    }
    hohEnrollment {
      id
      client {
        id
      }
    }
    householdMembers {
      id
      relationshipToHoH
      openEnrollmentSummary {
        ...EnrollmentSummaryFields
      }
      client {
        id
        ...ClientName
        ...ClientIdentificationFields
        veteranStatus
        gender
        externalIds {
          ...ClientIdentifierFields
        }
      }
    }
    customDataElements {
      ...CustomDataElementFields
    }
  }
  ${EnrollmentSummaryFieldsFragmentDoc}
  ${ClientNameFragmentDoc}
  ${ClientIdentificationFieldsFragmentDoc}
  ${ClientIdentifierFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
`;
export const UnitTypeFieldsFragmentDoc = gql`
  fragment UnitTypeFields on UnitTypeObject {
    id
    description
    bedType
    unitSize
    dateUpdated
    dateCreated
  }
`;
export const ReferralRequestFieldsFragmentDoc = gql`
  fragment ReferralRequestFields on ReferralRequest {
    id
    requestedOn
    identifier
    unitType {
      ...UnitTypeFields
    }
    neededBy
    requestorName
    requestorPhone
    requestorEmail
  }
  ${UnitTypeFieldsFragmentDoc}
`;
export const ReminderFieldsFragmentDoc = gql`
  fragment ReminderFields on Reminder {
    id
    topic
    dueDate
    overdue
    formDefinitionId
    assessmentId
    enrollment {
      id
      relationshipToHoH
      intakeAssessment {
        id
      }
      exitAssessment {
        id
      }
    }
    client {
      id
      ...ClientNameDobVet
    }
  }
  ${ClientNameDobVetFragmentDoc}
`;
export const EsgFundingServiceFieldsFragmentDoc = gql`
  fragment EsgFundingServiceFields on EsgFundingService {
    id
    clientId
    clientDob
    mciIds {
      ...ClientIdentifierFields
    }
    firstName
    lastName
    projectId
    projectName
    organizationId
    organizationName
    faAmount
    faStartDate
    faEndDate
    customDataElements {
      ...CustomDataElementFields
    }
  }
  ${ClientIdentifierFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
`;
export const ScanCardFieldsFragmentDoc = gql`
  fragment ScanCardFields on ScanCardCode {
    id
    value
    dateCreated
    dateDeleted
    createdBy {
      ...UserFields
    }
    deletedBy {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const ServiceBasicFieldsFragmentDoc = gql`
  fragment ServiceBasicFields on Service {
    id
    dateProvided
    serviceType {
      ...ServiceTypeFields
    }
  }
  ${ServiceTypeFieldsFragmentDoc}
`;
export const ServiceFieldsFragmentDoc = gql`
  fragment ServiceFields on Service {
    id
    dateProvided
    faAmount
    faStartDate
    faEndDate
    movingOnOtherType
    referralOutcome
    subTypeProvided
    otherTypeProvided
    dateCreated
    dateUpdated
    dateDeleted
    user {
      ...UserFields
    }
    serviceType {
      ...ServiceTypeFields
    }
    customDataElements {
      ...CustomDataElementFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${ServiceTypeFieldsFragmentDoc}
  ${CustomDataElementFieldsFragmentDoc}
`;
export const ServiceCategoryFieldsFragmentDoc = gql`
  fragment ServiceCategoryFields on ServiceCategory {
    id
    name
    serviceTypes {
      offset
      limit
      nodesCount
      nodes {
        id
        name
      }
    }
  }
`;
export const StaffAssignmentWithClientsFragmentDoc = gql`
  fragment StaffAssignmentWithClients on StaffAssignment {
    id
    user {
      id
      name
    }
    staffAssignmentRelationship
    assignedAt
    unassignedAt
    household {
      id
      anyInProgress
      latestExitDate
      householdClients {
        relationshipToHoH
        client {
          id
          ...ClientName
        }
        enrollment {
          id
          ...EnrollmentRangeFields
          project {
            ...ProjectNameAndType
          }
        }
      }
    }
  }
  ${ClientNameFragmentDoc}
  ${EnrollmentRangeFieldsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
`;
export const UnitTypeCapacityFieldsFragmentDoc = gql`
  fragment UnitTypeCapacityFields on UnitTypeCapacity {
    id
    unitType
    capacity
    availability
  }
`;
export const UnitFieldsFragmentDoc = gql`
  fragment UnitFields on Unit {
    id
    unitSize
    unitType {
      ...UnitTypeFields
    }
    occupants {
      id
      relationshipToHoH
      client {
        id
        ...ClientName
      }
    }
  }
  ${UnitTypeFieldsFragmentDoc}
  ${ClientNameFragmentDoc}
`;
export const UserActivityLogFieldsFragmentDoc = gql`
  fragment UserActivityLogFields on ActivityLog {
    id
    ipAddress
    createdAt
    resolvedRecords {
      recordId
      recordType
    }
  }
`;
export const ClientAccessSummaryFieldsFragmentDoc = gql`
  fragment ClientAccessSummaryFields on ClientAccessSummary {
    id
    lastAccessedAt
    clientId
    clientName
  }
`;
export const EnrollmentAccessSummaryFieldsFragmentDoc = gql`
  fragment EnrollmentAccessSummaryFields on EnrollmentAccessSummary {
    id
    lastAccessedAt
    enrollmentId
    clientId
    clientName
    projectId
    projectName
  }
`;
export const GetRootPermissionsDocument = gql`
  query GetRootPermissions {
    access {
      ...RootPermissions
    }
  }
  ${RootPermissionsFragmentDoc}
`;

/**
 * __useGetRootPermissionsQuery__
 *
 * To run a query within a React component, call `useGetRootPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRootPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRootPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRootPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRootPermissionsQuery,
    GetRootPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRootPermissionsQuery,
    GetRootPermissionsQueryVariables
  >(GetRootPermissionsDocument, options);
}
export function useGetRootPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRootPermissionsQuery,
    GetRootPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRootPermissionsQuery,
    GetRootPermissionsQueryVariables
  >(GetRootPermissionsDocument, options);
}
export function useGetRootPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRootPermissionsQuery,
    GetRootPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRootPermissionsQuery,
    GetRootPermissionsQueryVariables
  >(GetRootPermissionsDocument, options);
}
export type GetRootPermissionsQueryHookResult = ReturnType<
  typeof useGetRootPermissionsQuery
>;
export type GetRootPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetRootPermissionsLazyQuery
>;
export type GetRootPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetRootPermissionsSuspenseQuery
>;
export type GetRootPermissionsQueryResult = Apollo.QueryResult<
  GetRootPermissionsQuery,
  GetRootPermissionsQueryVariables
>;
export const CreateClientAlertDocument = gql`
  mutation CreateClientAlert($input: ClientAlertInput!) {
    createClientAlert(input: $input) {
      clientAlert {
        ...ClientAlertFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ClientAlertFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type CreateClientAlertMutationFn = Apollo.MutationFunction<
  CreateClientAlertMutation,
  CreateClientAlertMutationVariables
>;

/**
 * __useCreateClientAlertMutation__
 *
 * To run a mutation, you first call `useCreateClientAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientAlertMutation, { data, loading, error }] = useCreateClientAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientAlertMutation,
    CreateClientAlertMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientAlertMutation,
    CreateClientAlertMutationVariables
  >(CreateClientAlertDocument, options);
}
export type CreateClientAlertMutationHookResult = ReturnType<
  typeof useCreateClientAlertMutation
>;
export type CreateClientAlertMutationResult =
  Apollo.MutationResult<CreateClientAlertMutation>;
export type CreateClientAlertMutationOptions = Apollo.BaseMutationOptions<
  CreateClientAlertMutation,
  CreateClientAlertMutationVariables
>;
export const DeleteClientAlertDocument = gql`
  mutation DeleteClientAlert($id: ID!) {
    deleteClientAlert(id: $id) {
      clientAlert {
        ...ClientAlertFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ClientAlertFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteClientAlertMutationFn = Apollo.MutationFunction<
  DeleteClientAlertMutation,
  DeleteClientAlertMutationVariables
>;

/**
 * __useDeleteClientAlertMutation__
 *
 * To run a mutation, you first call `useDeleteClientAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientAlertMutation, { data, loading, error }] = useDeleteClientAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientAlertMutation,
    DeleteClientAlertMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientAlertMutation,
    DeleteClientAlertMutationVariables
  >(DeleteClientAlertDocument, options);
}
export type DeleteClientAlertMutationHookResult = ReturnType<
  typeof useDeleteClientAlertMutation
>;
export type DeleteClientAlertMutationResult =
  Apollo.MutationResult<DeleteClientAlertMutation>;
export type DeleteClientAlertMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientAlertMutation,
  DeleteClientAlertMutationVariables
>;
export const GetHouseholdClientAlertsDocument = gql`
  query GetHouseholdClientAlerts($id: ID!) {
    household(id: $id) {
      id
      householdClients {
        id
        client {
          ...ClientWithAlertFields
        }
      }
    }
  }
  ${ClientWithAlertFieldsFragmentDoc}
`;

/**
 * __useGetHouseholdClientAlertsQuery__
 *
 * To run a query within a React component, call `useGetHouseholdClientAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseholdClientAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseholdClientAlertsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHouseholdClientAlertsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHouseholdClientAlertsQuery,
    GetHouseholdClientAlertsQueryVariables
  > &
    (
      | { variables: GetHouseholdClientAlertsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHouseholdClientAlertsQuery,
    GetHouseholdClientAlertsQueryVariables
  >(GetHouseholdClientAlertsDocument, options);
}
export function useGetHouseholdClientAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHouseholdClientAlertsQuery,
    GetHouseholdClientAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHouseholdClientAlertsQuery,
    GetHouseholdClientAlertsQueryVariables
  >(GetHouseholdClientAlertsDocument, options);
}
export function useGetHouseholdClientAlertsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHouseholdClientAlertsQuery,
    GetHouseholdClientAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetHouseholdClientAlertsQuery,
    GetHouseholdClientAlertsQueryVariables
  >(GetHouseholdClientAlertsDocument, options);
}
export type GetHouseholdClientAlertsQueryHookResult = ReturnType<
  typeof useGetHouseholdClientAlertsQuery
>;
export type GetHouseholdClientAlertsLazyQueryHookResult = ReturnType<
  typeof useGetHouseholdClientAlertsLazyQuery
>;
export type GetHouseholdClientAlertsSuspenseQueryHookResult = ReturnType<
  typeof useGetHouseholdClientAlertsSuspenseQuery
>;
export type GetHouseholdClientAlertsQueryResult = Apollo.QueryResult<
  GetHouseholdClientAlertsQuery,
  GetHouseholdClientAlertsQueryVariables
>;
export const GetAssessmentDocument = gql`
  query GetAssessment($id: ID!) {
    assessment(id: $id) {
      ...FullAssessment
      definition {
        ...FormDefinitionFields
      }
      upgradedDefinitionForEditing {
        ...FormDefinitionFields
      }
    }
  }
  ${FullAssessmentFragmentDoc}
  ${FormDefinitionFieldsFragmentDoc}
`;

/**
 * __useGetAssessmentQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssessmentQuery,
    GetAssessmentQueryVariables
  > &
    (
      | { variables: GetAssessmentQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(
    GetAssessmentDocument,
    options
  );
}
export function useGetAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentQuery,
    GetAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(
    GetAssessmentDocument,
    options
  );
}
export function useGetAssessmentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAssessmentQuery,
    GetAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAssessmentQuery,
    GetAssessmentQueryVariables
  >(GetAssessmentDocument, options);
}
export type GetAssessmentQueryHookResult = ReturnType<
  typeof useGetAssessmentQuery
>;
export type GetAssessmentLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentLazyQuery
>;
export type GetAssessmentSuspenseQueryHookResult = ReturnType<
  typeof useGetAssessmentSuspenseQuery
>;
export type GetAssessmentQueryResult = Apollo.QueryResult<
  GetAssessmentQuery,
  GetAssessmentQueryVariables
>;
export const GetClientAssessmentsDocument = gql`
  query GetClientAssessments(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $sortOrder: AssessmentSortOption = ASSESSMENT_DATE
    $filters: AssessmentFilterOptions = null
  ) {
    client(id: $id) {
      id
      assessments(
        limit: $limit
        offset: $offset
        sortOrder: $sortOrder
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...AssessmentFields
          enrollment {
            ...ClientEnrollmentFields
          }
        }
      }
    }
  }
  ${AssessmentFieldsFragmentDoc}
  ${ClientEnrollmentFieldsFragmentDoc}
`;

/**
 * __useGetClientAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetClientAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAssessmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetClientAssessmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientAssessmentsQuery,
    GetClientAssessmentsQueryVariables
  > &
    (
      | { variables: GetClientAssessmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientAssessmentsQuery,
    GetClientAssessmentsQueryVariables
  >(GetClientAssessmentsDocument, options);
}
export function useGetClientAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientAssessmentsQuery,
    GetClientAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientAssessmentsQuery,
    GetClientAssessmentsQueryVariables
  >(GetClientAssessmentsDocument, options);
}
export function useGetClientAssessmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientAssessmentsQuery,
    GetClientAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientAssessmentsQuery,
    GetClientAssessmentsQueryVariables
  >(GetClientAssessmentsDocument, options);
}
export type GetClientAssessmentsQueryHookResult = ReturnType<
  typeof useGetClientAssessmentsQuery
>;
export type GetClientAssessmentsLazyQueryHookResult = ReturnType<
  typeof useGetClientAssessmentsLazyQuery
>;
export type GetClientAssessmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetClientAssessmentsSuspenseQuery
>;
export type GetClientAssessmentsQueryResult = Apollo.QueryResult<
  GetClientAssessmentsQuery,
  GetClientAssessmentsQueryVariables
>;
export const GetEnrollmentAssessmentsDocument = gql`
  query GetEnrollmentAssessments(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $inProgress: Boolean
    $sortOrder: AssessmentSortOption = ASSESSMENT_DATE
    $filters: AssessmentsForEnrollmentFilterOptions
  ) {
    enrollment(id: $id) {
      id
      assessments(
        limit: $limit
        offset: $offset
        inProgress: $inProgress
        sortOrder: $sortOrder
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...AssessmentFields
        }
      }
    }
  }
  ${AssessmentFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentAssessmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      inProgress: // value for 'inProgress'
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetEnrollmentAssessmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentAssessmentsQuery,
    GetEnrollmentAssessmentsQueryVariables
  > &
    (
      | { variables: GetEnrollmentAssessmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentAssessmentsQuery,
    GetEnrollmentAssessmentsQueryVariables
  >(GetEnrollmentAssessmentsDocument, options);
}
export function useGetEnrollmentAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentAssessmentsQuery,
    GetEnrollmentAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentAssessmentsQuery,
    GetEnrollmentAssessmentsQueryVariables
  >(GetEnrollmentAssessmentsDocument, options);
}
export function useGetEnrollmentAssessmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentAssessmentsQuery,
    GetEnrollmentAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentAssessmentsQuery,
    GetEnrollmentAssessmentsQueryVariables
  >(GetEnrollmentAssessmentsDocument, options);
}
export type GetEnrollmentAssessmentsQueryHookResult = ReturnType<
  typeof useGetEnrollmentAssessmentsQuery
>;
export type GetEnrollmentAssessmentsLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentAssessmentsLazyQuery
>;
export type GetEnrollmentAssessmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentAssessmentsSuspenseQuery
>;
export type GetEnrollmentAssessmentsQueryResult = Apollo.QueryResult<
  GetEnrollmentAssessmentsQuery,
  GetEnrollmentAssessmentsQueryVariables
>;
export const GetHouseholdAssessmentsDocument = gql`
  query GetHouseholdAssessments(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $inProgress: Boolean
    $sortOrder: AssessmentSortOption = ASSESSMENT_DATE
    $filters: AssessmentsForHouseholdFilterOptions
  ) {
    household(id: $id) {
      id
      assessments(
        limit: $limit
        offset: $offset
        inProgress: $inProgress
        sortOrder: $sortOrder
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...AssessmentFields
          enrollment {
            id
            relationshipToHoH
            client {
              ...ClientName
            }
          }
        }
      }
    }
  }
  ${AssessmentFieldsFragmentDoc}
  ${ClientNameFragmentDoc}
`;

/**
 * __useGetHouseholdAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetHouseholdAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseholdAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseholdAssessmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      inProgress: // value for 'inProgress'
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetHouseholdAssessmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHouseholdAssessmentsQuery,
    GetHouseholdAssessmentsQueryVariables
  > &
    (
      | { variables: GetHouseholdAssessmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHouseholdAssessmentsQuery,
    GetHouseholdAssessmentsQueryVariables
  >(GetHouseholdAssessmentsDocument, options);
}
export function useGetHouseholdAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHouseholdAssessmentsQuery,
    GetHouseholdAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHouseholdAssessmentsQuery,
    GetHouseholdAssessmentsQueryVariables
  >(GetHouseholdAssessmentsDocument, options);
}
export function useGetHouseholdAssessmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHouseholdAssessmentsQuery,
    GetHouseholdAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetHouseholdAssessmentsQuery,
    GetHouseholdAssessmentsQueryVariables
  >(GetHouseholdAssessmentsDocument, options);
}
export type GetHouseholdAssessmentsQueryHookResult = ReturnType<
  typeof useGetHouseholdAssessmentsQuery
>;
export type GetHouseholdAssessmentsLazyQueryHookResult = ReturnType<
  typeof useGetHouseholdAssessmentsLazyQuery
>;
export type GetHouseholdAssessmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetHouseholdAssessmentsSuspenseQuery
>;
export type GetHouseholdAssessmentsQueryResult = Apollo.QueryResult<
  GetHouseholdAssessmentsQuery,
  GetHouseholdAssessmentsQueryVariables
>;
export const GetRelatedAnnualsDocument = gql`
  query GetRelatedAnnuals($householdId: ID!, $assessmentId: ID) {
    householdAssessments(
      householdId: $householdId
      assessmentRole: ANNUAL
      assessmentId: $assessmentId
    ) {
      id
      assessmentDate
      enrollment {
        id
        relationshipToHoH
      }
      client {
        ...ClientName
      }
    }
  }
  ${ClientNameFragmentDoc}
`;

/**
 * __useGetRelatedAnnualsQuery__
 *
 * To run a query within a React component, call `useGetRelatedAnnualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedAnnualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedAnnualsQuery({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetRelatedAnnualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRelatedAnnualsQuery,
    GetRelatedAnnualsQueryVariables
  > &
    (
      | { variables: GetRelatedAnnualsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRelatedAnnualsQuery,
    GetRelatedAnnualsQueryVariables
  >(GetRelatedAnnualsDocument, options);
}
export function useGetRelatedAnnualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRelatedAnnualsQuery,
    GetRelatedAnnualsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRelatedAnnualsQuery,
    GetRelatedAnnualsQueryVariables
  >(GetRelatedAnnualsDocument, options);
}
export function useGetRelatedAnnualsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRelatedAnnualsQuery,
    GetRelatedAnnualsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRelatedAnnualsQuery,
    GetRelatedAnnualsQueryVariables
  >(GetRelatedAnnualsDocument, options);
}
export type GetRelatedAnnualsQueryHookResult = ReturnType<
  typeof useGetRelatedAnnualsQuery
>;
export type GetRelatedAnnualsLazyQueryHookResult = ReturnType<
  typeof useGetRelatedAnnualsLazyQuery
>;
export type GetRelatedAnnualsSuspenseQueryHookResult = ReturnType<
  typeof useGetRelatedAnnualsSuspenseQuery
>;
export type GetRelatedAnnualsQueryResult = Apollo.QueryResult<
  GetRelatedAnnualsQuery,
  GetRelatedAnnualsQueryVariables
>;
export const SaveAssessmentDocument = gql`
  mutation SaveAssessment($input: SaveAssessmentInput!) {
    saveAssessment(input: $input) {
      assessment {
        ...AssessmentWithValues
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${AssessmentWithValuesFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type SaveAssessmentMutationFn = Apollo.MutationFunction<
  SaveAssessmentMutation,
  SaveAssessmentMutationVariables
>;

/**
 * __useSaveAssessmentMutation__
 *
 * To run a mutation, you first call `useSaveAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAssessmentMutation, { data, loading, error }] = useSaveAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAssessmentMutation,
    SaveAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveAssessmentMutation,
    SaveAssessmentMutationVariables
  >(SaveAssessmentDocument, options);
}
export type SaveAssessmentMutationHookResult = ReturnType<
  typeof useSaveAssessmentMutation
>;
export type SaveAssessmentMutationResult =
  Apollo.MutationResult<SaveAssessmentMutation>;
export type SaveAssessmentMutationOptions = Apollo.BaseMutationOptions<
  SaveAssessmentMutation,
  SaveAssessmentMutationVariables
>;
export const SubmitAssessmentDocument = gql`
  mutation SubmitAssessment($input: SubmitAssessmentInput!) {
    submitAssessment(input: $input) {
      assessment {
        ...AssessmentWithRecords
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${AssessmentWithRecordsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type SubmitAssessmentMutationFn = Apollo.MutationFunction<
  SubmitAssessmentMutation,
  SubmitAssessmentMutationVariables
>;

/**
 * __useSubmitAssessmentMutation__
 *
 * To run a mutation, you first call `useSubmitAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAssessmentMutation, { data, loading, error }] = useSubmitAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitAssessmentMutation,
    SubmitAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitAssessmentMutation,
    SubmitAssessmentMutationVariables
  >(SubmitAssessmentDocument, options);
}
export type SubmitAssessmentMutationHookResult = ReturnType<
  typeof useSubmitAssessmentMutation
>;
export type SubmitAssessmentMutationResult =
  Apollo.MutationResult<SubmitAssessmentMutation>;
export type SubmitAssessmentMutationOptions = Apollo.BaseMutationOptions<
  SubmitAssessmentMutation,
  SubmitAssessmentMutationVariables
>;
export const SubmitHouseholdAssessmentsDocument = gql`
  mutation SubmitHouseholdAssessments(
    $input: SubmitHouseholdAssessmentsInput!
  ) {
    submitHouseholdAssessments(input: $input) {
      assessments {
        ...AssessmentWithRecords
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${AssessmentWithRecordsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type SubmitHouseholdAssessmentsMutationFn = Apollo.MutationFunction<
  SubmitHouseholdAssessmentsMutation,
  SubmitHouseholdAssessmentsMutationVariables
>;

/**
 * __useSubmitHouseholdAssessmentsMutation__
 *
 * To run a mutation, you first call `useSubmitHouseholdAssessmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitHouseholdAssessmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitHouseholdAssessmentsMutation, { data, loading, error }] = useSubmitHouseholdAssessmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitHouseholdAssessmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitHouseholdAssessmentsMutation,
    SubmitHouseholdAssessmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitHouseholdAssessmentsMutation,
    SubmitHouseholdAssessmentsMutationVariables
  >(SubmitHouseholdAssessmentsDocument, options);
}
export type SubmitHouseholdAssessmentsMutationHookResult = ReturnType<
  typeof useSubmitHouseholdAssessmentsMutation
>;
export type SubmitHouseholdAssessmentsMutationResult =
  Apollo.MutationResult<SubmitHouseholdAssessmentsMutation>;
export type SubmitHouseholdAssessmentsMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitHouseholdAssessmentsMutation,
    SubmitHouseholdAssessmentsMutationVariables
  >;
export const GetAssessmentsForPopulationDocument = gql`
  query GetAssessmentsForPopulation(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $roles: [String!]
  ) {
    client(id: $id) {
      id
      assessments(
        limit: $limit
        offset: $offset
        filters: { assessmentName: $roles }
        inProgress: false
        sortOrder: ASSESSMENT_DATE
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...AssessmentWithRecords
          enrollment {
            id
            entryDate
            exitDate
            project {
              ...ProjectNameAndType
            }
          }
        }
      }
    }
  }
  ${AssessmentWithRecordsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
`;

/**
 * __useGetAssessmentsForPopulationQuery__
 *
 * To run a query within a React component, call `useGetAssessmentsForPopulationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentsForPopulationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentsForPopulationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useGetAssessmentsForPopulationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssessmentsForPopulationQuery,
    GetAssessmentsForPopulationQueryVariables
  > &
    (
      | { variables: GetAssessmentsForPopulationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssessmentsForPopulationQuery,
    GetAssessmentsForPopulationQueryVariables
  >(GetAssessmentsForPopulationDocument, options);
}
export function useGetAssessmentsForPopulationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentsForPopulationQuery,
    GetAssessmentsForPopulationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssessmentsForPopulationQuery,
    GetAssessmentsForPopulationQueryVariables
  >(GetAssessmentsForPopulationDocument, options);
}
export function useGetAssessmentsForPopulationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAssessmentsForPopulationQuery,
    GetAssessmentsForPopulationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAssessmentsForPopulationQuery,
    GetAssessmentsForPopulationQueryVariables
  >(GetAssessmentsForPopulationDocument, options);
}
export type GetAssessmentsForPopulationQueryHookResult = ReturnType<
  typeof useGetAssessmentsForPopulationQuery
>;
export type GetAssessmentsForPopulationLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentsForPopulationLazyQuery
>;
export type GetAssessmentsForPopulationSuspenseQueryHookResult = ReturnType<
  typeof useGetAssessmentsForPopulationSuspenseQuery
>;
export type GetAssessmentsForPopulationQueryResult = Apollo.QueryResult<
  GetAssessmentsForPopulationQuery,
  GetAssessmentsForPopulationQueryVariables
>;
export const DeleteAssessmentDocument = gql`
  mutation DeleteAssessment($id: ID!, $assessmentLockVersion: Int) {
    deleteAssessment(
      input: { id: $id, assessmentLockVersion: $assessmentLockVersion }
    ) {
      clientMutationId
      assessmentId
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteAssessmentMutationFn = Apollo.MutationFunction<
  DeleteAssessmentMutation,
  DeleteAssessmentMutationVariables
>;

/**
 * __useDeleteAssessmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssessmentMutation, { data, loading, error }] = useDeleteAssessmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      assessmentLockVersion: // value for 'assessmentLockVersion'
 *   },
 * });
 */
export function useDeleteAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssessmentMutation,
    DeleteAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssessmentMutation,
    DeleteAssessmentMutationVariables
  >(DeleteAssessmentDocument, options);
}
export type DeleteAssessmentMutationHookResult = ReturnType<
  typeof useDeleteAssessmentMutation
>;
export type DeleteAssessmentMutationResult =
  Apollo.MutationResult<DeleteAssessmentMutation>;
export type DeleteAssessmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssessmentMutation,
  DeleteAssessmentMutationVariables
>;
export const GetClientAuditEventsDocument = gql`
  query GetClientAuditEvents(
    $id: ID!
    $limit: Int = 25
    $offset: Int = 0
    $filters: ClientAuditEventFilterOptions = null
  ) {
    client(id: $id) {
      id
      auditHistory(limit: $limit, offset: $offset, filters: $filters) {
        offset
        limit
        nodesCount
        nodes {
          ...ClientAuditEventFields
        }
      }
    }
  }
  ${ClientAuditEventFieldsFragmentDoc}
`;

/**
 * __useGetClientAuditEventsQuery__
 *
 * To run a query within a React component, call `useGetClientAuditEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAuditEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAuditEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetClientAuditEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientAuditEventsQuery,
    GetClientAuditEventsQueryVariables
  > &
    (
      | { variables: GetClientAuditEventsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientAuditEventsQuery,
    GetClientAuditEventsQueryVariables
  >(GetClientAuditEventsDocument, options);
}
export function useGetClientAuditEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientAuditEventsQuery,
    GetClientAuditEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientAuditEventsQuery,
    GetClientAuditEventsQueryVariables
  >(GetClientAuditEventsDocument, options);
}
export function useGetClientAuditEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientAuditEventsQuery,
    GetClientAuditEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientAuditEventsQuery,
    GetClientAuditEventsQueryVariables
  >(GetClientAuditEventsDocument, options);
}
export type GetClientAuditEventsQueryHookResult = ReturnType<
  typeof useGetClientAuditEventsQuery
>;
export type GetClientAuditEventsLazyQueryHookResult = ReturnType<
  typeof useGetClientAuditEventsLazyQuery
>;
export type GetClientAuditEventsSuspenseQueryHookResult = ReturnType<
  typeof useGetClientAuditEventsSuspenseQuery
>;
export type GetClientAuditEventsQueryResult = Apollo.QueryResult<
  GetClientAuditEventsQuery,
  GetClientAuditEventsQueryVariables
>;
export const GetEnrollmentAuditEventsDocument = gql`
  query GetEnrollmentAuditEvents(
    $id: ID!
    $limit: Int = 25
    $offset: Int = 0
    $filters: EnrollmentAuditEventFilterOptions = null
  ) {
    enrollment(id: $id) {
      id
      auditHistory(limit: $limit, offset: $offset, filters: $filters) {
        offset
        limit
        nodesCount
        nodes {
          ...EnrollmentAuditEventFields
        }
      }
    }
  }
  ${EnrollmentAuditEventFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentAuditEventsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentAuditEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentAuditEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentAuditEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetEnrollmentAuditEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentAuditEventsQuery,
    GetEnrollmentAuditEventsQueryVariables
  > &
    (
      | { variables: GetEnrollmentAuditEventsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentAuditEventsQuery,
    GetEnrollmentAuditEventsQueryVariables
  >(GetEnrollmentAuditEventsDocument, options);
}
export function useGetEnrollmentAuditEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentAuditEventsQuery,
    GetEnrollmentAuditEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentAuditEventsQuery,
    GetEnrollmentAuditEventsQueryVariables
  >(GetEnrollmentAuditEventsDocument, options);
}
export function useGetEnrollmentAuditEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentAuditEventsQuery,
    GetEnrollmentAuditEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentAuditEventsQuery,
    GetEnrollmentAuditEventsQueryVariables
  >(GetEnrollmentAuditEventsDocument, options);
}
export type GetEnrollmentAuditEventsQueryHookResult = ReturnType<
  typeof useGetEnrollmentAuditEventsQuery
>;
export type GetEnrollmentAuditEventsLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentAuditEventsLazyQuery
>;
export type GetEnrollmentAuditEventsSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentAuditEventsSuspenseQuery
>;
export type GetEnrollmentAuditEventsQueryResult = Apollo.QueryResult<
  GetEnrollmentAuditEventsQuery,
  GetEnrollmentAuditEventsQueryVariables
>;
export const GetUserAuditEventsDocument = gql`
  query GetUserAuditEvents(
    $id: ID!
    $limit: Int = 25
    $offset: Int = 0
    $filters: UserAuditEventFilterOptions = null
  ) {
    user(id: $id) {
      id
      auditHistory(limit: $limit, offset: $offset, filters: $filters) {
        offset
        limit
        nodesCount
        nodes {
          ...UserAuditEventFields
        }
      }
    }
  }
  ${UserAuditEventFieldsFragmentDoc}
`;

/**
 * __useGetUserAuditEventsQuery__
 *
 * To run a query within a React component, call `useGetUserAuditEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuditEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuditEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUserAuditEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAuditEventsQuery,
    GetUserAuditEventsQueryVariables
  > &
    (
      | { variables: GetUserAuditEventsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserAuditEventsQuery,
    GetUserAuditEventsQueryVariables
  >(GetUserAuditEventsDocument, options);
}
export function useGetUserAuditEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAuditEventsQuery,
    GetUserAuditEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAuditEventsQuery,
    GetUserAuditEventsQueryVariables
  >(GetUserAuditEventsDocument, options);
}
export function useGetUserAuditEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserAuditEventsQuery,
    GetUserAuditEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserAuditEventsQuery,
    GetUserAuditEventsQueryVariables
  >(GetUserAuditEventsDocument, options);
}
export type GetUserAuditEventsQueryHookResult = ReturnType<
  typeof useGetUserAuditEventsQuery
>;
export type GetUserAuditEventsLazyQueryHookResult = ReturnType<
  typeof useGetUserAuditEventsLazyQuery
>;
export type GetUserAuditEventsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserAuditEventsSuspenseQuery
>;
export type GetUserAuditEventsQueryResult = Apollo.QueryResult<
  GetUserAuditEventsQuery,
  GetUserAuditEventsQueryVariables
>;
export const BulkServicesClientSearchDocument = gql`
  query BulkServicesClientSearch(
    $textSearch: String!
    $filters: ClientFilterOptions
    $limit: Int
    $offset: Int
    $sortOrder: ClientSortOption
    $serviceTypeId: ID!
    $projectId: ID!
    $serviceDate: ISO8601Date!
  ) {
    clientSearch(
      input: { textSearch: $textSearch }
      filters: $filters
      limit: $limit
      offset: $offset
      sortOrder: $sortOrder
    ) {
      offset
      limit
      nodesCount
      nodes {
        id
        ...ClientName
        ...ClientIdentificationFields
        alerts {
          ...ClientAlertFields
        }
        activeEnrollment(projectId: $projectId, openOnDate: $serviceDate) {
          id
          entryDate
          lastServiceDate(serviceTypeId: $serviceTypeId)
          services(
            limit: 25
            offset: 0
            filters: {
              dateProvided: $serviceDate
              serviceType: [$serviceTypeId]
            }
          ) {
            limit
            offset
            nodesCount
            nodes {
              id
            }
          }
        }
      }
    }
  }
  ${ClientNameFragmentDoc}
  ${ClientIdentificationFieldsFragmentDoc}
  ${ClientAlertFieldsFragmentDoc}
`;

/**
 * __useBulkServicesClientSearchQuery__
 *
 * To run a query within a React component, call `useBulkServicesClientSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkServicesClientSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkServicesClientSearchQuery({
 *   variables: {
 *      textSearch: // value for 'textSearch'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *      serviceTypeId: // value for 'serviceTypeId'
 *      projectId: // value for 'projectId'
 *      serviceDate: // value for 'serviceDate'
 *   },
 * });
 */
export function useBulkServicesClientSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    BulkServicesClientSearchQuery,
    BulkServicesClientSearchQueryVariables
  > &
    (
      | { variables: BulkServicesClientSearchQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BulkServicesClientSearchQuery,
    BulkServicesClientSearchQueryVariables
  >(BulkServicesClientSearchDocument, options);
}
export function useBulkServicesClientSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BulkServicesClientSearchQuery,
    BulkServicesClientSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BulkServicesClientSearchQuery,
    BulkServicesClientSearchQueryVariables
  >(BulkServicesClientSearchDocument, options);
}
export function useBulkServicesClientSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BulkServicesClientSearchQuery,
    BulkServicesClientSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BulkServicesClientSearchQuery,
    BulkServicesClientSearchQueryVariables
  >(BulkServicesClientSearchDocument, options);
}
export type BulkServicesClientSearchQueryHookResult = ReturnType<
  typeof useBulkServicesClientSearchQuery
>;
export type BulkServicesClientSearchLazyQueryHookResult = ReturnType<
  typeof useBulkServicesClientSearchLazyQuery
>;
export type BulkServicesClientSearchSuspenseQueryHookResult = ReturnType<
  typeof useBulkServicesClientSearchSuspenseQuery
>;
export type BulkServicesClientSearchQueryResult = Apollo.QueryResult<
  BulkServicesClientSearchQuery,
  BulkServicesClientSearchQueryVariables
>;
export const BulkAssignServiceDocument = gql`
  mutation BulkAssignService($input: BulkAssignServiceInput!) {
    bulkAssignService(input: $input) {
      success
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type BulkAssignServiceMutationFn = Apollo.MutationFunction<
  BulkAssignServiceMutation,
  BulkAssignServiceMutationVariables
>;

/**
 * __useBulkAssignServiceMutation__
 *
 * To run a mutation, you first call `useBulkAssignServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAssignServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAssignServiceMutation, { data, loading, error }] = useBulkAssignServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkAssignServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkAssignServiceMutation,
    BulkAssignServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkAssignServiceMutation,
    BulkAssignServiceMutationVariables
  >(BulkAssignServiceDocument, options);
}
export type BulkAssignServiceMutationHookResult = ReturnType<
  typeof useBulkAssignServiceMutation
>;
export type BulkAssignServiceMutationResult =
  Apollo.MutationResult<BulkAssignServiceMutation>;
export type BulkAssignServiceMutationOptions = Apollo.BaseMutationOptions<
  BulkAssignServiceMutation,
  BulkAssignServiceMutationVariables
>;
export const BulkRemoveServiceDocument = gql`
  mutation BulkRemoveService($projectId: ID!, $serviceIds: [ID!]!) {
    bulkRemoveService(projectId: $projectId, serviceIds: $serviceIds) {
      success
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type BulkRemoveServiceMutationFn = Apollo.MutationFunction<
  BulkRemoveServiceMutation,
  BulkRemoveServiceMutationVariables
>;

/**
 * __useBulkRemoveServiceMutation__
 *
 * To run a mutation, you first call `useBulkRemoveServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkRemoveServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkRemoveServiceMutation, { data, loading, error }] = useBulkRemoveServiceMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      serviceIds: // value for 'serviceIds'
 *   },
 * });
 */
export function useBulkRemoveServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkRemoveServiceMutation,
    BulkRemoveServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkRemoveServiceMutation,
    BulkRemoveServiceMutationVariables
  >(BulkRemoveServiceDocument, options);
}
export type BulkRemoveServiceMutationHookResult = ReturnType<
  typeof useBulkRemoveServiceMutation
>;
export type BulkRemoveServiceMutationResult =
  Apollo.MutationResult<BulkRemoveServiceMutation>;
export type BulkRemoveServiceMutationOptions = Apollo.BaseMutationOptions<
  BulkRemoveServiceMutation,
  BulkRemoveServiceMutationVariables
>;
export const SearchClientsDocument = gql`
  query SearchClients(
    $filters: ClientFilterOptions
    $input: ClientSearchInput!
    $limit: Int
    $offset: Int
    $sortOrder: ClientSortOption = LAST_NAME_A_TO_Z
    $includeSsn: Boolean = false
  ) {
    clientSearch(
      input: $input
      filters: $filters
      limit: $limit
      offset: $offset
      sortOrder: $sortOrder
    ) {
      offset
      limit
      nodesCount
      nodes {
        ...ClientSearchResultFields
      }
    }
  }
  ${ClientSearchResultFieldsFragmentDoc}
`;

/**
 * __useSearchClientsQuery__
 *
 * To run a query within a React component, call `useSearchClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClientsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *      includeSsn: // value for 'includeSsn'
 *   },
 * });
 */
export function useSearchClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchClientsQuery,
    SearchClientsQueryVariables
  > &
    (
      | { variables: SearchClientsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchClientsQuery, SearchClientsQueryVariables>(
    SearchClientsDocument,
    options
  );
}
export function useSearchClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchClientsQuery,
    SearchClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchClientsQuery, SearchClientsQueryVariables>(
    SearchClientsDocument,
    options
  );
}
export function useSearchClientsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchClientsQuery,
    SearchClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SearchClientsQuery,
    SearchClientsQueryVariables
  >(SearchClientsDocument, options);
}
export type SearchClientsQueryHookResult = ReturnType<
  typeof useSearchClientsQuery
>;
export type SearchClientsLazyQueryHookResult = ReturnType<
  typeof useSearchClientsLazyQuery
>;
export type SearchClientsSuspenseQueryHookResult = ReturnType<
  typeof useSearchClientsSuspenseQuery
>;
export type SearchClientsQueryResult = Apollo.QueryResult<
  SearchClientsQuery,
  SearchClientsQueryVariables
>;
export const GetClientDocument = gql`
  query GetClient($id: ID!) {
    client(id: $id) {
      ...ClientFields
    }
  }
  ${ClientFieldsFragmentDoc}
`;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  > &
    ({ variables: GetClientQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options
  );
}
export function useGetClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options
  );
}
export function useGetClientSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options
  );
}
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<
  typeof useGetClientLazyQuery
>;
export type GetClientSuspenseQueryHookResult = ReturnType<
  typeof useGetClientSuspenseQuery
>;
export type GetClientQueryResult = Apollo.QueryResult<
  GetClientQuery,
  GetClientQueryVariables
>;
export const GetClientNameDocument = gql`
  query GetClientName($id: ID!) {
    client(id: $id) {
      id
      ...ClientName
    }
  }
  ${ClientNameFragmentDoc}
`;

/**
 * __useGetClientNameQuery__
 *
 * To run a query within a React component, call `useGetClientNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNameQuery,
    GetClientNameQueryVariables
  > &
    (
      | { variables: GetClientNameQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientNameQuery, GetClientNameQueryVariables>(
    GetClientNameDocument,
    options
  );
}
export function useGetClientNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNameQuery,
    GetClientNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientNameQuery, GetClientNameQueryVariables>(
    GetClientNameDocument,
    options
  );
}
export function useGetClientNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientNameQuery,
    GetClientNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientNameQuery,
    GetClientNameQueryVariables
  >(GetClientNameDocument, options);
}
export type GetClientNameQueryHookResult = ReturnType<
  typeof useGetClientNameQuery
>;
export type GetClientNameLazyQueryHookResult = ReturnType<
  typeof useGetClientNameLazyQuery
>;
export type GetClientNameSuspenseQueryHookResult = ReturnType<
  typeof useGetClientNameSuspenseQuery
>;
export type GetClientNameQueryResult = Apollo.QueryResult<
  GetClientNameQuery,
  GetClientNameQueryVariables
>;
export const GetClientPermissionsDocument = gql`
  query GetClientPermissions($id: ID!) {
    client(id: $id) {
      id
      access {
        ...ClientAccessFields
      }
    }
  }
  ${ClientAccessFieldsFragmentDoc}
`;

/**
 * __useGetClientPermissionsQuery__
 *
 * To run a query within a React component, call `useGetClientPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientPermissionsQuery,
    GetClientPermissionsQueryVariables
  > &
    (
      | { variables: GetClientPermissionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientPermissionsQuery,
    GetClientPermissionsQueryVariables
  >(GetClientPermissionsDocument, options);
}
export function useGetClientPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientPermissionsQuery,
    GetClientPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientPermissionsQuery,
    GetClientPermissionsQueryVariables
  >(GetClientPermissionsDocument, options);
}
export function useGetClientPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientPermissionsQuery,
    GetClientPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientPermissionsQuery,
    GetClientPermissionsQueryVariables
  >(GetClientPermissionsDocument, options);
}
export type GetClientPermissionsQueryHookResult = ReturnType<
  typeof useGetClientPermissionsQuery
>;
export type GetClientPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetClientPermissionsLazyQuery
>;
export type GetClientPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetClientPermissionsSuspenseQuery
>;
export type GetClientPermissionsQueryResult = Apollo.QueryResult<
  GetClientPermissionsQuery,
  GetClientPermissionsQueryVariables
>;
export const GetClientImageDocument = gql`
  query GetClientImage($id: ID!) {
    client(id: $id) {
      ...ClientImage
    }
  }
  ${ClientImageFragmentDoc}
`;

/**
 * __useGetClientImageQuery__
 *
 * To run a query within a React component, call `useGetClientImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientImageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientImageQuery,
    GetClientImageQueryVariables
  > &
    (
      | { variables: GetClientImageQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientImageQuery, GetClientImageQueryVariables>(
    GetClientImageDocument,
    options
  );
}
export function useGetClientImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientImageQuery,
    GetClientImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientImageQuery, GetClientImageQueryVariables>(
    GetClientImageDocument,
    options
  );
}
export function useGetClientImageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientImageQuery,
    GetClientImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientImageQuery,
    GetClientImageQueryVariables
  >(GetClientImageDocument, options);
}
export type GetClientImageQueryHookResult = ReturnType<
  typeof useGetClientImageQuery
>;
export type GetClientImageLazyQueryHookResult = ReturnType<
  typeof useGetClientImageLazyQuery
>;
export type GetClientImageSuspenseQueryHookResult = ReturnType<
  typeof useGetClientImageSuspenseQuery
>;
export type GetClientImageQueryResult = Apollo.QueryResult<
  GetClientImageQuery,
  GetClientImageQueryVariables
>;
export const GetClientEnrollmentsDocument = gql`
  query GetClientEnrollments(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $filters: EnrollmentsForClientFilterOptions
  ) {
    client(id: $id) {
      id
      enrollments(
        limit: $limit
        offset: $offset
        sortOrder: MOST_RECENT
        filters: $filters
        includeEnrollmentsWithLimitedAccess: true
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...ClientEnrollmentFields
        }
      }
    }
  }
  ${ClientEnrollmentFieldsFragmentDoc}
`;

/**
 * __useGetClientEnrollmentsQuery__
 *
 * To run a query within a React component, call `useGetClientEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientEnrollmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetClientEnrollmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientEnrollmentsQuery,
    GetClientEnrollmentsQueryVariables
  > &
    (
      | { variables: GetClientEnrollmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientEnrollmentsQuery,
    GetClientEnrollmentsQueryVariables
  >(GetClientEnrollmentsDocument, options);
}
export function useGetClientEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientEnrollmentsQuery,
    GetClientEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientEnrollmentsQuery,
    GetClientEnrollmentsQueryVariables
  >(GetClientEnrollmentsDocument, options);
}
export function useGetClientEnrollmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientEnrollmentsQuery,
    GetClientEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientEnrollmentsQuery,
    GetClientEnrollmentsQueryVariables
  >(GetClientEnrollmentsDocument, options);
}
export type GetClientEnrollmentsQueryHookResult = ReturnType<
  typeof useGetClientEnrollmentsQuery
>;
export type GetClientEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useGetClientEnrollmentsLazyQuery
>;
export type GetClientEnrollmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetClientEnrollmentsSuspenseQuery
>;
export type GetClientEnrollmentsQueryResult = Apollo.QueryResult<
  GetClientEnrollmentsQuery,
  GetClientEnrollmentsQueryVariables
>;
export const GetClientServicesDocument = gql`
  query GetClientServices(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $sortOrder: ServiceSortOption = DATE_PROVIDED
    $filters: ServiceFilterOptions = null
  ) {
    client(id: $id) {
      id
      services(
        limit: $limit
        offset: $offset
        sortOrder: $sortOrder
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...ServiceFields
          enrollment {
            ...ClientEnrollmentFields
          }
        }
      }
    }
  }
  ${ServiceFieldsFragmentDoc}
  ${ClientEnrollmentFieldsFragmentDoc}
`;

/**
 * __useGetClientServicesQuery__
 *
 * To run a query within a React component, call `useGetClientServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientServicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetClientServicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientServicesQuery,
    GetClientServicesQueryVariables
  > &
    (
      | { variables: GetClientServicesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientServicesQuery,
    GetClientServicesQueryVariables
  >(GetClientServicesDocument, options);
}
export function useGetClientServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientServicesQuery,
    GetClientServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientServicesQuery,
    GetClientServicesQueryVariables
  >(GetClientServicesDocument, options);
}
export function useGetClientServicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientServicesQuery,
    GetClientServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientServicesQuery,
    GetClientServicesQueryVariables
  >(GetClientServicesDocument, options);
}
export type GetClientServicesQueryHookResult = ReturnType<
  typeof useGetClientServicesQuery
>;
export type GetClientServicesLazyQueryHookResult = ReturnType<
  typeof useGetClientServicesLazyQuery
>;
export type GetClientServicesSuspenseQueryHookResult = ReturnType<
  typeof useGetClientServicesSuspenseQuery
>;
export type GetClientServicesQueryResult = Apollo.QueryResult<
  GetClientServicesQuery,
  GetClientServicesQueryVariables
>;
export const UpdateClientImageDocument = gql`
  mutation UpdateClientImage($clientId: ID!, $imageBlobId: ID!) {
    updateClientImage(
      input: { clientId: $clientId, imageBlobId: $imageBlobId }
    ) {
      clientMutationId
      client {
        ...ClientImage
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ClientImageFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type UpdateClientImageMutationFn = Apollo.MutationFunction<
  UpdateClientImageMutation,
  UpdateClientImageMutationVariables
>;

/**
 * __useUpdateClientImageMutation__
 *
 * To run a mutation, you first call `useUpdateClientImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientImageMutation, { data, loading, error }] = useUpdateClientImageMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      imageBlobId: // value for 'imageBlobId'
 *   },
 * });
 */
export function useUpdateClientImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientImageMutation,
    UpdateClientImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientImageMutation,
    UpdateClientImageMutationVariables
  >(UpdateClientImageDocument, options);
}
export type UpdateClientImageMutationHookResult = ReturnType<
  typeof useUpdateClientImageMutation
>;
export type UpdateClientImageMutationResult =
  Apollo.MutationResult<UpdateClientImageMutation>;
export type UpdateClientImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientImageMutation,
  UpdateClientImageMutationVariables
>;
export const DeleteClientImageDocument = gql`
  mutation DeleteClientImage($clientId: ID!) {
    deleteClientImage(input: { clientId: $clientId }) {
      clientMutationId
      client {
        ...ClientImage
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ClientImageFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteClientImageMutationFn = Apollo.MutationFunction<
  DeleteClientImageMutation,
  DeleteClientImageMutationVariables
>;

/**
 * __useDeleteClientImageMutation__
 *
 * To run a mutation, you first call `useDeleteClientImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientImageMutation, { data, loading, error }] = useDeleteClientImageMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useDeleteClientImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientImageMutation,
    DeleteClientImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientImageMutation,
    DeleteClientImageMutationVariables
  >(DeleteClientImageDocument, options);
}
export type DeleteClientImageMutationHookResult = ReturnType<
  typeof useDeleteClientImageMutation
>;
export type DeleteClientImageMutationResult =
  Apollo.MutationResult<DeleteClientImageMutation>;
export type DeleteClientImageMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientImageMutation,
  DeleteClientImageMutationVariables
>;
export const DeleteClientFileDocument = gql`
  mutation DeleteClientFile($input: DeleteClientFileInput!) {
    deleteClientFile(input: $input) {
      clientMutationId
      file {
        ...FileFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${FileFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteClientFileMutationFn = Apollo.MutationFunction<
  DeleteClientFileMutation,
  DeleteClientFileMutationVariables
>;

/**
 * __useDeleteClientFileMutation__
 *
 * To run a mutation, you first call `useDeleteClientFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientFileMutation, { data, loading, error }] = useDeleteClientFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientFileMutation,
    DeleteClientFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientFileMutation,
    DeleteClientFileMutationVariables
  >(DeleteClientFileDocument, options);
}
export type DeleteClientFileMutationHookResult = ReturnType<
  typeof useDeleteClientFileMutation
>;
export type DeleteClientFileMutationResult =
  Apollo.MutationResult<DeleteClientFileMutation>;
export type DeleteClientFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientFileMutation,
  DeleteClientFileMutationVariables
>;
export const DeleteClientDocument = gql`
  mutation DeleteClient($input: DeleteClientInput!) {
    deleteClient(input: $input) {
      clientMutationId
      client {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteClientMutationFn = Apollo.MutationFunction<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >(DeleteClientDocument, options);
}
export type DeleteClientMutationHookResult = ReturnType<
  typeof useDeleteClientMutation
>;
export type DeleteClientMutationResult =
  Apollo.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;
export const GetClientHouseholdMemberCandidatesDocument = gql`
  query GetClientHouseholdMemberCandidates(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    client(id: $id) {
      id
      lockVersion
      enrollments(limit: $limit, offset: $offset, sortOrder: MOST_RECENT) {
        offset
        limit
        nodesCount
        nodes {
          id
          project {
            ...ProjectNameAndType
          }
          household {
            id
            householdClients {
              ...HouseholdClientFields
            }
          }
        }
      }
    }
  }
  ${ProjectNameAndTypeFragmentDoc}
  ${HouseholdClientFieldsFragmentDoc}
`;

/**
 * __useGetClientHouseholdMemberCandidatesQuery__
 *
 * To run a query within a React component, call `useGetClientHouseholdMemberCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientHouseholdMemberCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientHouseholdMemberCandidatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetClientHouseholdMemberCandidatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientHouseholdMemberCandidatesQuery,
    GetClientHouseholdMemberCandidatesQueryVariables
  > &
    (
      | {
          variables: GetClientHouseholdMemberCandidatesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientHouseholdMemberCandidatesQuery,
    GetClientHouseholdMemberCandidatesQueryVariables
  >(GetClientHouseholdMemberCandidatesDocument, options);
}
export function useGetClientHouseholdMemberCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientHouseholdMemberCandidatesQuery,
    GetClientHouseholdMemberCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientHouseholdMemberCandidatesQuery,
    GetClientHouseholdMemberCandidatesQueryVariables
  >(GetClientHouseholdMemberCandidatesDocument, options);
}
export function useGetClientHouseholdMemberCandidatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientHouseholdMemberCandidatesQuery,
    GetClientHouseholdMemberCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientHouseholdMemberCandidatesQuery,
    GetClientHouseholdMemberCandidatesQueryVariables
  >(GetClientHouseholdMemberCandidatesDocument, options);
}
export type GetClientHouseholdMemberCandidatesQueryHookResult = ReturnType<
  typeof useGetClientHouseholdMemberCandidatesQuery
>;
export type GetClientHouseholdMemberCandidatesLazyQueryHookResult = ReturnType<
  typeof useGetClientHouseholdMemberCandidatesLazyQuery
>;
export type GetClientHouseholdMemberCandidatesSuspenseQueryHookResult =
  ReturnType<typeof useGetClientHouseholdMemberCandidatesSuspenseQuery>;
export type GetClientHouseholdMemberCandidatesQueryResult = Apollo.QueryResult<
  GetClientHouseholdMemberCandidatesQuery,
  GetClientHouseholdMemberCandidatesQueryVariables
>;
export const GetFileDocument = gql`
  query GetFile($id: ID!) {
    file(id: $id) {
      ...FileFields
    }
  }
  ${FileFieldsFragmentDoc}
`;

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFileQuery(
  baseOptions: Apollo.QueryHookOptions<GetFileQuery, GetFileQueryVariables> &
    ({ variables: GetFileQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFileQuery, GetFileQueryVariables>(
    GetFileDocument,
    options
  );
}
export function useGetFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFileQuery, GetFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFileQuery, GetFileQueryVariables>(
    GetFileDocument,
    options
  );
}
export function useGetFileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFileQuery,
    GetFileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFileQuery, GetFileQueryVariables>(
    GetFileDocument,
    options
  );
}
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>;
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>;
export type GetFileSuspenseQueryHookResult = ReturnType<
  typeof useGetFileSuspenseQuery
>;
export type GetFileQueryResult = Apollo.QueryResult<
  GetFileQuery,
  GetFileQueryVariables
>;
export const GetClientFilesDocument = gql`
  query GetClientFiles($id: ID!, $limit: Int = 10, $offset: Int = 0) {
    client(id: $id) {
      id
      files(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...FileFields
          enrollment {
            ...ClientEnrollmentFields
          }
        }
      }
    }
  }
  ${FileFieldsFragmentDoc}
  ${ClientEnrollmentFieldsFragmentDoc}
`;

/**
 * __useGetClientFilesQuery__
 *
 * To run a query within a React component, call `useGetClientFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetClientFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientFilesQuery,
    GetClientFilesQueryVariables
  > &
    (
      | { variables: GetClientFilesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientFilesQuery, GetClientFilesQueryVariables>(
    GetClientFilesDocument,
    options
  );
}
export function useGetClientFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientFilesQuery,
    GetClientFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientFilesQuery, GetClientFilesQueryVariables>(
    GetClientFilesDocument,
    options
  );
}
export function useGetClientFilesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientFilesQuery,
    GetClientFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientFilesQuery,
    GetClientFilesQueryVariables
  >(GetClientFilesDocument, options);
}
export type GetClientFilesQueryHookResult = ReturnType<
  typeof useGetClientFilesQuery
>;
export type GetClientFilesLazyQueryHookResult = ReturnType<
  typeof useGetClientFilesLazyQuery
>;
export type GetClientFilesSuspenseQueryHookResult = ReturnType<
  typeof useGetClientFilesSuspenseQuery
>;
export type GetClientFilesQueryResult = Apollo.QueryResult<
  GetClientFilesQuery,
  GetClientFilesQueryVariables
>;
export const ClientDetailFormsDocument = gql`
  query ClientDetailForms {
    clientDetailForms {
      ...OccurrencePointFormFields
    }
  }
  ${OccurrencePointFormFieldsFragmentDoc}
`;

/**
 * __useClientDetailFormsQuery__
 *
 * To run a query within a React component, call `useClientDetailFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientDetailFormsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClientDetailFormsQuery,
    ClientDetailFormsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientDetailFormsQuery,
    ClientDetailFormsQueryVariables
  >(ClientDetailFormsDocument, options);
}
export function useClientDetailFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientDetailFormsQuery,
    ClientDetailFormsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientDetailFormsQuery,
    ClientDetailFormsQueryVariables
  >(ClientDetailFormsDocument, options);
}
export function useClientDetailFormsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ClientDetailFormsQuery,
    ClientDetailFormsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientDetailFormsQuery,
    ClientDetailFormsQueryVariables
  >(ClientDetailFormsDocument, options);
}
export type ClientDetailFormsQueryHookResult = ReturnType<
  typeof useClientDetailFormsQuery
>;
export type ClientDetailFormsLazyQueryHookResult = ReturnType<
  typeof useClientDetailFormsLazyQuery
>;
export type ClientDetailFormsSuspenseQueryHookResult = ReturnType<
  typeof useClientDetailFormsSuspenseQuery
>;
export type ClientDetailFormsQueryResult = Apollo.QueryResult<
  ClientDetailFormsQuery,
  ClientDetailFormsQueryVariables
>;
export const GetMergeCandidatesDocument = gql`
  query GetMergeCandidates($limit: Int, $offset: Int) {
    mergeCandidates(limit: $limit, offset: $offset) {
      offset
      limit
      nodesCount
      nodes {
        id
        warehouseUrl
        clients {
          ...ClientName
          ...ClientIdentificationFields
          ...ClientSsnFields
        }
      }
    }
  }
  ${ClientNameFragmentDoc}
  ${ClientIdentificationFieldsFragmentDoc}
  ${ClientSsnFieldsFragmentDoc}
`;

/**
 * __useGetMergeCandidatesQuery__
 *
 * To run a query within a React component, call `useGetMergeCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMergeCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMergeCandidatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetMergeCandidatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMergeCandidatesQuery,
    GetMergeCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMergeCandidatesQuery,
    GetMergeCandidatesQueryVariables
  >(GetMergeCandidatesDocument, options);
}
export function useGetMergeCandidatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMergeCandidatesQuery,
    GetMergeCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMergeCandidatesQuery,
    GetMergeCandidatesQueryVariables
  >(GetMergeCandidatesDocument, options);
}
export function useGetMergeCandidatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMergeCandidatesQuery,
    GetMergeCandidatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMergeCandidatesQuery,
    GetMergeCandidatesQueryVariables
  >(GetMergeCandidatesDocument, options);
}
export type GetMergeCandidatesQueryHookResult = ReturnType<
  typeof useGetMergeCandidatesQuery
>;
export type GetMergeCandidatesLazyQueryHookResult = ReturnType<
  typeof useGetMergeCandidatesLazyQuery
>;
export type GetMergeCandidatesSuspenseQueryHookResult = ReturnType<
  typeof useGetMergeCandidatesSuspenseQuery
>;
export type GetMergeCandidatesQueryResult = Apollo.QueryResult<
  GetMergeCandidatesQuery,
  GetMergeCandidatesQueryVariables
>;
export const ClientMergeHistoryDocument = gql`
  query ClientMergeHistory($clientId: ID!, $limit: Int, $offset: Int) {
    client(id: $clientId) {
      id
      mergeAuditHistory(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...MergeAuditEventFields
        }
      }
    }
  }
  ${MergeAuditEventFieldsFragmentDoc}
`;

/**
 * __useClientMergeHistoryQuery__
 *
 * To run a query within a React component, call `useClientMergeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientMergeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientMergeHistoryQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useClientMergeHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientMergeHistoryQuery,
    ClientMergeHistoryQueryVariables
  > &
    (
      | { variables: ClientMergeHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientMergeHistoryQuery,
    ClientMergeHistoryQueryVariables
  >(ClientMergeHistoryDocument, options);
}
export function useClientMergeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientMergeHistoryQuery,
    ClientMergeHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientMergeHistoryQuery,
    ClientMergeHistoryQueryVariables
  >(ClientMergeHistoryDocument, options);
}
export function useClientMergeHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ClientMergeHistoryQuery,
    ClientMergeHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientMergeHistoryQuery,
    ClientMergeHistoryQueryVariables
  >(ClientMergeHistoryDocument, options);
}
export type ClientMergeHistoryQueryHookResult = ReturnType<
  typeof useClientMergeHistoryQuery
>;
export type ClientMergeHistoryLazyQueryHookResult = ReturnType<
  typeof useClientMergeHistoryLazyQuery
>;
export type ClientMergeHistorySuspenseQueryHookResult = ReturnType<
  typeof useClientMergeHistorySuspenseQuery
>;
export type ClientMergeHistoryQueryResult = Apollo.QueryResult<
  ClientMergeHistoryQuery,
  ClientMergeHistoryQueryVariables
>;
export const GlobalClientMergeHistoryDocument = gql`
  query GlobalClientMergeHistory(
    $limit: Int
    $offset: Int
    $filters: MergeAuditEventFilterOptions
  ) {
    mergeAuditHistory(limit: $limit, offset: $offset, filters: $filters) {
      offset
      limit
      nodesCount
      nodes {
        ...MergeAuditEventFields
        client {
          ...ClientName
        }
      }
    }
  }
  ${MergeAuditEventFieldsFragmentDoc}
  ${ClientNameFragmentDoc}
`;

/**
 * __useGlobalClientMergeHistoryQuery__
 *
 * To run a query within a React component, call `useGlobalClientMergeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalClientMergeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalClientMergeHistoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGlobalClientMergeHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GlobalClientMergeHistoryQuery,
    GlobalClientMergeHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GlobalClientMergeHistoryQuery,
    GlobalClientMergeHistoryQueryVariables
  >(GlobalClientMergeHistoryDocument, options);
}
export function useGlobalClientMergeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalClientMergeHistoryQuery,
    GlobalClientMergeHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GlobalClientMergeHistoryQuery,
    GlobalClientMergeHistoryQueryVariables
  >(GlobalClientMergeHistoryDocument, options);
}
export function useGlobalClientMergeHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GlobalClientMergeHistoryQuery,
    GlobalClientMergeHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GlobalClientMergeHistoryQuery,
    GlobalClientMergeHistoryQueryVariables
  >(GlobalClientMergeHistoryDocument, options);
}
export type GlobalClientMergeHistoryQueryHookResult = ReturnType<
  typeof useGlobalClientMergeHistoryQuery
>;
export type GlobalClientMergeHistoryLazyQueryHookResult = ReturnType<
  typeof useGlobalClientMergeHistoryLazyQuery
>;
export type GlobalClientMergeHistorySuspenseQueryHookResult = ReturnType<
  typeof useGlobalClientMergeHistorySuspenseQuery
>;
export type GlobalClientMergeHistoryQueryResult = Apollo.QueryResult<
  GlobalClientMergeHistoryQuery,
  GlobalClientMergeHistoryQueryVariables
>;
export const MergeClientsDocument = gql`
  mutation MergeClients($input: MergeClientsInput!) {
    mergeClients(input: $input) {
      client {
        ...ClientFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ClientFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type MergeClientsMutationFn = Apollo.MutationFunction<
  MergeClientsMutation,
  MergeClientsMutationVariables
>;

/**
 * __useMergeClientsMutation__
 *
 * To run a mutation, you first call `useMergeClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeClientsMutation, { data, loading, error }] = useMergeClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergeClientsMutation,
    MergeClientsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MergeClientsMutation,
    MergeClientsMutationVariables
  >(MergeClientsDocument, options);
}
export type MergeClientsMutationHookResult = ReturnType<
  typeof useMergeClientsMutation
>;
export type MergeClientsMutationResult =
  Apollo.MutationResult<MergeClientsMutation>;
export type MergeClientsMutationOptions = Apollo.BaseMutationOptions<
  MergeClientsMutation,
  MergeClientsMutationVariables
>;
export const BulkMergeClientsDocument = gql`
  mutation BulkMergeClients($input: BulkMergeClientsInput!) {
    bulkMergeClients(input: $input) {
      success
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type BulkMergeClientsMutationFn = Apollo.MutationFunction<
  BulkMergeClientsMutation,
  BulkMergeClientsMutationVariables
>;

/**
 * __useBulkMergeClientsMutation__
 *
 * To run a mutation, you first call `useBulkMergeClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkMergeClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkMergeClientsMutation, { data, loading, error }] = useBulkMergeClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkMergeClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkMergeClientsMutation,
    BulkMergeClientsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkMergeClientsMutation,
    BulkMergeClientsMutationVariables
  >(BulkMergeClientsDocument, options);
}
export type BulkMergeClientsMutationHookResult = ReturnType<
  typeof useBulkMergeClientsMutation
>;
export type BulkMergeClientsMutationResult =
  Apollo.MutationResult<BulkMergeClientsMutation>;
export type BulkMergeClientsMutationOptions = Apollo.BaseMutationOptions<
  BulkMergeClientsMutation,
  BulkMergeClientsMutationVariables
>;
export const CreateServiceTypeDocument = gql`
  mutation CreateServiceType($input: ServiceTypeInput!) {
    createServiceType(input: $input) {
      serviceType {
        ...ServiceTypeFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ServiceTypeFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type CreateServiceTypeMutationFn = Apollo.MutationFunction<
  CreateServiceTypeMutation,
  CreateServiceTypeMutationVariables
>;

/**
 * __useCreateServiceTypeMutation__
 *
 * To run a mutation, you first call `useCreateServiceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceTypeMutation, { data, loading, error }] = useCreateServiceTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceTypeMutation,
    CreateServiceTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceTypeMutation,
    CreateServiceTypeMutationVariables
  >(CreateServiceTypeDocument, options);
}
export type CreateServiceTypeMutationHookResult = ReturnType<
  typeof useCreateServiceTypeMutation
>;
export type CreateServiceTypeMutationResult =
  Apollo.MutationResult<CreateServiceTypeMutation>;
export type CreateServiceTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceTypeMutation,
  CreateServiceTypeMutationVariables
>;
export const DeleteServiceTypeDocument = gql`
  mutation DeleteServiceType($id: ID!) {
    deleteServiceType(id: $id) {
      serviceType {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteServiceTypeMutationFn = Apollo.MutationFunction<
  DeleteServiceTypeMutation,
  DeleteServiceTypeMutationVariables
>;

/**
 * __useDeleteServiceTypeMutation__
 *
 * To run a mutation, you first call `useDeleteServiceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceTypeMutation, { data, loading, error }] = useDeleteServiceTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceTypeMutation,
    DeleteServiceTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteServiceTypeMutation,
    DeleteServiceTypeMutationVariables
  >(DeleteServiceTypeDocument, options);
}
export type DeleteServiceTypeMutationHookResult = ReturnType<
  typeof useDeleteServiceTypeMutation
>;
export type DeleteServiceTypeMutationResult =
  Apollo.MutationResult<DeleteServiceTypeMutation>;
export type DeleteServiceTypeMutationOptions = Apollo.BaseMutationOptions<
  DeleteServiceTypeMutation,
  DeleteServiceTypeMutationVariables
>;
export const UpdateServiceTypeDocument = gql`
  mutation UpdateServiceType($id: ID!, $input: ServiceTypeInput) {
    updateServiceType(id: $id, input: $input) {
      serviceType {
        ...ServiceTypeFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ServiceTypeFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type UpdateServiceTypeMutationFn = Apollo.MutationFunction<
  UpdateServiceTypeMutation,
  UpdateServiceTypeMutationVariables
>;

/**
 * __useUpdateServiceTypeMutation__
 *
 * To run a mutation, you first call `useUpdateServiceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceTypeMutation, { data, loading, error }] = useUpdateServiceTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceTypeMutation,
    UpdateServiceTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceTypeMutation,
    UpdateServiceTypeMutationVariables
  >(UpdateServiceTypeDocument, options);
}
export type UpdateServiceTypeMutationHookResult = ReturnType<
  typeof useUpdateServiceTypeMutation
>;
export type UpdateServiceTypeMutationResult =
  Apollo.MutationResult<UpdateServiceTypeMutation>;
export type UpdateServiceTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceTypeMutation,
  UpdateServiceTypeMutationVariables
>;
export const GetFormRulesDocument = gql`
  query GetFormRules(
    $limit: Int = 25
    $offset: Int = 0
    $filters: FormRuleFilterOptions
    $sortOrder: FormRuleSortOption
  ) {
    formRules(
      limit: $limit
      offset: $offset
      filters: $filters
      sortOrder: $sortOrder
    ) {
      offset
      limit
      nodesCount
      nodes {
        ...FormRuleFields
      }
    }
  }
  ${FormRuleFieldsFragmentDoc}
`;

/**
 * __useGetFormRulesQuery__
 *
 * To run a query within a React component, call `useGetFormRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormRulesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetFormRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFormRulesQuery,
    GetFormRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormRulesQuery, GetFormRulesQueryVariables>(
    GetFormRulesDocument,
    options
  );
}
export function useGetFormRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormRulesQuery,
    GetFormRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormRulesQuery, GetFormRulesQueryVariables>(
    GetFormRulesDocument,
    options
  );
}
export function useGetFormRulesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormRulesQuery,
    GetFormRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFormRulesQuery, GetFormRulesQueryVariables>(
    GetFormRulesDocument,
    options
  );
}
export type GetFormRulesQueryHookResult = ReturnType<
  typeof useGetFormRulesQuery
>;
export type GetFormRulesLazyQueryHookResult = ReturnType<
  typeof useGetFormRulesLazyQuery
>;
export type GetFormRulesSuspenseQueryHookResult = ReturnType<
  typeof useGetFormRulesSuspenseQuery
>;
export type GetFormRulesQueryResult = Apollo.QueryResult<
  GetFormRulesQuery,
  GetFormRulesQueryVariables
>;
export const GetFormProjectMatchesDocument = gql`
  query GetFormProjectMatches($id: ID!, $limit: Int = 25, $offset: Int = 0) {
    formDefinition(id: $id) {
      id
      cacheKey
      projectMatches(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...FormProjectMatchFields
        }
      }
    }
  }
  ${FormProjectMatchFieldsFragmentDoc}
`;

/**
 * __useGetFormProjectMatchesQuery__
 *
 * To run a query within a React component, call `useGetFormProjectMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormProjectMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormProjectMatchesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetFormProjectMatchesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormProjectMatchesQuery,
    GetFormProjectMatchesQueryVariables
  > &
    (
      | { variables: GetFormProjectMatchesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormProjectMatchesQuery,
    GetFormProjectMatchesQueryVariables
  >(GetFormProjectMatchesDocument, options);
}
export function useGetFormProjectMatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormProjectMatchesQuery,
    GetFormProjectMatchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormProjectMatchesQuery,
    GetFormProjectMatchesQueryVariables
  >(GetFormProjectMatchesDocument, options);
}
export function useGetFormProjectMatchesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormProjectMatchesQuery,
    GetFormProjectMatchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFormProjectMatchesQuery,
    GetFormProjectMatchesQueryVariables
  >(GetFormProjectMatchesDocument, options);
}
export type GetFormProjectMatchesQueryHookResult = ReturnType<
  typeof useGetFormProjectMatchesQuery
>;
export type GetFormProjectMatchesLazyQueryHookResult = ReturnType<
  typeof useGetFormProjectMatchesLazyQuery
>;
export type GetFormProjectMatchesSuspenseQueryHookResult = ReturnType<
  typeof useGetFormProjectMatchesSuspenseQuery
>;
export type GetFormProjectMatchesQueryResult = Apollo.QueryResult<
  GetFormProjectMatchesQuery,
  GetFormProjectMatchesQueryVariables
>;
export const GetServiceCategoryRulesDocument = gql`
  query GetServiceCategoryRules(
    $id: ID!
    $limit: Int = 25
    $offset: Int = 0
    $filters: FormRuleFilterOptions
    $sortOrder: FormRuleSortOption
  ) {
    serviceCategory(id: $id) {
      id
      formRules(
        limit: $limit
        offset: $offset
        filters: $filters
        sortOrder: $sortOrder
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...FormRuleFields
        }
      }
    }
  }
  ${FormRuleFieldsFragmentDoc}
`;

/**
 * __useGetServiceCategoryRulesQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoryRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoryRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoryRulesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetServiceCategoryRulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceCategoryRulesQuery,
    GetServiceCategoryRulesQueryVariables
  > &
    (
      | { variables: GetServiceCategoryRulesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceCategoryRulesQuery,
    GetServiceCategoryRulesQueryVariables
  >(GetServiceCategoryRulesDocument, options);
}
export function useGetServiceCategoryRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceCategoryRulesQuery,
    GetServiceCategoryRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceCategoryRulesQuery,
    GetServiceCategoryRulesQueryVariables
  >(GetServiceCategoryRulesDocument, options);
}
export function useGetServiceCategoryRulesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceCategoryRulesQuery,
    GetServiceCategoryRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceCategoryRulesQuery,
    GetServiceCategoryRulesQueryVariables
  >(GetServiceCategoryRulesDocument, options);
}
export type GetServiceCategoryRulesQueryHookResult = ReturnType<
  typeof useGetServiceCategoryRulesQuery
>;
export type GetServiceCategoryRulesLazyQueryHookResult = ReturnType<
  typeof useGetServiceCategoryRulesLazyQuery
>;
export type GetServiceCategoryRulesSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceCategoryRulesSuspenseQuery
>;
export type GetServiceCategoryRulesQueryResult = Apollo.QueryResult<
  GetServiceCategoryRulesQuery,
  GetServiceCategoryRulesQueryVariables
>;
export const GetFormRuleDocument = gql`
  query GetFormRule($id: ID!) {
    formRule(id: $id) {
      ...FormRuleFields
    }
  }
  ${FormRuleFieldsFragmentDoc}
`;

/**
 * __useGetFormRuleQuery__
 *
 * To run a query within a React component, call `useGetFormRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormRuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormRuleQuery,
    GetFormRuleQueryVariables
  > &
    (
      | { variables: GetFormRuleQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormRuleQuery, GetFormRuleQueryVariables>(
    GetFormRuleDocument,
    options
  );
}
export function useGetFormRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormRuleQuery,
    GetFormRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormRuleQuery, GetFormRuleQueryVariables>(
    GetFormRuleDocument,
    options
  );
}
export function useGetFormRuleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormRuleQuery,
    GetFormRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFormRuleQuery, GetFormRuleQueryVariables>(
    GetFormRuleDocument,
    options
  );
}
export type GetFormRuleQueryHookResult = ReturnType<typeof useGetFormRuleQuery>;
export type GetFormRuleLazyQueryHookResult = ReturnType<
  typeof useGetFormRuleLazyQuery
>;
export type GetFormRuleSuspenseQueryHookResult = ReturnType<
  typeof useGetFormRuleSuspenseQuery
>;
export type GetFormRuleQueryResult = Apollo.QueryResult<
  GetFormRuleQuery,
  GetFormRuleQueryVariables
>;
export const CreateFormRuleDocument = gql`
  mutation CreateFormRule($input: CreateFormRuleInput!) {
    createFormRule(input: $input) {
      clientMutationId
      formRule {
        ...FormRuleFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${FormRuleFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type CreateFormRuleMutationFn = Apollo.MutationFunction<
  CreateFormRuleMutation,
  CreateFormRuleMutationVariables
>;

/**
 * __useCreateFormRuleMutation__
 *
 * To run a mutation, you first call `useCreateFormRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormRuleMutation, { data, loading, error }] = useCreateFormRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFormRuleMutation,
    CreateFormRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFormRuleMutation,
    CreateFormRuleMutationVariables
  >(CreateFormRuleDocument, options);
}
export type CreateFormRuleMutationHookResult = ReturnType<
  typeof useCreateFormRuleMutation
>;
export type CreateFormRuleMutationResult =
  Apollo.MutationResult<CreateFormRuleMutation>;
export type CreateFormRuleMutationOptions = Apollo.BaseMutationOptions<
  CreateFormRuleMutation,
  CreateFormRuleMutationVariables
>;
export const DeactivateFormRuleDocument = gql`
  mutation DeactivateFormRule($id: ID!) {
    deleteFormRule(id: $id) {
      formRule {
        id
        active
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeactivateFormRuleMutationFn = Apollo.MutationFunction<
  DeactivateFormRuleMutation,
  DeactivateFormRuleMutationVariables
>;

/**
 * __useDeactivateFormRuleMutation__
 *
 * To run a mutation, you first call `useDeactivateFormRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateFormRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateFormRuleMutation, { data, loading, error }] = useDeactivateFormRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateFormRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateFormRuleMutation,
    DeactivateFormRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateFormRuleMutation,
    DeactivateFormRuleMutationVariables
  >(DeactivateFormRuleDocument, options);
}
export type DeactivateFormRuleMutationHookResult = ReturnType<
  typeof useDeactivateFormRuleMutation
>;
export type DeactivateFormRuleMutationResult =
  Apollo.MutationResult<DeactivateFormRuleMutation>;
export type DeactivateFormRuleMutationOptions = Apollo.BaseMutationOptions<
  DeactivateFormRuleMutation,
  DeactivateFormRuleMutationVariables
>;
export const GetServiceTypesDocument = gql`
  query GetServiceTypes(
    $filters: ServiceTypeFilterOptions
    $limit: Int = 25
    $offset: Int = 0
  ) {
    serviceTypes(filters: $filters, limit: $limit, offset: $offset) {
      offset
      limit
      nodesCount
      nodes {
        ...ServiceTypeConfigFields
      }
    }
  }
  ${ServiceTypeConfigFieldsFragmentDoc}
`;

/**
 * __useGetServiceTypesQuery__
 *
 * To run a query within a React component, call `useGetServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetServiceTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServiceTypesQuery,
    GetServiceTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServiceTypesQuery, GetServiceTypesQueryVariables>(
    GetServiceTypesDocument,
    options
  );
}
export function useGetServiceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceTypesQuery,
    GetServiceTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceTypesQuery,
    GetServiceTypesQueryVariables
  >(GetServiceTypesDocument, options);
}
export function useGetServiceTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceTypesQuery,
    GetServiceTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceTypesQuery,
    GetServiceTypesQueryVariables
  >(GetServiceTypesDocument, options);
}
export type GetServiceTypesQueryHookResult = ReturnType<
  typeof useGetServiceTypesQuery
>;
export type GetServiceTypesLazyQueryHookResult = ReturnType<
  typeof useGetServiceTypesLazyQuery
>;
export type GetServiceTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceTypesSuspenseQuery
>;
export type GetServiceTypesQueryResult = Apollo.QueryResult<
  GetServiceTypesQuery,
  GetServiceTypesQueryVariables
>;
export const GetServiceCategoriesDocument = gql`
  query GetServiceCategories($limit: Int = 25, $offset: Int = 0) {
    serviceCategories(limit: $limit, offset: $offset) {
      offset
      limit
      nodesCount
      nodes {
        ...ServiceCategoryFields
      }
    }
  }
  ${ServiceCategoryFieldsFragmentDoc}
`;

/**
 * __useGetServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetServiceCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServiceCategoriesQuery,
    GetServiceCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceCategoriesQuery,
    GetServiceCategoriesQueryVariables
  >(GetServiceCategoriesDocument, options);
}
export function useGetServiceCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceCategoriesQuery,
    GetServiceCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceCategoriesQuery,
    GetServiceCategoriesQueryVariables
  >(GetServiceCategoriesDocument, options);
}
export function useGetServiceCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceCategoriesQuery,
    GetServiceCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceCategoriesQuery,
    GetServiceCategoriesQueryVariables
  >(GetServiceCategoriesDocument, options);
}
export type GetServiceCategoriesQueryHookResult = ReturnType<
  typeof useGetServiceCategoriesQuery
>;
export type GetServiceCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetServiceCategoriesLazyQuery
>;
export type GetServiceCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceCategoriesSuspenseQuery
>;
export type GetServiceCategoriesQueryResult = Apollo.QueryResult<
  GetServiceCategoriesQuery,
  GetServiceCategoriesQueryVariables
>;
export const GetServiceTypeDetailsDocument = gql`
  query GetServiceTypeDetails($id: ID!) {
    serviceType(id: $id) {
      ...ServiceTypeConfigFields
    }
  }
  ${ServiceTypeConfigFieldsFragmentDoc}
`;

/**
 * __useGetServiceTypeDetailsQuery__
 *
 * To run a query within a React component, call `useGetServiceTypeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceTypeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceTypeDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceTypeDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceTypeDetailsQuery,
    GetServiceTypeDetailsQueryVariables
  > &
    (
      | { variables: GetServiceTypeDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceTypeDetailsQuery,
    GetServiceTypeDetailsQueryVariables
  >(GetServiceTypeDetailsDocument, options);
}
export function useGetServiceTypeDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceTypeDetailsQuery,
    GetServiceTypeDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceTypeDetailsQuery,
    GetServiceTypeDetailsQueryVariables
  >(GetServiceTypeDetailsDocument, options);
}
export function useGetServiceTypeDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceTypeDetailsQuery,
    GetServiceTypeDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceTypeDetailsQuery,
    GetServiceTypeDetailsQueryVariables
  >(GetServiceTypeDetailsDocument, options);
}
export type GetServiceTypeDetailsQueryHookResult = ReturnType<
  typeof useGetServiceTypeDetailsQuery
>;
export type GetServiceTypeDetailsLazyQueryHookResult = ReturnType<
  typeof useGetServiceTypeDetailsLazyQuery
>;
export type GetServiceTypeDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceTypeDetailsSuspenseQuery
>;
export type GetServiceTypeDetailsQueryResult = Apollo.QueryResult<
  GetServiceTypeDetailsQuery,
  GetServiceTypeDetailsQueryVariables
>;
export const GetEnrollmentCurrentLivingSituationsDocument = gql`
  query GetEnrollmentCurrentLivingSituations(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    enrollment(id: $id) {
      id
      currentLivingSituations(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...CurrentLivingSituationFields
        }
      }
    }
  }
  ${CurrentLivingSituationFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentCurrentLivingSituationsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentCurrentLivingSituationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentCurrentLivingSituationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentCurrentLivingSituationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetEnrollmentCurrentLivingSituationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentCurrentLivingSituationsQuery,
    GetEnrollmentCurrentLivingSituationsQueryVariables
  > &
    (
      | {
          variables: GetEnrollmentCurrentLivingSituationsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentCurrentLivingSituationsQuery,
    GetEnrollmentCurrentLivingSituationsQueryVariables
  >(GetEnrollmentCurrentLivingSituationsDocument, options);
}
export function useGetEnrollmentCurrentLivingSituationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentCurrentLivingSituationsQuery,
    GetEnrollmentCurrentLivingSituationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentCurrentLivingSituationsQuery,
    GetEnrollmentCurrentLivingSituationsQueryVariables
  >(GetEnrollmentCurrentLivingSituationsDocument, options);
}
export function useGetEnrollmentCurrentLivingSituationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentCurrentLivingSituationsQuery,
    GetEnrollmentCurrentLivingSituationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentCurrentLivingSituationsQuery,
    GetEnrollmentCurrentLivingSituationsQueryVariables
  >(GetEnrollmentCurrentLivingSituationsDocument, options);
}
export type GetEnrollmentCurrentLivingSituationsQueryHookResult = ReturnType<
  typeof useGetEnrollmentCurrentLivingSituationsQuery
>;
export type GetEnrollmentCurrentLivingSituationsLazyQueryHookResult =
  ReturnType<typeof useGetEnrollmentCurrentLivingSituationsLazyQuery>;
export type GetEnrollmentCurrentLivingSituationsSuspenseQueryHookResult =
  ReturnType<typeof useGetEnrollmentCurrentLivingSituationsSuspenseQuery>;
export type GetEnrollmentCurrentLivingSituationsQueryResult =
  Apollo.QueryResult<
    GetEnrollmentCurrentLivingSituationsQuery,
    GetEnrollmentCurrentLivingSituationsQueryVariables
  >;
export const GetProjectCurrentLivingSituationsDocument = gql`
  query GetProjectCurrentLivingSituations(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    project(id: $id) {
      id
      currentLivingSituations(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...ProjectCurrentLivingSituationFields
        }
      }
    }
  }
  ${ProjectCurrentLivingSituationFieldsFragmentDoc}
`;

/**
 * __useGetProjectCurrentLivingSituationsQuery__
 *
 * To run a query within a React component, call `useGetProjectCurrentLivingSituationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCurrentLivingSituationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCurrentLivingSituationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProjectCurrentLivingSituationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectCurrentLivingSituationsQuery,
    GetProjectCurrentLivingSituationsQueryVariables
  > &
    (
      | {
          variables: GetProjectCurrentLivingSituationsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectCurrentLivingSituationsQuery,
    GetProjectCurrentLivingSituationsQueryVariables
  >(GetProjectCurrentLivingSituationsDocument, options);
}
export function useGetProjectCurrentLivingSituationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCurrentLivingSituationsQuery,
    GetProjectCurrentLivingSituationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectCurrentLivingSituationsQuery,
    GetProjectCurrentLivingSituationsQueryVariables
  >(GetProjectCurrentLivingSituationsDocument, options);
}
export function useGetProjectCurrentLivingSituationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectCurrentLivingSituationsQuery,
    GetProjectCurrentLivingSituationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectCurrentLivingSituationsQuery,
    GetProjectCurrentLivingSituationsQueryVariables
  >(GetProjectCurrentLivingSituationsDocument, options);
}
export type GetProjectCurrentLivingSituationsQueryHookResult = ReturnType<
  typeof useGetProjectCurrentLivingSituationsQuery
>;
export type GetProjectCurrentLivingSituationsLazyQueryHookResult = ReturnType<
  typeof useGetProjectCurrentLivingSituationsLazyQuery
>;
export type GetProjectCurrentLivingSituationsSuspenseQueryHookResult =
  ReturnType<typeof useGetProjectCurrentLivingSituationsSuspenseQuery>;
export type GetProjectCurrentLivingSituationsQueryResult = Apollo.QueryResult<
  GetProjectCurrentLivingSituationsQuery,
  GetProjectCurrentLivingSituationsQueryVariables
>;
export const GetEnrollmentCustomCaseNotesDocument = gql`
  query GetEnrollmentCustomCaseNotes(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $sortOrder: CustomCaseNoteSortOption
  ) {
    enrollment(id: $id) {
      id
      customCaseNotes(limit: $limit, offset: $offset, sortOrder: $sortOrder) {
        offset
        limit
        nodesCount
        nodes {
          ...CustomCaseNoteFields
        }
      }
    }
  }
  ${CustomCaseNoteFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentCustomCaseNotesQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentCustomCaseNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentCustomCaseNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentCustomCaseNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetEnrollmentCustomCaseNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentCustomCaseNotesQuery,
    GetEnrollmentCustomCaseNotesQueryVariables
  > &
    (
      | {
          variables: GetEnrollmentCustomCaseNotesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentCustomCaseNotesQuery,
    GetEnrollmentCustomCaseNotesQueryVariables
  >(GetEnrollmentCustomCaseNotesDocument, options);
}
export function useGetEnrollmentCustomCaseNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentCustomCaseNotesQuery,
    GetEnrollmentCustomCaseNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentCustomCaseNotesQuery,
    GetEnrollmentCustomCaseNotesQueryVariables
  >(GetEnrollmentCustomCaseNotesDocument, options);
}
export function useGetEnrollmentCustomCaseNotesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentCustomCaseNotesQuery,
    GetEnrollmentCustomCaseNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentCustomCaseNotesQuery,
    GetEnrollmentCustomCaseNotesQueryVariables
  >(GetEnrollmentCustomCaseNotesDocument, options);
}
export type GetEnrollmentCustomCaseNotesQueryHookResult = ReturnType<
  typeof useGetEnrollmentCustomCaseNotesQuery
>;
export type GetEnrollmentCustomCaseNotesLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentCustomCaseNotesLazyQuery
>;
export type GetEnrollmentCustomCaseNotesSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentCustomCaseNotesSuspenseQuery
>;
export type GetEnrollmentCustomCaseNotesQueryResult = Apollo.QueryResult<
  GetEnrollmentCustomCaseNotesQuery,
  GetEnrollmentCustomCaseNotesQueryVariables
>;
export const DeleteCustomCaseNoteDocument = gql`
  mutation DeleteCustomCaseNote($id: ID!) {
    deleteCustomCaseNote(id: $id) {
      customCaseNote {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteCustomCaseNoteMutationFn = Apollo.MutationFunction<
  DeleteCustomCaseNoteMutation,
  DeleteCustomCaseNoteMutationVariables
>;

/**
 * __useDeleteCustomCaseNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCustomCaseNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomCaseNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomCaseNoteMutation, { data, loading, error }] = useDeleteCustomCaseNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomCaseNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomCaseNoteMutation,
    DeleteCustomCaseNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomCaseNoteMutation,
    DeleteCustomCaseNoteMutationVariables
  >(DeleteCustomCaseNoteDocument, options);
}
export type DeleteCustomCaseNoteMutationHookResult = ReturnType<
  typeof useDeleteCustomCaseNoteMutation
>;
export type DeleteCustomCaseNoteMutationResult =
  Apollo.MutationResult<DeleteCustomCaseNoteMutation>;
export type DeleteCustomCaseNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomCaseNoteMutation,
  DeleteCustomCaseNoteMutationVariables
>;
export const GetClientCaseNotesDocument = gql`
  query GetClientCaseNotes(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $sortOrder: CustomCaseNoteSortOption
  ) {
    client(id: $id) {
      id
      customCaseNotes(limit: $limit, offset: $offset, sortOrder: $sortOrder) {
        offset
        limit
        nodesCount
        nodes {
          ...CustomCaseNoteFields
          enrollment {
            ...ClientEnrollmentFields
          }
        }
      }
    }
  }
  ${CustomCaseNoteFieldsFragmentDoc}
  ${ClientEnrollmentFieldsFragmentDoc}
`;

/**
 * __useGetClientCaseNotesQuery__
 *
 * To run a query within a React component, call `useGetClientCaseNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCaseNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCaseNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetClientCaseNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientCaseNotesQuery,
    GetClientCaseNotesQueryVariables
  > &
    (
      | { variables: GetClientCaseNotesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientCaseNotesQuery,
    GetClientCaseNotesQueryVariables
  >(GetClientCaseNotesDocument, options);
}
export function useGetClientCaseNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientCaseNotesQuery,
    GetClientCaseNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientCaseNotesQuery,
    GetClientCaseNotesQueryVariables
  >(GetClientCaseNotesDocument, options);
}
export function useGetClientCaseNotesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientCaseNotesQuery,
    GetClientCaseNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientCaseNotesQuery,
    GetClientCaseNotesQueryVariables
  >(GetClientCaseNotesDocument, options);
}
export type GetClientCaseNotesQueryHookResult = ReturnType<
  typeof useGetClientCaseNotesQuery
>;
export type GetClientCaseNotesLazyQueryHookResult = ReturnType<
  typeof useGetClientCaseNotesLazyQuery
>;
export type GetClientCaseNotesSuspenseQueryHookResult = ReturnType<
  typeof useGetClientCaseNotesSuspenseQuery
>;
export type GetClientCaseNotesQueryResult = Apollo.QueryResult<
  GetClientCaseNotesQuery,
  GetClientCaseNotesQueryVariables
>;
export const GetEnrollmentDocument = gql`
  query GetEnrollment($id: ID!) {
    enrollment(id: $id) {
      ...EnrollmentFields
      ...EnrollmentValues
    }
  }
  ${EnrollmentFieldsFragmentDoc}
  ${EnrollmentValuesFragmentDoc}
`;

/**
 * __useGetEnrollmentQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnrollmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentQuery,
    GetEnrollmentQueryVariables
  > &
    (
      | { variables: GetEnrollmentQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEnrollmentQuery, GetEnrollmentQueryVariables>(
    GetEnrollmentDocument,
    options
  );
}
export function useGetEnrollmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentQuery,
    GetEnrollmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEnrollmentQuery, GetEnrollmentQueryVariables>(
    GetEnrollmentDocument,
    options
  );
}
export function useGetEnrollmentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentQuery,
    GetEnrollmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentQuery,
    GetEnrollmentQueryVariables
  >(GetEnrollmentDocument, options);
}
export type GetEnrollmentQueryHookResult = ReturnType<
  typeof useGetEnrollmentQuery
>;
export type GetEnrollmentLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentLazyQuery
>;
export type GetEnrollmentSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentSuspenseQuery
>;
export type GetEnrollmentQueryResult = Apollo.QueryResult<
  GetEnrollmentQuery,
  GetEnrollmentQueryVariables
>;
export const GetEnrollmentDetailsDocument = gql`
  query GetEnrollmentDetails($id: ID!) {
    enrollment(id: $id) {
      ...AllEnrollmentDetails
    }
  }
  ${AllEnrollmentDetailsFragmentDoc}
`;

/**
 * __useGetEnrollmentDetailsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnrollmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentDetailsQuery,
    GetEnrollmentDetailsQueryVariables
  > &
    (
      | { variables: GetEnrollmentDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentDetailsQuery,
    GetEnrollmentDetailsQueryVariables
  >(GetEnrollmentDetailsDocument, options);
}
export function useGetEnrollmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentDetailsQuery,
    GetEnrollmentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentDetailsQuery,
    GetEnrollmentDetailsQueryVariables
  >(GetEnrollmentDetailsDocument, options);
}
export function useGetEnrollmentDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentDetailsQuery,
    GetEnrollmentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentDetailsQuery,
    GetEnrollmentDetailsQueryVariables
  >(GetEnrollmentDetailsDocument, options);
}
export type GetEnrollmentDetailsQueryHookResult = ReturnType<
  typeof useGetEnrollmentDetailsQuery
>;
export type GetEnrollmentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentDetailsLazyQuery
>;
export type GetEnrollmentDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentDetailsSuspenseQuery
>;
export type GetEnrollmentDetailsQueryResult = Apollo.QueryResult<
  GetEnrollmentDetailsQuery,
  GetEnrollmentDetailsQueryVariables
>;
export const GetEnrollmentWithHouseholdDocument = gql`
  query GetEnrollmentWithHousehold($id: ID!) {
    enrollment(id: $id) {
      ...EnrollmentWithHouseholdFields
    }
  }
  ${EnrollmentWithHouseholdFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentWithHouseholdQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentWithHouseholdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentWithHouseholdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentWithHouseholdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnrollmentWithHouseholdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentWithHouseholdQuery,
    GetEnrollmentWithHouseholdQueryVariables
  > &
    (
      | { variables: GetEnrollmentWithHouseholdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentWithHouseholdQuery,
    GetEnrollmentWithHouseholdQueryVariables
  >(GetEnrollmentWithHouseholdDocument, options);
}
export function useGetEnrollmentWithHouseholdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentWithHouseholdQuery,
    GetEnrollmentWithHouseholdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentWithHouseholdQuery,
    GetEnrollmentWithHouseholdQueryVariables
  >(GetEnrollmentWithHouseholdDocument, options);
}
export function useGetEnrollmentWithHouseholdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentWithHouseholdQuery,
    GetEnrollmentWithHouseholdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentWithHouseholdQuery,
    GetEnrollmentWithHouseholdQueryVariables
  >(GetEnrollmentWithHouseholdDocument, options);
}
export type GetEnrollmentWithHouseholdQueryHookResult = ReturnType<
  typeof useGetEnrollmentWithHouseholdQuery
>;
export type GetEnrollmentWithHouseholdLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentWithHouseholdLazyQuery
>;
export type GetEnrollmentWithHouseholdSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentWithHouseholdSuspenseQuery
>;
export type GetEnrollmentWithHouseholdQueryResult = Apollo.QueryResult<
  GetEnrollmentWithHouseholdQuery,
  GetEnrollmentWithHouseholdQueryVariables
>;
export const GetEnrollmentEventsDocument = gql`
  query GetEnrollmentEvents($id: ID!, $limit: Int = 10, $offset: Int = 0) {
    enrollment(id: $id) {
      id
      lockVersion
      events(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...EventFields
        }
      }
    }
  }
  ${EventFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentEventsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetEnrollmentEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentEventsQuery,
    GetEnrollmentEventsQueryVariables
  > &
    (
      | { variables: GetEnrollmentEventsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentEventsQuery,
    GetEnrollmentEventsQueryVariables
  >(GetEnrollmentEventsDocument, options);
}
export function useGetEnrollmentEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentEventsQuery,
    GetEnrollmentEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentEventsQuery,
    GetEnrollmentEventsQueryVariables
  >(GetEnrollmentEventsDocument, options);
}
export function useGetEnrollmentEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentEventsQuery,
    GetEnrollmentEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentEventsQuery,
    GetEnrollmentEventsQueryVariables
  >(GetEnrollmentEventsDocument, options);
}
export type GetEnrollmentEventsQueryHookResult = ReturnType<
  typeof useGetEnrollmentEventsQuery
>;
export type GetEnrollmentEventsLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentEventsLazyQuery
>;
export type GetEnrollmentEventsSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentEventsSuspenseQuery
>;
export type GetEnrollmentEventsQueryResult = Apollo.QueryResult<
  GetEnrollmentEventsQuery,
  GetEnrollmentEventsQueryVariables
>;
export const GetEnrollmentCeAssessmentsDocument = gql`
  query GetEnrollmentCeAssessments(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    enrollment(id: $id) {
      id
      ceAssessments(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...CeAssessmentFields
        }
      }
    }
  }
  ${CeAssessmentFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentCeAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentCeAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentCeAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentCeAssessmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetEnrollmentCeAssessmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentCeAssessmentsQuery,
    GetEnrollmentCeAssessmentsQueryVariables
  > &
    (
      | { variables: GetEnrollmentCeAssessmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentCeAssessmentsQuery,
    GetEnrollmentCeAssessmentsQueryVariables
  >(GetEnrollmentCeAssessmentsDocument, options);
}
export function useGetEnrollmentCeAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentCeAssessmentsQuery,
    GetEnrollmentCeAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentCeAssessmentsQuery,
    GetEnrollmentCeAssessmentsQueryVariables
  >(GetEnrollmentCeAssessmentsDocument, options);
}
export function useGetEnrollmentCeAssessmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentCeAssessmentsQuery,
    GetEnrollmentCeAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentCeAssessmentsQuery,
    GetEnrollmentCeAssessmentsQueryVariables
  >(GetEnrollmentCeAssessmentsDocument, options);
}
export type GetEnrollmentCeAssessmentsQueryHookResult = ReturnType<
  typeof useGetEnrollmentCeAssessmentsQuery
>;
export type GetEnrollmentCeAssessmentsLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentCeAssessmentsLazyQuery
>;
export type GetEnrollmentCeAssessmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentCeAssessmentsSuspenseQuery
>;
export type GetEnrollmentCeAssessmentsQueryResult = Apollo.QueryResult<
  GetEnrollmentCeAssessmentsQuery,
  GetEnrollmentCeAssessmentsQueryVariables
>;
export const GetEnrollmentRemindersDocument = gql`
  query GetEnrollmentReminders($id: ID!) {
    enrollment(id: $id) {
      id
      reminders {
        ...ReminderFields
      }
    }
  }
  ${ReminderFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentRemindersQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentRemindersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnrollmentRemindersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentRemindersQuery,
    GetEnrollmentRemindersQueryVariables
  > &
    (
      | { variables: GetEnrollmentRemindersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentRemindersQuery,
    GetEnrollmentRemindersQueryVariables
  >(GetEnrollmentRemindersDocument, options);
}
export function useGetEnrollmentRemindersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentRemindersQuery,
    GetEnrollmentRemindersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentRemindersQuery,
    GetEnrollmentRemindersQueryVariables
  >(GetEnrollmentRemindersDocument, options);
}
export function useGetEnrollmentRemindersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentRemindersQuery,
    GetEnrollmentRemindersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentRemindersQuery,
    GetEnrollmentRemindersQueryVariables
  >(GetEnrollmentRemindersDocument, options);
}
export type GetEnrollmentRemindersQueryHookResult = ReturnType<
  typeof useGetEnrollmentRemindersQuery
>;
export type GetEnrollmentRemindersLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentRemindersLazyQuery
>;
export type GetEnrollmentRemindersSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentRemindersSuspenseQuery
>;
export type GetEnrollmentRemindersQueryResult = Apollo.QueryResult<
  GetEnrollmentRemindersQuery,
  GetEnrollmentRemindersQueryVariables
>;
export const DeleteEnrollmentDocument = gql`
  mutation DeleteEnrollment($input: DeleteEnrollmentInput!) {
    deleteEnrollment(input: $input) {
      clientMutationId
      enrollment {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteEnrollmentMutationFn = Apollo.MutationFunction<
  DeleteEnrollmentMutation,
  DeleteEnrollmentMutationVariables
>;

/**
 * __useDeleteEnrollmentMutation__
 *
 * To run a mutation, you first call `useDeleteEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnrollmentMutation, { data, loading, error }] = useDeleteEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEnrollmentMutation,
    DeleteEnrollmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEnrollmentMutation,
    DeleteEnrollmentMutationVariables
  >(DeleteEnrollmentDocument, options);
}
export type DeleteEnrollmentMutationHookResult = ReturnType<
  typeof useDeleteEnrollmentMutation
>;
export type DeleteEnrollmentMutationResult =
  Apollo.MutationResult<DeleteEnrollmentMutation>;
export type DeleteEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteEnrollmentMutation,
  DeleteEnrollmentMutationVariables
>;
export const DeleteCeAssessmentDocument = gql`
  mutation DeleteCeAssessment($id: ID!) {
    deleteCeAssessment(input: { id: $id }) {
      clientMutationId
      ceAssessment {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteCeAssessmentMutationFn = Apollo.MutationFunction<
  DeleteCeAssessmentMutation,
  DeleteCeAssessmentMutationVariables
>;

/**
 * __useDeleteCeAssessmentMutation__
 *
 * To run a mutation, you first call `useDeleteCeAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCeAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCeAssessmentMutation, { data, loading, error }] = useDeleteCeAssessmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCeAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCeAssessmentMutation,
    DeleteCeAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCeAssessmentMutation,
    DeleteCeAssessmentMutationVariables
  >(DeleteCeAssessmentDocument, options);
}
export type DeleteCeAssessmentMutationHookResult = ReturnType<
  typeof useDeleteCeAssessmentMutation
>;
export type DeleteCeAssessmentMutationResult =
  Apollo.MutationResult<DeleteCeAssessmentMutation>;
export type DeleteCeAssessmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCeAssessmentMutation,
  DeleteCeAssessmentMutationVariables
>;
export const DeleteCeEventDocument = gql`
  mutation DeleteCeEvent($id: ID!) {
    deleteCeEvent(input: { id: $id }) {
      clientMutationId
      ceEvent {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteCeEventMutationFn = Apollo.MutationFunction<
  DeleteCeEventMutation,
  DeleteCeEventMutationVariables
>;

/**
 * __useDeleteCeEventMutation__
 *
 * To run a mutation, you first call `useDeleteCeEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCeEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCeEventMutation, { data, loading, error }] = useDeleteCeEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCeEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCeEventMutation,
    DeleteCeEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCeEventMutation,
    DeleteCeEventMutationVariables
  >(DeleteCeEventDocument, options);
}
export type DeleteCeEventMutationHookResult = ReturnType<
  typeof useDeleteCeEventMutation
>;
export type DeleteCeEventMutationResult =
  Apollo.MutationResult<DeleteCeEventMutation>;
export type DeleteCeEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteCeEventMutation,
  DeleteCeEventMutationVariables
>;
export const DeleteCurrentLivingSituationDocument = gql`
  mutation DeleteCurrentLivingSituation($id: ID!) {
    deleteCurrentLivingSituation(input: { id: $id }) {
      clientMutationId
      currentLivingSituation {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteCurrentLivingSituationMutationFn = Apollo.MutationFunction<
  DeleteCurrentLivingSituationMutation,
  DeleteCurrentLivingSituationMutationVariables
>;

/**
 * __useDeleteCurrentLivingSituationMutation__
 *
 * To run a mutation, you first call `useDeleteCurrentLivingSituationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurrentLivingSituationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurrentLivingSituationMutation, { data, loading, error }] = useDeleteCurrentLivingSituationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCurrentLivingSituationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCurrentLivingSituationMutation,
    DeleteCurrentLivingSituationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCurrentLivingSituationMutation,
    DeleteCurrentLivingSituationMutationVariables
  >(DeleteCurrentLivingSituationDocument, options);
}
export type DeleteCurrentLivingSituationMutationHookResult = ReturnType<
  typeof useDeleteCurrentLivingSituationMutation
>;
export type DeleteCurrentLivingSituationMutationResult =
  Apollo.MutationResult<DeleteCurrentLivingSituationMutation>;
export type DeleteCurrentLivingSituationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCurrentLivingSituationMutation,
    DeleteCurrentLivingSituationMutationVariables
  >;
export const GetEnrollmentAssessmentEligibilitiesDocument = gql`
  query GetEnrollmentAssessmentEligibilities($enrollmentId: ID!) {
    enrollment(id: $enrollmentId) {
      id
      assessmentEligibilities {
        id
        title
        formDefinitionId
        role
      }
    }
  }
`;

/**
 * __useGetEnrollmentAssessmentEligibilitiesQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentAssessmentEligibilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentAssessmentEligibilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentAssessmentEligibilitiesQuery({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useGetEnrollmentAssessmentEligibilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentAssessmentEligibilitiesQuery,
    GetEnrollmentAssessmentEligibilitiesQueryVariables
  > &
    (
      | {
          variables: GetEnrollmentAssessmentEligibilitiesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentAssessmentEligibilitiesQuery,
    GetEnrollmentAssessmentEligibilitiesQueryVariables
  >(GetEnrollmentAssessmentEligibilitiesDocument, options);
}
export function useGetEnrollmentAssessmentEligibilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentAssessmentEligibilitiesQuery,
    GetEnrollmentAssessmentEligibilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentAssessmentEligibilitiesQuery,
    GetEnrollmentAssessmentEligibilitiesQueryVariables
  >(GetEnrollmentAssessmentEligibilitiesDocument, options);
}
export function useGetEnrollmentAssessmentEligibilitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentAssessmentEligibilitiesQuery,
    GetEnrollmentAssessmentEligibilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentAssessmentEligibilitiesQuery,
    GetEnrollmentAssessmentEligibilitiesQueryVariables
  >(GetEnrollmentAssessmentEligibilitiesDocument, options);
}
export type GetEnrollmentAssessmentEligibilitiesQueryHookResult = ReturnType<
  typeof useGetEnrollmentAssessmentEligibilitiesQuery
>;
export type GetEnrollmentAssessmentEligibilitiesLazyQueryHookResult =
  ReturnType<typeof useGetEnrollmentAssessmentEligibilitiesLazyQuery>;
export type GetEnrollmentAssessmentEligibilitiesSuspenseQueryHookResult =
  ReturnType<typeof useGetEnrollmentAssessmentEligibilitiesSuspenseQuery>;
export type GetEnrollmentAssessmentEligibilitiesQueryResult =
  Apollo.QueryResult<
    GetEnrollmentAssessmentEligibilitiesQuery,
    GetEnrollmentAssessmentEligibilitiesQueryVariables
  >;
export const GetEnrollmentPermissionsDocument = gql`
  query GetEnrollmentPermissions($id: ID!) {
    enrollment(id: $id) {
      id
      access {
        ...EnrollmentAccessFields
      }
    }
  }
  ${EnrollmentAccessFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentPermissionsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnrollmentPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentPermissionsQuery,
    GetEnrollmentPermissionsQueryVariables
  > &
    (
      | { variables: GetEnrollmentPermissionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentPermissionsQuery,
    GetEnrollmentPermissionsQueryVariables
  >(GetEnrollmentPermissionsDocument, options);
}
export function useGetEnrollmentPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentPermissionsQuery,
    GetEnrollmentPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentPermissionsQuery,
    GetEnrollmentPermissionsQueryVariables
  >(GetEnrollmentPermissionsDocument, options);
}
export function useGetEnrollmentPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentPermissionsQuery,
    GetEnrollmentPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentPermissionsQuery,
    GetEnrollmentPermissionsQueryVariables
  >(GetEnrollmentPermissionsDocument, options);
}
export type GetEnrollmentPermissionsQueryHookResult = ReturnType<
  typeof useGetEnrollmentPermissionsQuery
>;
export type GetEnrollmentPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentPermissionsLazyQuery
>;
export type GetEnrollmentPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentPermissionsSuspenseQuery
>;
export type GetEnrollmentPermissionsQueryResult = Apollo.QueryResult<
  GetEnrollmentPermissionsQuery,
  GetEnrollmentPermissionsQueryVariables
>;
export const UpdateExternalFormSubmissionDocument = gql`
  mutation UpdateExternalFormSubmission(
    $id: ID!
    $input: ExternalFormSubmissionInput!
  ) {
    updateExternalFormSubmission(id: $id, input: $input) {
      externalFormSubmission {
        ...ExternalFormSubmissionSummary
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ExternalFormSubmissionSummaryFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type UpdateExternalFormSubmissionMutationFn = Apollo.MutationFunction<
  UpdateExternalFormSubmissionMutation,
  UpdateExternalFormSubmissionMutationVariables
>;

/**
 * __useUpdateExternalFormSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateExternalFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExternalFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExternalFormSubmissionMutation, { data, loading, error }] = useUpdateExternalFormSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExternalFormSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExternalFormSubmissionMutation,
    UpdateExternalFormSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExternalFormSubmissionMutation,
    UpdateExternalFormSubmissionMutationVariables
  >(UpdateExternalFormSubmissionDocument, options);
}
export type UpdateExternalFormSubmissionMutationHookResult = ReturnType<
  typeof useUpdateExternalFormSubmissionMutation
>;
export type UpdateExternalFormSubmissionMutationResult =
  Apollo.MutationResult<UpdateExternalFormSubmissionMutation>;
export type UpdateExternalFormSubmissionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateExternalFormSubmissionMutation,
    UpdateExternalFormSubmissionMutationVariables
  >;
export const BulkReviewExternalSubmissionsDocument = gql`
  mutation BulkReviewExternalSubmissions($ids: [ID!]!) {
    bulkReviewExternalSubmissions(externalSubmissionIds: $ids) {
      success
    }
  }
`;
export type BulkReviewExternalSubmissionsMutationFn = Apollo.MutationFunction<
  BulkReviewExternalSubmissionsMutation,
  BulkReviewExternalSubmissionsMutationVariables
>;

/**
 * __useBulkReviewExternalSubmissionsMutation__
 *
 * To run a mutation, you first call `useBulkReviewExternalSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkReviewExternalSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkReviewExternalSubmissionsMutation, { data, loading, error }] = useBulkReviewExternalSubmissionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkReviewExternalSubmissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkReviewExternalSubmissionsMutation,
    BulkReviewExternalSubmissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkReviewExternalSubmissionsMutation,
    BulkReviewExternalSubmissionsMutationVariables
  >(BulkReviewExternalSubmissionsDocument, options);
}
export type BulkReviewExternalSubmissionsMutationHookResult = ReturnType<
  typeof useBulkReviewExternalSubmissionsMutation
>;
export type BulkReviewExternalSubmissionsMutationResult =
  Apollo.MutationResult<BulkReviewExternalSubmissionsMutation>;
export type BulkReviewExternalSubmissionsMutationOptions =
  Apollo.BaseMutationOptions<
    BulkReviewExternalSubmissionsMutation,
    BulkReviewExternalSubmissionsMutationVariables
  >;
export const DeleteExternalFormSubmissionDocument = gql`
  mutation DeleteExternalFormSubmission($id: ID!) {
    deleteExternalFormSubmission(id: $id) {
      externalFormSubmission {
        ...ExternalFormSubmissionSummary
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ExternalFormSubmissionSummaryFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteExternalFormSubmissionMutationFn = Apollo.MutationFunction<
  DeleteExternalFormSubmissionMutation,
  DeleteExternalFormSubmissionMutationVariables
>;

/**
 * __useDeleteExternalFormSubmissionMutation__
 *
 * To run a mutation, you first call `useDeleteExternalFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExternalFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExternalFormSubmissionMutation, { data, loading, error }] = useDeleteExternalFormSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExternalFormSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExternalFormSubmissionMutation,
    DeleteExternalFormSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExternalFormSubmissionMutation,
    DeleteExternalFormSubmissionMutationVariables
  >(DeleteExternalFormSubmissionDocument, options);
}
export type DeleteExternalFormSubmissionMutationHookResult = ReturnType<
  typeof useDeleteExternalFormSubmissionMutation
>;
export type DeleteExternalFormSubmissionMutationResult =
  Apollo.MutationResult<DeleteExternalFormSubmissionMutation>;
export type DeleteExternalFormSubmissionMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteExternalFormSubmissionMutation,
    DeleteExternalFormSubmissionMutationVariables
  >;
export const RefreshExternalSubmissionsDocument = gql`
  mutation RefreshExternalSubmissions {
    refreshExternalSubmissions {
      success
    }
  }
`;
export type RefreshExternalSubmissionsMutationFn = Apollo.MutationFunction<
  RefreshExternalSubmissionsMutation,
  RefreshExternalSubmissionsMutationVariables
>;

/**
 * __useRefreshExternalSubmissionsMutation__
 *
 * To run a mutation, you first call `useRefreshExternalSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshExternalSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshExternalSubmissionsMutation, { data, loading, error }] = useRefreshExternalSubmissionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshExternalSubmissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshExternalSubmissionsMutation,
    RefreshExternalSubmissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshExternalSubmissionsMutation,
    RefreshExternalSubmissionsMutationVariables
  >(RefreshExternalSubmissionsDocument, options);
}
export type RefreshExternalSubmissionsMutationHookResult = ReturnType<
  typeof useRefreshExternalSubmissionsMutation
>;
export type RefreshExternalSubmissionsMutationResult =
  Apollo.MutationResult<RefreshExternalSubmissionsMutation>;
export type RefreshExternalSubmissionsMutationOptions =
  Apollo.BaseMutationOptions<
    RefreshExternalSubmissionsMutation,
    RefreshExternalSubmissionsMutationVariables
  >;
export const GetProjectExternalFormSubmissionsDocument = gql`
  query GetProjectExternalFormSubmissions(
    $id: ID!
    $formDefinitionIdentifier: ID!
    $limit: Int = 10
    $offset: Int = 0
    $filters: ExternalFormSubmissionFilterOptions = null
  ) {
    project(id: $id) {
      id
      externalFormSubmissions(
        limit: $limit
        offset: $offset
        formDefinitionIdentifier: $formDefinitionIdentifier
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...ExternalFormSubmissionSummary
        }
      }
    }
  }
  ${ExternalFormSubmissionSummaryFragmentDoc}
`;

/**
 * __useGetProjectExternalFormSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetProjectExternalFormSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectExternalFormSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectExternalFormSubmissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      formDefinitionIdentifier: // value for 'formDefinitionIdentifier'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProjectExternalFormSubmissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectExternalFormSubmissionsQuery,
    GetProjectExternalFormSubmissionsQueryVariables
  > &
    (
      | {
          variables: GetProjectExternalFormSubmissionsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectExternalFormSubmissionsQuery,
    GetProjectExternalFormSubmissionsQueryVariables
  >(GetProjectExternalFormSubmissionsDocument, options);
}
export function useGetProjectExternalFormSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectExternalFormSubmissionsQuery,
    GetProjectExternalFormSubmissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectExternalFormSubmissionsQuery,
    GetProjectExternalFormSubmissionsQueryVariables
  >(GetProjectExternalFormSubmissionsDocument, options);
}
export function useGetProjectExternalFormSubmissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectExternalFormSubmissionsQuery,
    GetProjectExternalFormSubmissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectExternalFormSubmissionsQuery,
    GetProjectExternalFormSubmissionsQueryVariables
  >(GetProjectExternalFormSubmissionsDocument, options);
}
export type GetProjectExternalFormSubmissionsQueryHookResult = ReturnType<
  typeof useGetProjectExternalFormSubmissionsQuery
>;
export type GetProjectExternalFormSubmissionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectExternalFormSubmissionsLazyQuery
>;
export type GetProjectExternalFormSubmissionsSuspenseQueryHookResult =
  ReturnType<typeof useGetProjectExternalFormSubmissionsSuspenseQuery>;
export type GetProjectExternalFormSubmissionsQueryResult = Apollo.QueryResult<
  GetProjectExternalFormSubmissionsQuery,
  GetProjectExternalFormSubmissionsQueryVariables
>;
export const GetExternalFormSubmissionDocument = gql`
  query GetExternalFormSubmission($id: ID!) {
    externalFormSubmission(id: $id) {
      ...ExternalFormSubmissionFields
    }
  }
  ${ExternalFormSubmissionFieldsFragmentDoc}
`;

/**
 * __useGetExternalFormSubmissionQuery__
 *
 * To run a query within a React component, call `useGetExternalFormSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalFormSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalFormSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExternalFormSubmissionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExternalFormSubmissionQuery,
    GetExternalFormSubmissionQueryVariables
  > &
    (
      | { variables: GetExternalFormSubmissionQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExternalFormSubmissionQuery,
    GetExternalFormSubmissionQueryVariables
  >(GetExternalFormSubmissionDocument, options);
}
export function useGetExternalFormSubmissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalFormSubmissionQuery,
    GetExternalFormSubmissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExternalFormSubmissionQuery,
    GetExternalFormSubmissionQueryVariables
  >(GetExternalFormSubmissionDocument, options);
}
export function useGetExternalFormSubmissionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetExternalFormSubmissionQuery,
    GetExternalFormSubmissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetExternalFormSubmissionQuery,
    GetExternalFormSubmissionQueryVariables
  >(GetExternalFormSubmissionDocument, options);
}
export type GetExternalFormSubmissionQueryHookResult = ReturnType<
  typeof useGetExternalFormSubmissionQuery
>;
export type GetExternalFormSubmissionLazyQueryHookResult = ReturnType<
  typeof useGetExternalFormSubmissionLazyQuery
>;
export type GetExternalFormSubmissionSuspenseQueryHookResult = ReturnType<
  typeof useGetExternalFormSubmissionSuspenseQuery
>;
export type GetExternalFormSubmissionQueryResult = Apollo.QueryResult<
  GetExternalFormSubmissionQuery,
  GetExternalFormSubmissionQueryVariables
>;
export const UpdateFormDefinitionDocument = gql`
  mutation UpdateFormDefinition($id: ID!, $input: FormDefinitionInput!) {
    updateFormDefinition(id: $id, input: $input) {
      formDefinition {
        ...FormDefinitionFieldsForEditor
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${FormDefinitionFieldsForEditorFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type UpdateFormDefinitionMutationFn = Apollo.MutationFunction<
  UpdateFormDefinitionMutation,
  UpdateFormDefinitionMutationVariables
>;

/**
 * __useUpdateFormDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateFormDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormDefinitionMutation, { data, loading, error }] = useUpdateFormDefinitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormDefinitionMutation,
    UpdateFormDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFormDefinitionMutation,
    UpdateFormDefinitionMutationVariables
  >(UpdateFormDefinitionDocument, options);
}
export type UpdateFormDefinitionMutationHookResult = ReturnType<
  typeof useUpdateFormDefinitionMutation
>;
export type UpdateFormDefinitionMutationResult =
  Apollo.MutationResult<UpdateFormDefinitionMutation>;
export type UpdateFormDefinitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormDefinitionMutation,
  UpdateFormDefinitionMutationVariables
>;
export const UpdateFormDefinitionFromJsonEditorDocument = gql`
  mutation UpdateFormDefinitionFromJsonEditor(
    $id: ID!
    $input: FormDefinitionInput!
  ) {
    updateFormDefinition(id: $id, input: $input) {
      formDefinition {
        ...FormDefinitionFieldsForJsonEditor
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${FormDefinitionFieldsForJsonEditorFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type UpdateFormDefinitionFromJsonEditorMutationFn =
  Apollo.MutationFunction<
    UpdateFormDefinitionFromJsonEditorMutation,
    UpdateFormDefinitionFromJsonEditorMutationVariables
  >;

/**
 * __useUpdateFormDefinitionFromJsonEditorMutation__
 *
 * To run a mutation, you first call `useUpdateFormDefinitionFromJsonEditorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormDefinitionFromJsonEditorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormDefinitionFromJsonEditorMutation, { data, loading, error }] = useUpdateFormDefinitionFromJsonEditorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormDefinitionFromJsonEditorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormDefinitionFromJsonEditorMutation,
    UpdateFormDefinitionFromJsonEditorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFormDefinitionFromJsonEditorMutation,
    UpdateFormDefinitionFromJsonEditorMutationVariables
  >(UpdateFormDefinitionFromJsonEditorDocument, options);
}
export type UpdateFormDefinitionFromJsonEditorMutationHookResult = ReturnType<
  typeof useUpdateFormDefinitionFromJsonEditorMutation
>;
export type UpdateFormDefinitionFromJsonEditorMutationResult =
  Apollo.MutationResult<UpdateFormDefinitionFromJsonEditorMutation>;
export type UpdateFormDefinitionFromJsonEditorMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateFormDefinitionFromJsonEditorMutation,
    UpdateFormDefinitionFromJsonEditorMutationVariables
  >;
export const CreateFormDefinitionDocument = gql`
  mutation CreateFormDefinition($input: FormDefinitionInput!) {
    createFormDefinition(input: $input) {
      formDefinition {
        ...FormDefinitionMetadata
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${FormDefinitionMetadataFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type CreateFormDefinitionMutationFn = Apollo.MutationFunction<
  CreateFormDefinitionMutation,
  CreateFormDefinitionMutationVariables
>;

/**
 * __useCreateFormDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateFormDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormDefinitionMutation, { data, loading, error }] = useCreateFormDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFormDefinitionMutation,
    CreateFormDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFormDefinitionMutation,
    CreateFormDefinitionMutationVariables
  >(CreateFormDefinitionDocument, options);
}
export type CreateFormDefinitionMutationHookResult = ReturnType<
  typeof useCreateFormDefinitionMutation
>;
export type CreateFormDefinitionMutationResult =
  Apollo.MutationResult<CreateFormDefinitionMutation>;
export type CreateFormDefinitionMutationOptions = Apollo.BaseMutationOptions<
  CreateFormDefinitionMutation,
  CreateFormDefinitionMutationVariables
>;
export const CreateNextDraftFormDefinitionDocument = gql`
  mutation CreateNextDraftFormDefinition($identifier: String!) {
    createNextDraftFormDefinition(identifier: $identifier) {
      formIdentifier {
        id
        identifier
        draftVersion {
          ...FormDefinitionMetadata
        }
      }
    }
  }
  ${FormDefinitionMetadataFragmentDoc}
`;
export type CreateNextDraftFormDefinitionMutationFn = Apollo.MutationFunction<
  CreateNextDraftFormDefinitionMutation,
  CreateNextDraftFormDefinitionMutationVariables
>;

/**
 * __useCreateNextDraftFormDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateNextDraftFormDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNextDraftFormDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNextDraftFormDefinitionMutation, { data, loading, error }] = useCreateNextDraftFormDefinitionMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useCreateNextDraftFormDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNextDraftFormDefinitionMutation,
    CreateNextDraftFormDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNextDraftFormDefinitionMutation,
    CreateNextDraftFormDefinitionMutationVariables
  >(CreateNextDraftFormDefinitionDocument, options);
}
export type CreateNextDraftFormDefinitionMutationHookResult = ReturnType<
  typeof useCreateNextDraftFormDefinitionMutation
>;
export type CreateNextDraftFormDefinitionMutationResult =
  Apollo.MutationResult<CreateNextDraftFormDefinitionMutation>;
export type CreateNextDraftFormDefinitionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateNextDraftFormDefinitionMutation,
    CreateNextDraftFormDefinitionMutationVariables
  >;
export const CreateDuplicateFormDefinitionDocument = gql`
  mutation CreateDuplicateFormDefinition($identifier: String!) {
    createDuplicateFormDefinition(identifier: $identifier) {
      formIdentifier {
        id
        identifier
        draftVersion {
          ...FormDefinitionMetadata
        }
      }
    }
  }
  ${FormDefinitionMetadataFragmentDoc}
`;
export type CreateDuplicateFormDefinitionMutationFn = Apollo.MutationFunction<
  CreateDuplicateFormDefinitionMutation,
  CreateDuplicateFormDefinitionMutationVariables
>;

/**
 * __useCreateDuplicateFormDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateDuplicateFormDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDuplicateFormDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDuplicateFormDefinitionMutation, { data, loading, error }] = useCreateDuplicateFormDefinitionMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useCreateDuplicateFormDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDuplicateFormDefinitionMutation,
    CreateDuplicateFormDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDuplicateFormDefinitionMutation,
    CreateDuplicateFormDefinitionMutationVariables
  >(CreateDuplicateFormDefinitionDocument, options);
}
export type CreateDuplicateFormDefinitionMutationHookResult = ReturnType<
  typeof useCreateDuplicateFormDefinitionMutation
>;
export type CreateDuplicateFormDefinitionMutationResult =
  Apollo.MutationResult<CreateDuplicateFormDefinitionMutation>;
export type CreateDuplicateFormDefinitionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDuplicateFormDefinitionMutation,
    CreateDuplicateFormDefinitionMutationVariables
  >;
export const PublishFormDefinitionDocument = gql`
  mutation PublishFormDefinition($id: ID!) {
    publishFormDefinition(id: $id) {
      formIdentifier {
        ...FormIdentifierDetails
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${FormIdentifierDetailsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type PublishFormDefinitionMutationFn = Apollo.MutationFunction<
  PublishFormDefinitionMutation,
  PublishFormDefinitionMutationVariables
>;

/**
 * __usePublishFormDefinitionMutation__
 *
 * To run a mutation, you first call `usePublishFormDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishFormDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishFormDefinitionMutation, { data, loading, error }] = usePublishFormDefinitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishFormDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishFormDefinitionMutation,
    PublishFormDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishFormDefinitionMutation,
    PublishFormDefinitionMutationVariables
  >(PublishFormDefinitionDocument, options);
}
export type PublishFormDefinitionMutationHookResult = ReturnType<
  typeof usePublishFormDefinitionMutation
>;
export type PublishFormDefinitionMutationResult =
  Apollo.MutationResult<PublishFormDefinitionMutation>;
export type PublishFormDefinitionMutationOptions = Apollo.BaseMutationOptions<
  PublishFormDefinitionMutation,
  PublishFormDefinitionMutationVariables
>;
export const DeleteFormDefinitionDraftDocument = gql`
  mutation DeleteFormDefinitionDraft($id: ID!) {
    deleteFormDefinition(id: $id) {
      formDefinition {
        id
        cacheKey
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteFormDefinitionDraftMutationFn = Apollo.MutationFunction<
  DeleteFormDefinitionDraftMutation,
  DeleteFormDefinitionDraftMutationVariables
>;

/**
 * __useDeleteFormDefinitionDraftMutation__
 *
 * To run a mutation, you first call `useDeleteFormDefinitionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormDefinitionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormDefinitionDraftMutation, { data, loading, error }] = useDeleteFormDefinitionDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormDefinitionDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFormDefinitionDraftMutation,
    DeleteFormDefinitionDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFormDefinitionDraftMutation,
    DeleteFormDefinitionDraftMutationVariables
  >(DeleteFormDefinitionDraftDocument, options);
}
export type DeleteFormDefinitionDraftMutationHookResult = ReturnType<
  typeof useDeleteFormDefinitionDraftMutation
>;
export type DeleteFormDefinitionDraftMutationResult =
  Apollo.MutationResult<DeleteFormDefinitionDraftMutation>;
export type DeleteFormDefinitionDraftMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteFormDefinitionDraftMutation,
    DeleteFormDefinitionDraftMutationVariables
  >;
export const GetPickListDocument = gql`
  query GetPickList(
    $pickListType: PickListType!
    $projectId: ID
    $clientId: ID
    $householdId: ID
    $enrollmentId: ID
  ) {
    pickList(
      pickListType: $pickListType
      projectId: $projectId
      clientId: $clientId
      householdId: $householdId
      enrollmentId: $enrollmentId
    ) {
      ...PickListOptionFields
    }
  }
  ${PickListOptionFieldsFragmentDoc}
`;

/**
 * __useGetPickListQuery__
 *
 * To run a query within a React component, call `useGetPickListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickListQuery({
 *   variables: {
 *      pickListType: // value for 'pickListType'
 *      projectId: // value for 'projectId'
 *      clientId: // value for 'clientId'
 *      householdId: // value for 'householdId'
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useGetPickListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPickListQuery,
    GetPickListQueryVariables
  > &
    (
      | { variables: GetPickListQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPickListQuery, GetPickListQueryVariables>(
    GetPickListDocument,
    options
  );
}
export function useGetPickListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPickListQuery,
    GetPickListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPickListQuery, GetPickListQueryVariables>(
    GetPickListDocument,
    options
  );
}
export function useGetPickListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPickListQuery,
    GetPickListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPickListQuery, GetPickListQueryVariables>(
    GetPickListDocument,
    options
  );
}
export type GetPickListQueryHookResult = ReturnType<typeof useGetPickListQuery>;
export type GetPickListLazyQueryHookResult = ReturnType<
  typeof useGetPickListLazyQuery
>;
export type GetPickListSuspenseQueryHookResult = ReturnType<
  typeof useGetPickListSuspenseQuery
>;
export type GetPickListQueryResult = Apollo.QueryResult<
  GetPickListQuery,
  GetPickListQueryVariables
>;
export const GetFormDefinitionDocument = gql`
  query GetFormDefinition($role: RecordFormRole!, $projectId: ID, $id: ID) {
    recordFormDefinition(role: $role, projectId: $projectId, id: $id) {
      ...FormDefinitionFields
    }
  }
  ${FormDefinitionFieldsFragmentDoc}
`;

/**
 * __useGetFormDefinitionQuery__
 *
 * To run a query within a React component, call `useGetFormDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDefinitionQuery({
 *   variables: {
 *      role: // value for 'role'
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormDefinitionQuery,
    GetFormDefinitionQueryVariables
  > &
    (
      | { variables: GetFormDefinitionQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormDefinitionQuery,
    GetFormDefinitionQueryVariables
  >(GetFormDefinitionDocument, options);
}
export function useGetFormDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormDefinitionQuery,
    GetFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormDefinitionQuery,
    GetFormDefinitionQueryVariables
  >(GetFormDefinitionDocument, options);
}
export function useGetFormDefinitionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormDefinitionQuery,
    GetFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFormDefinitionQuery,
    GetFormDefinitionQueryVariables
  >(GetFormDefinitionDocument, options);
}
export type GetFormDefinitionQueryHookResult = ReturnType<
  typeof useGetFormDefinitionQuery
>;
export type GetFormDefinitionLazyQueryHookResult = ReturnType<
  typeof useGetFormDefinitionLazyQuery
>;
export type GetFormDefinitionSuspenseQueryHookResult = ReturnType<
  typeof useGetFormDefinitionSuspenseQuery
>;
export type GetFormDefinitionQueryResult = Apollo.QueryResult<
  GetFormDefinitionQuery,
  GetFormDefinitionQueryVariables
>;
export const GetStaticFormDefinitionDocument = gql`
  query GetStaticFormDefinition($role: StaticFormRole!) {
    staticFormDefinition(role: $role) {
      ...FormDefinitionFields
    }
  }
  ${FormDefinitionFieldsFragmentDoc}
`;

/**
 * __useGetStaticFormDefinitionQuery__
 *
 * To run a query within a React component, call `useGetStaticFormDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticFormDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticFormDefinitionQuery({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useGetStaticFormDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStaticFormDefinitionQuery,
    GetStaticFormDefinitionQueryVariables
  > &
    (
      | { variables: GetStaticFormDefinitionQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStaticFormDefinitionQuery,
    GetStaticFormDefinitionQueryVariables
  >(GetStaticFormDefinitionDocument, options);
}
export function useGetStaticFormDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStaticFormDefinitionQuery,
    GetStaticFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStaticFormDefinitionQuery,
    GetStaticFormDefinitionQueryVariables
  >(GetStaticFormDefinitionDocument, options);
}
export function useGetStaticFormDefinitionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStaticFormDefinitionQuery,
    GetStaticFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetStaticFormDefinitionQuery,
    GetStaticFormDefinitionQueryVariables
  >(GetStaticFormDefinitionDocument, options);
}
export type GetStaticFormDefinitionQueryHookResult = ReturnType<
  typeof useGetStaticFormDefinitionQuery
>;
export type GetStaticFormDefinitionLazyQueryHookResult = ReturnType<
  typeof useGetStaticFormDefinitionLazyQuery
>;
export type GetStaticFormDefinitionSuspenseQueryHookResult = ReturnType<
  typeof useGetStaticFormDefinitionSuspenseQuery
>;
export type GetStaticFormDefinitionQueryResult = Apollo.QueryResult<
  GetStaticFormDefinitionQuery,
  GetStaticFormDefinitionQueryVariables
>;
export const GetServiceFormDefinitionDocument = gql`
  query GetServiceFormDefinition($serviceTypeId: ID!, $projectId: ID!) {
    serviceFormDefinition(
      serviceTypeId: $serviceTypeId
      projectId: $projectId
    ) {
      ...FormDefinitionFields
    }
  }
  ${FormDefinitionFieldsFragmentDoc}
`;

/**
 * __useGetServiceFormDefinitionQuery__
 *
 * To run a query within a React component, call `useGetServiceFormDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceFormDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceFormDefinitionQuery({
 *   variables: {
 *      serviceTypeId: // value for 'serviceTypeId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetServiceFormDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceFormDefinitionQuery,
    GetServiceFormDefinitionQueryVariables
  > &
    (
      | { variables: GetServiceFormDefinitionQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceFormDefinitionQuery,
    GetServiceFormDefinitionQueryVariables
  >(GetServiceFormDefinitionDocument, options);
}
export function useGetServiceFormDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceFormDefinitionQuery,
    GetServiceFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceFormDefinitionQuery,
    GetServiceFormDefinitionQueryVariables
  >(GetServiceFormDefinitionDocument, options);
}
export function useGetServiceFormDefinitionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceFormDefinitionQuery,
    GetServiceFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceFormDefinitionQuery,
    GetServiceFormDefinitionQueryVariables
  >(GetServiceFormDefinitionDocument, options);
}
export type GetServiceFormDefinitionQueryHookResult = ReturnType<
  typeof useGetServiceFormDefinitionQuery
>;
export type GetServiceFormDefinitionLazyQueryHookResult = ReturnType<
  typeof useGetServiceFormDefinitionLazyQuery
>;
export type GetServiceFormDefinitionSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceFormDefinitionSuspenseQuery
>;
export type GetServiceFormDefinitionQueryResult = Apollo.QueryResult<
  GetServiceFormDefinitionQuery,
  GetServiceFormDefinitionQueryVariables
>;
export const GetAssessmentFormDefinitionDocument = gql`
  query GetAssessmentFormDefinition(
    $projectId: ID!
    $id: ID
    $role: AssessmentRole
    $assessmentDate: ISO8601Date
  ) {
    assessmentFormDefinition(
      projectId: $projectId
      id: $id
      role: $role
      assessmentDate: $assessmentDate
    ) {
      ...FormDefinitionFields
    }
  }
  ${FormDefinitionFieldsFragmentDoc}
`;

/**
 * __useGetAssessmentFormDefinitionQuery__
 *
 * To run a query within a React component, call `useGetAssessmentFormDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentFormDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentFormDefinitionQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      assessmentDate: // value for 'assessmentDate'
 *   },
 * });
 */
export function useGetAssessmentFormDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssessmentFormDefinitionQuery,
    GetAssessmentFormDefinitionQueryVariables
  > &
    (
      | { variables: GetAssessmentFormDefinitionQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssessmentFormDefinitionQuery,
    GetAssessmentFormDefinitionQueryVariables
  >(GetAssessmentFormDefinitionDocument, options);
}
export function useGetAssessmentFormDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentFormDefinitionQuery,
    GetAssessmentFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssessmentFormDefinitionQuery,
    GetAssessmentFormDefinitionQueryVariables
  >(GetAssessmentFormDefinitionDocument, options);
}
export function useGetAssessmentFormDefinitionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAssessmentFormDefinitionQuery,
    GetAssessmentFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAssessmentFormDefinitionQuery,
    GetAssessmentFormDefinitionQueryVariables
  >(GetAssessmentFormDefinitionDocument, options);
}
export type GetAssessmentFormDefinitionQueryHookResult = ReturnType<
  typeof useGetAssessmentFormDefinitionQuery
>;
export type GetAssessmentFormDefinitionLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentFormDefinitionLazyQuery
>;
export type GetAssessmentFormDefinitionSuspenseQueryHookResult = ReturnType<
  typeof useGetAssessmentFormDefinitionSuspenseQuery
>;
export type GetAssessmentFormDefinitionQueryResult = Apollo.QueryResult<
  GetAssessmentFormDefinitionQuery,
  GetAssessmentFormDefinitionQueryVariables
>;
export const GetFormIdentifierDetailsDocument = gql`
  query GetFormIdentifierDetails($identifier: String!) {
    formIdentifier(identifier: $identifier) {
      ...FormIdentifierDetails
    }
  }
  ${FormIdentifierDetailsFragmentDoc}
`;

/**
 * __useGetFormIdentifierDetailsQuery__
 *
 * To run a query within a React component, call `useGetFormIdentifierDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormIdentifierDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormIdentifierDetailsQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useGetFormIdentifierDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormIdentifierDetailsQuery,
    GetFormIdentifierDetailsQueryVariables
  > &
    (
      | { variables: GetFormIdentifierDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormIdentifierDetailsQuery,
    GetFormIdentifierDetailsQueryVariables
  >(GetFormIdentifierDetailsDocument, options);
}
export function useGetFormIdentifierDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormIdentifierDetailsQuery,
    GetFormIdentifierDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormIdentifierDetailsQuery,
    GetFormIdentifierDetailsQueryVariables
  >(GetFormIdentifierDetailsDocument, options);
}
export function useGetFormIdentifierDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormIdentifierDetailsQuery,
    GetFormIdentifierDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFormIdentifierDetailsQuery,
    GetFormIdentifierDetailsQueryVariables
  >(GetFormIdentifierDetailsDocument, options);
}
export type GetFormIdentifierDetailsQueryHookResult = ReturnType<
  typeof useGetFormIdentifierDetailsQuery
>;
export type GetFormIdentifierDetailsLazyQueryHookResult = ReturnType<
  typeof useGetFormIdentifierDetailsLazyQuery
>;
export type GetFormIdentifierDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetFormIdentifierDetailsSuspenseQuery
>;
export type GetFormIdentifierDetailsQueryResult = Apollo.QueryResult<
  GetFormIdentifierDetailsQuery,
  GetFormIdentifierDetailsQueryVariables
>;
export const GetFormIdentifierVersionsDocument = gql`
  query GetFormIdentifierVersions(
    $identifier: String!
    $limit: Int = 25
    $offset: Int = 0
  ) {
    formIdentifier(identifier: $identifier) {
      id
      identifier
      allVersions(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...FormDefinitionMetadata
          version
          dateUpdated
          updatedBy {
            ...UserFields
          }
        }
      }
    }
  }
  ${FormDefinitionMetadataFragmentDoc}
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetFormIdentifierVersionsQuery__
 *
 * To run a query within a React component, call `useGetFormIdentifierVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormIdentifierVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormIdentifierVersionsQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetFormIdentifierVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormIdentifierVersionsQuery,
    GetFormIdentifierVersionsQueryVariables
  > &
    (
      | { variables: GetFormIdentifierVersionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormIdentifierVersionsQuery,
    GetFormIdentifierVersionsQueryVariables
  >(GetFormIdentifierVersionsDocument, options);
}
export function useGetFormIdentifierVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormIdentifierVersionsQuery,
    GetFormIdentifierVersionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormIdentifierVersionsQuery,
    GetFormIdentifierVersionsQueryVariables
  >(GetFormIdentifierVersionsDocument, options);
}
export function useGetFormIdentifierVersionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormIdentifierVersionsQuery,
    GetFormIdentifierVersionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFormIdentifierVersionsQuery,
    GetFormIdentifierVersionsQueryVariables
  >(GetFormIdentifierVersionsDocument, options);
}
export type GetFormIdentifierVersionsQueryHookResult = ReturnType<
  typeof useGetFormIdentifierVersionsQuery
>;
export type GetFormIdentifierVersionsLazyQueryHookResult = ReturnType<
  typeof useGetFormIdentifierVersionsLazyQuery
>;
export type GetFormIdentifierVersionsSuspenseQueryHookResult = ReturnType<
  typeof useGetFormIdentifierVersionsSuspenseQuery
>;
export type GetFormIdentifierVersionsQueryResult = Apollo.QueryResult<
  GetFormIdentifierVersionsQuery,
  GetFormIdentifierVersionsQueryVariables
>;
export const GetFormIdentifiersDocument = gql`
  query GetFormIdentifiers(
    $limit: Int = 25
    $offset: Int = 0
    $filters: FormIdentifierFilterOptions
  ) {
    formIdentifiers(limit: $limit, offset: $offset, filters: $filters) {
      offset
      limit
      nodesCount
      nodes {
        id
        identifier
        managedInVersionControl
        displayVersion {
          ...FormDefinitionMetadata
          system
          formRules(limit: 1, filters: { activeStatus: ACTIVE }) {
            nodesCount
          }
        }
      }
    }
  }
  ${FormDefinitionMetadataFragmentDoc}
`;

/**
 * __useGetFormIdentifiersQuery__
 *
 * To run a query within a React component, call `useGetFormIdentifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormIdentifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormIdentifiersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetFormIdentifiersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFormIdentifiersQuery,
    GetFormIdentifiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormIdentifiersQuery,
    GetFormIdentifiersQueryVariables
  >(GetFormIdentifiersDocument, options);
}
export function useGetFormIdentifiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormIdentifiersQuery,
    GetFormIdentifiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormIdentifiersQuery,
    GetFormIdentifiersQueryVariables
  >(GetFormIdentifiersDocument, options);
}
export function useGetFormIdentifiersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormIdentifiersQuery,
    GetFormIdentifiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFormIdentifiersQuery,
    GetFormIdentifiersQueryVariables
  >(GetFormIdentifiersDocument, options);
}
export type GetFormIdentifiersQueryHookResult = ReturnType<
  typeof useGetFormIdentifiersQuery
>;
export type GetFormIdentifiersLazyQueryHookResult = ReturnType<
  typeof useGetFormIdentifiersLazyQuery
>;
export type GetFormIdentifiersSuspenseQueryHookResult = ReturnType<
  typeof useGetFormIdentifiersSuspenseQuery
>;
export type GetFormIdentifiersQueryResult = Apollo.QueryResult<
  GetFormIdentifiersQuery,
  GetFormIdentifiersQueryVariables
>;
export const SubmitFormDocument = gql`
  mutation SubmitForm($input: SubmitFormInput!) {
    submitForm(input: $input) {
      clientMutationId
      record {
        ... on Client {
          ...ClientFields
        }
        ... on Organization {
          ...OrganizationFields
        }
        ... on Project {
          ...ProjectAllFields
        }
        ... on Funder {
          ...FunderFields
        }
        ... on ProjectCoc {
          ...ProjectCocFields
        }
        ... on Inventory {
          ...InventoryFields
        }
        ... on ReferralRequest {
          ...ReferralRequestFields
        }
        ... on Service {
          ...ServiceFields
        }
        ... on File {
          ...FileFields
        }
        ... on Enrollment {
          ...SubmittedEnrollmentResultFields
        }
        ... on CurrentLivingSituation {
          ...CurrentLivingSituationFields
        }
        ... on CeAssessment {
          ...CeAssessmentFields
        }
        ... on CustomCaseNote {
          ...CustomCaseNoteFields
        }
        ... on Event {
          ...EventFields
        }
        ... on CeParticipation {
          ...CeParticipationFields
        }
        ... on HmisParticipation {
          ...HmisParticipationFields
        }
        ... on ReferralPosting {
          id
        }
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ClientFieldsFragmentDoc}
  ${OrganizationFieldsFragmentDoc}
  ${ProjectAllFieldsFragmentDoc}
  ${FunderFieldsFragmentDoc}
  ${ProjectCocFieldsFragmentDoc}
  ${InventoryFieldsFragmentDoc}
  ${ReferralRequestFieldsFragmentDoc}
  ${ServiceFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
  ${SubmittedEnrollmentResultFieldsFragmentDoc}
  ${CurrentLivingSituationFieldsFragmentDoc}
  ${CeAssessmentFieldsFragmentDoc}
  ${CustomCaseNoteFieldsFragmentDoc}
  ${EventFieldsFragmentDoc}
  ${CeParticipationFieldsFragmentDoc}
  ${HmisParticipationFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type SubmitFormMutationFn = Apollo.MutationFunction<
  SubmitFormMutation,
  SubmitFormMutationVariables
>;

/**
 * __useSubmitFormMutation__
 *
 * To run a mutation, you first call `useSubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFormMutation, { data, loading, error }] = useSubmitFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitFormMutation,
    SubmitFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitFormMutation, SubmitFormMutationVariables>(
    SubmitFormDocument,
    options
  );
}
export type SubmitFormMutationHookResult = ReturnType<
  typeof useSubmitFormMutation
>;
export type SubmitFormMutationResult =
  Apollo.MutationResult<SubmitFormMutation>;
export type SubmitFormMutationOptions = Apollo.BaseMutationOptions<
  SubmitFormMutation,
  SubmitFormMutationVariables
>;
export const GetFormDefinitionFieldsForEditorDocument = gql`
  query GetFormDefinitionFieldsForEditor($id: ID!) {
    formDefinition(id: $id) {
      ...FormDefinitionFieldsForEditor
    }
  }
  ${FormDefinitionFieldsForEditorFragmentDoc}
`;

/**
 * __useGetFormDefinitionFieldsForEditorQuery__
 *
 * To run a query within a React component, call `useGetFormDefinitionFieldsForEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDefinitionFieldsForEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDefinitionFieldsForEditorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormDefinitionFieldsForEditorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormDefinitionFieldsForEditorQuery,
    GetFormDefinitionFieldsForEditorQueryVariables
  > &
    (
      | {
          variables: GetFormDefinitionFieldsForEditorQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormDefinitionFieldsForEditorQuery,
    GetFormDefinitionFieldsForEditorQueryVariables
  >(GetFormDefinitionFieldsForEditorDocument, options);
}
export function useGetFormDefinitionFieldsForEditorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormDefinitionFieldsForEditorQuery,
    GetFormDefinitionFieldsForEditorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormDefinitionFieldsForEditorQuery,
    GetFormDefinitionFieldsForEditorQueryVariables
  >(GetFormDefinitionFieldsForEditorDocument, options);
}
export function useGetFormDefinitionFieldsForEditorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormDefinitionFieldsForEditorQuery,
    GetFormDefinitionFieldsForEditorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFormDefinitionFieldsForEditorQuery,
    GetFormDefinitionFieldsForEditorQueryVariables
  >(GetFormDefinitionFieldsForEditorDocument, options);
}
export type GetFormDefinitionFieldsForEditorQueryHookResult = ReturnType<
  typeof useGetFormDefinitionFieldsForEditorQuery
>;
export type GetFormDefinitionFieldsForEditorLazyQueryHookResult = ReturnType<
  typeof useGetFormDefinitionFieldsForEditorLazyQuery
>;
export type GetFormDefinitionFieldsForEditorSuspenseQueryHookResult =
  ReturnType<typeof useGetFormDefinitionFieldsForEditorSuspenseQuery>;
export type GetFormDefinitionFieldsForEditorQueryResult = Apollo.QueryResult<
  GetFormDefinitionFieldsForEditorQuery,
  GetFormDefinitionFieldsForEditorQueryVariables
>;
export const GetFormDefinitionFieldsForJsonEditorDocument = gql`
  query GetFormDefinitionFieldsForJsonEditor($id: ID!) {
    formDefinition(id: $id) {
      ...FormDefinitionFieldsForJsonEditor
    }
  }
  ${FormDefinitionFieldsForJsonEditorFragmentDoc}
`;

/**
 * __useGetFormDefinitionFieldsForJsonEditorQuery__
 *
 * To run a query within a React component, call `useGetFormDefinitionFieldsForJsonEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDefinitionFieldsForJsonEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDefinitionFieldsForJsonEditorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormDefinitionFieldsForJsonEditorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormDefinitionFieldsForJsonEditorQuery,
    GetFormDefinitionFieldsForJsonEditorQueryVariables
  > &
    (
      | {
          variables: GetFormDefinitionFieldsForJsonEditorQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormDefinitionFieldsForJsonEditorQuery,
    GetFormDefinitionFieldsForJsonEditorQueryVariables
  >(GetFormDefinitionFieldsForJsonEditorDocument, options);
}
export function useGetFormDefinitionFieldsForJsonEditorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormDefinitionFieldsForJsonEditorQuery,
    GetFormDefinitionFieldsForJsonEditorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormDefinitionFieldsForJsonEditorQuery,
    GetFormDefinitionFieldsForJsonEditorQueryVariables
  >(GetFormDefinitionFieldsForJsonEditorDocument, options);
}
export function useGetFormDefinitionFieldsForJsonEditorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormDefinitionFieldsForJsonEditorQuery,
    GetFormDefinitionFieldsForJsonEditorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFormDefinitionFieldsForJsonEditorQuery,
    GetFormDefinitionFieldsForJsonEditorQueryVariables
  >(GetFormDefinitionFieldsForJsonEditorDocument, options);
}
export type GetFormDefinitionFieldsForJsonEditorQueryHookResult = ReturnType<
  typeof useGetFormDefinitionFieldsForJsonEditorQuery
>;
export type GetFormDefinitionFieldsForJsonEditorLazyQueryHookResult =
  ReturnType<typeof useGetFormDefinitionFieldsForJsonEditorLazyQuery>;
export type GetFormDefinitionFieldsForJsonEditorSuspenseQueryHookResult =
  ReturnType<typeof useGetFormDefinitionFieldsForJsonEditorSuspenseQuery>;
export type GetFormDefinitionFieldsForJsonEditorQueryResult =
  Apollo.QueryResult<
    GetFormDefinitionFieldsForJsonEditorQuery,
    GetFormDefinitionFieldsForJsonEditorQueryVariables
  >;
export const GetParsedFormDefinitionDocument = gql`
  query GetParsedFormDefinition($input: String!) {
    parsedFormDefinition(input: $input) {
      definition {
        ...FormDefinitionJsonFields
      }
      errors
    }
  }
  ${FormDefinitionJsonFieldsFragmentDoc}
`;

/**
 * __useGetParsedFormDefinitionQuery__
 *
 * To run a query within a React component, call `useGetParsedFormDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParsedFormDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParsedFormDefinitionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetParsedFormDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParsedFormDefinitionQuery,
    GetParsedFormDefinitionQueryVariables
  > &
    (
      | { variables: GetParsedFormDefinitionQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParsedFormDefinitionQuery,
    GetParsedFormDefinitionQueryVariables
  >(GetParsedFormDefinitionDocument, options);
}
export function useGetParsedFormDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParsedFormDefinitionQuery,
    GetParsedFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParsedFormDefinitionQuery,
    GetParsedFormDefinitionQueryVariables
  >(GetParsedFormDefinitionDocument, options);
}
export function useGetParsedFormDefinitionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetParsedFormDefinitionQuery,
    GetParsedFormDefinitionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetParsedFormDefinitionQuery,
    GetParsedFormDefinitionQueryVariables
  >(GetParsedFormDefinitionDocument, options);
}
export type GetParsedFormDefinitionQueryHookResult = ReturnType<
  typeof useGetParsedFormDefinitionQuery
>;
export type GetParsedFormDefinitionLazyQueryHookResult = ReturnType<
  typeof useGetParsedFormDefinitionLazyQuery
>;
export type GetParsedFormDefinitionSuspenseQueryHookResult = ReturnType<
  typeof useGetParsedFormDefinitionSuspenseQuery
>;
export type GetParsedFormDefinitionQueryResult = Apollo.QueryResult<
  GetParsedFormDefinitionQuery,
  GetParsedFormDefinitionQueryVariables
>;
export const GetEnrollmentGeolocationsDocument = gql`
  query GetEnrollmentGeolocations($id: ID!) {
    enrollment(id: $id) {
      id
      geolocations {
        ...GeolocationFieldsWithMetadata
      }
    }
  }
  ${GeolocationFieldsWithMetadataFragmentDoc}
`;

/**
 * __useGetEnrollmentGeolocationsQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentGeolocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentGeolocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentGeolocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnrollmentGeolocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentGeolocationsQuery,
    GetEnrollmentGeolocationsQueryVariables
  > &
    (
      | { variables: GetEnrollmentGeolocationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentGeolocationsQuery,
    GetEnrollmentGeolocationsQueryVariables
  >(GetEnrollmentGeolocationsDocument, options);
}
export function useGetEnrollmentGeolocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentGeolocationsQuery,
    GetEnrollmentGeolocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentGeolocationsQuery,
    GetEnrollmentGeolocationsQueryVariables
  >(GetEnrollmentGeolocationsDocument, options);
}
export function useGetEnrollmentGeolocationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentGeolocationsQuery,
    GetEnrollmentGeolocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentGeolocationsQuery,
    GetEnrollmentGeolocationsQueryVariables
  >(GetEnrollmentGeolocationsDocument, options);
}
export type GetEnrollmentGeolocationsQueryHookResult = ReturnType<
  typeof useGetEnrollmentGeolocationsQuery
>;
export type GetEnrollmentGeolocationsLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentGeolocationsLazyQuery
>;
export type GetEnrollmentGeolocationsSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentGeolocationsSuspenseQuery
>;
export type GetEnrollmentGeolocationsQueryResult = Apollo.QueryResult<
  GetEnrollmentGeolocationsQuery,
  GetEnrollmentGeolocationsQueryVariables
>;
export const JoinHouseholdDocument = gql`
  mutation JoinHousehold(
    $receivingHouseholdId: ID!
    $joiningEnrollmentInputs: [EnrollmentRelationshipInput!]!
  ) {
    joinHousehold(
      receivingHouseholdId: $receivingHouseholdId
      joiningEnrollmentInputs: $joiningEnrollmentInputs
    ) {
      receivingHousehold {
        ...HouseholdFields
      }
      donorHousehold {
        ...HouseholdFields
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type JoinHouseholdMutationFn = Apollo.MutationFunction<
  JoinHouseholdMutation,
  JoinHouseholdMutationVariables
>;

/**
 * __useJoinHouseholdMutation__
 *
 * To run a mutation, you first call `useJoinHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinHouseholdMutation, { data, loading, error }] = useJoinHouseholdMutation({
 *   variables: {
 *      receivingHouseholdId: // value for 'receivingHouseholdId'
 *      joiningEnrollmentInputs: // value for 'joiningEnrollmentInputs'
 *   },
 * });
 */
export function useJoinHouseholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinHouseholdMutation,
    JoinHouseholdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinHouseholdMutation,
    JoinHouseholdMutationVariables
  >(JoinHouseholdDocument, options);
}
export type JoinHouseholdMutationHookResult = ReturnType<
  typeof useJoinHouseholdMutation
>;
export type JoinHouseholdMutationResult =
  Apollo.MutationResult<JoinHouseholdMutation>;
export type JoinHouseholdMutationOptions = Apollo.BaseMutationOptions<
  JoinHouseholdMutation,
  JoinHouseholdMutationVariables
>;
export const SplitHouseholdDocument = gql`
  mutation SplitHousehold(
    $splittingEnrollmentInputs: [EnrollmentRelationshipInput!]!
  ) {
    splitHousehold(splittingEnrollmentInputs: $splittingEnrollmentInputs) {
      newHousehold {
        ...HouseholdFields
      }
      remainingHousehold {
        ...HouseholdFields
      }
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;
export type SplitHouseholdMutationFn = Apollo.MutationFunction<
  SplitHouseholdMutation,
  SplitHouseholdMutationVariables
>;

/**
 * __useSplitHouseholdMutation__
 *
 * To run a mutation, you first call `useSplitHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSplitHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [splitHouseholdMutation, { data, loading, error }] = useSplitHouseholdMutation({
 *   variables: {
 *      splittingEnrollmentInputs: // value for 'splittingEnrollmentInputs'
 *   },
 * });
 */
export function useSplitHouseholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SplitHouseholdMutation,
    SplitHouseholdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SplitHouseholdMutation,
    SplitHouseholdMutationVariables
  >(SplitHouseholdDocument, options);
}
export type SplitHouseholdMutationHookResult = ReturnType<
  typeof useSplitHouseholdMutation
>;
export type SplitHouseholdMutationResult =
  Apollo.MutationResult<SplitHouseholdMutation>;
export type SplitHouseholdMutationOptions = Apollo.BaseMutationOptions<
  SplitHouseholdMutation,
  SplitHouseholdMutationVariables
>;
export const GetHouseholdDocument = gql`
  query GetHousehold($id: ID!) {
    household(id: $id) {
      ...HouseholdFields
    }
  }
  ${HouseholdFieldsFragmentDoc}
`;

/**
 * __useGetHouseholdQuery__
 *
 * To run a query within a React component, call `useGetHouseholdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseholdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseholdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHouseholdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHouseholdQuery,
    GetHouseholdQueryVariables
  > &
    (
      | { variables: GetHouseholdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHouseholdQuery, GetHouseholdQueryVariables>(
    GetHouseholdDocument,
    options
  );
}
export function useGetHouseholdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHouseholdQuery,
    GetHouseholdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHouseholdQuery, GetHouseholdQueryVariables>(
    GetHouseholdDocument,
    options
  );
}
export function useGetHouseholdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHouseholdQuery,
    GetHouseholdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetHouseholdQuery, GetHouseholdQueryVariables>(
    GetHouseholdDocument,
    options
  );
}
export type GetHouseholdQueryHookResult = ReturnType<
  typeof useGetHouseholdQuery
>;
export type GetHouseholdLazyQueryHookResult = ReturnType<
  typeof useGetHouseholdLazyQuery
>;
export type GetHouseholdSuspenseQueryHookResult = ReturnType<
  typeof useGetHouseholdSuspenseQuery
>;
export type GetHouseholdQueryResult = Apollo.QueryResult<
  GetHouseholdQuery,
  GetHouseholdQueryVariables
>;
export const UpdateRelationshipToHoHDocument = gql`
  mutation UpdateRelationshipToHoH($input: UpdateRelationshipToHoHInput!) {
    updateRelationshipToHoH(input: $input) {
      clientMutationId
      enrollment {
        id
        relationshipToHoH
        household {
          id
          householdClients {
            id
            relationshipToHoH
            client {
              id
            }
            enrollment {
              id
              lockVersion
            }
          }
        }
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type UpdateRelationshipToHoHMutationFn = Apollo.MutationFunction<
  UpdateRelationshipToHoHMutation,
  UpdateRelationshipToHoHMutationVariables
>;

/**
 * __useUpdateRelationshipToHoHMutation__
 *
 * To run a mutation, you first call `useUpdateRelationshipToHoHMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRelationshipToHoHMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRelationshipToHoHMutation, { data, loading, error }] = useUpdateRelationshipToHoHMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRelationshipToHoHMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRelationshipToHoHMutation,
    UpdateRelationshipToHoHMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRelationshipToHoHMutation,
    UpdateRelationshipToHoHMutationVariables
  >(UpdateRelationshipToHoHDocument, options);
}
export type UpdateRelationshipToHoHMutationHookResult = ReturnType<
  typeof useUpdateRelationshipToHoHMutation
>;
export type UpdateRelationshipToHoHMutationResult =
  Apollo.MutationResult<UpdateRelationshipToHoHMutation>;
export type UpdateRelationshipToHoHMutationOptions = Apollo.BaseMutationOptions<
  UpdateRelationshipToHoHMutation,
  UpdateRelationshipToHoHMutationVariables
>;
export const ClearMciDocument = gql`
  mutation ClearMci($input: ClearMciInput!) {
    clearMci(input: $input) {
      clientMutationId
      matches {
        ...MciMatchFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${MciMatchFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type ClearMciMutationFn = Apollo.MutationFunction<
  ClearMciMutation,
  ClearMciMutationVariables
>;

/**
 * __useClearMciMutation__
 *
 * To run a mutation, you first call `useClearMciMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearMciMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearMciMutation, { data, loading, error }] = useClearMciMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearMciMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearMciMutation,
    ClearMciMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClearMciMutation, ClearMciMutationVariables>(
    ClearMciDocument,
    options
  );
}
export type ClearMciMutationHookResult = ReturnType<typeof useClearMciMutation>;
export type ClearMciMutationResult = Apollo.MutationResult<ClearMciMutation>;
export type ClearMciMutationOptions = Apollo.BaseMutationOptions<
  ClearMciMutation,
  ClearMciMutationVariables
>;
export const OmniSearchClientsDocument = gql`
  query OmniSearchClients($textSearch: String!, $limit: Int) {
    clientOmniSearch(textSearch: $textSearch, limit: $limit, offset: 0) {
      limit
      nodesCount
      nodes {
        id
        ...ClientOmniSearchFields
      }
    }
  }
  ${ClientOmniSearchFieldsFragmentDoc}
`;

/**
 * __useOmniSearchClientsQuery__
 *
 * To run a query within a React component, call `useOmniSearchClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOmniSearchClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOmniSearchClientsQuery({
 *   variables: {
 *      textSearch: // value for 'textSearch'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOmniSearchClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OmniSearchClientsQuery,
    OmniSearchClientsQueryVariables
  > &
    (
      | { variables: OmniSearchClientsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OmniSearchClientsQuery,
    OmniSearchClientsQueryVariables
  >(OmniSearchClientsDocument, options);
}
export function useOmniSearchClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OmniSearchClientsQuery,
    OmniSearchClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OmniSearchClientsQuery,
    OmniSearchClientsQueryVariables
  >(OmniSearchClientsDocument, options);
}
export function useOmniSearchClientsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OmniSearchClientsQuery,
    OmniSearchClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OmniSearchClientsQuery,
    OmniSearchClientsQueryVariables
  >(OmniSearchClientsDocument, options);
}
export type OmniSearchClientsQueryHookResult = ReturnType<
  typeof useOmniSearchClientsQuery
>;
export type OmniSearchClientsLazyQueryHookResult = ReturnType<
  typeof useOmniSearchClientsLazyQuery
>;
export type OmniSearchClientsSuspenseQueryHookResult = ReturnType<
  typeof useOmniSearchClientsSuspenseQuery
>;
export type OmniSearchClientsQueryResult = Apollo.QueryResult<
  OmniSearchClientsQuery,
  OmniSearchClientsQueryVariables
>;
export const OmniSearchProjectsDocument = gql`
  query OmniSearchProjects($searchTerm: String!, $limit: Int) {
    projects(filters: { searchTerm: $searchTerm }, limit: $limit, offset: 0) {
      limit
      nodesCount
      nodes {
        ...ProjectNameAndType
      }
    }
  }
  ${ProjectNameAndTypeFragmentDoc}
`;

/**
 * __useOmniSearchProjectsQuery__
 *
 * To run a query within a React component, call `useOmniSearchProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOmniSearchProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOmniSearchProjectsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOmniSearchProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OmniSearchProjectsQuery,
    OmniSearchProjectsQueryVariables
  > &
    (
      | { variables: OmniSearchProjectsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OmniSearchProjectsQuery,
    OmniSearchProjectsQueryVariables
  >(OmniSearchProjectsDocument, options);
}
export function useOmniSearchProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OmniSearchProjectsQuery,
    OmniSearchProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OmniSearchProjectsQuery,
    OmniSearchProjectsQueryVariables
  >(OmniSearchProjectsDocument, options);
}
export function useOmniSearchProjectsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OmniSearchProjectsQuery,
    OmniSearchProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OmniSearchProjectsQuery,
    OmniSearchProjectsQueryVariables
  >(OmniSearchProjectsDocument, options);
}
export type OmniSearchProjectsQueryHookResult = ReturnType<
  typeof useOmniSearchProjectsQuery
>;
export type OmniSearchProjectsLazyQueryHookResult = ReturnType<
  typeof useOmniSearchProjectsLazyQuery
>;
export type OmniSearchProjectsSuspenseQueryHookResult = ReturnType<
  typeof useOmniSearchProjectsSuspenseQuery
>;
export type OmniSearchProjectsQueryResult = Apollo.QueryResult<
  OmniSearchProjectsQuery,
  OmniSearchProjectsQueryVariables
>;
export const GetRecentItemsDocument = gql`
  query GetRecentItems {
    currentUser {
      id
      recentItems {
        ... on Client {
          ...ClientOmniSearchFields
        }
        ... on Project {
          ...ProjectNameAndType
        }
      }
    }
  }
  ${ClientOmniSearchFieldsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
`;

/**
 * __useGetRecentItemsQuery__
 *
 * To run a query within a React component, call `useGetRecentItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecentItemsQuery,
    GetRecentItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecentItemsQuery, GetRecentItemsQueryVariables>(
    GetRecentItemsDocument,
    options
  );
}
export function useGetRecentItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentItemsQuery,
    GetRecentItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecentItemsQuery, GetRecentItemsQueryVariables>(
    GetRecentItemsDocument,
    options
  );
}
export function useGetRecentItemsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRecentItemsQuery,
    GetRecentItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRecentItemsQuery,
    GetRecentItemsQueryVariables
  >(GetRecentItemsDocument, options);
}
export type GetRecentItemsQueryHookResult = ReturnType<
  typeof useGetRecentItemsQuery
>;
export type GetRecentItemsLazyQueryHookResult = ReturnType<
  typeof useGetRecentItemsLazyQuery
>;
export type GetRecentItemsSuspenseQueryHookResult = ReturnType<
  typeof useGetRecentItemsSuspenseQuery
>;
export type GetRecentItemsQueryResult = Apollo.QueryResult<
  GetRecentItemsQuery,
  GetRecentItemsQueryVariables
>;
export const AddRecentItemDocument = gql`
  mutation AddRecentItem($itemId: ID!, $itemType: RecentItemType!) {
    addRecentItem(input: { itemId: $itemId, itemType: $itemType }) {
      id
      recentItems {
        ... on Client {
          ...ClientOmniSearchFields
        }
        ... on Project {
          ...ProjectNameAndType
        }
      }
    }
  }
  ${ClientOmniSearchFieldsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
`;
export type AddRecentItemMutationFn = Apollo.MutationFunction<
  AddRecentItemMutation,
  AddRecentItemMutationVariables
>;

/**
 * __useAddRecentItemMutation__
 *
 * To run a mutation, you first call `useAddRecentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecentItemMutation, { data, loading, error }] = useAddRecentItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      itemType: // value for 'itemType'
 *   },
 * });
 */
export function useAddRecentItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRecentItemMutation,
    AddRecentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRecentItemMutation,
    AddRecentItemMutationVariables
  >(AddRecentItemDocument, options);
}
export type AddRecentItemMutationHookResult = ReturnType<
  typeof useAddRecentItemMutation
>;
export type AddRecentItemMutationResult =
  Apollo.MutationResult<AddRecentItemMutation>;
export type AddRecentItemMutationOptions = Apollo.BaseMutationOptions<
  AddRecentItemMutation,
  AddRecentItemMutationVariables
>;
export const ClearRecentItemsDocument = gql`
  mutation ClearRecentItems {
    clearRecentItems(input: {}) {
      id
      recentItems {
        ... on Client {
          ...ClientOmniSearchFields
        }
        ... on Project {
          ...ProjectNameAndType
        }
      }
    }
  }
  ${ClientOmniSearchFieldsFragmentDoc}
  ${ProjectNameAndTypeFragmentDoc}
`;
export type ClearRecentItemsMutationFn = Apollo.MutationFunction<
  ClearRecentItemsMutation,
  ClearRecentItemsMutationVariables
>;

/**
 * __useClearRecentItemsMutation__
 *
 * To run a mutation, you first call `useClearRecentItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearRecentItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearRecentItemsMutation, { data, loading, error }] = useClearRecentItemsMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearRecentItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearRecentItemsMutation,
    ClearRecentItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearRecentItemsMutation,
    ClearRecentItemsMutationVariables
  >(ClearRecentItemsDocument, options);
}
export type ClearRecentItemsMutationHookResult = ReturnType<
  typeof useClearRecentItemsMutation
>;
export type ClearRecentItemsMutationResult =
  Apollo.MutationResult<ClearRecentItemsMutation>;
export type ClearRecentItemsMutationOptions = Apollo.BaseMutationOptions<
  ClearRecentItemsMutation,
  ClearRecentItemsMutationVariables
>;
export const GetOrganizationsDocument = gql`
  query GetOrganizations(
    $limit: Int = 10
    $offset: Int = 0
    $filters: OrganizationFilterOptions
  ) {
    organizations(
      limit: $limit
      offset: $offset
      filters: $filters
      sortOrder: NAME
    ) {
      nodesCount
      nodes {
        ...OrganizationNameFields
        projects(limit: 1) {
          nodesCount
        }
      }
    }
  }
  ${OrganizationNameFieldsFragmentDoc}
`;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(
    GetOrganizationsDocument,
    options
  );
}
export function useGetOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >(GetOrganizationsDocument, options);
}
export function useGetOrganizationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >(GetOrganizationsDocument, options);
}
export type GetOrganizationsQueryHookResult = ReturnType<
  typeof useGetOrganizationsQuery
>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationsLazyQuery
>;
export type GetOrganizationsSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationsSuspenseQuery
>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<
  GetOrganizationsQuery,
  GetOrganizationsQueryVariables
>;
export const GetOrganizationDocument = gql`
  query GetOrganization($id: ID!) {
    organization(id: $id) {
      ...OrganizationFields
      access {
        id
        ...OrganizationAccessFields
      }
    }
  }
  ${OrganizationFieldsFragmentDoc}
  ${OrganizationAccessFieldsFragmentDoc}
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  > &
    (
      | { variables: GetOrganizationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options
  );
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GetOrganizationDocument, options);
}
export function useGetOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GetOrganizationDocument, options);
}
export type GetOrganizationQueryHookResult = ReturnType<
  typeof useGetOrganizationQuery
>;
export type GetOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationLazyQuery
>;
export type GetOrganizationSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationSuspenseQuery
>;
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const GetOrganizationProjectsDocument = gql`
  query GetOrganizationProjects(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $filters: ProjectsForEnrollmentFilterOptions
    $sortOrder: ProjectSortOption
  ) {
    organization(id: $id) {
      id
      projects(
        limit: $limit
        offset: $offset
        filters: $filters
        sortOrder: $sortOrder
      ) {
        offset
        limit
        nodesCount
        nodes {
          id
          ...ProjectNameAndType
          ...ProjectOperatingPeriod
        }
      }
    }
  }
  ${ProjectNameAndTypeFragmentDoc}
  ${ProjectOperatingPeriodFragmentDoc}
`;

/**
 * __useGetOrganizationProjectsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetOrganizationProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationProjectsQuery,
    GetOrganizationProjectsQueryVariables
  > &
    (
      | { variables: GetOrganizationProjectsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationProjectsQuery,
    GetOrganizationProjectsQueryVariables
  >(GetOrganizationProjectsDocument, options);
}
export function useGetOrganizationProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationProjectsQuery,
    GetOrganizationProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationProjectsQuery,
    GetOrganizationProjectsQueryVariables
  >(GetOrganizationProjectsDocument, options);
}
export function useGetOrganizationProjectsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationProjectsQuery,
    GetOrganizationProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrganizationProjectsQuery,
    GetOrganizationProjectsQueryVariables
  >(GetOrganizationProjectsDocument, options);
}
export type GetOrganizationProjectsQueryHookResult = ReturnType<
  typeof useGetOrganizationProjectsQuery
>;
export type GetOrganizationProjectsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationProjectsLazyQuery
>;
export type GetOrganizationProjectsSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationProjectsSuspenseQuery
>;
export type GetOrganizationProjectsQueryResult = Apollo.QueryResult<
  GetOrganizationProjectsQuery,
  GetOrganizationProjectsQueryVariables
>;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      clientMutationId
      organization {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationHookResult = ReturnType<
  typeof useDeleteOrganizationMutation
>;
export type DeleteOrganizationMutationResult =
  Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const GetProjectsDocument = gql`
  query GetProjects(
    $limit: Int = 10
    $offset: Int = 0
    $filters: ProjectFilterOptions
    $sortOrder: ProjectSortOption
  ) {
    projects(
      limit: $limit
      offset: $offset
      filters: $filters
      sortOrder: $sortOrder
    ) {
      offset
      limit
      nodesCount
      nodes {
        id
        ...ProjectNameAndType
        ...ProjectOperatingPeriod
        organization {
          ...OrganizationNameFields
        }
      }
    }
  }
  ${ProjectNameAndTypeFragmentDoc}
  ${ProjectOperatingPeriodFragmentDoc}
  ${OrganizationNameFieldsFragmentDoc}
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options
  );
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options
  );
}
export function useGetProjectsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options
  );
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<
  typeof useGetProjectsLazyQuery
>;
export type GetProjectsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectsSuspenseQuery
>;
export type GetProjectsQueryResult = Apollo.QueryResult<
  GetProjectsQuery,
  GetProjectsQueryVariables
>;
export const GetProjectDocument = gql`
  query GetProject($id: ID!) {
    project(id: $id) {
      ...ProjectAllFields
    }
  }
  ${ProjectAllFieldsFragmentDoc}
`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  > &
    (
      | { variables: GetProjectQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export function useGetProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectLazyQuery
>;
export type GetProjectSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectSuspenseQuery
>;
export type GetProjectQueryResult = Apollo.QueryResult<
  GetProjectQuery,
  GetProjectQueryVariables
>;
export const GetProjectPermissionsDocument = gql`
  query GetProjectPermissions($id: ID!) {
    project(id: $id) {
      id
      access {
        ...ProjectAccessFields
      }
    }
  }
  ${ProjectAccessFieldsFragmentDoc}
`;

/**
 * __useGetProjectPermissionsQuery__
 *
 * To run a query within a React component, call `useGetProjectPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectPermissionsQuery,
    GetProjectPermissionsQueryVariables
  > &
    (
      | { variables: GetProjectPermissionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectPermissionsQuery,
    GetProjectPermissionsQueryVariables
  >(GetProjectPermissionsDocument, options);
}
export function useGetProjectPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectPermissionsQuery,
    GetProjectPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectPermissionsQuery,
    GetProjectPermissionsQueryVariables
  >(GetProjectPermissionsDocument, options);
}
export function useGetProjectPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectPermissionsQuery,
    GetProjectPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectPermissionsQuery,
    GetProjectPermissionsQueryVariables
  >(GetProjectPermissionsDocument, options);
}
export type GetProjectPermissionsQueryHookResult = ReturnType<
  typeof useGetProjectPermissionsQuery
>;
export type GetProjectPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectPermissionsLazyQuery
>;
export type GetProjectPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectPermissionsSuspenseQuery
>;
export type GetProjectPermissionsQueryResult = Apollo.QueryResult<
  GetProjectPermissionsQuery,
  GetProjectPermissionsQueryVariables
>;
export const GetProjectEnrollmentsDocument = gql`
  query GetProjectEnrollments(
    $id: ID!
    $filters: EnrollmentsForProjectFilterOptions
    $sortOrder: EnrollmentSortOption
    $limit: Int = 10
    $offset: Int = 0
    $includeCls: Boolean = false
    $includeStaffAssignment: Boolean = false
  ) {
    project(id: $id) {
      id
      enrollments(
        limit: $limit
        offset: $offset
        sortOrder: $sortOrder
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...ProjectEnrollmentQueryEnrollmentFields
        }
      }
    }
  }
  ${ProjectEnrollmentQueryEnrollmentFieldsFragmentDoc}
`;

/**
 * __useGetProjectEnrollmentsQuery__
 *
 * To run a query within a React component, call `useGetProjectEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectEnrollmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filters: // value for 'filters'
 *      sortOrder: // value for 'sortOrder'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      includeCls: // value for 'includeCls'
 *      includeStaffAssignment: // value for 'includeStaffAssignment'
 *   },
 * });
 */
export function useGetProjectEnrollmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectEnrollmentsQuery,
    GetProjectEnrollmentsQueryVariables
  > &
    (
      | { variables: GetProjectEnrollmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectEnrollmentsQuery,
    GetProjectEnrollmentsQueryVariables
  >(GetProjectEnrollmentsDocument, options);
}
export function useGetProjectEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectEnrollmentsQuery,
    GetProjectEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectEnrollmentsQuery,
    GetProjectEnrollmentsQueryVariables
  >(GetProjectEnrollmentsDocument, options);
}
export function useGetProjectEnrollmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectEnrollmentsQuery,
    GetProjectEnrollmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectEnrollmentsQuery,
    GetProjectEnrollmentsQueryVariables
  >(GetProjectEnrollmentsDocument, options);
}
export type GetProjectEnrollmentsQueryHookResult = ReturnType<
  typeof useGetProjectEnrollmentsQuery
>;
export type GetProjectEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useGetProjectEnrollmentsLazyQuery
>;
export type GetProjectEnrollmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectEnrollmentsSuspenseQuery
>;
export type GetProjectEnrollmentsQueryResult = Apollo.QueryResult<
  GetProjectEnrollmentsQuery,
  GetProjectEnrollmentsQueryVariables
>;
export const GetProjectHouseholdsDocument = gql`
  query GetProjectHouseholds(
    $id: ID!
    $filters: HouseholdFilterOptions
    $sortOrder: HouseholdSortOption
    $limit: Int = 10
    $offset: Int = 0
    $includeStaffAssignment: Boolean = false
  ) {
    project(id: $id) {
      id
      households(
        limit: $limit
        offset: $offset
        sortOrder: $sortOrder
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...ProjectEnrollmentsHouseholdFields
        }
      }
    }
  }
  ${ProjectEnrollmentsHouseholdFieldsFragmentDoc}
`;

/**
 * __useGetProjectHouseholdsQuery__
 *
 * To run a query within a React component, call `useGetProjectHouseholdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectHouseholdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectHouseholdsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filters: // value for 'filters'
 *      sortOrder: // value for 'sortOrder'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      includeStaffAssignment: // value for 'includeStaffAssignment'
 *   },
 * });
 */
export function useGetProjectHouseholdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectHouseholdsQuery,
    GetProjectHouseholdsQueryVariables
  > &
    (
      | { variables: GetProjectHouseholdsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectHouseholdsQuery,
    GetProjectHouseholdsQueryVariables
  >(GetProjectHouseholdsDocument, options);
}
export function useGetProjectHouseholdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectHouseholdsQuery,
    GetProjectHouseholdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectHouseholdsQuery,
    GetProjectHouseholdsQueryVariables
  >(GetProjectHouseholdsDocument, options);
}
export function useGetProjectHouseholdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectHouseholdsQuery,
    GetProjectHouseholdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectHouseholdsQuery,
    GetProjectHouseholdsQueryVariables
  >(GetProjectHouseholdsDocument, options);
}
export type GetProjectHouseholdsQueryHookResult = ReturnType<
  typeof useGetProjectHouseholdsQuery
>;
export type GetProjectHouseholdsLazyQueryHookResult = ReturnType<
  typeof useGetProjectHouseholdsLazyQuery
>;
export type GetProjectHouseholdsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectHouseholdsSuspenseQuery
>;
export type GetProjectHouseholdsQueryResult = Apollo.QueryResult<
  GetProjectHouseholdsQuery,
  GetProjectHouseholdsQueryVariables
>;
export const GetProjectServicesDocument = gql`
  query GetProjectServices(
    $id: ID!
    $filters: ServicesForProjectFilterOptions
    $sortOrder: ServiceSortOption
    $limit: Int = 10
    $offset: Int = 0
  ) {
    project(id: $id) {
      id
      services(
        limit: $limit
        offset: $offset
        sortOrder: $sortOrder
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...ServiceBasicFields
          enrollment {
            id
            ...EnrollmentRangeFields
            client {
              ...ClientNameDobVet
            }
          }
        }
      }
    }
  }
  ${ServiceBasicFieldsFragmentDoc}
  ${EnrollmentRangeFieldsFragmentDoc}
  ${ClientNameDobVetFragmentDoc}
`;

/**
 * __useGetProjectServicesQuery__
 *
 * To run a query within a React component, call `useGetProjectServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectServicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filters: // value for 'filters'
 *      sortOrder: // value for 'sortOrder'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProjectServicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectServicesQuery,
    GetProjectServicesQueryVariables
  > &
    (
      | { variables: GetProjectServicesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectServicesQuery,
    GetProjectServicesQueryVariables
  >(GetProjectServicesDocument, options);
}
export function useGetProjectServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectServicesQuery,
    GetProjectServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectServicesQuery,
    GetProjectServicesQueryVariables
  >(GetProjectServicesDocument, options);
}
export function useGetProjectServicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectServicesQuery,
    GetProjectServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectServicesQuery,
    GetProjectServicesQueryVariables
  >(GetProjectServicesDocument, options);
}
export type GetProjectServicesQueryHookResult = ReturnType<
  typeof useGetProjectServicesQuery
>;
export type GetProjectServicesLazyQueryHookResult = ReturnType<
  typeof useGetProjectServicesLazyQuery
>;
export type GetProjectServicesSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectServicesSuspenseQuery
>;
export type GetProjectServicesQueryResult = Apollo.QueryResult<
  GetProjectServicesQuery,
  GetProjectServicesQueryVariables
>;
export const GetProjectAssessmentsDocument = gql`
  query GetProjectAssessments(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $sortOrder: AssessmentSortOption = ASSESSMENT_DATE
    $filters: AssessmentsForProjectFilterOptions = null
  ) {
    project(id: $id) {
      id
      assessments(
        limit: $limit
        offset: $offset
        sortOrder: $sortOrder
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...AssessmentFields
          enrollment {
            ...EnrollmentFields
          }
        }
      }
    }
  }
  ${AssessmentFieldsFragmentDoc}
  ${EnrollmentFieldsFragmentDoc}
`;

/**
 * __useGetProjectAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetProjectAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAssessmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProjectAssessmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectAssessmentsQuery,
    GetProjectAssessmentsQueryVariables
  > &
    (
      | { variables: GetProjectAssessmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectAssessmentsQuery,
    GetProjectAssessmentsQueryVariables
  >(GetProjectAssessmentsDocument, options);
}
export function useGetProjectAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectAssessmentsQuery,
    GetProjectAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectAssessmentsQuery,
    GetProjectAssessmentsQueryVariables
  >(GetProjectAssessmentsDocument, options);
}
export function useGetProjectAssessmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectAssessmentsQuery,
    GetProjectAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectAssessmentsQuery,
    GetProjectAssessmentsQueryVariables
  >(GetProjectAssessmentsDocument, options);
}
export type GetProjectAssessmentsQueryHookResult = ReturnType<
  typeof useGetProjectAssessmentsQuery
>;
export type GetProjectAssessmentsLazyQueryHookResult = ReturnType<
  typeof useGetProjectAssessmentsLazyQuery
>;
export type GetProjectAssessmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectAssessmentsSuspenseQuery
>;
export type GetProjectAssessmentsQueryResult = Apollo.QueryResult<
  GetProjectAssessmentsQuery,
  GetProjectAssessmentsQueryVariables
>;
export const DeleteProjectDocument = gql`
  mutation DeleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      clientMutationId
      project {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options);
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>;
export type DeleteProjectMutationResult =
  Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const GetFunderDocument = gql`
  query GetFunder($id: ID!) {
    funder(id: $id) {
      ...FunderFields
    }
  }
  ${FunderFieldsFragmentDoc}
`;

/**
 * __useGetFunderQuery__
 *
 * To run a query within a React component, call `useGetFunderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFunderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFunderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFunderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFunderQuery,
    GetFunderQueryVariables
  > &
    ({ variables: GetFunderQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFunderQuery, GetFunderQueryVariables>(
    GetFunderDocument,
    options
  );
}
export function useGetFunderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFunderQuery,
    GetFunderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFunderQuery, GetFunderQueryVariables>(
    GetFunderDocument,
    options
  );
}
export function useGetFunderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFunderQuery,
    GetFunderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFunderQuery, GetFunderQueryVariables>(
    GetFunderDocument,
    options
  );
}
export type GetFunderQueryHookResult = ReturnType<typeof useGetFunderQuery>;
export type GetFunderLazyQueryHookResult = ReturnType<
  typeof useGetFunderLazyQuery
>;
export type GetFunderSuspenseQueryHookResult = ReturnType<
  typeof useGetFunderSuspenseQuery
>;
export type GetFunderQueryResult = Apollo.QueryResult<
  GetFunderQuery,
  GetFunderQueryVariables
>;
export const GetInventoryDocument = gql`
  query GetInventory($id: ID!) {
    inventory(id: $id) {
      ...InventoryFields
    }
  }
  ${InventoryFieldsFragmentDoc}
`;

/**
 * __useGetInventoryQuery__
 *
 * To run a query within a React component, call `useGetInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInventoryQuery,
    GetInventoryQueryVariables
  > &
    (
      | { variables: GetInventoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInventoryQuery, GetInventoryQueryVariables>(
    GetInventoryDocument,
    options
  );
}
export function useGetInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInventoryQuery,
    GetInventoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInventoryQuery, GetInventoryQueryVariables>(
    GetInventoryDocument,
    options
  );
}
export function useGetInventorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInventoryQuery,
    GetInventoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInventoryQuery, GetInventoryQueryVariables>(
    GetInventoryDocument,
    options
  );
}
export type GetInventoryQueryHookResult = ReturnType<
  typeof useGetInventoryQuery
>;
export type GetInventoryLazyQueryHookResult = ReturnType<
  typeof useGetInventoryLazyQuery
>;
export type GetInventorySuspenseQueryHookResult = ReturnType<
  typeof useGetInventorySuspenseQuery
>;
export type GetInventoryQueryResult = Apollo.QueryResult<
  GetInventoryQuery,
  GetInventoryQueryVariables
>;
export const GetProjectCocDocument = gql`
  query GetProjectCoc($id: ID!) {
    projectCoc(id: $id) {
      ...ProjectCocFields
    }
  }
  ${ProjectCocFieldsFragmentDoc}
`;

/**
 * __useGetProjectCocQuery__
 *
 * To run a query within a React component, call `useGetProjectCocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCocQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectCocQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectCocQuery,
    GetProjectCocQueryVariables
  > &
    (
      | { variables: GetProjectCocQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectCocQuery, GetProjectCocQueryVariables>(
    GetProjectCocDocument,
    options
  );
}
export function useGetProjectCocLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCocQuery,
    GetProjectCocQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectCocQuery, GetProjectCocQueryVariables>(
    GetProjectCocDocument,
    options
  );
}
export function useGetProjectCocSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectCocQuery,
    GetProjectCocQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectCocQuery,
    GetProjectCocQueryVariables
  >(GetProjectCocDocument, options);
}
export type GetProjectCocQueryHookResult = ReturnType<
  typeof useGetProjectCocQuery
>;
export type GetProjectCocLazyQueryHookResult = ReturnType<
  typeof useGetProjectCocLazyQuery
>;
export type GetProjectCocSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectCocSuspenseQuery
>;
export type GetProjectCocQueryResult = Apollo.QueryResult<
  GetProjectCocQuery,
  GetProjectCocQueryVariables
>;
export const GetProjectInventoriesDocument = gql`
  query GetProjectInventories($id: ID!, $limit: Int = 10, $offset: Int = 0) {
    project(id: $id) {
      id
      inventories(limit: $limit, offset: $offset, sortOrder: START_DATE) {
        offset
        limit
        nodesCount
        nodes {
          ...InventoryFields
        }
      }
    }
  }
  ${InventoryFieldsFragmentDoc}
`;

/**
 * __useGetProjectInventoriesQuery__
 *
 * To run a query within a React component, call `useGetProjectInventoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectInventoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectInventoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProjectInventoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectInventoriesQuery,
    GetProjectInventoriesQueryVariables
  > &
    (
      | { variables: GetProjectInventoriesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectInventoriesQuery,
    GetProjectInventoriesQueryVariables
  >(GetProjectInventoriesDocument, options);
}
export function useGetProjectInventoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectInventoriesQuery,
    GetProjectInventoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectInventoriesQuery,
    GetProjectInventoriesQueryVariables
  >(GetProjectInventoriesDocument, options);
}
export function useGetProjectInventoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectInventoriesQuery,
    GetProjectInventoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectInventoriesQuery,
    GetProjectInventoriesQueryVariables
  >(GetProjectInventoriesDocument, options);
}
export type GetProjectInventoriesQueryHookResult = ReturnType<
  typeof useGetProjectInventoriesQuery
>;
export type GetProjectInventoriesLazyQueryHookResult = ReturnType<
  typeof useGetProjectInventoriesLazyQuery
>;
export type GetProjectInventoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectInventoriesSuspenseQuery
>;
export type GetProjectInventoriesQueryResult = Apollo.QueryResult<
  GetProjectInventoriesQuery,
  GetProjectInventoriesQueryVariables
>;
export const GetProjectReferralRequestsDocument = gql`
  query GetProjectReferralRequests(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    project(id: $id) {
      id
      referralRequests(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...ReferralRequestFields
        }
      }
    }
  }
  ${ReferralRequestFieldsFragmentDoc}
`;

/**
 * __useGetProjectReferralRequestsQuery__
 *
 * To run a query within a React component, call `useGetProjectReferralRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectReferralRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectReferralRequestsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProjectReferralRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectReferralRequestsQuery,
    GetProjectReferralRequestsQueryVariables
  > &
    (
      | { variables: GetProjectReferralRequestsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectReferralRequestsQuery,
    GetProjectReferralRequestsQueryVariables
  >(GetProjectReferralRequestsDocument, options);
}
export function useGetProjectReferralRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectReferralRequestsQuery,
    GetProjectReferralRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectReferralRequestsQuery,
    GetProjectReferralRequestsQueryVariables
  >(GetProjectReferralRequestsDocument, options);
}
export function useGetProjectReferralRequestsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectReferralRequestsQuery,
    GetProjectReferralRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectReferralRequestsQuery,
    GetProjectReferralRequestsQueryVariables
  >(GetProjectReferralRequestsDocument, options);
}
export type GetProjectReferralRequestsQueryHookResult = ReturnType<
  typeof useGetProjectReferralRequestsQuery
>;
export type GetProjectReferralRequestsLazyQueryHookResult = ReturnType<
  typeof useGetProjectReferralRequestsLazyQuery
>;
export type GetProjectReferralRequestsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectReferralRequestsSuspenseQuery
>;
export type GetProjectReferralRequestsQueryResult = Apollo.QueryResult<
  GetProjectReferralRequestsQuery,
  GetProjectReferralRequestsQueryVariables
>;
export const GetProjectReferralPostingsDocument = gql`
  query GetProjectReferralPostings(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $filters: ReferralPostingFilterOptions
  ) {
    project(id: $id) {
      id
      incomingReferralPostings(
        limit: $limit
        offset: $offset
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...ReferralPostingFields
        }
      }
    }
  }
  ${ReferralPostingFieldsFragmentDoc}
`;

/**
 * __useGetProjectReferralPostingsQuery__
 *
 * To run a query within a React component, call `useGetProjectReferralPostingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectReferralPostingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectReferralPostingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProjectReferralPostingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectReferralPostingsQuery,
    GetProjectReferralPostingsQueryVariables
  > &
    (
      | { variables: GetProjectReferralPostingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectReferralPostingsQuery,
    GetProjectReferralPostingsQueryVariables
  >(GetProjectReferralPostingsDocument, options);
}
export function useGetProjectReferralPostingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectReferralPostingsQuery,
    GetProjectReferralPostingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectReferralPostingsQuery,
    GetProjectReferralPostingsQueryVariables
  >(GetProjectReferralPostingsDocument, options);
}
export function useGetProjectReferralPostingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectReferralPostingsQuery,
    GetProjectReferralPostingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectReferralPostingsQuery,
    GetProjectReferralPostingsQueryVariables
  >(GetProjectReferralPostingsDocument, options);
}
export type GetProjectReferralPostingsQueryHookResult = ReturnType<
  typeof useGetProjectReferralPostingsQuery
>;
export type GetProjectReferralPostingsLazyQueryHookResult = ReturnType<
  typeof useGetProjectReferralPostingsLazyQuery
>;
export type GetProjectReferralPostingsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectReferralPostingsSuspenseQuery
>;
export type GetProjectReferralPostingsQueryResult = Apollo.QueryResult<
  GetProjectReferralPostingsQuery,
  GetProjectReferralPostingsQueryVariables
>;
export const GetProjectOutgoingReferralPostingsDocument = gql`
  query GetProjectOutgoingReferralPostings(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $filters: ReferralPostingFilterOptions
  ) {
    project(id: $id) {
      id
      outgoingReferralPostings(
        limit: $limit
        offset: $offset
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...ReferralPostingFields
          hohEnrollment {
            id
            client {
              id
            }
          }
        }
      }
    }
  }
  ${ReferralPostingFieldsFragmentDoc}
`;

/**
 * __useGetProjectOutgoingReferralPostingsQuery__
 *
 * To run a query within a React component, call `useGetProjectOutgoingReferralPostingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOutgoingReferralPostingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOutgoingReferralPostingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProjectOutgoingReferralPostingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectOutgoingReferralPostingsQuery,
    GetProjectOutgoingReferralPostingsQueryVariables
  > &
    (
      | {
          variables: GetProjectOutgoingReferralPostingsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectOutgoingReferralPostingsQuery,
    GetProjectOutgoingReferralPostingsQueryVariables
  >(GetProjectOutgoingReferralPostingsDocument, options);
}
export function useGetProjectOutgoingReferralPostingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectOutgoingReferralPostingsQuery,
    GetProjectOutgoingReferralPostingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectOutgoingReferralPostingsQuery,
    GetProjectOutgoingReferralPostingsQueryVariables
  >(GetProjectOutgoingReferralPostingsDocument, options);
}
export function useGetProjectOutgoingReferralPostingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectOutgoingReferralPostingsQuery,
    GetProjectOutgoingReferralPostingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectOutgoingReferralPostingsQuery,
    GetProjectOutgoingReferralPostingsQueryVariables
  >(GetProjectOutgoingReferralPostingsDocument, options);
}
export type GetProjectOutgoingReferralPostingsQueryHookResult = ReturnType<
  typeof useGetProjectOutgoingReferralPostingsQuery
>;
export type GetProjectOutgoingReferralPostingsLazyQueryHookResult = ReturnType<
  typeof useGetProjectOutgoingReferralPostingsLazyQuery
>;
export type GetProjectOutgoingReferralPostingsSuspenseQueryHookResult =
  ReturnType<typeof useGetProjectOutgoingReferralPostingsSuspenseQuery>;
export type GetProjectOutgoingReferralPostingsQueryResult = Apollo.QueryResult<
  GetProjectOutgoingReferralPostingsQuery,
  GetProjectOutgoingReferralPostingsQueryVariables
>;
export const GetProjectProjectCocsDocument = gql`
  query GetProjectProjectCocs($id: ID!, $limit: Int = 10, $offset: Int = 0) {
    project(id: $id) {
      id
      projectCocs(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...ProjectCocFields
        }
      }
    }
  }
  ${ProjectCocFieldsFragmentDoc}
`;

/**
 * __useGetProjectProjectCocsQuery__
 *
 * To run a query within a React component, call `useGetProjectProjectCocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectProjectCocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectProjectCocsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProjectProjectCocsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectProjectCocsQuery,
    GetProjectProjectCocsQueryVariables
  > &
    (
      | { variables: GetProjectProjectCocsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectProjectCocsQuery,
    GetProjectProjectCocsQueryVariables
  >(GetProjectProjectCocsDocument, options);
}
export function useGetProjectProjectCocsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectProjectCocsQuery,
    GetProjectProjectCocsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectProjectCocsQuery,
    GetProjectProjectCocsQueryVariables
  >(GetProjectProjectCocsDocument, options);
}
export function useGetProjectProjectCocsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectProjectCocsQuery,
    GetProjectProjectCocsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectProjectCocsQuery,
    GetProjectProjectCocsQueryVariables
  >(GetProjectProjectCocsDocument, options);
}
export type GetProjectProjectCocsQueryHookResult = ReturnType<
  typeof useGetProjectProjectCocsQuery
>;
export type GetProjectProjectCocsLazyQueryHookResult = ReturnType<
  typeof useGetProjectProjectCocsLazyQuery
>;
export type GetProjectProjectCocsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectProjectCocsSuspenseQuery
>;
export type GetProjectProjectCocsQueryResult = Apollo.QueryResult<
  GetProjectProjectCocsQuery,
  GetProjectProjectCocsQueryVariables
>;
export const GetProjectFundersDocument = gql`
  query GetProjectFunders($id: ID!, $limit: Int = 10, $offset: Int = 0) {
    project(id: $id) {
      id
      funders(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...FunderFields
        }
      }
    }
  }
  ${FunderFieldsFragmentDoc}
`;

/**
 * __useGetProjectFundersQuery__
 *
 * To run a query within a React component, call `useGetProjectFundersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFundersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFundersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProjectFundersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectFundersQuery,
    GetProjectFundersQueryVariables
  > &
    (
      | { variables: GetProjectFundersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectFundersQuery,
    GetProjectFundersQueryVariables
  >(GetProjectFundersDocument, options);
}
export function useGetProjectFundersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectFundersQuery,
    GetProjectFundersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectFundersQuery,
    GetProjectFundersQueryVariables
  >(GetProjectFundersDocument, options);
}
export function useGetProjectFundersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectFundersQuery,
    GetProjectFundersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectFundersQuery,
    GetProjectFundersQueryVariables
  >(GetProjectFundersDocument, options);
}
export type GetProjectFundersQueryHookResult = ReturnType<
  typeof useGetProjectFundersQuery
>;
export type GetProjectFundersLazyQueryHookResult = ReturnType<
  typeof useGetProjectFundersLazyQuery
>;
export type GetProjectFundersSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectFundersSuspenseQuery
>;
export type GetProjectFundersQueryResult = Apollo.QueryResult<
  GetProjectFundersQuery,
  GetProjectFundersQueryVariables
>;
export const GetProjectCeParticipationsDocument = gql`
  query GetProjectCeParticipations(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    project(id: $id) {
      id
      ceParticipations(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...CeParticipationFields
        }
      }
    }
  }
  ${CeParticipationFieldsFragmentDoc}
`;

/**
 * __useGetProjectCeParticipationsQuery__
 *
 * To run a query within a React component, call `useGetProjectCeParticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCeParticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCeParticipationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProjectCeParticipationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectCeParticipationsQuery,
    GetProjectCeParticipationsQueryVariables
  > &
    (
      | { variables: GetProjectCeParticipationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectCeParticipationsQuery,
    GetProjectCeParticipationsQueryVariables
  >(GetProjectCeParticipationsDocument, options);
}
export function useGetProjectCeParticipationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCeParticipationsQuery,
    GetProjectCeParticipationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectCeParticipationsQuery,
    GetProjectCeParticipationsQueryVariables
  >(GetProjectCeParticipationsDocument, options);
}
export function useGetProjectCeParticipationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectCeParticipationsQuery,
    GetProjectCeParticipationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectCeParticipationsQuery,
    GetProjectCeParticipationsQueryVariables
  >(GetProjectCeParticipationsDocument, options);
}
export type GetProjectCeParticipationsQueryHookResult = ReturnType<
  typeof useGetProjectCeParticipationsQuery
>;
export type GetProjectCeParticipationsLazyQueryHookResult = ReturnType<
  typeof useGetProjectCeParticipationsLazyQuery
>;
export type GetProjectCeParticipationsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectCeParticipationsSuspenseQuery
>;
export type GetProjectCeParticipationsQueryResult = Apollo.QueryResult<
  GetProjectCeParticipationsQuery,
  GetProjectCeParticipationsQueryVariables
>;
export const GetProjectHmisParticipationsDocument = gql`
  query GetProjectHmisParticipations(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    project(id: $id) {
      id
      hmisParticipations(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...HmisParticipationFields
        }
      }
    }
  }
  ${HmisParticipationFieldsFragmentDoc}
`;

/**
 * __useGetProjectHmisParticipationsQuery__
 *
 * To run a query within a React component, call `useGetProjectHmisParticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectHmisParticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectHmisParticipationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProjectHmisParticipationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectHmisParticipationsQuery,
    GetProjectHmisParticipationsQueryVariables
  > &
    (
      | {
          variables: GetProjectHmisParticipationsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectHmisParticipationsQuery,
    GetProjectHmisParticipationsQueryVariables
  >(GetProjectHmisParticipationsDocument, options);
}
export function useGetProjectHmisParticipationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectHmisParticipationsQuery,
    GetProjectHmisParticipationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectHmisParticipationsQuery,
    GetProjectHmisParticipationsQueryVariables
  >(GetProjectHmisParticipationsDocument, options);
}
export function useGetProjectHmisParticipationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectHmisParticipationsQuery,
    GetProjectHmisParticipationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectHmisParticipationsQuery,
    GetProjectHmisParticipationsQueryVariables
  >(GetProjectHmisParticipationsDocument, options);
}
export type GetProjectHmisParticipationsQueryHookResult = ReturnType<
  typeof useGetProjectHmisParticipationsQuery
>;
export type GetProjectHmisParticipationsLazyQueryHookResult = ReturnType<
  typeof useGetProjectHmisParticipationsLazyQuery
>;
export type GetProjectHmisParticipationsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectHmisParticipationsSuspenseQuery
>;
export type GetProjectHmisParticipationsQueryResult = Apollo.QueryResult<
  GetProjectHmisParticipationsQuery,
  GetProjectHmisParticipationsQueryVariables
>;
export const DeleteInventoryDocument = gql`
  mutation DeleteInventory($input: DeleteInventoryInput!) {
    deleteInventory(input: $input) {
      clientMutationId
      inventory {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteInventoryMutationFn = Apollo.MutationFunction<
  DeleteInventoryMutation,
  DeleteInventoryMutationVariables
>;

/**
 * __useDeleteInventoryMutation__
 *
 * To run a mutation, you first call `useDeleteInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInventoryMutation, { data, loading, error }] = useDeleteInventoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInventoryMutation,
    DeleteInventoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInventoryMutation,
    DeleteInventoryMutationVariables
  >(DeleteInventoryDocument, options);
}
export type DeleteInventoryMutationHookResult = ReturnType<
  typeof useDeleteInventoryMutation
>;
export type DeleteInventoryMutationResult =
  Apollo.MutationResult<DeleteInventoryMutation>;
export type DeleteInventoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteInventoryMutation,
  DeleteInventoryMutationVariables
>;
export const DeleteFunderDocument = gql`
  mutation DeleteFunder($input: DeleteFunderInput!) {
    deleteFunder(input: $input) {
      clientMutationId
      funder {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteFunderMutationFn = Apollo.MutationFunction<
  DeleteFunderMutation,
  DeleteFunderMutationVariables
>;

/**
 * __useDeleteFunderMutation__
 *
 * To run a mutation, you first call `useDeleteFunderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFunderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFunderMutation, { data, loading, error }] = useDeleteFunderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFunderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFunderMutation,
    DeleteFunderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFunderMutation,
    DeleteFunderMutationVariables
  >(DeleteFunderDocument, options);
}
export type DeleteFunderMutationHookResult = ReturnType<
  typeof useDeleteFunderMutation
>;
export type DeleteFunderMutationResult =
  Apollo.MutationResult<DeleteFunderMutation>;
export type DeleteFunderMutationOptions = Apollo.BaseMutationOptions<
  DeleteFunderMutation,
  DeleteFunderMutationVariables
>;
export const DeleteProjectCocDocument = gql`
  mutation DeleteProjectCoc($input: DeleteProjectCocInput!) {
    deleteProjectCoc(input: $input) {
      clientMutationId
      projectCoc {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteProjectCocMutationFn = Apollo.MutationFunction<
  DeleteProjectCocMutation,
  DeleteProjectCocMutationVariables
>;

/**
 * __useDeleteProjectCocMutation__
 *
 * To run a mutation, you first call `useDeleteProjectCocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectCocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectCocMutation, { data, loading, error }] = useDeleteProjectCocMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectCocMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectCocMutation,
    DeleteProjectCocMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectCocMutation,
    DeleteProjectCocMutationVariables
  >(DeleteProjectCocDocument, options);
}
export type DeleteProjectCocMutationHookResult = ReturnType<
  typeof useDeleteProjectCocMutation
>;
export type DeleteProjectCocMutationResult =
  Apollo.MutationResult<DeleteProjectCocMutation>;
export type DeleteProjectCocMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectCocMutation,
  DeleteProjectCocMutationVariables
>;
export const DeleteHmisParticipationDocument = gql`
  mutation DeleteHmisParticipation($id: ID!) {
    deleteHmisParticipation(input: { id: $id }) {
      hmisParticipation {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteHmisParticipationMutationFn = Apollo.MutationFunction<
  DeleteHmisParticipationMutation,
  DeleteHmisParticipationMutationVariables
>;

/**
 * __useDeleteHmisParticipationMutation__
 *
 * To run a mutation, you first call `useDeleteHmisParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHmisParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHmisParticipationMutation, { data, loading, error }] = useDeleteHmisParticipationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHmisParticipationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteHmisParticipationMutation,
    DeleteHmisParticipationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteHmisParticipationMutation,
    DeleteHmisParticipationMutationVariables
  >(DeleteHmisParticipationDocument, options);
}
export type DeleteHmisParticipationMutationHookResult = ReturnType<
  typeof useDeleteHmisParticipationMutation
>;
export type DeleteHmisParticipationMutationResult =
  Apollo.MutationResult<DeleteHmisParticipationMutation>;
export type DeleteHmisParticipationMutationOptions = Apollo.BaseMutationOptions<
  DeleteHmisParticipationMutation,
  DeleteHmisParticipationMutationVariables
>;
export const DeleteCeParticipationDocument = gql`
  mutation DeleteCeParticipation($id: ID!) {
    deleteCeParticipation(input: { id: $id }) {
      ceParticipation {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteCeParticipationMutationFn = Apollo.MutationFunction<
  DeleteCeParticipationMutation,
  DeleteCeParticipationMutationVariables
>;

/**
 * __useDeleteCeParticipationMutation__
 *
 * To run a mutation, you first call `useDeleteCeParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCeParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCeParticipationMutation, { data, loading, error }] = useDeleteCeParticipationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCeParticipationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCeParticipationMutation,
    DeleteCeParticipationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCeParticipationMutation,
    DeleteCeParticipationMutationVariables
  >(DeleteCeParticipationDocument, options);
}
export type DeleteCeParticipationMutationHookResult = ReturnType<
  typeof useDeleteCeParticipationMutation
>;
export type DeleteCeParticipationMutationResult =
  Apollo.MutationResult<DeleteCeParticipationMutation>;
export type DeleteCeParticipationMutationOptions = Apollo.BaseMutationOptions<
  DeleteCeParticipationMutation,
  DeleteCeParticipationMutationVariables
>;
export const CreateProjectConfigDocument = gql`
  mutation CreateProjectConfig($input: ProjectConfigInput!) {
    createProjectConfig(input: $input) {
      projectConfig {
        ...ProjectConfigFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ProjectConfigFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type CreateProjectConfigMutationFn = Apollo.MutationFunction<
  CreateProjectConfigMutation,
  CreateProjectConfigMutationVariables
>;

/**
 * __useCreateProjectConfigMutation__
 *
 * To run a mutation, you first call `useCreateProjectConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectConfigMutation, { data, loading, error }] = useCreateProjectConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectConfigMutation,
    CreateProjectConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectConfigMutation,
    CreateProjectConfigMutationVariables
  >(CreateProjectConfigDocument, options);
}
export type CreateProjectConfigMutationHookResult = ReturnType<
  typeof useCreateProjectConfigMutation
>;
export type CreateProjectConfigMutationResult =
  Apollo.MutationResult<CreateProjectConfigMutation>;
export type CreateProjectConfigMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectConfigMutation,
  CreateProjectConfigMutationVariables
>;
export const UpdateProjectConfigDocument = gql`
  mutation UpdateProjectConfig($input: ProjectConfigInput!, $id: ID!) {
    updateProjectConfig(input: $input, id: $id) {
      projectConfig {
        ...ProjectConfigFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ProjectConfigFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type UpdateProjectConfigMutationFn = Apollo.MutationFunction<
  UpdateProjectConfigMutation,
  UpdateProjectConfigMutationVariables
>;

/**
 * __useUpdateProjectConfigMutation__
 *
 * To run a mutation, you first call `useUpdateProjectConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectConfigMutation, { data, loading, error }] = useUpdateProjectConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProjectConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectConfigMutation,
    UpdateProjectConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectConfigMutation,
    UpdateProjectConfigMutationVariables
  >(UpdateProjectConfigDocument, options);
}
export type UpdateProjectConfigMutationHookResult = ReturnType<
  typeof useUpdateProjectConfigMutation
>;
export type UpdateProjectConfigMutationResult =
  Apollo.MutationResult<UpdateProjectConfigMutation>;
export type UpdateProjectConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectConfigMutation,
  UpdateProjectConfigMutationVariables
>;
export const DeleteProjectConfigDocument = gql`
  mutation DeleteProjectConfig($id: ID!) {
    deleteProjectConfig(id: $id) {
      projectConfig {
        ...ProjectConfigFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ProjectConfigFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteProjectConfigMutationFn = Apollo.MutationFunction<
  DeleteProjectConfigMutation,
  DeleteProjectConfigMutationVariables
>;

/**
 * __useDeleteProjectConfigMutation__
 *
 * To run a mutation, you first call `useDeleteProjectConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectConfigMutation, { data, loading, error }] = useDeleteProjectConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectConfigMutation,
    DeleteProjectConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectConfigMutation,
    DeleteProjectConfigMutationVariables
  >(DeleteProjectConfigDocument, options);
}
export type DeleteProjectConfigMutationHookResult = ReturnType<
  typeof useDeleteProjectConfigMutation
>;
export type DeleteProjectConfigMutationResult =
  Apollo.MutationResult<DeleteProjectConfigMutation>;
export type DeleteProjectConfigMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectConfigMutation,
  DeleteProjectConfigMutationVariables
>;
export const GetProjectConfigsDocument = gql`
  query GetProjectConfigs($limit: Int = 10, $offset: Int = 0) {
    projectConfigs(limit: $limit, offset: $offset) {
      offset
      limit
      nodesCount
      nodes {
        ...ProjectConfigFields
      }
    }
  }
  ${ProjectConfigFieldsFragmentDoc}
`;

/**
 * __useGetProjectConfigsQuery__
 *
 * To run a query within a React component, call `useGetProjectConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectConfigsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetProjectConfigsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectConfigsQuery,
    GetProjectConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectConfigsQuery,
    GetProjectConfigsQueryVariables
  >(GetProjectConfigsDocument, options);
}
export function useGetProjectConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectConfigsQuery,
    GetProjectConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectConfigsQuery,
    GetProjectConfigsQueryVariables
  >(GetProjectConfigsDocument, options);
}
export function useGetProjectConfigsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectConfigsQuery,
    GetProjectConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectConfigsQuery,
    GetProjectConfigsQueryVariables
  >(GetProjectConfigsDocument, options);
}
export type GetProjectConfigsQueryHookResult = ReturnType<
  typeof useGetProjectConfigsQuery
>;
export type GetProjectConfigsLazyQueryHookResult = ReturnType<
  typeof useGetProjectConfigsLazyQuery
>;
export type GetProjectConfigsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectConfigsSuspenseQuery
>;
export type GetProjectConfigsQueryResult = Apollo.QueryResult<
  GetProjectConfigsQuery,
  GetProjectConfigsQueryVariables
>;
export const VoidReferralRequestDocument = gql`
  mutation VoidReferralRequest($id: ID!) {
    voidReferralRequest(referralRequestId: $id) {
      record {
        id
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type VoidReferralRequestMutationFn = Apollo.MutationFunction<
  VoidReferralRequestMutation,
  VoidReferralRequestMutationVariables
>;

/**
 * __useVoidReferralRequestMutation__
 *
 * To run a mutation, you first call `useVoidReferralRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidReferralRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidReferralRequestMutation, { data, loading, error }] = useVoidReferralRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVoidReferralRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoidReferralRequestMutation,
    VoidReferralRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VoidReferralRequestMutation,
    VoidReferralRequestMutationVariables
  >(VoidReferralRequestDocument, options);
}
export type VoidReferralRequestMutationHookResult = ReturnType<
  typeof useVoidReferralRequestMutation
>;
export type VoidReferralRequestMutationResult =
  Apollo.MutationResult<VoidReferralRequestMutation>;
export type VoidReferralRequestMutationOptions = Apollo.BaseMutationOptions<
  VoidReferralRequestMutation,
  VoidReferralRequestMutationVariables
>;
export const UpdateReferralPostingDocument = gql`
  mutation UpdateReferralPosting($id: ID!, $input: ReferralPostingInput!) {
    updateReferralPosting(id: $id, input: $input) {
      record {
        ...ReferralPostingDetailFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ReferralPostingDetailFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type UpdateReferralPostingMutationFn = Apollo.MutationFunction<
  UpdateReferralPostingMutation,
  UpdateReferralPostingMutationVariables
>;

/**
 * __useUpdateReferralPostingMutation__
 *
 * To run a mutation, you first call `useUpdateReferralPostingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralPostingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralPostingMutation, { data, loading, error }] = useUpdateReferralPostingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferralPostingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReferralPostingMutation,
    UpdateReferralPostingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReferralPostingMutation,
    UpdateReferralPostingMutationVariables
  >(UpdateReferralPostingDocument, options);
}
export type UpdateReferralPostingMutationHookResult = ReturnType<
  typeof useUpdateReferralPostingMutation
>;
export type UpdateReferralPostingMutationResult =
  Apollo.MutationResult<UpdateReferralPostingMutation>;
export type UpdateReferralPostingMutationOptions = Apollo.BaseMutationOptions<
  UpdateReferralPostingMutation,
  UpdateReferralPostingMutationVariables
>;
export const GetProjectCanAcceptReferralDocument = gql`
  query GetProjectCanAcceptReferral(
    $sourceEnrollmentId: ID!
    $destinationProjectId: ID!
  ) {
    projectCanAcceptReferral(
      sourceEnrollmentId: $sourceEnrollmentId
      destinationProjectId: $destinationProjectId
    )
  }
`;

/**
 * __useGetProjectCanAcceptReferralQuery__
 *
 * To run a query within a React component, call `useGetProjectCanAcceptReferralQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCanAcceptReferralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCanAcceptReferralQuery({
 *   variables: {
 *      sourceEnrollmentId: // value for 'sourceEnrollmentId'
 *      destinationProjectId: // value for 'destinationProjectId'
 *   },
 * });
 */
export function useGetProjectCanAcceptReferralQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectCanAcceptReferralQuery,
    GetProjectCanAcceptReferralQueryVariables
  > &
    (
      | { variables: GetProjectCanAcceptReferralQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectCanAcceptReferralQuery,
    GetProjectCanAcceptReferralQueryVariables
  >(GetProjectCanAcceptReferralDocument, options);
}
export function useGetProjectCanAcceptReferralLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCanAcceptReferralQuery,
    GetProjectCanAcceptReferralQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectCanAcceptReferralQuery,
    GetProjectCanAcceptReferralQueryVariables
  >(GetProjectCanAcceptReferralDocument, options);
}
export function useGetProjectCanAcceptReferralSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectCanAcceptReferralQuery,
    GetProjectCanAcceptReferralQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectCanAcceptReferralQuery,
    GetProjectCanAcceptReferralQueryVariables
  >(GetProjectCanAcceptReferralDocument, options);
}
export type GetProjectCanAcceptReferralQueryHookResult = ReturnType<
  typeof useGetProjectCanAcceptReferralQuery
>;
export type GetProjectCanAcceptReferralLazyQueryHookResult = ReturnType<
  typeof useGetProjectCanAcceptReferralLazyQuery
>;
export type GetProjectCanAcceptReferralSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectCanAcceptReferralSuspenseQuery
>;
export type GetProjectCanAcceptReferralQueryResult = Apollo.QueryResult<
  GetProjectCanAcceptReferralQuery,
  GetProjectCanAcceptReferralQueryVariables
>;
export const GetReferralPostingDocument = gql`
  query GetReferralPosting($id: ID!) {
    referralPosting(id: $id) {
      ...ReferralPostingDetailFields
    }
  }
  ${ReferralPostingDetailFieldsFragmentDoc}
`;

/**
 * __useGetReferralPostingQuery__
 *
 * To run a query within a React component, call `useGetReferralPostingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralPostingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralPostingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReferralPostingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReferralPostingQuery,
    GetReferralPostingQueryVariables
  > &
    (
      | { variables: GetReferralPostingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReferralPostingQuery,
    GetReferralPostingQueryVariables
  >(GetReferralPostingDocument, options);
}
export function useGetReferralPostingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReferralPostingQuery,
    GetReferralPostingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReferralPostingQuery,
    GetReferralPostingQueryVariables
  >(GetReferralPostingDocument, options);
}
export function useGetReferralPostingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetReferralPostingQuery,
    GetReferralPostingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetReferralPostingQuery,
    GetReferralPostingQueryVariables
  >(GetReferralPostingDocument, options);
}
export type GetReferralPostingQueryHookResult = ReturnType<
  typeof useGetReferralPostingQuery
>;
export type GetReferralPostingLazyQueryHookResult = ReturnType<
  typeof useGetReferralPostingLazyQuery
>;
export type GetReferralPostingSuspenseQueryHookResult = ReturnType<
  typeof useGetReferralPostingSuspenseQuery
>;
export type GetReferralPostingQueryResult = Apollo.QueryResult<
  GetReferralPostingQuery,
  GetReferralPostingQueryVariables
>;
export const GetDeniedPendingReferralPostingsDocument = gql`
  query GetDeniedPendingReferralPostings(
    $limit: Int = 10
    $offset: Int = 0
    $filters: ReferralPostingFilterOptions
  ) {
    deniedPendingReferralPostings(
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      offset
      limit
      nodesCount
      nodes {
        ...ReferralPostingFields
      }
    }
  }
  ${ReferralPostingFieldsFragmentDoc}
`;

/**
 * __useGetDeniedPendingReferralPostingsQuery__
 *
 * To run a query within a React component, call `useGetDeniedPendingReferralPostingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeniedPendingReferralPostingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeniedPendingReferralPostingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDeniedPendingReferralPostingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDeniedPendingReferralPostingsQuery,
    GetDeniedPendingReferralPostingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDeniedPendingReferralPostingsQuery,
    GetDeniedPendingReferralPostingsQueryVariables
  >(GetDeniedPendingReferralPostingsDocument, options);
}
export function useGetDeniedPendingReferralPostingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeniedPendingReferralPostingsQuery,
    GetDeniedPendingReferralPostingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDeniedPendingReferralPostingsQuery,
    GetDeniedPendingReferralPostingsQueryVariables
  >(GetDeniedPendingReferralPostingsDocument, options);
}
export function useGetDeniedPendingReferralPostingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDeniedPendingReferralPostingsQuery,
    GetDeniedPendingReferralPostingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDeniedPendingReferralPostingsQuery,
    GetDeniedPendingReferralPostingsQueryVariables
  >(GetDeniedPendingReferralPostingsDocument, options);
}
export type GetDeniedPendingReferralPostingsQueryHookResult = ReturnType<
  typeof useGetDeniedPendingReferralPostingsQuery
>;
export type GetDeniedPendingReferralPostingsLazyQueryHookResult = ReturnType<
  typeof useGetDeniedPendingReferralPostingsLazyQuery
>;
export type GetDeniedPendingReferralPostingsSuspenseQueryHookResult =
  ReturnType<typeof useGetDeniedPendingReferralPostingsSuspenseQuery>;
export type GetDeniedPendingReferralPostingsQueryResult = Apollo.QueryResult<
  GetDeniedPendingReferralPostingsQuery,
  GetDeniedPendingReferralPostingsQueryVariables
>;
export const GetEsgFundingReportDocument = gql`
  query GetEsgFundingReport($clientIds: [ID!]!) {
    esgFundingReport(clientIds: $clientIds) {
      ...EsgFundingServiceFields
    }
  }
  ${EsgFundingServiceFieldsFragmentDoc}
`;

/**
 * __useGetEsgFundingReportQuery__
 *
 * To run a query within a React component, call `useGetEsgFundingReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEsgFundingReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEsgFundingReportQuery({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *   },
 * });
 */
export function useGetEsgFundingReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEsgFundingReportQuery,
    GetEsgFundingReportQueryVariables
  > &
    (
      | { variables: GetEsgFundingReportQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEsgFundingReportQuery,
    GetEsgFundingReportQueryVariables
  >(GetEsgFundingReportDocument, options);
}
export function useGetEsgFundingReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEsgFundingReportQuery,
    GetEsgFundingReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEsgFundingReportQuery,
    GetEsgFundingReportQueryVariables
  >(GetEsgFundingReportDocument, options);
}
export function useGetEsgFundingReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEsgFundingReportQuery,
    GetEsgFundingReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEsgFundingReportQuery,
    GetEsgFundingReportQueryVariables
  >(GetEsgFundingReportDocument, options);
}
export type GetEsgFundingReportQueryHookResult = ReturnType<
  typeof useGetEsgFundingReportQuery
>;
export type GetEsgFundingReportLazyQueryHookResult = ReturnType<
  typeof useGetEsgFundingReportLazyQuery
>;
export type GetEsgFundingReportSuspenseQueryHookResult = ReturnType<
  typeof useGetEsgFundingReportSuspenseQuery
>;
export type GetEsgFundingReportQueryResult = Apollo.QueryResult<
  GetEsgFundingReportQuery,
  GetEsgFundingReportQueryVariables
>;
export const GetClientScanCardCodesDocument = gql`
  query GetClientScanCardCodes($id: ID!, $limit: Int = 25, $offset: Int = 0) {
    client(id: $id) {
      id
      scanCardCodes(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...ScanCardFields
        }
      }
    }
  }
  ${ScanCardFieldsFragmentDoc}
`;

/**
 * __useGetClientScanCardCodesQuery__
 *
 * To run a query within a React component, call `useGetClientScanCardCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientScanCardCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientScanCardCodesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetClientScanCardCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientScanCardCodesQuery,
    GetClientScanCardCodesQueryVariables
  > &
    (
      | { variables: GetClientScanCardCodesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientScanCardCodesQuery,
    GetClientScanCardCodesQueryVariables
  >(GetClientScanCardCodesDocument, options);
}
export function useGetClientScanCardCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientScanCardCodesQuery,
    GetClientScanCardCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientScanCardCodesQuery,
    GetClientScanCardCodesQueryVariables
  >(GetClientScanCardCodesDocument, options);
}
export function useGetClientScanCardCodesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientScanCardCodesQuery,
    GetClientScanCardCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientScanCardCodesQuery,
    GetClientScanCardCodesQueryVariables
  >(GetClientScanCardCodesDocument, options);
}
export type GetClientScanCardCodesQueryHookResult = ReturnType<
  typeof useGetClientScanCardCodesQuery
>;
export type GetClientScanCardCodesLazyQueryHookResult = ReturnType<
  typeof useGetClientScanCardCodesLazyQuery
>;
export type GetClientScanCardCodesSuspenseQueryHookResult = ReturnType<
  typeof useGetClientScanCardCodesSuspenseQuery
>;
export type GetClientScanCardCodesQueryResult = Apollo.QueryResult<
  GetClientScanCardCodesQuery,
  GetClientScanCardCodesQueryVariables
>;
export const CreateScanCardDocument = gql`
  mutation CreateScanCard($clientId: ID!) {
    createScanCardCode(clientId: $clientId) {
      scanCardCode {
        ...ScanCardFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ScanCardFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type CreateScanCardMutationFn = Apollo.MutationFunction<
  CreateScanCardMutation,
  CreateScanCardMutationVariables
>;

/**
 * __useCreateScanCardMutation__
 *
 * To run a mutation, you first call `useCreateScanCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScanCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScanCardMutation, { data, loading, error }] = useCreateScanCardMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useCreateScanCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScanCardMutation,
    CreateScanCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScanCardMutation,
    CreateScanCardMutationVariables
  >(CreateScanCardDocument, options);
}
export type CreateScanCardMutationHookResult = ReturnType<
  typeof useCreateScanCardMutation
>;
export type CreateScanCardMutationResult =
  Apollo.MutationResult<CreateScanCardMutation>;
export type CreateScanCardMutationOptions = Apollo.BaseMutationOptions<
  CreateScanCardMutation,
  CreateScanCardMutationVariables
>;
export const DeleteScanCardDocument = gql`
  mutation DeleteScanCard($id: ID!) {
    deleteScanCardCode(id: $id) {
      scanCardCode {
        ...ScanCardFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ScanCardFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteScanCardMutationFn = Apollo.MutationFunction<
  DeleteScanCardMutation,
  DeleteScanCardMutationVariables
>;

/**
 * __useDeleteScanCardMutation__
 *
 * To run a mutation, you first call `useDeleteScanCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScanCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScanCardMutation, { data, loading, error }] = useDeleteScanCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScanCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScanCardMutation,
    DeleteScanCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScanCardMutation,
    DeleteScanCardMutationVariables
  >(DeleteScanCardDocument, options);
}
export type DeleteScanCardMutationHookResult = ReturnType<
  typeof useDeleteScanCardMutation
>;
export type DeleteScanCardMutationResult =
  Apollo.MutationResult<DeleteScanCardMutation>;
export type DeleteScanCardMutationOptions = Apollo.BaseMutationOptions<
  DeleteScanCardMutation,
  DeleteScanCardMutationVariables
>;
export const RestoreScanCardDocument = gql`
  mutation RestoreScanCard($id: ID!) {
    restoreScanCardCode(id: $id) {
      scanCardCode {
        ...ScanCardFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ScanCardFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type RestoreScanCardMutationFn = Apollo.MutationFunction<
  RestoreScanCardMutation,
  RestoreScanCardMutationVariables
>;

/**
 * __useRestoreScanCardMutation__
 *
 * To run a mutation, you first call `useRestoreScanCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreScanCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreScanCardMutation, { data, loading, error }] = useRestoreScanCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreScanCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreScanCardMutation,
    RestoreScanCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreScanCardMutation,
    RestoreScanCardMutationVariables
  >(RestoreScanCardDocument, options);
}
export type RestoreScanCardMutationHookResult = ReturnType<
  typeof useRestoreScanCardMutation
>;
export type RestoreScanCardMutationResult =
  Apollo.MutationResult<RestoreScanCardMutation>;
export type RestoreScanCardMutationOptions = Apollo.BaseMutationOptions<
  RestoreScanCardMutation,
  RestoreScanCardMutationVariables
>;
export const GetServiceDocument = gql`
  query GetService($id: ID!) {
    service(id: $id) {
      ...ServiceFields
    }
  }
  ${ServiceFieldsFragmentDoc}
`;

/**
 * __useGetServiceQuery__
 *
 * To run a query within a React component, call `useGetServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceQuery,
    GetServiceQueryVariables
  > &
    (
      | { variables: GetServiceQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServiceQuery, GetServiceQueryVariables>(
    GetServiceDocument,
    options
  );
}
export function useGetServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceQuery,
    GetServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServiceQuery, GetServiceQueryVariables>(
    GetServiceDocument,
    options
  );
}
export function useGetServiceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceQuery,
    GetServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetServiceQuery, GetServiceQueryVariables>(
    GetServiceDocument,
    options
  );
}
export type GetServiceQueryHookResult = ReturnType<typeof useGetServiceQuery>;
export type GetServiceLazyQueryHookResult = ReturnType<
  typeof useGetServiceLazyQuery
>;
export type GetServiceSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceSuspenseQuery
>;
export type GetServiceQueryResult = Apollo.QueryResult<
  GetServiceQuery,
  GetServiceQueryVariables
>;
export const GetServiceTypeDocument = gql`
  query GetServiceType($id: ID!) {
    serviceType(id: $id) {
      ...ServiceTypeFields
    }
  }
  ${ServiceTypeFieldsFragmentDoc}
`;

/**
 * __useGetServiceTypeQuery__
 *
 * To run a query within a React component, call `useGetServiceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceTypeQuery,
    GetServiceTypeQueryVariables
  > &
    (
      | { variables: GetServiceTypeQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServiceTypeQuery, GetServiceTypeQueryVariables>(
    GetServiceTypeDocument,
    options
  );
}
export function useGetServiceTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceTypeQuery,
    GetServiceTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServiceTypeQuery, GetServiceTypeQueryVariables>(
    GetServiceTypeDocument,
    options
  );
}
export function useGetServiceTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceTypeQuery,
    GetServiceTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceTypeQuery,
    GetServiceTypeQueryVariables
  >(GetServiceTypeDocument, options);
}
export type GetServiceTypeQueryHookResult = ReturnType<
  typeof useGetServiceTypeQuery
>;
export type GetServiceTypeLazyQueryHookResult = ReturnType<
  typeof useGetServiceTypeLazyQuery
>;
export type GetServiceTypeSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceTypeSuspenseQuery
>;
export type GetServiceTypeQueryResult = Apollo.QueryResult<
  GetServiceTypeQuery,
  GetServiceTypeQueryVariables
>;
export const DeleteServiceDocument = gql`
  mutation DeleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      clientMutationId
      service {
        ...ServiceFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ServiceFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteServiceMutationFn = Apollo.MutationFunction<
  DeleteServiceMutation,
  DeleteServiceMutationVariables
>;

/**
 * __useDeleteServiceMutation__
 *
 * To run a mutation, you first call `useDeleteServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceMutation, { data, loading, error }] = useDeleteServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceMutation,
    DeleteServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteServiceMutation,
    DeleteServiceMutationVariables
  >(DeleteServiceDocument, options);
}
export type DeleteServiceMutationHookResult = ReturnType<
  typeof useDeleteServiceMutation
>;
export type DeleteServiceMutationResult =
  Apollo.MutationResult<DeleteServiceMutation>;
export type DeleteServiceMutationOptions = Apollo.BaseMutationOptions<
  DeleteServiceMutation,
  DeleteServiceMutationVariables
>;
export const GetEnrollmentServicesDocument = gql`
  query GetEnrollmentServices(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $filters: ServicesForEnrollmentFilterOptions = null
  ) {
    enrollment(id: $id) {
      id
      services(limit: $limit, offset: $offset, filters: $filters) {
        offset
        limit
        nodesCount
        nodes {
          ...ServiceFields
        }
      }
    }
  }
  ${ServiceFieldsFragmentDoc}
`;

/**
 * __useGetEnrollmentServicesQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentServicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetEnrollmentServicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentServicesQuery,
    GetEnrollmentServicesQueryVariables
  > &
    (
      | { variables: GetEnrollmentServicesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnrollmentServicesQuery,
    GetEnrollmentServicesQueryVariables
  >(GetEnrollmentServicesDocument, options);
}
export function useGetEnrollmentServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentServicesQuery,
    GetEnrollmentServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnrollmentServicesQuery,
    GetEnrollmentServicesQueryVariables
  >(GetEnrollmentServicesDocument, options);
}
export function useGetEnrollmentServicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnrollmentServicesQuery,
    GetEnrollmentServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnrollmentServicesQuery,
    GetEnrollmentServicesQueryVariables
  >(GetEnrollmentServicesDocument, options);
}
export type GetEnrollmentServicesQueryHookResult = ReturnType<
  typeof useGetEnrollmentServicesQuery
>;
export type GetEnrollmentServicesLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentServicesLazyQuery
>;
export type GetEnrollmentServicesSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrollmentServicesSuspenseQuery
>;
export type GetEnrollmentServicesQueryResult = Apollo.QueryResult<
  GetEnrollmentServicesQuery,
  GetEnrollmentServicesQueryVariables
>;
export const GetServiceCategoryDocument = gql`
  query GetServiceCategory($id: ID!) {
    serviceCategory(id: $id) {
      ...ServiceCategoryFields
    }
  }
  ${ServiceCategoryFieldsFragmentDoc}
`;

/**
 * __useGetServiceCategoryQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceCategoryQuery,
    GetServiceCategoryQueryVariables
  > &
    (
      | { variables: GetServiceCategoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceCategoryQuery,
    GetServiceCategoryQueryVariables
  >(GetServiceCategoryDocument, options);
}
export function useGetServiceCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceCategoryQuery,
    GetServiceCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceCategoryQuery,
    GetServiceCategoryQueryVariables
  >(GetServiceCategoryDocument, options);
}
export function useGetServiceCategorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceCategoryQuery,
    GetServiceCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceCategoryQuery,
    GetServiceCategoryQueryVariables
  >(GetServiceCategoryDocument, options);
}
export type GetServiceCategoryQueryHookResult = ReturnType<
  typeof useGetServiceCategoryQuery
>;
export type GetServiceCategoryLazyQueryHookResult = ReturnType<
  typeof useGetServiceCategoryLazyQuery
>;
export type GetServiceCategorySuspenseQueryHookResult = ReturnType<
  typeof useGetServiceCategorySuspenseQuery
>;
export type GetServiceCategoryQueryResult = Apollo.QueryResult<
  GetServiceCategoryQuery,
  GetServiceCategoryQueryVariables
>;
export const GetServiceCategoryTypesDocument = gql`
  query GetServiceCategoryTypes($id: ID!, $limit: Int = 25, $offset: Int = 0) {
    serviceCategory(id: $id) {
      id
      serviceTypes(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...ServiceTypeFields
        }
      }
    }
  }
  ${ServiceTypeFieldsFragmentDoc}
`;

/**
 * __useGetServiceCategoryTypesQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoryTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoryTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoryTypesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetServiceCategoryTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceCategoryTypesQuery,
    GetServiceCategoryTypesQueryVariables
  > &
    (
      | { variables: GetServiceCategoryTypesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceCategoryTypesQuery,
    GetServiceCategoryTypesQueryVariables
  >(GetServiceCategoryTypesDocument, options);
}
export function useGetServiceCategoryTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceCategoryTypesQuery,
    GetServiceCategoryTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceCategoryTypesQuery,
    GetServiceCategoryTypesQueryVariables
  >(GetServiceCategoryTypesDocument, options);
}
export function useGetServiceCategoryTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceCategoryTypesQuery,
    GetServiceCategoryTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceCategoryTypesQuery,
    GetServiceCategoryTypesQueryVariables
  >(GetServiceCategoryTypesDocument, options);
}
export type GetServiceCategoryTypesQueryHookResult = ReturnType<
  typeof useGetServiceCategoryTypesQuery
>;
export type GetServiceCategoryTypesLazyQueryHookResult = ReturnType<
  typeof useGetServiceCategoryTypesLazyQuery
>;
export type GetServiceCategoryTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceCategoryTypesSuspenseQuery
>;
export type GetServiceCategoryTypesQueryResult = Apollo.QueryResult<
  GetServiceCategoryTypesQuery,
  GetServiceCategoryTypesQueryVariables
>;
export const AssignStaffDocument = gql`
  mutation AssignStaff($input: AssignStaffInput!) {
    assignStaff(input: $input) {
      staffAssignment {
        ...StaffAssignmentDetails
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${StaffAssignmentDetailsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type AssignStaffMutationFn = Apollo.MutationFunction<
  AssignStaffMutation,
  AssignStaffMutationVariables
>;

/**
 * __useAssignStaffMutation__
 *
 * To run a mutation, you first call `useAssignStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStaffMutation, { data, loading, error }] = useAssignStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignStaffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignStaffMutation,
    AssignStaffMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignStaffMutation, AssignStaffMutationVariables>(
    AssignStaffDocument,
    options
  );
}
export type AssignStaffMutationHookResult = ReturnType<
  typeof useAssignStaffMutation
>;
export type AssignStaffMutationResult =
  Apollo.MutationResult<AssignStaffMutation>;
export type AssignStaffMutationOptions = Apollo.BaseMutationOptions<
  AssignStaffMutation,
  AssignStaffMutationVariables
>;
export const UnassignStaffDocument = gql`
  mutation UnassignStaff($staffAssignmentId: ID!) {
    unassignStaff(id: $staffAssignmentId) {
      staffAssignment {
        ...StaffAssignmentDetails
      }
    }
  }
  ${StaffAssignmentDetailsFragmentDoc}
`;
export type UnassignStaffMutationFn = Apollo.MutationFunction<
  UnassignStaffMutation,
  UnassignStaffMutationVariables
>;

/**
 * __useUnassignStaffMutation__
 *
 * To run a mutation, you first call `useUnassignStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignStaffMutation, { data, loading, error }] = useUnassignStaffMutation({
 *   variables: {
 *      staffAssignmentId: // value for 'staffAssignmentId'
 *   },
 * });
 */
export function useUnassignStaffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignStaffMutation,
    UnassignStaffMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnassignStaffMutation,
    UnassignStaffMutationVariables
  >(UnassignStaffDocument, options);
}
export type UnassignStaffMutationHookResult = ReturnType<
  typeof useUnassignStaffMutation
>;
export type UnassignStaffMutationResult =
  Apollo.MutationResult<UnassignStaffMutation>;
export type UnassignStaffMutationOptions = Apollo.BaseMutationOptions<
  UnassignStaffMutation,
  UnassignStaffMutationVariables
>;
export const GetHouseholdStaffAssignmentsDocument = gql`
  query GetHouseholdStaffAssignments($id: ID!) {
    household(id: $id) {
      ...HouseholdWithStaffAssignments
    }
  }
  ${HouseholdWithStaffAssignmentsFragmentDoc}
`;

/**
 * __useGetHouseholdStaffAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetHouseholdStaffAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseholdStaffAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseholdStaffAssignmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHouseholdStaffAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHouseholdStaffAssignmentsQuery,
    GetHouseholdStaffAssignmentsQueryVariables
  > &
    (
      | {
          variables: GetHouseholdStaffAssignmentsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHouseholdStaffAssignmentsQuery,
    GetHouseholdStaffAssignmentsQueryVariables
  >(GetHouseholdStaffAssignmentsDocument, options);
}
export function useGetHouseholdStaffAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHouseholdStaffAssignmentsQuery,
    GetHouseholdStaffAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHouseholdStaffAssignmentsQuery,
    GetHouseholdStaffAssignmentsQueryVariables
  >(GetHouseholdStaffAssignmentsDocument, options);
}
export function useGetHouseholdStaffAssignmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHouseholdStaffAssignmentsQuery,
    GetHouseholdStaffAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetHouseholdStaffAssignmentsQuery,
    GetHouseholdStaffAssignmentsQueryVariables
  >(GetHouseholdStaffAssignmentsDocument, options);
}
export type GetHouseholdStaffAssignmentsQueryHookResult = ReturnType<
  typeof useGetHouseholdStaffAssignmentsQuery
>;
export type GetHouseholdStaffAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetHouseholdStaffAssignmentsLazyQuery
>;
export type GetHouseholdStaffAssignmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetHouseholdStaffAssignmentsSuspenseQuery
>;
export type GetHouseholdStaffAssignmentsQueryResult = Apollo.QueryResult<
  GetHouseholdStaffAssignmentsQuery,
  GetHouseholdStaffAssignmentsQueryVariables
>;
export const GetHouseholdStaffAssignmentHistoryDocument = gql`
  query GetHouseholdStaffAssignmentHistory($id: ID!) {
    household(id: $id) {
      id
      staffAssignments(isCurrentlyAssigned: false, limit: 100) {
        nodesCount
        nodes {
          ...StaffAssignmentDetails
        }
      }
    }
  }
  ${StaffAssignmentDetailsFragmentDoc}
`;

/**
 * __useGetHouseholdStaffAssignmentHistoryQuery__
 *
 * To run a query within a React component, call `useGetHouseholdStaffAssignmentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseholdStaffAssignmentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseholdStaffAssignmentHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHouseholdStaffAssignmentHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHouseholdStaffAssignmentHistoryQuery,
    GetHouseholdStaffAssignmentHistoryQueryVariables
  > &
    (
      | {
          variables: GetHouseholdStaffAssignmentHistoryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHouseholdStaffAssignmentHistoryQuery,
    GetHouseholdStaffAssignmentHistoryQueryVariables
  >(GetHouseholdStaffAssignmentHistoryDocument, options);
}
export function useGetHouseholdStaffAssignmentHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHouseholdStaffAssignmentHistoryQuery,
    GetHouseholdStaffAssignmentHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHouseholdStaffAssignmentHistoryQuery,
    GetHouseholdStaffAssignmentHistoryQueryVariables
  >(GetHouseholdStaffAssignmentHistoryDocument, options);
}
export function useGetHouseholdStaffAssignmentHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHouseholdStaffAssignmentHistoryQuery,
    GetHouseholdStaffAssignmentHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetHouseholdStaffAssignmentHistoryQuery,
    GetHouseholdStaffAssignmentHistoryQueryVariables
  >(GetHouseholdStaffAssignmentHistoryDocument, options);
}
export type GetHouseholdStaffAssignmentHistoryQueryHookResult = ReturnType<
  typeof useGetHouseholdStaffAssignmentHistoryQuery
>;
export type GetHouseholdStaffAssignmentHistoryLazyQueryHookResult = ReturnType<
  typeof useGetHouseholdStaffAssignmentHistoryLazyQuery
>;
export type GetHouseholdStaffAssignmentHistorySuspenseQueryHookResult =
  ReturnType<typeof useGetHouseholdStaffAssignmentHistorySuspenseQuery>;
export type GetHouseholdStaffAssignmentHistoryQueryResult = Apollo.QueryResult<
  GetHouseholdStaffAssignmentHistoryQuery,
  GetHouseholdStaffAssignmentHistoryQueryVariables
>;
export const GetUnitsDocument = gql`
  query GetUnits(
    $id: ID!
    $limit: Int = 10
    $offset: Int = 0
    $filters: UnitFilterOptions
  ) {
    project(id: $id) {
      id
      units(limit: $limit, offset: $offset, filters: $filters) {
        offset
        limit
        nodesCount
        nodes {
          ...UnitFields
        }
      }
    }
  }
  ${UnitFieldsFragmentDoc}
`;

/**
 * __useGetUnitsQuery__
 *
 * To run a query within a React component, call `useGetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables> &
    ({ variables: GetUnitsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(
    GetUnitsDocument,
    options
  );
}
export function useGetUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnitsQuery,
    GetUnitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(
    GetUnitsDocument,
    options
  );
}
export function useGetUnitsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUnitsQuery,
    GetUnitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUnitsQuery, GetUnitsQueryVariables>(
    GetUnitsDocument,
    options
  );
}
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<
  typeof useGetUnitsLazyQuery
>;
export type GetUnitsSuspenseQueryHookResult = ReturnType<
  typeof useGetUnitsSuspenseQuery
>;
export type GetUnitsQueryResult = Apollo.QueryResult<
  GetUnitsQuery,
  GetUnitsQueryVariables
>;
export const GetProjectUnitTypesDocument = gql`
  query GetProjectUnitTypes($id: ID!) {
    project(id: $id) {
      id
      unitTypes {
        ...UnitTypeCapacityFields
      }
    }
  }
  ${UnitTypeCapacityFieldsFragmentDoc}
`;

/**
 * __useGetProjectUnitTypesQuery__
 *
 * To run a query within a React component, call `useGetProjectUnitTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectUnitTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectUnitTypesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectUnitTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectUnitTypesQuery,
    GetProjectUnitTypesQueryVariables
  > &
    (
      | { variables: GetProjectUnitTypesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectUnitTypesQuery,
    GetProjectUnitTypesQueryVariables
  >(GetProjectUnitTypesDocument, options);
}
export function useGetProjectUnitTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectUnitTypesQuery,
    GetProjectUnitTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectUnitTypesQuery,
    GetProjectUnitTypesQueryVariables
  >(GetProjectUnitTypesDocument, options);
}
export function useGetProjectUnitTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectUnitTypesQuery,
    GetProjectUnitTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectUnitTypesQuery,
    GetProjectUnitTypesQueryVariables
  >(GetProjectUnitTypesDocument, options);
}
export type GetProjectUnitTypesQueryHookResult = ReturnType<
  typeof useGetProjectUnitTypesQuery
>;
export type GetProjectUnitTypesLazyQueryHookResult = ReturnType<
  typeof useGetProjectUnitTypesLazyQuery
>;
export type GetProjectUnitTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectUnitTypesSuspenseQuery
>;
export type GetProjectUnitTypesQueryResult = Apollo.QueryResult<
  GetProjectUnitTypesQuery,
  GetProjectUnitTypesQueryVariables
>;
export const CreateUnitsDocument = gql`
  mutation CreateUnits($input: CreateUnitsInput!) {
    createUnits(input: $input) {
      clientMutationId
      units {
        ...UnitFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${UnitFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type CreateUnitsMutationFn = Apollo.MutationFunction<
  CreateUnitsMutation,
  CreateUnitsMutationVariables
>;

/**
 * __useCreateUnitsMutation__
 *
 * To run a mutation, you first call `useCreateUnitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitsMutation, { data, loading, error }] = useCreateUnitsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUnitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUnitsMutation,
    CreateUnitsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUnitsMutation, CreateUnitsMutationVariables>(
    CreateUnitsDocument,
    options
  );
}
export type CreateUnitsMutationHookResult = ReturnType<
  typeof useCreateUnitsMutation
>;
export type CreateUnitsMutationResult =
  Apollo.MutationResult<CreateUnitsMutation>;
export type CreateUnitsMutationOptions = Apollo.BaseMutationOptions<
  CreateUnitsMutation,
  CreateUnitsMutationVariables
>;
export const DeleteUnitsDocument = gql`
  mutation DeleteUnits($input: DeleteUnitsInput!) {
    deleteUnits(input: $input) {
      clientMutationId
      unitIds
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
`;
export type DeleteUnitsMutationFn = Apollo.MutationFunction<
  DeleteUnitsMutation,
  DeleteUnitsMutationVariables
>;

/**
 * __useDeleteUnitsMutation__
 *
 * To run a mutation, you first call `useDeleteUnitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitsMutation, { data, loading, error }] = useDeleteUnitsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUnitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUnitsMutation,
    DeleteUnitsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUnitsMutation, DeleteUnitsMutationVariables>(
    DeleteUnitsDocument,
    options
  );
}
export type DeleteUnitsMutationHookResult = ReturnType<
  typeof useDeleteUnitsMutation
>;
export type DeleteUnitsMutationResult =
  Apollo.MutationResult<DeleteUnitsMutation>;
export type DeleteUnitsMutationOptions = Apollo.BaseMutationOptions<
  DeleteUnitsMutation,
  DeleteUnitsMutationVariables
>;
export const UpdateUnitsDocument = gql`
  mutation UpdateUnits($input: UpdateUnitsInput!) {
    updateUnits(input: $input) {
      clientMutationId
      units {
        ...UnitFields
      }
      errors {
        ...ValidationErrorFields
      }
    }
  }
  ${UnitFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;
export type UpdateUnitsMutationFn = Apollo.MutationFunction<
  UpdateUnitsMutation,
  UpdateUnitsMutationVariables
>;

/**
 * __useUpdateUnitsMutation__
 *
 * To run a mutation, you first call `useUpdateUnitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitsMutation, { data, loading, error }] = useUpdateUnitsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUnitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUnitsMutation,
    UpdateUnitsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUnitsMutation, UpdateUnitsMutationVariables>(
    UpdateUnitsDocument,
    options
  );
}
export type UpdateUnitsMutationHookResult = ReturnType<
  typeof useUpdateUnitsMutation
>;
export type UpdateUnitsMutationResult =
  Apollo.MutationResult<UpdateUnitsMutation>;
export type UpdateUnitsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUnitsMutation,
  UpdateUnitsMutationVariables
>;
export const CreateDirectUploadMutationDocument = gql`
  mutation CreateDirectUploadMutation($input: DirectUploadInput!) {
    createDirectUpload(input: { input: $input }) {
      filename
      headers
      url
      signedBlobId
    }
  }
`;
export type CreateDirectUploadMutationMutationFn = Apollo.MutationFunction<
  CreateDirectUploadMutationMutation,
  CreateDirectUploadMutationMutationVariables
>;

/**
 * __useCreateDirectUploadMutationMutation__
 *
 * To run a mutation, you first call `useCreateDirectUploadMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectUploadMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectUploadMutationMutation, { data, loading, error }] = useCreateDirectUploadMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDirectUploadMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDirectUploadMutationMutation,
    CreateDirectUploadMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDirectUploadMutationMutation,
    CreateDirectUploadMutationMutationVariables
  >(CreateDirectUploadMutationDocument, options);
}
export type CreateDirectUploadMutationMutationHookResult = ReturnType<
  typeof useCreateDirectUploadMutationMutation
>;
export type CreateDirectUploadMutationMutationResult =
  Apollo.MutationResult<CreateDirectUploadMutationMutation>;
export type CreateDirectUploadMutationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDirectUploadMutationMutation,
    CreateDirectUploadMutationMutationVariables
  >;
export const GetApplicationUsersDocument = gql`
  query GetApplicationUsers(
    $limit: Int = 10
    $offset: Int = 0
    $filters: ApplicationUserFilterOptions = null
  ) {
    applicationUsers(limit: $limit, offset: $offset, filters: $filters) {
      offset
      limit
      nodesCount
      nodes {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetApplicationUsersQuery__
 *
 * To run a query within a React component, call `useGetApplicationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetApplicationUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApplicationUsersQuery,
    GetApplicationUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationUsersQuery,
    GetApplicationUsersQueryVariables
  >(GetApplicationUsersDocument, options);
}
export function useGetApplicationUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationUsersQuery,
    GetApplicationUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationUsersQuery,
    GetApplicationUsersQueryVariables
  >(GetApplicationUsersDocument, options);
}
export function useGetApplicationUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetApplicationUsersQuery,
    GetApplicationUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetApplicationUsersQuery,
    GetApplicationUsersQueryVariables
  >(GetApplicationUsersDocument, options);
}
export type GetApplicationUsersQueryHookResult = ReturnType<
  typeof useGetApplicationUsersQuery
>;
export type GetApplicationUsersLazyQueryHookResult = ReturnType<
  typeof useGetApplicationUsersLazyQuery
>;
export type GetApplicationUsersSuspenseQueryHookResult = ReturnType<
  typeof useGetApplicationUsersSuspenseQuery
>;
export type GetApplicationUsersQueryResult = Apollo.QueryResult<
  GetApplicationUsersQuery,
  GetApplicationUsersQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> &
    ({ variables: GetUserQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<
  typeof useGetUserSuspenseQuery
>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUserAccessHistoryDocument = gql`
  query GetUserAccessHistory($id: ID!, $limit: Int = 25, $offset: Int = 0) {
    user(id: $id) {
      id
      activityLogs(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...UserActivityLogFields
        }
      }
    }
  }
  ${UserActivityLogFieldsFragmentDoc}
`;

/**
 * __useGetUserAccessHistoryQuery__
 *
 * To run a query within a React component, call `useGetUserAccessHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccessHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccessHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUserAccessHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAccessHistoryQuery,
    GetUserAccessHistoryQueryVariables
  > &
    (
      | { variables: GetUserAccessHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserAccessHistoryQuery,
    GetUserAccessHistoryQueryVariables
  >(GetUserAccessHistoryDocument, options);
}
export function useGetUserAccessHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAccessHistoryQuery,
    GetUserAccessHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAccessHistoryQuery,
    GetUserAccessHistoryQueryVariables
  >(GetUserAccessHistoryDocument, options);
}
export function useGetUserAccessHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserAccessHistoryQuery,
    GetUserAccessHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserAccessHistoryQuery,
    GetUserAccessHistoryQueryVariables
  >(GetUserAccessHistoryDocument, options);
}
export type GetUserAccessHistoryQueryHookResult = ReturnType<
  typeof useGetUserAccessHistoryQuery
>;
export type GetUserAccessHistoryLazyQueryHookResult = ReturnType<
  typeof useGetUserAccessHistoryLazyQuery
>;
export type GetUserAccessHistorySuspenseQueryHookResult = ReturnType<
  typeof useGetUserAccessHistorySuspenseQuery
>;
export type GetUserAccessHistoryQueryResult = Apollo.QueryResult<
  GetUserAccessHistoryQuery,
  GetUserAccessHistoryQueryVariables
>;
export const GetUserClientSummariesDocument = gql`
  query GetUserClientSummaries(
    $id: ID!
    $limit: Int = 25
    $offset: Int = 0
    $filters: ClientAccessSummaryFilterOptions
  ) {
    user(id: $id) {
      id
      clientAccessSummaries(limit: $limit, offset: $offset, filters: $filters) {
        offset
        limit
        nodesCount
        nodes {
          ...ClientAccessSummaryFields
        }
      }
    }
  }
  ${ClientAccessSummaryFieldsFragmentDoc}
`;

/**
 * __useGetUserClientSummariesQuery__
 *
 * To run a query within a React component, call `useGetUserClientSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserClientSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserClientSummariesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUserClientSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserClientSummariesQuery,
    GetUserClientSummariesQueryVariables
  > &
    (
      | { variables: GetUserClientSummariesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserClientSummariesQuery,
    GetUserClientSummariesQueryVariables
  >(GetUserClientSummariesDocument, options);
}
export function useGetUserClientSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserClientSummariesQuery,
    GetUserClientSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserClientSummariesQuery,
    GetUserClientSummariesQueryVariables
  >(GetUserClientSummariesDocument, options);
}
export function useGetUserClientSummariesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserClientSummariesQuery,
    GetUserClientSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserClientSummariesQuery,
    GetUserClientSummariesQueryVariables
  >(GetUserClientSummariesDocument, options);
}
export type GetUserClientSummariesQueryHookResult = ReturnType<
  typeof useGetUserClientSummariesQuery
>;
export type GetUserClientSummariesLazyQueryHookResult = ReturnType<
  typeof useGetUserClientSummariesLazyQuery
>;
export type GetUserClientSummariesSuspenseQueryHookResult = ReturnType<
  typeof useGetUserClientSummariesSuspenseQuery
>;
export type GetUserClientSummariesQueryResult = Apollo.QueryResult<
  GetUserClientSummariesQuery,
  GetUserClientSummariesQueryVariables
>;
export const GetUserEnrollmentSummariesDocument = gql`
  query GetUserEnrollmentSummaries(
    $id: ID!
    $limit: Int = 25
    $offset: Int = 0
    $filters: EnrollmentAccessSummaryFilterOptions
  ) {
    user(id: $id) {
      id
      enrollmentAccessSummaries(
        limit: $limit
        offset: $offset
        filters: $filters
      ) {
        offset
        limit
        nodesCount
        nodes {
          ...EnrollmentAccessSummaryFields
        }
      }
    }
  }
  ${EnrollmentAccessSummaryFieldsFragmentDoc}
`;

/**
 * __useGetUserEnrollmentSummariesQuery__
 *
 * To run a query within a React component, call `useGetUserEnrollmentSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEnrollmentSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEnrollmentSummariesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUserEnrollmentSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserEnrollmentSummariesQuery,
    GetUserEnrollmentSummariesQueryVariables
  > &
    (
      | { variables: GetUserEnrollmentSummariesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserEnrollmentSummariesQuery,
    GetUserEnrollmentSummariesQueryVariables
  >(GetUserEnrollmentSummariesDocument, options);
}
export function useGetUserEnrollmentSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEnrollmentSummariesQuery,
    GetUserEnrollmentSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserEnrollmentSummariesQuery,
    GetUserEnrollmentSummariesQueryVariables
  >(GetUserEnrollmentSummariesDocument, options);
}
export function useGetUserEnrollmentSummariesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserEnrollmentSummariesQuery,
    GetUserEnrollmentSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserEnrollmentSummariesQuery,
    GetUserEnrollmentSummariesQueryVariables
  >(GetUserEnrollmentSummariesDocument, options);
}
export type GetUserEnrollmentSummariesQueryHookResult = ReturnType<
  typeof useGetUserEnrollmentSummariesQuery
>;
export type GetUserEnrollmentSummariesLazyQueryHookResult = ReturnType<
  typeof useGetUserEnrollmentSummariesLazyQuery
>;
export type GetUserEnrollmentSummariesSuspenseQueryHookResult = ReturnType<
  typeof useGetUserEnrollmentSummariesSuspenseQuery
>;
export type GetUserEnrollmentSummariesQueryResult = Apollo.QueryResult<
  GetUserEnrollmentSummariesQuery,
  GetUserEnrollmentSummariesQueryVariables
>;
export const GetUserStaffAssignmentsDocument = gql`
  query GetUserStaffAssignments($id: ID!, $limit: Int = 25, $offset: Int = 0) {
    user(id: $id) {
      id
      staffAssignments(limit: $limit, offset: $offset) {
        offset
        limit
        nodesCount
        nodes {
          ...StaffAssignmentWithClients
        }
      }
    }
  }
  ${StaffAssignmentWithClientsFragmentDoc}
`;

/**
 * __useGetUserStaffAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetUserStaffAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStaffAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStaffAssignmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUserStaffAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserStaffAssignmentsQuery,
    GetUserStaffAssignmentsQueryVariables
  > &
    (
      | { variables: GetUserStaffAssignmentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserStaffAssignmentsQuery,
    GetUserStaffAssignmentsQueryVariables
  >(GetUserStaffAssignmentsDocument, options);
}
export function useGetUserStaffAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserStaffAssignmentsQuery,
    GetUserStaffAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserStaffAssignmentsQuery,
    GetUserStaffAssignmentsQueryVariables
  >(GetUserStaffAssignmentsDocument, options);
}
export function useGetUserStaffAssignmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserStaffAssignmentsQuery,
    GetUserStaffAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserStaffAssignmentsQuery,
    GetUserStaffAssignmentsQueryVariables
  >(GetUserStaffAssignmentsDocument, options);
}
export type GetUserStaffAssignmentsQueryHookResult = ReturnType<
  typeof useGetUserStaffAssignmentsQuery
>;
export type GetUserStaffAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetUserStaffAssignmentsLazyQuery
>;
export type GetUserStaffAssignmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserStaffAssignmentsSuspenseQuery
>;
export type GetUserStaffAssignmentsQueryResult = Apollo.QueryResult<
  GetUserStaffAssignmentsQuery,
  GetUserStaffAssignmentsQueryVariables
>;
